enum RuleType {
  Require = 'required',
  Length = 'minimal length',
  Items = 'range',
  Confirmation = 'confirmation',
  Info = 'info',
  PreventSameAsEmail = 'not same as email',
}

export default RuleType;
