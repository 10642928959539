import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { deletePaymentMethod } from '@cv/portal-cps-lib';
import { DeletePaymentMethodRequest } from '@cv/portal-cps-lib/payment/payment-methods/models';
import { options } from '../../commonService';
import { SubscriptionProps } from '@manageSubscription';

export const deletePayment = (props: SubscriptionProps, paymentId: string) => {
  const {
    userDetails: { userId },
  } = props;

  const request: DeletePaymentMethodRequest = {
    ...options(props),
    pathParams: { userId, id: paymentId },
  };
  return deletePaymentMethod(request);
};

export const useDeletePayment = (
  props: SubscriptionProps,
  options?: UseMutationOptions<unknown, unknown, unknown, unknown>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['updateDefaultPaymentMethod'],
    (paymentMethodId: string) => deletePayment(props, paymentMethodId),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        // we should re-fetch data to have updated payment methods
        queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
};
