import { styled } from '@mui/material';
import { Container, H1 } from '@styled-components/globalStyles';
import {
  BackgroundImage as BackgroundImageBase,
  HeaderContainer as HeaderContainerBase,
  LogoContainer as LogoContainerBase,
} from '../InformationPageStyles';

export const BackgroundImage = styled(BackgroundImageBase)`
  justify-content: left;
  margin: -16px -16px 0px;

  @media screen and (min-width: 600px) {
    margin: -32px -32px 0px;
  }
`;

export const HeaderContainer = styled(HeaderContainerBase)`
  text-align: left;
  padding: 1.3rem 0rem 0.9rem 16px;

  & > h1 {
    margin-bottom: 0rem;
  }

  @media screen and (min-width: 600px) {
    padding-left: 32px;
  }
`;

export const LogoContainer = styled(LogoContainerBase)`
  margin-bottom: 5rem;
`;

export const OfferName = styled('h3')`
  font-size: 1.5rem;
  font-weight: lighter;
  text-transform: uppercase;
  text-align: left;
`;

export const OfferDescription = styled('p')`
  font-size: 1rem;
`;

export const Offer = styled(Container)`
  margin-top: 1.3rem;
`;

export const FeatureNote = styled('span')`
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme?.palette?.portalTheme?.greyColor || 'gray'};
`;

export const ServicesTitle = styled(H1)`
  font-size: 1rem;
`;
