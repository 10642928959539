import { VehicleHealthResponse } from '@cv/portal-rts-lib/health/models';
import { LockType, Position, MilType } from '@cv/portal-rts-lib/health/enums';
import { UoM } from '@cv/portal-rts-lib/enums';
import { HealthReport } from '@cv/portal-rts-lib/health-reports/models';
import { IVehicle, VEHICLE_ACTIONS } from '../actions/';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';

type VehicleDetailsState = {
  vehicleHealth: {
    data: VehicleHealthResponse;
    initial?: boolean;
  };
  vehicleHealthReports: HealthReport[];
  vehicle: IVehicle;
};

type VehicleDetailsAction = {
  type: VEHICLE_ACTIONS;
  data: any;
};

const defaultTime = '2019-08-01T20:15:15.000Z';

const vehicleHealthInitialState: VehicleHealthResponse = {
  tyres: [
    {
      status: 'null',
      position: Position.Front_Left,
      UoM: UoM.Psi,
      value: '36',
    },
    {
      status: 'null',
      position: Position.Front_Right,
      UoM: UoM.Psi,
      value: '36',
    },
    {
      status: 'null',
      position: Position.Rear_Left,
      UoM: UoM.Psi,
      value: '36',
    },
    {
      status: 'null',
      position: Position.Rear_Right,
      UoM: UoM.Psi,
      value: '36',
    },
  ],
  mil: [
    {
      type: MilType.Abs,
      status: 'null',
    },
    {
      type: MilType.Air_Bag,
      status: 'null',
    },
    {
      type: MilType.Break_Fluid,
      status: 'null',
    },
    {
      type: MilType.Emergency_Breaking,
      status: 'null',
    },
    {
      type: MilType.Lamp_Request,
      status: 'null',
    },
    {
      type: MilType.Oil_Pressure_Switch,
      status: 'null',
    },
    {
      type: MilType.Oil_Pressure,
      status: 'null',
    },
    {
      type: MilType.Tyre_Pressure,
      status: 'null',
    },
  ],
  dtc: {
    code: 'null',
    description: '',
    language: 'en_US',
    acknowledge: {
      status: true,
      time: defaultTime,
    },
    detailedMessage: 'A possible condition has been determined with your car engine.',
    dtcOdometer: {
      UoM: 'KM',
      value: '3',
    },
    priority: '2A',
    createdTime: defaultTime,
    id: 1,
    lastUpdatedTime: defaultTime,
    vehicleSubSystem: 'AIRBAG',
    vehicleSystem: 'SAFETY',
  },
  odometer: null,
  locks: [
    {
      status: null,
      type: LockType.Door_Front_Left,
    },
    {
      status: null,
      type: LockType.Door_Front_Right,
    },
    {
      status: null,
      type: LockType.Door_Rear_Left,
    },
    {
      status: null,
      type: LockType.Door_Rear_Right,
    },
    {
      status: null,
      type: LockType.Engine_Hood,
    },
    {
      status: null,
      type: LockType.Hatch,
    },
    {
      status: null,
      type: LockType.Overrall_Lock,
    },
  ],
  maintenance: {
    timeToMaintenance: 0,
    distanceToMaintenance: 0,
  },
  fuelAutonomy: null,
  lastUpdatedTime: '2019-08-01T20:15:15.000Z',
};

const initState: VehicleDetailsState = {
  vehicleHealth: {
    data: vehicleHealthInitialState,
    initial: true,
  },
  vehicleHealthReports: [] as HealthReport[],
  vehicle: {
    vin: '',
    vehicleId: '',
    telematicsProgramId: '',
    activeServices: [],
    services: [],
    registrationCountry: '',
    active: false,
    make: '',
    model: '',
    nickname: '',
    year: '',
    ownershipType: OwnershipType.New,
    additionalFeatures: {},
  },
};

export default (state: VehicleDetailsState = initState, action: VehicleDetailsAction) => {
  switch (action.type) {
    case VEHICLE_ACTIONS.SET_VEHICLE_DATA:
      const vehicleData = action.data || state.vehicle;
      return {
        ...state,
        vehicle: vehicleData,
      };

    case VEHICLE_ACTIONS.SET_VEHICLE_HEALTH:
      return {
        ...state,
        vehicleHealth: action.data,
      };

    case VEHICLE_ACTIONS.SET_VEHICLE_HEALTH_REPORTS:
      return {
        ...state,
        vehicleHealthReports: action.data,
      };

    default:
      return state;
  }
};
