import React from 'react';
import { Container, Heading, Link, Title } from '../../styled-components/globalStyles';
import { UserProfileWrapper } from '@manageSubscription/UserProfile/userProfileStyles';
import Button from '../../components/Button';
import { PhoneInput } from '@lib-components/PhoneNumberInput';
import FormikField from '../../components/FormikField';
import Select from '../../components/Select';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { CommonWebContent, UserProfileWebContent } from '..';
import { CommonFormAnalyticsWrapper, withFormAnalytics } from '@lib-components/Analytics';
import FlowSmsCheckbox from '@components/SmsCheckbox/FlowSmsCheckbox';

export type UserProfileYupShapeType = {
  firstName: Yup.StringSchema;
  lastName: Yup.StringSchema;
  password?: Yup.StringSchema;
  confirmPassword?: Yup.StringSchema;
  pin?: Yup.StringSchema;
  question?: Yup.StringSchema;
  answer?: Yup.StringSchema;
};

interface UserProfileProps {
  handleSubmit: () => void;
  initialValues: any;
  firstNameExisted: boolean;
  lastNameExisted: boolean;
  isPinSet: boolean;
  isSecurityQuestionsSet: boolean;
  passwordExists: boolean;
  needPhone: boolean;
  phoneLabel: string;
  phoneTypeLabel?: string;
  phoneTypeOptions: { label: string; value: string }[];
  yupShape: UserProfileYupShapeType;
  userProfileWebContent: UserProfileWebContent;
  commonWebContent: CommonWebContent;
  navigateBack?: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
  handleSubmit,
  initialValues,
  firstNameExisted,
  lastNameExisted,
  isPinSet,
  isSecurityQuestionsSet,
  passwordExists,
  needPhone,
  phoneLabel,
  phoneTypeLabel = '',
  phoneTypeOptions,
  yupShape,
  userProfileWebContent: {
    userProfileHeader,
    userProfileTitle,
    firstNameInputLabel,
    firstNamePlaceholder,
    lastNameInputLabel,
    lastNamePlaceholder,
    emailInputLabel,
    passwordInputLabel,
    passwordPlaceholder,
    confirmPasswordInputLabel,
    confirmPasswordPlaceholder,
    pinInputLabel,
    pinPlaceholder,
    sqLabel,
    sqlPlaceholder,
    secretQuestionsList,
  },
  commonWebContent: { saveAndContinueButtonLabel, backButtonLabel },
  navigateBack,
}) => {
  return (
    <Container>
      <Heading>{userProfileHeader}</Heading>
      <Title>{userProfileTitle}</Title>
      <UserProfileWrapper>
        <CommonFormAnalyticsWrapper name="User Profile">
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(yupShape)}
            onSubmit={handleSubmit}
            validateOnMount
            initialTouched={{
              firstName: true,
              lastName: true,
              ...(!passwordExists && {
                password: true,
                confirmPassword: true,
              }),
              ...(!(isPinSet && isSecurityQuestionsSet) && {
                pin: true,
                question: true,
                answer: true,
              }),
            }}
          >
            {(props: FormikProps<typeof initialValues>) => (
              <Form>
                <FormikField
                  name="firstName"
                  required
                  label={firstNameInputLabel}
                  placeholder={firstNamePlaceholder}
                  data-testid="firstName"
                  disabled={firstNameExisted}
                  readOnly={firstNameExisted}
                />
                <FormikField
                  name="lastName"
                  required
                  label={lastNameInputLabel}
                  placeholder={lastNamePlaceholder}
                  data-testid="lastName"
                  disabled={lastNameExisted}
                  readOnly={lastNameExisted}
                />
                <FormikField name="email" data-testid="email" disabled label={emailInputLabel} />
                {needPhone && (
                  <>
                    <FormikField id="primary-phone" name="primaryPhone" label={phoneLabel} Component={PhoneInput} />
                    <FlowSmsCheckbox />
                    <FormikField
                      id="primary-phone-type"
                      name="primaryPhoneType"
                      label={phoneTypeLabel}
                      Component={Select}
                      options={phoneTypeOptions}
                    />
                  </>
                )}

                {!passwordExists && (
                  <>
                    <FormikField
                      name="password"
                      required
                      type="password"
                      label={passwordInputLabel}
                      placeholder={passwordPlaceholder}
                      data-testid="password"
                    />
                    <FormikField
                      name="confirmPassword"
                      required
                      type="password"
                      label={confirmPasswordInputLabel}
                      placeholder={confirmPasswordPlaceholder}
                      data-testid="confirmPassword"
                    />
                  </>
                )}

                {!(isPinSet && isSecurityQuestionsSet) && (
                  <>
                    <FormikField
                      name="pin"
                      data-testid="pin"
                      required
                      label={pinInputLabel}
                      placeholder={pinPlaceholder}
                    />
                    <FormikField
                      id="security-question"
                      name="question"
                      data-testid="question"
                      required
                      label={sqLabel}
                      placeholder={sqlPlaceholder}
                      Component={Select}
                      options={secretQuestionsList.map((question) => ({
                        label: question,
                        value: question,
                      }))}
                    />
                    <FormikField name="answer" data-testid="answer" required placeholder="Security Question Answer" />
                  </>
                )}

                <Button
                  sx={{ mt: 2 }}
                  fullWidth
                  data-testid="submit-button"
                  disabled={!(props.isValid && !props.isSubmitting)}
                  onClick={props.submitForm}
                  type="submit"
                  data-analyticseventname="CompleteProfile::SaveAndContinue::Button-Clicked"
                >
                  {saveAndContinueButtonLabel}
                </Button>
                {navigateBack && (
                  <Link
                    data-testid="navigate-back-link"
                    onClick={navigateBack}
                    data-analyticseventname="CompleteProfile::Back::Link-Clicked"
                  >
                    {backButtonLabel}
                  </Link>
                )}
              </Form>
            )}
          </Formik>
        </CommonFormAnalyticsWrapper>
      </UserProfileWrapper>
    </Container>
  );
};

export default UserProfile;
