import set from 'lodash/set';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { ResetPinPayload, ResetPinByKBAPayload, ResetPinRequest } from '@cv/portal-idm-lib/pin/models';
import { resetPin } from '@cv/portal-idm-lib/pin';

type PinCommon = {
  userId: string;
  accessToken: string;
  locale: string;
};

type PinUpdate = PinCommon & {
  data: ResetPinPayload;
};

type PinReset = PinCommon & {
  data: ResetPinByKBAPayload;
};

export default class PinService {
  private payload: Omit<ResetPinRequest, 'data'>;

  constructor(environment = Environment.LOCAL) {
    this.payload = {
      environment,
      pathParams: {
        id: '',
      },
      headers: {
        Authorization: 'token',
        'If-Match': '*',
      },
    };
  }

  pinUpdate({ data, userId, accessToken, locale }: PinUpdate) {
    return this.resetPin({ data, userId, accessToken, locale });
  }

  pinReset({ data, userId, accessToken, locale }: PinReset) {
    return this.resetPin({ data, userId, accessToken, locale });
  }

  private resetPin({ data, userId, accessToken, locale }: PinUpdate | PinReset) {
    const { payload } = this;
    set(payload, 'data', data);
    set(payload, 'pathParams.id', userId);
    set(payload, 'headers.Authorization', accessToken);
    set(payload, 'headers.Accept-Language', locale);
    return resetPin(payload as ResetPinRequest);
  }
}
