import { styled, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';

const sidePadding = '30px';

export const ModalContainer = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 44px ${sidePadding} 30px !important;
  max-height: calc(100vh - 56px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 200px;
  position: relative;
`;
export const Icon = styled('img')`
  width: 50px;
  height: 50px;
  margin-bottom: 24px;
`;
export const Title = styled(DialogTitle)`
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;
export const CloseButton = styled('button')`
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 20px;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;
export const Body = styled(DialogContentText)`
  overflow-y: auto;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const BtnWrapper = styled(DialogActions)`
  margin-top: 20px;
  padding: 0;
`;
