// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._LYVFdzJ_EpcNCN_5Myp {
  min-width: 350px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  justify-items: center;
  align-items: center;
  row-gap: 10px;
}
._LYVFdzJ_EpcNCN_5Myp h3 {
  font-weight: bold;
}
._LYVFdzJ_EpcNCN_5Myp .DdcyreZIQBhXyVpenFiX {
  border-top: 2px solid gray;
  grid-column: 1 / 5;
}
.yER69_L2W9FqZfAHUt7C {
  text-decoration: underline;
  cursor: pointer;
}

._5k9iEh0u6EuydVhwohrV {
  display: flex;
  flex-direction: row;
  gap: 11px;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/InvoicesList/InvoicesList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,oCAAoC;EACpC,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,uBAAuB;AACzB","sourcesContent":[".styledTable {\n  min-width: 350px;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1.5fr;\n  justify-items: center;\n  align-items: center;\n  row-gap: 10px;\n}\n.styledTable h3 {\n  font-weight: bold;\n}\n.styledTable .rowBorder {\n  border-top: 2px solid gray;\n  grid-column: 1 / 5;\n}\n.pointer {\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.actions {\n  display: flex;\n  flex-direction: row;\n  gap: 11px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styledTable": `_LYVFdzJ_EpcNCN_5Myp`,
	"rowBorder": `DdcyreZIQBhXyVpenFiX`,
	"pointer": `yER69_L2W9FqZfAHUt7C`,
	"actions": `_5k9iEh0u6EuydVhwohrV`
};
export default ___CSS_LOADER_EXPORT___;
