import { styled } from '@mui/material';

import Button from '../../components/Button';
import Image from '../../components/Image/Image';
import { Content } from '../../styled-components/globalStyles';

export const AddOnDescription = styled(Content)`
  margin-bottom: 0.4rem;
`;

export const SubContent = styled(Content)`
  margin-bottom: 0.4rem;
`;

export const SaveAddOnsButton = styled(Button)`
  margin-top: 3.5rem;
`;

export const ProductSection = styled('div')`
  margin: 0 -1rem;
`;

export const ExpirationImage = styled(Image)`
  width: 30%;
  max-width: 300px;
  margin: auto;
  padding: 0 1rem 1rem 1rem;
`;
