const parseValue = (value: string): string | boolean => {
  if (['true', 'false'].includes(value)) {
    return JSON.parse(value) as boolean;
  } else if (value === '') {
    // we assume that it uses like `?hideNavigation`, what means a boolean.
    return true;
  }

  return value;
};

const cache = {} as Record<string, unknown>;
export const getSearchParam = <T extends unknown>(search: string, paramToTake: string): T => {
  const key = search + paramToTake;
  if (!cache[key]) {
    const searchParams = new URLSearchParams(search);
    const value = searchParams.get(paramToTake);
    if (value !== null) {
      cache[key] = parseValue(value);
    }
  }

  return cache[key] as T;
};

export function getRawParamValue(paramName: string) {
  // This is used when we do not want to have the URL parameters decoded
  if (!paramName) return null;
  const paramSearchArray = window.location.search.split(paramName + '=');
  return paramSearchArray[1] ? paramSearchArray[1].split('&')[0] : null;
}
