import { RRS_ACTIONS, RRSData } from '../actions';

type RapidRegistrationState = {
  data: RRSData;
};

type RapidRegistrationAction = {
  type: RRS_ACTIONS;
  data: RRSData;
};

export default (state: RapidRegistrationState = { data: {} }, action: RapidRegistrationAction) => {
  switch (action.type) {
    case RRS_ACTIONS.SET_RRS_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
