import { ComponentRoutes } from '../types';
import { CompoundState } from './componentFlow/Types';
import { Middleware } from '../flowMiddleware';

const getCompoundState = (middleware: Middleware) =>
  ({
    initial: ComponentRoutes.payment,
    states: {
      [ComponentRoutes.payment]: {
        ...middleware.getComponentFlow(ComponentRoutes.payment),
      },
    },
  } as CompoundState);

export default {
  default: getCompoundState,
};
