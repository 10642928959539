import React, { forwardRef } from 'react';
import {
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Alert,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
import { isAnnualTerm, isPaidPackage } from '@utils/subscriptionUtils';
import { EventDataBuilder, EventType, cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import { useCancelLabels } from '@components/Cancellation/CancelLabelsContext';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import NavigationProp from '@customTypes/NavigationProp';

import StepScreenHeader from '@components/Cancellation/StepScreenHeader';
import PreviewOrderList from '../PreviewOrderList';
import { useIsMutating } from '@tanstack/react-query';

type Props = {
  onCancelReasonChange: (reason: string) => void;
  onContinue: () => void;
} & NavigationProp;

const CancelPackages = forwardRef(({ onCancelReasonChange, Navigation, onContinue }: Props, _ref) => {
  const { selectedPackages, cancelReason, step } = useCancelMachineContext();

  const {
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    cancellationReasons,
    cancelPackagesHeader,
    reasonForCanceling,
    reasonForCancellingDescription,
    reasonForCancellingPostScript,
    reasonLabel,
    continueButton,
  } = useCancelLabels();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: cancelPackagesHeader,
    subsection: 'Cancelation > Cancel Packages',
  });

  const orderIsLoading = useIsMutating({ mutationKey: ['createOrder'] });

  const isCancellingMonthlyPackages = selectedPackages?.some((sPkg) => isPaidPackage(sPkg) && !isAnnualTerm(sPkg));

  const cancelReasons = Object.keys(cancellationReasons).map((key) => ({
    value: key,
    label: cancellationReasons[key],
  }));

  return (
    <Stack spacing={4} component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <StepScreenHeader>{cancelPackagesHeader}</StepScreenHeader>

      <PreviewOrderList />

      <Stack>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {reasonForCanceling}
        </Typography>
        <Typography>{reasonForCancellingDescription}</Typography>
        <FormControl
          sx={(theme) => ({
            mt: theme.spacing(1),
            mb: theme.spacing(1),

            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '30%',
            },
          })}
          size="small"
        >
          <InputLabel id="reason-label">{reasonLabel}</InputLabel>
          <Select
            labelId="reason-label"
            id="reason"
            value={cancelReason}
            label="Reason"
            onChange={(e) => {
              onCancelReasonChange(e.target.value);
            }}
          >
            {cancelReasons.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
      </Stack>

      <Typography>{reasonForCancellingPostScript}</Typography>
      <Box>
        <AnalyticsButton
          startIcon={orderIsLoading ? <CircularProgress color="secondary" size={20} /> : null}
          sx={(theme) => ({
            width: '100%',
            mb: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              mr: theme.spacing(1),
              mb: 0,
              width: 'auto',
            },
          })}
          disabled={!!orderIsLoading}
          onClick={onContinue}
          analyticsEvent={new EventDataBuilder(EventType.CancelSubscription).withArgs({ cancelReason })}
        >
          {continueButton}
        </AnalyticsButton>
        {Navigation && <Navigation />}
      </Box>
    </Stack>
  );
});

export default CancelPackages;
