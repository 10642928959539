import React from 'react';

import AnalyticsTrackView from '@components/Analytics';
import ModalContainer from '@components/ModalContainer';
import { formatAnalyticsEventName } from '@cv/portal-common-lib/utils';
import usePreferencesSelector from '@redux/selectors/preferences';
import { Button } from '@components-lib';

import styles from './ErrorModal.module.css';
import clsx from 'clsx';

interface ErrorModalProps {
  analyticsEventName?: string;
  errorMessage?: string;
  okLabel?: string;
  cancelLabel?: string;
  buttonOrientation?: 'horizontal' | 'vertical';
  onClose?: () => void;
  onCancel?: () => void;
  onOk: () => void;
}

const ErrorModal = ({
  analyticsEventName,
  errorMessage,
  onClose,
  onCancel,
  onOk,
  okLabel,
  cancelLabel,
  buttonOrientation = 'horizontal',
}: ErrorModalProps) => {
  const preferences = usePreferencesSelector();

  return (
    <AnalyticsTrackView analyticsEventName={formatAnalyticsEventName(analyticsEventName)}>
      <ModalContainer
        show
        header={{ text: '', position: 'center', showXButton: false }}
        onCloseHandler={onClose}
        position="center"
        height="auto"
      >
        {preferences.errorHeaderLabel && (
          <div className={styles['ModalHeader']}>
            <span>{preferences.errorHeaderLabel}</span>
          </div>
        )}
        <div className={styles['ModalContent']}>
          <p>{errorMessage}</p>
        </div>
        <div className={clsx(styles['Actions'], styles[buttonOrientation])}>
          <Button variant="contained" className={styles['Button']} onClick={onOk}>
            {okLabel || preferences.ok}
          </Button>
          {onCancel && (
            <Button variant="contained" className={styles['Button']} onClick={onCancel}>
              {cancelLabel || preferences.cancelButtonLabel}
            </Button>
          )}
        </div>
      </ModalContainer>
    </AnalyticsTrackView>
  );
};

export default ErrorModal;
