import React from 'react';
import { useSelector } from 'react-redux';

import { ContentfulFile } from '@customTypes/ContentfulComponent';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';

import styles from '../services.module.css';

interface SecurityProps {
  header: string;
  title: string;
  description: string;
  image: ContentfulFile;
  tag: string[];
}

const Security = ({ header, title, description, image, tag }: SecurityProps) => {
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  if (!isServiceSubscribed(tag, vehicle?.activeServices)) {
    return null;
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ServiceContainer}>
        <h1 className={styles.ServiceHeader}>{header}</h1>
        <div className={styles.ServiceContent}>
          <img className={styles.ServiceImage} src={image.file.url} />
          <div>
            <div className={styles.ServiceTitle}>{title}</div>
            <div className={styles.ServiceDescription}>{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
