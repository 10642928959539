import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { EligiblePackageInfo, PackageSubscription } from '../Types';
import { Container } from '@styled-components/globalStyles';
import { ExpirationImage } from './styles';
import { PromoPackages } from '../Packages';
import { LegalContainer } from '../Legal';
import { hasPackageAdded } from '../utils';
import { DiscountContent, DiscountExpiryHeader, DiscountExpirySection } from '../Packages/DiscountPackage/styles';
import { CommonWebContent, PromoPackagesWebContent, TrialPackagesWebContent } from '..';
import { PromoPackagesProps } from '../Packages/DiscountPackage/PromoDiscount/PromoPackages';
import PackageSection from '@manageSubscription/Packages/PaidPackage/PackageSection';
import Button from '@app/components-lib/components/Button';

export type ManagePromoPackageListProps = {
  promoPackages: EligiblePackageInfo[];
  commonWebContent: CommonWebContent;
  promoPackagesWebContent: PromoPackagesWebContent;
  trialPackagesWebContent: TrialPackagesWebContent;
  isTrialEligible?: boolean;
  canShowBackButton: boolean;
  packageSubscriptions: PackageSubscription[];
  navigate: (isSelected: boolean) => void;
  navigateBack?: () => void;
  disable?: boolean;
  maxSelectedPackages?: number;
} & PromoPackagesProps;

const ManagePromoPackageList = ({
  promoPackages,
  commonWebContent,
  promoPackagesWebContent,
  trialPackagesWebContent,
  isTrialEligible,
  canShowBackButton,
  packageSubscriptions,
  navigate,
  disable,
  navigateBack,
  ...promoPackagesProps
}: ManagePromoPackageListProps) => {
  const { backButtonLabel, continueButtonLabel, saveAndContinueButtonLabel, skipAndContinueButtonLabel } =
    commonWebContent;
  const { header, description, legalText, expiryHeader, expiryDescription } = promoPackagesWebContent;
  const { trialEligibleHeader, trialEligibleSubheader, packageSelectionTrialTermsDisclaimer } = trialPackagesWebContent;

  const promoHeader = isTrialEligible ? trialEligibleHeader : header;
  const subHeader = isTrialEligible ? trialEligibleSubheader : description;

  const isPromoPackageSelected = useMemo(
    () => promoPackages?.some((pkg) => hasPackageAdded(packageSubscriptions, pkg)),
    [promoPackages, packageSubscriptions],
  );

  const legal =
    isTrialEligible && packageSelectionTrialTermsDisclaimer ? packageSelectionTrialTermsDisclaimer : legalText;

  return (
    <Container>
      {promoPackages?.length > 0 ? (
        <PackageSection header={promoHeader} subHeader={subHeader}>
          <PromoPackages
            promoPackages={promoPackages}
            commonWebContent={commonWebContent}
            promoPackagesWebContent={promoPackagesWebContent}
            packageSubscriptions={packageSubscriptions}
            {...promoPackagesProps}
          />
        </PackageSection>
      ) : (
        <DiscountExpirySection>
          <DiscountExpiryHeader>{expiryHeader}</DiscountExpiryHeader>
          <DiscountContent>
            <ExpirationImage assets={promoPackagesProps.assets} name="Expiration" />
            <span>{expiryDescription}</span>
          </DiscountContent>
        </DiscountExpirySection>
      )}
      <Stack alignItems="flex-end" mt="3rem">
        <Stack gap="1rem" sx={(theme) => ({ width: '100%', [theme.breakpoints.up('lg')]: { maxWidth: '15rem' } })}>
          <Button
            onClick={() => navigate(isPromoPackageSelected)}
            disabled={disable && !isPromoPackageSelected}
            fullWidth
          >
            {promoPackages?.length > 0
              ? isPromoPackageSelected
                ? saveAndContinueButtonLabel
                : skipAndContinueButtonLabel
              : continueButtonLabel}
          </Button>
          {canShowBackButton && (
            <Button variant="outlined" onClick={navigateBack} fullWidth>
              {backButtonLabel}
            </Button>
          )}
        </Stack>
      </Stack>
      <LegalContainer legalText={legal} showCheckbox={false} />
    </Container>
  );
};

export default ManagePromoPackageList;
