// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wqd8ld70RhY5FF0TKFao {
  min-height: 244px;
}

.wMYfC5J03qEN7Y9hoYpm {
  width: 135px;
}

.NnumqU_ETIOkqVePAb7W {
  width: 100%;
}

.WWwoxBUFqHunPNbA6ZFK {
  margin-left: 1rem;
}

.NbTBA90nstODlpYwV7Cj {
  margin-top: 0.5rem;
  text-align: center;
}

.qIFHcHFo3WIifLZso6Ui {
  margin-top: 2rem;
  display: flex;
}

.qbfCPDJdyhJyFjkzRhIW {
  margin-top: 1.5rem;
}

.TuWoUeBS1ULZRF0hPJcK {
  width: 100%;
  margin-top: 1rem;
}

h2.lMwGgcE40mkRapCP69Jx {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 1rem;
}

.xGRu97wI3J96J5V6tRde {
  margin-bottom: 1rem;
}

.EvF_zt4b67LWpka66qjd {
  margin-bottom: 1rem;
}

.Cd92BgGrAjn4c3BDkYuS {
  cursor: pointer;
  border-width: 0px;
  padding: 0px;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionCard/Calendar/Calendar.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".calendarBlock {\n  min-height: 244px;\n}\n\n.calendarButton {\n  width: 135px;\n}\n\n.calendarDialogButton {\n  width: 100%;\n}\n\n.calendarDialogButtonSelect {\n  margin-left: 1rem;\n}\n\n.calendarDialogDescription {\n  margin-top: 0.5rem;\n  text-align: center;\n}\n\n.calendarDialogButtons {\n  margin-top: 2rem;\n  display: flex;\n}\n\n.setupButton {\n  margin-top: 1.5rem;\n}\n\n.optionCalendarButton {\n  width: 100%;\n  margin-top: 1rem;\n}\n\nh2.calendarHeader {\n  font-size: 1.2rem;\n  color: white;\n  margin-bottom: 1rem;\n}\n\n.calendarName {\n  margin-bottom: 1rem;\n}\n\n.updateButton {\n  margin-bottom: 1rem;\n}\n\n.calendarTopRightIcon {\n  cursor: pointer;\n  border-width: 0px;\n  padding: 0px;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarBlock": `wqd8ld70RhY5FF0TKFao`,
	"calendarButton": `wMYfC5J03qEN7Y9hoYpm`,
	"calendarDialogButton": `NnumqU_ETIOkqVePAb7W`,
	"calendarDialogButtonSelect": `WWwoxBUFqHunPNbA6ZFK`,
	"calendarDialogDescription": `NbTBA90nstODlpYwV7Cj`,
	"calendarDialogButtons": `qIFHcHFo3WIifLZso6Ui`,
	"setupButton": `qbfCPDJdyhJyFjkzRhIW`,
	"optionCalendarButton": `TuWoUeBS1ULZRF0hPJcK`,
	"calendarHeader": `lMwGgcE40mkRapCP69Jx`,
	"calendarName": `xGRu97wI3J96J5V6tRde`,
	"updateButton": `EvF_zt4b67LWpka66qjd`,
	"calendarTopRightIcon": `Cd92BgGrAjn4c3BDkYuS`
};
export default ___CSS_LOADER_EXPORT___;
