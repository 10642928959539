import config from '@config/config';

const getClientId = (platform: string) => {
  if (platform === 'ios') {
    return config.getOemValue('IOS_CLIENT_ID');
  } else if (platform === 'android') {
    return config.getOemValue('ANDROID_CLIENT_ID');
  } else {
    return config.getOemValue('FALLBACK_CLIENT_ID');
  }
};

export default getClientId;
