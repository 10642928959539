import React, { useMemo, useCallback } from 'react';
import { FormikProps } from 'formik';
import { ListAddressesResponse } from '@cv/portal-cps-lib/utility/utility-services/models';
import { useFlowMachineContext } from '@lib-appRouter/RouterContext';
import { FormValuesMultiBrand } from '@lib-appRouter/flows/componentFlow/Types';
import { formatAddress, getListAddresses } from '@lib-services/cps/utility';
import { stateSelector } from '@manageSubscription/utils/addressFormUtils';
import { countriesOptions } from '@manageSubscription/utils/countries';
import FormikAddressField, { AddressType } from '@lib-components/FormikAddressField';

export type AddressFieldProps = {
  addressType: AddressType;
  props: FormikProps<FormValuesMultiBrand>;
};

const AddressField = ({ addressType, props }: AddressFieldProps) => {
  const { subscriptionProps, content } = useFlowMachineContext();
  const { vehicleDetails, locale } = subscriptionProps;
  const { registrationCountry } = vehicleDetails;

  const stateOptions = useMemo(() => {
    const states = stateSelector(locale, content.mexicoStates);
    return states.map((state) => ({
      label: state.name,
      value: state.abbreviation,
    }));
  }, [locale]);

  const loadOptions = useCallback(
    (inputText: string) =>
      getListAddresses(
        {
          isoCountryCode: registrationCountry,
          query: inputText,
        },
        subscriptionProps,
      )
        .then(({ data = [] }) => data)
        .catch(() => []),
    [registrationCountry, subscriptionProps],
  );

  const formatOption = useCallback(
    (address: ListAddressesResponse, isoCountryCode: string) =>
      formatAddress({ id: address.id, isoCountryCode }, subscriptionProps).then(({ data }) => data),
    [subscriptionProps],
  );

  const name = `${addressType}AddressField`;

  return (
    <FormikAddressField
      addressType={addressType}
      name={name}
      content={content}
      registrationCountry={registrationCountry}
      states={stateOptions}
      countries={countriesOptions}
      loadOptions={loadOptions}
      formatOption={formatOption}
      {...props}
    />
  );
};

export default AddressField;
