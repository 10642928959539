import React from 'react';
import { Container, Heading, Link } from '../../styled-components/globalStyles';
import { PackagesLegal } from '../Legal';
import { AddOnPackages } from '../Packages';
import { EligiblePackageInfo } from '../Types';
import { AddOnDescription, SaveAddOnsButton } from './styles';

interface ManageAddOnsContainer {
  addOnHeaderLabel: string;
  addOnDescription: string;
  continueButtonLabel: string;
  backButtonLabel: string;
  eligibleAddOns: EligiblePackageInfo[];
  navigateForward: () => void;
  navigateBack: () => void;
}

const ManageAddOns: React.FC<ManageAddOnsContainer> = ({
  addOnHeaderLabel,
  addOnDescription,
  continueButtonLabel,
  backButtonLabel,
  eligibleAddOns,
  navigateForward,
  navigateBack,
}) => {
  return (
    <Container data-testid="manage-addOns-component">
      <Heading>{addOnHeaderLabel}</Heading>
      <AddOnDescription>{addOnDescription}</AddOnDescription>

      <AddOnPackages packages={eligibleAddOns} />

      <SaveAddOnsButton data-testid="addOn-continue-button" onClick={navigateForward}>
        {continueButtonLabel}
      </SaveAddOnsButton>
      <Link data-testid="addOn-back-link" onClick={navigateBack}>
        {backButtonLabel}
      </Link>

      <PackagesLegal />
    </Container>
  );
};

export default ManageAddOns;
