import React from 'react';
import { Button } from '@components-lib';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { ProductList } from './ProductList';

import styles from './CancelSubscription.module.css';

type CancelSubscriptionDetailsProps = {
  handleGoBack: () => void;
  labels: {
    [index: string]: { primary: string; analyticsEventName?: string };
  };
  packages: Array<SubscribedPackage>;
  products: Array<string>;
};

export const CancelSubscriptionDetails = ({
  handleGoBack,
  labels,
  packages,
  products,
}: CancelSubscriptionDetailsProps) => {
  const { detailsTitle, goBackLabel } = labels;

  const renderPackages = () => {
    if (packages.length) {
      // Checking most expensive package
      const costlyPackage = packages.reduce((expensivePackage, Package) =>
        +expensivePackage.amountWithoutTax > +Package.amountWithoutTax ? expensivePackage : Package,
      );
      return (
        <h3 data-testid={'package-description'} className={styles['package-description']}>
          {costlyPackage.longDescription}
        </h3>
      );
    }

    return null;
  };

  return (
    <div className={styles['container']}>
      <h1 className="title">{detailsTitle.primary}</h1>

      <div className={styles['package-container']} style={{ marginTop: 10, marginBottom: 10 }}>
        {renderPackages()}
        <ProductList products={products} maxProductsToDisplay={products.length} />
      </div>

      <Button className="cancel-button" onClick={handleGoBack}>
        {goBackLabel.primary}
      </Button>
    </div>
  );
};
