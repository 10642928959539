import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@api';
import { useAnalytics } from '@cv/webframework-react-components';
import milliseconds from 'date-fns/milliseconds';

import { Tab, TabItem } from './TempDestinationTab';
import MapWindowJourneyOverlay from './MapWindowJourneyOverlay';
import MapWindowDestinationsOverlay from './MapWindowDestinationsOverlay';
import { SET_FAVORITES, SET_SENT_TO_CAR, SET_JOURNEYS } from './constants';

import { SavedLocation } from './types';

import './MapWindow.css';
import { DestinationsResponse } from '@cv/portal-rts-lib/v0/information/destinations';

type MapWindowDestinationsProps = {
  labels: {
    AddJourneyButton: string;
    AddWaypointButton: string;
    DestinationsBodyText: string;
    DestinationsTab: string;
    DestinationsTitle: string;
    FavoritesTab: string;
    FinalRoute: string;
    InputPlaceholder: string;
    JourneyBodyText: string;
    JourneyTab: string;
    JourneyTitle: string;
    NameJourneyButton: string;
    NameJourneyText: string;
    ReverseRouteButton: string;
    RouteSummary: string;
    SelectWaypointTitle: string;
    SendToCarButton: string;
    SentToCarTab: string;
    StartOverButton: string;
    ViewRouteButton: string;
    JourneyInputPlaceholder: string;
    JourneyNameInputPlaceholder: string;
    JourneyModalP1: string;
    JourneyModalP2Nissan: string;
    JourneyModalP2Infiniti: string;
    JourneyModalP3Infiniti: string;
    JourneyModalP3Nissan: string;
    JourneyModalP4: string;
    JourneyModalLi1: string;
    JourneyModalLi2: string;
    JourneyModalLi3: string;
    JourneyModalLi4: string;
    JourneyModalHeader: string;
    SentToCarModalTitle: string;
    SentToCarModalBody: string;
    JourneyStartingPointText: string;
    RouteSummaryStartLabel: string;
    RouteSummaryEndLabel: string;
    PoiSearchErrorBody: string;
    PoiSearchErrorTitle: string;
    MaxJourneys?: number;
    PoiSearchErrorLine1: string;
    PoiSearchErrorLine2: string;
    JourneySearchErrorLine1: string;
    JourneySearchErrorLine2: string;
  };
  setDirections: () => void;
  resetMap: () => void;
  vehicleLocationPinIcon: string;
};

function MapWindowDestinations({
  setDirections,
  labels,
  resetMap,
  vehicleLocationPinIcon,
}: MapWindowDestinationsProps) {
 
  const api = useApi();
  const { trackEvent } = useAnalytics();
  const [isLoadingJourneys, setLoadingJourneys] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);

  const MAX_CACHE_AGE = milliseconds({ minutes: 10 });
  const checkForQueryParams = () => {
    const params = location.search;
    if (params) {
      const parsedParams = new URLSearchParams(params);
      const formattedTab = (queryParam: string) => {
        const tabs = {
          destinations: locale === 'es-MX' ? 'Destinos' : 'Destinations',
          journeyPlanner: locale === 'es-MX' ? 'Planificador de viaje' : 'Journey Planner',
        };
        if (queryParam === 'JourneyPlanner') {
          return tabs.journeyPlanner;
        }

        return tabs.destinations;
      };
      const selectedTab = parsedParams.get('selectedTab');
      if (selectedTab) {
        return formattedTab(selectedTab);
      }
    } else {
      const defaultTab = locale === 'es-MX' ? 'Destinos' : 'Destinations';
      return defaultTab;
    }
  };

  const [selectedTab] = useState(checkForQueryParams());

  useEffect(() => {
    getFolders();
  }, []);

  
  const getFavorites = async (folderId: string, favorites: Array<SavedLocation>) => {
    if (favorites.length) {
      const ids = favorites.map((fav) => fav.channelId);
      const favoritesArray: DestinationsResponse[] = [];

      for (const id of ids) {
        try {
          const { data } = await api.getDestinations(folderId, id);
          data.forEach((destination) => {
            favoritesArray.push(destination);
          });
        } catch (err) {
          // TODO - IMPLEMENT ERROR HANDLING
          console.log(err);
        }
      }
      dispatch({ type: SET_FAVORITES, favorites: favoritesArray });
    }
  };

  const getSentToCar = async (folderId: string, sentToCar: Array<SavedLocation>) => {
    if (sentToCar.length) {
      const ids = sentToCar.map((item) => item.channelId);
      const sentToCarArray: DestinationsResponse[] = [];

      for (const id of ids) {
        try {
          const { data } = await api.getDestinations(folderId, id);
          data.forEach((destination) => {
            sentToCarArray.push(destination);
          });
        } catch (err) {
          // TODO - IMPLEMENT ERROR HANDLING
          console.log(err);
        }
      }
      dispatch({ type: SET_SENT_TO_CAR, sentToCar: sentToCarArray });
    }
  };

  const getJourneys = async (folderId: string, channelIds: string) => {
    const ids = channelIds.split(',');
    const journeysArray = [];
    setLoadingJourneys(true);
    for (const id of ids) {
      try {
        const { data: journey } = await api.getJourney(folderId, id);
        journeysArray.push(...journey);
      } catch (err) {
        console.log('ERROR', err);
      }
    }
    setLoadingJourneys(false);
    dispatch({ type: SET_JOURNEYS, journeys: journeysArray });
  };

  const filterFolder = (folders: Array<any>) => {
    // Format folder response
    return folders.reduce((newObject, folder) => {
      switch (folder.folderName) {
        case 'MY DESTINATIONS':
          newObject.myDestinations = { ...folder };
          break;
        case 'Journey Planner':
          newObject.journeys = { ...folder };
          break;
        case 'Favorites':
          newObject.favorites = { ...folder };
          break;
        case 'Recents':
          newObject.savedToCar = { ...folder };
          break;
      }

      return newObject;
    }, {});
  };

  const getDestinationItems = (...args: Array<object>) => {
    args.forEach((dataType: any) => {
      switch (dataType?.folderName) {
        case 'MY DESTINATIONS':
          const { channels } = dataType;
          const { folderId } = dataType;
          const favorites = channels.filter((channel) => channel.channelName === 'FAVORITES');
          const sentToCar = channels.filter((channel) => channel.channelName === 'RECENTS');

          getFavorites(folderId, favorites);
          getSentToCar(folderId, sentToCar);
          break;
        case 'Journey Planner':
          if (dataType.channels?.length) {
            const ids = dataType.channels.map((item) => item.channelId).toString();
            getJourneys(dataType.folderId, ids);
          }
          break;
        case 'Favorites':
          if (dataType.channels?.length) {
            getFavorites(dataType.folderId, dataType.channels);
          }
          break;
        case 'Recents':
          if (dataType.channels?.length) {
            getSentToCar(dataType.folderId, dataType.channels);
          }
          break;
      }
    });
  };

  const getFolders = async () => {
    // Fetch folders for vehicle
    const { data: folders } = await api.getFolders();
    dispatch({ type: 'SET_FOLDERS', folders });

    const { myDestinations, journeys, favorites, savedToCar } = filterFolder(folders);

    getDestinationItems(myDestinations, journeys, favorites, savedToCar);

   
  };

  // THIS IS TEMP UNTIL CONVERTING TO NEW IMPLEMENTATION OF TABS
  if (!labels) {
    return null;
  }
  
  const services: Record<string, any> = {
    capableServices: api.getCapableServices.bind(api),
  };


  const { data, isError, error, isFetching } = useQuery({
    queryKey: ["capableServices"],
    queryFn: async () => {
      const { data } = await services["capableServices"]("en-US");
      return data;
    },
    cacheTime: MAX_CACHE_AGE,
  });

  let checkForJourney;

  let both;
  let journey;
  let destination;
  let bothSpanish;
  let journeySpanish;
  let destinationSpanish;
  if(data) {
    checkForJourney = data
    .map((capableServices: { services: any[]; }) => capableServices.services).flat(); 
    both = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Destination Download" && name.vehicleServiceName === "Journey Planner"
    });
    destination = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Destination Download"
    });
    journey = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Journey Planner"
    });
    bothSpanish = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Envio de destino al vehículo" && name.vehicleServiceName === "Planificador de viaje"
    });
    destinationSpanish = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Envio de destino al vehículo"
    });
    journeySpanish = checkForJourney.filter((name: { vehicleServiceName: string; }) => {
      return name.vehicleServiceName === "Planificador de viaje"
    });
  } 
  
  return (
    <div className="map-window-destinations">
      <Tab type="primary" selectedTab={selectedTab} className="TabContainer--max-height">
        <TabItem
          showTab={(both?.length > 0 || destination?.length > 0) || (bothSpanish?.length > 0 || destinationSpanish?.length > 0)}
          data-label={labels.DestinationsTab}         
          onClick={() => {
            trackEvent('ServicesDestinations::Destinations::Tab-Selected');
          }}
        >
          <MapWindowDestinationsOverlay
            labels={labels}
            setDirections={setDirections}
            resetMap={resetMap}
            vehicleLocationPinIcon={vehicleLocationPinIcon}
          />
        </TabItem>  
        <TabItem
          showTab={(both?.length > 0 || journey?.length > 0) || (bothSpanish?.length > 0 || journeySpanish?.length > 0)}
          data-label={labels.JourneyTab}
          onClick={() => {
            trackEvent('ServicesDestinations::JourneyPlanner::Tab-Selected');
          }}
        >
          <MapWindowJourneyOverlay
            isLoading={isLoadingJourneys}
            labels={labels}
            setDirections={setDirections}
            resetMap={resetMap}
          />
        </TabItem>
      </Tab>
    </div>
  );
}

export default MapWindowDestinations;

