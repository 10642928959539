import { PaymentMethodCC, PaymentMethodPaypal } from '@cv/portal-cps-lib/payment/payment-methods/models';
import { SubscriptionProps } from '../..';
import { CONFIG_KEYS } from '@manageSubscription/utils/constants';
import { getStateName, ZuoraPaymentRequestBuilder, ZuoraUserInfoBuilder } from '../components/Payment/zuora';
import { PaymentInfo, PaymentOptions, RSASignature } from '../Types';

export const buildPaymentInfo = (ccPaymentResp: PaymentMethodCC) => {
  const {
    paymentMethodId,
    cardType,
    cardNumber,
    defaultPaymentMethod,
    expirationMonth,
    expirationYear,
    cardHolderInfo,
  } = ccPaymentResp;
  const paymentInfo: PaymentInfo = {
    paymentMethodId,
    cardType,
    cardNumber,
    defaultPaymentMethod,
    expirationMonth,
    expirationYear,
    expiration: `${expirationMonth}/${expirationYear}`,
    cardHolderName: cardHolderInfo.cardHolderName,
    addressLine1: cardHolderInfo.addressLine1,
    addressLine2: cardHolderInfo.addressLine2,
    city: cardHolderInfo.city,
    state: cardHolderInfo.state,
    zipCode: cardHolderInfo.zipCode,
    paymentOption: defaultPaymentMethod ? PaymentOptions.USE_CARD_ON_FILE : PaymentOptions.ADD_NEW_CARD,
  };
  return paymentInfo;
};

export const buildPaypalPaymentInfo = (paypalPaymentResp: PaymentMethodPaypal) => {
  const { paymentMethodId, defaultPaymentMethod } = paypalPaymentResp;
  return {
    paymentMethodId,
    defaultPaymentMethod,
    paymentOption: PaymentOptions.PAYPAL,
  } as PaymentInfo;
};

export const buildZuoraPaymentRequest = (
  subscriptionProps: SubscriptionProps,
  rsaSignature: RSASignature,
  transactionId: string,
  clientIP: string,
  source: string,
) => {
  const { key, pageId, signature, tenantCode, token, url } = rsaSignature;
  const {
    tenantId,
    supportedCreditCards,
    locale,
    userDetails: { billingId, phone, email },
    vehicleDetails: { vin },
    config,
  } = subscriptionProps;

  const sourceChannel = config.get(CONFIG_KEYS.SOURCE_CHANNEL);
  return new ZuoraPaymentRequestBuilder()
    .id(pageId)
    .key(key)
    .url(url)
    .token(token)
    .phone(phone)
    .email(email)
    .locale(locale.substring(0, 2))
    .signature(signature)
    .tenantId(tenantCode)
    .accountId(billingId)
    .deviceSessionId(sourceChannel)
    .supportedTypes(supportedCreditCards)
    .authTId(transactionId)
    .source(source)
    .oem(tenantId)
    .vin(vin)
    .ipAddress(clientIP)
    .build();
};

export const buildZuoraUserInfo = (subscriptionProps: SubscriptionProps) => {
  const {
    userDetails: {
      firstName,
      lastName,
      email,
      phone,
      billingAddress: { address1, address2, city, state, postalCode, country },
    },
  } = subscriptionProps;
  return new ZuoraUserInfoBuilder()
    .cardHolderName(firstName + ' ' + lastName)
    .phone(phone || '')
    .email(email || '')
    .address1(address1)
    .address2(address2 || '')
    .city(city)
    .state(getStateName(country, state))
    .country(country)
    .postalCode(postalCode)
    .build();
};

export const buildPaymentTransactionInfo = (
  props: SubscriptionProps,
  transactionId: string,
  clientIP: string,
  source: string,
) => {
  const {
    tenantId,
    vehicleDetails: { vin },
  } = props;

  return {
    inAuthTId: transactionId,
    source: source,
    ipAddress: clientIP,
    OEM: tenantId,
    VIN: vin,
  };
};
