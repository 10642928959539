import { DEFAULT, DEFAULTBLACK, STATUS } from './constant';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import React from 'react';
import { VehicleImage } from '@components/VehicleImage';
import { useNavigation } from '@components/Navigation';
import { useSelector } from 'react-redux';

type VehicleImageContainerProps = {
  images: Array<data>;
};
type data = {
  image: ContentfulFile;
  name: string;
  tags: string[];
};
export default function VehicleImageContainer({ images }: VehicleImageContainerProps) {
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { currentPath } = useNavigation();
  const location = currentPath.page;
  const vehicleType = `${vehicle?.year}${vehicle?.model?.toUpperCase()}`;
  const renderImage = images.find(({ tags }) => tags?.includes(vehicleType));
  const defaultImage = images.find(({ tags }) => tags?.includes(location === STATUS ? DEFAULTBLACK : DEFAULT));
  const resultingImage = renderImage ? renderImage?.image : defaultImage?.image;
  return resultingImage ? <VehicleImage image={resultingImage} /> : null;
}
