import React from 'react';
import Loader from '@components/Loader';
import { SpinnerBackDrop, SpinnerBox, SpinnerWheel } from './SpinnerStyles';

export enum SPINNER_TYPES {
  TRANSLUCENT = 'translucent',
  OPAQUE = 'opaque',
}

export type SpinnerTypes = SPINNER_TYPES;

export type SpinnerProps = {
  type?: SpinnerTypes;
};

const Spinner = ({ type }: SpinnerProps) => (
  <SpinnerBackDrop>
    <SpinnerBox>
      <Loader />
    </SpinnerBox>
  </SpinnerBackDrop>
);

export default Spinner;
