import React from 'react';

import Dialog from '@components/Dialog';

export type DeclineConfirmationProps = {
  labelDeclineConfirmationTitle: string;
  labelDeclineConfirmationNote: string;
  labelDeclineConfirmationDescription: string;
  labelDeclineConfirmation: string;
  labelDeclineYes: string;
  labelDeclineNo: string;
  onDecline: () => void;
  onContinue: () => void;
};

const DeclineConfirmation = ({
  labelDeclineConfirmationTitle,
  labelDeclineConfirmationNote,
  labelDeclineConfirmationDescription,
  labelDeclineConfirmation,
  labelDeclineYes,
  labelDeclineNo,
  onDecline,
  onContinue,
}: DeclineConfirmationProps) => {
  return (
    <Dialog
      title={labelDeclineConfirmationTitle}
      labelOk={labelDeclineNo}
      labelCancel={labelDeclineYes}
      onOk={onContinue}
      onCancel={onDecline}
    >
      <>
        <p>
          <strong>{labelDeclineConfirmationNote}</strong> {labelDeclineConfirmationDescription}
        </p>
        <p>{labelDeclineConfirmation}</p>
      </>
    </Dialog>
  );
};

export default DeclineConfirmation;
