import { SubscriptionProps } from '@manageSubscription';
import { options } from '../commonService';
import { UserByIdRequest } from '@cv/portal-idm-lib/user/user-Info/models';
import { getUserInfoByID } from '@cv/portal-idm-lib/user/user-Info';
import { SecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions/models';
import { getSecurityQuestions } from '@cv/portal-idm-lib/security-questions';
import { UserUpdateProfile } from '@cv/portal-idm-lib/user/user-update-profile';
import { setupPin } from '@cv/portal-idm-lib/pin';
import { UserUpdatePayload, UserUpdateRequest } from '@cv/portal-idm-lib/user/user-update-profile/models';
import { setupPinAndSecurityQuestions } from '@cv/portal-idm-lib/security-questions-pin';
import { SetupPinAndSecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions-pin/models';
import { UpdateOperation } from '@cv/portal-idm-lib/contact/enums';
import { merge } from 'lodash';

export const getUserInfoByUserID = (props: SubscriptionProps) => {
  const { userDetails } = props;
  const request: UserByIdRequest = {
    ...options(props, {
      headersToOmit: ['cv-tenant-id'],
    }),
    pathParams: {
      _id: userDetails.userId,
    },
  };

  return getUserInfoByID(request).then((response) => response.data);
};

export const getUserSecurityQuestions = (props: SubscriptionProps) => {
  const { userDetails } = props;
  const request: SecurityQuestionsRequest = {
    ...options(props, {
      headersToOmit: ['cv-tenant-id'],
    }),
    pathParams: {
      id: userDetails.userId,
    },
  };

  return getSecurityQuestions(request).then((response) => response.data);
};

export const updateUserProfileById = (props: SubscriptionProps, data: { [key: string]: string }) => {
  const { userDetails } = props;
  const updatePayload: UserUpdatePayload[] = Object.keys(data).map((key) => ({
    operation: UpdateOperation.Replace,
    field: key,
    value: data[key],
  }));
  const request: UserUpdateRequest = merge(
    {
      ...options(props, {
        headersToOmit: ['cv-tenant-id'],
      }),
      pathParams: {
        _id: userDetails.userId,
      },
      data: updatePayload,
    },
    { headers: { 'If-Match': '*' as const } },
  );

  return UserUpdateProfile(request).then((response) => response.data);
};

export const updateUserPin = (props: SubscriptionProps, newPin: string) => {
  const { userDetails } = props;
  const request = {
    ...options(props, {
      headersToOmit: ['cv-tenant-id'],
    }),
    pathParams: {
      id: userDetails.userId,
    },
    data: {
      input: { newPin },
    },
  };

  return setupPin(request as any).then((response) => response.data);
};

export const updateUserPinWithSQA = (
  props: SubscriptionProps,
  newPin: string,
  newQuestion: string,
  newAnswer: string,
) => {
  const { userDetails } = props;
  const request = {
    ...options(props, {
      headersToOmit: ['cv-tenant-id'],
    }),
    pathParams: {
      id: userDetails.userId,
    },
    data: {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: newQuestion,
            answer: newAnswer,
          },
        ],
        pin: newPin,
      },
    },
  };

  return setupPinAndSecurityQuestions(request as SetupPinAndSecurityQuestionsRequest).then((response) => response.data);
};
