// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J0KLNDxKNZX3jsYMvPwR {
  width: calc(100vw - 8px);
  min-width: 0;
  max-height: none;
  max-height: 60vh;
  position: absolute;
  background-color: var(--theme-header-secondary-dropdown-background);
  margin: 0 auto;
  color: var(--theme-color-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  align-items: center;
}

.USqv9nbbBhIpy9plzcO3 {
  position: relative;
  padding-left: calc(20px + 1rem);
}

.KeWboHqgGkCOkJwpGm5Q {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
}

.sfXEyo4nMq1gCzOqsB0c {
  top: 100%;
  right: auto;
}

.UeFfzzkWgoCswnlM9eF5 {
  overflow-y: auto;
  max-height: calc(80vh - 90px);
}

.mS92ret8MLEU9RSeGPaM {
  height: auto;
  max-height: 80vh;
  padding: 31px;
  border: 1px solid var(--theme-color-grey-d);
  box-shadow: 0 5px 10px var(--theme-color-grey-d);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.ak8NJoWXRVxonxXfYIL7 > * {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/WarningBanner/WarningBanner.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,mEAAmE;EACnE,cAAc;EACd,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,2CAA2C;EAC3C,gDAAgD;EAChD,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".dropDown {\n  width: calc(100vw - 8px);\n  min-width: 0;\n  max-height: none;\n  max-height: 60vh;\n  position: absolute;\n  background-color: var(--theme-header-secondary-dropdown-background);\n  margin: 0 auto;\n  color: var(--theme-color-white);\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  overflow-y: auto;\n  align-items: center;\n}\n\n.warning-menu {\n  position: relative;\n  padding-left: calc(20px + 1rem);\n}\n\n.warning-menu-icon {\n  height: 20px;\n  width: 20px;\n  margin-right: 1rem;\n  position: absolute;\n  left: 0;\n  bottom: 50%;\n  transform: translateY(50%);\n}\n\n.wrapper {\n  top: 100%;\n  right: auto;\n}\n\n.WarningModal-body {\n  overflow-y: auto;\n  max-height: calc(80vh - 90px);\n}\n\n.WarningModal {\n  height: auto;\n  max-height: 80vh;\n  padding: 31px;\n  border: 1px solid var(--theme-color-grey-d);\n  box-shadow: 0 5px 10px var(--theme-color-grey-d);\n  border-radius: 10px;\n  overflow: hidden;\n  position: relative;\n}\n\n.OtpForm > * {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDown": `J0KLNDxKNZX3jsYMvPwR`,
	"warning-menu": `USqv9nbbBhIpy9plzcO3`,
	"warning-menu-icon": `KeWboHqgGkCOkJwpGm5Q`,
	"wrapper": `sfXEyo4nMq1gCzOqsB0c`,
	"WarningModal-body": `UeFfzzkWgoCswnlM9eF5`,
	"WarningModal": `mS92ret8MLEU9RSeGPaM`,
	"OtpForm": `ak8NJoWXRVxonxXfYIL7`
};
export default ___CSS_LOADER_EXPORT___;
