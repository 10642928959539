import { SubscriptionProps } from '@manageSubscription';
import { options } from '../commonService';
import { QueryManagedVehicleRequest } from '@cv/portal-idm-lib/vehicle/models/query-vehicle';
import { queryManagedVehicle } from '@cv/portal-idm-lib/vehicle';

export const getVehicleAndUserDetails = (props: SubscriptionProps) => {
  const { userDetails, vehicleDetails } = props;
  const request: QueryManagedVehicleRequest = {
    ...options(props, {
      headersToOmit: ['cv-tenant-id'],
    }),
    pathParams: {
      _id: userDetails.userId,
    },
    queryParams: {
      _fields: '*,users/*',
      _queryFilter: {
        vin: vehicleDetails.vin,
      },
    },
  };

  return queryManagedVehicle(request).then((response) => response.data);
};
