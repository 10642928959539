export const getCookie = (cookieName: string) =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];

export const setCookie = (key: string, value: string, expirationTime?: number | string) => {
  let expires = '';
  if (expirationTime) {
    const date = new Date(expirationTime);
    expires = `expires=${date.toUTCString()};`;
  }
  document.cookie = `${key}=${value};${expires}`;
};

type Cookie = Record<
  string,
  | string
  | {
      value: string;
      expirationTime?: number | string;
    }
>;
export const setCookies = (cookies: Cookie) => {
  Object.entries(cookies).forEach(([key, value]) => {
    if (typeof value === 'object') {
      setCookie(key, value.value, value.expirationTime);
    } else {
      setCookie(key, value);
    }
  });
};
