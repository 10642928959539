import { Content, Container } from '../../styled-components/globalStyles';
import BackgroundImageComponent from '../../components/BackgroundImage';
import { styled } from '@mui/material';

export const BackgroundImage = styled(BackgroundImageComponent)`
  display: flex;
  justify-content: center;
`;

export const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

export const ServiceContainer = styled('article')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 2rem 0 2rem;

  p {
    font-size: 1rem;
    line-height: 1.38;
  }
`;

export const HeaderContainer = styled(Content)`
  color: white;
  text-align: center;
  padding: 2.375rem 1rem 1.5rem;

  & > h1 {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
  }

  & > p {
    font-size: 1.0625rem;
  }

  & > Button {
    margin: 1rem auto;
  }
`;

export const LogoContainer = styled('div')`
  height: 4rem;
  margin-bottom: 1.5rem;
  & > img {
    max-height: 100%;
  }
`;

export const InnerContainer = styled(Container)`
  padding: 1rem 2rem;

  @media screen and (max-width: 425px) {
    padding: 1rem 2rem;
  }
`;

export const FooterContainer = styled('footer')`
  padding: 1.5rem 1rem;
  text-align: center;
  padding: 1.5rem 1rem;
  background-color: ${({ theme }) => theme.palette.background.footer};

  & > h2 {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.palette.text.navigation};
  }

  & > p {
    font-size: 1.0625rem;
    color: ${({ theme }) => theme.palette.text.navigation};
  }

  & > Button {
    margin: 1.5rem auto;
  }
`;
