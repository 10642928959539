import base from './base.config';

export default {
  ...base,
  loginEnabled: true,
  mapEnabled: true,
  headerNavigationEnabled: true,
  headerUserEnabled: true,
  excludeErrorDialog: [
    'VALIDATE_OTP_FAILED',
    '422:RESPONSE.ERROR.INPUT.INCORRECT_PIN',
    '422:RESPONSE.ERROR.INPUT.INCORRECT_KBA',
    '422:RESPONSE.ERROR.DATAERROR.PIN_LOCKED',
    '422:UNIQUE__username',
    'ACTIVE_DEVICE_NOT_FOUND',
    'IDM_INVALID_OTP',
  ],
  authExcludePath: ['/login', '/logout', '/support', '/authenticate', '/manage', '/manage.html'],
  oemsToSendChannelIdInDestinationReq: ['INFINITI'],
  excludeCodeOnRoute: ['TP4041::/reports/health'],
} as const;
