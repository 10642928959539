import React from 'react';
import { Content, LineSeparator, Title } from '../../styled-components/globalStyles';
import { NewLine, Pricing, SummarySection } from './styles';
import { DiscountsOfferLegal } from './DiscountsOfferLegal';
import { OrderSummaryInfo } from '../Types';
import { formatDateByLocale } from '@cv/portal-common-lib';
import { isHighestTrialSubscription } from '../utils';
import { SubscriptionProps, WebContent } from '..';

interface SubscriptionSummaryProps {
  subscriptionSummaryInfo: OrderSummaryInfo;
  content: WebContent;
  subscriptionProps: SubscriptionProps;
}

export const SubscriptionSummary = ({
  subscriptionSummaryInfo,
  content,
  subscriptionProps,
}: SubscriptionSummaryProps) => {
  const {
    subscriptionSummaryLabel,
    packagePriceLabel,
    refundLabel,
    refundTaxLabel,
    refundDueLabel,
    totalTaxLabel,
    totalAmountLabel,
    paymentDateLabel,
    taxLegalPayLater,
    taxLegalPayNow,
    commonWebContent: { currencyLabel },
  } = content;

  const {
    amountWithoutTaxTotal,
    taxTotal,
    amountTotal,
    refundAmountWithoutTaxTotal,
    refundTaxTotal,
    refundAmountTotal,
    packages,
  } = subscriptionSummaryInfo;

  const { getOfferLegalText } = subscriptionProps;

  const paymentDate = () => {
    const startDate = packages?.[0]?.variant?.startDate;
    return formatDateByLocale(startDate);
  };

  const getAmount = (amount) => {
    const newAmount = parseFloat(amount || 0.0).toFixed(2);
    return (
      <>
        {currencyLabel}
        {newAmount}
      </>
    );
  };

  const hasRefundAmount = () => {
    return parseFloat(refundAmountTotal || 0) > 0;
  };

  const hasRefundTaxAmounts = () => {
    return parseFloat(refundAmountWithoutTaxTotal ?? 0) > 0 || parseFloat(refundTaxTotal ?? 0) > 0;
  };

  const displayLegal = () => {
    return packages.some((p) => isHighestTrialSubscription(p)) ? taxLegalPayLater : taxLegalPayNow;
  };

  return (
    <SummarySection>
      <Title>{subscriptionSummaryLabel}</Title>
      <LineSeparator />
      <Pricing>
        <Title>{packagePriceLabel}</Title>
        <Title>{getAmount(amountWithoutTaxTotal)}</Title>
      </Pricing>
      {hasRefundTaxAmounts() && (
        <React.Fragment>
          <Pricing>
            <Title>{refundLabel}</Title>
            <Title>{getAmount(refundAmountWithoutTaxTotal)}</Title>
          </Pricing>
          <Pricing>
            <Title>{refundTaxLabel}</Title>
            <Title>{getAmount(refundTaxTotal)}</Title>
          </Pricing>
        </React.Fragment>
      )}
      <Pricing>
        <Title>{totalTaxLabel}</Title>
        <Title>{getAmount(taxTotal)}</Title>
      </Pricing>
      <LineSeparator />
      <Pricing>
        <Title>{totalAmountLabel}</Title>
        <Title>{getAmount(amountTotal)}</Title>
      </Pricing>
      {hasRefundAmount() && (
        <Pricing>
          <Title>{refundDueLabel}</Title>
          <Title>{getAmount(refundAmountTotal)}</Title>
        </Pricing>
      )}
      <NewLine />
      <Pricing>
        <Title>{paymentDateLabel}</Title>
        <Title>{paymentDate()}</Title>
      </Pricing>
      <NewLine />
      <Content>{displayLegal()}</Content>
      <DiscountsOfferLegal packages={packages} getOfferLegalText={getOfferLegalText} />
    </SummarySection>
  );
};
