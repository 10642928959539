import { updateMarketingPreference } from '@cv/portal-cps-lib/preferences/marketing-preference';
import { UpdatePreferenceRequest } from '@cv/portal-cps-lib/preferences/marketing-preference/models';
import config from '@config/config';
import {
  CreateEditPayloadOptions,
  createEditPayload,
} from '@api/requests/marketingPreference/createNewMarketingPreference';

export type UpdateCurrentMarketingPreferenceOptions = CreateEditPayloadOptions & {
  accessToken: string;
  userId: string;
  locale?: string;
};

export const updateCurrentMarketingPreference = ({
  accessToken,
  userId,
  locale = 'en-US',
  optIn,
  phone,
  email,
}: UpdateCurrentMarketingPreferenceOptions) => {
  const environmentEnum = config.getEnvironmentEnum();
  const payload = createEditPayload({ optIn, phone, email });

  const request: UpdatePreferenceRequest = {
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'If-Match': '*',
    },
    environment: environmentEnum,
    data: {
      userId,
      ...payload,
    },
  };

  return updateMarketingPreference(request);
};
