import React, { useState } from 'react';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { AccordionContainer, AccordionTitle, CirclePlus } from './AccordionStyles';

export type AccordionProps = {
  expanded?: boolean;
  title: React.ReactNode;
  children?: React.ReactNode;
  analyticsEventName?: string;
};

const Accordion = ({ expanded = false, title, children, analyticsEventName = '' }: AccordionProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(expanded);
  const analyticsProp = getAnalyticsProp(analyticsEventName, isExpanded);

  return (
    <AccordionContainer
      expanded={isExpanded}
      onChange={() => setExpanded((prevExpanded) => !prevExpanded)}
      {...analyticsProp}
    >
      <AccordionTitle expandIcon={<CirclePlus open={isExpanded} title={isExpanded ? 'Hide' : 'Expand'} />}>
        {title}
      </AccordionTitle>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </AccordionContainer>
  );
};

export const getAnalyticsProp = (analyticsEventName: string, isExpanded: boolean) => ({
  ...(analyticsEventName && {
    ['data-analyticseventname']: isExpanded ? `${analyticsEventName}Expand` : `${analyticsEventName}Collapse`,
  }),
});

export default Accordion;
