import useFilterByMake from '@hooks/useFilterByMake';
import filter from '@utils/filterByMake';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import { FooterContent } from './Footer';

export type FilteredFooterProps = {
  content: FooterContent[];
  filter?: BrandNamesFilterGroup;
};

const useFilterFooter = ({ content, filter: filterGroup }: FilteredFooterProps): FooterContent[] =>
  useFilterByMake({ content, filter, filterGroup });

export default useFilterFooter;
