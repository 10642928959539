import React from 'react';
// @ts-ignore
import { ContentfulImage } from '@cv/webframework-react-components';

import { ImageWrapper } from './styles';
import { ContentfulAssets } from '@manageSubscription';

export interface ImageProps {
  name: string;
  prefix?: string;
  size?: string;
  assets: ContentfulAssets;
  suppressMissingImage?: boolean;
  inline?: boolean;
}

const Image = ({
  name,
  assets = {},
  prefix = '',
  size = '',
  suppressMissingImage = false,
  inline = false,
  ...rest
}: ImageProps) => {
  const formattedPrefix = prefix ? prefix[0].toLowerCase() + prefix.slice(1) : '';
  let imageData = assets[`${formattedPrefix}${name}`] || assets[`${prefix}${name}`] || assets[name];

  if (!imageData && !suppressMissingImage) {
    imageData = assets['Missing'];
  }

  if (!imageData) {
    return null;
  }

  const width = imageData.file?.details?.image?.width;
  const maxWidth = size || (width && `${width}px`) || null;

  return (
    <ImageWrapper {...rest} maxWidth={maxWidth} inline={inline}>
      <ContentfulImage imageData={imageData} />
    </ImageWrapper>
  );
};

export default Image;
