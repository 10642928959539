import React from 'react';
import useToggle from '@hooks/useToggle';

import './ExpandingContainer.css';
import { Button } from '@components-lib';

type ExpandingContainerProps = {
  visibleContent: React.ReactNode;
  extraContent: React.ReactNode;
  visibleRows?: string;
  numColumns: '2' | '3';
  expandLabel: string;
  hideLabel: string;
  itemsCount: number;
  noServicesLabel?: string;
};

function ExpandingContainer({
  visibleContent,
  extraContent,
  expandLabel,
  hideLabel,
  itemsCount,
  noServicesLabel = '',
}: ExpandingContainerProps) {
  const [isExpanded, setExpanded] = useToggle(false);
  const visibleContentCount = React.Children.count(visibleContent?.props?.children);
  const extraContentCount = React.Children.count(extraContent?.props?.children);
  const isExpandedButtonVisible = visibleContentCount >= itemsCount && extraContentCount > visibleContentCount;

  if (!visibleContentCount) {
    return <div data-testid="expandinglist-content">{noServicesLabel}</div>;
  }

  return (
    <div className="ExpandingContainer-container">
      <div>
        {visibleContent}
        {isExpanded && extraContent}
      </div>
      {isExpandedButtonVisible && (
        <Button variant="text" onClick={() => setExpanded(!isExpanded)} className="ExpandingButton">
          {isExpanded ? hideLabel : expandLabel}
        </Button>
      )}
    </div>
  );
}

ExpandingContainer.defaultProps = {
  numColumns: '2',
  visibleRows: '3',
};

export default ExpandingContainer;
