import { chunk } from 'lodash';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { isTrialPackage } from '@utils/isTrialPackage';

export type GetPackagesHeaderOptions = { selectedPackages: SubscribedPackage[]; andLabel: string };

export const getPackagesHeader = ({ selectedPackages, andLabel }: GetPackagesHeaderOptions) => {
  return selectedPackages
    .reduce((header, pkg, index, array) => {
      const isFirst = index === 0;

      if (isFirst) {
        return pkg.marketingName;
      } else {
        const isLast = index === array.length - 1;
        if (isLast) {
          return `${header} ${andLabel} ${pkg.marketingName}`;
        } else {
          return `${header}, ${pkg.marketingName}`;
        }
      }
    }, '')
    .trim();
};

export type GetPackagesSubHeaderOptions = {
  selectedPackages: SubscribedPackage[];
  paidOnly: string;
  trialOnly: string;
  trialPaidTogether: string;
};

export const getPackagesSubHeader = ({
  selectedPackages,
  paidOnly,
  trialOnly,
  trialPaidTogether,
}: GetPackagesSubHeaderOptions) => {
  const packagesCounters = selectedPackages.reduce(
    (counters, currentPkg) =>
      isTrialPackage(currentPkg)
        ? { ...counters, trialPackages: counters.trialPackages + 1 }
        : { ...counters, paidPackages: counters.paidPackages + 1 },
    { trialPackages: 0, paidPackages: 0 },
  );

  if (packagesCounters.trialPackages === 0) {
    return paidOnly;
  } else if (packagesCounters.paidPackages === 0) {
    return trialOnly;
  } else {
    return trialPaidTogether;
  }
};

type GetServicePairs = { services?: VehicleServiceDetails[]; lengthToShow?: number };

export const getServicePairs = ({ services = [], lengthToShow }: GetServicePairs) => {
  return chunk(services.slice(0, lengthToShow), 2);
};

export const getSortedServices = (servicesHierarchy: string[], services?: VehicleServiceDetails[]) => {
  if (!services) return [];
  const reorderedServices = [];
  //first add services from the hierarchy to the new array
  servicesHierarchy.forEach((serviceName) => {
    const service = services.find((s) => s.vehicleServiceName === serviceName);
    service && reorderedServices.push(service);
  });
  //now add the rest of the services in the same order as they came from the API
  reorderedServices.push(...services.filter((s) => !servicesHierarchy.includes(s.vehicleServiceName)));
  return reorderedServices;
};
