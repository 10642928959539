import { useDispatch } from 'react-redux';
import { DIALOG_ACTIONS } from '@redux/actions';
import { DialogConfigs } from '@redux/reducers/dialog';

const useDialogActions = () => {
  const dispatch = useDispatch();

  const showDialog = (data: DialogConfigs) => {
    dispatch({ type: DIALOG_ACTIONS.SHOW, data });
  };

  const hideDialog = () => {
    dispatch({ type: DIALOG_ACTIONS.CLOSE });
  };

  return {
    showDialog,
    hideDialog,
  };
};

export default useDialogActions;
