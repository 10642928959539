import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

export type AttachCapableServicesOptions = {
  packages?: any[];
  capableServices?: VehicleServiceDetails[];
};

const attachCapableServices = ({ packages = [], capableServices = [] }: AttachCapableServicesOptions) =>
  packages.reduce<any[]>((allPackages, pkg) => {
    const products = pkg.products.map((product) => ({
      ...product,
      capableServices: product.serviceIds.reduce<VehicleServiceDetails[]>((allServices, serviceId: string) => {
        const productService = capableServices.find((service) => service.vehicleServiceId === serviceId);
        const isCapable = !!productService;
        return isCapable ? [...allServices, productService] : allServices;
      }, []),
    }));

    const allCapableServices = products.reduce<VehicleServiceDetails[]>(
      (allServices, { capableServices = [] }) => [...allServices, ...capableServices],
      [],
    );

    return [...allPackages, { ...pkg, products, capableServices: allCapableServices }];
  }, []);

export default attachCapableServices;
