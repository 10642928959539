// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LpNluKjS2jxpGaygak1e {
  --link-active: var(--theme-color-link-active, inherit);
  display: inline-block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.dIvOUVW7uXHk7cos5KF3 {
  color: var(--link-active);
  font-weight: bold;
}

.wD5dumIWKyuC7I964X9J {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.tutUfcHsks74Wamfe5hg {
  width: 28px;
}

.ApBqgjTS23EMIJwZDo3q {
  margin-left: 3px;
  order: 1;
}

.ytnaZVRrqQTiit9wDD6N {
  margin-right: 3px;
  order: -1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Link/Link.module.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;EACtD,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX","sourcesContent":[".Link {\n  --link-active: var(--theme-color-link-active, inherit);\n  display: inline-block;\n  text-decoration: none;\n  color: inherit;\n  cursor: pointer;\n}\n\n.Link--active {\n  color: var(--link-active);\n  font-weight: bold;\n}\n\n.LinkIcon {\n  display: flex;\n  justify-content: center;\n  text-decoration: none;\n  color: inherit;\n  cursor: pointer;\n}\n\n.LinkIcon-image {\n  width: 28px;\n}\n\n.LinkIconPosition--right {\n  margin-left: 3px;\n  order: 1;\n}\n\n.LinkIconPosition--left {\n  margin-right: 3px;\n  order: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Link": `LpNluKjS2jxpGaygak1e`,
	"Link--active": `dIvOUVW7uXHk7cos5KF3`,
	"LinkIcon": `wD5dumIWKyuC7I964X9J`,
	"LinkIcon-image": `tutUfcHsks74Wamfe5hg`,
	"LinkIconPosition--right": `ApBqgjTS23EMIJwZDo3q`,
	"LinkIconPosition--left": `ytnaZVRrqQTiit9wDD6N`
};
export default ___CSS_LOADER_EXPORT___;
