import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { assign, send } from 'xstate';
import { getEligiblePromoPackages, getPackagesMatchingTrials } from '@manageSubscription/utils';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig, ManagePromoPackagesFlowContext } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import calculatePackageDependencies from '@utils/calculatePromoPackageDependencies';
import { previewOrders } from '@app/components-lib/services';

export const managePromoPackagesFlow: ComponentFlowStateConfig<ManagePromoPackagesFlowContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePromoPackages,
  states: {
    pre: {
      entry: 'getEligiblePromoPackages',
      always: [
        { target: 'navigateToSkip', cond: 'is2fFlowWithTrialEligibleHasNoPromos' },
        { target: 'navigate', cond: 'skipPromotion' },
        'prefetchTaxes',
      ],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePromoPackages },
        }),
      ],
      on: {
        NAVIGATE: 'navigate',
        NAVIGATE_OR_SKIP: 'navigateOrSkip',
        SKIP: 'navigateToSkip',
        NAVIGATE_BACK: { actions: 'navigateBack' },
      },
    },
    prefetchTaxes: {
      entry: 'setLoading',
      invoke: {
        id: 'prefetchTaxes',
        src: async (context) => {
          const { eligiblePromoPackages } = context;
          const { shouldIncludeTax } = context.content.commonWebContent;
          if (!shouldIncludeTax) {
            return assign({ eligiblePromoPackages });
          }
          const eligiblePackagesWithTaxTotal = await Promise.all(
            eligiblePromoPackages.map(async (promoPkg) => {
              const previewResult = await previewOrders(context.subscriptionProps, [promoPkg], []);
              promoPkg.taxTotal = Number(previewResult.taxTotal);
              return promoPkg;
            }),
          );
          return assign({ eligiblePromoPackages: eligiblePackagesWithTaxTotal });
        },
        onDone: {
          target: 'idle',
          actions: ['unsetLoading'],
        },
        onError: {
          target: 'idle',
          actions: ['unsetLoading'],
        },
      },
    },
    navigate: {
      always: [
        {
          target: 'navigateToCancel',
          cond: 'hasTrialPackageWithCCRequired',
        },
        { target: 'navigateForward', cond: 'hasPromoPackage' },
        { target: 'navigateToCancel', cond: 'hasTrialPackage' },
        'navigateToCancel',
      ],
    },
    navigateOrSkip: {
      always: [{ target: 'navigateToSkip', cond: 'isTrialEligibleHasNoPaidPkg' }, 'navigateForward'],
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateToCancel: {
      entry: 'navigateToCancel',
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});

export const actions: ComponentActions<ManagePromoPackagesFlowContext> = {
  getEligiblePromoPackages: assign({
    eligiblePromoPackages: (context) => {
      const {
        promoPackagesWebContent: { rrsPromoCriteria, subscriberPromoCriteria },
      } = context.content;
      const { salesChannel, shouldMatchTrialWithCCRequired } = context.subscriptionProps;
      const { eligiblePackages, packageSubscriptions } = context.flowSessionStorage;
      const promoCriteria =
        salesChannel === SalesChannel.Rapid_registration ? rrsPromoCriteria : subscriberPromoCriteria;
      return getPackagesMatchingTrials(
        calculatePackageDependencies(getEligiblePromoPackages(eligiblePackages, promoCriteria)),
        packageSubscriptions,
        shouldMatchTrialWithCCRequired,
      );
    },
  }),
};
