import { styled } from '@mui/material';
import { Link, Title } from '../../../styled-components/globalStyles';

export const PaymentContainer = styled('div')`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const CCHeader = styled('div')`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`;

export const UpdateCCHeader = styled(CCHeader)`
  display: flex;
  margin: -1rem 0 1rem 2rem;
  align-items: center;
`;

export const CardDetail = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
`;

export const CardType = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const PrivacyPolicyLink = styled(Link)`
  display: flex;
  margin: 1rem 0 0;
  justify-content: left;
`;

export const RequiredField = styled(Title)`
  text-align: right;
  color: #c71444;

  &:before {
    content: '*';
  }
`;

export const InlineContent = styled('div')`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: left;
`;

export const PayPalContainer = styled('div')`
  display: flex;
  justify-content: center;
`;
