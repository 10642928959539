import React, { useState } from 'react';
import styles from './InvoicesList.module.css';
import Dialog from '@components/Dialog';
import InvoiceItem, { Invoice } from './InvoiceItem';
import useGetInvoices from '@api/queries/useGetInvoices';

export interface InvoicesListProps {
  count?: number;
  labels: InvoicesLabels;
}

export type InvoicesLabels = {
  closeButtonLabel: string;
  currencySign: string;
  invoiceActionsLabel: string;
  invoiceAmountLabel: string;
  invoiceDateLabel: string;
  invoiceLoadError: string;
  invoicesListLabel: string;
  viewInvoicesLabel: string;
};

export function InvoicesList({ count = 10, labels = {} }: InvoicesListProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isSuccess, data: invoices } = useGetInvoices(count);

  const {
    closeButtonLabel,
    currencySign,
    invoiceActionsLabel,
    invoiceAmountLabel,
    invoiceDateLabel,
    invoiceLoadError,
    invoicesListLabel,
    viewInvoicesLabel,
  } = labels;

  return (
    <>
      <a className={styles.pointer} role="button" tabIndex={0} href="#dialog" onClick={() => setIsOpen(true)}>
        {viewInvoicesLabel}
      </a>
      {isOpen && (
        <Dialog title={invoicesListLabel} labelCancel={closeButtonLabel} onCancel={() => setIsOpen(false)}>
          <div className={styles.styledTable}>
            <h3>{invoiceDateLabel}</h3>
            <h3>{invoiceAmountLabel}</h3>
            <h3>{invoiceActionsLabel}</h3>

            {isSuccess &&
              invoices.map((invoice: Invoice) => (
                <InvoiceItem
                  invoice={invoice}
                  currencySign={currencySign}
                  invoiceLoadError={invoiceLoadError}
                  key={invoice.invoiceId}
                />
              ))}
          </div>
        </Dialog>
      )}
    </>
  );
}

export default InvoicesList;
