import React from 'react';
import { Typography, Grid, Divider } from '@mui/material';

type SectionHeaderProps = {
  children: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ children }) => {
  return (
    <Grid item alignSelf="normal">
      <Typography variant="h6" component="span">
        {children}
      </Typography>
      <Divider />
    </Grid>
  );
};

export default SectionHeader;
