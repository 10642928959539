import React from 'react';
import InfoItem from './InfoItem';
import { FaEdit } from 'react-icons/fa';

import './InfoBox.css';
import { useTheme } from '@mui/material';

type InfoBoxProps = {
  entries?: Array<{ label: string; value: string }>;
  isEditable?: boolean;
  onEdit?: (index?: number) => void;
  columns?: '1' | '2' | '3';
  index?: number;
};

function InfoBox({ entries = [], isEditable = false, onEdit, columns = '2', index }: InfoBoxProps) {
  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  const theme = useTheme();

  return (
    <>
      <div className="InfoBox-container" style={{ color: theme.palette.text.primary }}>
        {entries.map(({ label, value }, i) => {
          const formatValue = value?.trim();
          return (
            Boolean(formatValue) && (
              <InfoItem label={label} content={formatValue} columns={columns} key={`${label}-${i}`} />
            )
          );
        })}
      </div>
      {isEditable && (
        <button className="InfoBox-button InfoBox-edit-button" onClick={onEditClick} title="Edit">
          <FaEdit className={'InfoBox-edit-icon'} style={{ color: theme.palette.primary.main }} />
        </button>
      )}
    </>
  );
}

export default InfoBox;
