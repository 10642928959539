import React from 'react';
import { FieldConfig, FieldHelperProps, useField } from 'formik';

import { InputLabelContainer, InputLabelStar, Input, ErrorText } from './formikFieldStyles';
import { ListAddressesResponse } from '@cv/portal-cps-lib/utility/utility-services/models';

export type UserProfileInputProps = FieldConfig &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    required?: boolean;
    label?: string;
    inputId?: string;
    options?: { label: string; value: string }[];
    Component?: React.ComponentType<{
      error?: boolean;
      helpers?: FieldHelperProps<string>;
    }>;
    loadOptions?: (inputText: string) => Promise<ListAddressesResponse[]>;
    onSelectOption?: (x: Object) => void;
    input?: React.ReactElement<HTMLInputElement, string>;
    fullWidth?: boolean;
  };

const FormikField = ({ required, label, Component = Input, ...props }: UserProfileInputProps) => {
  const [field, meta, helpers] = useField(props);

  const errorMessage = meta.touched && meta.error;

  return (
    <>
      {label && (
        <InputLabelContainer htmlFor={props.id || props.inputId}>
          <p data-testid="input-label-text">{label}</p>
          {required && <InputLabelStar data-testid="input-label-star" />}
        </InputLabelContainer>
      )}
      <Component {...field} data-testid="input-field" error={!!errorMessage} helpers={helpers} {...props} />
      {errorMessage && <ErrorText data-testid="error">{errorMessage}</ErrorText>}
    </>
  );
};

export default FormikField;
