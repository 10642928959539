// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout-container {
  margin: 0 auto;
  display: flex;
}

@media (min-width: 768px) {
  .Layout-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .Layout-container {
    padding: 0.3125rem;
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .Layout-container {
    width: 1170px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 992px) {
  .width-40 {
    width: 40%;
  }

  .width-49 {
    width: 49%;
  }

  .width-50 {
    width: 50%;
  }

  .width-60 {
    width: 60%;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.error {
  color: var(--theme-color-error);
}

.no-mb {
  margin-bottom: 0;
}

.no-ml {
  margin-left: 0;
}

.no-mr {
  margin-right: 0;
}

.no-mt {
  margin-top: 0;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mx-1 {
  margin: 0 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/commons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,UAAU;EACV,SAAS;EACT,WAAW;EACX,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".Layout-container {\n  margin: 0 auto;\n  display: flex;\n}\n\n@media (--viewport-s) {\n  .Layout-container {\n    width: 750px;\n  }\n}\n\n@media (--viewport-m) {\n  .Layout-container {\n    padding: 0.3125rem;\n    width: 970px;\n  }\n}\n\n@media (--viewport-l) {\n  .Layout-container {\n    width: 1170px;\n  }\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n@media (--viewport-m) {\n  .width-40 {\n    width: 40%;\n  }\n\n  .width-49 {\n    width: 49%;\n  }\n\n  .width-50 {\n    width: 50%;\n  }\n\n  .width-60 {\n    width: 60%;\n  }\n}\n\n.visually-hidden {\n  position: absolute;\n  clip: rect(1px, 1px, 1px, 1px);\n  padding: 0;\n  border: 0;\n  height: 1px;\n  width: 1px;\n  overflow: hidden;\n}\n\n.text-left {\n  text-align: left;\n}\n\n.text-right {\n  text-align: right;\n}\n\n.text-center {\n  text-align: center;\n}\n\n.text-justify {\n  text-align: justify;\n}\n\n.error {\n  color: var(--theme-color-error);\n}\n\n.no-mb {\n  margin-bottom: 0;\n}\n\n.no-ml {\n  margin-left: 0;\n}\n\n.no-mr {\n  margin-right: 0;\n}\n\n.no-mt {\n  margin-top: 0;\n}\n\n.mb-1rem {\n  margin-bottom: 1rem;\n}\n\n.mx-1 {\n  margin: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
