import React, { useEffect, useState } from 'react';

import { Button } from '@components-lib';
import InputSwitch from '@components/InputSwitch';
import InputRange from '@components/InputRange';
import TabHeader from '../TabHeader';
import ActiveView from '../ActiveView';
import { useApi } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { DIALOG_ACTIONS, GEOFENCE_ACTIONS } from '@redux/actions/';
import { useAnalytics } from '@cv/webframework-react-components';

import styles from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import { saveValetAlert } from '../Forms/utils';
import { SET_VEHICLE_LOCATION } from '@components/Map/constants';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { setLoadingStatus } from '@redux/actions/loading';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { GetGeofenceResponse } from '@cv/portal-rts-lib/geofences/models';
import { pollingTime } from '../constants';
import { RootState } from '../../../../reducers';
import Loader from '@components/Loader';
import useToggle from '@hooks/useToggle';
import { Modal } from '@components-lib';

type SliderUnit = 'Mile' | 'Kilometer';

type TabValetProps = {
  cancelButton: string;
  confirmButton: string;
  defaultViewContent: string;
  formUnit: SliderUnit;
  sliderDescription: string;
  sliderMaxValue: string;
  sliderMinValue: string;
  sliderStepValue: string;
  sliderUnit: string;
  switchButtonLabel: string;
  switchLabelUnit: string;
  switchLabel: string;
  title: string;
  createSuccessMsg: string;
  updateSuccessMsg: string;
  createErrorMsg: string;
  updateErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  toggleOnAnalyticsEventName: string;
  toggleOffAnalyticsEventName: string;
  attemptsExceededMessage: string;
};

export default function TabValet({
  cancelButton,
  confirmButton,
  defaultViewContent,
  formUnit,
  sliderDescription,
  sliderMaxValue,
  sliderMinValue,
  sliderStepValue,
  sliderUnit,
  switchButtonLabel,
  switchLabelUnit,
  switchLabel,
  title,
  createSuccessMsg,
  updateSuccessMsg,
  createErrorMsg,
  updateErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  toggleOnAnalyticsEventName,
  toggleOffAnalyticsEventName,
  attemptsExceededMessage,
}: TabValetProps) {
  const api = useApi();
  const { trackEvent } = useAnalytics();
  const valet = useSelector(({ geofenceReducer }: RootState) => geofenceReducer.valet);
  const initialSliderValue = valet?.geometry?.radius?.value || sliderMinValue;
  const [radiusValue, setRadiusValue] = useState(initialSliderValue);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [valetRadio, setValetRadio] = useState<string>(initialSliderValue.toString());
  const [view, setView] = useState('default');
  const [attemptsExceededDialog, setAttemptsExceededDialog] = useToggle(false);

  let valetAlertId = '';
  let createReq = false;

  const getGeofence = async () => {
    setLoading(true);
    try {
      const response = await api.getGeofence();
      const valetData = response.data.geofences.find((item) => item.alertType === 'VALET');
      if (valetData) {
        dispatch({ type: GEOFENCE_ACTIONS.GET_VALET, data: valetData });
      } else {
        dispatch({ type: GEOFENCE_ACTIONS.RESET_VALET });
      }
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      dispatch({ type: GEOFENCE_ACTIONS.RESET_VALET });
      console.log('error api', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getGeofence();
  }, []);

  useEffect(() => {
    setSelected(valet.state === 'ACTIVE');
    setRadiusValue(valet.geometry.radius.value);
  }, [valet]);

  const getVehicleLocation = async () => {
    try {
      const { locations: [location] = [] } = await api.getVehicleLocation(api.storeService.getAccessToken());
      dispatch({ type: SET_VEHICLE_LOCATION, vehicleLocation: location });
      return location;
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeValet = () => {
    if (!selected) {
      trackEvent(toggleOnAnalyticsEventName);
    } else trackEvent(toggleOffAnalyticsEventName);
    setSelected(!selected);
    if (!valet || valet.state === 'INACTIVE') {
      saveValet(valet?.lastSvcReqId);
    } else {
      deleteValet(valet?.lastSvcReqId, true);
    }
  };

  const deleteValet = async (valetId: string, currentState?: boolean) => {
    try {
      dispatch(setLoadingStatus(true));
      const res = await api.deleteGeofence(valetId);

      // we should not call POST in case if delete is not successful
      // delete won't get to catch as we return data as regular for delete
      if (res.status !== 202) {
        throw new Error(res.message);
      }

      valetAlertId = valetId;
      createReq = false;
      initiatePolling({
        pollingFunc: api.getGeofence.bind(api),
        validationCallback,
        successCallback: () => successCallback('delete', currentState),
        errorCallback: () => errorCallback('delete'),
        timeout: pollingTime,
      });
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      setSelected(true);
    }
  };

  const validationCallback = (response: APIResponse<GetGeofenceResponse>) => {
    const svcRequestValet = response.data.svcRequests.find((item) => item.id === valetAlertId);
    const valetStatus = svcRequestValet?.status;
    if (valetStatus?.includes(Status.SUCCESS) || (!createReq && !svcRequestValet)) {
      return PollingStatus.SUCCESS;
    }
    if (valetStatus?.includes(Status.FAILED) || (createReq && !svcRequestValet)) {
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  };

  const successCallback = (type: string, currentState?: boolean) => {
    const { state } = valet;
    if (!currentState && state === 'ACTIVE' && type === 'delete') {
      saveValet(valet?.lastSvcReqId);
    } else {
      const message = type === 'create' ? createSuccessMsg : updateSuccessMsg;
      onFormClose();
      getGeofence();
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
    }
  };

  const errorCallback = (type: string) => {
    const message = type === 'create' ? createErrorMsg : updateErrorMsg;
    onFormClose();
    getGeofence();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  };

  const onFormClose = () => {
    setView('default');
    setRadiusValue(valetRadio);
    dispatch(setLoadingStatus(false));
  };

  const warningCallback = () => {
    setAttemptsExceededDialog(true);
    onFormClose();
    getGeofence();
  };

  const closeModal = () => {
    setAttemptsExceededDialog(false);
  };

  const saveValet = async (valetId = '') => {
    const vehicleLocation = await getVehicleLocation();
    const saveValet = {
      valetRadio,
      formUnit,
      latitude: vehicleLocation?.coordinates?.latitude,
      longitude: vehicleLocation?.coordinates?.longitude,
      selected: !selected,
      ...(valetId && { id: valetId }),
    };
    const valetPayload = saveValetAlert(saveValet);
    try {
      dispatch(setLoadingStatus(true));
      const { data: valet } = await api.createGeofence(valetPayload);
      valetAlertId = valet.svcReqId;
      createReq = true;
      initiatePolling({
        pollingFunc: api.getGeofence.bind(api),
        validationCallback,
        successCallback: () => successCallback('create'),
        errorCallback: () => errorCallback('create'),
        warningCallback,
        timeout: pollingTime,
      });
    } catch (error) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      setSelected(false);
    }
  };

  const onConfirm = () => {
    const { state, lastSvcReqId } = valet;
    if (state === 'ACTIVE') {
      deleteValet(lastSvcReqId, false);
    }
    if (!valet || state === 'INACTIVE') {
      saveValet(lastSvcReqId);
    }
  };

  if (isLoading) return <Loader style={{ height: '100%' }} />;

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader
          label={title}
          onEdit={() => setView('editItem')}
          content={defaultViewContent}
          displayEditButton={true}
        />
        <InputSwitch
          type="wide"
          checked={selected}
          label={`${switchButtonLabel} ${radiusValue} ${switchLabelUnit}`}
          className={styles['form-valet-switch']}
          onChange={onChangeValet}
        />
      </ActiveView>
      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={title} content={defaultViewContent} displayEditButton={false} />
        <InputRange
          value={Number(valetRadio)}
          min={Number(sliderMinValue)}
          max={Number(sliderMaxValue)}
          step={Number(sliderStepValue)}
          unit={sliderUnit}
          onChange={(e) => setValetRadio(e.toString())}
          label={switchLabel}
          labelUnit={switchLabelUnit}
          hint={sliderDescription}
          showMinMaxValues
        />
        <div className={styles['button-container']}>
          <Button variant="outlined" onClick={onFormClose}>
            {cancelButton}
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            {confirmButton}
          </Button>
        </div>
      </ActiveView>
      <Modal
        open={attemptsExceededDialog}
        onClose={closeModal}
        primaryControl={
          <Button variant="contained" onClick={closeModal}>
            Ok
          </Button>
        }
      >
        <p>{attemptsExceededMessage}</p>
      </Modal>
    </>
  );
}
