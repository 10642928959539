import { PaymentInfo } from '../../payment/Types';
import { DiscountInfo, EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from './PackageInfo';

export interface InitialStateType {
  clientIP: string;
  paymentInfo: PaymentInfo;
  discountInfo: DiscountInfo;
  subscribedPackages: SubscribedPackageInfo[];
  eligiblePackages: EligiblePackageInfo[];
  packageSubscriptions: PackageSubscription[];
  lastVisitedPage: string;
}

export const InitialState = {
  clientIP: '127.0.0.1',
  paymentInfo: null,
  discountInfo: null,
  subscribedPackages: [],
  eligiblePackages: [],
  packageSubscriptions: [],
  lastVisitedPage: '',
} as InitialStateType;
