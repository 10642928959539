// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root.dwp-common {
  --theme-color-header: var(--theme-color-grey-4a);
  --theme-color-text: var(--theme-color-grey-a7);
  --theme-color-table-head: var(--theme-color-grey-c1);
  --theme-color-input-label: var(--theme-color-grey-7a);
}
`, "",{"version":3,"sources":["webpack://./src/themes/common.dealer.theme.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,8CAA8C;EAC9C,oDAAoD;EACpD,qDAAqD;AACvD","sourcesContent":[":root.dwp-common {\n  --theme-color-header: var(--theme-color-grey-4a);\n  --theme-color-text: var(--theme-color-grey-a7);\n  --theme-color-table-head: var(--theme-color-grey-c1);\n  --theme-color-input-label: var(--theme-color-grey-7a);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
