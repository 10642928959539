import { SubscriptionProps } from '../Subscription/SubscriptionProps';
import { getEligiblePackages, getPackagesSubscribed } from '../../services';

async function fetchPackages(subscriptionProps: SubscriptionProps) {
  // we should cover only the case when we have location provided but doesn't have accessToken yet.
  if (!subscriptionProps.accessToken && !subscriptionProps.vehicleDetails.vin && subscriptionProps.location) {
    // skip fetching this data in case if we don't have accessToken yet. Please refer to PORTAL-2779 for details.
    return Promise.resolve();
  }

  const subscribedPackages = await getPackagesSubscribed(subscriptionProps);
  const eligiblePackages = await getEligiblePackages(subscriptionProps);

  return Promise.resolve({ subscribedPackages, eligiblePackages });
}

export default fetchPackages;
