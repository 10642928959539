import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';

import { FormikInputField } from '@components/FormikFields';
import Widget from '@components/Widget/Widget';
import { Button } from '@components-lib';

import styles from './ResetPassword.module.css';

const initialValues = {
  userName: '',
};

type ResetPasswordFormProps = {
  onCancel: () => void;
  onSubmit: (
    { userName }: { userName: string },
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => Promise<void>;
  labels: Record<string, string>;
};

interface ResetPasswordForm {
  userName: string;
}

const ResetPasswordForm = ({ onCancel, onSubmit, labels }: ResetPasswordFormProps) => {
  const validationSchema = yup.object().shape({
    userName: yup.string().trim().required(labels.userNameRequired),
  });

  return (
    <Widget title={labels.title} titleAlignment="center">
      <p className={styles['forget-password-description']}>{labels.description}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          onSubmit(values, setFieldError, setSubmitting);
        }}
      >
        {(props: FormikProps<ResetPasswordForm>) => (
          <Form onSubmit={props.handleSubmit}>
            <FormikInputField name="userName" label={labels.userName} />
            <div className={clsx(styles.actions, styles['actions-reversed'])}>
              <Button
                variant="contained"
                className={styles.button}
                disabled={props.isSubmitting}
                onClick={props.submitForm}
              >
                {labels.submit}
              </Button>
              <Button variant="outlined" className={styles.button} onClick={onCancel}>
                {labels.cancel}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Widget>
  );
};

export default ResetPasswordForm;
