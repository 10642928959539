import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { RootState } from '@app/reducers';
import {
  fetchMarketingPreference,
  createNewMarketingPreference,
  updateCurrentMarketingPreference,
} from '@api/requests/marketingPreference';
import { TokenTypes } from '@api/services/StoreService';
import { getCookie } from '@utils/cookies';

export const readKey = 'readMarketingPreference';
export const createKey = 'createMarketingPreference';
export const updateKey = 'updateMarketingPreference';

export type HookOptions = {
  keyName: 'email' | 'phoneNumber' | 'smsNumber';
};

export type ReadCallbackOptions = {
  accessToken: string;
  userId: string;
  locale: string;
  smsNumber: string;
  phoneNumber: string;
  email: string;
  keyName: 'email' | 'phoneNumber' | 'smsNumber';
};

function useMarketingPreferences({ keyName }: HookOptions) {
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const locale: string = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en_US');

  type PreferenceAccountData = { primaryPhone: { number: string }; email: string };

  const {
    primaryPhone: { number: smsNumber },
    email,
  } = useSelector(
    ({ accountReducer }: RootState): PreferenceAccountData =>
      accountReducer.account?.data || { primaryPhone: { number: '' }, email },
  );

  const { data, refetch: readPreference } = useQuery({
    queryKey: [readKey, keyName],
    queryFn: () =>
      fetchMarketingPreference(
        {
          accessToken,
          userId,
          locale,
          smsNumber,
          phoneNumber: smsNumber,
          email,
        },
        keyName,
      ),
  });

  const { mutate: createPreference } = useMutation({
    mutationKey: [createKey, keyName],
    mutationFn: (optIn: boolean) =>
      createNewMarketingPreference({ accessToken, userId, locale, phone: smsNumber, email, optIn }),
  });

  const { mutate: updatePreference } = useMutation({
    mutationKey: [updateKey, keyName],
    mutationFn: (optIn: boolean) =>
      updateCurrentMarketingPreference({ accessToken, userId, locale, phone: smsNumber, email, optIn }),
  });

  return { data: data?.data, readPreference, createPreference, updatePreference };
}

export default useMarketingPreferences;
