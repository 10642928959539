import { styled } from '@mui/material';
import LinkToOkModal from '@lib-components/Modal/LinkToOkModal';

export const PackageDetailsLink: any = styled(LinkToOkModal)`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  color: white;
  font-size: 16px;

  &.package-details-link,
  &.package-details-link:hover {
    color: white;
  }

  &.package-details-link.link-open {
    text-decoration: underline !important;
  }
`;

export const Description = styled('h4')`
  text-align: left;
`;

export const ProductsContainer = styled('div')`
  max-height: 615px;
  overflow-y: scroll;
`;
