// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@property --theme-color-success {
  syntax: "<color>";
  initial-value: green;
  inherits: true;
}


.S4dCo95nAXhJmPWFCkEm {
  text-align: center;
}

.UEF2sjSODIp3pTpqkUwh {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.lsGLxN1KeTk9UxzeAOob {
  border-bottom: 1px solid var(--theme-color-table-head);
  color: var(--theme-color-header);
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.PjYH8gu25efnACTObzXl {
  color: var(--theme-color-table-head);
}

.WQeWjaCi2LESQf0F3FNg {
  display: list-item;
}
.Fh0XGxwV5nqYIOAN7poW {
  background-color: var(--theme-color-success);
}

.lwvgvQITK8dpn8L2LbII {
  background-color: rgb(52, 52, 52);
}
.ZKNwLz4vyhrMuCmJl2gB {
  color: var(--theme-color-success);
}

.A9vScvWGUo85ANGPP1X6 {
  color: black;
}

.tHBc3kaPFsNTaFXvzPVO {
  display: flex;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.tHBc3kaPFsNTaFXvzPVO button {
  min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/VinStatus/VinStatus.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,cAAc;AAChB;;;AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sDAAsD;EACtD,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,8BAA8B;EAC9B,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,4CAA4C;AAC9C;;AAEA;EACE,iCAAiC;AACnC;AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@property --theme-color-success {\n  syntax: \"<color>\";\n  initial-value: green;\n  inherits: true;\n}\n\n\n.container {\n  text-align: center;\n}\n\n.data {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  text-align: left;\n}\n\n.row {\n  border-bottom: 1px solid var(--theme-color-table-head);\n  color: var(--theme-color-header);\n  display: flex;\n  font-size: 0.875rem;\n  font-weight: bold;\n  justify-content: space-between;\n  margin-bottom: 1.5rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-bottom: 1rem;\n}\n\n.head {\n  color: var(--theme-color-table-head);\n}\n\n.status {\n  display: list-item;\n}\n.DotActive {\n  background-color: var(--theme-color-success);\n}\n\n.DotNotActive {\n  background-color: rgb(52, 52, 52);\n}\n.active {\n  color: var(--theme-color-success);\n}\n\n.notActive {\n  color: black;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  column-gap: 1rem;\n}\n\n.buttons button {\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `S4dCo95nAXhJmPWFCkEm`,
	"data": `UEF2sjSODIp3pTpqkUwh`,
	"row": `lsGLxN1KeTk9UxzeAOob`,
	"head": `PjYH8gu25efnACTObzXl`,
	"status": `WQeWjaCi2LESQf0F3FNg`,
	"DotActive": `Fh0XGxwV5nqYIOAN7poW`,
	"DotNotActive": `lwvgvQITK8dpn8L2LbII`,
	"active": `ZKNwLz4vyhrMuCmJl2gB`,
	"notActive": `A9vScvWGUo85ANGPP1X6`,
	"buttons": `tHBc3kaPFsNTaFXvzPVO`
};
export default ___CSS_LOADER_EXPORT___;
