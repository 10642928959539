// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YPaBHm4cZGTUU0bIfcmg {
  display: flex;
  align-items: flex-start;
  padding: 0.4rem 0;
}

.YPaBHm4cZGTUU0bIfcmg span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
  font-weight: bold;
}

.YPaBHm4cZGTUU0bIfcmg input {
  margin: 0;
}

.YPaBHm4cZGTUU0bIfcmg .YgMGwuCLdmfyxMixOcw3 {
  margin-left: 0;
  padding: 0;
}

.YPaBHm4cZGTUU0bIfcmg .xStqSbHCyE29LYHYuGYo {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputCheckbox/InputCheckbox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".InputCheckbox {\n  display: flex;\n  align-items: flex-start;\n  padding: 0.4rem 0;\n}\n\n.InputCheckbox span {\n  margin-left: 0.4rem;\n  font-size: 0.8rem;\n  font-weight: bold;\n}\n\n.InputCheckbox input {\n  margin: 0;\n}\n\n.InputCheckbox .InputCheckbox-button {\n  margin-left: 0;\n  padding: 0;\n}\n\n.InputCheckbox .InputCheckbox-input {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputCheckbox": `YPaBHm4cZGTUU0bIfcmg`,
	"InputCheckbox-button": `YgMGwuCLdmfyxMixOcw3`,
	"InputCheckbox-input": `xStqSbHCyE29LYHYuGYo`
};
export default ___CSS_LOADER_EXPORT___;
