// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JHhAVcJKG4wA_C4qi1Dc {
  display: grid;
}

.JHhAVcJKG4wA_C4qi1Dc > * {
  flex: 1 1 100%;
}

.sSVSFuSMdMzaH0ba4asJ {
  flex-direction: row;
}

.KPnQjDkNGAnYK4kJQhjk {
  flex-direction: column;
}

.i_tcriOCbXPal1vefCMq {
  flex-wrap: wrap;
}

.UEVP023x3nFjNYB5TNzB {
  flex-wrap: nowrap;
}

.p3Xf8YLWpbtr6oPeBgU8 {
  justify-content: flex-start;
  justify-items: flex-start;
}
.sKOCtWp1HG0mGAhIqM3c {
  justify-content: flex-end;
  justify-items: flex-end;
}
.Rnk56X6TqsWUBuLrINHE {
  justify-content: center;
  justify-items: center;
}
.WNjlLH9MXAHTIXQvzmbC {
  justify-content: space-between;
  justify-items: space-between;
}
.R7TNnpZKuwsPOd6i8089 {
  justify-content: space-around;
  justify-items: space-around;
}

.uCjkOIbFItH1DkU2nECd {
  align-items: flex-start;
  align-content: flex-start;
}
.PZd4LQoj2rutQ9NK1Xv_ {
  align-items: flex-end;
  align-content: flex-end;
}
.iKwnbBLGQ8_2OfS0pgmR {
  align-items: center;
  align-content: center;
}
.pYGqrdbyP2tmrHaCgBsd {
  align-items: stretch;
  align-content: stretch;
}
.c28B7tIvxgiV1AalyDOX {
  align-items: baseline;
  align-content: baseline;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContentfulGrid/ContentfulGrid.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,8BAA8B;EAC9B,4BAA4B;AAC9B;AACA;EACE,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".contentful-grid {\n  display: grid;\n}\n\n.contentful-grid > * {\n  flex: 1 1 100%;\n}\n\n.row {\n  flex-direction: row;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.justify-start {\n  justify-content: flex-start;\n  justify-items: flex-start;\n}\n.justify-end {\n  justify-content: flex-end;\n  justify-items: flex-end;\n}\n.justify-center {\n  justify-content: center;\n  justify-items: center;\n}\n.justify-space-between {\n  justify-content: space-between;\n  justify-items: space-between;\n}\n.justify-space-around {\n  justify-content: space-around;\n  justify-items: space-around;\n}\n\n.align-start {\n  align-items: flex-start;\n  align-content: flex-start;\n}\n.align-end {\n  align-items: flex-end;\n  align-content: flex-end;\n}\n.align-center {\n  align-items: center;\n  align-content: center;\n}\n.align-stretch {\n  align-items: stretch;\n  align-content: stretch;\n}\n.align-baseline {\n  align-items: baseline;\n  align-content: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentful-grid": `JHhAVcJKG4wA_C4qi1Dc`,
	"row": `sSVSFuSMdMzaH0ba4asJ`,
	"column": `KPnQjDkNGAnYK4kJQhjk`,
	"wrap": `i_tcriOCbXPal1vefCMq`,
	"nowrap": `UEVP023x3nFjNYB5TNzB`,
	"justify-start": `p3Xf8YLWpbtr6oPeBgU8`,
	"justify-end": `sKOCtWp1HG0mGAhIqM3c`,
	"justify-center": `Rnk56X6TqsWUBuLrINHE`,
	"justify-space-between": `WNjlLH9MXAHTIXQvzmbC`,
	"justify-space-around": `R7TNnpZKuwsPOd6i8089`,
	"align-start": `uCjkOIbFItH1DkU2nECd`,
	"align-end": `PZd4LQoj2rutQ9NK1Xv_`,
	"align-center": `iKwnbBLGQ8_2OfS0pgmR`,
	"align-stretch": `pYGqrdbyP2tmrHaCgBsd`,
	"align-baseline": `c28B7tIvxgiV1AalyDOX`
};
export default ___CSS_LOADER_EXPORT___;
