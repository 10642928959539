import React, { forwardRef, useMemo } from 'react';
import { useToggle } from '@app/components-lib';
import { Collapse, Divider, Stack } from '@mui/material';
import { getSortedServices } from './AllBenefits/allBenefitsUtils';
import { Typography, Box, Grid } from '@mui/material';
import { useCancelLabels } from '@components/Cancellation/CancelLabelsContext';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import useVehicle from '@api/queries/useVehicle';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import { EventDataBuilder, EventType, cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import ServiceItem from '../ServiceItem';
import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { Variant } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import NavigationProp from '@customTypes/NavigationProp';
import { TransitionGroup } from 'react-transition-group';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import StepScreenHeader from '../StepScreenHeader';

const isMonthly = (variant: Variant) => variant.initialTermUnit === TermUnit.Months && variant.renewalTerm < 12;

export type NextBestOfferProps = {
  onSelect: (pkg: EligiblePackageInfo) => void;
} & NavigationProp;

const NextBestOffer = forwardRef(({ onSelect, Navigation }: NextBestOfferProps, _ref) => {
  const [listExpanded, setListExpanded] = useToggle(false);

  const {
    nextBestOfferHeader,
    nextBestOfferSubheader,
    nextBestOfferPromoLabel,
    nextBestOfferEnrollNow,
    assets,
    descriptions,
    defaultShortDescription,
    defaultLongDescription,
    hideCompleteList,
    seeCompleteList,
    servicesHierarchy,
    currencyLabel,
    monthlyLabel,
    annuallyLabel,
  } = useCancelLabels();

  const { nextBestOffer, step } = useCancelMachineContext();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: nextBestOfferHeader,
    subsection: 'Cancelation > Next Best Offer',
  });

  const {
    data: { vin },
  } = useVehicle();

  const handleExpandList = () => setListExpanded(!listExpanded);
  const servicesToShowCollapsed = 3;

  const reorderedServices = useMemo(() => {
    const services = nextBestOffer?.services || nextBestOffer?.capableServices;
    return getSortedServices(servicesHierarchy, services);
  }, [nextBestOffer, servicesHierarchy]);

  const renderServiceItem = (service: VehicleServiceDetails) => (
    <Grid item xs={12} key={service.vehicleServiceId}>
      <ServiceItem
        service={service}
        assets={assets}
        descriptions={descriptions}
        defaultShortDescription={defaultShortDescription}
        defaultLongDescription={defaultLongDescription}
      />
    </Grid>
  );

  if (!nextBestOffer) {
    return <>{Navigation && <Navigation />}</>;
  }

  return (
    <Stack component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <Box>
        <StepScreenHeader>{nextBestOfferHeader}</StepScreenHeader>
        <h4>{nextBestOfferSubheader}</h4>
      </Box>
      {nextBestOffer && (
        <>
          <Grid
            container
            alignItems="center"
            mt={3}
            mb={3}
            sx={(theme) => ({
              gap: theme.spacing(3),
              [theme.breakpoints.up('sm')]: {
                gap: 0,
              },
            })}
          >
            <Grid item xs={12} sm={6} md={8}>
              <Typography variant="h4" fontWeight="fontWeightBold">
                {nextBestOfferPromoLabel}
              </Typography>
              <Typography fontWeight="fontWeightBold">{nextBestOffer?.marketingName}</Typography>
              <Typography fontWeight="fontWeightBold">
                {`${currencyLabel}${nextBestOffer.variant.listPrice} ${
                  isMonthly(nextBestOffer.variant) ? monthlyLabel : annuallyLabel
                }`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AnalyticsButton
                variant={nextBestOffer?.isSelected ? 'contained' : 'outlined'}
                onClick={() => {
                  if (nextBestOffer) {
                    onSelect(nextBestOffer);
                  }
                }}
                fullWidth
                analyticsEvent={
                  nextBestOffer && vin
                    ? new EventDataBuilder(EventType.EnrollCancelClick).withArgs({
                        text: nextBestOfferEnrollNow,
                        pageName: step,
                        vin,
                        enrollPackages: [nextBestOffer],
                      })
                    : undefined
                }
              >
                {nextBestOfferEnrollNow}
              </AnalyticsButton>
            </Grid>
          </Grid>
          <Divider />
          <TransitionGroup>
            <Grid container gap={3} mt={3}>
              {reorderedServices?.map((service, index) =>
                index < servicesToShowCollapsed ? (
                  renderServiceItem(service)
                ) : (
                  <Collapse in={listExpanded} key={service.vehicleServiceId} mountOnEnter unmountOnExit>
                    {renderServiceItem(service)}
                  </Collapse>
                ),
              )}
            </Grid>
          </TransitionGroup>
        </>
      )}
      <AnalyticsButton
        sx={{
          maxWidth: '11rem',
          textDecoration: 'underline',
          borderWidth: 0,
          justifyContent: 'flex-start',
          alignSelf: 'flex-start',
          marginBottom: '2rem',
        }}
        variant="text"
        onClick={handleExpandList}
        analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
          text: listExpanded ? hideCompleteList : seeCompleteList,
          pageName: step,
        })}
      >
        {listExpanded ? hideCompleteList : seeCompleteList}
      </AnalyticsButton>
      {Navigation && <Navigation />}
    </Stack>
  );
});

export default NextBestOffer;
