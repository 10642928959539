import styled from 'styled-components';

export const ServicesList = styled.ul`
  list-style-type: none;
`;

export const Service = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.625rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const ServiceContent = styled('div')`
  width: 90%;
  margin-left: 0.75rem;
`;
