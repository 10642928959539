import React from 'react';
import { BundleContent, Container, Header, PackageTitle, StyledToggleButton, StyledWrapper } from './styles';
import { EligiblePackageInfo } from '../../Types';
import { PackagePrice } from '../Price/PackagePrice';
import { CommonWebContent, ContentMap, ContentfulAssets } from '../..';
import CurrentPackage from '../CurrentPackage';

interface BundleProps {
  packageBundle: EligiblePackageInfo;
  commonWebContent: CommonWebContent;
  selected?: boolean;
  onSelect: (pkg: EligiblePackageInfo) => void;
  perTermsLabel: ContentMap;
  assets: ContentfulAssets;
  currentPackageLabel: string;
}

const EligibleBundle = ({
  packageBundle,
  commonWebContent,
  selected,
  onSelect,
  perTermsLabel,
  assets,
  currentPackageLabel,
}: BundleProps) => {
  const { shouldIncludeTax, currencyLabel } = commonWebContent;
  const { marketingName, longDescription, shortDescription, isPkgSubscribed, variant } = packageBundle;
  const { actualPrice, taxTotal, initialTerm } = variant;
  return (
    <Container>
      <Header>{marketingName}</Header>
      <BundleContent>
        <PackageTitle>{shortDescription}</PackageTitle>
        <span>{longDescription}</span>
        {isPkgSubscribed ? (
          <StyledWrapper>
            <CurrentPackage assets={assets} currentPackageLabel={currentPackageLabel} />
          </StyledWrapper>
        ) : (
          <StyledToggleButton
            disabled={isPkgSubscribed}
            variant={selected ? 'contained' : 'outlined'}
            onClick={() => onSelect(packageBundle)}
          >
            <PackagePrice
              shouldIncludeTax={shouldIncludeTax}
              perTermsLabel={perTermsLabel}
              currencyLabel={currencyLabel}
              amountWithoutTax={actualPrice}
              taxTotal={taxTotal}
              term={initialTerm}
            />
          </StyledToggleButton>
        )}
      </BundleContent>
    </Container>
  );
};

export default EligibleBundle;
