import React from 'react';

type SpacerProps = {
  spacing?: string;
};

const Spacer = ({ spacing = '0' }: SpacerProps): JSX.Element => (
  <div role="separator" style={{ width: spacing, height: spacing }} />
);

export default Spacer;
