import {
  DiscountInfo,
  EligiblePackageInfo,
  PackageSubscription,
  SubscribedPackageInfo,
} from '@manageSubscription/Types';
import { Actions, ActionTypes } from './actionTypes';
import { buildPackage } from '@manageSubscription/builders';
import { hasPackageAdded, isPaidPackage, isPromoPackage, isTiered, isTrialPackage } from '@manageSubscription/utils';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export const discountReducer = (state: DiscountInfo, action: Actions) => {
  if (action.type === ActionTypes.AddDiscountInfo || action.type === ActionTypes.RemoveDiscountInfo) {
    return action.payload;
  }
  return state;
};

export const subscribedPackageReducer = (state: SubscribedPackageInfo[], action: Actions) => {
  if (action.type === ActionTypes.AddSubscribedPackages) {
    return action.payload;
  }
  return state;
};

export const eligiblePackageReducer = (state: EligiblePackageInfo[], action: Actions) => {
  if (action.type === ActionTypes.AddEligiblePackages) {
    return action.payload;
  }
  return state;
};

export const packageSubscriptionReducer = (state: PackageSubscription[], action: Actions) => {
  switch (action.type) {
    case ActionTypes.AddTrialPackage: {
      if (hasPackageAdded(state, action.payload)) {
        return state;
      }
      return [...state, buildPackage(action.payload)];
    }

    case ActionTypes.AddAllTrialPackages: {
      return [...action.payload.map(buildPackage)];
    }

    case ActionTypes.AddPromoPackage: {
      let filtered = state;

      if (!action.allowMultiplePromoPackagesSelection) {
        filtered = state.filter((p) => !isPromoPackage(p.variant));
        return [...filtered, buildPackage(action.payload)];
      } else {
        filtered = action.promoPackages?.reduce<PackageSubscription[]>((acc, pkg) => {
          if (action.payload.autoSelectIds.includes(pkg.variant.id)) {
            pkg.disabled = true;
            return acc.filter((p) => p.variant.id !== pkg.variant.id);
          }
          return acc;
        }, state);
        return [...filtered, buildPackage(action.payload)];
      }
    }

    case ActionTypes.AddPackage: {
      const { packageType, tier } = action.payload;

      if (packageType === PackageType.Add_on) {
        return [...state, buildPackage(action.payload)];
      }
      if (isTrialPackage(action.payload.variant)) {
        return [...state, buildPackage(action.payload)];
      }

      const filtered = isTiered(tier)
        ? state.filter((p) => p.packageType !== PackageType.Add_on && !isPaidPackage(p.variant))
        : state;
      return [...filtered, buildPackage(action.payload)];
    }

    case ActionTypes.RemovePackage: {
      const {
        variant: { id: variantId },
        autoSelectIds,
      } = action.payload;

      action.promoPackages?.forEach((pkg) => {
        autoSelectIds.includes(pkg.variant.id) && (pkg.disabled = false);
      });

      return state.filter((p) => !(p.variant.id === variantId && hasPackageAdded(state, p)));
    }

    case ActionTypes.RemovePaidPackages: {
      return state.filter((p) => p.packageType !== PackageType.Add_on && !isPaidPackage(p.variant));
    }

    case ActionTypes.RemovePackageSubscriptions: {
      return [];
    }

    default:
      return state;
  }
};
