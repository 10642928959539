import React from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';

export interface FormControlLabelProps extends MuiFormControlLabelProps {}

export const FormControlLabel: React.FC<FormControlLabelProps> = (props) => {
  return <MuiFormControlLabel {...props} />;
};
