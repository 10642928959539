import React, { useState } from 'react';
import PayPal from './PayPal';
import { PaymentOptions } from '@lib-payment/Types';
import { usePaymentContext } from '../PaymentContext';
import { OkModal } from '@lib-components/Modal/OkModal';
import Modal, { useModal } from '@lib-components/Modal';
import { useUpdatePayment as useUpdatePaymentQuery } from '@lib-services/cps/queries';
import Spinner from '@lib-components/Spinner';
import Image from '@lib-components/Image';
import { IconSection } from '@styled-components/globalStyles';

interface PayPalPaymentProps {
  cancelEdit?: () => void;
}

const PayPalPayment = ({ cancelEdit }: PayPalPaymentProps) => {
  const {
    submitCallback,
    content: {
      paypalAgreementTermsErrorMsg,
      commonWebContent: { okButtonLabel },
      assets,
    },
    subscriptionProps,
  } = usePaymentContext();

  const { isShown, toggleModal } = useModal();
  const onPaymentError = () => toggleModal();
  const [isLoading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  const { mutate } = useUpdatePaymentQuery(subscriptionProps);

  const paymentErrorContent = () => {
    return <OkModal onConfirm={onPaymentError} buttonText={okButtonLabel} message={paypalAgreementTermsErrorMsg} />;
  };

  const setPaymentMethodAsDefault = (paymentMethodId: string) => {
    if (!paymentMethodId) {
      setApproved(false);
      toggleModal();
      return;
    }
    setLoading(true);

    mutate(
      {
        paymentMethodId,
        defaultPaymentMethod: true,
        cardType: PaymentOptions.PAYPAL,
      },
      {
        onSuccess: (response) => {
          submitCallback?.(response);
          cancelEdit?.();
        },
        onError: toggleModal,
        onSettled: () => {
          setLoading(false);
          setApproved(false);
        },
      },
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      {approved ? (
        <IconSection>
          <Image assets={assets} name="CheckCircle" size="45px" />
        </IconSection>
      ) : (
        <PayPal
          subscriptionProps={subscriptionProps}
          onApprove={() => setApproved(true)}
          setPaymentMethodId={setPaymentMethodAsDefault}
        />
      )}
      <Modal assets={assets} content={paymentErrorContent()} isShown={isShown} hide={toggleModal} />
    </>
  );
};

export default PayPalPayment;
