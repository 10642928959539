import { intersectionBy } from 'lodash';
import { EligiblePackageInfo } from '../components-lib/manageSubscription/Types';

export const haveProductsIntersection = (pkg: EligiblePackageInfo, selectedBy: EligiblePackageInfo) => {
  if (!selectedBy || selectedBy.variant.id === pkg.variant.id) {
    return false;
  }

  const haveInCommon = intersectionBy(selectedBy.products, pkg.products, 'id').filter((p) => !p.overlapAllowed);
  return !!haveInCommon.length;
};

export const shouldBeAutoSelectedBy = (pkg: EligiblePackageInfo, selectedBy: EligiblePackageInfo) => {
  if (pkg.id === selectedBy.id) {
    return false;
  }

  const discounts = selectedBy?.variant.discounts;
  const isTrialOrPromo = discounts.some(
    (discount) => discount.offerType === 'TRIAL' || discount.offerType === 'PROMOTIONAL',
  );

  if (isTrialOrPromo && selectedBy.bundle) {
    return haveProductsIntersection(pkg, selectedBy); // * if bundle have intersection by products with other pkg it means that this package is part of bundle
  }

  return false;
};

function calculatePackageDependencies(pkgs: EligiblePackageInfo[]) {
  const packages = pkgs.map(
    (p: EligiblePackageInfo) =>
      ({
        ...p,
        autoSelectIds: [],
        isSelected: false,
        isAutoSelected: false,
      } as EligiblePackageInfo),
  );

  //calculate autoSelect dependency array for every package
  //autoSelect property of a package is an array of ids of other packages that are going to be autoSelected with it
  packages.forEach((pkg) => {
    pkg.autoSelectIds = packages.filter((p) => shouldBeAutoSelectedBy(p, pkg)).map((p) => p.variant.id);
  });

  return packages;
}

export default calculatePackageDependencies;
