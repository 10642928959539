import { useQuery } from '@tanstack/react-query';
import { listPayments } from '@cv/portal-cps-lib/payment';
import { formatPaymentData } from '@api/formatters/personalData';
import useAccount from './useAccount';
import { PaymentMethodRequest, PaymentMethodResponse } from '@cv/portal-cps-lib/payment/payment-methods/models';
import { useSelector } from 'react-redux';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import { useConfig } from '@components/ConfigProvider';

type PaymentMethod = {
  cardHolderName: string;
  cardNumber: string;
  cardType: string;
  expirationMonth: string;
  expirationYear: string;
  paymentMethodId: string;
};

const usePaymentMethod = () => {
  const { data: account } = useAccount();
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  const { data } = useQuery<PaymentMethod>({
    enabled: !!account?._id,
    queryKey: ['paymentMethods'],
    queryFn: async () => {
      const payload: PaymentMethodRequest = {
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
        data: {
          userId: account?._id,
        },
      };
      const response = await listPayments(payload);
      return response.data;
    },
    select: (_data) => formatPaymentData(_data as PaymentMethodResponse[]),
  });

  return { data };
};

export default usePaymentMethod;
