import React, { useState, useEffect } from 'react';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import InfoCardContent from '@components/InfoCard/InfoCardContent';
import { HealthReport } from '@cv/portal-rts-lib/health-reports/models';
import InputSelect from '@components/InputSelect';
import { Formik, Form } from 'formik';
import { FormikInputField } from '@components/FormikFields';
import { ContentfulImage } from '@cv/webframework-react-components';
import { TContentColor, InfoCardContentProps } from './InfoCardContent';
import useFetchVehicleHealthReports from '@hooks/useFetchVehicleHealthReports';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import _ from 'lodash';
import styles from './infoCard.module.css';

export type CTAVHRBlockProps = InfoCardContentProps & {
  openIcon?: ContentfulFile;
  noReportError: string;
  accessReportError: string;
  componentType?: string;
  contentColor: TContentColor;
  dateTableLabel?: string;
  openTableLabel?: string;
};

type TFiltredReports = {
  [key: string]: HealthReport[];
};

const CTAVHRBlock = (props: CTAVHRBlockProps): JSX.Element | null => {
  const { noReportError, accessReportError, componentType, openIcon, dateTableLabel, openTableLabel, ...restProps } =
    props;

  const [error, setError] = useState<string | null>(null);
  const [isVHREntitled, setIsVHREntitled] = useState(false);
  const [filteredReports, setFilteredReports] = useState<TFiltredReports>({});
  const [currentReports, setCurrentReports] = useState<TFiltredReports>({});

  const { fetchVehicleHealthReports } = useFetchVehicleHealthReports();

  const vehicleHealthReports = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicleHealthReports);

  const {
    data: { services },
  } = useSelector(({ accountReducer }: RootState) => accountReducer.vehicle);

  const lightColor = 'var(--theme-color-background)';
  const darkColor = 'var(--theme-color-text)';
  const inlineStyles = {
    ...(props.contentColor === TContentColor.LIGHT
      ? { color: lightColor, borderColor: lightColor }
      : { color: darkColor, borderColor: darkColor }),
  };

  useEffect(() => {
    setIsVHREntitled(!!services?.find((vehicleServiceName: string) => vehicleServiceName === 'Vehicle Health Report'));

    fetchVehicleHealthReports().catch(() => {
      setError(accessReportError || 'fetch VHR error');
    });
  }, []);

  useEffect(() => {
    if (!!vehicleHealthReports?.length) {
      filterReports(vehicleHealthReports);
      setError(null);
    } else {
      setError(noReportError || 'No reports');
    }
  }, [noReportError, vehicleHealthReports]);

  useEffect(() => {
    if (!!Object.keys(filteredReports).length) {
      setCurrentReports({ [Object.keys(filteredReports)[0]]: filteredReports[Object.keys(filteredReports)[0]] });
    }
  }, [filteredReports]);

  const currentReport = Object.values(currentReports)[0];

  const filterReports = (reports: HealthReport[]) => {
    const result: TFiltredReports = {};
    reports.sort((a, b) => new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime());
    reports.forEach((val) => {
      const [year, month] = val.reportDate.split('-');
      const reportKey = `${month}/${year}`;
      _.defaults(result, { [reportKey]: [] });
      result[reportKey].push(val);
    });
    setFilteredReports(result);
  };

  const selectReports = (r) => {
    setCurrentReports({ [r.label]: r.value });
  };

  const renderVHR = () => {
    const optionsList = Object.keys(filteredReports).map((item) => {
      return {
        label: item,
        value: filteredReports[item],
      };
    });

    return (
      <div className={styles['vhr-report']}>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <FormikInputField
              name="Select date"
              InputComponent={InputSelect}
              onChange={selectReports}
              options={optionsList}
              styleType="secondary"
              value={currentReport}
            />
          </Form>
        </Formik>
        {!!currentReport && (
          <ul className={styles['vhr-table']} style={inlineStyles}>
            {dateTableLabel && openTableLabel && (
              <li key="header" className={`${styles['vhr-table-row']} ${styles['vhr-table-header']}`}>
                <div className={styles['vhr-table-cell']}>{dateTableLabel}</div>
                <div className={styles['vhr-table-cell']}>{openTableLabel}</div>
              </li>
            )}
            {currentReport.map((r, i) => {
              return (
                <li key={i + r.reportDate} className={styles['vhr-table-row']}>
                  <div className={styles['vhr-table-cell']}>{r.reportDate}</div>
                  <div className={styles['vhr-table-cell']}>
                    <a
                      className={styles['vhr-download-link']}
                      href={r.link.uri}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Open report"
                    >
                      {openIcon ? (
                        <ContentfulImage className={styles['vhr-download-icon']} imageData={openIcon} />
                      ) : (
                        'Link'
                      )}
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };
  if (!isVHREntitled) return null;
  const render = !!error ? <p>{error}</p> : renderVHR();
  return (
    <InfoCardContent
      {...restProps}
      shortDescription={!!error ? '' : restProps.shortDescription}
      description=""
      component={render}
    />
  );
};

export default CTAVHRBlock;
