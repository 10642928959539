import React from 'react';
import { Background, CloseButton, Header, HeaderText, ModalContent, StyledModal, Wrapper } from './styles';
import ReactDOM from 'react-dom';
import Image from '../../components/Image';
import { ContentfulAssets } from '@manageSubscription';

interface ModalProps {
  header?: string;
  content: JSX.Element;
  hide: () => void;
  isShown: boolean;
  assets?: ContentfulAssets;
  flexibleSize?: boolean;
}

const Modal = ({ header, content, hide, isShown, assets, flexibleSize = false }: ModalProps) => {
  const modal = (
    <React.Fragment>
      <Background onClick={hide} />
      <Wrapper aria-modal aria-labelledby={header} role="dialog">
        <StyledModal>
          {(header || assets) && (
            <Header>
              {header && <HeaderText>{header}</HeaderText>}
              {assets && (
                <CloseButton onClick={hide} title="Exit">
                  <Image name="Close" assets={assets} />
                </CloseButton>
              )}
            </Header>
          )}
          <ModalContent>{content}</ModalContent>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
