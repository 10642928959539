import { useDispatch } from 'react-redux';
import { VEHICLE_ACTIONS } from '@redux/actions';
import { setLoadingStatus } from '@redux/actions/loading';
import { useApi } from '@api';
import { useVehicleHealthSelector } from '@redux/selectors/vehicle';

const useFetchVehicleHealth = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const vehicleHealth = useVehicleHealthSelector();

  const fetchVehicleHealth = async ({ allowBackgroundUpdate = false } = {}) => {
    // Update health data in background if we already have it fetched
    if (vehicleHealth.initial || !allowBackgroundUpdate) {
      dispatch(setLoadingStatus(true));
    }

    try {
      const data = await api.getVehicleHealth();
      dispatch({ type: VEHICLE_ACTIONS.SET_VEHICLE_HEALTH, data });
      return data;
    } catch (e) {
      // TODO add error handler for this API..check how legacy does it
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  return { fetchVehicleHealth };
};

export default useFetchVehicleHealth;
