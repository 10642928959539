import { getJourney, updateJourney, addJourney, deleteJourney } from '@cv/portal-rts-lib/v0/information/journey';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  JourneyRequest,
  UpdateJourneyRequest,
  AddJourneyRequest,
  AddJourney,
  Journey,
} from '@cv/portal-rts-lib/v0/information/journey/models';

type GetJourneyRequestProps = {
  accessToken: string;
  vin: string;
  folderId: string;
  channelId: string;
};

type UpdateJourneyRequestProps = {
  accessToken: string;
  id_token: string;
  vin: string;
  data: Journey;
};

type AddJourneyRequestProps = {
  accessToken: string;
  id_token: string;
  vin: string;
  data: AddJourney;
};

type deleteJourneyRequestProps = {
  accessToken: string;
  id_token: string;
  vin: string;
  folderId: string;
  channelId: string;
};

export default class JourneyService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getJourney({ accessToken, vin, folderId, channelId }: GetJourneyRequestProps) {
    const request = {
      environment: this.environment,
      queryParams: {
        folderId,
        channelId,
      },
      headers: {
        Authorization: accessToken,
        vin,
      },
      data: {},
    } as JourneyRequest;

    const response = await getJourney(request);
    return { data: response.data };
  }

  async updateJourney({ accessToken, id_token, vin, data }: UpdateJourneyRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token,
        vin,
      },
      data,
    } as UpdateJourneyRequest;

    const response = await updateJourney(request);
    return { data: response.data };
  }

  async addJourney({ accessToken, id_token, vin, data }: AddJourneyRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token,
        vin,
      },
      data,
    } as AddJourneyRequest;

    const response = await addJourney(request);
    return { data: response.data };
  }

  async deleteJourney({ accessToken, id_token, vin, folderId, channelId }: deleteJourneyRequestProps) {
    const request = {
      environment: this.environment,
      queryParams: {
        folderId,
        channelId,
      },
      headers: {
        Authorization: accessToken,
        id_token,
        vin,
      },
      data: {},
    } as JourneyRequest;

    const response = await deleteJourney(request);
    return { data: response.data };
  }
}
