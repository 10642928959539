import React from 'react';
import { Typography } from '@mui/material';
import { CenterAlignedContent, Markdown } from '../styles';
import { usePaymentContext } from '../../PaymentContext';
import Image from '@lib-components/Image';
import { PAYMENT_LABEL_IMAGE_SIZE } from '@lib-payment/Types';

type CardLabelPops = {
  children: React.ReactNode;
  type: string;
};

const CardLabel: React.FC<CardLabelPops> = ({ children, type }) => {
  const {
    content: { assets, fontStyle = 'normal', cardTypes = {} },
  } = usePaymentContext();

  return (
    <CenterAlignedContent>
      <Image inline assets={assets} name={type} size={PAYMENT_LABEL_IMAGE_SIZE} />
      <Typography variant="body2" fontStyle={fontStyle} sx={{ m: 0 }}>
        <Markdown>{cardTypes[type] ?? type}</Markdown> {children}
      </Typography>
    </CenterAlignedContent>
  );
};

export default CardLabel;
