import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { formatDateByLocale } from '@cv/portal-common-lib';
import { RootState } from '@app/reducers';

type DateUtility = (date: string) => string;

const useLocalDate = (customDateFormat?: string): DateUtility => {
  const dateFormat = useSelector(
    ({ preferencesReducer }: RootState) => preferencesReducer.preferences.dateFormat || 'MM/dd/yyyy',
  );
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer.locale);

  return useCallback(
    (date: string) => formatDateByLocale(date, customDateFormat || dateFormat, locale),
    [dateFormat, customDateFormat, locale],
  );
};

export default useLocalDate;
