import React from 'react';
import { usePaymentContext } from '../PaymentContext';

import PaymentButton, { PaymentButtonProps } from './PaymentButton';

interface PaymentButtonContainerProps extends PaymentButtonProps {
  paymentMethod?: string;
}

const PaymentButtonContainer: React.FC<PaymentButtonContainerProps> = ({ paymentMethod, ...rest }) => {
  const { subscriptionProps, content } = usePaymentContext();

  const { cancelLinkLabel } = content.commonWebContent;

  return (
    <PaymentButton
      buttonText={content.commonWebContent.saveAndContinueButtonLabel}
      legalText={content.paymentTerms}
      cancelText={cancelLinkLabel}
      additionalLegalText={content.additionalPaymentTerms}
      subscriptionProps={subscriptionProps}
      {...rest}
    />
  );
};

export default PaymentButtonContainer;
