import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { retrieveCalendar, saveCalendar, deleteCalendar } from '@cv/portal-rts-lib/v0/information/calendar';
import {
  RetrieveCalendarRequest,
  SaveCalendarRequest,
  SaveCalendar,
  DeleteCalendarRequest,
  CalendarOptions,
} from '@cv/portal-rts-lib/v0/information/calendar/models';
import { convertToRead } from '@api/formatters/convertCalendar';

export default class CalendarService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async retrieveCalendar({ accessToken, vin }: CalendarOptions) {
    const request: RetrieveCalendarRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data: {},
    };

    return retrieveCalendar(request).then(({ data }) => convertToRead(data));
  }

  async saveCalendar({ accessToken, vin, data }: SaveCalendar) {
    const request: SaveCalendarRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data,
    };

    return saveCalendar(request);
  }

  async deleteCalendar({ accessToken, vin }: CalendarOptions) {
    const request: DeleteCalendarRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data: {},
    };

    return deleteCalendar(request);
  }
}
