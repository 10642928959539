import React from 'react';

import OutsideClick from '@components/OutsideClick';

import styles from './Header.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';

type HeaderMobileMenuProps = {
  children?: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
};

export default function HeaderMobileMenu({ children, onClose, isVisible }: HeaderMobileMenuProps) {
  const mobileMenuClassName = `${styles['Header-mobile-menu']} ${isVisible ? styles['show'] : ''}`;
  const theme = useTheme();
  return (
    <div className={mobileMenuClassName}>
      <OutsideClick onClick={onClose}>
        <div className={styles['Header-mobile-menu-inner']} style={{ backgroundColor: theme.palette.background.paper }}>
          <CloseIcon
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.getContrastText(theme.palette.background.paper),
              cursor: 'pointer',
            })}
          />

          {children}
        </div>
      </OutsideClick>
    </div>
  );
}

HeaderMobileMenu.defaultProps = {
  children: null,
  isVisible: false,
};
