import React from 'react';
import clsx from 'clsx';
import { IVehicle } from '@redux/actions';

import styles from './VehicleSwitcher.module.css';

type VehicleItem = {
  label: string;
  onVehicleSelect: () => void;
  disabled?: boolean;
};

export default function VehicleItem({ label, onVehicleSelect, disabled }: VehicleItem) {
  return (
    <li onClick={onVehicleSelect} className={clsx(styles['VehicleItem'], { [styles['disabled']]: disabled })}>
      {label}
    </li>
  );
}
