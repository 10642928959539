import config from '@config/config';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { userRoleLink } from '@cv/portal-idm-lib/user/user-role';
import { userRoleUrls } from '@cv/portal-idm-lib/user/user-role/enums';
import { UserRoleLink } from '@cv/portal-idm-lib/user/user-role/models';
import { formatUserData } from '../formatters/';

type UserLinkRoleArgs = {
  userId: string;
  roleId?: string;
  tenantId: string;
  accessToken: string;
  locale: string;
};

export default class AccountLinkService {
  private environment: Environment;
  private defaultRoleId: string;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
    this.defaultRoleId = config.getOemValue('DEFAULT_USER_ROLE_ID');
  }

  userRoleLink({ userId, roleId = this.defaultRoleId, tenantId, accessToken, locale }: UserLinkRoleArgs) {
    let API_BASE_URL = 'IDM_API_PROXY_BASE_URL';
    const sourceChannel = config.getOemValue('SOURCE_CHANNEL');

    const payload: UserRoleLink = {
      pathParams: { userId, roleId },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };
    if (sourceChannel === 'CWP') {
      API_BASE_URL = 'SHIM_IDM_API_BASE_URL';
      payload.headers['CV-Tenant-Id'] = tenantId;
    }

    const idmApiProxyBaseUrl = config.get<string>(API_BASE_URL);
    if (idmApiProxyBaseUrl) {
      const { userRoleLink } = userRoleUrls;
      const path = userRoleLink.replace('{userId}', userId).replace('{roleId}', roleId);

      const url = new URL(path, idmApiProxyBaseUrl);
      payload.url = url.toString();
    }

    return userRoleLink(payload).then((response) => ({ data: formatUserData(response.data) }));
  }
}
