export enum BOUNDARY_ACTIONS {
  SET_BOUNDARIES = 'SET_BOUNDARIES',
  ADD_BOUNDARY = 'ADD_BOUNDARY',
  UPDATE_BOUNDARY = 'UPDATE_BOUNDARY',
  DELETE_BOUNDARY = 'DELETE_BOUNDARY',
  SET_BOUNDARY_COORDINATES = 'SET_BOUNDARY_COORDINATES',
  SET_BOUNDARY_RADIUS = 'SET_BOUNDARY_RADIUS',
  SET_MULTIPLE_BOUNDARY_COORDINATES = 'SET_MULTIPLE_BOUNDARY_COORDINATES',
  RESET_MULTIPLE_BOUNDARY_COORDINATES = 'RESET_MULTIPLE_BOUNDARY_COORDINATES',
  UPDATE_BOUNDARY_COORDINATES = 'UPDATE_BOUNDARY_COORDINATES',
  DELETE_BOUNDARY_COORDINATES = 'DELETE_BOUNDARY_COORDINATES',
  SET_RADIUS = 'SET_RADIUS',
  RESET_BOUNDARY_AND_RADIUS = 'RESET_BOUNDARY_AND_RADIUS',
}
