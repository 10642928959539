import { UI_ACTIONS } from '../actions/';
import urlSearchParams from '@utils/urlSearchParams';
import { getRawParamValue } from '@utils/url';
import { ROOT_EVENTS } from '@app/reducers';

type UIState = {
  vehicleId: string;
  campaignCode: string;
};

type UIAction = {
  type: UI_ACTIONS | ROOT_EVENTS;
  data: string;
};

const initState = (): UIState => {
  const { utm_campaign } = urlSearchParams.getAll();
  const campaign = utm_campaign ? utm_campaign : getRawParamValue('utm_campaign');
  return {
    vehicleId: '',
    campaignCode: campaign || '',
  } as UIState;
};

const uiReducer = (state: UIState = initState(), action: UIAction) => {
  switch (action.type) {
    case UI_ACTIONS.SET_SELECTED_VEHICLE_ID:
      return { ...state, vehicleId: action.data };
    case UI_ACTIONS.SET_CAMPAIGN_CODE:
      return { ...state, campaignCode: action.data };
    case ROOT_EVENTS.CLEAR_CACHE:
    case ROOT_EVENTS.DESTROY_SESSION:
      return {
        ...state,
        vehicleId: '',
        campaignCode: '',
      };
    default:
      return state;
  }
};

export default uiReducer;
