import React from 'react';
import { useFlowMachineContextSubscriptionProps } from '../../Router/RouterContext';
import { Legal } from './Legal';

interface LegalSectionProps {
  legalText: string;
  termsAgreed?: boolean;
  setTermsAgreed?: any;
  showCheckbox?: boolean;
  analyticsEventName?: string;
}

export const LegalContainer = (props: LegalSectionProps) => {
  const subscriptionProps = useFlowMachineContextSubscriptionProps();

  return <Legal {...props} subscriptionProps={subscriptionProps} />;
};
