import { IVehicle, setAccountData, setUserPinStatus, setVehicleIds } from '@redux/actions';
import { setPackages, setSubscribedServices, setVehicleVins } from '@redux/actions/user';

import Api from '../api/Api';
import { Dispatch } from 'redux';
import { getTokenUserAndTenant } from './token';
import { uniq } from 'lodash';

type DispatchApiProps = {
  dispatch: Dispatch;
  api: Api;
};

type GetUserDataProps = DispatchApiProps & {
  accessToken: string;
  refreshToken: string;
  selectedVehicleId?: string;
  onSuccess?: () => void;
};

type GetVinSubscribedPackagesProps = DispatchApiProps & {
  vehicleIds: string[];
  userId?: string;
};

export const getVinSubscribedPackages = async ({
  dispatch,
  vehicleIds,
  api,
  userId,
}: GetVinSubscribedPackagesProps) => {
  const _subscribed = await api.getVinSubscribedPackages(vehicleIds, userId);
  if (_subscribed) {
    dispatch(setPackages(_subscribed));
    dispatch(setSubscribedServices(_subscribed));
  }
  return _subscribed;
};

export const getUserData = async ({
  accessToken,
  refreshToken,
  dispatch,
  api,
  selectedVehicleId,
  onSuccess,
}: GetUserDataProps) => {
  const getVinStatus = async (vehicleIds: string[]) => {
    try {
      const vehiclesData: IVehicle[] = await api.getVehiclesData(vehicleIds);
      dispatch(setVehicleVins(vehiclesData));
      const currentVehicleData =
        vehiclesData.find((vehicle) => vehicle.vehicleId === selectedVehicleId) || vehiclesData[0];
      currentVehicleData.activeServices = vehiclesData.reduce<string[]>((acc, item) => {
        return uniq([...acc, ...item.activeServices]);
      }, []);
      api.storeService.setVehicleData(currentVehicleData);
    } catch (error) {
      console.error('sxm vehicle vin error', error);
    }
  };

  api.storeService.setTokens({
    accessToken,
    refreshToken,
  });

  const { userId } = getTokenUserAndTenant(accessToken);
  api.storeService.setUserID(userId);

  try {
    const { data } = await api.getAccountDetails(undefined, userId);
    if (data) {
      dispatch(setVehicleIds(data?.vehicleIds || []));
      dispatch(setAccountData(data));
      dispatch(setUserPinStatus(data?.isPinConfigured || false));
      await getVinSubscribedPackages({
        vehicleIds: data?.primarySubscriberVehicleIds || [],
        api,
        dispatch,
        userId,
      });
      await getVinStatus(data?.primarySubscriberVehicleIds || []);

      onSuccess?.();
    }
  } catch (error) {
    console.error('sxm account error', error);
  }
};
