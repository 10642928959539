import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import useLinkConfig from '@hooks/useLinkConfig';
import LinkButton from './LinkButton';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';

import styles from './Link.module.css';

const Icon = ({ icon, position }: { icon?: ContentfulFile; position?: string }) => (
  <ContentfulImage
    className={clsx(styles['LinkIcon-image'], styles[`LinkIconPosition--${position}`])}
    imageData={icon}
  />
);

export type LinkProps = {
  name: string;
  label: string;
  url: string;
  newTab?: boolean;
  navigationLink?: boolean;
  showAsButton?: boolean;
  icon?: ContentfulFile;
  iconPosition?: 'right' | 'left';
  linkConfig?: string;
  analyticsEventName?: string;
  buttonSize: 'small' | 'medium' | 'large';
  phone: string;
  align: 'left' | 'center' | 'right';
};

const Link = ({
  label,
  url: staticUrl,
  newTab,
  navigationLink,
  showAsButton,
  icon,
  iconPosition,
  linkConfig,
  analyticsEventName,
  buttonSize,
  phone,
  align,
}: LinkProps): JSX.Element => {
  const history = useHistory();
  const url = useLinkConfig(staticUrl, linkConfig);

  const formatEventName = formatAnalyticsEventName(analyticsEventName);

  if (showAsButton) {
    return (
      <LinkButton
        formatEventName={formatEventName}
        label={label}
        url={url}
        newTab={newTab}
        size={buttonSize}
        phone={phone}
        justifySelf={align}
      />
    );
  }

  if (navigationLink) {
    return (
      <a className={styles.Link} href={url} data-analyticseventname={formatEventName}>
        {label}
      </a>
    );
  }

  return (
    <a
      className={icon ? styles['LinkIcon'] : styles.Link}
      href={url}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        const win = window.open(url, '_blank');
        win?.focus();
      }}
      data-analyticseventname={formatEventName}
    >
      {label}
      {icon && <Icon icon={icon} position={iconPosition} />}
    </a>
  );
};

export default Link;
