import React, { useEffect } from 'react';

import { useAnalytics } from '@cv/webframework-react-components';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
type AnalyticsTrackViewProps = {
  analyticsEventName?: string;
  children: React.ReactNode;
};

export default function AnalyticsTrackView({ analyticsEventName, children }: AnalyticsTrackViewProps): JSX.Element {
  const { trackEvent } = useAnalytics();
  const formatEventName = formatAnalyticsEventName(analyticsEventName);

  useEffect(() => {
    if (formatEventName) {
      trackEvent(formatEventName, 'pageView');
    }
  }, [formatEventName]);

  return <>{children}</>;
}
