import React from 'react';
import { useHistory } from 'react-router-dom';
import useLabels, { Label } from '@hooks/useLabels';
import { partition } from 'lodash';
import { RichTextContainerProps } from '@components/RichTextContainer';
import ServiceOverview from './ServicesOverview';

import { CardView } from '@components/Card';

import styles from './ServicesOverview.module.css';

type PortalLabelDictionary = {
  componentType: 'portalLabelDictionary';
  content: Label[];
};

type PortalRichText = RichTextContainerProps & {
  componentType: 'portalRichText';
};

type ServiceOverviewPageProps = {
  content: [PortalLabelDictionary, PortalRichText];
};

const ServiceOverviewPage = ({ content }: ServiceOverviewPageProps) => {
  const [labels, richText] = partition(content, ({ componentType }) => componentType === 'portalLabelDictionary');
  const combinedLabels = (labels as PortalLabelDictionary[]).reduce(
    (acc, label) => [...acc, ...label.content],
    [] as Label[],
  );
  const dictionary = useLabels(combinedLabels);

  const history = useHistory();

  const handleNext = () => {
    history.push('/termsConditions');
  };

  const handlePrev = () => {
    history.goBack();
  };

  return (
    <CardView type="main" classes={{ card: styles.card }}>
      <ServiceOverview
        classes={{
          title: styles.title,
        }}
        labelTitle={dictionary.getPrimary('title', 'RESUMEN DE SERVICIOS')}
        labelContinue={dictionary.getPrimary('nextButton', 'CONTINUAR')}
        onContinue={handleNext}
        labelCancel={dictionary.getPrimary('prevButton', 'ATRÁS')}
        onCancel={handlePrev}
        content={richText[0]}
      />
    </CardView>
  );
};

export default ServiceOverviewPage;
