import Modal from '@lib-components/Modal';
import { YesNoModal } from '@lib-components/Modal/YesNoModal';
import React from 'react';
import { usePaymentContext } from '../../PaymentContext';

export interface DeletePaymentModalProps {
  children: string;
  onYes: () => void;
  onNo: () => void;
  onCancel: () => void;
  isShown: Boolean;
}

const DeletePaymentModal: React.FC<DeletePaymentModalProps> = ({ children, onYes, onNo, onCancel, isShown }) => {
  const { content } = usePaymentContext();
  const { commonWebContent } = content;

  if (!isShown) {
    return null;
  }

  return (
    <Modal
      isShown
      content={
        <YesNoModal
          message={children}
          noText={commonWebContent.noLabel}
          yesText={commonWebContent.yesLabel}
          onYes={onYes}
          onNo={onNo}
        />
      }
      hide={onCancel}
    />
  );
};

export default DeletePaymentModal;
