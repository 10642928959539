import * as Yup from 'yup';
import { SubscriptionProps, WebContent } from '@manageSubscription/Subscription';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';

export type SubaruFields = {
  preferredLanguage: Yup.StringSchema;
};

export type GetBrandFields = (subscriptionProps: SubscriptionProps, content: WebContent) => SubaruFields | {};

const getBrandFields: GetBrandFields = (subscriptionProps, content) => {
  const { tenantId } = subscriptionProps;
  const { preferredLanguageRequiredError } = content.userProfileWebContent;

  const subaruFields: SubaruFields = {
    preferredLanguage: Yup.string().required(preferredLanguageRequiredError),
  };

  if (tenantId === TenantIds.Subaru) {
    return subaruFields;
  }

  return {};
};

export default getBrandFields;
