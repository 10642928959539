import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ContentfulImage } from '@cv/webframework-react-components';
import { ActionCardProps } from '@components/ActionCard';
import styles from './ActionCardLayout.module.css';

export type ActionCardLayoutProps = Pick<ActionCardProps, 'title' | 'subtitle' | 'description'> &
  Partial<Pick<ActionCardProps, 'icon'>> & {
    className?: string;
    children: ReactNode;
    topRightIcon?: ReactNode | null;
  };

const ActionCardLayout = ({
  className = '',
  title,
  icon,
  subtitle,
  description,
  children,
  topRightIcon = null,
}: ActionCardLayoutProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>
      {icon && <ContentfulImage className={styles.icon} imageData={icon} />}
      <h3 className={styles.subtitle}>{subtitle}</h3>
      <p className={styles.description}>{description}</p>
      {children}
    </div>
    {topRightIcon}
  </div>
);

export default ActionCardLayout;
