import { PaymentInfo } from '../../payment/Types';
import { Actions, ActionTypes } from './actionTypes';

export const paymentReducer = (state: PaymentInfo, action: Actions) => {
  if (action.type === ActionTypes.AddPaymentMethodId) {
    const { paymentMethodId } = action.payload;
    return { ...state, paymentMethodId };
  } else if (action.type === ActionTypes.AddPaymentOption) {
    const { paymentOption } = action.payload;
    return { ...state, paymentOption };
  }
  return state;
};
