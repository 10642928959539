import React from 'react';

type ActiveViewProps = {
  children: React.ReactNode | React.ReactNode[];
  currentView: string;
  activeView: string;
};

export default function ActiveView({ currentView, activeView, children }: ActiveViewProps) {
  if (currentView !== activeView) {
    return null;
  }
  return <>{children}</>;
}
