import React from 'react';
import Link from '../../../components/Link';
import { useFlowMachine, useFlowMachineContext, useFlowMachineContextFlowStorage } from '../../../Router/RouterContext';
import { Container } from '../../../styled-components/globalStyles';
import { SubmitCallback, SubscriptionInfo } from '../../Types';
import { PaymentContextProvider } from './Payment';

type StateMachineWrapperProps = {
  navigateCancel?: () => void;
  submitCallback?: SubmitCallback;
  subscriptionInfo: SubscriptionInfo;
};

const StateMachineWrapper = ({ navigateCancel, submitCallback, subscriptionInfo }: StateMachineWrapperProps) => {
  const flowMachine = useFlowMachine();
  const { send } = flowMachine;
  const { subscriptionProps, content } = useFlowMachineContext();
  const { clientIP } = useFlowMachineContextFlowStorage();

  return (
    <Container>
      <PaymentContextProvider
        submitCallback={submitCallback}
        subscriptionProps={subscriptionProps}
        subscriptionInfo={subscriptionInfo}
        content={content}
        send={send}
        clientIP={clientIP}
        managePage
      />
      <Link component="button" onClick={navigateCancel} sx={{ alignSelf: 'center' }}>
        {content?.commonWebContent?.backButtonLabel}
      </Link>
    </Container>
  );
};

export default StateMachineWrapper;
