import React from 'react';
import { ContentfulImage } from '@cv/webframework-react-components';

import styles from './Header.module.css';

type HeaderServiceProps = {
  imageData: object;
};

export default function HeaderService({ imageData }: HeaderServiceProps) {
  return (
    <a href="/" className={styles['Header-service']} rel="noopener noreferrer">
      <ContentfulImage imageData={imageData} />
    </a>
  );
}
