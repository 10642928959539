import React from 'react';
import Button from '../../components/Button';
import { ButtonPanel, Message } from './styles';

interface OkModalProps {
  message: React.ReactNode;
  buttonText: string;
  onConfirm: () => void;
}

export const OkModal = ({ message, buttonText, onConfirm }: OkModalProps) => {
  return (
    <React.Fragment>
      <Message>{message}</Message>
      <ButtonPanel>
        <Button onClick={onConfirm}>{buttonText}</Button>
      </ButtonPanel>
    </React.Fragment>
  );
};
