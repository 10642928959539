import { omitBy, isNil } from 'lodash';

export const nonNil = (object: Object) => omitBy(object, isNil);

export const omitRecursivelyBy = (object: object, omitByRule: (value: unknown, key: string) => boolean) => {
  const entries = Object.entries(object);
  const cleanObjectAsArray: ([string, unknown] | undefined)[] = entries.map(([k, v]) => {
    if (omitByRule(v, k)) {
      return;
    } else if (typeof v === 'object' && v !== null) {
      const val = omitRecursivelyBy(v, omitByRule);
      // check if result of recursion is still valid
      // for example if we have check for `isEmpty`, we should not return key: {}
      if (omitByRule(val, k)) {
        return;
      }
      return [k, val];
    }

    return [k, v];
  });
  const withoutUndefine = cleanObjectAsArray.filter(Boolean) as [string, unknown][];

  return Object.fromEntries(withoutUndefine!);
};
