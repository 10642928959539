import { SubscriptionProps } from '@manageSubscription/Subscription';
import { listAddresses, getFormattedAddress } from '@cv/portal-cps-lib';
import { options } from '../commonService';

export const getListAddresses = async (data: { isoCountryCode: string; query: string }, props: SubscriptionProps) => {
  const { isoCountryCode, query } = data;
  const request = {
    ...options(props),
    data: { isoCountryCode, query },
  };
  return listAddresses(request);
};

export const formatAddress = async (data: { id: string; isoCountryCode: string }, props: SubscriptionProps) => {
  const { isoCountryCode, id } = data;
  const request = {
    ...options(props),
    queryParams: { isoCountryCode, id },
  };
  return getFormattedAddress(request);
};
