import { Frequency } from '@cv/portal-rts-lib/doors/enums';
import { State, UoM } from '@cv/portal-rts-lib/enums';
import { AlertType, GeofenceType } from '@cv/portal-rts-lib/geofences/enums';
import { Geofence, GetGeofenceResponse } from '@cv/portal-rts-lib/geofences/models';
import { GeofenceResponse } from '@api/types/geofences';

import { GEOFENCE_ACTIONS } from '../actions';

export type GeofenceDataState = {
  geofence: string;
  valet: GeofenceResponse;
};

type GeofenceDataAction = {
  type: GEOFENCE_ACTIONS;
  data: GetGeofenceResponse | Geofence;
};

const initialState: GeofenceDataState = {
  geofence: '',
  valet: {
    name: '',
    id: '',
    inVehicleWarning: false,
    geometry: {
      radius: {
        UoM: UoM.Kilometers,
        value: 0.4,
      },
      center: {
        latitude: 0,
        longitude: 0,
      },
      address: {
        city: '',
        addressLine1: '',
        postalCode: '',
        country: '',
      },
    },
    schedules: [
      {
        startDate: '',
        recurrenceRules: [
          {
            freq: Frequency.DAILY,
            dayOfTheWeek: [],
          },
        ],
        startTime: '',
        duration: '',
        endDate: '',
        endTime: '',
        id: '',
      },
    ],
    alertType: AlertType.Valet,
    type: GeofenceType.Circular,
    lastSvcReqId: '',
    state: State.Inactve,
    statusChangedTime: '',
    requestedTime: '',
    vehicleSlotId: 1,
  },
};

export default (state: GeofenceDataState = initialState, action: GeofenceDataAction) => {
  switch (action.type) {
    case GEOFENCE_ACTIONS.GET_GEOFENCE:
      return {
        ...state,
        geofence: action.data,
      };
    case GEOFENCE_ACTIONS.GET_VALET:
      return {
        ...state,
        valet: action.data,
      };
    case GEOFENCE_ACTIONS.CREATE_GEOFENCE:
    case GEOFENCE_ACTIONS.UPDATE_GEOFENCE:
    case GEOFENCE_ACTIONS.DELETE_GEOFENCE:
      return {
        ...state,
      };
    case GEOFENCE_ACTIONS.UPDATE_VALET:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          valet: [action.data],
        },
      };
    case GEOFENCE_ACTIONS.RESET_VALET:
      return initialState;
    default:
      return state;
  }
};
