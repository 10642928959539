import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

export type LinkProps = MuiLinkProps & {
  component?: string;
};

const Link: React.FC<LinkProps> = ({ children, ...rest }) => {
  return <MuiLink {...rest}>{children}</MuiLink>;
};

export default Link;
