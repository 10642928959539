import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './MapWindow.css';

type Props = {
  collapsed: boolean;
  onToggle: () => void;
};

const MapWindowToggle = ({ collapsed, onToggle }: Props) => {
  const Icon = collapsed ? FaAngleRight : FaAngleLeft;
  return (
    <button className="map-window-toggle" onClick={onToggle}>
      <Icon />
    </button>
  );
};

export default MapWindowToggle;
