import React from 'react';
import CreatorForm, { CreatorFormProps } from '@components/EntryCreator/CreatorForm';

export type PinResetFormProps = CreatorFormProps & {
  question?: string;
};

const PinResetForm = ({ formFields = [], question, ...creatorFormProps }: PinResetFormProps) => {
  const placeholders = { question };

  const pinResetFields = formFields.map((field) => {
    const fieldPlaceholder = field.fieldPlaceholder?.replaceAll(
      /{{([^{}]*)}}/g,
      (placeholder: string, foundKey: keyof typeof placeholders) => placeholders[foundKey] || placeholder,
    );

    return { ...field, fieldPlaceholder };
  });

  return <CreatorForm {...creatorFormProps} formFields={pinResetFields} />;
};

export default PinResetForm;
