import React from 'react';
import clsx from 'clsx';
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa';

import styles from './EnrollStatus.module.css';

type EnrollStatusProps = {
  vin: string;
  success: boolean;
  labelSuccessTitle: string;
  labelErrorTitle: string;
  labelSuccessText: string;
  labelErrorText: string;
  labelVin: string;
  labelDealerInstructions: string;
  labelDealerInstructionsText: string;
  agreementUrl?: string;
  printSubscriberAgreementText: string;
};

const EnrollStatus = ({
  vin,
  success,
  labelSuccessTitle,
  labelErrorTitle,
  labelSuccessText,
  labelErrorText,
  labelVin,
  labelDealerInstructions,
  labelDealerInstructionsText,
  agreementUrl,
  printSubscriberAgreementText,
}: EnrollStatusProps) => {
  return (
    <div className={clsx(styles.container, { [styles.error]: !success })}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {success ? <FaCheckCircle /> : <FaRegTimesCircle />}
          {success ? labelSuccessTitle : labelErrorTitle}
        </h1>
        <div className={styles.vin}>
          {labelVin} #{vin}
        </div>
      </div>
      <div className={styles.body}>{success ? labelSuccessText : labelErrorText}</div>
      {agreementUrl && (
        <a href={agreementUrl} className={styles.link}>
          {printSubscriberAgreementText}
        </a>
      )}
      {success && (
        <div className={styles.instructions}>
          <h6>{labelDealerInstructions}</h6>
          <p>{labelDealerInstructionsText}</p>
        </div>
      )}
    </div>
  );
};

export default EnrollStatus;
