import React from 'react';
import clsx from 'clsx';

import RoundToggleButton from '@components/RoundToggleButton';
import styles from './InputCheckbox.module.css';
import ControlLabel from '@components/ControlLabel';
import { FaCheck } from 'react-icons/fa';
import { FieldHelperProps } from 'formik';

type InputCheckboxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: boolean;
  showErrorIcon?: boolean;
  helpers?: FieldHelperProps<any>;
};

const InputCheckbox = ({
  name = '',
  placeholder = '',
  className,
  error,
  showErrorIcon,
  ...props
}: InputCheckboxProps) => {
  const inputClassName = clsx(styles.InputCheckbox, className, {
    [styles.error]: error,
    [styles.errorIcon]: showErrorIcon,
  });

  return (
    <div className={inputClassName} title={`${props.checked ? 'checked' : 'unchecked'}`}>
      <ControlLabel
        label={placeholder}
        control={
          <RoundToggleButton
            {...props}
            name={name}
            classes={{
              input: styles['InputCheckbox-input'],
              button: styles['InputCheckbox-button'],
            }}
          >
            <FaCheck title={placeholder} />
          </RoundToggleButton>
        }
      />
    </div>
  );
};

export default InputCheckbox;
