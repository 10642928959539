import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export enum SubscriptionTerm {
  expires = 'expires',
  annual = 'annual',
  month = 'month',
  promo = 'promo',
}

export const subscriptionTerm = (subscription: SubscribedPackage | undefined): SubscriptionTerm => {
  if (!subscription) {
    return SubscriptionTerm.expires;
  }

  const { renewalTerm, currentTermUnit, autoRenew } = subscription;

  if (!autoRenew) {
    return SubscriptionTerm.expires;
  }

  if (currentTermUnit === TermUnit.Months) {
    if (renewalTerm === 1) {
      return SubscriptionTerm.month;
    }

    if (renewalTerm === 12) {
      return SubscriptionTerm.annual;
    }
  }

  return SubscriptionTerm.promo;
};
