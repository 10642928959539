import { formatDateByLocale } from '@cv/portal-common-lib';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import useExpireRenewText from '@hooks/useExpireRenewText';
import { isPackageFutureDated } from '@manageSubscription/utils';
import { isPaidPackage } from '@utils/subscriptionUtils';

const useFormatTermLabel = () => {
  const expireRenewText = useExpireRenewText();

  type Options = {
    pkg: SubscribedPackage;
    startsLabel: string;
    renewLabel: string;
    expiresLabel: string;
  };

  const formatTermLabel = ({ pkg, startsLabel, renewLabel, expiresLabel }: Options) => {
    const isFuturePaid = isPackageFutureDated(pkg) && isPaidPackage(pkg);
    if (isFuturePaid) {
      return `${startsLabel} ${formatDateByLocale(pkg.startDate)}`;
    }

    return expireRenewText({
      autoRenew: pkg.autoRenew,
      termEndDate: pkg.termEndDate,
      termServiceEndDate: pkg.termServiceEndDate,
      renewLabel,
      expiresLabel,
    });
  };

  return formatTermLabel;
};

export default useFormatTermLabel;
