// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XbwY1oKVh6AGEK1lBJAW {
  display: inline-flex;
  cursor: pointer;
}

.hjuyZ8pA94_Ii3nwE6V6 {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hT2xLoSrQ67PegpWZ_0g {
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/ControlLabel/ControlLabel.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".container {\n  display: inline-flex;\n  cursor: pointer;\n}\n\n.column {\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n}\n\n.row {\n  flex-direction: row;\n  align-items: center;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XbwY1oKVh6AGEK1lBJAW`,
	"column": `hjuyZ8pA94_Ii3nwE6V6`,
	"row": `hT2xLoSrQ67PegpWZ_0g`
};
export default ___CSS_LOADER_EXPORT___;
