import { FlowEventName } from '@lib-appRouter/flowTypes';
import { assign, send } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig } from '../Types';
import { SubscribedPackageInfo } from '../../../../manageSubscription/Types';

export const reviewOrderFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.reviewOrder,
  states: {
    pre: {
      // skip order review page if there are no packageSubscriptions
      always: [{ target: 'navigateForward', cond: 'hasNoPackageSubscriptions' }, 'idle'],
      entry: assign(({ flowSessionStorage }) => ({
        showDesignatedDealerMessage: flowSessionStorage?.subscribedPackages.some(
          (pkg: SubscribedPackageInfo) => pkg.designatedRefundChannel === 'DEALER',
        ),
      })),
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.reviewOrder },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: 'navigateForward',
        [FlowEventName.NAVIGATE_BACK]: 'navigateBack',
      },
    },
    navigateForward: {
      entry: FlowEventName.NAVIGATE_FORWARD,
    },
    navigateBack: {
      entry: FlowEventName.NAVIGATE_BACK,
    },
  },
});
