import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { VehiclePackages } from '@redux/reducers/user';

const isVehicleSubscribed = ({ packages }: VehiclePackages) => {
  if (!packages?.length) {
    return false;
  }

  let allPackagesCancelled = true;
  let isDefaultsOnly = true;
  let containsActivePackage = false;

  packages.forEach(({ cancelReceiveDate, packageType, active }) => {
    if (!cancelReceiveDate) {
      allPackagesCancelled = false;

      // monthly cancelled packages are still marked as active packages
      if (active) {
        containsActivePackage = true;
      }
    }

    if (packageType !== PackageType.Default) {
      isDefaultsOnly = false;
    }
  });

  if (allPackagesCancelled) {
    return false;
  }

  if (isDefaultsOnly) {
    return false;
  }

  return containsActivePackage;
};

export default isVehicleSubscribed;
