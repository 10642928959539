import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useApi } from '@api';
import { RootState } from '@app/reducers';
import { IUserState } from '@redux/reducers/user';
import { setCalendar } from '@redux/actions/user';
import UserCalendar from '@customTypes/UserCalendar';
import CalendarStatus from '@customTypes/CalendarStatus';
import useLabels from '@hooks/useLabels';
import useCalendarDialog from '@hooks/useCalendarDialog';
import { Button } from '@components-lib';
import Loader from '@components/Loader';
import { ActionCardProps } from '@components/ActionCard';
import GoogleCalendarList from '@components/ActionCard/Calendar/GoogleCalendarList';
import TopRightIcon from '@components/ActionCard/Calendar/TopRightIcon';
import ActionCardLayout from '@components/ActionCard/ActionCardLayout';

import styles from '@components/ActionCard/Calendar/Calendar.module.css';

const calendarLoader = { background: 'transparent' };

export type DescriptionByStatus = { [key in CalendarStatus]: string };

const CalendarAction = ({ title, icon, subtitle, content }: ActionCardProps) => {
  const { calendar }: IUserState = useSelector(({ userReducer }: RootState) => userReducer);

  const dispatch = useDispatch();
  const updateCalendar = useCallback((value: UserCalendar | null) => dispatch(setCalendar(value)), [dispatch]);

  const [status, setStatus] = useState<CalendarStatus>(CalendarStatus.Loading);

  const showLoading = useCallback(() => setStatus(CalendarStatus.Loading), [setStatus]);
  const goToSetup = useCallback(() => setStatus(CalendarStatus.SettingUp), [setStatus]);
  const letsRead = useCallback(() => setStatus(CalendarStatus.Reading), [setStatus]);
  const letsEdit = useCallback(() => setStatus(CalendarStatus.Editing), [setStatus]);

  const api = useApi();

  useEffect(() => {
    const initialRender = () => {
      if (calendar === undefined) {
        api
          .retrieveCalendar()
          .then(updateCalendar)
          .then(letsRead)
          .catch(() => {
            goToSetup();
            updateCalendar(null);
          });
      } else if (calendar === null) {
        goToSetup();
      } else {
        letsRead();
      }
    };

    initialRender();
  }, []);

  const labels = useLabels(content).getAllPrimaries();

  const onSelect = useCallback(
    (selectedCalendar: UserCalendar) => {
      showLoading();
      api
        .deleteCalendar()
        .finally(() => api.saveCalendar(selectedCalendar))
        .finally(() => {
          updateCalendar(selectedCalendar);
          letsRead();
        });
    },
    [api, letsRead, showLoading, updateCalendar],
  );

  const selectCalendar = useCalendarDialog(labels, onSelect);

  const onRemove = useCallback(() => {
    showLoading();
    api.deleteCalendar().finally(() => {
      updateCalendar(null);
      goToSetup();
    });
  }, [api, goToSetup, showLoading, updateCalendar]);

  const descriptionByStatus: DescriptionByStatus = {
    [CalendarStatus.Loading]: '',
    [CalendarStatus.SettingUp]: labels.setupYourCalendar,
    [CalendarStatus.Reading]: labels.calendarHasBeenSetUp,
    [CalendarStatus.Editing]: labels.updateYourCalendar,
  };

  const description = descriptionByStatus[status];

  const isReading = status === CalendarStatus.Reading;
  const isEditing = status === CalendarStatus.Editing;
  const isCalendarAvailable = isReading || isEditing;

  return (
    <GoogleCalendarList>
      <ActionCardLayout
        className={styles.calendarBlock}
        title={title}
        icon={icon}
        subtitle={subtitle}
        description={description}
        topRightIcon={
          isCalendarAvailable ? <TopRightIcon isReading={isReading} onEdit={letsEdit} onClose={letsRead} /> : null
        }
      >
        {status === CalendarStatus.Loading && <Loader style={calendarLoader} />}
        {status === CalendarStatus.SettingUp && (
          <Button className={clsx(styles.calendarButton, styles.setupButton)} onClick={selectCalendar}>
            {labels.btnSetupText}
          </Button>
        )}
        {isReading && (
          <>
            <h2 className={styles.calendarHeader}>{labels.calendarLabel}</h2>
            <p className={styles.calendarName} title="Name of Calendar">
              {calendar?.calendarName}
            </p>
          </>
        )}
        {isEditing && (
          <>
            <Button className={clsx(styles.calendarButton, styles.updateButton)} onClick={selectCalendar}>
              {labels.btnUpdateText}
            </Button>
            <Button className={styles.calendarButton} onClick={onRemove}>
              {labels.btnRemoveText}
            </Button>
          </>
        )}
      </ActionCardLayout>
    </GoogleCalendarList>
  );
};

export default CalendarAction;
