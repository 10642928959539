import React from 'react';
import { Container, Heading, Link, Title } from '@styled-components/globalStyles';
import { UserProfileWrapper } from '@manageSubscription/UserProfile/userProfileStyles';
import Button from '@lib-components/Button';
import FormikField from '@lib-components/FormikField';
import { PhoneInput } from '@lib-components/PhoneNumberInput';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  CommonWebContent,
  FormFieldDropdown,
  NissanCpoWebContent,
  SubscriptionProps,
  UserProfileWebContent,
  WebContent,
} from '../..';
import Select from '@lib-components/Select';
import { FormValuesMultiBrand } from '@lib-appRouter/flows/componentFlow/Types';
import AddressField from './AddressField';
import UserProfileYupShapeType from './UserProfileYupShapeType';
import { CommonFormAnalyticsWrapper } from '@lib-components/Analytics';
import FlowSmsCheckbox from '@components/SmsCheckbox/FlowSmsCheckbox';

interface UserProfileProps {
  handleSubmit: () => void;
  initialValues: FormValuesMultiBrand;
  isPinConfigured?: boolean;
  needPreferredLanguage: boolean;
  yupShape: UserProfileYupShapeType;
  webContent: WebContent;
  userProfileWebContent: UserProfileWebContent;
  commonWebContent: CommonWebContent;
  subscriptionProps: SubscriptionProps;
  nissanCpoWebContent: NissanCpoWebContent;
  primaryPhoneTypeField?: FormFieldDropdown;
  AddressFieldComponent: typeof AddressField;
  navigateBack?: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
  handleSubmit,
  initialValues,
  yupShape,
  isPinConfigured,
  needPreferredLanguage,
  userProfileWebContent: {
    userProfileHeader,
    userProfileTitle,
    firstNameInputLabel,
    firstNamePlaceholder,
    lastNameInputLabel,
    lastNamePlaceholder,
    emailInputLabel,
    phoneNumberInputLabel,
    pinInputLabel,
    pinPlaceholder,
    preferredLanguageLabel,
  },
  webContent,
  AddressFieldComponent,
  primaryPhoneTypeField,
  commonWebContent: { saveAndContinueButtonLabel, backButtonLabel },
  navigateBack,
}) => {
  const { billingAddressLabel, billingAndMailingSameLabel, mailingAddressLabel } = webContent.addressFormWebContent;
  const { preferredLanguages } = webContent.commonWebContent;
  return (
    <Container>
      <Heading>{userProfileHeader}</Heading>
      <Title>{userProfileTitle}</Title>
      <UserProfileWrapper>
        <CommonFormAnalyticsWrapper name="User Profile">
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(yupShape)}
            onSubmit={handleSubmit}
            validateOnMount
            initialTouched={{
              firstName: true,
              lastName: true,
              mobileNumber: true,
              pin: true,
              question: true,
              answer: true,
              ...(needPreferredLanguage ? { preferredLanguage: true } : {}),
              street__billing: true,
              street__billing2: true,
              city__billing: true,
              state__billing: true,
              postalCode__billing: true,
              primaryPhone: true,
              primaryPhoneType: true,
            }}
          >
            {(props: FormikProps<typeof initialValues>) => (
              <Form>
                <FormikField
                  name="firstName"
                  required
                  label={firstNameInputLabel}
                  placeholder={firstNamePlaceholder}
                  aria-label="firstName"
                />
                <FormikField
                  name="lastName"
                  required
                  label={lastNameInputLabel}
                  placeholder={lastNamePlaceholder}
                  aria-label="lastName"
                />
                <FormikField name="email" aria-label="email" disabled label={emailInputLabel} />
                <FormikField
                  required
                  name="primaryPhone"
                  aria-label="primaryPhone"
                  label={phoneNumberInputLabel}
                  Component={PhoneInput}
                />
                <FlowSmsCheckbox isSubmitting={props.isSubmitting} />
                <FormikField
                  required
                  name="primaryPhoneType"
                  aria-label="primaryPhoneType"
                  label={primaryPhoneTypeField?.fieldLabel}
                  Component={Select}
                  options={primaryPhoneTypeField?.fieldValues.map((val) => {
                    const [value, label] = val.split('::');
                    return { value, label };
                  })}
                />
                {needPreferredLanguage && (
                  <FormikField
                    name="preferredLanguage"
                    required
                    label={preferredLanguageLabel}
                    aria-label="language"
                    Component={Select}
                    options={Object.entries(preferredLanguages).map(([value, label]) => ({
                      label,
                      value,
                    }))}
                  />
                )}
                {!isPinConfigured && (
                  <FormikField
                    name="pin"
                    aria-label="pin"
                    required
                    label={pinInputLabel}
                    placeholder={pinPlaceholder}
                  />
                )}
                <Title>{billingAddressLabel}</Title>
                <AddressFieldComponent addressType="billing" props={props} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                  <input
                    type="checkbox"
                    name="mailingSameAsBilling"
                    id="mailingSameAsBilling"
                    checked={props.values.mailingSameAsBilling}
                    onChange={() => {
                      props.setFieldValue('mailingSameAsBilling', !props.values.mailingSameAsBilling);
                    }}
                  />
                  <label htmlFor="mailingSameAsBilling">
                    <p style={{ paddingLeft: 10 }}>{billingAndMailingSameLabel}</p>
                  </label>
                </div>
                {!props.values.mailingSameAsBilling && (
                  <div>
                    <Title>{mailingAddressLabel}</Title>
                    <AddressFieldComponent addressType="mailing" props={props} />
                  </div>
                )}
                <Button
                  data-testid="submit-button"
                  disabled={!(props.isValid && !props.isSubmitting)}
                  onClick={props.submitForm}
                  type="submit"
                  data-analyticseventname="CompleteProfile::SaveAndContinue::Button-Clicked"
                >
                  {saveAndContinueButtonLabel}
                </Button>
                {navigateBack && (
                  <Link
                    data-testid="navigate-back-link"
                    onClick={navigateBack}
                    data-analyticseventname="CompleteProfile::Back::Link-Clicked"
                  >
                    {backButtonLabel}
                  </Link>
                )}
              </Form>
            )}
          </Formik>
        </CommonFormAnalyticsWrapper>
      </UserProfileWrapper>
    </Container>
  );
};

export default UserProfile;
