import { styled } from '@mui/material';
import BaseDivider from '@lib-components/Divider';
import { LineBreak, Title } from '../../styled-components/globalStyles';

export const Section = styled('section')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const HeaderTitle = styled(Title)`
  margin: 0.7rem 0;
`;

export const SummarySection = styled('div')`
  display: flex;
  flex-direction: column;
  background: #dcdcdc;
  margin: 1rem 0 1rem 0;
  padding: 0.5rem 0.8rem;
`;

export const Pricing = styled('div')`
  display: flex;
  justify-content: space-between;

  ${({ bold }: { bold?: boolean }) => bold && 'font-weight: bold'}
`;

export const Discount = styled(Title)`
  color: #c71444;
`;

export const LegalSection = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  align-items: flex-start;
`;

export const NewLine = styled(LineBreak)`
  margin: 0.5rem 0;
`;

export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`;

export const Paragraph = styled('p')`
  margin-bottom: 20px !important;
`;
