import { assign, send } from 'xstate';
import { getPackagesMatchingCriteria } from '@manageSubscription/utils';
import { ComponentRoutes } from '../../../types';
import {
  AggregatedFlowContext,
  ComponentActions,
  ComponentFlowStateConfig,
  ManagePromoCodePackagesFlowContext,
} from '../Types';

export const managePromoCodePackagesFlow: ComponentFlowStateConfig<ManagePromoCodePackagesFlowContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePromoCodePackages,
  states: {
    pre: {
      entry: 'getPromoCodePackages',
      always: [
        { target: 'navigate', cond: 'skipPromotion' },
        { target: 'navigateToCancel', cond: 'hasNoPromoCodePackages' },
        'idle',
      ],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePromoCodePackages },
        }),
      ],
      on: {
        NAVIGATE: 'navigate',
      },
    },
    navigate: {
      always: [{ target: 'navigateForward', cond: 'hasPromoCodePackage' }, 'navigateToCancel'],
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateToCancel: {
      entry: 'navigateToCancel',
    },
  },
});

export const actions: ComponentActions<AggregatedFlowContext> = {
  getPromoCodePackages: assign({
    promoCodePackages: (context) => {
      const { discountCriteria } = context.subscriptionProps;
      const { eligiblePackages } = context.flowSessionStorage;
      return getPackagesMatchingCriteria(eligiblePackages, discountCriteria);
    },
  }),
};
