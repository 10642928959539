import React from 'react';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import './TwoColumnSection.css';

type TwoColumnSectionProps = {
  leftColumn: Array<ContentfulComponent>;
  rightColumn: Array<ContentfulComponent>;
  columnWidthRatio?: '60x40' | '49x49';
  name: string;
};

function TwoColumnSection({ leftColumn, rightColumn, columnWidthRatio, name }: TwoColumnSectionProps) {
  // TODO Temporary solution to handle component width ratio, to be improved (e.g. Marketing section)
  const leftClassName = columnWidthRatio === '49x49' ? 'column width-49' : 'column width-60';
  const rightClassName = columnWidthRatio === '49x49' ? 'column width-49' : 'column width-40';
  const justifyContent = columnWidthRatio === '49x49' ? 'TwoColumn-container--justify' : '';

  return (
    <div className={`Layout-container TwoColumn-container ${justifyContent}`}>
      <div className={leftClassName}>
        <ContentRenderer name={name} content={leftColumn} />
      </div>
      <div className={rightClassName}>
        <ContentRenderer name={name} content={rightColumn} />
      </div>
    </div>
  );
}

export default TwoColumnSection;
