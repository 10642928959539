import useFilterByMake from '@hooks/useFilterByMake';
import { filterLabels as filter, LabelObject, OnlyLabels } from '@utils/labels';
import { BrandNamesFilterGroup } from '../../types/BrandNamesFilterGroup';
import useFilterByModels from './useFilterByModels';

export type Dictionary = OnlyLabels & { filterGroup?: BrandNamesFilterGroup };

const useFilterDictionary = ({ labels, filterGroup }: Dictionary): LabelObject => {
  const makeDictionary = useFilterByMake({ content: labels, filterGroup, filter });
  return useFilterByModels(makeDictionary);
};

export default useFilterDictionary;
