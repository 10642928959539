import React, { CSSProperties } from 'react';
import { ContentfulRichText } from '@cv/webframework-react-components';

import styles from './Disclaimer.module.css';

type DisclaimerProps = {
  description: object;
  contact?: object;
  textAlign?: CSSProperties['textAlign'];
};

function Disclaimer({ description, contact, textAlign }: DisclaimerProps) {
  return (
    <div className={styles['Disclaimer-container']}>
      <div style={textAlign && { textAlign }} className={styles['Disclaimer-description']}>
        <ContentfulRichText document={description} />
      </div>
      <div className={styles['Disclaimer-contact']}>
        <ContentfulRichText document={contact} />
      </div>
    </div>
  );
}

export default Disclaimer;
