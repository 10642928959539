import React from 'react';
import { Alert, Box, Divider, List, ListItem, Skeleton } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useCancelLabels } from './CancelLabelsContext';
import { useCancelMachineContext } from './CancelSubscriptionStateMachineWrapper';
import PackageLine from './PackageLine';

const PreviewOrderList = () => {
  const { preview, error, step } = useCancelMachineContext();

  const {
    currencyLabel,
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    expires,
    renew,
    cancelEffectiveLabel,
    starts,
    moreDetailsLabel,
    defaultShortDescription,
    defaultLongDescription,
    servicesHierarchy,
    descriptions,
    assets,
  } = useCancelLabels();
  const previewIsLoading = useIsMutating({ mutationKey: ['previewOrder'] });

  if (previewIsLoading) {
    return <Skeleton variant="rectangular" animation="wave" width="100%" height="10vh" />;
  }

  if (error) {
    return (
      <Box mt={2} mb={2}>
        <Alert severity="error">Preview not avalible at this moment</Alert>
      </Box>
    );
  }

  const packageLineLabels = {
    cancelEffectiveLabel,
    currencyLabel,
    defaultLongDescription,
    defaultShortDescription,
    descriptions,
    expires,
    importantCancellationInfo__Annual,
    importantCancellationInfo__Monthly,
    renew,
    servicesHierarchy,
    starts,
    details: moreDetailsLabel,
  };

  const { subscribedPackages, refundAmountTotal } = preview;

  return (
    <List>
      {subscribedPackages.map((pkg) => (
        <>
          <ListItem disablePadding key={pkg.packageId}>
            <PackageLine
              pkg={pkg}
              packageState={pkg.cancelReason ? 'cancel' : 'subscribe'}
              labels={packageLineLabels}
              assets={assets}
              step={step}
              hidePrice={!refundAmountTotal}
            />
          </ListItem>
          <Divider component="li" sx={{ mt: 2, mb: 2 }} />{' '}
        </>
      ))}
    </List>
  );
};

export default PreviewOrderList;
