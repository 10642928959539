import React from 'react';
import useCpsRtsEntitlements from '@api/queries/useCpsRtsEntitlements';
import { hasEntitlement } from '@api/requests/userInfo';
import DisplayCheckbox from './DisplayCheckbox';
import OptSms, { OptSmsProps } from './OptSms';

const OptSmsCheckbox = (props: OptSmsProps) => {
  const { cpsEntitlements } = useCpsRtsEntitlements();

  if (hasEntitlement(cpsEntitlements, 'marketing-preferences')) {
    return (
      <DisplayCheckbox>
        <OptSms {...props} />
      </DisplayCheckbox>
    );
  }

  return null;
};

export default OptSmsCheckbox;
