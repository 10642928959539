import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useRemoteConfig = () => {
  return useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const config = await axios.get('/config/config.json').then(({ data }) => data);
      if (!config) {
        throw new Error('Config is empty or null');
      }
      return config;
    },
  });
};

export default useRemoteConfig;
