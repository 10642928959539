import React from 'react';
import clsx from 'clsx';
import { FaInfoCircle } from 'react-icons/fa';

import styles from './MonitoringAlerts.module.css';

type InfoButtonProps = {
  onClick?: () => void;
  className?: string;
};

export default function InfoButton({ onClick, className }: InfoButtonProps) {
  const buttonClassName = clsx(styles['info-button'], { [styles['info-button-clickable']]: !!onClick }, className);

  return (
    <button className={buttonClassName} onClick={onClick}>
      <FaInfoCircle />
    </button>
  );
}
