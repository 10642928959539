import React, { Fragment } from 'react';

import styles from './AccountEnrollment.module.css';

const AccountEnrollmentDetails = ({ vehicle, account }) => {
  const detailItems = [
    {
      label: 'Primer Nombre',
      value: account?.data?.userName?.givenName,
    },
    {
      label: 'Apellido Paterno',
      value: account?.data?.userName?.fathersName,
    },
    {
      label: 'Apellido Materno',
      value: account?.data?.userName?.mothersName,
    },
    {
      label: 'Correo Electrónico',
      value: account?.data?.email,
    },
    {
      label: 'Número del teléfono',
      value: account?.data?.primaryPhone?.number,
      isHidden: true,
    },
    {
      label: 'Calle',
      value: account?.data?.homeAddress?.street,
      isHidden: true,
    },
    {
      label: 'Número Interior',
      value: account?.data?.homeAddress?.addressLine2,
      isHidden: true,
    },
    {
      label: 'Ciudad',
      value: account?.data?.homeAddress?.city,
      isHidden: true,
    },
    {
      label: 'Código del estado',
      value: account?.data?.homeAddress?.state,
      isHidden: true,
    },
    {
      label: 'Código postal',
      value: account?.data?.homeAddress?.zip,
      isHidden: true,
    },
  ];

  if (!vehicle?.vin) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles['Form']}>
        {detailItems.map((detail) => {
          if (detail.isHidden && !detail.value) {
            return;
          }
          return (
            <div className={styles['Group']} key={detail.label}>
              <label className={styles['Label']}>{detail.label}</label>
              <label className={styles['LabelValue']}> {detail.value || ''}</label>
            </div>
          );
        })}
      </div>
      <hr className={styles['Hr']} />
    </Fragment>
  );
};

export default AccountEnrollmentDetails;
