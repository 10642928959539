import { useQuery } from '@tanstack/react-query';
import { useConfig } from '@components/ConfigProvider';
import { maskVin } from '@app/components-lib/manageSubscription';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

type useVinLookupProps = {
  vin: string;
};

export const useVinLookup = ({ vin }: useVinLookupProps) => {
  const config = useConfig();
  const vinLookupUrl = config.getOemValue('VIN_LOOKUP_URL');

  return useQuery({
    queryKey: ['vinLookup', vin],
    queryFn: async () => {
      const response = await fetch(`${vinLookupUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vin }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          JSON.stringify({
            statusCode: response.status,
            body: errorData.errorMessage,
          }),
        );
      }
      return await response.json();
    },
    enabled: !!vin,
    onSuccess: (data) => {
      sendAnalyticsEvent(
        new EventDataBuilder(EventType.VinLookupSuccess).withArgs({
          vinMask: maskVin(vin),
          serviceName: [],
          vehInfo: data?.vehicleDetails.map((d) => {
            return [d.make, d.marketingColor, d.model, d.vin, d.year].join('|');
          }),
        }),
      );
    },
    onError: (error: any) => {
      const errorData = JSON.parse(error.message);
      sendAnalyticsEvent(
        new EventDataBuilder(EventType.VinLookupError).withArgs({
          errorCode: errorData.statusCode,
          errorMessage: errorData.body,
          formField: 'vin',
          formName: 'vin',
          pageName: 'vinLookup',
        }),
      );
    },
  });
};
