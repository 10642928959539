import { get } from '@components/GlobalPreferences/GlobalPreferences';

type DisplayCheckboxProps = {
  children: JSX.Element;
};

const DisplayCheckbox = ({ children }: DisplayCheckboxProps) => {
  const marketingOptInEnabled = get('marketingOptInEnabled');

  if (marketingOptInEnabled) {
    return children;
  }

  return null;
};

export default DisplayCheckbox;
