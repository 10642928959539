import { ServiceNotificationPreference } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models/vehicle-notification-service';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

export const filterNotificationServices = (
  notifyServices: ServiceNotificationPreference[],
  capableServices: VehicleServiceDetails[],
  serviceNames: { [key: string]: string },
  alwaysVisiblePreferences: string[] = [],
) => {
  const alwaysVisibleServices = notifyServices?.filter((ns) =>
    alwaysVisiblePreferences.includes(ns.telematicsServiceName),
  );

  const filteredServices = notifyServices?.filter((ns) => {
    const serviceName = serviceNames[ns.telematicsServiceName];
    return (
      serviceName &&
      capableServices.some((cs) => cs.vehicleServiceName === serviceName || cs.marketingName === serviceName)
    );
  });

  return [...alwaysVisibleServices, ...filteredServices];
};
