import React from 'react';
import Button from '@lib-components/Button';
import { Message, ModalContainer, ModalTitle } from './styles';
import Image from '../Image';
import { ContentfulAssets } from '@manageSubscription';
import Link from '@lib-components/Link';
import { Stack } from '@lib-components/Stack';

interface YesNoModalProps {
  imgName?: string;
  header?: string;
  description?: string;
  message: string;
  yesText: string;
  noText: string;
  onYes: () => void;
  onNo: () => void;
  onYesAnalyticsEvent?: string;
  onNoAnalyticsEvent?: string;
  assets?: ContentfulAssets;
}

export const YesNoModal = ({
  imgName,
  header,
  description,
  message,
  noText,
  yesText,
  onYes,
  onNo,
  onYesAnalyticsEvent,
  onNoAnalyticsEvent,
  assets,
}: YesNoModalProps) => {
  return (
    <ModalContainer>
      {imgName && <Image assets={assets} name={imgName} />}
      {header && <ModalTitle>{header}</ModalTitle>}
      {description && <Message>{description}</Message>}
      <Message>{message}</Message>
      <Stack direction="column" spacing={2}>
        <Button onClick={onNo} data-analyticseventname={onNoAnalyticsEvent}>
          {noText}
        </Button>
        <Link component="button" role="button" onClick={onYes} data-analyticseventname={onYesAnalyticsEvent}>
          {yesText}
        </Link>
      </Stack>
    </ModalContainer>
  );
};
