import React from 'react';
import { Box } from '@mui/material';
import { ContentfulRichText } from '@cv/webframework-react-components';
import './RichTextContainer.css';

export type RichTextContainerProps = {
  content: object;
  primaryColorText?: boolean;
  contentOneLevel?: boolean;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
  ulResponsiveColsDesktop: string;
  ulResponsiveColsMobile: string;
};

const RichTextContainer = ({
  content,
  textAlignment = 'left',
  primaryColorText,
  contentOneLevel = false,
  ulResponsiveColsDesktop = '1',
  ulResponsiveColsMobile = '1',
}: RichTextContainerProps) => {
  return (
    <Box
      className={`RichTextContainer text-${textAlignment} ${contentOneLevel && 'one-level'}`}
      sx={(theme) => ({
        color: primaryColorText ? theme.palette.primary.main : 'inherit',
        '& ul': {
          [theme.breakpoints.down('sm')]: {
            columnCount: ulResponsiveColsMobile,
          },
          [theme.breakpoints.up('sm')]: {
            columnCount: ulResponsiveColsDesktop,
          },
        },
      })}
    >
      <ContentfulRichText document={content} />
    </Box>
  );
};

export default RichTextContainer;
