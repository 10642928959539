import { Placement, GeneralDirection, RelativePosition } from './types';

const startWith = <T extends any>(array: T[], value: T) => {
  return array.sort((x: T, y: T) => (x == value ? -1 : y == value ? 1 : 0));
};

type Coordinates = {
  x?: number;
  y?: number;
  name?: string;
};
type Settings = {
  [key: string]: {
    coordinates: Coordinates;
    isFit: boolean;
  };
};
interface findPositionArgs {
  settings: Settings;
  allPositions: string[];
  startPosition: string;
  force: boolean;
}
const findPosition = ({ settings, allPositions, startPosition, force }: findPositionArgs) => {
  if (force) {
    return settings[startPosition].coordinates;
  }

  let coordinates: Coordinates = {};

  const sortedPositions = startWith(allPositions, startPosition);

  for (let i = 0; i < sortedPositions.length; i++) {
    const currentPosition = sortedPositions[i];
    const positionInfo = settings[currentPosition];
    if (positionInfo.isFit) {
      coordinates = positionInfo.coordinates;
      break;
    }
  }

  if (!coordinates?.x && !coordinates?.y) {
    coordinates = settings[startPosition].coordinates;
  }

  return coordinates;
};

interface calculatePositionArgs {
  triggerElement: HTMLElement | null;
  contentElement: HTMLDivElement | null;
  initialPlacement?: Placement;
  force?: boolean;
  indent?: number | [number, number, number?, number?];
  offset?: number | [number, number, number?, number?];
  arrow?: boolean;
}

const possibleDirections = ['top', 'bottom', 'left', 'right'];
const possibleYRelativePositions = ['xCenter', 'left', 'right'];
const possibleXRelativePositions = ['yCenter', 'top', 'bottom'];

export const calculatePosition = ({
  triggerElement,
  contentElement,
  initialPlacement = 'top-center',
  force = false,
  offset = 0,
  indent = 0,
  arrow = false,
}: calculatePositionArgs): [
  Placement,
  {
    x: number;
    y: number;
  },
  {
    x: number;
    y: number;
  },
] => {
  if (!triggerElement || !contentElement) {
    return [
      initialPlacement,
      {
        x: 0,
        y: 0,
      },
      {
        x: 0,
        y: 0,
      },
    ];
  }

  if (typeof indent !== 'number' || Array.isArray(indent)) {
    throw new Error('Indent should be number or array of numbers!');
  }

  let indentArray = [];
  if (typeof indent === 'number') {
    indentArray = [indent, indent, indent, indent];
  } else if ((indent as number[]).length === 2) {
    indentArray = [indent[0], indent[1], indent[0], indent[1]];
  } else if ((indent as number[]).length === 3) {
    indentArray = [indent[0], indent[1], indent[2], indent[1]];
  } else {
    indentArray = indent;
  }

  let offsetArray = [];
  if (typeof offset === 'number') {
    offsetArray = [offset, offset, offset, offset];
  } else if ((offset as number[]).length === 2) {
    offsetArray = [offset[0], offset[1], offset[0], offset[1]];
  } else if ((offset as number[]).length === 3) {
    offsetArray = [offset[0], offset[1], offset[2], offset[1]];
  } else {
    offsetArray = offset;
  }

  const [direction, position] = initialPlacement.split('-') as [GeneralDirection, RelativePosition];

  const {
    x: triggerX,
    y: triggerY,
    width: triggerWidth,
    height: triggerHeight,
  } = triggerElement.getBoundingClientRect();
  const { width: contentWidth, height: contentHeight } = contentElement.getBoundingClientRect();
  const { scrollY, scrollX, innerWidth, innerHeight } = window;

  const [indentTop, indentRight, indentBottom, indentLeft] = indentArray;
  const [offsetTop, offsetRight, offsetBottom, offsetLeft] = offsetArray;
  const [windowTop, windowBottom, windowLeft, windowRight] = [
    scrollY,
    innerHeight + scrollY,
    scrollX,
    innerWidth + scrollX,
  ];

  const arrowOffset = arrow ? 5 : 0;

  const topPosition = triggerY - contentHeight + windowTop - offsetTop - arrowOffset;
  const bottomPosition = triggerY + triggerHeight + windowTop + offsetBottom + arrowOffset;
  const leftPosition = triggerX - contentWidth + windowLeft - offsetLeft - arrowOffset;
  const rightPosition = triggerX + triggerWidth + windowLeft + offsetRight + arrowOffset;

  const generalPositioning = {
    top: {
      coordinates: {
        y: topPosition,
        name: 'top',
      },
      isFit: topPosition > windowTop + indentTop,
      relativePositions: possibleXRelativePositions,
    },
    bottom: {
      coordinates: {
        y: bottomPosition,
        name: 'bottom',
      },
      isFit: bottomPosition + contentHeight < windowBottom - indentBottom,
      relativePositions: possibleXRelativePositions,
    },
    left: {
      coordinates: {
        x: leftPosition,
        name: 'left',
      },
      isFit: leftPosition > windowLeft + indentLeft,
      relativePositions: possibleYRelativePositions,
    },
    right: {
      coordinates: {
        x: rightPosition,
        name: 'right',
      },
      isFit: rightPosition + contentWidth < windowRight - indentRight,
      relativePositions: possibleYRelativePositions,
    },
  };

  const relativeXCenterPosition = triggerX + triggerWidth / 2 - contentWidth / 2 + windowLeft;
  const relativeRight = triggerX + windowLeft;
  const relativeLeft = triggerX + triggerWidth + windowLeft - contentWidth;
  const relativeYCenterPosition = triggerY + triggerHeight / 2 - contentHeight / 2 + windowTop;
  const relativeBottom = triggerY + windowTop;
  const relativeTop = triggerY + triggerHeight + windowTop - contentHeight;

  const relativePositioning = {
    top: {
      coordinates: {
        y: relativeTop,
        name: 'top',
      },
      isFit: relativeTop - contentHeight > windowTop,
    },
    bottom: {
      coordinates: {
        y: relativeBottom,
        name: 'bottom',
      },
      isFit: relativeBottom + contentHeight < windowBottom,
    },
    left: {
      coordinates: {
        x: relativeLeft,
        name: 'left',
      },
      isFit: relativeLeft - contentWidth > windowLeft,
    },
    right: {
      coordinates: {
        x: relativeRight,
        name: 'right',
      },
      isFit: relativeRight + contentWidth < windowRight,
    },
    xCenter: {
      coordinates: {
        x: relativeXCenterPosition,
        name: 'center',
      },
      isFit: relativeXCenterPosition > windowLeft && relativeXCenterPosition + contentWidth < windowRight,
    },
    yCenter: {
      coordinates: {
        y: relativeYCenterPosition,
        name: 'center',
      },
      isFit: relativeYCenterPosition > windowTop && relativeYCenterPosition + contentHeight < windowBottom,
    },
  };

  const tooltipPosition = findPosition({
    settings: generalPositioning,
    allPositions: possibleDirections,
    startPosition: direction,
    force,
  });

  const possibleRelativePositions = tooltipPosition.x ? possibleXRelativePositions : possibleYRelativePositions;
  const relativePositionStart = possibleRelativePositions.includes(position) ? position : possibleRelativePositions[0];

  const tooltipRelativePosition = findPosition({
    settings: relativePositioning,
    allPositions: possibleRelativePositions,
    startPosition: relativePositionStart,
    force,
  });

  let arrowXPosition = 0;
  if (tooltipPosition.name === 'left' || tooltipPosition.name === 'right') {
    if (tooltipRelativePosition.name === 'center') {
      arrowXPosition = Math.floor(contentHeight / 2);
    } else if (tooltipRelativePosition.name === 'top') {
      arrowXPosition = Math.floor(Math.abs(triggerHeight / 2 - contentHeight));
    } else if (tooltipRelativePosition.name === 'bottom') {
      arrowXPosition = Math.floor(triggerHeight / 2);
    }
  }

  let arrowYPosition = 0;
  if (tooltipPosition.name === 'top' || tooltipPosition.name === 'bottom') {
    if (tooltipRelativePosition.name === 'center') {
      arrowYPosition = Math.floor(contentWidth / 2);
    } else if (tooltipRelativePosition.name === 'left') {
      arrowYPosition = Math.floor(Math.abs(triggerWidth / 2 - contentWidth));
    } else if (tooltipRelativePosition.name === 'right') {
      arrowYPosition = Math.floor(triggerWidth / 2);
    }
  }

  const positions = Object.assign({}, tooltipPosition, tooltipRelativePosition);
  return [
    `${tooltipPosition.name}-${tooltipRelativePosition.name}` as Placement,
    {
      x: Math.floor(positions.x || 0),
      y: Math.floor(positions.y || 0),
    },
    {
      x: arrowXPosition, // Arrow X
      y: arrowYPosition, // Arrow Y
    },
  ];
};
