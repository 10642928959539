// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a4M41Wlvzub4RnpjDFbB label {
  margin-top: 0.7rem;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/SmsCheckbox/OptSms.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".opt-sms-checkbox-input label {\n  margin-top: 0.7rem;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"opt-sms-checkbox-input": `a4M41Wlvzub4RnpjDFbB`
};
export default ___CSS_LOADER_EXPORT___;
