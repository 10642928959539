import clsx from 'clsx';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import styles from './Markdown.module.css';
import rehypeRaw from 'rehype-raw';

type DescriptionProps = {
  children: string;
  className?: string;
  options?: Partial<
    ReactMarkdown.ReactMarkdownOptions & {
      plugins: ReactMarkdown.PluggableList;
    }
  >;
};

const Markdown = ({ children, options, className }: DescriptionProps) => {
  const {
    plugins,
    linkTarget,
    className: innerClassName,
    ...rest
  } = {
    plugins: [],
    linkTarget: '_blank',
    ...options,
  };

  return (
    <ReactMarkdown
      className={clsx(styles.container, className, innerClassName)}
      {...rest}
      remarkPlugins={[gfm, ...plugins]}
      rehypePlugins={[rehypeRaw]}
      linkTarget={linkTarget}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
