import { v4 as uuidv4 } from 'uuid';
import config from '@config/config';

export type UpdatePasswordOptions = {
  clientId: string;
  tenantId: string;
  userIdentifier: string;
  code: string;
  password: string;
  confirmPassword: string;
};

const updatePassword = ({
  clientId,
  tenantId,
  userIdentifier,
  code,
  password,
  confirmPassword,
}: UpdatePasswordOptions) => {
  const updatePasswordUrl = config.getOemValue('PASSWORD_FLOW_UPDATE_PASSWORD');
  return fetch(updatePasswordUrl, {
    method: 'POST',
    headers: new Headers({
      'cv-client-id': clientId,
      'cv-tenant-id': tenantId,
      'cv-correlation-id': `CWP-${uuidv4()}`,
      'content-type': 'application/json',
    }),
    body: JSON.stringify({
      userIdentifier,
      code,
      password,
      confirmPassword,
    }),
  });
};

export default updatePassword;
