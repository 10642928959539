import React from 'react';
import {
  useFlowMachine,
  useFlowMachineContextContent,
  useFlowMachineContextSubscriptionProps,
} from '../../Router/RouterContext';
import { useSelector } from '@xstate/react';
import InformationPageEnrollment from './InformationPageEnrollment';
import { FlowEventName } from '@lib-appRouter/flowTypes';

const InformationPageEnrollmentContainer = (): JSX.Element => {
  const flowMachine = useFlowMachine();
  const subscriptionTerms = useSelector(flowMachine, (state) => state.context.subscriptionTerms);
  const { informationPageEnrollmentContent, assets, carouselList } = useFlowMachineContextContent();
  const subscriptionProps = useFlowMachineContextSubscriptionProps();

  const navigateForward = () => {
    flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
  };

  const imageData = assets['InfoBackground'];
  const headerLogo = assets['headerLogoInfoPage'];

  return (
    <InformationPageEnrollment
      informationPageEnrollmentContent={informationPageEnrollmentContent}
      carouselList={carouselList}
      subscriptionTerms={subscriptionTerms}
      headerLogoData={headerLogo}
      bgImageData={imageData}
      navigateForward={navigateForward}
      subscriptionProps={subscriptionProps}
    />
  );
};

export default InformationPageEnrollmentContainer;
