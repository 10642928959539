import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useDispatch, useSelector } from 'react-redux';

import './MapWindow.css';
import { useTheme, Typography, Stack } from '@mui/material';

type TimerProps = {
  labels: any;
  updatedTimerValue: (result?: any) => void;
};

const MapCountDownTimer = ({ labels, updatedTimerValue }: TimerProps) => {
  const dispatch = useDispatch();
  const mapTimerValue = useSelector(({ mapReducer }) => mapReducer.mapTimerValue);
  const theme = useTheme();
  const timerTheme = {
    color: theme.palette.text.headerIcons,
    trailColor: theme.palette.grey[600],
  };

  const { color, trailColor } = timerTheme;

  const timerProps = {
    isPlaying: true,
    size: 95,
    strokeWidth: 4,
    trailColor,
  };

  const renderTime = ({ remainingTime }: any) => {
    const min = Math.ceil(remainingTime / 60);
    if (remainingTime < mapTimerValue) {
      dispatch({ type: 'SET_MAP_TIMER_VALUE', mapTimerValue: remainingTime });
    }
    if (remainingTime === 0) {
      updatedTimerValue(remainingTime);
    }

    return (
      <Stack alignItems="center">
        <Typography
          component="span"
          sx={(theme) => ({
            color: theme.palette.text.headerIcons,
            fontSize: '0.65rem',
            width: '4rem',
            textAlign: 'center',
            marginTop: '3rem',
          })}
        >
          {labels?.timeLeft}
        </Typography>
        <Typography
          component="span"
          className="map-window-time-mins"
          sx={(theme) => ({ color: theme.palette.text.headerIcons, fontSize: '1.5rem', lineHeight: '1.5rem' })}
        >
          {min}
        </Typography>
        <Typography
          component="span"
          className="map-window-time-mins-label"
          sx={(theme) => ({ color: theme.palette.text.headerIcons, fontSize: '0.75 rem', textTransform: 'uppercase' })}
        >
          {labels?.min}
        </Typography>
        <Typography
          component="span"
          sx={(theme) => ({ color: theme.palette.text.headerIcons, fontSize: '0.5rem', marginTop: '1.8rem' })}
        >
          {labels?.pressToStop}
        </Typography>
      </Stack>
    );
  };
  return (
    <CountdownCircleTimer {...timerProps} colors={color} duration={mapTimerValue}>
      {renderTime}
    </CountdownCircleTimer>
  );
};

export default MapCountDownTimer;
