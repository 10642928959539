import React from 'react';
import { useFlowMachineContextContent } from '../../Router/RouterContext';
import Product, { ProductProps } from './Product';
import ProductInfo from './ProductInfo';

type ProductsProps = {
  products: ProductInfo[];
  productsToHide: string[];
} & Omit<ProductProps, 'productInfo'>;

const Products: React.FC<ProductsProps> = ({ products, productsToHide, ...rest }) => {
  return (
    <>
      {products
        .filter((p) => !productsToHide.includes(p.productName))
        .map((item, index) => (
          <Product {...rest} key={index} productInfo={item} />
        ))}
    </>
  );
};

export default Products;
