import { omitBy } from 'lodash';
import publicIp from 'public-ip';

const openpgp = require('openpgp');

export const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip'],
  });

interface Opts {
  headersToOmit?: string[];
}
export const options = ({ accessToken, locale, config, tenantId, correlationId = '' }, opts: Opts = {}) => {
  return {
    environment: config.getEnvironmentEnum(),
    headers: omitBy(
      {
        Authorization: accessToken,
        'CV-Correlation-Id': correlationId,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
      (_, key) => opts.headersToOmit?.some((header) => header.toLowerCase() === key.toLowerCase()),
    ),
  };
};

export async function encrypt(text, publicKey) {
  const options = {
    message: await openpgp.createMessage({ text }),
    encryptionKeys: await openpgp.readKey({ armoredKey: publicKey }),
  };
  return openpgp.encrypt(options);
}
