import { useEffect, useState } from 'react';

export type UseScriptProps = [state: ScriptStatus, setSource: (src?: string) => void];

export type ScriptStatus = 'unloaded' | 'loading' | 'active' | 'error';

export const useScript = (src?: string, appendAtStart = true, callback?: any): UseScriptProps => {
  const [source, setSource] = useState<string | undefined>(src);
  const [scriptState, setScriptState] = useState<ScriptStatus>(src ? 'loading' : 'unloaded');

  useEffect(() => {
    if (!source) {
      setScriptState('unloaded');
      return;
    }
    // Fetch existing script element by src if added by another instance
    let script = document.querySelector<HTMLScriptElement>(`script[src="${source}"]`);

    if (!script) {
      // If script is not in DOM yet, add it
      script = document.createElement('script');
      script.src = source;
      script.async = true;

      // Callback on script load/error to update the hooks state
      const setStateCallback = (event: Event) => {
        setScriptState((event.type as keyof HTMLElementEventMap) === 'load' ? 'active' : 'error');
      };
      script.addEventListener('load', setStateCallback);
      script.addEventListener('error', setStateCallback);

      // Attach the script to the DOM
      if (appendAtStart) {
        const firstScriptTag = document.body.getElementsByTagName('script')[0];
        document.body.insertBefore(script, firstScriptTag);
      } else {
        document.body.appendChild(script);
      }
      if (typeof callback === 'function') {
        callback();
      }
      return (): void => {
        if (script) {
          // Reset state, useful during re-render
          setScriptState('unloaded');
          script.remove();
        }
      };
    }
  }, [source]); // Only re-run if script src changes

  return [scriptState, (sourceUrl?: string) => setSource(sourceUrl)];
};
