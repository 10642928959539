import { udpateHeadsUnit } from '@cv/portal-rts-lib/head-units';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { UpdateHeadhUnitsRequest, HeadUnitResponse } from '@cv/portal-rts-lib/head-units/models';
import { GetHeadUnitState } from '@cv/portal-rts-lib/head-units/enums';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';

export default class HeadUnitsService {
  private environment: Environment;

  constructor(environment = Environment.SIT) {
    this.environment = environment;
  }

  async wipeVehicle(accessToken: string, idToken: string, vehicleId: string) {
    const request: UpdateHeadhUnitsRequest = {
      data: {
        headUnits: [
          {
            personalData: [
              {
                state: GetHeadUnitState.Wipe,
              },
            ],
            id: '1',
          },
        ],
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      pathParams: {
        vehicleId,
      },
    };
    const response: APIResponse<HeadUnitResponse> = await udpateHeadsUnit(request);
    return { data: response.data };
  }
}
