import React from 'react';
import { ContentfulAssets } from '@manageSubscription';
import { Content as Subheader, IconSection } from '../../styled-components/globalStyles';
import { Header, Body } from './styles';
import Image from '@lib-components/Image';

type SuccessfulMessageProps = {
  assets?: ContentfulAssets;
  headerText: string;
  subheaderText?: string;
  bodyText?: string;
};

const SuccessfulMessage = ({ assets, headerText, subheaderText, bodyText }: SuccessfulMessageProps) => (
  <>
    {assets && (
      <IconSection>
        <Image assets={assets} name="CheckCircleGreen" size="45px" />
      </IconSection>
    )}
    <Header>{headerText}</Header>
    {subheaderText && <Subheader>{subheaderText}</Subheader>}
    {bodyText && <Body>{bodyText}</Body>}
  </>
);
export default SuccessfulMessage;
