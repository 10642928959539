import {
  createOrder,
  getSubscribedPackages,
  searchEligiblePackages,
  getDefaultEligiblePackages,
} from '@cv/portal-cps-lib/subscription';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  SubscribePackagesRequest,
  SearchEligiblePackagesRequest,
  OrderRequest,
  OrderPayload,
  SearchDefaultEligiblePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { formatSubscription } from '../formatters';
import queryClient from '@api/queries/queryClient';

type SubscriptionArgs = {
  userId: string;
  vehicleId: string;
  accessToken: string;
  locale: string;
  tenantId: string;
};

type SearchEligiblePackagesArgs = SubscriptionArgs;

type CreateOrderArgs = SubscriptionArgs & OrderPayload;

type GetSubscriptionArgs = SubscriptionArgs & {
  excludeDefaultPkgs: boolean;
};

export default class SubscribedPackagesService {
  private environment: Environment;

  constructor(environment = Environment.DEVINT) {
    this.environment = environment;
  }

  async getSubscription({ userId, vehicleId, accessToken, locale, tenantId, excludeDefaultPkgs }: GetSubscriptionArgs) {
    if (!vehicleId) {
      return;
    }
    const request: SubscribePackagesRequest = {
      data: { userId, vehicleId },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };
    let defaultPkgs;
    if (!excludeDefaultPkgs) {
      defaultPkgs = await this.getDefaultPackages({ userId, vehicleId, accessToken, locale, tenantId });
    }
    return queryClient
      .fetchQuery(['getSubscribedPackages', vehicleId], () => getSubscribedPackages(request))
      .then((response) => ({
        data: formatSubscription(response.data, defaultPkgs?.data),
      }));
  }

  searchEligiblePackages({ userId, vehicleId, accessToken, locale, tenantId }: SearchEligiblePackagesArgs) {
    const request: SearchEligiblePackagesRequest = {
      data: {
        userId,
        vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };

    return queryClient
      .fetchQuery(['getEligiblePackages'], () => searchEligiblePackages(request))
      .then((response) => ({ data: response.data, vehicleId }));
  }

  createOrder({ userId, vehicleId, accessToken, locale, tenantId, ...data }: CreateOrderArgs) {
    const request: OrderRequest = {
      data: {
        vehicleId,
        userId,
        ...data,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };
    return createOrder(request);
  }

  getDefaultPackages({ userId, vehicleId, accessToken, locale, tenantId }: SubscriptionArgs) {
    const request: SearchDefaultEligiblePackagesRequest = {
      data: { userId, vehicleId },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };
    return queryClient.fetchQuery(['getDefaultEligiblePackages', vehicleId], () => getDefaultEligiblePackages(request));
  }
}
