import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants/';
import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils/';
import { CONFIG_KEYS } from '@manageSubscription/utils/constants';
import { SubscriptionProps } from '@manageSubscription';

enum userManagementUrls {
  sendCode = '/auth/resend',
  validateCode = '/auth/validate',
}

const buildAuthCodeRequest = (url: string, subscriptionProps: SubscriptionProps, otp?: string): APIRequest => {
  const {
    tenantId,
    accessToken,
    config,
    userDetails: { userId },
    vehicleDetails: { vin },
  } = subscriptionProps;
  return {
    url: config.get(CONFIG_KEYS.USER_API_URL_BASE) + url,
    method: HttpMethodEnum.POST,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'CV-Tenant-Id': tenantId,
      brand: config.get(CONFIG_KEYS.SXM_BRAND_NAME),
    },
    data: {
      vin,
      userId,
      auth: otp,
    },
  };
};

export const sendAuthCode = (subscriptionProps: SubscriptionProps) => {
  return callHttpRequestMethod(buildAuthCodeRequest(userManagementUrls.sendCode, subscriptionProps));
};
export const validateCode = (subscriptionProps: SubscriptionProps, otp: string) => {
  return callHttpRequestMethod(buildAuthCodeRequest(userManagementUrls.validateCode, subscriptionProps, otp));
};
