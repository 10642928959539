import { useSelector } from 'react-redux';

type LoadingReducer = { loadingReducer: { isLoading: boolean } };

const getIsLoading = (state: LoadingReducer) => {
  return state.loadingReducer.isLoading;
};

const useLoadingSelector = () => {
  const isLoading = useSelector(getIsLoading);

  return { isLoading };
};

export default useLoadingSelector;
