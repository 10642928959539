import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCookie } from '@utils/cookies';
import { RootState } from '@app/reducers';
import { TokenTypes } from '@api/services/StoreService';
import {
  EligiblePackagesResponse,
  SearchEligiblePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { useConfig } from '@components/ConfigProvider';
import useVehicle from './useVehicle';
import { searchEligiblePackages } from '@cv/portal-cps-lib';
import attachCapableServices from '@api/requests/subscription/attachCapableServices';

export const useEligiblePackages = (salesChannel: SalesChannel = SalesChannel.Subscriber) => {
  const { data: vehicle } = useVehicle();

  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const vehicleId: string = vehicle?.vehicleId;
  const config = useConfig();
  const environment = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);

  return useQuery<EligiblePackagesResponse[]>({
    enabled: !!vehicleId,
    queryKey: ['eligiblePackages', salesChannel],
    queryFn: async () => {
      const request: SearchEligiblePackagesRequest = {
        data: {
          userId,
          vehicleId,
          salesChannel,
        },
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
        },
      };

      const response = await searchEligiblePackages(request)
        .then((response) => ({
          eligiblePackages: attachCapableServices({
            packages: response.data,
            capableServices: vehicle?.capableServices,
          }),
          vehicleId,
        }))
        .catch(() => ({ vehicleId, eligiblePackages: [] }));

      return response;
    },
    placeholderData: { vehicleId, eligiblePackages: [] },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default useEligiblePackages;
