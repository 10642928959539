import { useState, useEffect } from 'react';
import { Payload } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { SubscriptionProps } from '../Subscription';
import { getAgreementTemplate } from '../../services/cps';

type LinkPayload = Payload & { agreementTemplateUrl: string };
type HtmlLink = HTMLAnchorElement | HTMLAreaElement;

const useSetLink = (legalText: string, props: SubscriptionProps): string => {
  const [text, setText] = useState(legalText);

  useEffect(() => {
    const html = parse(text);
    const links = Array.from(html.links);
    const hasLink = check(links);

    if (hasLink) {
      setLink();
    } else {
      setText(legalText);
    }

    async function setLink() {
      const { agreementTemplateUrl: url } = await getUrl(props);
      set(url);
    }

    function set(url: string) {
      replaceUrl(url, links);
      setText(html.body.innerHTML);
    }
  }, [legalText, props]);

  return text;
};

function parse(text: string) {
  return new DOMParser().parseFromString(text, 'text/html');
}

function check(links: HtmlLink[]) {
  return links.some(isAgreement);
}

function isAgreement(link: HtmlLink) {
  const { classList: list, attributes } = link;
  return list.contains('agreement') && list.length === 1 && attributes.getNamedItem('href')?.value === '#';
}

async function getUrl(props: SubscriptionProps): Promise<LinkPayload> {
  return getAgreementTemplate(props) as Promise<LinkPayload>;
}

function replaceUrl(url: string, links: HtmlLink[]) {
  links.forEach((link) => {
    if (isAgreement(link)) {
      link.href = url;
    }
  });
}

export default useSetLink;
