import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { differenceInDays, milliseconds } from 'date-fns';

export const monthsToDays = (months: number): number => {
  if (typeof months !== 'number' || months <= 0) {
    return 0;
  }
  return differenceInDays(milliseconds({ months }), 0);
};

export const toDays = (term: number, unit: TermUnit) => {
  if (unit === TermUnit.Days) return term;

  return monthsToDays(term);
};
