import { send } from 'xstate/lib/actions';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, SubaruUserProfileFlowContext, FormValuesMultiBrand } from '../Types';
import { updateUserProfileById, updateUserPin } from '../../../../services/idm/userService';
import { FlowEventName } from '../../../flowTypes';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

export const userProfileFlowSubaru: ComponentFlowStateConfig<SubaruUserProfileFlowContext> = () => ({
  initial: 'waitForInput',
  id: ComponentRoutes.userProfile,
  states: {
    waitForInput: {
      entry: send({
        type: 'PUSH_HISTORY',
        data: { componentRoute: ComponentRoutes.userProfile },
      }),
      on: {
        HANDLE_INPUT: {
          target: 'setUserDetailsAndPin',
        },
      },
    },
    setUserDetailsAndPin: {
      entry: 'setLoading',
      invoke: {
        id: 'setUserDetailsAndPin',
        src: async (context, event) => {
          const { subscriptionProps } = context;
          const values: FormValuesMultiBrand = event.data?.values || {};
          try {
            if (values?.pin && !context.subscriptionProps?.userDetails?.isPinConfigured) {
              await updateUserPin(subscriptionProps, values.pin);
            }

            const userDetails = await updateUserProfileById(context.subscriptionProps, {
              primaryPhone: values.primaryPhone,
              primaryPhoneType: values.primaryPhoneType || 'Mobile',
              email: subscriptionProps?.userDetails.email,
              preferredLanguage: values.preferredLanguage,

              billingPostalAddress1: values.street__billing,
              billingPostalAddress2: values.street__billing2,
              billingCity: values.city__billing,
              billingPostalCode: values.postalCode__billing,
              billingCountry: subscriptionProps.vehicleDetails?.registrationCountry || 'USA',
              billingStateProvince: values.state__billing,

              ...(values.mailingSameAsBilling
                ? {
                    mailingPostalAddress1: values.street__billing,
                    mailingPostalAddress2: values.street__billing2,
                    mailingCity: values.city__billing,
                    mailingPostalCode: values.postalCode__billing,
                    mailingCountry: subscriptionProps.vehicleDetails?.registrationCountry || 'USA',
                    mailingStateProvince: values.state__billing,
                  }
                : {
                    mailingPostalAddress1: values.street__mailing,
                    mailingPostalAddress2: values.street__mailing2,
                    mailingCity: values.city__mailing,
                    mailingPostalCode: values.postalCode__mailing,
                    mailingCountry: subscriptionProps.vehicleDetails?.registrationCountry || 'USA',
                    mailingStateProvince: values.state__mailing,
                  }),
              givenName: values.firstName || subscriptionProps.userDetails.firstName,
              surname: values.lastName || subscriptionProps.userDetails.lastName,
            });

            sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: 'user profile' }));

            return {
              subscriptionData: {
                ...subscriptionProps,
                userDetails: {
                  ...subscriptionProps.userDetails,
                  firstName: userDetails.givenName,
                  lastName: userDetails.surname,
                  billingAddress: {
                    address1: userDetails.billingPostalAddress1,
                    address2: userDetails.billingPostalAddress2,
                    city: userDetails.billingCity,
                    state: userDetails.billingStateProvince,
                    country: userDetails.billingCountry,
                    postalCode: userDetails.billingPostalCode,
                  },
                  primaryPhone: {
                    number: userDetails.primaryPhone,
                    type: userDetails.primaryPhoneType,
                  },
                  secondaryPhone: {
                    number: userDetails.secondaryPhone,
                    type: userDetails.secondaryPhoneType,
                  },
                  preferredLanguage: userDetails.preferredLanguage,
                },
              },
            };
          } catch (e) {
            console.log('User login has failed. Reason: ', e);
            sendAnalyticsEvent(
              new EventDataBuilder(EventType.FormFailedEvent).withArgs({
                name: 'user profile',
                error: (e as Error).message,
              }),
            );
          }
        },
        onDone: {
          actions: [
            send((_, event) => ({
              type: FlowEventName.SET_SUBSCRIPTION_PROPS,
              data: event.data.subscriptionData,
            })),
            FlowEventName.UNSET_LOADING,
            FlowEventName.NAVIGATE_FORWARD,
          ],
        },
        onError: {
          actions: [FlowEventName.UNSET_LOADING, (_, event) => console.error(event), FlowEventName.NAVIGATE_TO_ERROR],
        },
      },
    },
  },
});
