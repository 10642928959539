// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._4XBMd740_OfeFZGTaA5p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

._4XBMd740_OfeFZGTaA5p.SVuUuDDVW0bB3w5RDMwU {
  flex-direction: row;
}

._4XBMd740_OfeFZGTaA5p.MUPkAKlPYLs5ECEaVlFh {
  flex-direction: column;
}

.yppLyXtkIwKPGplKanIq {
  margin: 0.5rem;
}

.El5G_GoCr9qMpZJr67P2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
}

.El5G_GoCr9qMpZJr67P2 img {
  padding: 0 1rem;
}

.aSGGF52QbBqsgg8pGt4A {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/ErrorModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".Actions {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 2rem;\n}\n\n.Actions.horizontal {\n  flex-direction: row;\n}\n\n.Actions.vertical {\n  flex-direction: column;\n}\n\n.Button {\n  margin: 0.5rem;\n}\n\n.ModalHeader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 1rem 1rem;\n}\n\n.ModalHeader img {\n  padding: 0 1rem;\n}\n\n.ModalContent {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Actions": `_4XBMd740_OfeFZGTaA5p`,
	"horizontal": `SVuUuDDVW0bB3w5RDMwU`,
	"vertical": `MUPkAKlPYLs5ECEaVlFh`,
	"Button": `yppLyXtkIwKPGplKanIq`,
	"ModalHeader": `El5G_GoCr9qMpZJr67P2`,
	"ModalContent": `aSGGF52QbBqsgg8pGt4A`
};
export default ___CSS_LOADER_EXPORT___;
