import { readMarketingPreference } from '@cv/portal-cps-lib/preferences/marketing-preference';
import {
  ReadPreferenceRequest,
  ReadPreferencePayload,
} from '@cv/portal-cps-lib/preferences/marketing-preference/models';
import config from '@config/config';

export type ReadPayload = Omit<ReadPreferencePayload, 'userId'>;

export type FetchMarketingPreferencesOptions = Partial<ReadPayload> & {
  accessToken: string;
  userId: string;
  locale?: string;
};

export const fetchMarketingPreference = (
  { accessToken, locale = 'en-US', userId, ...options }: FetchMarketingPreferencesOptions,
  keyName: 'email' | 'phoneNumber' | 'smsNumber',
) => {
  const environmentEnum = config.getEnvironmentEnum();

  const request: ReadPreferenceRequest = {
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
    },
    environment: environmentEnum,
    data: {
      userId,
      [keyName]: options[keyName],
    },
  };
  return readMarketingPreference(request);
};
