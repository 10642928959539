import { UseMutateAsyncFunction, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import { getUserPreferences, updateUserPreferences } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service';
import useVehicle from '@api/queries/useVehicle';
import useAccount from '@api/queries/useAccount';
import { useConfig } from '@components/ConfigProvider';
import {
  PreferencesResponse,
  getPreferencesRequest,
  preferencesData,
  updatePreferencesRequest,
} from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import { useSelector } from 'react-redux';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';

function usePreferences(): UseQueryResult<PreferencesResponse> & {
  update: UseMutateAsyncFunction<APIResponse<PreferencesResponse>, unknown, preferencesData, unknown>;
} {
  const queryClient = useQueryClient();
  const config = useConfig();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const { data: vehicle } = useVehicle();
  const { data: account } = useAccount();
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);

  const getPreferencesQuery = useQuery({
    enabled: Boolean(accessToken && vehicle?.vin && account?._id),
    queryKey: ['preferences', vehicle?.vin],
    queryFn: async () => {
      const readPreferencesUrl = config.get<string>('READ_PREFERENCES_URL');

      const payload: getPreferencesRequest = {
        url: readPreferencesUrl,
        data: {
          vin: vehicle?.vin,
          userId: account?._id,
        },
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
      };

      const response = await getUserPreferences(payload);

      return response?.data;
    },
    placeholderData: {
      vin: '',
      externalTenantUserId: '',
      email: '',
      timeZone: '',
      preferredLanguage: '',
      userId: '',
      serviceNotificationPreferences: [],
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { mutateAsync } = useMutation({
    mutationKey: ['updatePreferences'],
    mutationFn: async (data: preferencesData) => {
      const updatePreferencesUrl = config.get<string>('UPDATE_PREFERENCES_URL');
      const request: updatePreferencesRequest = {
        url: updatePreferencesUrl,
        data: {
          ...data,
          vin: vehicle?.vin,
          userId: account?._id,
        },
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
      };

      return updateUserPreferences(request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['preferences'] });
    },
  });

  return { ...getPreferencesQuery, update: mutateAsync };
}

export default usePreferences;
