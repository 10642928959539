// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mip15XF7w32Zw17C35Qg {
  background-image: var(--hero-image-desktop);
  background-size: cover;
  color: var(--theme-hero-banner-color-text, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 420px;
  /* TODO: margin needs to be placed on a layout level */
  margin-bottom: 1.5rem;
  padding: 2.625rem;
  position: relative;
}

.ZKXG6vanBWjkyjrl1qGO {
  max-width: 350px;
}

.ZKXG6vanBWjkyjrl1qGO h2 {
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1.2em;
  margin: 0 0 0.25em;
}

.ZKXG6vanBWjkyjrl1qGO p {
  line-height: 1.5;
}

.cp8kXqaruUHPhHGd50LK a {
  background-color: var(--theme-color-primary);
  text-transform: uppercase;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

.cp8kXqaruUHPhHGd50LK a:hover {
  color: #fff;
}

@media (max-width: 960px) {
  .Mip15XF7w32Zw17C35Qg {
    background-image: var(--hero-image-tablet, var(--hero-image-desktop));
    justify-content: flex-start;
  }

  .ZKXG6vanBWjkyjrl1qGO {
    font-size: 0.8rem;
  }
}

@media (max-width: 640px) {
  .Mip15XF7w32Zw17C35Qg {
    background-image: var(--hero-image-mobile, var(--hero-image-tablet, var(--hero-image-desktop)));
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HeroBanner/HeroBanner.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,sBAAsB;EACtB,gDAAgD;EAChD,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,sDAAsD;EACtD,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;EAC5C,yBAAyB;EACzB,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,qEAAqE;IACrE,2BAA2B;EAC7B;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,+FAA+F;EACjG;AACF","sourcesContent":[".HeroBanner {\n  background-image: var(--hero-image-desktop);\n  background-size: cover;\n  color: var(--theme-hero-banner-color-text, #fff);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 420px;\n  /* TODO: margin needs to be placed on a layout level */\n  margin-bottom: 1.5rem;\n  padding: 2.625rem;\n  position: relative;\n}\n\n.HeroBanner-text {\n  max-width: 350px;\n}\n\n.HeroBanner-text h2 {\n  font-size: 2.25rem;\n  font-weight: bold;\n  line-height: 1.2em;\n  margin: 0 0 0.25em;\n}\n\n.HeroBanner-text p {\n  line-height: 1.5;\n}\n\n.Hero-link a {\n  background-color: var(--theme-color-primary);\n  text-transform: uppercase;\n  line-height: 1.5;\n  padding: 0.375rem 0.75rem;\n  margin: 0.5rem 0;\n  cursor: pointer;\n}\n\n.Hero-link a:hover {\n  color: #fff;\n}\n\n@media (max-width: 960px) {\n  .HeroBanner {\n    background-image: var(--hero-image-tablet, var(--hero-image-desktop));\n    justify-content: flex-start;\n  }\n\n  .HeroBanner-text {\n    font-size: 0.8rem;\n  }\n}\n\n@media (max-width: 640px) {\n  .HeroBanner {\n    background-image: var(--hero-image-mobile, var(--hero-image-tablet, var(--hero-image-desktop)));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeroBanner": `Mip15XF7w32Zw17C35Qg`,
	"HeroBanner-text": `ZKXG6vanBWjkyjrl1qGO`,
	"Hero-link": `cp8kXqaruUHPhHGd50LK`
};
export default ___CSS_LOADER_EXPORT___;
