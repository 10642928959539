import { PackageType, OfferType, DiscountType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import {
  EligiblePackagesResponse,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SubscriptionData } from '@api';
import config from '@config/config';
import { Role } from './types';

export const isVehicleOwner = (role: Role) => role.roleId === config.get<string>('VEHICLE_OWNER_ROLE_ID');

export const isPrimarySubscriber = (role: Role) => role.roleId === config.getOemValue('PRIMARY_SUBSCRIBER_ROLE_ID');

export const isRdrDefault = (role: Role) => role.roleId === config.getOemValue('RDR_DEFAULT_ROLE_ID');

export function isPreRdrPackage(pkg: SubscribedPackage) {
  return pkg.packageType === PackageType.Pre_rdr;
}

export function isActivePackage(pkg: SubscribedPackage) {
  return pkg.packageType === PackageType.Regular || pkg.packageType === PackageType.Default;
}

type EligiblePackagesLookup = {
  packages: EligiblePackagesResponse[];
  type?: PackageType;
};

export function findFreeProductInCurrentPackages(data: SubscriptionData) {
  return data.subscribedPackages.find((pkg) => {
    return pkg.discounts.find((discount) => {
      return (
        discount.offerType === OfferType.Trial &&
        discount.discountType === DiscountType.Percentage &&
        Number(discount.amount) === 100
      );
    });
  });
}

export function findFreeProductInEligiblePackages({ packages, type = PackageType.Regular }: EligiblePackagesLookup) {
  return packages.filter((pkg) => pkg.packageType === type).find((pkg) => findFreePackageVariant(pkg));
}

export function findFreePackageVariant(pkg: EligiblePackagesResponse) {
  return pkg.variants.find((variant) => {
    return variant.discounts.find((discount) => {
      return (
        discount.offerType === OfferType.Trial &&
        discount.type === DiscountType.Percentage &&
        Number(discount.amount) === 100
      );
    });
  });
}
