import { useApi } from '@api';

export default function useIsAuthorised() {
  const api = useApi();

  const accessToken = api.storeService.getAccessToken();
  const refreshToken = api.storeService.getRefreshToken();

  return Boolean(refreshToken && accessToken);
}
