import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { FormikInputField } from '@components/FormikFields';
import { Button } from '@components-lib';

import styles from './EmailSearch.module.css';

export type EmailSearchFormData = {
  email: string;
};

type EmailSearchProps = {
  labelTitle: string;
  labelInput: string;
  labelInputPlaceholder: string;
  labelSubmit: string;
  labelErrorEmailRequired: string;
  labelErrorEmailInvalid: string;
  onSubmit: (data: EmailSearchFormData) => void;
};

const EmailSearch = ({
  labelTitle,
  labelInput,
  labelInputPlaceholder,
  labelSubmit,
  labelErrorEmailRequired,
  labelErrorEmailInvalid,
  onSubmit,
}: EmailSearchProps) => {
  const validationSchema = yup.object().shape({
    email: yup.string().trim().required(labelErrorEmailRequired).email(labelErrorEmailInvalid),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
    >
      <Form className={styles.form}>
        <h1>{labelTitle}</h1>
        <FormikInputField
          name="email"
          label={labelInput}
          inputMode="email"
          placeholder={labelInputPlaceholder}
          className={styles['input-field']}
          classes={{
            wrapper: styles['input-wrapper'],
            label: styles['input-label'],
            error: styles['input-error'],
          }}
        />
        <Button variant="contained" className={styles.submit} type="submit">
          {labelSubmit}
        </Button>
      </Form>
    </Formik>
  );
};

export default EmailSearch;
