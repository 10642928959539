import React, { useEffect, useState } from 'react';
import { EligiblePackageInfo } from '../../Types';
import { AddOnPackage } from './AddOnPackage';
import { deDupePackages, sortByActualPriceInDesc } from '../../utils';
import Spinner from '../../../components/Spinner';
import { getPackagesWithTax } from '../../../services';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { useFlowMachineContext, useFlowMachineContextFlowStorage } from '../../../Router/RouterContext';

export const AddOnPackages = ({ packages }: { packages: EligiblePackageInfo[] }) => {
  const {
    content: {
      commonWebContent: { shouldIncludeTax },
    },
    subscriptionProps,
  } = useFlowMachineContext();

  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  const [eligibleAddOns, setEligibleAddOns] = useState(null);

  useEffect(() => {
    (async () => {
      const ePackages = await getPackagesWithTax(packages, subscriptionProps, subscribedPackages, shouldIncludeTax);
      setEligibleAddOns(ePackages);
    })();
    return (): void => {
      setEligibleAddOns(null);
    };
  }, [packages]);

  return (
    <div>
      {!eligibleAddOns && <Spinner />}
      {eligibleAddOns &&
        deDupePackages(sortByActualPriceInDesc(packages), OfferType.Trial).map((pkgInfo, idx) => (
          <AddOnPackage key={idx} pkg={pkgInfo} />
        ))}
    </div>
  );
};
