import React from 'react';
import ProductInfo from './ProductInfo';
import { IconSection, ProductDescription, ProductHeader, ProductTitle, ProductWrapper } from './styles';
import { Content } from '../../styled-components/globalStyles';
import Image from '../../components/Image';
import { translateToEN } from '../utils/translations';
import { EMPTY_SPACE_REGEX } from '../utils/constants';
import { ComponentRoutes } from '../../Router/types';
import { ContentfulAssets } from '..';

export interface ProductProps {
  productInfo: ProductInfo;
  assets: ContentfulAssets;
  make: string;
  locale: string;
  tenantId: string;
  location: string;
}

const Product: React.FC<ProductProps> = ({ productInfo, assets, make, locale, tenantId, location }) => {
  const { productName, marketingName, longDescription, shortDescription } = productInfo;
  const description = location === ComponentRoutes.managePromoPackages ? shortDescription : longDescription;

  const getProductName = () => {
    return translateToEN(productName.replace(EMPTY_SPACE_REGEX, ''), locale, tenantId);
  };

  return (
    <ProductWrapper>
      <ProductHeader>
        <IconSection>
          <Image assets={assets} name={getProductName()} prefix={make} />
        </IconSection>
        <ProductTitle>{marketingName}</ProductTitle>
      </ProductHeader>
      <ProductDescription>
        <Content>{description}</Content>
      </ProductDescription>
    </ProductWrapper>
  );
};

export default Product;
