import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { ComponentRoutes } from '../../types';
import { CommonComponentFlowMap } from './common';
import { HondaComponentFlowMap } from './honda';
import { NNAComponentFlowMap } from './nna';
import { SubaruComponentFlowMap } from './subaru';
import { ToyotaComponentFlowMap } from './toyota';
import { FcaComponentFlowMap } from './fca';
import { ComponentFlowStateConfig } from './Types';

const customPageToComponentData = {
  [TenantIds.Infiniti_CA]: NNAComponentFlowMap,
  [TenantIds.Infiniti_US]: NNAComponentFlowMap,
  [TenantIds.Nissan_CA]: NNAComponentFlowMap,
  [TenantIds.Nissan_US]: NNAComponentFlowMap,
  [TenantIds.Toyota]: ToyotaComponentFlowMap,
  [TenantIds.Lexus]: ToyotaComponentFlowMap,
  [TenantIds.Honda]: HondaComponentFlowMap,
  [TenantIds.Acura]: HondaComponentFlowMap,
  [TenantIds.Subaru]: SubaruComponentFlowMap,
  [TenantIds.Fca]: FcaComponentFlowMap,
};

export const getComponentFlowTuple = (
  componentName: ComponentRoutes,
  tenantId: TenantIds,
): { flow: ComponentFlowStateConfig<any>; component: () => JSX.Element } => {
  return customPageToComponentData[tenantId]?.[componentName] || CommonComponentFlowMap[componentName];
};
