import { useApi } from '@api';

const useTokenScope = () => {
  const api = useApi();
  const scope = api.storeService.getScope() ?? [];

  return {
    canUseRts: scope.includes('rts'),
    canUseCps: scope.includes('cps'),
  };
};

export default useTokenScope;
