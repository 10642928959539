import React, { useCallback, useState } from 'react';
import Dialog, { DialogProps } from './Dialog';

type UseDialogProps = DialogProps & {
  description?: string;
  initialClosed?: boolean;
};

const DialogContext = React.createContext();

const DialogProvider = (props: UseDialogProps) => {
  const [show, setShow] = useState(false);
  const [dialogProps, setDialogProps] = useState<UseDialogProps>({});

  const closeDialog = useCallback(() => {
    setShow(false);
  }, []);

  const openDialog = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, setDialogProps }}>
      {props.children}
      {show && (
        <Dialog onCancel={closeDialog} onOk={closeDialog} {...dialogProps}>
          {dialogProps.children}
          <span>{dialogProps.description}</span>
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = React.useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  const { openDialog, setDialogProps, closeDialog } = context;

  const dialog = (props: UseDialogProps) => {
    setDialogProps(props);
    if (!props.initialClosed) openDialog();
  };
  dialog.close = closeDialog;
  dialog.open = openDialog;
  return dialog;
};

export { DialogProvider, useDialog };
