import React from 'react';
import { AssetObject } from '@utils/assets';
import { Box, Typography } from '@mui/material';
import { ServiceDescriptions } from '@components/Cancellation/cancellationUtils';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import Image from '@app/components-lib/components/Image';

export type ServiceItemProps = {
  service: VehicleServiceDetails;
  descriptions: ServiceDescriptions;
  defaultShortDescription: string;
  defaultLongDescription: string;
  assets?: AssetObject;
};

const ServiceItem = ({
  service,
  descriptions,
  defaultShortDescription,
  defaultLongDescription,
  assets = {},
}: ServiceItemProps) => {
  const { marketingName, description, vehicleServiceName } = service;

  return (
    <Box data-testid="service">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '0.5rem',
          justifyContent: 'flex-start',
        }}
      >
        <Image
          name={marketingName}
          assets={assets}
          size="22px"
          style={{ aspectRatio: '1 / 1', maxHeight: '22px', marginRight: '1rem' }}
        />
        <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 700 }}>
          {marketingName}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        {descriptions[vehicleServiceName]?.shortDescription || description || defaultShortDescription}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {descriptions[vehicleServiceName]?.longDescription || description || defaultLongDescription}
      </Typography>
    </Box>
  );
};

export default ServiceItem;
