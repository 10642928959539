import React, { useMemo } from 'react';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import RuleType from '@customTypes/password/RuleType';
import ContentRenderer from '@components/ContentRenderer';

export type PasswordTooltipContentProps = {
  tooltipLabels: ContentfulComponent;
};

const PasswordTooltipContent = ({ tooltipLabels }: PasswordTooltipContentProps) => {
  const signUpLabels = useMemo(
    () => ({
      ...tooltipLabels,
      content: tooltipLabels.content.filter(
        ({ ruleType }) => ruleType !== RuleType.Require && ruleType !== RuleType.Confirmation,
      ),
    }),

    [tooltipLabels],
  );
  return <ContentRenderer name="rules-list" content={[signUpLabels]} />;
};

export default PasswordTooltipContent;
