import { send } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const infoPageEnrollmentFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.infoPageEnrollment,
  states: {
    pre: {
      //[getSubscriptionTerms] action is inside InformationPageSubscription.tsx
      entry: 'getSubscriptionTerms',
      always: [
        {
          target: 'NAVIGATE',
          cond: 'notCpoPreOwned',
        },
        {
          target: 'idle',
          actions: send({
            type: 'PUSH_HISTORY',
            data: { componentRoute: ComponentRoutes.infoPageEnrollment },
          }),
        },
      ],
    },
    idle: {
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'NAVIGATE' },
      },
    },
    NAVIGATE: {
      entry: 'navigateForward',
    },
  },
});
