import { PackageSubscription, SubscribedPackageInfo } from '@manageSubscription/Types';
import { PackageToRemove } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { buildPackageToRemove } from '@manageSubscription/builders/packageBuilders';
import { isAnnualTerm } from '@manageSubscription/utils/subscriptionUtils';
import { isTrialPackage, isDefaultPackage, isPackageFutureDated } from '@manageSubscription/utils';
import { CancelReason, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export const packagesToCancel = (
  subscribedPackages: SubscribedPackageInfo[],
  followOnPackages: PackageSubscription[],
  cancelReason?: CancelReason,
): PackageToRemove[] => {
  return subscribedPackages.map((sPkg) => {
    let isCancelEffectiveToday = true;
    if (!followOnPackages?.length) {
      isCancelEffectiveToday =
        isTrialPackage(sPkg.variant || sPkg) ||
        isAnnualTerm(sPkg) ||
        isPackageFutureDated(sPkg) ||
        isDefaultPackage(sPkg) ||
        isAddOnPackage(sPkg.packageType);
    }
    return buildPackageToRemove(sPkg, isCancelEffectiveToday, cancelReason);
  });
};

export const isAddOnPackage = (packageType: PackageType) => {
  return packageType === PackageType.Add_on;
};
