import { LOADING_ACTIONS } from '../actions/';

type LoadingState = {
  isLoading: boolean;
};

type LoadingAction = {
  type: LOADING_ACTIONS;
  data: boolean;
};

export default (state: LoadingState = { isLoading: false }, action: LoadingAction) => {
  switch (action.type) {
    case LOADING_ACTIONS.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.data,
      };
    default:
      return state;
  }
};
