import React from 'react';
import ContentRenderer from '@components/ContentRenderer';
import jss from '@utils/jss';
import clsx from 'clsx';

import styles from './ContentfulGrid.module.css';

type ContentfulGridProps = {
  direction?: 'column' | 'row';
  overflow?: 'move to the next line' | 'keep in the same line';
  justify?: 'start' | 'end' | 'center' | 'space between' | 'space around';
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  gap?: string;
  name: string;
  content: {
    componentType: string;
  }[];
};

const configMap: { [key: string]: string } = {
  'space between': 'space-between',
  'space around': 'space-around',
};
const getCorrectProperty = (key: string) => {
  return configMap[key] ?? key;
};

const ContentfulGrid = ({
  direction = 'row',
  overflow = 'move to the next line',
  justify = 'start',
  align = 'stretch',
  gap,
  name,
  content,
}: ContentfulGridProps) => {
  let selectorName;

  if (gap) {
    const halfGap = +gap / 2;
    selectorName = `gap-${gap}`;

    jss({
      [`.${selectorName}`]: {
        margin: `calc(${halfGap} * var(--theme-spacing-step) * -1)`,
        width: `calc(100% + ${gap} * var(--theme-spacing-step))`,
      },
      [`.${selectorName} > *`]: {
        padding: `calc(${halfGap} * var(--theme-spacing-step))`,
      },
    });
  }

  return (
    <div
      className={clsx(
        styles['contentful-grid'],
        selectorName,
        styles[direction],
        styles[overflow === 'keep in the same line' ? 'nowrap' : 'wrap'],
        styles[`align-${align}`],
        styles[`justify-${getCorrectProperty(justify)}`],
      )}
    >
      {<ContentRenderer name={name} content={content} />}
    </div>
  );
};

export default ContentfulGrid;
