import { Button } from '@components-lib';
import { CardView } from '@components/Card';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styles from './AccountEnrollment.module.css';
import config from '@config/config';

type SuccessPageProps = {
  labels: Record<string, string>;
};

const SuccessPage = ({ labels }: SuccessPageProps) => {
  const history = useHistory();

  const handleGoHome = async () => {
    history.push(config.get('DISPLAY_VIN_STATUS_PAGE') ? '/vinStatus' : '/');
  };

  return (
    <CardView
      type="main"
      className={styles['success-page-container']}
      classes={{ inner: styles['success-page-inner-container'] }}
    >
      <div className={styles['success-page-content']}>
        <h3 className={styles['success-page-title']}>
          <FaCheckCircle className={styles['success-page-icon']} />
          <p>{labels.successTitle}</p>
        </h3>
        <p className={styles['success-page-details']}>{labels.successDescription}</p>
        <Button onClick={handleGoHome}>{labels.successButton}</Button>
      </div>
    </CardView>
  );
};

export default SuccessPage;
