import { send } from 'xstate/lib/actions';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const managePaidPackagesFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePaidPackages,
  states: {
    pre: {
      always: [
        { target: 'navigateToSkip', cond: 'hasNoEligiblePackageVariants' },
        { target: 'navigate', cond: 'skipPaidPackagesSubaru' },
        { target: 'navigate', cond: 'hasNoEligiblePaidPackages' },
        'idle',
      ],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePaidPackages },
        }),
      ],
      on: {
        HANDLE_ADD_SERVICES: 'navigate',
        [FlowEventName.NAVIGATE_FORWARD]: 'navigate',
        [FlowEventName.NAVIGATE_BACK]: { actions: 'navigateBack' },
      },
    },
    navigate: {
      entry: ['navigateForward'],
    },
    navigateBack: {
      entry: 'navigateBack',
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});

export const actions: ComponentActions<{}> = {};
