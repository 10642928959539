import React from 'react';
import Markdown from '../../../components/Markdown';
import { buildOfferDetailKeys, replaceTemplateString } from '../../utils';
import { DiscountInfo, VariantInfo } from '../../Types';
import { styled } from '@mui/material';
import LinkToOkModal from '../../../components/Modal/LinkToOkModal';
import { ContentfulAssets, DiscountWebContent } from '../..';

const OfferDetailsLink = styled(LinkToOkModal)`
  display: flex;
  margin: 1rem 0;
  justify-content: left;
`;

export const DetailHeading = styled('h2')`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
  text-transform: uppercase;
`;
interface OfferDetailProps {
  packageName: string;
  variant: VariantInfo;
  discount: DiscountInfo;
  okButtonLabel: string;
  discountWebContent: DiscountWebContent;
  customerCareCancelPackageNumber: string;
  assets: ContentfulAssets;
}

const OfferDetails = ({
  packageName,
  variant,
  discount,
  okButtonLabel,
  discountWebContent,
  customerCareCancelPackageNumber,
  assets,
}: OfferDetailProps) => {
  const {
    offerDetailsLabel,
    offerDetails: { header: offerDetailHeader, offerDetail },
  } = discountWebContent;

  const offerDetails = () => {
    const matchingKey = buildOfferDetailKeys(variant, discount, packageName)?.find((key) => offerDetail[key]);

    const message = replaceTemplateString(`${offerDetailHeader} ${offerDetail[matchingKey] || ''}`, {
      customerCareCancelPackageNumber,
    });
    return (
      <>
        <DetailHeading>{offerDetailsLabel}</DetailHeading>
        <Markdown text={message} />
      </>
    );
  };

  return (
    <OfferDetailsLink assets={assets} message={offerDetails()} buttonText={okButtonLabel}>
      {offerDetailsLabel}
    </OfferDetailsLink>
  );
};

export default OfferDetails;
