import React from 'react';
import { containsAddon } from '../utils';

import ManageAddOns from './ManageAddOns';
import {
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
} from '../../Router/RouterContext';
import { FlowEventName } from '@lib-appRouter/flowTypes';

const ManageAddOnsContainer = () => {
  const {
    addOnsWebContent: { addOnHeaderLabel, addOnDescription },
    commonWebContent: { skipAndContinueButtonLabel, saveAndContinueButtonLabel, backButtonLabel },
  } = useFlowMachineContextContent();
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();
  const { eligibleAddOns } = useFlowMachineContext();
  const { send } = useFlowMachine();
  const navigateForward = () => {
    send({ type: FlowEventName.NAVIGATE_FORWARD });
  };
  const navigateBack = () => {
    send({ type: FlowEventName.NAVIGATE_BACK });
  };

  return (
    <ManageAddOns
      addOnHeaderLabel={addOnHeaderLabel}
      addOnDescription={addOnDescription}
      backButtonLabel={backButtonLabel}
      continueButtonLabel={
        containsAddon(packageSubscriptions) ? saveAndContinueButtonLabel : skipAndContinueButtonLabel
      }
      eligibleAddOns={eligibleAddOns}
      navigateForward={navigateForward}
      navigateBack={navigateBack}
    />
  );
};

export default ManageAddOnsContainer;
