// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZWJzr7wpZVh1FbHdy6Q2 {
  font-size: var(--theme-paragraph-font-size);
  max-height: 75vh;
}

.ZWJzr7wpZVh1FbHdy6Q2 h1 {
  text-align: center;
}

.qp9vv4JWCG08tdC6eDG9 {
  display: none;
}

.uLTJKkksUozlWin56dDh {
  margin-bottom: 2rem;
}

.uLTJKkksUozlWin56dDh p {
  margin-bottom: 1rem;
}

.QYlHVh8bL4Y0UkJcHQ6j {
  display: flex;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.QYlHVh8bL4Y0UkJcHQ6j button {
  min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dialog {\n  font-size: var(--theme-paragraph-font-size);\n  max-height: 75vh;\n}\n\n.dialog h1 {\n  text-align: center;\n}\n\n.header {\n  display: none;\n}\n\n.body {\n  margin-bottom: 2rem;\n}\n\n.body p {\n  margin-bottom: 1rem;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  column-gap: 1rem;\n}\n\n.buttons button {\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `ZWJzr7wpZVh1FbHdy6Q2`,
	"header": `qp9vv4JWCG08tdC6eDG9`,
	"body": `uLTJKkksUozlWin56dDh`,
	"buttons": `QYlHVh8bL4Y0UkJcHQ6j`
};
export default ___CSS_LOADER_EXPORT___;
