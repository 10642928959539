import React, { useState, useMemo } from 'react';

import {
  InputWrapper,
  InputItem,
  BodyText,
  Button,
  TitleWrapper,
  ErrorMessage,
  ErrorMessageContainer,
  SecurityWrapper,
} from './styles';
import { AuthCodeFormContent, DictionaryLabel } from '@manageSubscription';
import Spinner from '@lib-components/Spinner/Spinner';
import { Container } from '@styled-components/globalStyles';

export enum OtpDigitsFormat {
  RRS = 'rrs',
  Promotional = 'promotional',
}

export type BodyTextSource = 'email' | 'sms' | 'RRS-IVA';

export type OtpFormProps = {
  labels: AuthCodeFormContent;
  apiError: boolean;
  inputDigitsFormat: OtpDigitsFormat;
  bodyTextSource: BodyTextSource;
  errorMessage: DictionaryLabel;
  isLoading?: boolean;
  classes?: { form?: string };
  onSubmit: (otp: string) => Promise<void>;
  skip?: () => void;
  tryAgain?: () => void;
  onChange?: () => void;
};
const OtpForm = ({
  labels,
  apiError,
  inputDigitsFormat,
  bodyTextSource,
  errorMessage,
  isLoading,
  classes = {},
  skip,
  tryAgain,
  onSubmit,
  onChange,
}: OtpFormProps) => {
  const {
    title,
    bodyEmail,
    bodyTextMessage,
    bodyHeadUnit,
    enterSecurityCode,
    submitButton,
    inputFieldsPromo,
    inputFieldsRRS,
    tryDifferentRecoveryMethod,
    skipLabel,
  } = labels;

  const inputFieldsDict = {
    promotional: inputFieldsPromo?.primary,
    rrs: inputFieldsRRS?.primary,
  };

  const bodyTextDict = {
    email: bodyEmail?.primary,
    sms: bodyTextMessage?.primary,
    'RRS-IVA': bodyHeadUnit?.primary,
  };
  const inputFieldDigits = inputFieldsDict[inputDigitsFormat] || '4';
  const bodyText = bodyTextDict[bodyTextSource];
  const otpSize = parseInt(inputFieldDigits, 10);
  const [otp, setOtp] = useState<string[]>(Array(otpSize).fill(''));

  const handleChange = (target: HTMLInputElement, index: number) => {
    onChange?.();
    const { value } = target;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    if (sanitizedValue.length === otpSize) {
      setOtp(Array.from(sanitizedValue));
      const lastElement = document.getElementById(`otp_input_${otpSize - 1}`);
      lastElement?.focus();
      return;
    }

    const number = sanitizedValue.slice(0, 1);
    setOtp((prevValue) => {
      prevValue[index] = number;
      return [...prevValue];
    });
    const nextElement = document.getElementById(`otp_input_${index + 1}`);
    number && nextElement?.focus();
  };

  const handleSubmit = () => onSubmit(otp.join(''));

  const onKeyDown = (key: string, length: number, index: number) => {
    if (key === 'Enter' && length - 1 === index && formValidate()) {
      handleSubmit();
    }
  };

  const renderInputFields = useMemo(() => {
    return Array(otpSize)
      .fill(null)
      .map((_, index, { length }) => {
        return (
          <InputItem
            error={apiError}
            id={`otp_input_${index}`}
            key={index}
            type="text"
            value={otp[index] || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target, index)}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(e.key, length, index)}
            aria-label="code"
            inputProps={{ style: { textAlign: 'center' } }}
          />
        );
      });
  }, [apiError, handleChange, onKeyDown]);

  const renderErrorMessage = () => {
    return (
      <ErrorMessageContainer>
        <ErrorMessage>{errorMessage?.primary}</ErrorMessage>
      </ErrorMessageContainer>
    );
  };
  const formValidate = () => otp.every(Boolean);
  return (
    <Container>
      {isLoading && <Spinner />}
      <SecurityWrapper aria-labelledby="form-label" className={classes?.form}>
        {title && <h1 id="form-label">{title?.primary}</h1>}
        <BodyText>{bodyText}</BodyText>
        <TitleWrapper>
          <h3>{enterSecurityCode?.primary}</h3>
        </TitleWrapper>
        {inputFieldDigits && <InputWrapper>{renderInputFields}</InputWrapper>}
        {apiError && renderErrorMessage()}
        <Button sx={{ mt: 4 }} variant="contained" disabled={!formValidate()} onClick={handleSubmit}>
          {submitButton?.primary}
        </Button>

        {tryAgain && (
          <Button
            sx={{ mt: 2 }}
            variant="text"
            onClick={() => {
              setOtp(Array(otpSize).fill(''));
              tryAgain();
            }}
          >
            {tryDifferentRecoveryMethod?.primary}
          </Button>
        )}
        {skip && (
          <Button sx={{ mt: 1 }} variant="text" onClick={skip}>
            {skipLabel?.primary}
          </Button>
        )}
      </SecurityWrapper>
    </Container>
  );
};
export default OtpForm;
