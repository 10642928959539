import { ComponentRoutes, FlowActions } from '../../types';
import { CompoundState } from '../componentFlow/Types';
import { Middleware } from '../../flowMiddleware';

export default (middleware: Middleware) =>
  ({
    initial: ComponentRoutes.checkIfProfileIsComplete,
    states: {
      [ComponentRoutes.checkIfProfileIsComplete]: {
        ...middleware.getComponentFlow(ComponentRoutes.checkIfProfileIsComplete),
        on: {
          [FlowActions.proceed]: ComponentRoutes.userProfile,
          [FlowActions.skip]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.userProfile]: {
        ...middleware.getComponentFlow(ComponentRoutes.userProfile),
        on: {
          [FlowActions.proceed]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.manageTrialPackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.manageTrialPackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.termsAndConditions,
        },
      },
      [ComponentRoutes.termsAndConditions]: {
        ...middleware.getComponentFlow(ComponentRoutes.termsAndConditions),
        on: {
          [FlowActions.proceed]: ComponentRoutes.reviewDowngradeOrder,
          [FlowActions.cancel]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.reviewDowngradeOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.reviewDowngradeOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.confirmOrder,
          [FlowActions.return]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.confirmOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.confirmOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.manageTrialPackages,
        },
      },
    },
  } as CompoundState);
