import React, { useMemo } from 'react';
import { useFlowMachine, useFlowMachineContext } from '@lib-appRouter/RouterContext';
import * as Yup from 'yup';
import { FormValues, FormValuesMultiBrand } from '@lib-appRouter/flows/componentFlow/Types';
import UserProfile from './UserProfile';
import { FormFieldDropdown } from '@manageSubscription';
import getBrandFields from './getBrandFields';
import AddressField from './AddressField';
import UserProfileYupShapeType from './UserProfileYupShapeType';
import { validatePhoneNumber } from '@manageSubscription/utils';

const UserProfileContainer = () => {
  const flowMachine = useFlowMachine();
  const { subscriptionProps, content } = useFlowMachineContext();
  const { userDetails } = subscriptionProps;
  const {
    commonWebContent,
    userProfileWebContent,
    nissanCpoWebContent,
    addressFormWebContent: { streetError, cityError, postalCodeError, stateError, phoneError, phoneRequiredError },
    formFields,
  } = content;
  const { firstNameValidationError, lastNameValidationError, pinValidationError, pinRequiredError } =
    userProfileWebContent;

  const { isPinConfigured } = userDetails;

  const primaryPhoneType = formFields?.find((f) => f.fieldName === 'primaryPhoneType') as FormFieldDropdown;

  const yupShape: UserProfileYupShapeType = useMemo(() => {
    return {
      firstName: Yup.string().trim().required(firstNameValidationError),
      lastName: Yup.string().trim().required(lastNameValidationError),
      primaryPhone: Yup.string()
        .required(phoneRequiredError)
        .test('validation', phoneError, (value = '') => validatePhoneNumber(value)),
      primaryPhoneType: Yup.string().required(primaryPhoneType?.fieldValidation?.fieldRequiredError),
      pin: Yup.string().when([], {
        is: () => !isPinConfigured,
        then: (schema) =>
          schema.required(pinRequiredError).matches(/^[0-9]+$/, "Must be only digits")
        .required()
        .min(4, 'Must be exactly 4 digits')
        .max(4, 'Must be exactly 4 digits'),
        }),
      ...getBrandFields(subscriptionProps, content),
      street__billing: Yup.string().required(streetError),
      street__billing2: Yup.string(),
      city__billing: Yup.string().required(cityError),
      state__billing: Yup.string().required(stateError),
      postalCode__billing: Yup.string().required(postalCodeError),

      street__mailing: Yup.string().when('mailingSameAsBilling', {
        is: false,
        then: (schema) => schema.required(streetError),
      }),
      street__mailing2: Yup.string(),
      city__mailing: Yup.string().when('mailingSameAsBilling', {
        is: false,
        then: (schema) => schema.required(cityError),
      }),
      state__mailing: Yup.string().when('mailingSameAsBilling', {
        is: false,
        then: (schema) => schema.required(stateError),
      }),
      postalCode__mailing: Yup.string().when('mailingSameAsBilling', {
        is: false,
        then: (schema) => schema.required(postalCodeError),
      }),
    };
  }, [isPinConfigured, firstNameValidationError, lastNameValidationError, pinRequiredError, pinValidationError]);

  const handleSubmit = (values?: FormValues) => {
    flowMachine.send({
      type: 'HANDLE_INPUT',
      data: { values },
    });
  };

  const needPreferredLanguage = !!yupShape.preferredLanguage;

  const initialValues: FormValuesMultiBrand = {
    firstName: userDetails?.firstName || '',
    lastName: userDetails?.lastName || '',
    email: userDetails?.email || '',
    pin: '',
    ...(needPreferredLanguage ? { preferredLanguage: '' } : {}),
    preferredLanguage: '',
    street__mailing: userDetails?.mailingAddress?.address1 || '',
    street__mailing2: userDetails?.mailingAddress?.address2 || '',
    postalCode__mailing: userDetails?.mailingAddress?.postalCode || '',
    city__mailing: userDetails?.mailingAddress?.city || '',
    state__mailing: userDetails?.mailingAddress?.state || '',
    street__billing: userDetails?.billingAddress?.address1 || '',
    street__billing2: userDetails?.billingAddress?.address2 || '',
    postalCode__billing: userDetails?.billingAddress?.postalCode || '',
    city__billing: userDetails?.billingAddress?.city || '',
    state__billing: userDetails?.billingAddress?.state || '',
    primaryPhone: userDetails?.primaryPhone?.number || userDetails?.phone || '',
    primaryPhoneType: userDetails?.primaryPhone?.type || 'Mobile',
    mailingSameAsBilling: userDetails?.homeAddressSameAsMailingAddress || false,
  };

  return (
    <UserProfile
      AddressFieldComponent={AddressField}
      isPinConfigured={isPinConfigured}
      needPreferredLanguage={needPreferredLanguage}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      yupShape={yupShape}
      commonWebContent={commonWebContent}
      userProfileWebContent={userProfileWebContent}
      nissanCpoWebContent={nissanCpoWebContent}
      webContent={content}
      primaryPhoneTypeField={primaryPhoneType}
      subscriptionProps={subscriptionProps}
    />
  );
};

export default UserProfileContainer;
