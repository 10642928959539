import React from 'react';
import clsx from 'clsx';
import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent, { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';

import styles from './Carousel.module.css';

export type CarouselContentProps = {
  backgroundColor?: string;
  backgroundImage: ContentfulFile;
  content: Array<ContentfulComponent>;
  contentPlacementAreas?: string;
  slideContentWidth?: string;
  minImageHeight: number;
  isMobile: boolean;
  textPlacementOustideImage?: boolean;
};

function CarouselContent({
  backgroundColor,
  backgroundImage,
  content,
  contentPlacementAreas,
  minImageHeight,
  isMobile,
  slideContentWidth,
  textPlacementOustideImage,
}: CarouselContentProps): JSX.Element {
  const getFlexPosition = (): Record<string, string> =>
    (contentPlacementAreas ?? 'Upper Left')
      .split(' ')
      .map((axis) => {
        switch (axis) {
          case 'Upper':
          case 'Left':
            return 'flex-start';
          case 'Lower':
          case 'Right':
            return 'flex-end';
          default:
            return 'center';
        }
      })
      .reduce((prev, cur, idx, arr) => ({ justifyContent: arr[0], alignItems: arr[1] }), {});

  const contentStyle = {
    backgroundColor: backgroundColor || 'initial',
    ...getFlexPosition(),
    minHeight: minImageHeight,
  };

  const wrapperStyle = {
    minHeight: minImageHeight,
    height: isMobile ? minImageHeight : '100%',
  };

  const backgroundContentClassNames = textPlacementOustideImage
    ? ['slide-image-wrapper', 'slide-content-image']
    : ['carousel-content-image-wrapper', 'carousel-content-image'];

  const renderBackground = (classNames: string[]) => (
    <div className={styles[classNames[0]]} style={wrapperStyle}>
      <ContentfulImage className={styles[classNames[1]]} imageData={backgroundImage} />
    </div>
  );

  const renderSlideContentWrapper = () => (
    <div
      className={`${
        textPlacementOustideImage
          ? styles['slide-content-wrapper']
          : clsx(styles['carousel-content-wrapper'], {
              [styles['carousel-content-wrapper-small']]: slideContentWidth === 'small' || !slideContentWidth,
              [styles['carousel-content-wrapper-medium']]: slideContentWidth === 'medium',
            })
      }`}
    >
      <ContentRenderer name="slide-content" content={content} />
    </div>
  );

  return (
    <div className={styles['carousel-content']} style={contentStyle}>
      {backgroundImage && renderBackground(backgroundContentClassNames)}
      {content && renderSlideContentWrapper()}
    </div>
  );
}

export default CarouselContent;
