import React, { useState } from 'react';
import { Box } from '@mui/material';
import VinLookup from '@components/VinLookup/VinLookup';
import { getDictionariesContent } from '@utils/labels';
import ContentfulComponent from '@app/types/ContentfulComponent';
import { getAssets, ServerAsset } from '@utils/assets';
import ContentRenderer from '@components/ContentRenderer';
import EligibleVehicleOverview from '@components/VinLookup/EligibleVehicleOverview';
import useLabels from '@hooks/useLabels';
import VinLookupForm from '@components/VinLookup/VinLookupForm';
import { ScrollToTopButton } from '@components-lib/components/ScrollToTop';
import { useVinLookup } from '@api/queries/useVinLookup';

interface VinLookupWrapperProps {
  contentSections: Array<ContentfulComponent>;
}

const VinLookupWrapper = ({ contentSections }: VinLookupWrapperProps) => {
  const labelDictionary = getDictionariesContent(contentSections);
  const labels = useLabels(labelDictionary).getAllPrimaries();
  const assets = getAssets(contentSections)?.map(({ key, asset }) => ({ key, asset } as ServerAsset));

  const [vin, setVin] = useState<string>('');
  const {
    data: response,
    isLoading,
    status,
  } = useVinLookup({
    vin,
  });

  const { vehicleDetails = [], eligiblePackages = [] } = response
    ? response
    : { vehicleDetails: [], eligiblePackages: [] };

  return (
    <Box>
      <VinLookup labels={labels} assets={assets}>
        <VinLookupForm labels={labels} onVinSubmit={setVin} />
      </VinLookup>

      {vin && (
        <EligibleVehicleOverview
          labels={labels}
          assets={assets}
          vehicleDetails={vehicleDetails}
          vinEligiblePackages={eligiblePackages}
          queryStatus={status}
        />
      )}

      <ContentRenderer name={'learn-about-vin-container'} content={contentSections} />

      <ScrollToTopButton />
    </Box>
  );
};

export default VinLookupWrapper;
