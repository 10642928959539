import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { AccountData } from '@api/types/personalData';

export const defaultAccount: AccountData = {
  name: '',
  email: '',
  sxmKey: '',
  primaryPhone: {
    number: '',
    type: '',
    typeShortcut: '( m )',
  },
  secondaryPhone: {
    number: '',
    type: '',
    typeShortcut: '( m )',
  },
  homeAddress: { state: 'CA', unit: '', city: '', zip: '', street: '' },
  mailingAddress: { state: 'CO', unit: '', city: '', zip: '', street: '' },
  homeAddressSameAsMailingAddress: false,
  userName: {
    givenName: '',
    fathersName: '',
    mothersName: '',
  },
  notificationPreferences: {
    email: '',
    phone: '',
    services: [],
  },
  vehicleIds: [],
  _id: '',
  mailingCountry: 'USA',
  tenantId: '',
  isPinConfigured: false,
};

export type UserState = { state: string };
export type Account = AccountData & UserState;

export const useAccountSelector = (): Account =>
  useSelector(({ accountReducer }: RootState) => {
    const account = { ...defaultAccount, ...accountReducer.account?.data };
    return { ...account, state: account.homeAddress.state };
  });
