import React from 'react';
import { ModelConnector } from '@components/ApiConnectorHOCs';

import styles from './Contacts.modules.css';
import useLabels, { Label } from '@hooks/useLabels';

type Vehicle = {
  name: string;
  vin: string;
};

type Service = {
  name: string;
  expiry: string;
};

export type ContactsProps = {
  labels: Label[];
  data: {
    vehicle: Vehicle;
    services: Service[];
  };
};

const Contacts = ({ data, labels }: ContactsProps) => {
  const labelsValue = useLabels(labels).getAll();
  const subscribedServices = data?.services?.map(({ name }) => name).join(', ') || '';

  return (
    <div className={styles.root}>
      <h2>{labelsValue?.title.primary}</h2>
      <div>
        <div className={styles.title}>{labelsValue?.customerCare.primary}</div>
        <span>{labelsValue?.customerCare.secondary}</span>
      </div>
      <div>
        <div className={styles.title}>{labelsValue?.stolenVehicleLocator.primary}</div>
        <span>{labelsValue?.stolenVehicleLocator.secondary}</span>
      </div>
      <div>
        <div className={styles.title}>{labelsValue?.roadsideAssistance?.primary}</div>
        <span>{labelsValue?.roadsideAssistance?.secondary}</span>
      </div>
      <div>
        <div className={styles.title}>{labelsValue?.subscription.primary}</div>
        <span>{subscribedServices ?? labelsValue?.subscription.secondary}</span>
      </div>
      <div>
        <div className={styles.title}>{labelsValue?.vin.primary}</div>
        <span>{data?.vehicle?.vin}</span>
      </div>
    </div>
  );
};

export default ModelConnector(Contacts, { showUiOnError: true });
