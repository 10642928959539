import * as yup from 'yup';
import { nameRegex } from '@utils/nameRegex';

export const formValidation = {
  email: yup
    .string()
    .trim()
    .required('Debe ingresar una dirección de correo electrónico.')
    .email('Ingrese un correo electrónico válido'),
  name: yup
    .string()
    .trim()
    .required('Debe ingresar un nombre.')
    .min(2, 'El nombre debe tener al menos 2 caracteres alfanuméricos.')
    .matches(nameRegex, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
  surname: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Paterno')
    .min(2, 'El apellido paterno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(nameRegex, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
  secondSurname: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Materno')
    .min(2, 'El apellido materno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(nameRegex, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
};
