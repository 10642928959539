import React, { useMemo } from 'react';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SubscribedPackageInfo } from '../Types';
import { Content } from '../../styled-components/globalStyles';
import { NewLine } from './styles';
import { uniqWith, isEqual } from 'lodash';

type Props = {
  packages?: SubscribedPackageInfo[];
  getOfferLegalText?: (type: OfferType, isYearly?: boolean) => string | false;
};

export const DiscountsOfferLegal = ({ packages = [], getOfferLegalText = () => false }: Props): JSX.Element => {
  const offerTypes = useMemo(
    () =>
      uniqWith(
        packages.flatMap((pkg) => {
          const isYearly = pkg.variant?.initialTerm >= 12;
          return pkg.variant?.discounts?.map((discount) => {
            const offerType = discount.offerType;
            return { offerType, isYearly };
          });
        }),
        isEqual,
      )
        .map((offer) => ({ ...offer, legalText: getOfferLegalText(offer?.offerType, offer?.isYearly) }))
        .filter((offer) => offer?.legalText),
    [packages, getOfferLegalText],
  );

  return (
    <>
      {offerTypes?.map((offer) => {
        return (
          <React.Fragment key={offer.offerType}>
            <NewLine />
            <Content title="Additional Legal Text">{offer.legalText}</Content>
          </React.Fragment>
        );
      })}
    </>
  );
};
