import React, { useMemo } from 'react';
import { useApi } from '@api';
import { lowerCase } from 'lodash';

import styles from './DownloadApp.module.css';
import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@app/types/ContentfulComponent';
import HeaderOemBrandLogo, { HeaderOemBrandLogoType } from '@components/Header/HeaderOemBrandLogo';
import { IVehicle } from '@redux/actions';
import { getMobileOperatingSystem } from '@components-lib';

type Props = {
  title: string;
  text: string;
  windowsPhoneError: string;
  iosLinks: ContentfulComponent[];
  androidLinks: ContentfulComponent[];
  backgroundColor?: string;
  oemBrandLogo?: HeaderOemBrandLogoType[];
  vehicleData?: IVehicle;
  treatAsButton?: boolean;
  androidLinkText?: string;
  iosLinkText?: string;
};

const DownloadApp = ({
  backgroundColor,
  oemBrandLogo,
  vehicleData,
  title,
  text,
  windowsPhoneError,
  iosLinks,
  androidLinks,
  treatAsButton = false,
  androidLinkText = '',
  iosLinkText = '',
}: Props): JSX.Element => {
  const api = useApi();
  const { make: carMake } = vehicleData || api.storeService.getVehicleData() || {};
  const os = getMobileOperatingSystem();

  const iosAppLink = useMemo(
    () =>
      iosLinks
        .filter((iosLink) => (iosLink.key as string)?.split(',').map(lowerCase).includes(lowerCase(carMake)))
        .map((link) => ({ ...link, treatAsButton, linkText: iosLinkText })),
    [iosLinks, carMake, treatAsButton, iosLinkText],
  );

  const androidAppLink = useMemo(
    () =>
      androidLinks
        .filter((androidLink) => (androidLink.key as string)?.split(',').map(lowerCase).includes(lowerCase(carMake)))
        .map((link) => ({ ...link, treatAsButton, linkText: androidLinkText })),
    [androidLinks, carMake, treatAsButton, androidLinkText],
  );

  return (
    <div
      className={styles.container}
      style={{
        ...(backgroundColor && { backgroundColor }),
      }}
    >
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{title}</h1>
        {oemBrandLogo && (
          <HeaderOemBrandLogo content={oemBrandLogo} className={styles['brand-logo']} vehicleData={vehicleData} />
        )}
        <p className={styles.body}>{os === 'Windows Phone' ? windowsPhoneError : text}</p>
      </div>
      <div className={styles['buttons-container']}>
        {os !== 'Android' && <ContentRenderer name="iOSLink" content={iosAppLink as ContentfulComponent[]} />}
        {os !== 'iOS' && <ContentRenderer name="androidLink" content={androidAppLink as ContentfulComponent[]} />}
      </div>
    </div>
  );
};

export default DownloadApp;
