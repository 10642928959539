import React, { useState } from 'react';
import { Button } from '@components-lib';
import { parse, format } from 'date-fns';
import { useApi } from '@api';
import styles from './InvoicesList.module.css';

export interface Invoice {
  amount: number;
  invoiceDate: string;
  invoiceId: string;
  error?: boolean;
}

interface Props {
  invoice: Invoice;
  currencySign?: string;
  invoiceLoadError?: string;
}

export function InvoiceItem(props: Props) {
  const {
    invoice: { amount, invoiceDate, invoiceId },
    currencySign,
    invoiceLoadError,
  } = props;
  const [error, setError] = useState<boolean>(false);

  const api = useApi();

  const onDownloadClick = (format: string) => () =>
    api.downloadInvoice(invoiceId, format).catch(() => {
      setError(true);
    });

  return (
    <>
      <div>{format(parse(invoiceDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}</div>
      <div>
        {currencySign}
        {amount}
      </div>
      <div>
        {error ? (
          <span>{invoiceLoadError}</span>
        ) : (
          <div className={styles.actions}>
            <Button onClick={onDownloadClick('pdf')}>PDF</Button>
            <Button onClick={onDownloadClick('xml')}>XML</Button>
          </div>
        )}
      </div>
    </>
  );
}
export default InvoiceItem;
