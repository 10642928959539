import React from 'react';
import { useApi } from '@api';
import Loader from '@components/Loader/';
import ApiRequestPayload from '@customTypes/ApiRequestPayload';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type RemovalConnectorProps = {
  removalConnector: {
    api: string;
    errorLabel?: string;
  };
  onRemove?: (payload: ApiRequestPayload) => any;
};

function RemovalConnector(WrappedComponent: React.ComponentType<RemovalConnectorProps>) {
  return function RemovalConnectorWrapper(props: RemovalConnectorProps) {
    const { removalConnector } = props;
    const api = useApi();
    const queryClient = useQueryClient();
    const { api: apiPath } = removalConnector || {};
    const modelKey = removalConnector?.api?.split('/')[1];

    const services: Record<string, (...args: any) => any> = {
      'DELETE /contacts/': api.deleteContact.bind(api),
      'DELETE /drivers/': api.deleteContact.bind(api),
    };

    const { mutate, isLoading, isError, error } = useMutation<any, unknown, ApiRequestPayload, unknown>({
      mutationFn: async (data: ApiRequestPayload) => {
        if (!services[apiPath]) {
          throw new Error(`${apiPath} don't exist in RemovalConnector`);
        }

        return services[apiPath](data);
      },
    });

    const onRemove = (submittedPayload: ApiRequestPayload) => {
      mutate(submittedPayload, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [modelKey] });
        },
        onError: (e) => {
          console.error(e);
        },
      });
    };

    if (!removalConnector) {
      return <WrappedComponent {...props} />;
    }

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      console.error('RemovalConnector error', removalConnector, error);
      return <div className="error">{removalConnector.errorLabel}</div>;
    }

    return <WrappedComponent onRemove={onRemove} {...props} />;
  };
}

export default RemovalConnector;
