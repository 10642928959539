import React, { useState, useEffect } from 'react';
import OtpForm, { OtpDigitsFormat } from '.';
import { validateCode, sendAuthCode } from '../../services/externalApi';
import { AuthCodeFormContent, ContentfulAssets, DictionaryLabel, SubscriptionProps } from '@manageSubscription';
import SuccessfulMessage from '@lib-components/SuccessfulMessage/SuccessfulMessage';

enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

export type OtpFormConnectorProps = {
  subscriptionProps: SubscriptionProps;
  authCodeFormContent: AuthCodeFormContent;
  isAuthStatusError?: boolean;
  assets?: ContentfulAssets;
  classes?: { form?: string };
  skipAction?: () => void;
  onSuccessValidation?: () => void;
};

const OtpFormConnector = ({
  onSuccessValidation,
  skipAction,
  classes,
  isAuthStatusError,
  authCodeFormContent,
  subscriptionProps,
  assets,
}: OtpFormConnectorProps) => {
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [errorMessage, setErrorMessage] = useState<DictionaryLabel | null>(null);

  useEffect(() => {
    if (isAuthStatusError) {
      setErrorMessage(authCodeFormContent.otpErrorMessage);
      setStatus(Status.Error);
    }
  }, []);

  const handleValidateOTPCode = async (otp: string) => {
    try {
      setStatus(Status.Loading);
      setErrorMessage(null);
      const { data } = (await validateCode(subscriptionProps, otp)) as { data: { statusCode: number } };
      if (data.statusCode === 200) {
        setStatus(Status.Success);
        onSuccessValidation?.();
      } else {
        setErrorMessage(authCodeFormContent.otpErrorMessage);
        setStatus(Status.Error);
      }
    } catch (error) {
      setErrorMessage(authCodeFormContent.otpErrorMessage);
      setStatus(Status.Error);
    }
  };
  const handleSendOTPCode = async () => {
    try {
      setStatus(Status.Loading);
      setErrorMessage(null);
      const { data } = (await sendAuthCode(subscriptionProps)) as { data: { statusCode: number } };
      if (data.statusCode === 200) {
        setStatus(Status.Idle);
      } else {
        setErrorMessage(authCodeFormContent.otpResendErrorMessage);
        setStatus(Status.Error);
      }
    } catch (error) {
      setErrorMessage(authCodeFormContent.otpResendErrorMessage);
      setStatus(Status.Error);
    }
  };

  if (status === Status.Success)
    return <SuccessfulMessage assets={assets} headerText={authCodeFormContent.successfulHeaderText?.primary} />;
  return (
    <OtpForm
      isLoading={status === Status.Loading}
      onSubmit={handleValidateOTPCode}
      labels={authCodeFormContent}
      apiError={status === Status.Error}
      onChange={() => setStatus(Status.Idle)}
      inputDigitsFormat={OtpDigitsFormat.RRS}
      bodyTextSource="RRS-IVA"
      tryAgain={handleSendOTPCode}
      skip={skipAction}
      errorMessage={errorMessage}
      classes={classes}
    />
  );
};
export default OtpFormConnector;
