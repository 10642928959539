const get = (name: string) =>
  document.getElementById(name) || document.getElementsByName(name)[0] || document.getElementsByClassName(name)[0];

const inView = (name: string, threshold = 0) => {
  const elm = get(name);
  if (!elm) {
    // NOSONAR
    console.warn(`element with id/name/class ${name} not found`);
    return false;
  }
  const rect = elm.getBoundingClientRect();
  const vpWidth = window.innerWidth;
  const vpHeight = window.innerHeight;

  const above = rect.bottom - threshold <= 0;
  const below = rect.top - vpHeight + threshold >= 0;
  const left = rect.right - threshold <= 0;
  const right = rect.left - vpWidth + threshold >= 0;

  return !above && !below && !left && !right;
};

export default inView;
