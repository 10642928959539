// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TWAp0I3Uv_BLruipt1lU {
  color: var(--theme-color-header);
  margin: 1rem 0;
}

.TWAp0I3Uv_BLruipt1lU label {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/CreateAccount/CreateAccount.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".form {\n  color: var(--theme-color-header);\n  margin: 1rem 0;\n}\n\n.form label {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TWAp0I3Uv_BLruipt1lU`
};
export default ___CSS_LOADER_EXPORT___;
