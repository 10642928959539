import React from 'react';

import { Button } from '@app/components-lib';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
interface CreatorButtonProps {
  label: string;
  onClick: () => void;
}

const CreatorButton = ({ label, onClick }: CreatorButtonProps) => {
  return (
    <Button
      startIcon={<ControlPointIcon />}
      sx={{
        '&:hover': {
          textDecoration: 'none',
        },
        marginLeft: '35%',
      }}
      variant="text"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CreatorButton;
