import useLabels, { Label } from '@hooks/useLabels';

import { CardView } from '@components/Card';
import React from 'react';
import TermsAndConditions from './TermsAndConditions';
import { partition } from 'lodash';
import styles from './TermsAndConditions.module.css';
import { useHistory } from 'react-router-dom';

type PortalLabelDictionary = {
  componentType: 'portalLabelDictionary';
  content: Label[];
};

type TermsAndConditionsContent = {
  componentType: 'portalAsset';
  asset: {
    file: {
      url: string;
      contentType: string;
    };
  };
};

type TermsAndConditionsPageProps = {
  content: [PortalLabelDictionary, TermsAndConditionsContent];
};

const TermsAndConditionsPage = ({ content }: TermsAndConditionsPageProps) => {
  const history = useHistory();

  const [labels, termsAndConditionsContent] = partition(
    content,
    ({ componentType }) => componentType === 'portalLabelDictionary',
  );
  const combinedLabels = (labels as PortalLabelDictionary[]).reduce(
    (acc, label) => [...acc, ...label.content],
    [] as Label[],
  );
  const dict = useLabels(combinedLabels);
  const handleNext = () => {
    history.push('/accountEnrollment');
  };

  const handlePrev = () => {
    history.push('/vinStatus');
  };

  return (
    <CardView type="main" classes={{ card: styles.card }}>
      <TermsAndConditions
        labelTitle={dict.getPrimary('termsConditionsTitle', 'Terms and conditions')}
        labelTermsConditionsInstructions={dict.getPrimary('termsConditionsInstructions')}
        labelDecline={dict.getPrimary('decline', 'Decline')}
        labelAgreeTerms={dict.getPrimary('agreeTerms', 'Agree terms')}
        labelDeclineConfirmationTitle={dict.getPrimary('declineConfirmationTitle', 'Are you sure?')}
        labelDeclineConfirmationNote={dict.getPrimary('declineConfirmationNote', 'Please note:')}
        labelDeclineConfirmationDescription={dict.getPrimary(
          'declineConfirmationDescription',
          'By declining terms, you will not activate your vehicle identification number.',
        )}
        labelDeclineConfirmation={dict.getPrimary(
          'declineConfirmation',
          'Are you sure you want to decline Terms & Conditions?',
        )}
        labelDeclineYes={dict.getPrimary('declineYes', 'Yes, decline')}
        labelDeclineNo={dict.getPrimary('declineNo', 'No, continue')}
        contentUrl={
          ((termsAndConditionsContent as TermsAndConditionsContent[])[0]?.asset.file.contentType === 'text/html' &&
            (termsAndConditionsContent as TermsAndConditionsContent[])[0]?.asset.file.url) ||
          ''
        }
        onDecline={handlePrev}
        onAccept={handleNext}
      />
    </CardView>
  );
};

export default TermsAndConditionsPage;
