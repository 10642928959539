import React from 'react';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import SubscriptionOverview, { SubscriptionOverviewProps } from './SubscriptionOverviewView';
import SubscriptionModelInfo from './SubscriptionModelInfo';

type SubscriptionOverviewConnectorProps = SubscriptionOverviewProps;

function SubscriptionOverviewConnector({ data, ...restProps }: SubscriptionOverviewConnectorProps) {
  //TODO: this needs to be wholly rewritten with pure view layer and Connector components accepting multiple APIs
  return (
    <SubscriptionOverview
      data={data}
      vehicleInfo={<SubscriptionModelInfo apiConnector={{ api: 'GET /vehicle/' }} />}
      {...restProps}
    />
  );
}

SubscriptionOverviewConnector.defaultProps = {
  apiConnector: {},
};

export default ModelConnector(SubscriptionOverviewConnector);
