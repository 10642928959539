import { SubscriptionProps } from '@manageSubscription/Subscription';
import { options } from '../commonService';
import {
  CreateApplePaySessionPayload,
  CreateApplePaySessionRequest,
  DecryptApplePayTokenPayload,
  DecryptApplePayTokenRequest,
  GetAllPaymentMethodsResponse,
  PayPalAgreementTokenRequest,
  PayPalPaymentMethodRequest,
  RSASignatureRequest,
  UpdatePaymentMethodByIdRequest,
} from '@cv/portal-cps-lib/payment/payment-methods/models';
import {
  createApplePaySession as createSession,
  createPayPalAgreementToken,
  createPayPalPaymentMethod,
  decryptApplePayToken as decryptToken,
  getRSASignature,
  updatePaymentMethodById,
} from '@cv/portal-cps-lib/payment';
import { PaymentInfo, PaymentOptions, PAYPAL_PLAN_TYPE, PAYPAL_REDIRECT_URL } from '../../payment/Types';
import { buildPaymentInfo, buildPaypalPaymentInfo } from '../../payment/builders';
import { listAllPaymentMethods } from './queries';

export const getRsaSignature = (props: SubscriptionProps) => {
  const {
    userDetails: {
      billingAddress: { country, region },
    },
    vehicleDetails: { telematicsProgramId },
  } = props;
  const request: RSASignatureRequest = {
    ...options(props),
    data: { country, region, telematicsProgramId },
  };
  return getRSASignature(request).then((res) => res.data);
};

export const updatePayment = (props: SubscriptionProps, updatedPayment: PaymentInfo) => {
  const {
    userDetails: { userId },
  } = props;
  const {
    paymentMethodId,
    defaultPaymentMethod,
    cardHolderName,
    expirationMonth,
    expirationYear,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    transactionInfo,
    paymentOption,
  } = updatedPayment;
  const request: UpdatePaymentMethodByIdRequest = {
    ...options(props),
    pathParams: { userId, paymentMethodId },
    data: {
      defaultPaymentMethod,
      transactionInfo,
      ...(paymentOption !== PaymentOptions.PAYPAL && {
        creditCardInfo: {
          cardHolderName,
          expirationMonth,
          expirationYear,
          addressLine1,
          addressLine2,
          city,
          state,
          zipCode,
        },
      }),
    },
  };
  return updatePaymentMethodById(request).then((res) => res.data);
};

export const getDefaultPayment = async (props: SubscriptionProps) => {
  const { creditCard: ccPayments, paypal }: GetAllPaymentMethodsResponse = await listAllPaymentMethods(props);

  const defaultCCPayment = ccPayments?.find((p) => p.defaultPaymentMethod);
  if (defaultCCPayment) {
    return buildPaymentInfo(defaultCCPayment);
  }
  const defaultPaypalPayment = paypal?.find((p) => p.defaultPaymentMethod);
  return defaultPaypalPayment ? buildPaypalPaymentInfo(defaultPaypalPayment) : null;
};

export const createApplePaySession = (props: SubscriptionProps, data: Omit<CreateApplePaySessionPayload, 'userId'>) => {
  const {
    userDetails: { userId },
  } = props;
  const request: CreateApplePaySessionRequest = {
    ...options(props),
    data: { ...data, userId },
  };
  return createSession(request).then((res) => res.data);
};

export const decryptApplePayToken = (props: SubscriptionProps, data: Omit<DecryptApplePayTokenPayload, 'userId'>) => {
  const {
    userDetails: { userId },
  } = props;
  const request: DecryptApplePayTokenRequest = {
    ...options(props),
    data: { ...data, userId },
  };
  return decryptToken(request).then((res) => res.data);
};

export const getPayPalAgreementToken = (props: SubscriptionProps) => {
  const request: PayPalAgreementTokenRequest = {
    ...options(props),
    data: {
      userId: props.userDetails.userId,
      payer: {
        payment_method: PaymentOptions.PAYPAL,
      },
      plan: {
        type: PAYPAL_PLAN_TYPE.MERCHANT_INITIATED_BILLING,
        merchant_preferences: {
          return_url: PAYPAL_REDIRECT_URL,
          cancel_url: PAYPAL_REDIRECT_URL,
          accepted_pymt_type: 'INSTANT',
          skip_shipping_address: false,
          immutable_shipping_address: false,
        },
      },
    },
  };
  return createPayPalAgreementToken(request).then((res) => res.data);
};

export const getPayPalPaymentMethod = (props: SubscriptionProps, billingToken: string) => {
  const {
    userDetails: {
      billingAddress: { region },
    },
  } = props;
  const request: PayPalPaymentMethodRequest = {
    ...options(props),
    data: {
      userId: props.userDetails.userId,
      billingToken: billingToken,
      region,
    },
  };
  return createPayPalPaymentMethod(request).then((res) => res.data);
};
