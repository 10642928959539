import ReactPhoneInput, { Props, DefaultInputComponentProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { styled } from '@mui/material';

export const PhoneNumberInput = ReactPhoneInput;

export const TelephoneInput: (props: Props<DefaultInputComponentProps>) => JSX.Element = styled(ReactPhoneInput)`
  border: 1px solid;
  border-color: ${(props) => (props.error ? 'red' : 'rgb(190, 190, 190)')};
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  border-radius: 3px;
  text-indent: 5px;
  width: 100%;
  height: 35px;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;

  &.PhoneInput--focus {
    border-color: black;
    outline: 0;

    input {
      outline: 0;
    }
  }

  input {
    font-family: inherit;
    font-size: 16px;
    border-width: 0px;

    &:focus-visible {
      outline: 0;
    }
  }
`;
