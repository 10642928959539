import React from 'react';
import { LegalContainer } from './LegalContainer';
import { Container } from '../../styled-components/globalStyles';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { useFlowMachineContextContent, useFlowMachineContextFlowStorage } from '../../Router/RouterContext';

export const PackagesLegal = () => {
  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  const {
    packagesWebContent: { trialPackageLegalText, packageLegalText },
  } = useFlowMachineContextContent();

  const canShowLegalText = () => {
    return subscribedPackages.every((pkg) => pkg.packageType === PackageType.Default);
  };

  return (
    <Container>
      {canShowLegalText() && <LegalContainer legalText={trialPackageLegalText} showCheckbox={false} />}
      <LegalContainer legalText={packageLegalText} showCheckbox={false} />
    </Container>
  );
};
