import React from 'react';
import urlSearchParams from '@utils/urlSearchParams';

export type FlowCarouselProps = {
  carouselFlows?: string[];
  children: React.ReactNode;
};

const FlowCarousel = ({ carouselFlows, children }: FlowCarouselProps) => {
  const currentFlow = urlSearchParams.get<string>('flowName');
  const carouselForCurrentFlow =
    typeof carouselFlows === 'undefined' || carouselFlows.includes(currentFlow) || carouselFlows.includes('all');

  return <>{carouselForCurrentFlow && children}</>;
};

export default FlowCarousel;
