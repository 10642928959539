import { PREFERENCES_ACTIONS, Preferences } from '../actions';

type PreferencesState = {
  preferences: Preferences;
};

type PreferencesAction = {
  type: PREFERENCES_ACTIONS;
  data: Preferences;
};

export default (state: PreferencesState = { preferences: {} }, action: PreferencesAction) => {
  switch (action.type) {
    case PREFERENCES_ACTIONS.SET_PREFERENCES:
      return {
        ...state,
        preferences: action.data,
      };
    default:
      return state;
  }
};
