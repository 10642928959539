import { CreateLocationTracker, GetLocationTrackerByVehicleId } from '@cv/portal-rts-lib/location-tracker';
import { GetLocationTracker, GetLocationTrackerResponse } from '@cv/portal-rts-lib/location-tracker/models';

import { Environment } from '@cv/portal-common-lib/ajax/constants';

export default class VehicleContactsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getVehicleLocation(
    accessToken: string,
    vehicleId: string,
    idToken: string,
    svcReqId?: string,
  ): Promise<GetLocationTrackerResponse> {
    const request: GetLocationTracker = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      pathParams: {
        vehicleId,
      },
      ...(svcReqId && { queryParams: { svcReqId } }),
    };

    return await GetLocationTrackerByVehicleId(request)
      .then((res) => res.data)
      .catch((err) => err);
  }

  async getServiceRequestId(accessToken: string, vehicleId: string, idToken: string) {
    const request: GetLocationTracker = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      pathParams: {
        vehicleId,
      },
      data: {},
    };
    const response = (await CreateLocationTracker(request)) as {
      data: GetLocationTrackerResponse;
    };

    return response.data || {};
  }
}
