// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EH34HVWF5X9EiY0zRX1w {
  border-bottom: 1px solid var(--theme-color-table-head);
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.s_f6E3a7yZSH2dijz8DW {
  height: 400px;
  overflow: auto;
}

.s_f6E3a7yZSH2dijz8DW h1 {
  color: var(--theme-color-header);
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.Al4r_qeRUxnTAnAS5kUp {
  color: var(--theme-color-header);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Al4r_qeRUxnTAnAS5kUp h6 {
  font-size: 1em;
  margin: 0;
}

.Al4r_qeRUxnTAnAS5kUp p {
  margin: 0;
}

.Al4r_qeRUxnTAnAS5kUp h6 {
  font-weight: bold;
}

.GU2fN0Adi1jP5glALLnH {
  display: flex;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.GU2fN0Adi1jP5glALLnH button {
  min-width: 150px;
}

.Zj1qXQjyrfj3D3GU6dmj {
  max-width: 100%;
  color: var(--theme-color-text);
  margin: 0 auto;
  margin-top: 3rem;
}

.Zj1qXQjyrfj3D3GU6dmj .UYo01EPvDlembm33xaVc {
  text-align: center;
}

@media (min-width: 1200px) {
  .Zj1qXQjyrfj3D3GU6dmj {
    max-width: 58.33%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsAndConditions/TermsAndConditions.module.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;EACtD,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".body {\n  border-bottom: 1px solid var(--theme-color-table-head);\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  padding-bottom: 1rem;\n}\n\n.content {\n  height: 400px;\n  overflow: auto;\n}\n\n.content h1 {\n  color: var(--theme-color-header);\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n.instructions {\n  color: var(--theme-color-header);\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.instructions h6 {\n  font-size: 1em;\n  margin: 0;\n}\n\n.instructions p {\n  margin: 0;\n}\n\n.instructions h6 {\n  font-weight: bold;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  column-gap: 1rem;\n}\n\n.buttons button {\n  min-width: 150px;\n}\n\n.card {\n  max-width: 100%;\n  color: var(--theme-color-text);\n  margin: 0 auto;\n  margin-top: 3rem;\n}\n\n.card .title {\n  text-align: center;\n}\n\n@media (--viewport-l) {\n  .card {\n    max-width: 58.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `EH34HVWF5X9EiY0zRX1w`,
	"content": `s_f6E3a7yZSH2dijz8DW`,
	"instructions": `Al4r_qeRUxnTAnAS5kUp`,
	"buttons": `GU2fN0Adi1jP5glALLnH`,
	"card": `Zj1qXQjyrfj3D3GU6dmj`,
	"title": `UYo01EPvDlembm33xaVc`
};
export default ___CSS_LOADER_EXPORT___;
