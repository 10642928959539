import { makeStyles } from '@mui/styles';

export const useHeadlineStyles = () => {
  const useStyles = makeStyles(() => ({
    headline: {
      fontFamily: 'Helvetica Now Display Black',
      fontWeight: 900,
      fontSize: '2.125rem',
      marginTop: '2rem',
      marginBottom: '1rem',
      textAlign: 'center',
    },
  }));
  return useStyles();
};

export const useTextStyles = (overrideStyles = {}) => {
  const useStyles = makeStyles(() => ({
    text: {
      marginBottom: '1.5rem',
      fontSize: '1.0625rem',
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#686868',
      textAlign: 'center',
      ...overrideStyles,
    },
  }));
  return useStyles();
};

export const useTextFieldStyles = () => {
  const useStyles = makeStyles(() => ({
    field: {
      fontFamily: 'Helvetica Now Text',
      fontSize: '1.0625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      marginBottom: '1.5rem',
      label: {
        marginLeft: '1rem',
      },
      div: {
        paddingLeft: '1rem',
      },
    },
  }));
  return useStyles();
};

export const useValidationRulesStyles = () => {
  const text = {
    fontFamily: 'Helvetica Now Display',
    fontSize: '0.8rem',
    fontStyle: 'normal',
    fontWeight: 400,
  };

  const useStyles = makeStyles(() => ({
    header: { ...text, paddingLeft: '1rem' },
    list: { listStyle: 'none', marginBottom: '2rem', paddingLeft: '1rem' },
    rule: {
      ...text,
      marginLeft: '0.7rem',
    },
  }));
  return useStyles();
};

export const useActionButtonStyles = (overrideStyles = {}) => {
  const useStyles = makeStyles(() => ({
    actionButton: {
      fontFamily: 'Helvetica Now Display',
      fontSize: '1.0625rem',
      fontStyle: 'normal',
      fontWeight: 700,
      borderRadius: '2.4rem',
      padding: '0.5rem',
      '&:disabled': {
        backgroundColor: '#d1d1d1',
        borderColor: '#d1d1d1',
        color: 'white',
        opacity: 1,
      },
      ...overrideStyles,
    },
  }));
  return useStyles();
};
