import React from 'react';

import styles from './TermsAndConditions.module.css';

type TermsAndConditionsDeclinedProps = {
  labelTitle: string;
  labelText: string;
  labelDealerInstructions: string;
  labelCloseWindow: string;
};

const TermsAndConditionsDeclined = ({
  labelTitle,
  labelText,
  labelDealerInstructions,
  labelCloseWindow,
}: TermsAndConditionsDeclinedProps) => {
  return (
    <div className={styles.container}>
      <h1>{labelTitle}</h1>
      <div className={styles.body}>{labelText}</div>
      <div className={styles.instructions}>
        <h6>{labelDealerInstructions}</h6>
        <p>{labelCloseWindow}</p>
      </div>
    </div>
  );
};

export default TermsAndConditionsDeclined;
