import React from 'react';
import { SubscriptionProps, WebContent } from '..';
import Router from '../../Router';

export interface ManageSubscriptionProps {
  content: WebContent;
  subscriptionProps: SubscriptionProps;
}

const Subscription: React.FC<ManageSubscriptionProps> = ({ content, subscriptionProps }) => {
  return <Router content={content} subscriptionProps={subscriptionProps} />;
};

export default Subscription;
