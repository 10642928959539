import { STOLEN_VEHICLE_STATUS } from '@redux/actions/stolen-vehicle';

type SvlActions = {
  type: STOLEN_VEHICLE_STATUS;
  data: any;
};

type StolenVehicleStatus = {
  svl: object;
};

const initState: StolenVehicleStatus = {
  svl: {},
};

export default (state: StolenVehicleStatus = initState, action: SvlActions) => {
  switch (action.type) {
    case STOLEN_VEHICLE_STATUS.STOLEN_STATUS:
      return {
        ...state,
        svl: action.data,
      };
    default:
      return state;
  }
};
