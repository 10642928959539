// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandingList-grid {
  display: grid;
  grid-template-rows: auto;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ExpandingList/ExpandingList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,uBAAkB;OAAlB,kBAAkB;EAClB,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".ExpandingList-grid {\n  display: grid;\n  grid-template-rows: auto;\n  column-gap: 0.5rem;\n  row-gap: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
