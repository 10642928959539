import React from 'react';
import { EligiblePackageInfo, PackageSubscription } from '../../../Types';
import { PromoPackage, DiscountProps } from './PromoPackage';
import { PackagesContainer } from '../styles';

export type PromoPackagesProps = {
  promoPackages: EligiblePackageInfo[];
  maxSelectedPackages?: number;
  packageSubscriptions: PackageSubscription[];
  onPromoSelection: (promoPackage: EligiblePackageInfo) => void;
} & Omit<DiscountProps, 'promoPackage'>;

const PromoPackages = ({ promoPackages, maxSelectedPackages, packageSubscriptions, ...rest }: PromoPackagesProps) => {
  const disabled = packageSubscriptions.length === maxSelectedPackages;

  return (
    <PackagesContainer>
      {promoPackages.map((ePkg) => (
        <PromoPackage
          key={ePkg.variant.id}
          promoPackage={ePkg}
          packageSubscriptions={packageSubscriptions}
          disabled={disabled}
          {...rest}
        />
      ))}
    </PackagesContainer>
  );
};

export default PromoPackages;
