import { ComponentRoutes } from '@lib-appRouter/types';
import UserProfileContainerFca from '@manageSubscription/UserProfile/FCA';
import { userProfileFlow as commonUserProfileFlow } from '@lib-appRouter/flows/componentFlow/common/UserProfile';

export const FcaComponentFlowMap = {
  [ComponentRoutes.userProfile]: {
    component: UserProfileContainerFca,
    flow: commonUserProfileFlow,
  },
};
