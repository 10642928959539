import React from 'react';
import { convertDuration } from '@manageSubscription';
import { useFlowMachineContextContent } from '@lib-appRouter/RouterContext';

interface TrialPackageTermProps {
  startDate: string;
  endDate: string;
  isAddTrialLabel?: boolean;
  isAddSubscriptionLabel?: boolean;
}

const TrialPackageTerm = ({
  startDate,
  endDate,
  isAddTrialLabel = true,
  isAddSubscriptionLabel = false,
}: TrialPackageTermProps) => {
  const {
    trialPackagesWebContent: { trialLabel },
    packagesWebContent: {
      subscribedPackagesWebContent: { subscriptionLabel },
    },
    commonWebContent: { yearsLabel, monthsLabel, daysLabel },
  } = useFlowMachineContextContent();

  const trialTermLabel = () => {
    const term = convertDuration(startDate, endDate, { years: yearsLabel, months: monthsLabel, days: daysLabel });
    return `${term}${isAddTrialLabel ? ` ${trialLabel}` : ''}${isAddSubscriptionLabel ? ` ${subscriptionLabel}` : ''}`;
  };

  return <React.Fragment>{trialTermLabel()}</React.Fragment>;
};

export default TrialPackageTerm;
