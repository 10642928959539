import React, { useMemo } from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import { FormikInputField } from '@components/FormikFields';
import { Button } from '@components-lib';
import Widget from '@components/Widget/Widget';

import styles from '@components/ResetPassword/ResetPassword.module.css';

interface UpdatePinForm {
  [index: string]: string;
}

type UpdatePasswordVerificationProps = {
  isDisabled: boolean;
  onValidation: (payload: any, setFieldError: (fieldName: string, message: string) => void) => void;
  labels: Record<string, string>;
};

const UpdatePasswordVerification = ({ isDisabled, onValidation, labels }: UpdatePasswordVerificationProps) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        surname: yup
          .string()
          .trim()
          .required(labels.fathersLastNameRequired)
          .min(2, labels.fathersLastNameMin)
          .matches(/^([a-zA-Z\- '0-9]*)$/, labels.nameMatch),
        surname2: yup
          .string()
          .trim()
          .required(labels.mothersLastNameRequired)
          .min(2, labels.mothersLastNameMin)
          .matches(/^([a-zA-Z\- '0-9]*)$/, labels.nameMatch),
      }),
    [labels],
  );

  const initialValues = {
    surname: '',
    surname2: '',
  };

  const onSubmit = (
    values: UpdatePinForm,
    setFieldError: (fieldName: string, message: string) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    onValidation(values, setFieldError);
    setSubmitting(false);
  };

  return (
    <Widget title={labels.verificationTitle} titleAlignment="center">
      <p className={styles['forget-password-description']}>{labels.verificationDescription}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          onSubmit(values, setFieldError, setSubmitting);
        }}
      >
        {(props: FormikProps<UpdatePinForm>) => (
          <Form onSubmit={props.handleSubmit}>
            <div className={styles['reset-field']}>
              <FormikInputField name="surname" label={labels.fathersLastName} disabled={isDisabled} />
            </div>
            <div className={styles['reset-field']}>
              <FormikInputField name="surname2" label={labels.mothersLastName} disabled={isDisabled} />
            </div>
            <div className={styles['actions']}>
              <Button
                variant="contained"
                className={styles['button']}
                disabled={props.isSubmitting || isDisabled}
                onClick={props.submitForm}
                type="submit"
              >
                {labels.continue}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Widget>
  );
};

export default UpdatePasswordVerification;
