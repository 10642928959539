import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { listAddresses, getFormattedAddress } from '@cv/portal-cps-lib';

export default class SearchAddressService {
  private environment: Environment;

  constructor(environment = Environment.SIT) {
    this.environment = environment;
  }

  async listAddresses(data: { accessToken: string; isoCountryCode: string; query: string }) {
    const { accessToken, isoCountryCode, query } = data;
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
      data: { isoCountryCode, query },
    };
    return listAddresses(request);
  }

  async getFormattedAddress(data: { accessToken: string; id: string; isoCountryCode: string }) {
    const { accessToken, isoCountryCode, id } = data;
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
      queryParams: { isoCountryCode, id },
    };
    return getFormattedAddress(request);
  }
}
