import React from 'react';
import { ReactReduxContext } from 'react-redux';

import Api from './Api';
import { StoreService } from './services';

export { MockService } from './services';
export * from './types';

type ApiProviderProps = {
  api?: Api;
  children?: React.ReactNode;
};

const ApiContext = React.createContext<Api | null>(null);

export const useApi = () => {
  const api = React.useContext(ApiContext);

  if (api === null) {
    throw new Error('useApi hook must be used within ApiProvider');
  }

  return api;
};

export const ApiProvider = ({ api, children }: ApiProviderProps) => {
  const { store } = React.useContext(ReactReduxContext);
  let _api = api;
  if (!_api) {
    const storeService = new StoreService(store);
    _api = new Api(storeService);
  }

  return <ApiContext.Provider value={_api}>{children}</ApiContext.Provider>;
};

export { Api };
