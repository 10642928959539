import React, { useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Stack, Box, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Button from '@components-lib/components/Button';
import resetPassword from '@api/requests/msafety/passwordFlow/resetPassword';
import config from '@config/config';
import getClientId from './getClientId';
import { extractPrimaries } from '@utils/labels';
import urlSearchParams from '@utils/urlSearchParams';
import { useHeadlineStyles, useTextStyles, useTextFieldStyles, useActionButtonStyles } from './styles';
import {
  CommonFormAnalyticsWrapper,
  sendAnalyticsEvent,
  EventType,
  EventDataBuilder,
} from '@app/components-lib/components/Analytics';

const analyticsFormName = 'msc password flow send email form';

const SendEmail = ({ labels }) => {
  const {
    title,
    description,
    label,
    sendButtonText,
    emailRequiredMessage,
    emailInvalidMessage,
    nonExistentCustomerMessage,
    emailRegex,
    nextPage,
  } = extractPrimaries(labels);

  const headline = useHeadlineStyles();
  const textStyles = useTextStyles();
  const textField = useTextFieldStyles();
  const button = useActionButtonStyles();

  const history = useHistory();
  const [nonExistentEmails, setNonExistentEmails] = useState<string[]>([]);
  const [status, setStatus] = useState<'idle' | 'pending' | 'rejected'>('idle');

  const handleSubmit = async ({ email }: { email: string }, { validateForm, setSubmitting }) => {
    setStatus('pending');
    const params = new URLSearchParams(window.location.search);
    const platform = params.get('platform') || '';
    const clientId = getClientId(platform);
    const tenantId = config.getOemName().toLowerCase();

    try {
      const result = await resetPassword({ clientId, tenantId, userIdentifier: email });
      if (result.ok) {
        urlSearchParams.setMultiple({
          platform,
          email,
        });
        sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: analyticsFormName }));
        history.push(`/${nextPage}?hideNavigation=true`);
      } else {
        const error = await result.json();
        sendAnalyticsEvent(
          new EventDataBuilder(EventType.FormFailedEvent).withArgs({
            name: analyticsFormName,
            error: error.message,
          }),
        );
        throw new Error();
      }
    } catch (_) {
      setNonExistentEmails((prevNonExistentEmails) => [...prevNonExistentEmails, email]);
      setStatus('rejected');
    } finally {
      setSubmitting(false);
      validateForm();
    }
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(emailRequiredMessage)
      .matches(new RegExp(emailRegex), emailInvalidMessage)
      .test(
        'do not let customer proceed if they do not have account',
        nonExistentCustomerMessage,
        (value = '') => !nonExistentEmails.includes(value),
      ),
  });

  return (
    <CommonFormAnalyticsWrapper name={analyticsFormName}>
      <Stack p="1rem" sx={{ height: '100vh' }}>
        <Box textAlign="center">
          <Typography variant="h1" component="h1" className={headline.headline}>
            {title}
          </Typography>
          <Typography component="p" className={textStyles.text}>
            {description}
          </Typography>
        </Box>
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(props) => (
            <Form style={{ width: '100%', height: '100%' }}>
              <Stack justifyContent="space-between" width={1} height={1}>
                <Field name="email">
                  {({ field, meta }) => {
                    const isError = !!meta.error && field.value !== meta.initialValue;
                    return (
                      <TextField
                        label={label}
                        variant="standard"
                        className={textField.field}
                        sx={{
                          label: {
                            marginLeft: '1rem',
                          },
                          div: {
                            paddingLeft: '1rem',
                          },
                        }}
                        error={isError}
                        helperText={meta.error}
                        InputProps={{
                          endAdornment: isError ? (
                            <InputAdornment position="end">
                              <InfoRoundedIcon sx={(theme) => ({ color: theme.palette.error.light })} />
                            </InputAdornment>
                          ) : (
                            field.value !== meta.initialValue && (
                              <InputAdornment position="end">
                                <IconButton onClick={() => props.resetForm()} title="Clear">
                                  <HighlightOffRoundedIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          ),
                        }}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Button
                  type="submit"
                  className={button.actionButton}
                  disabled={!props.isValid || !props.dirty || props.isSubmitting}
                >
                  {status === 'pending' ? <CircularProgress color="secondary" size={30} /> : sendButtonText}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </CommonFormAnalyticsWrapper>
  );
};

export default SendEmail;
