import React, { useEffect } from 'react';
import { useApplePay } from '../../../ApplePay';
import { buildApplePayRequestInfo } from '../../../builders';
import { usePaymentContext } from '../PaymentContext';
import { PaymentOptions } from '../../../Types';
import PaymentButton from '../PaymentButton';
import { useUpdatePayment as useUpdatePaymentQuery } from '@lib-services/cps/queries';

type ApplePayPaymentProps = {
  cancelEdit?: () => void;
};

const ApplePayPayment = ({ cancelEdit }: ApplePayPaymentProps) => {
  const { submitCallback, subscriptionInfo = { totalAmount: '0.0' } } = usePaymentContext();
  const {
    content: { applePayDisplayName },
    subscriptionProps,
  } = usePaymentContext();

  const [createPayment, paymentMethodId] = useApplePay(
    {
      displayName: applePayDisplayName,
      request: buildApplePayRequestInfo(subscriptionProps, subscriptionInfo),
      subscriptionInfo,
    },
    subscriptionProps,
  );

  const { mutate } = useUpdatePaymentQuery(subscriptionProps);

  useEffect(() => {
    if (paymentMethodId) {
      mutate(
        {
          paymentMethodId,
          defaultPaymentMethod: true,
        },
        {
          onSuccess: (response) => {
            submitCallback?.(response);
            cancelEdit?.();
          },
        },
      );
    }
  }, [paymentMethodId]);

  return <PaymentButton paymentMethod={PaymentOptions.APPLE_PAY} onContinue={createPayment} onCancel={cancelEdit} />;
};
export default ApplePayPayment;
