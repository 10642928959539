import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';

import clsx from 'clsx';

import styles from './Tooltip.module.css';

const TooltipContainer = forwardRef(
  ({ x, y, arrowX, arrowY, placement, children, className, classes = {}, arrow = false, ...rest }: any, ref) => {
    const [direction, position] = placement ? placement.split('-') : 'top-center';

    return ReactDOM.createPortal(
      <div
        ref={ref}
        className={clsx(styles['Tooltip-container'], className, classes.outerContainer)}
        style={{ transform: `translate(${x}px, ${y}px)` }}
        {...rest}
      >
        <div className={clsx(styles['Tooltip-inner-container'], classes.innerContainer)}>
          {children}
          {arrow && (
            <span
              style={!!arrowY ? { left: arrowY } : { top: arrowX }}
              className={clsx(styles.Arrow, classes.arrow)}
              arrow-direction={direction}
              arrow-position={position}
            ></span>
          )}
        </div>
      </div>,
      window.document.body,
    );
  },
);

export default TooltipContainer;
