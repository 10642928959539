// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qXPQf2nRsqxzZTMxGpiS .JfbF3B049fEtNH8fUzFW {
  display: none;
}

.qXPQf2nRsqxzZTMxGpiS .h86CkFkpNkTV2rHm_1cW {
  display: block;
}

@media (min-width: 600px) {
  .qXPQf2nRsqxzZTMxGpiS .h86CkFkpNkTV2rHm_1cW {
    display: none;
  }

  .qXPQf2nRsqxzZTMxGpiS .JfbF3B049fEtNH8fUzFW {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/ResponsiveLogo/ResponsiveLogo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".responsive-logo .desktop-logo {\n  display: none;\n}\n\n.responsive-logo .mobile-logo {\n  display: block;\n}\n\n@media (--viewport-xs) {\n  .responsive-logo .mobile-logo {\n    display: none;\n  }\n\n  .responsive-logo .desktop-logo {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsive-logo": `qXPQf2nRsqxzZTMxGpiS`,
	"desktop-logo": `JfbF3B049fEtNH8fUzFW`,
	"mobile-logo": `h86CkFkpNkTV2rHm_1cW`
};
export default ___CSS_LOADER_EXPORT___;
