import styled, { css } from 'styled-components';

interface ImageWrapperProps {
  maxWidth?: string;
  inline?: boolean;
}
export const ImageWrapper = styled('div')`
  display: ${({ inline }: ImageWrapperProps) => (inline ? 'inline-' : '')}flex;

  ${(props: ImageWrapperProps) =>
    props['maxWidth']
      ? css`
          max-width: ${props['maxWidth']};
          img {
            max-width: 100%;
          }
        `
      : 'max-width: initial'};
  img {
    width: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;
