// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t2YodgiWtLlD5nAkkRyx {
  text-align: center;
}

.kztJKoQ5BHO9Eisk_ASt {
  align-items: center;
  flex: 1;
  margin-bottom: 2rem;
}

.pJWDsVxgtebBt5E1YbuV {
  color: var(--theme-color-grey-7a);
  margin-bottom: 1rem;
}

.KaR_GX5RIKYGofX40rkW {
  font-size: 0.75rem;
}

.pulyu7ALmYL77phKh08w {
  margin-top: 0.75rem;
}

.sYmoZBBDIiAjIltkS0ly {
  margin: 0 auto;
  min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/VinSearch/VinSearch.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".form {\n  text-align: center;\n}\n\n.input-wrapper {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n.input-label {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n.input-field {\n  font-size: 0.75rem;\n}\n\n.input-error {\n  margin-top: 0.75rem;\n}\n\n.submit {\n  margin: 0 auto;\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `t2YodgiWtLlD5nAkkRyx`,
	"input-wrapper": `kztJKoQ5BHO9Eisk_ASt`,
	"input-label": `pJWDsVxgtebBt5E1YbuV`,
	"input-field": `KaR_GX5RIKYGofX40rkW`,
	"input-error": `pulyu7ALmYL77phKh08w`,
	"submit": `sYmoZBBDIiAjIltkS0ly`
};
export default ___CSS_LOADER_EXPORT___;
