import { merge } from 'lodash';
import { AnyEventObject, assign, createMachine, send, sendParent } from 'xstate';
import { choose } from 'xstate/lib/actions';
import { SubscriptionProps } from '@manageSubscription';
import fetchPackages from '@manageSubscription/Subscription/fetchPackages';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { configs } from '../configs';

interface fetchPackagesMachineContext {
  subscriptionProps: SubscriptionProps;
  previousLocale: string;
}

export const fetchPackagesMachine = createMachine<fetchPackagesMachineContext>({
  id: 'fetchPackagesMachine',
  predictableActionArguments: true,
  initial: 'idle',
  context: { subscriptionProps: {} as SubscriptionProps, previousLocale: '' },
  states: {
    active: {
      invoke: {
        id: 'fetchPackagesCall',
        src: async (context) => {
          const fetchPackagesData = await fetchPackages(context.subscriptionProps);
          return { fetchPackagesData };
        },
        onDone: {
          actions: [
            sendParent((_, event) => ({
              type: 'FETCH_PACKAGES_GLOBAL',
              data: event.data,
            })),
            sendParent(() => ({ type: 'onFetchPackages' })),
          ],
          target: 'idle',
        },
        onError: {
          actions: [(_, e) => console.error(e), sendParent({ type: 'onFetchFailed' })],
          target: 'idle',
        },
      },
    },
    idle: {
      on: {
        active: 'active',
        idle: 'idle',
      },
    },
  },
  on: {
    updateSubscriptionProps: {
      actions: [
        assign((context, event) => ({
          previousLocale: context.subscriptionProps?.locale,
          subscriptionProps: merge({}, context.subscriptionProps, event.data),
        })),
        choose<fetchPackagesMachineContext, AnyEventObject>([
          {
            cond: (context) =>
              context.subscriptionProps.locale !== context.previousLocale && !configs.skipInitFetchPackages,
            actions: [send('active')],
          },
          {
            actions: send('idle'),
          },
        ]),
        sendParent({ type: 'onFetchContextUpdated' }),
      ],
    },
    fetchPackages: 'active',
  },
});

export const FetchMachineEvent = {
  updateSubscriptionProps: () =>
    send((_, event) => ({ type: 'updateSubscriptionProps', data: (event as any).data }), {
      to: (context: AggregatedFlowContext) => context.fetchPackagesMachineRef,
    }),
  fetchPackages: () =>
    send({ type: 'fetchPackages' }, { to: (context: AggregatedFlowContext) => context.fetchPackagesMachineRef }),
};
