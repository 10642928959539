import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import useMarketingPreferences from '@api/queries/useMarketingPreferences';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import Field from '@components/Field';
import InputCheckbox from '@components/InputCheckbox';
import styles from './OptSms.module.css';

export type OptSmsProps = {
  topLabel?: string;
  name: string;
  isSubmitting: boolean;
};

const OptSms = ({ name, topLabel, isSubmitting }: OptSmsProps) => {
  const { data, createPreference, updatePreference } = useMarketingPreferences({
    keyName: 'smsNumber',
  });

  const isEmpty = data === undefined;
  const currentOptIn = !!data?.preferences?.some(({ optIn }) => !!optIn);

  const [, { initialValue }, { setValue: setFormValue }] = useField<boolean | null | undefined>(name);
  const [checked, setChecked] = useState<boolean | undefined>(undefined);

  const onChange = () => {
    if (typeof checked !== 'undefined') {
      const newValue = !checked;

      setFormValue(newValue === currentOptIn ? initialValue : newValue);
      setChecked(newValue);
    } else {
      setFormValue(!currentOptIn);
      setChecked(!currentOptIn);
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      if (isEmpty) {
        createPreference(Boolean(checked));
      } else if (currentOptIn !== checked) {
        updatePreference(Boolean(checked));
      }
    }
  }, [isSubmitting, isEmpty, currentOptIn, checked, updatePreference, createPreference]);

  const rightLabel = get('smsCheckboxRightLabel') || '';

  return (
    <Field label={topLabel}>
      <InputCheckbox
        className={styles['opt-sms-checkbox-input']}
        name={name}
        checked={typeof checked === 'undefined' ? currentOptIn : checked}
        onChange={onChange}
        placeholder={rightLabel}
      />
    </Field>
  );
};

export default OptSms;
