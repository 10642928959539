import config from '@config/config';
import { getCookie } from '@utils/cookies';
import { getLanguage } from '@utils/getLanguage';
import { parseSearchParams } from '@utils/urlSearchParams';
import { SETTINGS_ACTIONS } from '../actions/';

type SettingsState = {
  locale: string;
  isLocaleNonDefault: boolean;
  isLangModalSuppressed: boolean;
  contentfulLocale: string;
  isAuthCodeVerified: boolean;
};

type SettingsAction = {
  type: SETTINGS_ACTIONS;
  data: string | boolean;
};

const initState = (): SettingsState => {
  const params = new URLSearchParams(window.location.search);
  const langParam = params.get('lang');
  const cookiesLang = getCookie('l');
  const lang = langParam || cookiesLang;
  return {
    locale: getLanguage(lang) as string,
    isLocaleNonDefault: false,
    isLangModalSuppressed: false,
    contentfulLocale: getLanguage(lang) as string,
    isAuthCodeVerified: false,
  };
};

const settingsReducer = (state: SettingsState = initState(), action: SettingsAction) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.SET_LOCALE:
      return {
        ...state,
        locale: action.data,
      };
    case SETTINGS_ACTIONS.SET_IS_LOCALE_UPDATED_BY_USER:
      return {
        ...state,
        isLocaleNonDefault: action.data,
      };
    case SETTINGS_ACTIONS.TOGGLE_SUPPRESS_LANG_MODAL:
      return {
        ...state,
        isLangModalSuppressed: !state.isLangModalSuppressed,
      };
    // We need to have this for library. So when we change locale - it changes only locally and we need to wait some time
    // before content will be fetched from contentful. This is why this second state was added.
    case SETTINGS_ACTIONS.SET_CONTENTFUL_LOCALE:
      return {
        ...state,
        contentfulLocale: action.data,
      };
    case SETTINGS_ACTIONS.SET_SUCCESS_AUTH_STATUS:
      return {
        ...state,
        isAuthCodeVerified: action.data,
      };
    default:
      return state;
  }
};

export default settingsReducer;
