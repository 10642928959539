import React, { useState } from 'react';
import Button, { ButtonProps } from '../../../../components/Button';
import { Legal } from '@manageSubscription/Legal';
import { SubscriptionProps } from '@manageSubscription';
import { Stack } from '@lib-components/Stack';

export interface PaymentButtonProps extends ButtonProps {
  onContinue?: () => void;
  onCancel?: () => void;
}

export interface PaymentButtonLocalProps extends PaymentButtonProps {
  subscriptionProps: SubscriptionProps;
  buttonText: string;
  cancelText: string;
  legalText?: string;
  additionalLegalText?: string;
}

// when terms exist, start at false, to require agreement with them,
// otherwise start the state at true, to skip the check
const useTermsAgreed = (text?: string) => useState(text === undefined);

const PaymentButton: React.FC<PaymentButtonLocalProps> = ({
  onContinue,
  onCancel,
  buttonText,
  subscriptionProps,
  legalText,
  cancelText,
  additionalLegalText,
  ...rest
}) => {
  const [termsAgreed, setTermsAgreed] = useTermsAgreed(legalText);
  const [additionalTermsAgreed, setAdditionalTermsAgreed] = useTermsAgreed(additionalLegalText);

  return (
    <>
      {legalText && (
        <Legal
          legalText={legalText}
          setTermsAgreed={setTermsAgreed}
          termsAgreed={termsAgreed}
          subscriptionProps={subscriptionProps}
        />
      )}
      {additionalLegalText && (
        <Legal
          legalText={additionalLegalText}
          setTermsAgreed={setAdditionalTermsAgreed}
          termsAgreed={additionalTermsAgreed}
          subscriptionProps={subscriptionProps}
        />
      )}
      <Stack spacing={2} direction="row" sx={{ mt: 3, mb: 2 }} justifyContent="center">
        {onCancel && (
          <Button {...rest} variant="outlined" color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button {...rest} disabled={!termsAgreed || !additionalTermsAgreed} onClick={onContinue}>
          {buttonText}
        </Button>
      </Stack>
    </>
  );
};

export default PaymentButton;
