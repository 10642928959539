import { styled } from '@mui/material';
import { Content, Heading, Title as BaseTitle } from '../../../styled-components/globalStyles';

export const PackageHeader = styled(Heading)`
  margin-top: 1rem;
`;

export const PackageInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const DateContent = styled(Content)`
  &:before {
    content: '|';
    margin: 0 0.2rem;
  }
`;

export const Title = styled(BaseTitle)`
  ${({ isCancel }: { isCancel?: boolean }) => isCancel && `text-decoration: line-through;`}
`;
