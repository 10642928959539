// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CQlIYNlh22c7xvfzkd9i {
  background: #eee;
}

.sCu1N2gzY38I2lJv7jxu {
  font-size: 16px;
}

.vTpg0xHRPWs9ge7PMyD8 h2 {
  font-size: 1.5rem;
}

.vTpg0xHRPWs9ge7PMyD8 > *:not(:first-child) {
  margin-top: 25px;
}

.vTpg0xHRPWs9ge7PMyD8 p {
  margin: 0;
  font-size: 12px;
  line-height: 1.42857143;
  letter-spacing: 0.14px;
}

.vTpg0xHRPWs9ge7PMyD8 p:not(:last-child) {
  margin-bottom: 25px;
}

.vTpg0xHRPWs9ge7PMyD8 ul,
.vTpg0xHRPWs9ge7PMyD8 ol {
  padding-left: 1rem;
  padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Faq/Faq.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[".accordion {\n  background: #eee;\n}\n\n.accordion-title {\n  font-size: 16px;\n}\n\n.root h2 {\n  font-size: 1.5rem;\n}\n\n.root > *:not(:first-child) {\n  margin-top: 25px;\n}\n\n.root p {\n  margin: 0;\n  font-size: 12px;\n  line-height: 1.42857143;\n  letter-spacing: 0.14px;\n}\n\n.root p:not(:last-child) {\n  margin-bottom: 25px;\n}\n\n.root ul,\n.root ol {\n  padding-left: 1rem;\n  padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `CQlIYNlh22c7xvfzkd9i`,
	"accordion-title": `sCu1N2gzY38I2lJv7jxu`,
	"root": `vTpg0xHRPWs9ge7PMyD8`
};
export default ___CSS_LOADER_EXPORT___;
