// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r5U1zfEjc8av8whSDi9B {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.4rem 0;
}

.r5U1zfEjc8av8whSDi9B span {
  font-size: 0.8rem;
  font-weight: bold;
}

.r5U1zfEjc8av8whSDi9B input {
  margin: 0;
}

.r5U1zfEjc8av8whSDi9B .kwae34jIPakdbORNicux {
  margin-left: 0;
  padding: 0;
}

.r5U1zfEjc8av8whSDi9B .VlSmzWLxOa8100HCeJzg {
  padding: 0;
  background-color: transparent;
  color: transparent;
}

.r5U1zfEjc8av8whSDi9B .FWshhr6SywO7cK5dCLve {
  padding: 0;
  background: var(--theme-color-white);
  color: var(--theme-color-primary);
  border-color: var(--theme-color-grey-c1);
}

.r5U1zfEjc8av8whSDi9B .VlSmzWLxOa8100HCeJzg svg {
  width: 9px;
}

.r5U1zfEjc8av8whSDi9B .N8pzW45VMlurk_LzZaaE {
  padding-left: 2rem;
  margin-left: 0.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputRadio/InputRadio.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,oCAAoC;EACpC,iCAAiC;EACjC,wCAAwC;AAC1C;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".InputRadio {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  padding: 0.4rem 0;\n}\n\n.InputRadio span {\n  font-size: 0.8rem;\n  font-weight: bold;\n}\n\n.InputRadio input {\n  margin: 0;\n}\n\n.InputRadio .InputRadio-button {\n  margin-left: 0;\n  padding: 0;\n}\n\n.InputRadio .InputRadio-input {\n  padding: 0;\n  background-color: transparent;\n  color: transparent;\n}\n\n.InputRadio .InputRadio-selected {\n  padding: 0;\n  background: var(--theme-color-white);\n  color: var(--theme-color-primary);\n  border-color: var(--theme-color-grey-c1);\n}\n\n.InputRadio .InputRadio-input svg {\n  width: 9px;\n}\n\n.InputRadio .InputRadio-description {\n  padding-left: 2rem;\n  margin-left: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputRadio": `r5U1zfEjc8av8whSDi9B`,
	"InputRadio-button": `kwae34jIPakdbORNicux`,
	"InputRadio-input": `VlSmzWLxOa8100HCeJzg`,
	"InputRadio-selected": `FWshhr6SywO7cK5dCLve`,
	"InputRadio-description": `N8pzW45VMlurk_LzZaaE`
};
export default ___CSS_LOADER_EXPORT___;
