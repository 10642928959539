import {
  AccountData,
  ContactData,
  SubscriptionData,
  VehicleDetails,
  PaymentMethodData,
  CapableServicesResponse,
} from '@api/types/';
import { ACCOUNT_ACTIONS, VEHICLE_ACTIONS } from '../actions/';
import { ROOT_EVENTS } from '../../reducers';
import { SETTINGS_ACTIONS } from '../actions/';
import { USER_EVENTS } from '@redux/actions/user';
import { StateItem } from '../types';

export type AccountDataState = {
  account?: StateItem<AccountData>;
  contacts?: StateItem<ContactData[]>;
  drivers?: StateItem<ContactData[]>;
  subscription?: StateItem<SubscriptionData>;
  vehicle?: StateItem<VehicleDetails>;
  paymentMethod?: StateItem<PaymentMethodData>;
  capableServices?: StateItem<CapableServicesResponse>;
  tenantId?: string;
};

type TenantId = string;

type SetAccountAction = {
  type: ACCOUNT_ACTIONS.SET_ACCOUNT_DATA;
  data: AccountData;
};

type SetContactsAction = {
  type: ACCOUNT_ACTIONS.SET_CONTACTS;
  data: ContactData[];
};

type SetDriversAction = {
  type: ACCOUNT_ACTIONS.SET_DRIVERS;
  data: ContactData[];
};

type SetSubscriptionsAction = {
  type: ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS;
  data: SubscriptionData;
};

type SetVehicleDataAction = {
  type: ACCOUNT_ACTIONS.SET_VEHICLE_DETAILS | VEHICLE_ACTIONS.SET_VEHICLE_DATA;
  data: VehicleDetails;
};

type SetCapableServicesAction = {
  type: ACCOUNT_ACTIONS.SET_CAPABLE_SERVICES;
  data: CapableServicesResponse;
};

type SetPaymentMethodAction = {
  type: ACCOUNT_ACTIONS.SET_PAYMENT_METHOD;
  data: PaymentMethodData;
};

type SetTenantIdAction = {
  type: ACCOUNT_ACTIONS.SET_TENANT_ID;
  data: TenantId;
};

type ClearSubscriptionsAction = {
  type: ROOT_EVENTS.CLEAR_SUBSCRIPTIONS;
};

type SetLocaleAction = {
  type: SETTINGS_ACTIONS.SET_LOCALE;
};

type ClearPaymentMethodsAction = {
  type: ACCOUNT_ACTIONS.CLEAR_PAYMENT_METHODS;
};

type SetUserPinStatusAction = {
  type: USER_EVENTS.SET_USER_PIN_STATUS;
  payload: boolean;
};

type DestorySessionCache = {
  type: ROOT_EVENTS.CLEAR_CACHE | ROOT_EVENTS.DESTROY_SESSION;
};

type AccountAction =
  | SetAccountAction
  | SetContactsAction
  | SetDriversAction
  | SetSubscriptionsAction
  | SetVehicleDataAction
  | SetCapableServicesAction
  | SetPaymentMethodAction
  | SetTenantIdAction
  | ClearSubscriptionsAction
  | SetLocaleAction
  | ClearPaymentMethodsAction
  | SetUserPinStatusAction
  | DestorySessionCache;

const accountReducer = (state: AccountDataState = {}, action: AccountAction): AccountDataState => {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_ACCOUNT_DATA:
      return {
        ...state,
        account: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
        tenantId: (action.data as AccountData)?.tenantId,
      };
    case ACCOUNT_ACTIONS.SET_CONTACTS:
      return {
        ...state,
        contacts: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_DRIVERS:
      return {
        ...state,
        drivers: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscription: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_VEHICLE_DETAILS:
    case VEHICLE_ACTIONS.SET_VEHICLE_DATA:
      const vehicleData = action.data || state.vehicle?.data;
      return {
        ...state,
        vehicle: {
          data: vehicleData,
          timestamp: new Date().getTime(),
        },
        contacts: undefined,
        drivers: undefined,
        capableServices: undefined,
      };
    case ACCOUNT_ACTIONS.SET_CAPABLE_SERVICES:
      return {
        ...state,
        capableServices: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_TENANT_ID:
      return {
        ...state,
        account: state.account && {
          ...state.account,
          data: {
            ...state.account.data,
            tenantId: action.data,
          },
        },
        tenantId: action.data,
      };
    case ROOT_EVENTS.CLEAR_SUBSCRIPTIONS: {
      return {
        ...state,
        subscription: undefined,
        vehicle: state.vehicle && { ...state.vehicle, timestamp: 0 },
      };
    }
    // force re-fetch of data after a change of locale
    case SETTINGS_ACTIONS.SET_LOCALE:
      return {
        ...state,
        drivers: state.drivers && { ...state.drivers, timestamp: 0 },
        contacts: state.contacts && { ...state.contacts, timestamp: 0 },
        subscription: state.subscription && { ...state.subscription, timestamp: 0 },
        vehicle: state.vehicle && { ...state.vehicle, timestamp: 0 },
        paymentMethod: state.paymentMethod && { ...state.paymentMethod, timestamp: 0 },
      };
    case ACCOUNT_ACTIONS.CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethod: state.paymentMethod && { ...state.paymentMethod, timestamp: 0 },
      };
    case USER_EVENTS.SET_USER_PIN_STATUS:
      return {
        ...state,
        account: state.account && {
          ...state.account,
          data: {
            ...state.account.data,
            isPinConfigured: action.payload,
          },
        },
      };
    case ROOT_EVENTS.CLEAR_CACHE:
    case ROOT_EVENTS.DESTROY_SESSION:
      return {
        ...state,
        account: undefined,
        drivers: undefined,
        contacts: undefined,
        subscription: undefined,
        vehicle: undefined,
        paymentMethod: undefined,
      };
    default:
      return state;
  }
};

export default accountReducer;
