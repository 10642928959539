import React from 'react';
import { packagesToRemove } from '../utils';
import { useFlowMachineContextFlowStorage } from '../../Router/RouterContext';

import { ReviewOrderContainerCommon } from './ReviewOrderContainerCommon';

export const ReviewOrderContainer = () => {
  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  return (
    <ReviewOrderContainerCommon
      packageToRemoveBuilder={packagesToRemove}
      packagesToSubscribe={subscribedPackages}
      filterPackages={(p) => p.active || p.amountWithoutTax < 0}
    />
  );
};
