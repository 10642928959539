import { BrandNamesFilterGroup, BrandNameFilter } from '../types/BrandNamesFilterGroup';

const getMakeFilterName = (make: string | undefined, { group, defaultBrandName }: BrandNamesFilterGroup): string => {
  const makeFilter = group.find((filter) => filter.brandName === make);
  const defaultFilter = group.find((filter) => filter.brandName === defaultBrandName) as BrandNameFilter;

  return !!makeFilter ? makeFilter.filterName : defaultFilter.filterName;
};

export default getMakeFilterName;
