// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-column-1 {
  grid-template-columns: 1fr;
}

.grid-column-2 {
  grid-template-columns: 1fr;
}

.grid-column-3 {
  grid-template-columns: 1fr;
}

.grid-column-4 {
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .grid-column-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid-column-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid-column-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Grid/Grid.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,kCAAkC;EACpC;EACA;IACE,sCAAsC;EACxC;AACF","sourcesContent":[".grid-column-1 {\n  grid-template-columns: 1fr;\n}\n\n.grid-column-2 {\n  grid-template-columns: 1fr;\n}\n\n.grid-column-3 {\n  grid-template-columns: 1fr;\n}\n\n.grid-column-4 {\n  grid-template-columns: 1fr;\n}\n\n@media (--viewport-s) {\n  .grid-column-2 {\n    grid-template-columns: 1fr 1fr;\n  }\n  .grid-column-3 {\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n  .grid-column-4 {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
