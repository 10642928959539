// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XI4xQ4qROfj2BMC2K7V9 {
  background-color: var(--theme-color-grey-ef);
}

.XYi0UY2yGhH8AFrzdwEu {
  margin: 0 auto;
  padding: 3rem 1rem 0 1rem;
  text-align: center;
}

.CVjn7V3bBJ2TiIlYJtwJ {
  color: var(--theme-color-header);
  font-size: 2.25rem;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.xbAYiQ8tCbWiVSbXsoOF {
  padding-bottom: 1rem;
  font-size: 1rem;
}

.WhzLUL5ciLz5vMgU9SYy {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .XYi0UY2yGhH8AFrzdwEu {
    width: 542px;
  }
}

.CO_Yo5XY5toRhjQWopNO {
  max-width: 100%;
  width: 200px;
  margin: auto;
  padding: 0;
  padding-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/DownloadApp/DownloadApp.module.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".container {\n  background-color: var(--theme-color-grey-ef);\n}\n\n.wrapper {\n  margin: 0 auto;\n  padding: 3rem 1rem 0 1rem;\n  text-align: center;\n}\n\n.title {\n  color: var(--theme-color-header);\n  font-size: 2.25rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n.body {\n  padding-bottom: 1rem;\n  font-size: 1rem;\n}\n\n.buttons-container {\n  gap: 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 1rem 0;\n}\n\n@media (--viewport-s) {\n  .wrapper {\n    width: 542px;\n  }\n}\n\n.brand-logo {\n  max-width: 100%;\n  width: 200px;\n  margin: auto;\n  padding: 0;\n  padding-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XI4xQ4qROfj2BMC2K7V9`,
	"wrapper": `XYi0UY2yGhH8AFrzdwEu`,
	"title": `CVjn7V3bBJ2TiIlYJtwJ`,
	"body": `xbAYiQ8tCbWiVSbXsoOF`,
	"buttons-container": `WhzLUL5ciLz5vMgU9SYy`,
	"brand-logo": `CO_Yo5XY5toRhjQWopNO`
};
export default ___CSS_LOADER_EXPORT___;
