import React from 'react';
import { EligiblePackageDetail, WebContentProps } from '@components/VinLookup/Types';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

interface VinEligibleServicesProps extends WebContentProps {
  eligiblePackages: EligiblePackageDetail[];
}

const VinEligibleServices = ({ labels, eligiblePackages }: VinEligibleServicesProps) => {
  const { vinEligibleServicesInfoLabel } = labels;

  const regularEligiblePackages = eligiblePackages?.filter((ePkg) => ePkg.packageType === PackageType.Regular);

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid lightgray',
        borderRadius: '6px',
        borderColor: 'divider',
        height: '34em',
        overflowY: 'auto',
      }}
    >
      <Typography mb={2}>{vinEligibleServicesInfoLabel}</Typography>
      <List>
        {regularEligiblePackages
          ?.sort((p1, p2) => p1.tier - p2.tier)
          .map((ePackage, index) => (
            <React.Fragment key={ePackage.id}>
              <ListItem>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={ePackage.marketingName}
                  secondary={ePackage.longDescription}
                  sx={{
                    '& .MuiListItemText-secondary': {
                      mt: 1,
                      color: (theme) => '#000',
                    },
                  }}
                />
              </ListItem>
              {index !== regularEligiblePackages?.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
};

export default VinEligibleServices;
