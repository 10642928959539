import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useOpenPage = (pathname: string) => {
  const history = useHistory();
  return useCallback(() => {
    const isOutsideRouter = history === undefined;
    return isOutsideRouter ? openPage(pathname) : history.push(pathname);
  }, [history, pathname]);
};

export function openPage(pathname: string) {
  window.location.href = `${window.location.origin}${pathname}`;
}

export default useOpenPage;
