import { useConfig } from '@components/ConfigProvider';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { Box, Slide, SlideProps } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCancelLabels } from './CancelLabelsContext';
import { CancelFlow, EVENTS } from './CancelSubscriptionStateMachine';
import { useCancelMachine, useCancelMachineContext } from './CancelSubscriptionStateMachineWrapper';
import styles from './Cancellation.module.css';
import AllBenefits from './steps/AllBenefits/AllBenefits';
import CancelPackages from './steps/CancelPackages';
import NextBestOffer from './steps/NextBestOffer';
import PreviewOrder from './steps/PreviewOrder';
import Promotions from './steps/Promotions';
import SelectPackagesToCancel from './steps/SelectPackagesToCancel/SelectPackagesToCancel';
import LastMessage from './steps/LastMessage';
import Payment from './steps/PaymentsMethods';
import ErrorModal from './ErrorModal';
import { useIsMutating } from '@tanstack/react-query';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';
import { getUniquePackages } from './cancellationUtils';

const Cancellation = () => {
  const cancelMachine = useCancelMachine();
  const { step, selectedPackages } = useCancelMachineContext();
  const { continueButton, keepButton, agreementType } = useCancelLabels();
  const config = useConfig();

  useEffect(() => {
    cancelMachine.send(EVENTS.SET_AGREEMENT_TYPE, { agreementType });
  }, [cancelMachine, agreementType]);

  const handleSelectPackage = useMemo(
    () => (selectedPackage: SubscribedPackage) => {
      cancelMachine.send(EVENTS.HANDLE_SELECT_PACKAGE, { selectedPackage });
    },
    [cancelMachine],
  );

  const handleSelectPromotion = useCallback(
    (selectedPromotion: EligiblePackageInfo) => {
      cancelMachine.send(EVENTS.HANDLE_SELECT_PROMOTION, { selectedPromotion });
    },
    [cancelMachine],
  );

  const handleSelectNextBestOffer = useCallback(
    (eligiblePackage: EligiblePackageInfo) => {
      cancelMachine.send(EVENTS.SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE, { eligiblePackage });
    },
    [cancelMachine],
  );

  const onContinue = useCallback(() => {
    cancelMachine.send({ type: EVENTS.NEXT_STEP });
  }, [cancelMachine]);

  const handleSetCancelReason = useCallback(
    (cancelReason) => {
      cancelMachine.send(EVENTS.SET_CANCEL_REASON, { cancelReason });
    },
    [cancelMachine],
  );

  const oemName = config.getOemName().toLowerCase();

  const orderIsLoading = useIsMutating({ mutationKey: ['createOrder'] });
  const previewIsLoading = useIsMutating({ mutationKey: ['previewOrder'] });

  const uniquePackages = useMemo(() => getUniquePackages(selectedPackages), [selectedPackages]);

  const ProfileButton = () => (
    <AnalyticsButton
      sx={(theme) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      })}
      variant="outlined"
      component={Link}
      to="/profile"
      analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
        text: keepButton,
        pageName: step,
      })}
    >
      {keepButton}
    </AnalyticsButton>
  );

  const CancellationNavigation = () => (
    <Box>
      <AnalyticsButton
        sx={(theme) => ({
          width: '100%',
          mb: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            mr: theme.spacing(1),
            mb: 0,
            width: 'auto',
          },
        })}
        disabled={!!orderIsLoading || !!previewIsLoading}
        onClick={onContinue}
        analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
          text: continueButton,
          pageName: step,
        })}
      >
        {continueButton}
      </AnalyticsButton>
      <ProfileButton />
    </Box>
  );

  const slideProps: Partial<SlideProps> = {
    timeout: { enter: 250, exit: 0 },
    mountOnEnter: true,
    unmountOnExit: true,
    direction: 'left',
  };

  return (
    <>
      <section className={clsx(styles['cancel-container'], styles[oemName])}>
        <Box
          sx={(theme) => ({
            margin: '1.5rem auto',
            paddingLeft: '1rem',
            paddingRight: '1rem',

            [theme.breakpoints.up('sm')]: {
              maxWidth: theme.breakpoints.values.sm,
            },
            [theme.breakpoints.up('md')]: {
              maxWidth: theme.breakpoints.values.md,
            },
          })}
        >
          <Slide {...slideProps} in={step === CancelFlow.SelectPackages}>
            <Box>
              <SelectPackagesToCancel
                onSelect={handleSelectPackage}
                onContinue={onContinue}
                Navigation={ProfileButton}
              />
            </Box>
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.AllBenefits}>
            <AllBenefits selectedPackages={uniquePackages} Navigation={CancellationNavigation} step={step} />
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.PackagePromotions}>
            <Promotions onSelect={handleSelectPromotion} Navigation={CancellationNavigation} step={step} />
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.NextBestOffer}>
            <NextBestOffer onSelect={handleSelectNextBestOffer} Navigation={CancellationNavigation} />
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.CancelPackages}>
            <CancelPackages
              onCancelReasonChange={handleSetCancelReason}
              onContinue={onContinue}
              Navigation={ProfileButton}
            />
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.PaymentMethods}>
            <Box>
              <Payment Navigation={ProfileButton} onContinue={onContinue} step={step} />
            </Box>
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.PreviewOrder}>
            <Box>
              <PreviewOrder Navigation={ProfileButton} onContinue={onContinue} />
            </Box>
          </Slide>

          <Slide {...slideProps} in={step === CancelFlow.LastMessage}>
            <Box>
              <LastMessage />
            </Box>
          </Slide>
        </Box>
      </section>
      <ErrorModal />
    </>
  );
};

export default Cancellation;
