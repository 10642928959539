import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';
import ModalContainer from '@components/ModalContainer/';
import MoreIcon from '@assets/more-icon.svg';
import StatusSuccessIcon from '@assets/status_icons.svg';
import StatusUnavailableIcon from '@assets/unavailable_mils.svg';
import StatusWarningIcon from '@assets/status_warning_icon.svg';
import { VehicleImageContainer } from '@components/VehicleImageContainer';
import clsx from 'clsx';
import styles from './VehicleCondition.module.css';
import { useSelector } from 'react-redux';
import { useVehicleConditionContext } from './VehicleConditionContext';
import { useApi } from '@api';
import { enterKeyPressed } from '@utils/enterKeyPressed';
import ScheduleServiceButton from '@components/ScheduleServiceButton';
import { useAnalytics } from '@cv/webframework-react-components';
import { useConvertDistance } from '@utils/metrics';

type VehicleConditionItemProps = {
  icon: ContentfulFile;
  label: string;
  contentBoxType: 'non expandable' | 'expandable' | 'default' | 'info';
  statusUnavailableLabel: string;
  statusSuccessLabel: string;
  statusWarningLabel: string;
  statusWarningDescription?: string;
  moreLabel: string;
  vehicleYearLabel: string;
  vehicleModelLabel: string;
  description: string;
  apiKey: string;
  imageContainer: {
    images: Array<data>;
  };
  commonWarningIcon?: ContentfulFile;
  modalWarningIcon?: ContentfulFile;
  scheduleServiceButtonLabel?: string;
  scheduleServiceButtonUrl?: string;
  expandAnalyticsEventName: string;
  collapseAnalyticsEventName: string;
  moreAnalyticsEventName: string;
};
type data = {
  image: ContentfulFile;
  name: string;
  tags: string[];
};

type WarningModalProps = {
  icon?: ContentfulFile;
  label?: string;
  description?: string;
  isConfirmationModalVisible: boolean;
  toggleConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleServiceButtonUrl?: string;
  scheduleServiceButtonLabel?: string;
  content?: React.ReactNode;
};

function VehicleConditionItem({
  icon,
  label,
  apiKey,
  description,
  contentBoxType,
  statusUnavailableLabel,
  statusSuccessLabel,
  statusWarningLabel,
  statusWarningDescription,
  vehicleYearLabel,
  vehicleModelLabel,
  moreLabel,
  imageContainer,
  commonWarningIcon,
  modalWarningIcon,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  expandAnalyticsEventName,
  collapseAnalyticsEventName,
  moreAnalyticsEventName,
}: VehicleConditionItemProps) {
  const {
    data: { vehicleData, isExpandAll, displayViewMode },
  } = useVehicleConditionContext();
  const { trackEvent } = useAnalytics();

  const [isExpanded, toggleExpanded] = useState<boolean>(false);
  const [isConfirmationModalVisible, toggleConfirmationModal] = useState<boolean>(false);
  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const { make: brand } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicle);
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const convertDistance = useConvertDistance();

  const tenantId = account?.data?.tenantId;
  const api = useApi();
  const userVehicleInfo = api.storeService.getUserVehicleInfo();

  const milStatusChecker = vehicleStatus?.mil?.filter((item) => item.type === apiKey);
  const milStatus = (milStatusChecker && milStatusChecker[0]?.status) || null;

  const isMilstatusWarning = milStatus === '1';
  const isDefaultViewMode = displayViewMode === 'default';
  const isWarningViewMode = displayViewMode === 'warning';
  const isShortViewMode = displayViewMode === 'short';

  const isExpandable = contentBoxType === 'expandable';
  const isNotExpandable = contentBoxType === 'non expandable';

  const defaultViewModeHidden = isMilstatusWarning && isDefaultViewMode && isExpandable;
  const warningViewModeHidden = !isMilstatusWarning && isWarningViewMode && isExpandable;

  useEffect(() => {
    toggleExpanded(isExpandAll);
  }, [isExpandAll]);

  const renderWarningDescriptionWithBrand = (brandName: string) => {
    if (statusWarningDescription) {
      const regex = /{{brand}}/i;

      return statusWarningDescription.replace(regex, brandName);
    }
    return null;
  };

  const renderChevron = (labelId: string) => {
    return isExpanded ? (
      <FaChevronUp
        role="button"
        aria-expanded={isExpanded}
        aria-labelledby={labelId}
        className={styles['VehicleConditionItem-expand']}
        onClick={() => {
          trackEvent(collapseAnalyticsEventName);
          toggleExpanded(!isExpanded);
        }}
      />
    ) : (
      <FaChevronDown
        role="button"
        aria-expanded={isExpanded}
        aria-labelledby={labelId}
        className={styles['VehicleConditionItem-expand']}
        onClick={() => {
          trackEvent(expandAnalyticsEventName);
          toggleExpanded(!isExpanded);
        }}
      />
    );
  };

  const renderMoreButton = () => {
    return (
      isExpandable && (
        <span
          role="button"
          tabIndex={0}
          className={styles['VehicleConditionItem-more']}
          onClick={() => {
            trackEvent(moreAnalyticsEventName);
            toggleConfirmationModal(true);
          }}
          onKeyPress={(e) => enterKeyPressed(e) && toggleConfirmationModal(true)}
        >
          <img className={styles['VehicleConditionItem-more-icon']} src={MoreIcon} alt={moreLabel} />
          <p className={styles['VehicleConditionItem-more-text']}>{moreLabel}</p>
        </span>
      )
    );
  };

  const renderVehicleInfo = () => {
    return !isExpandable && !isNotExpandable ? (
      <div className={styles['VehicleConditionItem-vehicle-content']}>
        {<VehicleImageContainer images={imageContainer?.[0]?.images} />}
        <div className={styles['VehicleConditionItem-description-wrapper']}>
          <p className={styles['VehicleConditionItem-vehicle-description']}>{userVehicleInfo}</p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>
            {vehicleYearLabel}: {vehicleData?.year}
          </p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>
            {vehicleModelLabel}: {vehicleData?.model}
          </p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>VIN: {vehicleData?.vin}</p>
        </div>
      </div>
    ) : null;
  };

  const getStatusElement = (status: string, type: 'icon' | 'label') => {
    switch (type) {
      case 'icon':
        switch (status) {
          case '0':
            return StatusSuccessIcon;
          case '1':
            return StatusWarningIcon;
          default:
            return StatusUnavailableIcon;
        }
      case 'label':
        switch (status) {
          case '0':
            return statusSuccessLabel;
          case '1':
            return statusWarningLabel;
          default:
            return statusUnavailableLabel;
        }
    }
  };

  if (isShortViewMode)
    return (
      <WarningShortView
        moreLabel={moreLabel}
        commonWarningIcon={commonWarningIcon}
        label={label}
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalVisible={isConfirmationModalVisible}
        warningDescription={renderWarningDescriptionWithBrand(brand)}
        modal={(isConfirmationModalVisible, toggleConfirmationModal) => (
          <WarningModal
            icon={modalWarningIcon}
            {...{
              isConfirmationModalVisible,
              toggleConfirmationModal,
              label,
              description,
              scheduleServiceButtonUrl,
              scheduleServiceButtonLabel,
            }}
          />
        )}
      />
    );

  const { value: odometerValue, unit: odometerUoM } = convertDistance(
    vehicleStatus?.odometer?.value,
    vehicleStatus?.odometer?.UoM,
  );

  const itemHeaderId = `VehicleConditionItem-header-${label.replace(' ', '+')}`;

  return (
    <div
      className={clsx(styles['VehicleConditionItem'], {
        [styles['VehicleConditionItem--hidden']]: defaultViewModeHidden || warningViewModeHidden,
        [styles['VehicleConditionItem--warning']]: isWarningViewMode,
      })}
    >
      <div className={styles['VehicleConditionItem-header']}>
        <div className={styles['VehicleConditionItem-title']}>
          <span className={styles['VehicleConditionItem-icon-default']}>
            <ContentfulImage imageData={icon} />
          </span>
          <h2 className={styles['VehicleConditionItem-name']} id={itemHeaderId}>
            {label}
          </h2>
        </div>

        {isNotExpandable && (
          <span className={styles['VehicleConditionItem-label']}>
            {odometerValue && odometerUoM ? `${odometerValue} ${odometerUoM}` : statusUnavailableLabel}
          </span>
        )}

        {isExpandable && milStatus && (
          <div className={styles['VehicleConditionItem-status']}>
            <img
              className={styles['VehicleConditionItem-icon-status']}
              src={getStatusElement(milStatus, 'icon')}
              alt="status_icon"
            />
            <h2 className={styles['VehicleConditionItem-status-name']}>{getStatusElement(milStatus, 'label')}</h2>
          </div>
        )}

        {isExpandable && renderChevron(itemHeaderId)}
      </div>

      {isExpanded && isExpandable && milStatus && (
        <div className={styles['VehicleConditionItem-content']}>
          <p className={styles['VehicleConditionItem-description']}>
            {isDefaultViewMode ? getStatusElement(milStatus, 'label') : renderWarningDescriptionWithBrand(brand)}
          </p>
          {renderMoreButton()}
        </div>
      )}

      {renderVehicleInfo()}
      <WarningModal
        {...{
          isConfirmationModalVisible,
          toggleConfirmationModal,
          icon,
          label,
          description,
          scheduleServiceButtonUrl,
          scheduleServiceButtonLabel,
        }}
      />
    </div>
  );
}

export type WarningShortViewProps = {
  warningDescription: string | undefined;
  label: string;
  moreLabel: string;
  commonWarningIcon?: ContentfulFile;
  modal?: (
    isConfirmationModalVisible: boolean,
    toggleConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>,
    isAuthStatusError?: boolean,
  ) => React.ReactNode;
  isAuthStatusError?: boolean;
  isConfirmationModalVisible: boolean;
  toggleConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WarningShortView = ({
  modal,
  commonWarningIcon,
  label,
  warningDescription,
  isConfirmationModalVisible,
  toggleConfirmationModal,
  moreLabel,
}: WarningShortViewProps) => (
  <>
    <div className={styles['VehicleConditionItem--short']}>
      <span>
        {commonWarningIcon && (
          <ContentfulImage imageData={commonWarningIcon} className={styles['VehicleConditionItem-icon--short']} />
        )}
        {label && <span className={styles['VehicleConditionItem-name--short']}>{label}</span>}
      </span>
      {warningDescription}
      <span
        role="button"
        tabIndex={0}
        className={styles['VehicleConditionItem-more--short']}
        onClick={() => toggleConfirmationModal(true)}
        onKeyPress={(e) => enterKeyPressed(e) && toggleConfirmationModal(true)}
      >
        <p className={styles['VehicleConditionItem-more-text']}>{moreLabel}</p>
      </span>
    </div>
    {modal?.(isConfirmationModalVisible, toggleConfirmationModal)}
  </>
);

const WarningModal = ({
  isConfirmationModalVisible,
  toggleConfirmationModal,
  icon,
  label,
  description,
  scheduleServiceButtonUrl,
  scheduleServiceButtonLabel,
  content,
}: WarningModalProps) => {
  return (
    <ModalContainer
      show={isConfirmationModalVisible}
      header={{ text: '', position: 'center', showTopBorder: false }}
      onCloseHandler={() => toggleConfirmationModal(false)}
      classes={{
        header: styles['WarningModal-header'],
        body: styles['WarningModal-body'],
        dialog: styles['WarningModal'],
      }}
    >
      {icon && <ContentfulImage imageData={icon} className={styles['WarningModal-icon']} />}
      {label && <span className={styles['WarningModal-label']}>{label}</span>}
      {description && <div className={styles['WarningModal-description']}>{description}</div>}
      {content}
      {!!scheduleServiceButtonLabel && !!scheduleServiceButtonUrl && (
        <ScheduleServiceButton
          classes={{ button: styles['WarningModal-button'] }}
          label={scheduleServiceButtonLabel}
          url={scheduleServiceButtonUrl}
          displayViewMode="warning"
        />
      )}
    </ModalContainer>
  );
};

export { WarningModal, WarningModalProps };
export default VehicleConditionItem;
