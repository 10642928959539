// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwoColumn-container {
  flex-direction: column;
}

.TwoColumn-container.TwoColumn-container--justify {
  justify-content: space-between;
}

@media (min-width: 992px) {
  .TwoColumn-container {
    flex-direction: row;
  }

  .TwoColumn-container.TwoColumn-container--justify {
    width: 63%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TwoColumnSection/TwoColumnSection.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".TwoColumn-container {\n  flex-direction: column;\n}\n\n.TwoColumn-container.TwoColumn-container--justify {\n  justify-content: space-between;\n}\n\n@media (--viewport-m) {\n  .TwoColumn-container {\n    flex-direction: row;\n  }\n\n  .TwoColumn-container.TwoColumn-container--justify {\n    width: 63%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
