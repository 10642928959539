import React, { FunctionComponent } from 'react';
import { Wrapper } from './styles';
import { ContentfulAssets } from '@manageSubscription';
import Image from '../../../components/Image';
import { Content, HSpace } from '../../../styled-components/globalStyles';

interface CurrentPackageProps {
  assets: ContentfulAssets;
  currentPackageLabel: string;
}

const CurrentPackage: FunctionComponent<CurrentPackageProps> = ({ assets, currentPackageLabel }) => {
  return (
    <Wrapper>
      <Content>{currentPackageLabel}</Content> <HSpace />
      <Image assets={assets} name="CheckMark" size="18px" />
    </Wrapper>
  );
};

export default CurrentPackage;
