export const getFractionLength = (number: number) => {
  const stringNumber = number.toString();
  const [_, fractionalPart] = stringNumber.split('.');
  return fractionalPart?.length ?? 0;
};

export const toFixed = (value: number, fixed?: number) => {
  return typeof fixed === 'number' ? Number(value.toFixed(fixed)) : value;
};

export function round(value: number, toNearest: number = 1, fixed?: number) {
  let result;
  // if nearest equal 0, it means we want to round down
  if (toNearest === 0) {
    result = Math.floor(value);
  } else {
    result = Math.round(value / toNearest) * toNearest;
  }

  if (typeof fixed === 'number') {
    return Number(result.toFixed(fixed));
  }

  if (!Number.isInteger(result)) {
    const fixedLength = getFractionLength(toNearest);
    return Number(result.toFixed(fixedLength));
  }

  return Number(result);
}
