import React from 'react';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { DetailHeading } from '@manageSubscription/Discount/OfferDetails/OfferDetails';
import PackageProductList from '@manageSubscription/Discount/PackageDetails/PackageProductList';
import { ProductsContainer, Description } from './PackageDetailsStyles';

export type PackageDetailsContentProps = {
  promoPackage?: EligiblePackageInfo;
  detailsLabel?: string;
};

const PackageDetailsContent = ({ promoPackage, detailsLabel }: PackageDetailsContentProps) => {
  const packageName = `${promoPackage?.marketingName || ''} ${detailsLabel || ''}`.trim();
  return (
    <div>
      <DetailHeading title="Package name">{packageName}</DetailHeading>
      <ProductsContainer>
        <PackageProductList
          products={promoPackage?.products}
          servicesTitle={<Description>{promoPackage?.shortDescription}</Description>}
        />
      </ProductsContainer>
    </div>
  );
};

export default PackageDetailsContent;
