import { styled } from '@mui/material';
import { Heading } from '../../styled-components/globalStyles';

export const Header = styled(Heading)`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;

export const IconSection = styled('span')`
  display: flex;
  justify-content: center;
  margin: 1.3rem;
`;
