import React from 'react';

import clsx from 'clsx';

import styles from '../MonitoringAlerts/MonitoringAlerts.module.css';

type AlertsCount = {
  label: string;
  preposition: string;
  countCurrent: number;
  countMax: number;
  className?: string;
};

export default function AlertsCount({ label, preposition, countCurrent = 0, countMax, className }: AlertsCount) {
  const alertClassName = clsx(styles['alerts-count'], className);

  return (
    <div className={alertClassName}>
      {countCurrent} {preposition} {countMax} {label}
    </div>
  );
}
