import { format } from 'date-fns';

type Options = Parameters<typeof format>['2'];

export const formatDate = (date: string, options?: Options) => {
  if (!date) return '-';

  const d = new Date(date);
  const formatted = format(d, 'MMM dd @ hh:mma', options);

  return formatted;
};
