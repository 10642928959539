import { PaymentMethodData } from '@api/types/index';

type ValidationsProps = {
  [key: string]: (props: PaymentMethodData) => boolean;
};

const isCreditCardValid = ({ expirationMonth, expirationYear }: PaymentMethodData) => {
  if (!expirationMonth || !expirationYear) {
    return false;
  }

  const currentDate = new Date();
  const inputExpirationMonthYear = new Date(Number(expirationYear), Number(expirationMonth) - 1);
  const todaysMonthYear = new Date(currentDate.getFullYear(), currentDate.getMonth());
  return inputExpirationMonthYear > todaysMonthYear;
};

const validations: ValidationsProps = {
  isCreditCardValid,
};

export default validations;
