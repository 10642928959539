import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type LoaderProps = {
  style?: Record<string, string>;
  sx?: Record<string, string>;
};

export default function Loader({ style }: LoaderProps): JSX.Element {
  return <CircularProgress color="primary" sx={{ marginLeft: 'auto', marginRight: 'auto' }} />;
}
