import React, { useState } from 'react';
import { Container, Content, Heading } from '@styled-components/globalStyles';
import { TrialPackagesProps } from '../Packages/DiscountPackage/TrialDiscount/TrialPackages';
import { TrialPackages } from '../Packages';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { LegalContainer } from '@manageSubscription/Legal';
import { trialEligiblePackages } from '@manageSubscription';
import { SubContent } from './styles';

type ManageTrialPackagesProps = {
  selectedTrialSubscriptions: EligiblePackageInfo[];
  header: string;
  subHeader: string;
  trialEligibleDescription: string;
  shouldMatchTrialWithCCRequired: boolean;
  legalText: string;
} & Omit<TrialPackagesProps, 'selectedTrialPackages' | 'setSelectedTrialPackages'>;

const ManageTrialPackages: React.FC<ManageTrialPackagesProps> = ({
  trialPackages,
  selectedTrialSubscriptions,
  header,
  subHeader,
  trialEligibleDescription,
  shouldMatchTrialWithCCRequired,
  hasMultipleTrials,
  legalText,
  ...rest
}) => {
  const [selectedTrialPackages, setSelectedTrialPackages] = useState<EligiblePackageInfo[]>(selectedTrialSubscriptions);

  const isTrialEligibleSelected = trialEligiblePackages(selectedTrialPackages).length > 0;

  return (
    <div>
      <Container>
        <Heading>{header}</Heading>
        {hasMultipleTrials && <SubContent>{subHeader}</SubContent>}
        {isTrialEligibleSelected && <Content>{trialEligibleDescription}</Content>}
        <TrialPackages
          {...rest}
          trialPackages={trialPackages}
          hasMultipleTrials={hasMultipleTrials}
          selectedTrialPackages={selectedTrialPackages}
          setSelectedTrialPackages={setSelectedTrialPackages}
          shouldMatchTrialWithCCRequired={shouldMatchTrialWithCCRequired}
        />
        <LegalContainer legalText={legalText} showCheckbox={false} />
      </Container>
    </div>
  );
};

export default ManageTrialPackages;
