import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';

const unwindByVariants = (packages: EligiblePackageInfo[] | EligiblePackagesResponse[]) => {
  return packages
    .map((pkg) =>
      pkg.variants.map((variant) => ({
        ...pkg,
        variant,
      })),
    )
    .flat();
};

export default unwindByVariants;
