import { useMemo } from 'react';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import useMake from '@hooks/useMake';
import getMakeFilterName from '@utils/getMakeFilterName';

export type Config<T> = {
  content: T;
  makeName: string;
  filterGroup: BrandNamesFilterGroup;
};

export type Filter<T> = (config: Config<T>) => T;

export type Props<T> = {
  content: T;
  filterGroup?: BrandNamesFilterGroup;
  filter: Filter<T>;
};

const useFilterByMake = <T>({ content, filterGroup, filter }: Props<T>): T => {
  const make = useMake();

  const filteredContent = useMemo(() => {
    if (typeof filterGroup !== 'undefined') {
      const makeName = getMakeFilterName(make, filterGroup);
      return filter({ content, makeName, filterGroup });
    }

    return content;
  }, [filter, content, make, filterGroup]);

  return filteredContent;
};

export default useFilterByMake;
