// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.euOGWpQDqETDygJOXVAm {
  display: flex;
  align-items: flex-start;
}

.o6fjNCFQ4hGoIWZuZZI5 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--theme-color-white);
}

.mN4m4JHU3Fn8WoHHSaT3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.w8kCtSQqTaFptFxhIMXV {
  width: 45px;
  margin-bottom: 1rem;
}

.eqTBs48yT1ypbToQpTmU {
  font-size: 24px;
  margin-bottom: 0.5rem;
}

p.YdP146D5G4EfEjEwu3dk {
  font-size: 12px;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionCard/ActionCardLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  align-items: flex-start;\n}\n\n.wrapper {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: var(--theme-color-white);\n}\n\n.title {\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 1rem;\n}\n\n.icon {\n  width: 45px;\n  margin-bottom: 1rem;\n}\n\n.subtitle {\n  font-size: 24px;\n  margin-bottom: 0.5rem;\n}\n\np.description {\n  font-size: 12px;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `euOGWpQDqETDygJOXVAm`,
	"wrapper": `o6fjNCFQ4hGoIWZuZZI5`,
	"title": `mN4m4JHU3Fn8WoHHSaT3`,
	"icon": `w8kCtSQqTaFptFxhIMXV`,
	"subtitle": `eqTBs48yT1ypbToQpTmU`,
	"description": `YdP146D5G4EfEjEwu3dk`
};
export default ___CSS_LOADER_EXPORT___;
