import { format } from 'date-fns';
import { isTrialPackage } from '@manageSubscription/utils/trialPackageUtils';
import { defaultDateFormat } from '@constants';
import { formatDateByLocale } from '@cv/portal-common-lib';
import { EligiblePackageInfo, SubscribedPackageInfo } from '@manageSubscription/Types';
import getFirstExisting from '@utils/getFirstExisting';

type LabelOptions = {
  renew?: string;
  expires?: string;
};

type UserOptions = {
  locale?: string;
  dateFormat?: string;
};

export function getExpireOrRenewText(
  pkg: SubscribedPackageInfo | EligiblePackageInfo,
  labelOptions: LabelOptions = { renew: 'Renews', expires: 'Expires' },
  userOptions: UserOptions = { locale: 'en-US', dateFormat: defaultDateFormat },
) {
  const isAutoRenew = 'autoRenew' in pkg ? pkg.autoRenew : !isTrialPackage(pkg.variant);

  const defaultDate = format(Date.now(), defaultDateFormat);
  const renewDate = getFirstExisting(pkg, ['termEndDate', 'variant.endDate'], defaultDate);
  const expiresDate = getFirstExisting(pkg, ['termServiceEndDate', 'variant.termServiceEndDate'], defaultDate);
  const termDate = isAutoRenew ? renewDate : expiresDate;

  const label = isAutoRenew ? labelOptions.renew : labelOptions.expires;
  const formattedDate = formatDateByLocale(termDate as string, userOptions.dateFormat, userOptions.locale);

  return `${label} ${formattedDate}`;
}
