import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { List } from '@mui/material';
import styles from './Tab.module.css';

type TabProps = {
  children: React.ReactElement<ChildProps>[];
  type: 'primary' | 'secondary' | 'tertiary';
  selectedTab?: 'none' | string;
  className?: string;
  hideAllTabs?: boolean;
  contentClassName?: string;
};

type ChildProps = {
  activeTab: string;
  label: string;
  ['data-label']: string;
  children: React.ReactNode;
  onClick: (label: string) => void;
  type: string;
};

const Tab = ({ children, type = 'primary', selectedTab, className, hideAllTabs, contentClassName = '' }: TabProps) => {
  const [activeTab, setActiveTab] = useState(selectedTab);
  const tabClassName = clsx(styles.TabContainer, styles[`TabContainer--${type}`], className);

  const onClickTabItem = (tab: string, callback?: Function) => {
    setActiveTab(tab);
    callback && callback();
  };

  useEffect(() => {
    if (hideAllTabs) {
      setActiveTab('none');
    }
  }, [hideAllTabs]);

  return (
    <div className={tabClassName}>
      <List
        className={styles.TabList}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: 0,
          padding: 0,
          listStyleType: 'none',
          ...(type === 'primary' && { backgroundColor: theme.palette.primary.main, borderTop: 0, pt: 0.313 }),
        })}
      >
        {children &&
          React.Children.map(children, (child) => {
            const label = child.props['data-label'];
            if (React.isValidElement(child)) {
              return (
                <li>
                  {React.cloneElement(child, {
                    activeTab,
                    key: label,
                    label,
                    onClick: () => onClickTabItem(label, child.props.onClick),
                    type,
                  })}
                </li>
              );
            }
          })}
      </List>
      <div className={clsx(styles.TabContent, contentClassName)}>
        {children &&
          React.Children.map(children, (child) => {
            if (child.props['data-label'] !== activeTab) return null;
            return child.props.children;
          })}
      </div>
    </div>
  );
};

export default Tab;