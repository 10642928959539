import { getSearchParam } from '@utils/url';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import useFilterFooter, { FilteredFooterProps } from './useFilterFooter';

const FilteredFooter = (props: FilteredFooterProps): JSX.Element | null => {
  const location = useLocation();
  const filteredContent = useFilterFooter(props);

  const hideNavigation = getSearchParam(location.search, 'hideNavigation');

  if (filteredContent.length === 0 || hideNavigation) {
    return null;
  }

  return <Footer content={filteredContent} />;
};

export default FilteredFooter;
