import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { WebContentProps } from '@components/VinLookup/Types';

const NonVinEligibilityInfo = ({ labels, assets }: WebContentProps) => {
  const { inEligibleServiceHeader, inEligibleServiceSubheader } = labels;

  const nonVinEligibleImage = assets?.find((a) => a.key === 'non-vin-eligible-image');

  return (
    <Container
      sx={(theme) => ({
        width: { xs: '100%', md: '70%' },
        mt: 4,
        mb: 4,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
      })}
    >
      <Box>
        <Typography
          mb={1}
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
        >
          {inEligibleServiceHeader}
        </Typography>
        <Typography variant="h6" style={{ color: 'grey' }}>
          {inEligibleServiceSubheader}
        </Typography>
        {nonVinEligibleImage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
            <img
              src={nonVinEligibleImage.asset.file.url}
              alt={nonVinEligibleImage.asset.title}
              style={{ width: '18rem', height: '18rem' }}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default NonVinEligibilityInfo;
