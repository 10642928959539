import React from 'react';

export const stringReplaceWithJSX = (str: string, formattingFunction: (value: string) => React.ReactNode | string) => {
  const regExp = /{{([^{}]*)}}/g;
  const regExpExecArray = regExp.exec(str);
  if (!regExpExecArray) {
    return str;
  }
  return str
    .split(regExp)
    .map((text, idx) => {
      if (idx & 1) {
        return formattingFunction(text);
      }
      return text;
    })
    .filter(Boolean);
};
