import { styled } from '@mui/material';
import { Link } from '../../styled-components/globalStyles';
import ContentfulImage from '../../components/Image';

export const PrivacyPolicyLink = styled(Link)`
  display: flex;
  margin: 1rem 0 0;
  justify-content: left;
`;

export const Image = styled(ContentfulImage)`
  margin-bottom: 24px;
`;

export const Description = styled('div')`
  margin-top: 14px;

  & + & {
    margin-top: 32px;
  }
`;
