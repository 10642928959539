import { VehicleResponse, VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { VehicleDetails } from '../types/vehicleDetails';
import { SubscribedServices } from '../types';
import { PROVISIONING_COMPLETE } from '@components/VinStatusPage/constants';
import { getCustomerFacingServices, getUniqueVehicleServices } from '@utils/subscriptionUtils';

type VehicleFormatter = (data: VehicleResponse, subscribedServices: SubscribedServices[]) => VehicleDetails;

export const formatVehicleDetails: VehicleFormatter = (data, subscribedServices = []) => {
  const vehicleServices: VehicleServiceDetails[] = [...data?.allVehicleServiceDetails.vehicleServiceDetails].sort(
    (serviceObj1: VehicleServiceDetails, serviceObj2: VehicleServiceDetails) =>
      (serviceObj1.displayOrder ? serviceObj1.displayOrder : 999) -
      (serviceObj2.displayOrder ? serviceObj2.displayOrder : 999),
  ); //Few of services don't have displayOrder, setting to max displayOrder

  const matchingServices = vehicleServices.filter(
    (serviceObj: VehicleServiceDetails) =>
      serviceObj.customerFacing && checkIsSubscribed(subscribedServices, data.vehicleId, serviceObj.vehicleServiceId),
  );
  const subscribedCustomerFacingServices = getCustomerFacingServices(matchingServices);
  const uniqueVehicleServices = getUniqueVehicleServices(subscribedCustomerFacingServices);

  const services = uniqueVehicleServices.map((service) => service.vehicleServiceName);
  const marketingServiceNames = uniqueVehicleServices.map((service) => service.marketingName);

  const activeServices = uniqueVehicleServices
    .filter((service) => service?.provisioningStatus === PROVISIONING_COMPLETE)
    .map((service) => service.vehicleServiceName);

  const capableServices = uniqueVehicleServices.filter((service) => service.provisioningStatus !== 'NULL');

  return {
    ...data,
    vehicleServiceDetails: vehicleServices,
    activeServices,
    services,
    marketingServiceNames,
    capableServices,
    subscribedCustomerFacingServices,
  };
};

const checkIsSubscribed = (subscribedServices: SubscribedServices[], vehicleId: string, serviceId: string) => {
  return subscribedServices
    .filter((subscribedService) => subscribedService.vehicleId === vehicleId)
    .flatMap((subscribedService) => subscribedService.subscribedServiceIds)
    ?.includes(serviceId);
};
