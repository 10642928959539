import { RootState } from '@app/reducers';
import { AnyCase, ValueOf } from '@customTypes/utils';
import { DistanceUnit } from '@cv/portal-rts-lib/v0/enums';
import { useSelector } from 'react-redux';
import { getFractionLength, round, toFixed } from './numbers';

export const KM_PER_MILE = 1.6093;
export const convertMilesToKm = (miles: number, fixed?: number) => {
  return toFixed(miles * KM_PER_MILE, fixed);
};

export const convertKmToMiles = (kilometers: number, fixed?: number) => {
  return toFixed(kilometers / KM_PER_MILE, fixed);
};

const shortVersions = {
  KILOMETERS: 'KM',
  MILES: 'MI',
} as const;

const longVersions = {
  KM: 'KILOMETERS',
  MI: 'MILES',
} as const;

const speedUnitsToShortVersions = {
  KPH: 'KM',
  MPH: 'MI',
} as const;

export const shortVersionsToSpeedUnits = {
  KM: 'KPH',
  MI: 'MPH',
} as const;

export const distanceUnits = {
  KM: DistanceUnit.Kilometer,
  MI: DistanceUnit.Mile,
} as const;

type SpeedUnits = keyof typeof speedUnitsToShortVersions;

type ShortVersions = typeof shortVersions;
export type UpperCaseLongUnits = keyof ShortVersions;
export type UpperCaseShortUnits = ValueOf<ShortVersions>;
export type UpperCaseDistanceUnits = UpperCaseLongUnits | UpperCaseShortUnits;

export type UpperCaseUnits = UpperCaseDistanceUnits | SpeedUnits;

const isLongVersionsKey = (key: string): key is keyof typeof longVersions => key in longVersions;
export const getLongUnit = (unit: UpperCaseDistanceUnits): UpperCaseLongUnits => {
  return isLongVersionsKey(unit) ? longVersions[unit] : unit;
};

export const getShortUnit = (unit: UpperCaseDistanceUnits): UpperCaseShortUnits => {
  return isLongVersionsKey(unit) ? unit : shortVersions[unit];
};

export type Distance = {
  unit: UpperCaseDistanceUnits;
  value: number;
};

export const distanceInUnits = (distance: Distance, countryUoM: UpperCaseShortUnits) => {
  if (distance.unit === countryUoM) {
    return distance.value;
  }

  const convert = countryUoM === 'MI' ? convertKmToMiles : convertMilesToKm;
  return convert(distance.value);
};

type Nearest = number | { mi: number; km?: number } | { mi?: number; km: number };
/* Take correct nearest
if we pass nearest as number - we apply it for both miles and kilometers
if we pass nearest only for miles - we use it for both, but convert it to kilometers before applying for kilometers
if we pass nearest only for kilometers - we use it for both, but convert it to miles before applying for miles
*/
export const getCorrectNearest = (toNearest: Nearest, UoM: UpperCaseShortUnits): Distance => {
  if (typeof toNearest === 'number') {
    return { value: toNearest, unit: UoM };
  }

  let lowerCaseUoM = UoM.toLowerCase() as Lowercase<UpperCaseShortUnits>;
  if (lowerCaseUoM in toNearest) {
    return { value: toNearest[lowerCaseUoM]!, unit: UoM };
  }

  const otherUoM = UoM === 'KM' ? 'MI' : 'KM';
  lowerCaseUoM = otherUoM.toLowerCase() as Lowercase<UpperCaseShortUnits>;
  return { value: toNearest[lowerCaseUoM]!, unit: otherUoM };
};

/**
 * The SI has been adopted as the official system of weights and measures by all nations in the world
 * except for Myanmar, Liberia, and the United States. — Metric System on Wikipedia (Metrication has a more-nuanced evaluation.)
 */
export const milesCountries = ['USA', 'LBR', 'MMR'];
export const convertBasedOnLocale = (
  value: number,
  UoM: UpperCaseDistanceUnits,
  country: string,
  roundToNearest: Nearest = 0,
): Distance => {
  const countryUoM = milesCountries.includes(country) ? 'MI' : 'KM';
  const foundNearest = getCorrectNearest(roundToNearest, countryUoM);

  const fixed = getFractionLength(foundNearest.value);
  const correctNearest = toFixed(distanceInUnits(foundNearest, countryUoM), fixed);
  const countryValue = distanceInUnits({ value, unit: getShortUnit(UoM) }, countryUoM);

  const getUnit = UoM in shortVersions ? getLongUnit : getShortUnit;
  return { value: round(countryValue, correctNearest, fixed), unit: getUnit(countryUoM) };
};

export const convertSpeedUoMToDistance = (UoM: UpperCaseUnits): UpperCaseDistanceUnits => {
  if (UoM in speedUnitsToShortVersions) {
    return speedUnitsToShortVersions[UoM as SpeedUnits];
  }

  return UoM as UpperCaseDistanceUnits;
};

type ConvertDistanceOptions = {
  roundToNearest?: Nearest;
};

type DistanceType = number | string;
type UoMType = AnyCase<UpperCaseUnits>;

type ConvertDistanceMethod = <V extends DistanceType, U extends UoMType>(
  value?: V,
  UoM?: U,
) => {
  value: V extends DistanceType ? number : undefined;
  unit: U extends UoMType ? UpperCaseDistanceUnits : undefined;
};

export const useConvertDistance = (options?: ConvertDistanceOptions) => {
  const country = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle.registrationCountry);

  const convertDistance = (value?: DistanceType, UoM?: UoMType) => {
    const valueAsNumber = Number(value);
    if (Number.isNaN(valueAsNumber) || typeof UoM === 'undefined') {
      return { value, unit: UoM };
    }

    const upperCasedUoM = UoM.toUpperCase() as UpperCaseUnits;

    const distanceUoM = convertSpeedUoMToDistance(upperCasedUoM);
    return convertBasedOnLocale(valueAsNumber, distanceUoM, country, options?.roundToNearest);
  };

  return convertDistance as ConvertDistanceMethod;
};

export const convertToApiUnit = (unit: UpperCaseShortUnits) => shortVersionsToSpeedUnits[unit];

export const useVehicleCountryUnit = () => {
  const country = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle.registrationCountry);
  return milesCountries.includes(country) ? shortVersions.MILES : shortVersions.KILOMETERS;
};

export const useVehicleCountryFormat = () => {
  const country = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle.registrationCountry);
  const countryUnit = useVehicleCountryUnit();
  return () => (!milesCountries.includes(country) ? `${countryUnit}/H` : shortVersionsToSpeedUnits[countryUnit]);
};
