import React from 'react';
import InputCheckbox from '@components/InputCheckbox';
import { useField } from 'formik';

const FormikCheckboxField = ({
  name,
  checked,
  placeholder,
  onChange,
}: {
  name: string;
  checked: boolean;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, callback: React.ChangeEventHandler<HTMLInputElement>) => void;
}) => {
  const [field, _, helpers] = useField(name);
  return (
    <InputCheckbox
      {...field}
      placeholder={placeholder}
      checked={checked}
      onChange={(e) => {
        onChange(e, field.onChange);
      }}
      helpers={helpers}
    />
  );
};

export default FormikCheckboxField;
