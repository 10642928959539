import { ComponentMapping } from '@components/component-mappings';
import ContentfulComponent from '@customTypes/ContentfulComponent';

export type ContentfulComponentWithContent<T> = ContentfulComponent & {
  content: T[];
};

export const getContentTypes = (components: ContentfulComponent[], contentType: ComponentMapping) => {
  return components.filter((label: ContentfulComponent) => label.componentType === contentType);
};

export const aggregateContent = <T extends any>(dictionaries: ContentfulComponentWithContent<T>[]) => {
  return dictionaries.reduce(
    (acc: Array<T>, dict: ContentfulComponentWithContent<T>) => [...acc, ...dict.content],
    [] as Array<T>,
  );
};
