import React from 'react';
import { AllLabels } from '@hooks/useLabels';

export type LabelListProps = {
  labels: AllLabels;
};

const LabelList = ({ labels }: LabelListProps) => (
  <div>
    <ul title="List of content">
      {Object.values(labels).map(({ primary: label }) => (
        <li key={label}>{label}</li>
      ))}
    </ul>
  </div>
);

export default LabelList;
