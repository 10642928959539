import { AggregatedFlowContext } from '@lib-appRouter/flows/componentFlow/Types';
import { VehicleResponse } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import {
  AccountData,
  AuthNotificationStatus,
  AUTH_NOTIFICATION_STATUSES,
  VehicleDetails,
} from '@manageSubscription/Subscription';
import { DoneInvokeEvent } from 'xstate';

export type userAuthorizationStatuses = VehicleResponse['refProperties']['subscriberArray'][number]['attributeValue'];

export const USER_AUTHORIZATION_STATUSES: Record<userAuthorizationStatuses, userAuthorizationStatuses> = {
  PENDING: 'PENDING',
  AUTHORIZED: 'AUTHORIZED',
  FAILED: 'FAILED',
};
const getAuthNotificationStatus = (
  vehicleDetails: VehicleDetails,
  isAuthCodeFunctionality: boolean,
): AuthNotificationStatus => {
  if (!isAuthCodeFunctionality) {
    return AUTH_NOTIFICATION_STATUSES.HIDE;
  }
  const subscriberArray = vehicleDetails?.refProperties?.subscriberArray;
  if (!subscriberArray) {
    return AUTH_NOTIFICATION_STATUSES.HIDE;
  }
  const subscriptionAuthorization = subscriberArray.find(
    ({ attributeKey }) => attributeKey === 'SubscriptionAuthorization',
  );
  if (subscriptionAuthorization?.attributeStatus === 'ERROR') {
    return AUTH_NOTIFICATION_STATUSES.ERROR;
  }

  const attributeStatus = subscriptionAuthorization?.attributeStatus;

  switch (attributeStatus) {
    case USER_AUTHORIZATION_STATUSES.FAILED:
      return AUTH_NOTIFICATION_STATUSES.ERROR;
    case USER_AUTHORIZATION_STATUSES.PENDING:
      return AUTH_NOTIFICATION_STATUSES.SHOW;
    default:
      return AUTH_NOTIFICATION_STATUSES.HIDE;
  }
};
const getAuthNotificationStatusFromContext = (
  { subscriptionProps }: AggregatedFlowContext,
  event: DoneInvokeEvent<{ data: AccountData }>,
): AuthNotificationStatus => {
  const { vehicles } = event?.data?.data;
  if (!vehicles) return AUTH_NOTIFICATION_STATUSES.HIDE;

  const vehicleDetails = vehicles.find(({ vehicleId }) => vehicleId === subscriptionProps.vehicleDetails.vehicleId);
  return getAuthNotificationStatus(vehicleDetails, true);
};

export { getAuthNotificationStatusFromContext, getAuthNotificationStatus };
