import useGetLink from '@hooks/useGetLink';
import { LabelObject } from '@utils/labels';

type RedirectCustomerProps = {
  labels: LabelObject;
};

const RedirectCustomer = ({ labels }: RedirectCustomerProps) => {
  const getLink = useGetLink();

  const redirect = async () => {
    const linkConfig = labels?.urlConfig?.primary;
    const link = await getLink(linkConfig);
    window.location.replace(link);
  };

  redirect();

  return null;
};

export default RedirectCustomer;
