import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';

type GetUserInfoArgs = {
  accessToken: string;
  config: {
    getOemValue: (arg: string) => string;
  };
};

export const getUserInfo = ({ accessToken, config }: GetUserInfoArgs) => {
  const userInfoUrl: string = config.getOemValue('USER_INFO_URL') || '';
  const request = {
    url: userInfoUrl,
    method: HttpMethodEnum.POST,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  return callHttpRequestMethod(request);
};
