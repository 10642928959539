import React from 'react';
import clsx from 'clsx';
import styles from './Input.module.css';
import { useTheme } from '@mui/material';

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: boolean;
  showErrorIcon?: boolean;
  helpers?: object;
};

const Input = ({ className, error = false, showErrorIcon = false, helpers, ...props }: InputProps) => {
  const theme = useTheme();
  const inputClassName = clsx(styles.input, className, {
    [styles.error]: error,
    [styles.errorIcon]: showErrorIcon,
  });

  return (
    <input
      className={inputClassName}
      {...props}
      style={{
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: `0.375rem 0.75rem`,
        ...(error ? { border: `1px solid ${theme.palette.error.main}` } : {}),
      }}
    />
  );
};

export default Input;
