import React from 'react';
import { useField } from 'formik';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import Field from '@components/Field';
import InputCheckbox from '@components/InputCheckbox';
import DisplayCheckbox from './DisplayCheckbox';

export const FlowSms = () => {
  const [, { value }, { setValue }] = useField('optIn');

  const rightLabel = get('smsCheckboxRightLabel') || '';

  return (
    <Field>
      <InputCheckbox checked={value} onChange={() => setValue(!value)} placeholder={rightLabel} />
    </Field>
  );
};

const FlowSmsCheckbox = () => (
  <DisplayCheckbox>
    <FlowSms />
  </DisplayCheckbox>
);

export default FlowSmsCheckbox;
