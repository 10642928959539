import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { previewOrders } from '../../services';
import Spinner from '../../components/Spinner';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { APIErrorResponse, APIResponse } from '@cv/portal-common-lib/ajax/models';
import { SubscriptionProps } from '../Subscription';
import { DiscountInfo, PackageToRemoveBuilderType, PreviewOrderRequestInfo } from '../Types';
import { packagesToRemove } from '../utils';

interface PreviewOrderSummaryFetcherProps {
  isFetchPreviewOrder?: boolean;
  setPreviewOrderResponse: (previewOrderResponse: PreviewOrderResponse) => void;
  setPreviewOrderFetched?: (isPreviewOrderFetched: boolean) => void;
  setPreviewOrderError?: (previewOrderError: string) => void;
  previewOrderRequestInfo: PreviewOrderRequestInfo;
  subscriptionProps: SubscriptionProps;
  packageToRemoveBuilder?: PackageToRemoveBuilderType;
}

export const PreviewOrderSummaryFetcher = ({
  isFetchPreviewOrder,
  previewOrderRequestInfo,
  setPreviewOrderResponse,
  setPreviewOrderFetched,
  setPreviewOrderError,
  subscriptionProps,
  packageToRemoveBuilder = packagesToRemove,
}: PreviewOrderSummaryFetcherProps) => {
  const {
    data: previewOrderResponse,
    isInitialLoading: isPreviewOrderLoading,
    isFetched: isPreviewOrderFetched,
    error: previewOrderError,
  } = useQuery(
    ['previewOrder'],
    () =>
      previewOrders(
        subscriptionProps,
        // should be in that way to prevent rollup to break `packagesToRemove` function on code minimization
        previewOrderRequestInfo?.packagesToAdd,
        previewOrderRequestInfo?.packagesToRemove,
        previewOrderRequestInfo?.paymentInfo?.paymentMethodId,
        previewOrderRequestInfo?.discountInfo as DiscountInfo,
        packageToRemoveBuilder,
      ),
    {
      cacheTime: 0,
      retry: false,
      enabled: isFetchPreviewOrder,
    },
  );

  useEffect(() => {
    if (previewOrderResponse) {
      setPreviewOrderResponse(previewOrderResponse);
    }
  }, [previewOrderResponse]);

  useEffect(() => {
    if (previewOrderError) {
      const { data } = previewOrderError as APIResponse<APIErrorResponse>;
      if (data?.message) {
        setPreviewOrderError?.(data.message);
      }
    }
  }, [previewOrderError]);

  useEffect(() => {
    if (isPreviewOrderFetched) {
      setPreviewOrderFetched?.(isPreviewOrderFetched);
    }
  }, [isPreviewOrderFetched]);

  return <div>{isPreviewOrderLoading && <Spinner />}</div>;
};
