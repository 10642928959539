import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createOrders } from '@lib-services';
import { getDefaultPayment } from '../../services/cps';
import { authorizeSite, buildAccertifyUrl, generateTransactionId, replaceTemplateString, useScript } from '../utils';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
  useFlowMachineContextSubscriptionProps,
} from '../../Router/RouterContext';
import { CONFIG_KEYS, SESSION_STORAGE_KEY } from '../utils/constants';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { OrderSummaryInfo, PackageToRemoveBuilderType, SubscribedPackageInfo } from '../Types';
import { buildOrderSummaryWithDiscount, buildPreviewOrderRequestInfo } from '../builders';
import { ReviewOrder } from './ReviewOrder';
import { filterPackagesType } from '../Packages/SubscribedPackage/SubscribedPackages';
import Modal from '@lib-components/NewModal/Modal';
import { useModal } from '@lib-components/NewModal/useModal';
import Button from '@lib-components/Button/Button';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import {
  EventDataBuilder,
  EventType,
  buildProductInfoData,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';

interface ReviewOrderContainerCommonProps {
  packagesToSubscribe: SubscribedPackageInfo[];
  packageToRemoveBuilder: PackageToRemoveBuilderType;
  filterPackages: filterPackagesType;
}

const SUB_BILL_CHARGE_ERROR = 'SUB_BILL_CHARGE_ERROR';
const ERROR_STATUS = 422;

type ErrorResponse = {
  data: {
    code: string;
    status: number;
  };
};

export const ReviewOrderContainerCommon = ({
  packagesToSubscribe,
  packageToRemoveBuilder,
  filterPackages,
}: ReviewOrderContainerCommonProps) => {
  const flowMachine = useFlowMachine();
  const location = useFlowLocation();
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [transactionId] = useState(generateTransactionId);
  const [orderSummaryInfo, setOrderSummaryInfo] = useState<OrderSummaryInfo>(null);
  const [previewOrderResponse, setPreviewOrderResponse] = useState<PreviewOrderResponse>(null);
  const [scriptLoaderStatus, setScriptLoaderSource] = useScript(null);
  const { clientIP, packageSubscriptions, paymentInfo, discountInfo } = useFlowMachineContextFlowStorage();
  const content = useFlowMachineContextContent();
  const { commonWebContent, paymentErrorMsg, paymentBillChargeErrorMsg } = content;
  const subscriptionProps = useFlowMachineContextSubscriptionProps();
  const flowMachineContext = useFlowMachineContext();

  const { isShown, toggleModal } = useModal();

  const { config, onSubscriptionConfirm = () => {}, customerCareNumber } = subscriptionProps;

  const siteId = config.get(CONFIG_KEYS.SITE_ID);
  const collectorUrl = config.get(CONFIG_KEYS.COLLECTOR_URL);

  // On transactionId state change, load script
  useEffect(() => {
    if (!transactionId) {
      return;
    }
    setScriptLoaderSource(buildAccertifyUrl(collectorUrl, siteId, transactionId));
  }, [transactionId]);

  // Authorize site once the script has been loaded
  useEffect(() => {
    if (scriptLoaderStatus !== 'active') return;
    authorizeSite(collectorUrl, siteId, transactionId);
  }, [scriptLoaderStatus]);

  useEffect(() => {
    if (previewOrderResponse) {
      const filteredOrderResponse = {
        ...previewOrderResponse,
        subscribedPackages: previewOrderResponse.subscribedPackages.filter(filterPackages),
      };
      setOrderSummaryInfo(
        buildOrderSummaryWithDiscount(filteredOrderResponse, packageSubscriptions, discountInfo?.promoCode),
      );
    }
  }, [previewOrderResponse]);

  const { data: defaultPayment } = useQuery(['defaultPayment'], () => getDefaultPayment(subscriptionProps));

  const {
    mutate,
    status: createOrderStatus,
    isLoading: isCreateOrderLoading,
    isError,
    error,
  } = useMutation<unknown, ErrorResponse>(
    ['createOrder'],
    () =>
      createOrders(
        subscriptionProps,
        packageSubscriptions,
        packagesToSubscribe,
        packageToRemoveBuilder,
        orderSummaryInfo,
        defaultPayment,
        commonWebContent.shouldIncludeTax,
        transactionId,
        clientIP,
        discountInfo,
      ),
    { cacheTime: 0 },
  );

  const previewOrderRequestInfo = buildPreviewOrderRequestInfo(
    packageSubscriptions,
    packagesToSubscribe,
    discountInfo,
    paymentInfo,
  );

  useEffect(() => {
    if (createOrderStatus === 'success') {
      sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE);
      onSubscriptionConfirm();
      flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
      openAnalyticsBracket(flowMachineContext);
      sendAnalyticsEvent(
        new EventDataBuilder(EventType.OrderEvent).withArgs({
          item: packageSubscriptions.map((pkg) => ({
            price: {
              sellingPrice: pkg.variant.actualPrice,
            },
            productInfo: {
              ...buildProductInfoData(pkg),
              priceTier: `${pkg.variant.actualPrice} ${pkg.variant.initialTermUnit}`,
            },
            quantity: 1,
            tax: pkg.variant.taxTotal,
            voucherDiscount: {
              orderLevelDiscountAmount: pkg.variant.discounts[0]?.amount,
              orderLevelDiscountCode: pkg.variant.discounts[0]?.discountCode,
            },
          })),
          payment: [
            {
              paymentGateway: previewOrderRequestInfo?.paymentInfo?.paymentOption || 'default',
              paymentID: transactionId,
            },
          ],
          purchaseID: transactionId,
          total: {
            currency: packagesToSubscribe?.[0]?.currency || '',
          },
          transactionID: transactionId,
        }),
      );
      closeAnalyticsBracket();
    }

    if (createOrderStatus === 'error') {
      toggleModal();
    }
  }, [createOrderStatus]);

  const billChargeErrorCondition =
    isError && error.data?.status === ERROR_STATUS && error.data?.code === SUB_BILL_CHARGE_ERROR;

  return (
    <>
      <ReviewOrder
        content={content}
        location={location}
        discountInfo={discountInfo}
        isCreateOrderLoading={isCreateOrderLoading}
        defaultPayment={defaultPayment}
        orderSummaryInfo={orderSummaryInfo}
        termsAgreed={termsAgreed}
        previewOrderResponse={previewOrderResponse}
        setTermsAgreed={setTermsAgreed}
        onConfirmOrder={mutate}
        setPreviewOrderResponse={setPreviewOrderResponse}
        subscriptionProps={subscriptionProps}
        previewOrderRequestInfo={previewOrderRequestInfo}
        navigateBack={() => flowMachine.send({ type: FlowEventName.NAVIGATE_BACK })}
        filterPackages={filterPackages}
        packageToRemoveBuilder={packageToRemoveBuilder}
        showDesignatedDealerMessage={flowMachineContext.showDesignatedDealerMessage}
      />
      <Modal
        open={isShown}
        onClose={toggleModal}
        primaryControl={
          <Button
            fullWidth
            onClick={() => {
              toggleModal();
              flowMachine.send({ type: FlowEventName.NAVIGATE_BACK });
            }}
          >
            Ok
          </Button>
        }
      >
        {billChargeErrorCondition
          ? paymentBillChargeErrorMsg
          : replaceTemplateString(paymentErrorMsg, { customerCareNumber })}
      </Modal>
    </>
  );
};
