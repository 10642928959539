import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { InputField, InputGroup, Label, Message } from './styles';

export interface InputProps {
  id: string;
  label: string;
  defaultValue: string | number;
  register: UseFormRegisterReturn;
  errorMsg?: string;
  className?: string;
}

export const Input = ({ id, label, defaultValue, register, errorMsg, className }: InputProps) => {
  return (
    <InputGroup className={className}>
      <Label htmlFor={id}>{label}</Label>
      <InputField className="input-field" type="text" id={id} defaultValue={defaultValue} {...register} />
      {!!errorMsg && <Message>{errorMsg}</Message>}
    </InputGroup>
  );
};
