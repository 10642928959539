import React from 'react';
import { useHistory } from 'react-router';
import { Stack, Typography, Box } from '@mui/material';
import { replaceTemplateString } from '@app/components-lib';
import { useCancelLabels } from '@components/Cancellation/CancelLabelsContext';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import StepScreenHeader from '@components/Cancellation/StepScreenHeader';
import { EventDataBuilder, EventType, cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';

const LastMessage = () => {
  const history = useHistory();
  const { btnGoToProfileText, ...otherLabels } = useCancelLabels();
  const { isCancelOnly, step } = useCancelMachineContext();

  const getLastMessageLabels = () => {
    const {
      finalHeaderOrder = '',
      finalHeaderCancel = '',
      finalSubHeaderOrder = '',
      finalSubHeaderCancel = '',
      finalMessageOrder = '',
      finalMessageCancel = '',
    } = otherLabels;
    return {
      header: isCancelOnly ? finalHeaderCancel : finalHeaderOrder,
      subHeader: isCancelOnly ? finalSubHeaderCancel : finalSubHeaderOrder,
      text: isCancelOnly ? finalMessageCancel : finalMessageOrder,
    };
  };

  const { header, subHeader, text } = getLastMessageLabels();

  const customerCareNumber = get('customerCareNumber');
  const message = replaceTemplateString(text, { customerCareNumber });

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: header,
    subsection: 'Cancelation > Last Message',
  });

  return (
    <Stack alignItems="flex-start">
      <Box
        sx={(theme) => ({
          margin: '1.5rem auto',
          paddingLeft: '1rem',
          paddingRight: '1rem',

          [theme.breakpoints.up('sm')]: {
            maxWidth: theme.breakpoints.values.sm,
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.md,
          },
        })}
      >
        <StepScreenHeader>{header}</StepScreenHeader>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {subHeader}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <AnalyticsButton
          onClick={() => history.push('/profile')}
          sx={{ mt: 2, textDecoration: 'underline' }}
          variant="text"
          analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
            text: btnGoToProfileText,
            pageName: step,
          })}
        >
          {btnGoToProfileText}
        </AnalyticsButton>
      </Box>
    </Stack>
  );
};

export default LastMessage;
