import styled from 'styled-components';

type BackgroundImageProps = {
  img: string;
  height: number;
};

const BackgroundImage = styled('div')`
  background-image: ${({ img }: BackgroundImageProps) => `URL(${img})`};
  display: flex;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: ${({ height }: BackgroundImageProps) => height}px;
`;

export default BackgroundImage;
