// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YkPc_Zi2OO7n_LvS3bug {
  color: var(--theme-color-text);
  margin-left: 1rem;
  margin-right: 1rem;
}

.YkPc_Zi2OO7n_LvS3bug h1 {
  color: var(--theme-color-header);
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.KYefS3y17a9N0nC2RBI2 {
  text-align: center;
}

@media (min-width: 768px) {
  .YkPc_Zi2OO7n_LvS3bug {
    margin: 3rem auto;
    max-width: 560px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/LinkVehicle.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".card {\n  color: var(--theme-color-text);\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n\n.card h1 {\n  color: var(--theme-color-header);\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n.error {\n  text-align: center;\n}\n\n@media (--viewport-s) {\n  .card {\n    margin: 3rem auto;\n    max-width: 560px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `YkPc_Zi2OO7n_LvS3bug`,
	"error": `KYefS3y17a9N0nC2RBI2`
};
export default ___CSS_LOADER_EXPORT___;
