// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CqH3lbGYv5rePlJJi03Q {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.vZoLJiQQzE8qtuSjtRdC {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.vZoLJiQQzE8qtuSjtRdC li {
  flex-grow: 1;
  text-align: center;
}

.osDHGFmI6VcvQsMDqys4 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.FsW1xZgxHH4ODiwq_tW0 {
  border: 0;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 0 5px;
}

.hXNLAjf97wYwPc8z9FVk {
  padding: 0 0.313rem;
}

.hXNLAjf97wYwPc8z9FVk > .osDHGFmI6VcvQsMDqys4 {
  padding: 0 0.9375rem;
}

.hXNLAjf97wYwPc8z9FVk ul {
  justify-content: space-around;
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/TempDestinationTab/Tab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,SAAS;EACT,6BAA6B;EAC7B,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".TabContainer {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n}\n\n.TabList {\n  display: flex;\n  justify-content: center;\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n\n.TabList li {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.TabContent {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.TabItem {\n  border: 0;\n  background-color: transparent;\n  text-align: center;\n  display: inline-block;\n  cursor: pointer;\n  padding: 0 5px;\n}\n\n.TabContainer--tertiary {\n  padding: 0 0.313rem;\n}\n\n.TabContainer--tertiary > .TabContent {\n  padding: 0 0.9375rem;\n}\n\n.TabContainer--tertiary ul {\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabContainer": `CqH3lbGYv5rePlJJi03Q`,
	"TabList": `vZoLJiQQzE8qtuSjtRdC`,
	"TabContent": `osDHGFmI6VcvQsMDqys4`,
	"TabItem": `FsW1xZgxHH4ODiwq_tW0`,
	"TabContainer--tertiary": `hXNLAjf97wYwPc8z9FVk`
};
export default ___CSS_LOADER_EXPORT___;
