// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yOJhd9Rhu9LhBYCLdbUC p {
  font-size: 0.75rem;
  margin: 0 0 0.625rem;
}

.zHfvw5JZoM02kRHQnpQg {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.zHfvw5JZoM02kRHQnpQg button {
  margin: 0 0.5rem;
  max-width: 315px;
  min-width: 120px;
}

.y3zYIAqHsLb69rfm81Em {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.j_DGzp6F7ax3R91BAXnb {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  justify-content: space-evenly;
}

.j_DGzp6F7ax3R91BAXnb button {
  min-width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResetDataInfoBox/ResetDataInfoBox.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".reset-data-container p {\n  font-size: 0.75rem;\n  margin: 0 0 0.625rem;\n}\n\n.button-container {\n  margin: 1rem 0;\n  display: flex;\n  justify-content: center;\n}\n\n.button-container button {\n  margin: 0 0.5rem;\n  max-width: 315px;\n  min-width: 120px;\n}\n\n.modal-text {\n  width: 100%;\n  text-align: center;\n  font-size: 12px;\n}\n\n.modal-buttons {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 80px;\n  justify-content: space-evenly;\n}\n\n.modal-buttons button {\n  min-width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-data-container": `yOJhd9Rhu9LhBYCLdbUC`,
	"button-container": `zHfvw5JZoM02kRHQnpQg`,
	"modal-text": `y3zYIAqHsLb69rfm81Em`,
	"modal-buttons": `j_DGzp6F7ax3R91BAXnb`
};
export default ___CSS_LOADER_EXPORT___;
