import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export type ButtonProps = MuiButtonProps & { to?: string };

const Button = ({ children, variant = 'contained', ...props }: ButtonProps) => {
  return (
    <MuiButton disableRipple={variant === 'text'} variant={variant} {...props}>
      {children}
    </MuiButton>
  );
};

export default Button;
