import { styled, Accordion, AccordionSummary } from '@mui/material';

export const AccordionContainer = styled(Accordion)`
  box-shadow: initial;

  &.Mui-expanded {
    margin: 0px 5px 16px;
  }
`;

export const CirclePlus = styled('span')`
  position: relative;
  border: 3px solid ${(props) => props?.theme?.palette?.portalTheme?.color || 'black'};
  border-radius: 50%;
  padding: 0.625rem;
  background-color: ${({ open, theme }) => (open ? theme?.palette?.portalTheme?.color || 'black' : 'transparent')};

  &::before {
    content: '${({ open }: { open: boolean }) => (open ? '-' : '+')}';
    font-family: sans-serif;
    font-size: 1.5rem;
    color: ${({ open, theme }) => (open ? 'white' : theme?.palette?.portalTheme?.color || 'black')};
    position: absolute;
    left: 50%;
    top: 50%;
    font-weight: bold;
    transform: translate(-50%, -50%);
    min-height: 38px;
  }
`;

export const AccordionTitle = styled(AccordionSummary)`
  padding: 0px;

  .MuiAccordionSummary-content {
    margin: 0.625rem 0;
  }
`;
