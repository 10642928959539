import React from 'react';
import HeaderOemBrandLogo, { HeaderOemBrandLogoType } from '@components/Header/HeaderOemBrandLogo';
import styles from './ResponsiveLogo.module.css';
import { Displays } from '@customTypes/DisplayEnum';

export type ResponsiveLogoProps = {
  logos?: HeaderOemBrandLogoType[];
  pagesWhenClick?: string[];
};

const ResponsiveLogo = ({ logos, pagesWhenClick }: ResponsiveLogoProps) => {
  const desktopLogos = logos?.filter(({ displayBreakpoint }) => displayBreakpoint === Displays.desktop) || [];
  const mobileLogos = logos?.filter(({ displayBreakpoint }) => displayBreakpoint === Displays.mobile) || [];

  const isDesktopLogoAvailable = !!desktopLogos.length;
  const isMobileLogoAvailable = !!mobileLogos.length;
  const isAnyLogoAvailable = isDesktopLogoAvailable || isMobileLogoAvailable;

  if (!isAnyLogoAvailable) {
    return null;
  }

  return (
    <div className={styles['responsive-logo']} title="Logo">
      {isDesktopLogoAvailable && (
        <div className={styles['desktop-logo']}>
          <HeaderOemBrandLogo content={desktopLogos} pagesToHideTcLinkInUserDropDown={pagesWhenClick} />
        </div>
      )}
      {isMobileLogoAvailable && (
        <div className={styles['mobile-logo']}>
          <HeaderOemBrandLogo content={mobileLogos} pagesToHideTcLinkInUserDropDown={pagesWhenClick} />
        </div>
      )}
    </div>
  );
};

export default ResponsiveLogo;
