import { assign, send } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, ManagePromoPackagesFlowContext } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const managePromoPackagesFlow: ComponentFlowStateConfig<ManagePromoPackagesFlowContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePromoPackages,
  states: {
    pre: {
      entry: 'getEligiblePromoPackages',
      always: [
        { target: 'navigateToSkip', cond: 'is2fFlowWithTrialEligibleHasNoPromos' },
        { target: 'navigate', cond: 'skipPromotion' },
        'idle',
      ],
    },
    idle: {
      entry: [
        assign(() => ({
          allowMultiplePromoPackagesSelection: true,
        })),
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePromoPackages },
        }),
      ],
      on: {
        NAVIGATE: 'navigate',
        NAVIGATE_OR_SKIP: 'navigateOrSkip',
        SKIP: 'navigateToSkip',
        NAVIGATE_BACK: { actions: 'navigateBack' },
      },
    },
    navigate: {
      always: [{ target: 'navigateForward', cond: 'hasPromoPackage' }, 'navigateToCancel'],
    },
    navigateOrSkip: {
      always: [{ target: 'navigateToSkip', cond: 'isTrialEligibleHasNoPaidPkg' }, 'navigateForward'],
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateToCancel: {
      entry: 'navigateToCancel',
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});
