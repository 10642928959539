import a1 from './1a';
import b1 from './1b';
import c1 from './1c';
import a2 from './2a';
import c2 from './2c';
import d2 from './2d';
import f2 from './2f';
import e2 from './2e';

export default {
  '1a': a1,
  '1b': b1,
  '1c': c1,
  '2a': a2,
  '2c': c2,
  '2d': d2,
  '2f': f2,
  '2e': e2,
  default: a2,
};
