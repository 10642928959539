import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import { PackageFlag } from '@customTypes/package-flags';
import { get } from '@components/GlobalPreferences';

const isPackageToShow = (anyPackage: SubscriptionPackage, flags?: PackageFlag[]): boolean => {
  const flagsToSearch = flags || get<PackageFlag[]>('packageFlags', []);
  const check = flagsToSearch
    .filter(({ componentType, name, isActive, priority, ...flag }) =>
      Object.keys(flag).every((key) => flag[key] === anyPackage[key]),
    )
    .sort((firstItem, secondItem) => secondItem.priority - firstItem.priority)
    .shift();

  return !check || check.isActive;
};

export default isPackageToShow;
