import React from 'react';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@app/types/ContentfulComponent';

type DataLoaderProps = {
  contentSections: Array<ContentfulComponent>;
  name: string;
};

function DataLoader({ contentSections, name }: DataLoaderProps) {
  return <ContentRenderer name={name} content={contentSections} />;
}

export default ModelConnector(DataLoader);
