import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import clsx from 'clsx';

import useToggle from '@hooks/useToggle';

import styles from './Dropdown.module.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material';

type HorizontalOriginEnum = 'left' | 'center' | 'right';

type DropdownProps = {
  firstItemLabel: React.ReactNode;
  children: React.ReactNode;
  classes?: {
    button?: string;
    dropdown?: string;
    wrapper?: string;
    container?: string;
  };
  isInline?: boolean;
  isContentToggle?: boolean;
  container?: HTMLElement;
  hideOnLocationChange?: boolean;
  anchorOrigin?: {
    horizontal: HorizontalOriginEnum;
  };
  sx: any;
};

export default function Dropdown({
  firstItemLabel,
  children,
  classes,
  isInline = false,
  isContentToggle = true,
  container,
  hideOnLocationChange = true,
  anchorOrigin = {
    horizontal: 'right',
  },
  sx,
}: DropdownProps) {
  const theme = useTheme();
  const [isOpen, toggleIsOpen] = useToggle(false);
  const location = useLocation();
  const renderChevron = () => {
    return isOpen ? (
      <FaChevronUp className={styles['Dropdown-chevron']} />
    ) : (
      <FaChevronDown className={styles['Dropdown-chevron']} />
    );
  };

  useEffect(() => {
    hideOnLocationChange && toggleIsOpen(false);
  }, [location, toggleIsOpen, hideOnLocationChange]);

  const renderDropdown = () => (
    <div
      className={clsx(
        styles[`Dropdown-wrapper${isInline ? '-inline' : ''}`],
        styles[anchorOrigin.horizontal],
        { [styles.show]: isOpen },
        classes?.wrapper,
      )}
    >
      <div
        className={clsx(
          styles[`Dropdown-content${isInline ? '-inline' : ''}`],
          { [styles.show]: isOpen },
          classes?.dropdown,
        )}
        onClick={isContentToggle ? toggleIsOpen : () => {}}
        style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, ...sx }}
      >
        {children}
      </div>
    </div>
  );

  const renderDropdownContainer = () => {
    if (!container) {
      return renderDropdown();
    }
    return ReactDom.createPortal(renderDropdown(), container);
  };

  return (
    <div className={clsx(styles['Dropdown'], { [styles.dark]: theme === 'dark' }, classes?.container)}>
      <button
        type="button"
        onClick={toggleIsOpen}
        className={clsx(styles['Dropdown-button'], classes?.button)}
        data-testid="DropdownButton"
      >
        {firstItemLabel}
        {renderChevron()}
      </button>
      {renderDropdownContainer()}
    </div>
  );
}
