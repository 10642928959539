// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iwyGK7XzFli8N9MGKKJw {
  text-align: center;
}

.UyB3Es3Jpcp79TuJjuIF {
  display: flex;
  margin-bottom: 1rem;
}

.UyB3Es3Jpcp79TuJjuIF > label {
  display: inline-flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.WNfdgClwM2IbFuXbHa3O {
  align-items: center;
  flex: 1;
  margin-bottom: 2rem;
}

.MN0nXHBv_EAp3q559xT4 {
  color: var(--theme-color-grey-7a);
  margin-bottom: 1rem;
}

.hFNEhTLKtFvKEGN7luph {
  font-size: 0.75rem;
}

.KeKILppFNlWaZzDGbZrT {
  margin-top: 0.75rem;
}

.isCG_yzpoOgz3atRjDyz {
  margin: 0 auto;
  min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/EmailSearch/EmailSearch.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".form {\n  text-align: center;\n}\n\n.input-radio {\n  display: flex;\n  margin-bottom: 1rem;\n}\n\n.input-radio > label {\n  display: inline-flex;\n  flex-direction: column;\n  margin-left: 0.5rem;\n}\n\n.input-wrapper {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n.input-label {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n.input-field {\n  font-size: 0.75rem;\n}\n\n.input-error {\n  margin-top: 0.75rem;\n}\n\n.submit {\n  margin: 0 auto;\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `iwyGK7XzFli8N9MGKKJw`,
	"input-radio": `UyB3Es3Jpcp79TuJjuIF`,
	"input-wrapper": `WNfdgClwM2IbFuXbHa3O`,
	"input-label": `MN0nXHBv_EAp3q559xT4`,
	"input-field": `hFNEhTLKtFvKEGN7luph`,
	"input-error": `KeKILppFNlWaZzDGbZrT`,
	"submit": `isCG_yzpoOgz3atRjDyz`
};
export default ___CSS_LOADER_EXPORT___;
