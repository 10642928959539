import { FlowEventName } from '../../../flowTypes';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, SubaruUserProfileFlowContext, FormValuesNNa, FormValues } from '../Types';

export const checkIfProfileIsComplete: ComponentFlowStateConfig<SubaruUserProfileFlowContext> = () => ({
  initial: 'checkIfProfileIsComplete',
  id: ComponentRoutes.checkIfProfileIsComplete,
  states: {
    checkIfProfileIsComplete: {
      always: [{ target: 'navigateToSkip', cond: 'isProfileComplete' }, { target: 'navigateForward' }],
    },
    navigateForward: {
      entry: FlowEventName.NAVIGATE_FORWARD,
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});
