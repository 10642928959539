export enum osTypeEnum {
  WP = 'Windows Phone',
  Android = 'Android',
  iOS = 'iOS',
  Desktop = 'Desktop',
}
declare global {
  interface Window {
    MSStream: string;
  }
}

export const getMobileOperatingSystem = (): osTypeEnum => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as Window & typeof globalThis & { opera: string }).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return osTypeEnum.WP;
  }

  if (/android/i.test(userAgent)) {
    return osTypeEnum.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return osTypeEnum.iOS;
  }

  return osTypeEnum.Desktop;
};

export const isMobileDevice = (): boolean => {
  const deviceType = getMobileOperatingSystem();
  return deviceType !== osTypeEnum.Desktop;
};
