import { assign, send } from 'xstate';
import { ComponentRoutes } from '@lib-appRouter/types';
import { ComponentFlowStateConfig, ToyotaFlowContext } from '../Types';
import { getVehicleDetails } from '@lib-services/cps/vehicleService';
import { getVehicleAndUserDetails } from '@lib-services/idm/vehicleService';
import { getPackagesSubscribed, requestToyotaOrderPreview } from '@lib-services/cps/subscriptionService';
import { assignSubscriptionProps } from '../../../flowUtils';
import { sendMessage } from '@manageSubscription/utils';
import { toyotaBuildSelectedPackageSummary } from '@manageSubscription/builders/orderBuilders';
import { OperationType } from './operationTypes';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { getCookie } from '@lib-utils';
import { SPINNER_TYPES } from '@lib-components/Spinner/Spinner';
import { getUserInfoByUserID } from '@lib-services/idm';
import { SubscriptionProps } from '@manageSubscription';

type UrlParamsManage = {
  operationType: OperationType.manage;
  authorization: string;
  guid: string;
  vin: string;
};

type UrlParamsPurchase = Omit<UrlParamsManage, 'operationType'> & {
  operationType: OperationType.purchase;
  offerIds: string[];
};

const setCorrectLocale = (country: string, props: SubscriptionProps) => {
  const { setLanguage, locale } = props;
  let currentLang: string;
  switch (country) {
    case 'hi':
      currentLang = 'en-HI';
      break;
    case 'pr':
      currentLang = ['en-PR', 'en-US'].includes(locale) ? 'en-PR' : 'es-PR';
      break;
    case 'ca':
      currentLang = locale === 'fr-CA' ? 'fr-CA' : 'en-CA';
      break;
    default:
      currentLang = 'en-US';
  }
  if (currentLang !== locale) {
    setLanguage(currentLang);
  }
};

const cookieNames = [
  { key: 'at', name: 'authorization' },
  { key: 'g', name: 'guid' },
  { key: 'v', name: 'vin' },
  { key: 'b', name: 'brand' },
  { key: 'l', name: 'language' },
  { key: 'ot', name: 'operationType' },
  { key: 'po', name: 'offerIds' },
  { key: 'r', name: 'region' },
] as const;

export const toyotaPaymentFlow: ComponentFlowStateConfig<ToyotaFlowContext> = () => ({
  initial: 'parseUrl',
  id: ComponentRoutes.payment,
  on: {
    error: {
      actions: () => sendMessage('User Need to Retry'),
    },
    submit: {
      actions: [
        'setLoading',
        (_, event) => sendMessage(`Payment Information Stored - paymentMethodId:${event.data.paymentMethodId}`),
      ],
    },
    cancel: {
      actions: () => sendMessage('Payment Information Cancelled'),
    },
  },
  states: {
    parseUrl: {
      entry: send({ type: 'setLoading', data: { type: SPINNER_TYPES.OPAQUE } }),
      invoke: {
        id: 'parseUrl',
        src: () => {
          const data = cookieNames.reduce((cookieParams, { key, name }) => {
            const foundParameter = getCookie(key);

            return {
              ...cookieParams,
              [name]: name === 'offerIds' ? foundParameter?.split(',') || [] : foundParameter,
            };
          }, {} as UrlParamsManage | UrlParamsPurchase);

          if (!data.operationType) {
            return Promise.reject('Operation type is no set');
          }

          return Promise.resolve(data);
        },
        onDone: {
          target: 'fetchUserDetails',
          actions: [
            assignSubscriptionProps(({ subscriptionProps }, event) => {
              const { authorization, language, guid, region, brand, vin } = event.data;
              if (region) {
                setCorrectLocale(region.toLowerCase(), subscriptionProps);
              }
              return {
                accessToken: authorization,
                locale: language,
                userDetails: {
                  userId: guid,
                  billingAddress: {
                    ...(region && {
                      region: `${brand}_${region.toLowerCase()}`,
                    }),
                  },
                },
                vehicleDetails: {
                  vin,
                },
              };
            }),
            assign({
              operationType: (_, event) => event.data.operationType,
              offerIds: (_, event) => event.data.offerIds,
            }),
          ],
        },
        onError: {
          actions: [send('error'), 'unsetLoading'],
        },
      },
    },
    fetchUserDetails: {
      invoke: {
        id: 'fetchUserDetails',
        src: (context) => getUserInfoByUserID(context.subscriptionProps),
        onDone: [
          {
            target: 'fetchDetails',
            actions: [
              assignSubscriptionProps(({ subscriptionProps }, { data: currentUser }) => {
                if (!currentUser) {
                  console.error(`User ${subscriptionProps.userDetails.userId} wasn't found`);
                  send('error');
                  return {};
                }

                return {
                  userDetails: {
                    firstName: currentUser.givenName,
                    lastName: currentUser.surname,
                    email: currentUser.email,
                    phone: currentUser.primaryPhone,
                    billingId: currentUser.billingId,
                    billingAddress: {
                      address1: currentUser.billingPostalAddress1,
                      address2: currentUser.billingPostalAddress2,
                      city: currentUser.billingCity,
                      state: currentUser.billingStateProvince,
                      postalCode: currentUser.billingPostalCode,
                      country: currentUser.mailingCountry || currentUser.billingCountry,
                      ...subscriptionProps.userDetails.billingAddress,
                    },
                  },
                };
              }),
            ],
          },
        ],
        onError: {
          actions: [send('error'), 'unsetLoading'],
        },
      },
    },
    fetchDetails: {
      always: [{ target: 'handleOperationType', cond: 'isRegionAlreadyAdded' }, { target: 'fetchVehicleDetails' }],
    },
    fetchVehicleDetails: {
      invoke: {
        id: 'fetchVehicleDetails',
        src: async (context) => {
          const { accessToken, locale, tenantId, config } = context.subscriptionProps;
          const {
            result: [{ vehicleId }],
          } = await getVehicleAndUserDetails(context.subscriptionProps);
          return getVehicleDetails({
            accessToken,
            locale,
            vehicleId,
            tenantId,
            config,
          });
        },
        onDone: {
          target: 'handleOperationType',
          actions: [
            assignSubscriptionProps(({ subscriptionProps }, { data }) => {
              const telematicsProgramId = data?.telematicsProgramId;

              if (!telematicsProgramId) {
                send('error');
                return {};
              }

              /* all possible variants for us are:
                                toyota_us, toyota_pr, toyota_ca, toyota_hi
                                lexus_us, lexus_pr, lexus_ca, lexus_hi

                                the data we get from API looks like: TOYOTA_17CY_US, so we need to make sure we have only needed part and extract it correctly.
                                (?<NAME> variant1|variant2) - take group with specific name, so we would have `variable.groups[NAME]` to take here.
                            */
              const { groups } = telematicsProgramId?.match(/(?<brand>toyota|lexus)(?:.*)_(?<country>us|pr|hi|ca)/i);
              let region =
                groups?.brand && groups?.country ? `${groups?.brand}_${groups?.country}`.toLowerCase() : undefined;

              setCorrectLocale(groups?.country?.toLowerCase(), subscriptionProps);

              // TODO: get additional information on what should be the correct behavior
              if (!region) {
                console.error(
                  `The region is not defined for the current vehicle: ${data.make} ${data.model} with id: ${data.vehicleId}, defaults to toyota_us`,
                );
                region = 'toyota_us';
              }

              return {
                userDetails: {
                  billingAddress: {
                    region,
                  },
                },
              };
            }),
          ],
        },
        onError: {
          actions: [send('error'), 'unsetLoading'],
        },
      },
    },
    handleOperationType: {
      always: [{ target: 'fetchPackagesPreview', cond: 'isToyotaPurchaseFlow' }, 'idle'],
    },
    fetchPackagesPreview: {
      invoke: {
        id: 'fetchPackagesPreview',
        src: async (context) => {
          const subscribedPackages = (await getPackagesSubscribed(context.subscriptionProps)) as SubscribedPackage[];
          return requestToyotaOrderPreview(context.subscriptionProps, context.offerIds, subscribedPackages);
        },
        onDone: {
          target: 'idle',
          actions: [assign((context, event) => toyotaBuildSelectedPackageSummary(event.data, context.offerIds))],
        },
        onError: {
          actions: [send('error'), 'unsetLoading'],
        },
      },
    },
    idle: {
      entry: [
        'unsetLoading',
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.payment },
        }),
      ],
    },
  },
});
