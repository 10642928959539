import React, { forwardRef } from 'react';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import { useCancelLabels } from '../../CancelLabelsContext';
import Checkbox from '@lib-components/Checkbox';
import Loader from '@components/Loader';
import CustomerPackage from './CustomerPackage';

import styles from '../../Cancellation.module.css';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import { EventDataBuilder, EventType, cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import NavigationProp from '@customTypes/NavigationProp';
import { Stack, Box } from '@mui/material';

export type SelectPackagesToCancelProps = {
  onSelect: (pkg: SubscribedPackage) => void;
  onContinue: () => void;
} & NavigationProp;

const SelectPackagesToCancel = forwardRef(({ onSelect, onContinue, Navigation }: SelectPackagesToCancelProps, _ref) => {
  const { isLoading, isFetching } = useSubscribedPackages();

  const { subscribedPackages, selectedPackages, step } = useCancelMachineContext();

  const { selectSubscription, continueButton } = useCancelLabels();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: selectSubscription,
    subsection: 'Cancelation > Select Subscription',
  });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <Stack component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <h3 className={styles['cancel-layout-header']}>{selectSubscription}</h3>
      <ul className={styles['customer-packages-list']}>
        {subscribedPackages.map((subscribedPackage) => {
          return (
            <li key={subscribedPackage.subscriptionPackageId}>
              <CustomerPackage
                customerPackage={subscribedPackage}
                checkbox={
                  <Checkbox
                    className={styles['select-to-cancel-checkbox']}
                    onChange={() => onSelect(subscribedPackage)}
                    checked={subscribedPackage.isSelected || subscribedPackage.isAutoSelected}
                    disabled={subscribedPackage.isAutoSelected}
                    placeholder={subscribedPackage.packageId}
                  />
                }
              />
            </li>
          );
        })}
      </ul>
      <Box>
        <AnalyticsButton
          sx={(theme) => ({
            width: '100%',
            mb: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              mr: theme.spacing(1),
              mb: 0,
              width: 'auto',
            },
          })}
          disabled={!selectedPackages.length}
          onClick={onContinue}
          analyticsEvent={new EventDataBuilder(EventType.SubmitPackagesOfCancelFlow).withArgs({
            subscribedPackages,
            selectedPackages,
          })}
        >
          {continueButton}
        </AnalyticsButton>
        {Navigation && <Navigation />}
      </Box>
    </Stack>
  );
});

export default SelectPackagesToCancel;
