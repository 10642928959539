import React from 'react';
import DaysSelect, { DaysSelectProps } from './DaysSelect';
import { FormikHandlers } from 'formik';
import { WeekDay } from '@components/Map/MonitoringAlerts/ManageAlertsContent/TabCurfew';

type DaysSelectFormikWrapperProps = {
  helpers?: FormikHandlers;
} & DaysSelectProps;

const DaysSelectFormikField = ({ helpers, ...props }: DaysSelectFormikWrapperProps) => {
  const onChange = (value: WeekDay[], isSelected: boolean) => {
    helpers?.setValue(value);
  };

  return <DaysSelect {...props} onChange={onChange} />;
};

export default DaysSelectFormikField;
