import React from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import { FieldHelperProps } from 'formik';
import { ListAddressesResponse } from '@cv/portal-cps-lib/utility/utility-services/models';
import { useQuery } from '@tanstack/react-query';
import useDebounce from '@hooks/useDebounce';

type AddressAutocompleteProps = Omit<
  AutocompleteProps<ListAddressesResponse, false, true, true>,
  'renderInput' | 'options'
> & {
  name: string;
  label: string;
  loadOptions: (inputText: string) => Promise<ListAddressesResponse[]>;
  onSelectOption: (selectedAddress: ListAddressesResponse) => void;
  error?: boolean;
  helpers?: FieldHelperProps<string>;
  onChange: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  variant: TextFieldVariants;
};

export const AddressAutocomplete = (props: AddressAutocompleteProps) => {
  const { name, label, placeholder, id, value, onChange } = props;
  const { loadOptions, onSelectOption, error, ...restProps } = props;

  const debouncedValue = useDebounce(value, 500);

  const { data: options = [] } = useQuery({
    queryKey: ['address', debouncedValue],
    queryFn: () => loadOptions(debouncedValue),
    refetchOnMount: false,
  });

  return (
    <Autocomplete
      {...restProps}
      disablePortal
      autoComplete
      onInputChange={onChange}
      inputValue={value}
      onChange={(e, val) => {
        e.preventDefault();
        val && onSelectOption(val);
      }}
      id={id}
      disableClearable
      freeSolo
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option: ListAddressesResponse | string) => {
        if (typeof option === 'string') {
          return option;
        } else {
          return option.address;
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          data-testid={id}
          label={label}
          name={name}
          placeholder={placeholder}
          required
          variant={props.variant || 'outlined'}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};
