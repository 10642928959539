import set from 'lodash/set';

import { getCurfews, deleteCurfews, createCurfews, updateCurfews } from '@cv/portal-rts-lib/curfews';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  CurfewsRequest,
  UpdateCurfewsRequest,
  CreateCurfewsRequest,
  DeleteCurfewsRequest,
  UpdateCurfewPayload,
  CreateCurfewPayload,
} from '@cv/portal-rts-lib/curfews/models';
import { Modify } from '@cv/portal-common-lib';

type CurfewRequestProps = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
};

type UpdateCurfewRequestProps = Modify<
  CurfewRequestProps,
  {
    data: UpdateCurfewPayload;
  }
>;

type CreateCurfewRequestProps = Modify<
  CurfewRequestProps,
  {
    data: CreateCurfewPayload;
  }
>;

type DeleteCurfewRequestProps = Modify<
  CurfewRequestProps,
  {
    data: {
      id: string;
    };
  }
>;

export default class CurfewService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getCurfews({ vehicleId, accessToken, idToken }: CurfewRequestProps) {
    const request: CurfewsRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await getCurfews(request);
    return { data: response.data };
  }

  async updateCurfews({ vehicleId, accessToken, idToken, data }: UpdateCurfewRequestProps) {
    const request: UpdateCurfewsRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await updateCurfews(request);
    return { data: response.data };
  }

  async createCurfews({ vehicleId, accessToken, idToken, data }: CreateCurfewRequestProps) {
    const request: CreateCurfewsRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await createCurfews(request);
    return { data: response.data };
  }

  async deleteCurfews({ vehicleId, accessToken, idToken, data }: DeleteCurfewRequestProps) {
    const request: DeleteCurfewsRequest = {
      pathParams: {
        vehicleId: vehicleId,
        curfewId: data.id,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await deleteCurfews(request);
    return { data: response.data };
  }
}
