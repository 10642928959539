import { SearchUserResponse } from '@manageSubscription/Types';
import { getUserInfo } from '../../../services/cps/userService';
import { getVehicleDetails } from '../../../services/cps/vehicleService';
import { SubscriptionProps, UserDetails, VehicleDetails } from '@manageSubscription';
import { pick } from 'lodash';

export const fetchVehicleDetails = async (
  subscriptionProps: SubscriptionProps,
  accessToken: string,
): Promise<VehicleDetails> => {
  try {
    const vehicleData = await getVehicleDetails({
      vehicleId: subscriptionProps.vehicleDetails.vehicleId,
      tenantId: subscriptionProps.tenantId,
      accessToken,
      config: subscriptionProps.config,
      locale: subscriptionProps.locale,
    });

    subscriptionProps.setVehicleData(vehicleData);

    return pick(vehicleData, [
      'vehicleId',
      'make',
      'vin',
      'telematicsProgramId',
      'registrationCountry',
      'model',
      'year',
      'color',
      'ownershipType',
      'trimLevel',
      'refProperties',
      'type',
    ]);
  } catch (e) {
    console.error('Fetching vehicle details has failed. Reason: ', e);
    throw e;
  }
};

export const fetchUserDetails = async (
  subscriptionProps: SubscriptionProps,
  registrationCountry: string,
  accessToken: string,
  searchUserData: SearchUserResponse,
): Promise<UserDetails> => {
  try {
    if (!subscriptionProps.userDetails.userId) {
      const { data: userInfo = {} } = await getUserInfo({
        accessToken,
        config: subscriptionProps.config,
      });
      subscriptionProps.userDetails.userId = userInfo.sub;
    }
    const {
      data: {
        billingId,
        userName,
        email,
        externalTenantUserId,
        billingCountry,
        billingAddress,
        primaryPhone,
        secondaryPhone,
      },
    } = await subscriptionProps.getAccountDetails(
      subscriptionProps.locale,
      subscriptionProps.userDetails.userId,
      accessToken,
    );
    const { fcaUser } = searchUserData;
    return {
      ...subscriptionProps.userDetails,
      billingId,
      firstName: userName.givenName,
      lastName: userName.fathersName,
      email,
      externalTenantUserId: externalTenantUserId || fcaUser.userId,
      billingAddress: {
        address1: billingAddress.street,
        address2: billingAddress.unit,
        city: billingAddress.city,
        state: billingAddress.state,
        country: billingCountry || registrationCountry,
        postalCode: billingAddress.zip,
      },
      primaryPhone,
      secondaryPhone,
    };
  } catch (e) {
    console.error('Fetching user details has failed. Reason: ', e);
    throw e;
  }
};
