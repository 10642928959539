import { v4 as uuidv4 } from 'uuid';
import config from '@config/config';
import { listPayments, updatePayments, getListOfRecentInvoices } from '@cv/portal-cps-lib/payment';
import { Environment, HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import {
  PaymentMethodRequest,
  PaymentMethodResponse,
  UpdatePaymentMethodRequest,
  UpdatePaymentMethodPayload,
  ListOfRecentInvoicesRequest,
} from '@cv/portal-cps-lib/payment/payment-methods/models';
import { PaymentMethodData } from '../types/';
import { formatPaymentData, formatPaymentFormData } from '@api/formatters/personalData';

export default class PaymentMethodsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getPaymentMethods(userId: string, locale, token) {
    const payload: PaymentMethodRequest = {
      environment: this.environment,
      headers: {
        Authorization: token,
        'Accept-Language': locale,
      },
      data: {
        userId,
      },
    };
    const { data } = await listPayments(payload);
    return { data: formatPaymentData(data as PaymentMethodResponse[]) };
  }

  async updatePaymentMethod(data: PaymentMethodData, userId: string, token: string) {
    const fullData: UpdatePaymentMethodPayload = formatPaymentFormData(data, userId);
    const payload: UpdatePaymentMethodRequest = {
      environment: this.environment,
      data: fullData,
      headers: {
        Authorization: token,
        'If-Match': '*',
      },
    };
    const response = await updatePayments(payload);
    return { data: response.data };
  }

  async getListOfRecentInvoices(numberOfInvoices: number, userId: string, token: string) {
    const payload: ListOfRecentInvoicesRequest = {
      environment: this.environment,
      data: {
        numberOfInvoices,
        userId,
      },
      headers: {
        Authorization: token,
        'If-Match': '*',
      },
    };

    const response = await getListOfRecentInvoices(payload);
    return response;
  }

  downloadInvoice(locale: string, accessToken: string, userId: string, invoiceId: string, invoiceType: string) {
    const readInvoiceUrl = config.get<string>('READ_INVOICE_URL');
    const apiUrl = readInvoiceUrl?.replace('{userId}', userId).replace('{invoiceId}', invoiceId);

    if (!apiUrl) {
      throw new Error('READ_INVOICE_URL is absent');
    }

    return fetch(apiUrl, {
      method: HttpMethodEnum.GET,
      headers: {
        'accept-language': locale,
        authorization: `Bearer ${accessToken}`,
        'CV-Correlation-Id': `CWP-${uuidv4()}`,
        accept: `application/${invoiceType}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.size === 0) {
          throw new Error('Failed to download the invoice');
        }

        const fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      });
  }
}
