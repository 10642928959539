import React from 'react';

import { CreatorForm } from '@components/EntryCreator';

import { User } from '../types';

import styles from './CreateAccount.module.css';

type Form = {
  name: string;
  formFields: {
    fieldName: string;
    fieldType: string;
    fieldPlaceholder: string;
    fieldValidation: object | null;
  }[];
};

export type CreateAccountFormData = {
  givenName: string;
  surname: string;
  surname2: string;
  email: string;
  primaryPhone: string;
  address?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
};

export type CreateAccountProps = {
  vin: string;
  user: User;
  formModel: object;
  labelTitle: string;
  labelVin: string;
  onSubmit: (data: CreateAccountFormData) => void;
  onBack: () => void;
};

const CreateAccount = ({ vin, user, formModel, labelTitle, labelVin, onSubmit, onBack }: CreateAccountProps) => {
  const form = { ...formModel } as Form;
  const initialValues: Record<string, string> = {
    ...user,
    address: user.billingPostalAddress1,
    address2: user.billingPostalAddress2,
    city: user.billingCity,
    state: user.billingStateProvince,
    postalCode: user.billingPostalCode,
  };

  // Make all fields static if user exists
  if (user._id) {
    form.formFields = form.formFields.map((field) => ({
      ...field,
      fieldType: 'static',
      fieldPlaceholder: '-',
      fieldValidation: null,
    }));
  } else {
    form.formFields.forEach((field) => {
      initialValues[field.fieldName] = initialValues[field.fieldName] || '';
    });
  }

  return (
    <div>
      <h1>{labelTitle}</h1>
      <div>
        {labelVin} #{vin}
      </div>
      <div className={styles.form}>
        <CreatorForm
          {...form}
          initialValues={initialValues}
          validateOnBlur={false}
          disableSubmitOnInvalid={!user?._id}
          onFormConfirm={onSubmit}
          onFormClose={onBack}
        />
      </div>
    </div>
  );
};

export default CreateAccount;
