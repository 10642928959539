import { Vehicle } from '@components/LinkVehicle/types';
import React from 'react';

type VehicleConditionProviderProps = {
  data: {
    isExpandAll: boolean;
    toggleExpandAll: (value: boolean) => void;
    vehicleData: Vehicle;
    displayViewMode: string;
    data: object;
  };
  children?: any;
};

export const VehicleConditionContext = React.createContext<Pick<VehicleConditionProviderProps, 'data'> | null>(null);

export const useVehicleConditionContext = () => {
  const conditionContext = React.useContext(VehicleConditionContext);

  if (conditionContext === null) {
    throw new Error('useVehicleConditionContext must be used within VehicleConditionProvider');
  }

  return conditionContext;
};

export const VehicleConditionProvider = ({ data, children }: VehicleConditionProviderProps) => (
  <VehicleConditionContext.Provider value={{ data }}>{children}</VehicleConditionContext.Provider>
);
