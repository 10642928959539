import { getDefaultEligiblePackages } from '@cv/portal-cps-lib/subscription';
import { SearchDefaultEligiblePackagesRequest } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import queryClient from '@api/queries/queryClient';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';

export type FetchDefaultEligiblePackagesOptions = {
  accessToken: string;
  tenantId: string;
  userId: string;
  vehicleId: string;
  environment: Environment;
  locale?: string;
};

export type DefaultEligiblePackagesResponse = APIResponse<EligiblePackagesResponse[]>;

export const fetchDefaultEligiblePackages = ({
  accessToken,
  tenantId,
  userId,
  vehicleId,
  environment,
  locale = 'en-US',
}: FetchDefaultEligiblePackagesOptions): Promise<DefaultEligiblePackagesResponse> => {
  const request: SearchDefaultEligiblePackagesRequest = {
    data: { userId, vehicleId },
    environment,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };
  return queryClient.fetchQuery(['getDefaultEligiblePackages', vehicleId], () => getDefaultEligiblePackages(request));
};
