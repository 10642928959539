import React from 'react';

import styles from './LanguagePicker.module.css';

type LanguagePickerItemProps = {
  index: number;
  onClick: (key: string) => void;
  onKeyDown?: (key: string) => void;
  data: {
    key: string;
    label: string;
  };
};

export default function LanguagePickerItem({
  index,
  onClick,
  onKeyDown,
  data: { key, label },
}: LanguagePickerItemProps): JSX.Element {
  return (
    <div
      className={styles['LanguagePickerItem']}
      role="button"
      tabIndex={index}
      onClick={() => onClick(key)}
      onKeyDown={() => onKeyDown && onKeyDown(key)}
      data-testid={`PickerItem-${index}`}
    >
      {label}
    </div>
  );
}
