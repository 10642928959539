import React from 'react';
import { Container, Heading, Link, Title } from '../../../styled-components/globalStyles';
import { UserProfileWrapper } from '@manageSubscription/UserProfile/userProfileStyles';
import Button from '../../../components/Button';
import FormikField from '../../../components/FormikField';
import { PhoneInput } from '@lib-components/PhoneNumberInput';
import Select from '../../../components/Select';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { CommonWebContent, NissanCpoWebContent, UserProfileWebContent } from '../..';
import { CommonFormAnalyticsWrapper } from '@lib-components/Analytics';
import FlowSmsCheckbox from '@components/SmsCheckbox/FlowSmsCheckbox';

export type UserProfileYupShapeType = {
  firstName: Yup.StringSchema;
  lastName: Yup.StringSchema;
  password?: Yup.StringSchema;
  confirmPassword?: Yup.StringSchema;
  addressLine1?: Yup.StringSchema;
  addressLine2?: Yup.StringSchema;
  zip?: Yup.StringSchema;
  city?: Yup.StringSchema;
  state?: Yup.StringSchema;
  phoneNumber?: Yup.StringSchema;
};

interface UserProfileProps {
  handleSubmit: () => void;
  initialValues: any;
  firstNameExisted: boolean;
  lastNameExisted: boolean;
  yupShape: UserProfileYupShapeType;
  userProfileWebContent: UserProfileWebContent;
  commonWebContent: CommonWebContent;
  nissanCpoWebContent: NissanCpoWebContent;
  navigateBack?: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
  handleSubmit,
  initialValues,
  firstNameExisted,
  lastNameExisted,
  yupShape,
  userProfileWebContent: {
    userProfileHeader,
    userProfileTitle,
    firstNameInputLabel,
    firstNamePlaceholder,
    lastNameInputLabel,
    lastNamePlaceholder,
    emailInputLabel,
    passwordInputLabel,
    passwordPlaceholder,
    confirmPasswordInputLabel,
    confirmPasswordPlaceholder,
    zipInputLabel,
    cityLabel,
    stateLabel,
    phoneNumberInputLabel,
    addressLineOneInputLabel,
    addressLineTwoInputLabel,
  },
  commonWebContent: { saveAndContinueButtonLabel, backButtonLabel },
  nissanCpoWebContent: { mobileNetworkOperatorLabel, nissanCpoMobileOperators, nissanCpoStates },
  navigateBack,
}) => {
  return (
    <Container>
      <Heading>{userProfileHeader}</Heading>
      <Title>{userProfileTitle}</Title>
      <UserProfileWrapper>
        <CommonFormAnalyticsWrapper name="User Profile">
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(yupShape)}
            onSubmit={handleSubmit}
            validateOnMount
            initialTouched={{
              firstName: true,
              lastName: true,
              password: true,
              confirmPassword: true,
              addressLine1: true,
              addressLine2: true,
              zip: true,
              city: true,
              state: true,
              phoneNumber: true,
              operator: true,
            }}
          >
            {(props: FormikProps<typeof initialValues>) => (
              <Form>
                <FormikField
                  name="firstName"
                  required
                  label={firstNameInputLabel}
                  placeholder={firstNamePlaceholder}
                  data-testid="firstName"
                  disabled={firstNameExisted}
                  readOnly={firstNameExisted}
                />
                <FormikField
                  name="lastName"
                  required
                  label={lastNameInputLabel}
                  placeholder={lastNamePlaceholder}
                  data-testid="lastName"
                  disabled={lastNameExisted}
                  readOnly={lastNameExisted}
                />
                <FormikField name="email" data-testid="email" disabled label={emailInputLabel} />
                <FormikField
                  name="password"
                  required
                  type="password"
                  label={passwordInputLabel}
                  placeholder={passwordPlaceholder}
                  data-testid="password"
                />
                <FormikField
                  name="confirmPassword"
                  required
                  type="password"
                  label={confirmPasswordInputLabel}
                  placeholder={confirmPasswordPlaceholder}
                  data-testid="confirmPassword"
                />
                <FormikField name="addressLine1" data-testid="addressLine1" required label={addressLineOneInputLabel} />
                <FormikField name="addressLine2" data-testid="addressLine2" label={addressLineTwoInputLabel} />
                <FormikField name="zip" data-testid="zipInputLabel" required label={zipInputLabel} />
                <FormikField name="city" data-testid="cityLabel" required label={cityLabel} />
                <FormikField
                  name="state"
                  required
                  label={stateLabel}
                  aria-label="State"
                  Component={Select}
                  options={nissanCpoStates?.split(',').map((state) => {
                    const [label, value] = state?.split('::');
                    return { label, value };
                  })}
                />
                <FormikField
                  name="operator"
                  aria-label="Operator"
                  required
                  label={mobileNetworkOperatorLabel}
                  Component={Select}
                  options={nissanCpoMobileOperators?.split(',').map((op) => {
                    const [label, value] = op?.split('::');
                    return { label, value };
                  })}
                />
                <FormikField
                  required
                  name="phoneNumber"
                  data-testid="phoneNumberInputLabel"
                  label={phoneNumberInputLabel}
                  Component={PhoneInput}
                />
                <FlowSmsCheckbox isSubmitting={props.isSubmitting} />
                <Button
                  data-testid="submit-button"
                  disabled={!(props.isValid && !props.isSubmitting)}
                  onClick={props.submitForm}
                  type="submit"
                  data-analyticseventname="CompleteProfile::SaveAndContinue::Button-Clicked"
                >
                  {saveAndContinueButtonLabel}
                </Button>
                {navigateBack && (
                  <Link
                    data-testid="navigate-back-link"
                    onClick={navigateBack}
                    data-analyticseventname="CompleteProfile::Back::Link-Clicked"
                  >
                    {backButtonLabel}
                  </Link>
                )}
              </Form>
            )}
          </Formik>
        </CommonFormAnalyticsWrapper>
      </UserProfileWrapper>
    </Container>
  );
};

export default UserProfile;
