import { useSelector } from 'react-redux';
import { VehicleHealthResponse } from '@cv/portal-rts-lib/health/models';
import { HealthReport } from '@cv/portal-rts-lib/health-reports/models';
import { IVehicle } from '../actions/vehicle';

type VehicleState = {
  vehicleReducer: {
    vehicleHealth: {
      data: VehicleHealthResponse;
      initial?: boolean;
    };
    vehicleHealthReports: HealthReport[];
    vehicle: IVehicle;
  };
};

const getVehicleState = (state: VehicleState) => {
  return state.vehicleReducer;
};

export const useVehicleSelector = (): IVehicle => {
  return useSelector(getVehicleState).vehicle;
};

export const useVehicleHealthSelector = () => {
  return useSelector(getVehicleState).vehicleHealth;
};

export const useVehicleHealthReportsSelector = () => {
  return useSelector(getVehicleState).vehicleHealthReports;
};
