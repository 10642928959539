import { getTokenExpirationTime } from '@utils/token';

const isTokenExpired = (token: string) => {
  if (!token) {
    return true;
  }

  const expireTime = getTokenExpirationTime(token);
  return expireTime < Date.now();
};

export default isTokenExpired;
