// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card-container .RichTextContainer.text-center img {
  max-width: 50%;
}

.VehicleConditionItem-vehicle-logo {
  max-width: 35%;
}
`, "",{"version":3,"sources":["webpack://./src/components/VehicleImage/VehicleImage.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".Card-container .RichTextContainer.text-center img {\n  max-width: 50%;\n}\n\n.VehicleConditionItem-vehicle-logo {\n  max-width: 35%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
