import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

import useDebounce from '@hooks/useDebounce';

interface PdfViewerProps {
  pdfUrl: string;
  defaultWidth?: number;
  defaultHeight?: number;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfUrl,
  defaultWidth = 612, // Letter size width
  defaultHeight = 792, // Letter size height
}) => {
  const [width, setWidth] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const debouncedWidth = useDebounce(width, 500);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  // Calculate height based on letter size aspect ratio (1:1.294)
  const height = debouncedWidth * 1.294;

  // Calculate zoom based on iframe dimensions
  const zoomWidth = (debouncedWidth / defaultWidth) * 100;
  const zoomHeight = (height / defaultHeight) * 100;
  const zoom = Math.min(zoomWidth, zoomHeight);

  return (
    <Box ref={containerRef} sx={{ width: '100%', margin: 'auto' }} data-testid="pdf-container">
      <iframe
        title="PDF Viewer"
        width={debouncedWidth}
        height={height}
        src={`${pdfUrl}#toolbar=0&zoom=${zoom}`}
        style={{ border: 'none', background: 'white' }}
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default PdfViewer;
