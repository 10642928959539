import React from 'react';

type ProductListProps = {
  products: Array<string>;
  maxProductsToDisplay: number;
};

export const ProductList = ({ products, maxProductsToDisplay }: ProductListProps) => {
  if (products.length) {
    return (
      <ul id={'product-details-container'}>
        {products.slice(0, maxProductsToDisplay).map((product: string, i) => (
          <li
            key={`product-${i}`}
            data-testid={'product-details'}
            className={'product-list-item'}
            style={{ margin: 5 }}
          >
            {product}
          </li>
        ))}
      </ul>
    );
  }

  return null;
};
