import { uniqWith } from 'lodash';
import {
  Discount,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SESSION_STORAGE_KEY } from '@components-lib';
import { CapableServiceDetails } from '@api';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

export const isAnnualTerm = ({ currentTermUnit, renewalTerm }: SubscribedPackage) =>
  currentTermUnit === TermUnit.Months && renewalTerm >= 12;

export const isPaidPackage = (sPkg: SubscribedPackage) => {
  const PAID_PKG_OFFER_TYPES = [OfferType.Base_discount, OfferType.Promotional];
  return (
    sPkg.packageType === PackageType.Regular &&
    (!sPkg?.discounts?.length || getDiscounts(sPkg.discounts, PAID_PKG_OFFER_TYPES).length > 0)
  );
};

const getDiscounts = (discounts: Discount[], offerTypes: OfferType[]) => {
  return discounts?.filter((d) => offerTypes.includes(d.offerType));
};

export const clearSubscriptionCache = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.SUBSCRIPTION);
};

export const mapPackagesWithServices = (
  capableServices: CapableServiceDetails[] | null,
  selectedVehiclePackages: SubscribedPackage[],
) => {
  if (!capableServices?.length || !selectedVehiclePackages?.length) {
    return [];
  }
  return selectedVehiclePackages.map((item: SubscribedPackage) => {
    return {
      ...item,
      packageName: item.marketingName,
      products: item.products?.map((product) => ({
        ...product,
        services: getPackagesServices(capableServices, product.id),
      })),
    };
  });
};

export const getPackagesServices = (capableServices: CapableServiceDetails[], productId: string) => {
  const matchingServices = capableServices?.find((service) => service.id === productId)?.services;
  return getCustomerFacingServices(matchingServices);
};

export const getCustomerFacingServices = (services: VehicleServiceDetails[] | undefined) => {
  if (!services?.length) {
    return [];
  }
  return (
    services
      .filter(
        (s) =>
          s.hasOwnProperty('provisioningStatus') &&
          !s.provisioningStatus.startsWith('DEPROVIONING_') &&
          s.customerFacing,
      )
      // Some of the services don't have displayOrder, setting them to max order
      .sort((a, b) => (a.displayOrder || 999) - (b.displayOrder || 999))
  );
};

export const getUniqueVehicleServices = (customerFacingServices: VehicleServiceDetails[]): VehicleServiceDetails[] => {
  return uniqWith(
    customerFacingServices,
    (arrVal, othVal) =>
      arrVal.vehicleServiceName === othVal.vehicleServiceName || arrVal.marketingName === othVal.marketingName,
  );
};
