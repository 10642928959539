import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import styles from './InputRadio.module.css';
import ControlLabel from '@components/ControlLabel';
import { Radio } from '@mui/material';

type OptionType = {
  description?: string;
  label: string;
  value: string;
  analyticsEventName?: string;
};

type InputSelectProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  options: Array<OptionType>;
  onChange: () => void;
};

const InputRadio = ({
  name = '',
  value,
  onChange,
  options,
  className = '',
  helpers = null,
}: InputSelectProps): JSX.Element => {
  const getInitialValue = options.find((option: OptionType) => option.value === value || option.label === value);
  const [selectedOption, setSelectedOption] = useState(getInitialValue);

  const handleChange = (selectedOption: OptionType) => {
    setSelectedOption(selectedOption);
    onChange?.(selectedOption);

    // Helpers is an object from formik useField
    // it is needed to change state in Formik for custom inputs
    if (helpers) {
      const { setValue } = helpers;
      setValue(selectedOption.value);
    }
  };

  // immediately update value if it was selected based on label
  // this fixes some translation weirdness
  useEffect(() => {
    if (getInitialValue && value !== getInitialValue.value) {
      handleChange(getInitialValue);
    }
  }, [value, getInitialValue]);

  const inputClassName = clsx(styles.InputRadio, className);

  return (
    <>
      {options.map(({ value, label, description, analyticsEventName }) => {
        return (
          <div className={inputClassName} key={value}>
            <ControlLabel
              label={label}
              labelPlacement="right"
              control={
                <Radio
                  sx={(theme) => ({
                    padding: '0',
                    marginRight: theme.spacing(1),
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  })}
                  checked={selectedOption?.value === value}
                  onClick={() => handleChange({ label, value })}
                  data-analyticseventname={analyticsEventName}
                  name={name}
                />
              }
            />
            <div className={styles['InputRadio-description']}>{description}</div>
          </div>
        );
      })}
    </>
  );
};

export default InputRadio;
