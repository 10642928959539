import { useMemo } from 'react';
import useMake from '@hooks/useMake';
import { LabelObject } from '@utils/labels';
import usePreferencesSelector from '@redux/selectors/preferences';

const useDynamicQuestions = (labelsQuestions: LabelObject) => {
  const make = useMake();
  const preferences = usePreferencesSelector();

  return useMemo(() => {
    const dynamicValues: Record<string, string> = {
      Brand: make,
    };

    const replaceDynamicValues = (question: string) =>
      question.replaceAll(
        /{{([^{}]*)}}/g,
        (match: string, foundKey: string) => dynamicValues[foundKey] || preferences[foundKey] || match,
      );

    return Object.entries(labelsQuestions).reduce<LabelObject>(
      (questions, [key, { primary, secondary = '', ...question }]) => {
        return {
          ...questions,
          [key]: {
            ...question,
            primary: replaceDynamicValues(primary),
            secondary: replaceDynamicValues(secondary),
          },
        };
      },
      {},
    );
  }, [labelsQuestions, make, preferences]);
};

export default useDynamicQuestions;
