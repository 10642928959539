import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect, { Props, components } from 'react-select';
import { FaCaretDown } from 'react-icons/fa';
import { FieldHelperProps } from 'formik';

type OptionType = {
  value?: string;
  label?: string;
};

type customProps = {
  nativeDropdown: boolean;
  helpers: FieldHelperProps<string>;
  onChange: (arg: OptionType) => void;
  error?: boolean;
};

type InputSelectProps = Omit<Props, 'onChange'> & OptionType & customProps;

const DropdownIndicator = (props: any) => {
  const { DropdownIndicator } = components;
  return (
    DropdownIndicator && (
      <DropdownIndicator {...props}>
        <FaCaretDown />
      </DropdownIndicator>
    )
  );
};

const Select = ({
  name = '',
  value,
  onChange,
  options = [],
  helpers = null,
  nativeDropdown = false,
  error,
  id,
  ...restProps
}: InputSelectProps) => {
  const selectStyles = useMemo(
    () => ({
      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
      control: (base) => ({
        ...base,
        borderColor: error ? 'red' : '#ddd',
        '&:hover': {
          borderColor: error ? 'red' : '#ddd',
        },
      }),
    }),
    [error],
  );
  const getInitialValue = (options as OptionType[]).find((option) => option.value === value || option.label === value);
  const [selectedOption, setSelectedOption] = useState(getInitialValue);

  const handleChange = (option: OptionType) => {
    setSelectedOption(option);

    // Helpers is an object from formik useField
    // it is needed to change state in Formik for custom inputs
    if (helpers) {
      const { setValue } = helpers;
      setValue(option.value);
    } else {
      onChange(option);
    }
  };

  // immediately update value if it was selected based on label
  // this fixes some translation weirdness
  useEffect(() => {
    if (getInitialValue && value !== getInitialValue.value) {
      handleChange(getInitialValue);
    }
  }, [value, getInitialValue]);

  return (
    <ReactSelect
      options={options}
      value={selectedOption}
      components={{ ...(!nativeDropdown && { DropdownIndicator }) }}
      onChange={onChange && handleChange}
      classNamePrefix="input-select"
      menuPortalTarget={document.body}
      styles={selectStyles}
      {...restProps}
      inputId={id}
    />
  );
};

export default Select;
