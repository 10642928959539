import { ComponentRoutes, FlowActions } from '../../types';
import { CompoundState } from '../componentFlow/Types';
import { Middleware } from '../../flowMiddleware';

export default (middleware: Middleware) =>
  ({
    initial: ComponentRoutes.managePromoCodePackages,
    states: {
      [ComponentRoutes.managePromoCodePackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.managePromoCodePackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.manageAddOns,
          [FlowActions.cancel]: ComponentRoutes.managePaidPackages,
        },
      },
      [ComponentRoutes.manageAddOns]: {
        ...middleware.getComponentFlow(ComponentRoutes.manageAddOns),
        on: {
          [FlowActions.proceed]: ComponentRoutes.payment,
          [FlowActions.return]: ComponentRoutes.managePaidPackages,
        },
      },
      [ComponentRoutes.managePaidPackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.managePaidPackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.payment,
          [FlowActions.return]: ComponentRoutes.managePromoCodePackages,
        },
      },
      [ComponentRoutes.payment]: {
        ...middleware.getComponentFlow(ComponentRoutes.payment),
        on: {
          [FlowActions.proceed]: ComponentRoutes.reviewOrder,
          [FlowActions.return]: ComponentRoutes.managePromoCodePackages,
        },
      },
      [ComponentRoutes.reviewOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.reviewOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.confirmOrder,
          [FlowActions.return]: ComponentRoutes.payment,
        },
      },
      [ComponentRoutes.confirmOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.confirmOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.managePromoCodePackages,
        },
      },
    },
  } as CompoundState);
