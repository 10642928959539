import React from 'react';
import ContentfulComponent from '@app/types/ContentfulComponent';
import ContentRenderer from '@components/ContentRenderer';

type InfoCardContainerProps = {
  infoBoxContent: Array<ContentfulComponent>;
  name: string;
};

function InfoCardContainer({ infoBoxContent, name }: InfoCardContainerProps): JSX.Element {
  return <ContentRenderer name={name} content={infoBoxContent} />;
}

export default InfoCardContainer;
