import { IVehicle } from '@redux/actions';
import { USER_EVENTS } from '@redux/actions/user';
import { SubscribedServices } from '../../api/types';
import UserCalendar from '@customTypes/UserCalendar';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { ROOT_EVENTS } from '@app/reducers';

export type VehiclePackages = {
  activeType: boolean;
  packages: Array<SubscribedPackage>;
  subscribedServiceIds: Array<string>;
  vehicleId: string;
};

export interface PromoCodeCriteria {
  pkgTier: number;
  term: number;
  pkgCount: number;
}

export interface IUserState {
  userId?: string;
  vehicleIds?: string[];
  vehicleVins?: IVehicle[];
  isPinConfigured?: boolean;
  subscribedServices?: SubscribedServices[];
  pinLockedTimestamp?: number;
  packages: Array<VehiclePackages>;
  promoCode: string;
  promoCodeCriteria?: PromoCodeCriteria;
  calendar: UserCalendar | null | undefined;
  calendarList: UserCalendar[] | null;
}

const initState: IUserState = {
  userId: '',
  vehicleIds: [],
  vehicleVins: [],
  isPinConfigured: false,
  subscribedServices: [],
  pinLockedTimestamp: 0,
  packages: [],
  promoCode: '',
  calendar: undefined,
  calendarList: null,
};

export interface IUserAction {
  type: USER_EVENTS | ROOT_EVENTS;
  payload?: any;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IUserState = initState, action: IUserAction) => {
  switch (action.type) {
    case USER_EVENTS.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case USER_EVENTS.SET_VEHICLE_IDS:
      return {
        ...state,
        vehicleIds: action.payload,
      };
    case USER_EVENTS.SET_VEHICLE_VINS:
      return {
        ...state,
        vehicleVins: action.payload,
      };
    case USER_EVENTS.SET_USER_PIN_STATUS:
      return {
        ...state,
        isPinConfigured: action.payload,
      };
    case USER_EVENTS.SET_SUBSCRIBED_SERVICES:
      return {
        ...state,
        subscribedServices: action.payload,
      };
    case ROOT_EVENTS.CLEAR_SUBSCRIPTIONS:
      return {
        ...state,
        subscribedServices: [],
      };
    case USER_EVENTS.SET_USER_PIN_LOCKED_TIMESTAMP:
      return {
        ...state,
        pinLockedTimestamp: action.payload,
      };
    case USER_EVENTS.SET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case USER_EVENTS.SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case USER_EVENTS.SET_PROMO_CODE_CRITERIA:
      return {
        ...state,
        promoCodeCriteria: action.payload,
      };
    case USER_EVENTS.SET_CALENDAR:
      return {
        ...state,
        calendar: action.payload,
      };
    case USER_EVENTS.SET_CALENDAR_LIST:
      return {
        ...state,
        calendarList: action.payload,
      };
    default:
      return state;
  }
};
