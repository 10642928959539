import React from 'react';
import clsx from 'clsx';
import { ContentfulImage } from '@cv/webframework-react-components';
import useExpireRenewText from '@hooks/useExpireRenewText';
import { PackageItemContenfulProps } from './SubscriptionInformation';
import ProductItem from './ProductItem';
import s from './SubscriptionInformation.module.css';
import { isPackageUpcoming } from '@utils/orderPackages';
import PackageSubscriptionType from '@components/SubscriptionOverview/PackageSubscriptionType';
import { CapablePackage } from '@customTypes/subscription';

type PackageItemProps = PackageItemContenfulProps & {
  packageItem: CapablePackage;
};

const PackageItem = ({
  packageItem,
  trialSubscriptionLabel,
  paidSubscriptionLabel,
  startsLabel,
  endsLabel,
  renewsLabel,
  beginsLabel,
  activeIcon,
  upcomingIcon,
  infoIcon,
  renewsAnnually,
  renewsMonthly,
  endOfTermLabel,
  promoTermLabel,
  productIcon: serviceIcon,
}: PackageItemProps) => {
  const { marketingName, products, autoRenew, termEndDate, termStartDate, termServiceEndDate, termServiceStartDate } =
    packageItem;

  const isUpcoming = isPackageUpcoming(packageItem);

  const expireRenewText = useExpireRenewText();
  const packageExpirDate = expireRenewText({
    autoRenew,
    renewLabel: isUpcoming ? beginsLabel : renewsLabel,
    expiresLabel: isUpcoming ? startsLabel : endsLabel,
    termEndDate: isUpcoming ? termStartDate : termEndDate,
    termServiceEndDate: isUpcoming ? termServiceStartDate : termServiceEndDate,
  });

  const statusIcon = (isUpcomingItem: boolean): JSX.Element | undefined | null | false => {
    const iconInUse = isUpcomingItem ? upcomingIcon : activeIcon;
    return iconInUse && <ContentfulImage className={s.packageIcon} imageData={iconInUse} />;
  };
  const productIcon = serviceIcon && <ContentfulImage className={s.productIcon} imageData={serviceIcon} />;

  return (
    <li className={clsx(s.packageItem, isUpcoming && s.upcoming)} key={packageItem.marketingName + '_package'}>
      <div className={s.packageHeading}>
        <h3 className={s.packageTitle}>
          {statusIcon(isUpcoming)}
          {marketingName}
        </h3>
        <time>{packageExpirDate}</time>
      </div>

      <PackageSubscriptionType subscribedPackage={packageItem} />

      <ul className={s.productList}>
        {products.map((product) => {
          const productItemProps = {
            product,
            infoIcon: infoIcon ? <ContentfulImage className={s.infoIcon} imageData={infoIcon} /> : 'i',
            productIcon,
            statusIcon: statusIcon(isUpcoming),
          };
          return <ProductItem {...productItemProps} key={packageItem.marketingName + product.id} />;
        })}
      </ul>
    </li>
  );
};
export default PackageItem;
