export const mexicanStates = {
  'Ags.': 'AG',
  'B.C.': 'BC',
  'B.C.S.': 'BS',
  'Camp.': 'CM',
  'Chis.': 'CS',
  'Chih.': 'CH',
  'Coah.': 'CO',
  'Col.': 'CL',
  CDMX: 'DF',
  'Dgo.': 'DG',
  'Gto.': 'GT',
  'Gro.': 'GR',
  'Hgo.': 'HG',
  'Jal.': 'JA',
  'Edomex.': 'EM',
  'Méx.': 'EM',
  'Mich.': 'MI',
  'Mor.': 'MO',
  'Nay.': 'NA',
  'N.L.': 'NL',
  'Oax.': 'OA',
  'Pue.': 'PU',
  'Qro.': 'QT',
  'Q. Roo.': 'QR',
  'Q.R.': 'QR',
  'S.L.P.': 'SL',
  'Sin.': 'SI',
  'Son.': 'SO',
  'Tab.': 'TB',
  'Tamps.': 'TM',
  'Tlax.': 'TL',
  'Ver.': 'VE',
  'Yuc.': 'YU',
  'Zac.': 'ZA',
};
