import React from 'react';
import { Container, Heading, LineSeparator, Title } from '../../styled-components/globalStyles';
import { PrivacyPolicyLink, Image, Description } from './styles';
import { OrderSummary } from '../Order/OrderSummary';
import { Pricing } from '../Order/styles';
import { OrderSummaryInfo, PackageSubscription } from '../Types';
import { SubscriptionProps, WebContent } from '../Subscription';
import Markdown from '../../components/Markdown';
import { OperationType } from '../../Router/flows/componentFlow/toyota/operationTypes';
import { ToyotaFlowContext } from '../../Router/flows/componentFlow/Types';
import StateMachineWrapper from '../../payment/components/Payment/StateMachineWrapper';
import { SubmitCallback } from '../../payment/Types';

interface PaymentCookiesProps {
  subTotal?: number;
  total?: number;
  navigateCancel: () => void;
  submitCallback: SubmitCallback;
  subscriptionProps: SubscriptionProps;
  orderSummaryInfo: OrderSummaryInfo;
  content: WebContent;
  location: string;
  packageSubscriptions: PackageSubscription[];
  tax?: number;
  taxItems?: ToyotaFlowContext['taxItems'];
  operationType: OperationType;
}

const signsAfterComma = 2;

const ToyotaPayment: React.FC<PaymentCookiesProps> = ({
  subTotal = 0.0,
  total = 0.0,
  navigateCancel,
  submitCallback,
  subscriptionProps,
  orderSummaryInfo,
  content,
  location,
  taxItems,
  tax = 0.0,
  operationType,
}) => {
  const {
    subTotalLabel,
    taxLabel,
    totalLabel,
    taxDescription,
    renewalInfo,
    showTaxSplit,
    commonWebContent,
    paymentInfoLabel,
    privacyPolicyLinkLabel,
    assets,
  } = content;

  return (
    <div>
      <Container>
        <div>
          <Image assets={assets} name="appLogo" size="75px" suppressMissingImage />
          <Heading>{paymentInfoLabel}</Heading>
          <LineSeparator />

          {operationType === OperationType.purchase && (
            <OrderSummary
              orderSummaryInfo={orderSummaryInfo}
              content={content}
              subscriptionProps={subscriptionProps}
              location={location}
            >
              <Pricing>
                {subTotalLabel}
                <span>{`${commonWebContent.currencyLabel}${subTotal.toFixed(signsAfterComma)}`}</span>
              </Pricing>
              {!!showTaxSplit &&
                taxItems?.map((item) => (
                  <Pricing key={item.name}>
                    {item.name}
                    <span>{`${commonWebContent.currencyLabel}${item.amount.toFixed(signsAfterComma)}`}</span>
                  </Pricing>
                ))}
              {taxLabel && (
                <Pricing>
                  {taxLabel}
                  <span>{`${commonWebContent.currencyLabel}${tax.toFixed(signsAfterComma)}`}</span>
                </Pricing>
              )}
              {totalLabel && (
                <Pricing>
                  <Title>{totalLabel}</Title>
                  <Title>{`${commonWebContent.currencyLabel}${total.toFixed(signsAfterComma)}`}</Title>
                </Pricing>
              )}
              <Description>{taxDescription}</Description>
              <Description>
                <Markdown>{renewalInfo}</Markdown>
              </Description>
            </OrderSummary>
          )}

          {privacyPolicyLinkLabel && (
            <PrivacyPolicyLink href={subscriptionProps.privacyPolicyUrl} target={'_blank'}>
              {privacyPolicyLinkLabel}
            </PrivacyPolicyLink>
          )}
          <StateMachineWrapper
            navigateCancel={navigateCancel}
            submitCallback={submitCallback}
            subscriptionInfo={{ totalAmount: total.toString() }}
          />
        </div>
      </Container>
    </div>
  );
};

export default ToyotaPayment;
