import React from 'react';
import Button from '@lib-components/Button';
import { styled } from '@mui/material';
import { usePaymentContext } from '../PaymentContext';

const Container = styled('div')`
  display: grid;
  place-items: center;
  grid-row-gap: 20px;
`;

const Header = styled('h3')`
  max-width: 24rem;
  text-align: center;
`;

function NoPaymentsMethods() {
  const { content } = usePaymentContext();

  const { noPaymentsHeader, noPaymentsSubscribeLink, subscribeNowButtonLabel } = content;
  return (
    <Container>
      <Header>{noPaymentsHeader}</Header>

      {noPaymentsSubscribeLink && (
        <Button variant="outlined" href={noPaymentsSubscribeLink}>
          {subscribeNowButtonLabel}
        </Button>
      )}
    </Container>
  );
}

export default NoPaymentsMethods;
