export enum LOADING_ACTIONS {
  SET_LOADING_STATUS = 'SET_LOADING_STATUS',
}

export const setLoadingStatus = (data: boolean) => {
  return {
    type: LOADING_ACTIONS.SET_LOADING_STATUS,
    data,
  };
};
