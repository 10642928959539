import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { getUserPreferences, updateUserPreferences } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service';
import { getPreferencesRequest, preferencesData } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import config from '@config/config';

type PreferencesArgs = {
  vin: string;
  accessToken: string;
  userId: string;
  locale: string;
};

export default class NotificationPreferences {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  updatePreferences({ accessToken, locale, ...data }: PreferencesArgs & preferencesData) {
    const updatePreferencesUrl = config.get<string>('UPDATE_PREFERENCES_URL');
    const request: getPreferencesRequest = {
      url: updatePreferencesUrl,
      data,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    return updateUserPreferences(request);
  }

  getPreferences({ locale, vin, userId, accessToken }: PreferencesArgs) {
    const readPreferencesUrl = config.get<string>('READ_PREFERENCES_URL');
    const request: getPreferencesRequest = {
      url: readPreferencesUrl,
      data: {
        vin,
        // @ts-ignore
        // TODO: add this to library types
        userId,
      },
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };
    return getUserPreferences(request);
  }
}
