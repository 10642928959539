import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { DoorsbyVehicleIdRequest, UpdateDoorsByVehicleIdRequest } from '@cv/portal-rts-lib/doors/models/doors';
import { getDoorsByVehicleID, updateDoorsByVehicleID } from '@cv/portal-rts-lib/doors';
import { DOOR_ID_ALL, RemoteCommand, DOOR_ID } from '@components/Map/constants';
import { DoorState } from '@cv/portal-rts-lib/doors/enums';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { isSuccessHttpCode } from '@cv/portal-common-lib/ajax/helper';

export type DoorUnlockRequestPayload = {
  validationCallback: () => PollingStatus;
  successHandlerCallback: () => void;
  errorHandlerCallback: (result?: object) => void;
  remoteCommand?: string;
  vehicleId?: string;
};
export default class DoorLockUnlockService {
  private environment: Environment;
  constructor(environment = Environment.DEVINT) {
    this.environment = environment;
  }
  makeRemoteDoorAPICall(payload: DoorUnlockRequestPayload, accessToken: string, id_token: string, vehicleId: string) {
    const updateRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId: vehicleId,
      },
      headers: {
        Authorization: accessToken,
        id_token,
      },
      data: {
        doors: [
          {
            id: DOOR_ID_ALL,
            locks: [
              {
                state: payload.remoteCommand === RemoteCommand.DOOR_LOCK ? DoorState.LOCK : DoorState.UNLOCK,
                id: DOOR_ID,
              },
            ],
          },
        ],
      },
    } as UpdateDoorsByVehicleIdRequest;

    const request = {
      environment: this.environment,
      pathParams: {
        vehicleId: vehicleId,
      },
      headers: {
        Authorization: accessToken,
      },
    } as DoorsbyVehicleIdRequest;
    return updateDoorsByVehicleID(updateRequest)
      .then((result) => {
        isSuccessHttpCode(result.status)
          ? initiatePolling({
              pollingFunc: getDoorsByVehicleID.bind(null, request),
              validationCallback: payload.validationCallback,
              successCallback: payload.successHandlerCallback,
              errorCallback: payload.errorHandlerCallback,
            })
          : payload.errorHandlerCallback();
      })
      .catch((err) => {
        payload.errorHandlerCallback(err);
      });
  }
}
