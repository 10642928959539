import React from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@components-lib/components/Button';
import { useHeadlineStyles, useTextStyles, useActionButtonStyles } from './styles';
import { extractPrimaries, LabelObject } from '@utils/labels';
import config from '@config/config';
import urlSearchParams from '@utils/urlSearchParams';

export type SuccessProps = {
  labels: LabelObject;
};

const Success = ({ labels }: SuccessProps) => {
  const { title, description, loginButtonText } = extractPrimaries(labels);
  const headlineStyles = useHeadlineStyles();
  const textStyles = useTextStyles();
  const buttonStyles = useActionButtonStyles();

  const loginUrl = config.getOemValue('LOGIN_FLOW_URL');
  const platform = urlSearchParams.get('platform');
  const loginFlow = `${loginUrl}/?platform=${platform}`;

  return (
    <Stack p="1rem" sx={{ height: '100vh' }}>
      <Typography variant="h1" component="h1" className={headlineStyles.headline}>
        {title}
      </Typography>
      <Stack width={1} height={1} justifyContent="space-between">
        <Typography component="p" className={textStyles.text}>
          {description}
        </Typography>
        <Button className={buttonStyles.actionButton} sx={{ marginBottom: '1rem' }} href={loginFlow}>
          {loginButtonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default Success;
