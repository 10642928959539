// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoBox-container {
  display: flex;
  margin: -0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--theme-color-border);
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
}

.InfoBox-container > * {
  width: 100%;
  margin: 0.5rem;
}

.InfoItem-container {
  font-size: var(--theme-label-font-size);
}

.InfoItem-value {
  font-weight: 600;
  font-size: var(--theme-paragraph-font-size);
  overflow: hidden;
  text-overflow: ellipsis;
}

.InfoBox-button {
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: -0.75rem;
  cursor: pointer;
}

.InfoBox-edit-button {
  top: -0.75rem;
}

.InfoBox-edit-icon {
  font-size: 1rem;
  color: var(--theme-color-primary);
}

.InfoBox-edit-container {
  position: relative;
}

.InfoBox-remove-button {
  top: -0.75rem;
}

.InfoBox-modal-content {
  font-size: 0.8rem;
  margin: 0.7rem auto;
}

.InfoBox-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .InfoBox-container {
    flex-direction: row;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/InfoBox/InfoBox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;EACrB,kDAAkD;EAClD,kBAAkB;EAClB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,2CAA2C;EAC3C,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".InfoBox-container {\n  display: flex;\n  margin: -0.5rem;\n  margin-bottom: 1.5rem;\n  border-bottom: 1px solid var(--theme-color-border);\n  position: relative;\n  flex-wrap: wrap;\n  flex-direction: column;\n}\n\n.InfoBox-container > * {\n  width: 100%;\n  margin: 0.5rem;\n}\n\n.InfoItem-container {\n  font-size: var(--theme-label-font-size);\n}\n\n.InfoItem-value {\n  font-weight: 600;\n  font-size: var(--theme-paragraph-font-size);\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.InfoBox-button {\n  border: none;\n  padding: 0;\n  background-color: transparent;\n  position: absolute;\n  right: -0.75rem;\n  cursor: pointer;\n}\n\n.InfoBox-edit-button {\n  top: -0.75rem;\n}\n\n.InfoBox-edit-icon {\n  font-size: 1rem;\n  color: var(--theme-color-primary);\n}\n\n.InfoBox-edit-container {\n  position: relative;\n}\n\n.InfoBox-remove-button {\n  top: -0.75rem;\n}\n\n.InfoBox-modal-content {\n  font-size: 0.8rem;\n  margin: 0.7rem auto;\n}\n\n.InfoBox-inline {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n@media (--viewport-s) {\n  .InfoBox-container {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
