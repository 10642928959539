import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import axios from 'axios';
import config from '@config/config';
import urlSearchParams from '@utils/urlSearchParams';
import { v4 as uuidv4 } from 'uuid';

export default class TokenLoginService {
  constructor() {}

  loginToken(tokenId: string, otp?: string) {
    const tokenLoginUrl: string = config.getOemValue('TOKEN_LOGIN_URL') || '';

    const request: APIRequest = {
      url: tokenLoginUrl,
      method: HttpMethodEnum.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'CV-Correlation-Id': `CWP-${uuidv4()}`,
        'cv-client-id': config.getOemValueBySuffix('MARKETING_CLIENT_ID', urlSearchParams.get('page')),
      },
      data: {
        tokenId,
        ...(otp && { otp }),
      },
    };

    return axios(request);
  }
}
