import React, { useMemo } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import ContentfulComponent from '@customTypes/ContentfulComponent';
import { FormikInputField } from '@components/FormikFields';
import { Button } from '@components-lib';
import Widget from '@components/Widget/Widget';
import styles from '@components/ResetPassword/ResetPassword.module.css';
import { getPasswordValidation } from '@utils/passwordRules';
import { ServerLabel } from '@utils/labels';
import PasswordTooltipContent from '@components/PasswordTooltipContent';

const initialValues = {
  password: '',
  confirmPassword: '',
};

interface UpdatePinForm {
  password: string;
  confirmPassword: string;
}

type UpdatePasswordSetNewPasswordFormProps = {
  onPasswordUpdate: (payload: any, setSubmitting?: (isSubmitting: boolean) => void) => void;
  labels: Record<string, string>;
  passwordRules: ContentfulComponent;
};

const UpdatePasswordSetNewPassword = ({
  onPasswordUpdate,
  labels,
  passwordRules,
}: UpdatePasswordSetNewPasswordFormProps) => {
  const validationSchema = useMemo(() => {
    const validation = getPasswordValidation(passwordRules.content as ServerLabel[]);
    return yup.object().shape(validation);
  }, [passwordRules]);
  const onSubmit = (values: UpdatePinForm, setSubmitting?: (isSubmitting: boolean) => void) => {
    onPasswordUpdate(values.confirmPassword, setSubmitting);
  };

  return (
    <Widget title={labels.setPasswordTitle} titleAlignment="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        {(props: FormikProps<UpdatePinForm>) => (
          <Form onSubmit={props.handleSubmit}>
            <div className={styles['reset-field']}>
              <FormikInputField
                name="password"
                label={labels.newPassword}
                type="password"
                helpCallout={<PasswordTooltipContent tooltipLabels={passwordRules} />}
              />
            </div>
            <div className={styles['reset-field']}>
              <FormikInputField name="confirmPassword" label={labels.confirmNewPassword} type="password" />
            </div>
            <div className={styles['actions']}>
              <Button
                variant="contained"
                className={styles['button']}
                disabled={props.isSubmitting}
                onClick={props.submitForm}
                type="submit"
              >
                {labels.savePassword}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Widget>
  );
};

export default UpdatePasswordSetNewPassword;
