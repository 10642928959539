import React, { useContext } from 'react';
import { AssetObject, ServerAsset } from '@utils/assets';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import { getLabels, getDescriptions, ServiceDescriptions, getAssets, getHierarchy } from './cancellationUtils';
import { OFFER_DETAILS_DICT_KEY } from '@components/SubscriptionWrapper/constants';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import useLabels from '@hooks/useLabels';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';

type CancelLabels = {
  activateTrial: string;
  andLabel: string;
  assets: AssetObject;
  benefits: string;
  continueButton: string;
  currentPriceLabel: string;
  days: string;
  defaultLongDescription: string;
  defaultShortDescription: string;
  descriptions: ServiceDescriptions;
  expires: string;
  expiriesLabel: string;
  getThisDealLabel: string;
  hideCompleteList: string;
  keepButton: string;
  months: string;
  nextBestOfferEnrollNow: string;
  nextBestOfferHeader: string;
  nextBestOfferPromoLabel: string;
  nextBestOfferSubheader: string;
  offerDetailLabels: Record<string, string>;
  offerDetailsHeader: string;
  offerDetailsLabel: string;
  packageDetailsLabel: string;
  paid: string;
  paidOnly: string;
  promotionalMessageHeader: string;
  promotionNotification: string;
  promotionScreenHeader: string;
  promotionScreenSubheader: string;
  promotionScreenSubHeader: string;
  regularPriceLabel: string;
  renew: string;
  seeCompleteList: string;
  selectSubscription: string;
  servicesHierarchy: string[];
  starts: string;
  trial: string;
  trialOnly: string;
  trialPaidTogether: string;
  years: string;
  monthlyLabel: string;
  annuallyLabel: string;
  cancellationReasons: Record<string, string>;
  currencyLabel: string;
  importantCancellationInfo__Annual: string;
  importantCancellationInfo__Monthly: string;
  cancelPackagesHeader: string;
  reasonForCanceling: string;
  reasonForCancellingDescription: string;
  reasonForCancellingPostScript: string;
  reasonLabel: string;
  cancelEffectiveLabel: string;
  moreDetailsLabel: string;
  reviewOrder: string;
  paymentMethod: string;
  billingAddress: string;
  subscriptionSummary: string;
  totalAmount: string;
  totalTax: string;
  subTotal: string;
  previewOrderAgree: string;
  orderPreviewLegal: string;
  lastMessage: string;
  paymentDate: string;
  subscribeNow: string;
  refund: string;
  refundTaxLabel: string;
  refundDueLabel: string;
  subHeaders: Record<string, string>;
  assetsRaw: ServerAsset[];
  subscriptionLabels: ContentfulLabelType;
  paymentHeader: string;
  paymentSubHeader: string;
  reviewOrderTerms: string;
  orderSummary: string;
  saveAndContinue: string;
  errorTitle: string;
  errorMessage: string;
  finalHeaderOrder: string;
  finalHeaderCancel: string;
  finalSubHeaderOrder: string;
  finalSubHeaderCancel: string;
  finalMessageOrder: string;
  finalMessageCancel: string;
  btnGoToProfileText: string;
  agreementType: AgreementType;
};

const CancelLabelsContext = React.createContext<CancelLabels | null>(null);

type CancelLabelsContainerProps = {
  contentSections: ContentfulComponent[];
  children: React.ReactNode;
};

const CANCEL_REASONS_DICT_KEY = 'cancellationReasons';

const CancelLabelsContainer = ({ contentSections, children }: CancelLabelsContainerProps) => {
  const labelsContent = getLabels(contentSections);
  const labels: CancelLabels = useLabels(labelsContent).getAllPrimaries() as unknown as CancelLabels;
  const offerDetailLabels = useLabels(
    findDictionary(contentSections as ContentfulLabelType[], OFFER_DETAILS_DICT_KEY),
  ).getAllPrimaries();
  const cancellationReasons = useLabels(
    findDictionary(contentSections as ContentfulLabelType[], CANCEL_REASONS_DICT_KEY),
  ).getAllPrimaries();

  const subHeaders = useLabels(
    findDictionary(contentSections as ContentfulLabelType[], 'subHeaders'),
  ).getAllPrimaries();

  const subscriptionLabels = contentSections.find((section) => section.dictionaryKey === 'manage-subscription-dict');
  const assetsRaw = contentSections.filter((section) => section.componentType === 'portalAsset');

  const descriptions = getDescriptions(contentSections);

  const servicesHierarchy = getHierarchy(contentSections);

  const assets = getAssets(contentSections);

  return (
    <CancelLabelsContext.Provider
      value={{
        ...labels,
        descriptions,
        assets,
        offerDetailLabels,
        servicesHierarchy,
        cancellationReasons,
        subHeaders,
        subscriptionLabels,
        assetsRaw,
      }}
    >
      {children}
    </CancelLabelsContext.Provider>
  );
};

export default CancelLabelsContainer;

export const useCancelLabels = () => {
  const labels = useContext(CancelLabelsContext);
  if (labels === null) {
    throw new Error("'useCancelLabels' should be used within CancelLabelsContext");
  }

  return labels;
};
