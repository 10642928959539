import { useDispatch } from 'react-redux';
import { LOADING_ACTIONS } from '@redux/actions';
import useLoadingSelector from '../selectors/loading';

const useLoadingActions = () => {
  const dispatch = useDispatch();
  const loadingState = useLoadingSelector();

  const toggleLoading = (isLoading?: boolean) => {
    dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: isLoading ?? !loadingState.isLoading });
  };

  const showLoading = () => {
    dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: true });
  };

  const hideLoading = () => {
    dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: false });
  };

  return {
    toggleLoading,
    showLoading,
    hideLoading,
  };
};

export default useLoadingActions;
