import { styled } from '@mui/material';

export const Card = styled('div')<{ main?: boolean }>`
  background-color: #fff;
  border: ${({ theme }) => theme.palette.portalTheme.themeCardSizeBorder || '1px'} solid
    ${({ theme }) => theme.palette.portalTheme.themeCardColorBorder || '#ddd'};
  font-size: var(--theme-paragraph-font-size);
  margin: 0.7rem;
  ${(props) =>
    props.main &&
    `
        border: none;
        border-top: 8px solid ${({ theme }) => theme.cardColorBorderTop || theme.themeCardColorBorderMain};`}

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 550px;
`;

export const CardInner = styled('div')`
  box-shadow: rgb(0 0 0 / 20%) 0 2px 12px 0;
  display: flex;
  flex-direction: column;
  padding: 2.35rem 0.9rem;
  position: relative;

  p {
    margin-top: 1rem;
    margin-bottom: 1.1rem;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Container = styled('div')`
  margin: 2rem auto;
`;

export const ErrorContainer = styled('span')`
  color: ${({ theme }) => theme.palette.portalTheme.themeColorError};
`;

export const Title = styled('h3')`
  display: flex;
  flex-direction: row;
  justify-items: center;

  svg {
    color: ${({ theme }) => theme.palette.portalTheme.themeColorError};
  }

  span {
    margin-left: 0.5rem;
  }
`;
