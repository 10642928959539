import React, { ReactNode, useMemo } from 'react';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { useFlowMachineContextSubscriptionProps, useFlowMachineContextContent } from '@lib-appRouter/RouterContext';
import Accordion from '@lib-components/Accordion';
import VehicleServices from '@manageSubscription/InformationPages/VehicleServices';

export type ServiceListProps = {
  expanded?: boolean;
  title: ReactNode;
  products?: Product[];
};

const ServiceList = ({ expanded = false, title, products }: ServiceListProps) => {
  const subscriptionProps = useFlowMachineContextSubscriptionProps();
  const {
    informationPageSubscriptionContent: { servicesFetchError },
    assets,
  } = useFlowMachineContextContent();

  const servicesToShow = useMemo(
    () => products?.reduce((all, { serviceIds }) => [...all, ...serviceIds], []),
    [products],
  );

  return (
    <Accordion expanded={expanded} title={title}>
      <VehicleServices
        subscriptionProps={subscriptionProps}
        servicesToShow={servicesToShow}
        assets={assets}
        fetchError={servicesFetchError}
      />
    </Accordion>
  );
};

export default ServiceList;
