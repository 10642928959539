import React from 'react';
import ScheduleServiceButton from '@components/ScheduleServiceButton';
import checkWarningStatus from '@utils/warning-status-checker';
import clsx from 'clsx';
import styles from './VehicleCondition.module.css';
import { useSelector } from 'react-redux';
import { useVehicleConditionContext } from './VehicleConditionContext';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import ContentRenderer from '@components/ContentRenderer';

type VehicleConditionContainerProps = {
  children?: React.ReactNode;
  expandAllLabel: string;
  collapseAllLabel: string;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
  displayViewMode: 'default' | 'warning' | 'short';
  commonWarningIcon?: ContentfulFile;
  modalWarningIcon?: ContentfulFile;
};

function VehicleConditionContainer({
  children,
  expandAllLabel,
  collapseAllLabel,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  displayViewMode,
  commonWarningIcon,
  modalWarningIcon,
}: VehicleConditionContainerProps) {
  const {
    data: { isExpandAll, toggleExpandAll },
  } = useVehicleConditionContext();
  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const checkWarning = checkWarningStatus(vehicleStatus?.mil);

  const isDefaultViewMode = displayViewMode === 'default';
  const isWarningViewMode = displayViewMode === 'warning';
  const isShortViewMode = displayViewMode === 'short';

  const scheduleServiceButtonDisplay = () => {
    if (checkWarning || isShortViewMode || !scheduleServiceButtonUrl || !scheduleServiceButtonLabel) {
      return null;
    }

    return (
      <ScheduleServiceButton
        url={scheduleServiceButtonUrl}
        label={scheduleServiceButtonLabel}
        displayViewMode={displayViewMode}
      />
    );
  };

  return (
    <div
      className={clsx(
        { [styles['VehicleCondition']]: isDefaultViewMode },
        { [styles['VehicleCondition--warning']]: isWarningViewMode },
        { [styles['VehicleCondition--short']]: isShortViewMode },
      )}
    >
      {isDefaultViewMode && (
        <button className={styles['VehicleCondition-expand-all']} onClick={() => toggleExpandAll(!isExpandAll)}>
          {isExpandAll ? collapseAllLabel : expandAllLabel}
        </button>
      )}
      <div
        className={clsx(
          { [styles['VehicleCondition-wrapper']]: isDefaultViewMode },
          { [styles['VehicleCondition-wrapper--warning']]: isWarningViewMode },
          { [styles['VehicleCondition-wrapper--short']]: isShortViewMode },
        )}
      >
        {children && (
          <ContentRenderer
            name={children.props.name}
            content={children.props.content}
            {...{ scheduleServiceButtonLabel, scheduleServiceButtonUrl, modalWarningIcon, commonWarningIcon }}
          />
        )}
      </div>
      {!isShortViewMode && scheduleServiceButtonDisplay()}
    </div>
  );
}

export default VehicleConditionContainer;
