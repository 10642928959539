import React from 'react';
import { Button } from '@components-lib';

export type LinkButtonProps = {
  onClick?: () => void;
  formatEventName: string;
  label: string;
  url: string;
  newTab: boolean | undefined;
  size: 'small' | 'medium' | 'large';
  phone: string;
  justifySelf: 'left' | 'center' | 'right';
};

const LinkButton = ({
  onClick,
  formatEventName,
  label,
  url,
  newTab,
  size = 'medium',
  phone,
  justifySelf = 'left',
}: LinkButtonProps) => {
  return (
    <Button
      onClick={onClick ? onClick : () => {}}
      data-analyticseventname={formatEventName}
      size={size}
      href={phone ? `tel:${phone}` : url}
      style={{ justifySelf: justifySelf }}
      target={newTab ? '_blank' : ''}
      rel={newTab ? 'noreferrer' : ''}
    >
      {label}
    </Button>
  );
};

export default LinkButton;
