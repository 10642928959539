import c2 from './2c';
import d2 from './2d';
import e2 from './2e';

const flows = {
  '2c': c2,
  '2d': d2,
  '2e': e2,
};

export default flows;
