// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ln0pkiWiNNkERnWOPMMg {
  color: var(--theme-disclaimer-color-text);
  text-transform: none;
  text-align: justify;
}

.vd7rPozzRNstEWTT9lM9 {
  line-height: 1.125rem;
}

.ln0pkiWiNNkERnWOPMMg .vd7rPozzRNstEWTT9lM9 p {
  font-size: 0.75rem;
}

.ln0pkiWiNNkERnWOPMMg .rxH8ICc8r928e3uUWh8A p {
  font-size: 0.875rem;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Disclaimer/Disclaimer.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".Disclaimer-container {\n  color: var(--theme-disclaimer-color-text);\n  text-transform: none;\n  text-align: justify;\n}\n\n.Disclaimer-description {\n  line-height: 1.125rem;\n}\n\n.Disclaimer-container .Disclaimer-description p {\n  font-size: 0.75rem;\n}\n\n.Disclaimer-container .Disclaimer-contact p {\n  font-size: 0.875rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Disclaimer-container": `ln0pkiWiNNkERnWOPMMg`,
	"Disclaimer-description": `vd7rPozzRNstEWTT9lM9`,
	"Disclaimer-contact": `rxH8ICc8r928e3uUWh8A`
};
export default ___CSS_LOADER_EXPORT___;
