import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { getAgreements, createAgreement, getEffectiveAgreementTemplate } from '@cv/portal-cps-lib';
import {
  AgreementsRequest,
  CreateAgreementPayload,
  CreateAgreementRequest,
  GetEffectiveAgreement,
  GetEffectiveAgreementRequest,
} from '@cv/portal-cps-lib/agreements/agreement-service/models';

type CreateAgreementArgs = {
  data: CreateAgreementPayload;
  accessToken: string;
  locale: string;
};

type EffectiveAgreementArgs = {
  data: GetEffectiveAgreement;
  accessToken: string;
  locale: string;
};

export default class AgreementService {
  private environment: Environment;

  constructor(environment = Environment.DEVINT) {
    this.environment = environment;
  }

  async getAgreement(locale: string, accessToken: string, userId: string, vin: string) {
    const request: AgreementsRequest = {
      data: { userId, vin },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };
    const response = await getAgreements(request);
    return { data: response.data };
  }

  async createAgreement({ data, accessToken, locale }: CreateAgreementArgs) {
    const payload: CreateAgreementRequest = {
      data,
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Correlation-Id': '',
      },
    };

    const response = await createAgreement(payload);
    return { data: response.data };
  }

  async getEffectiveAgreement({ data, accessToken, locale }: EffectiveAgreementArgs) {
    const payload: GetEffectiveAgreementRequest = {
      data,
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Correlation-Id': '',
      },
    };

    const response = await getEffectiveAgreementTemplate(payload);
    return { data: response.data };
  }
}
