import React, { useMemo } from 'react';
import { useFlowMachine, useFlowMachineContextContent } from '../../Router/RouterContext';
import * as Yup from 'yup';
import CheckIfEmailIsOnFile, { CheckIfEmailIsOnFileSchema } from './CheckIfEmailIsOnFile';

const CheckIfEmailIsOnFileContainer = () => {
  const flowMachine = useFlowMachine();

  const { userProfileWebContent, commonWebContent } = useFlowMachineContextContent();
  const { emailValidation, emailValidationError } = userProfileWebContent;

  const yupShape: CheckIfEmailIsOnFileSchema = useMemo(() => {
    return {
      email: Yup.string().trim().required().matches(new RegExp(emailValidation), emailValidationError),
    };
  }, []);

  const handleSubmit = (values?: { email: string }) => {
    flowMachine.send({
      type: 'SET_EMAIL_ON_FILE',
      data: { ...values },
    });
  };

  return (
    <CheckIfEmailIsOnFile
      handleSubmit={handleSubmit}
      yupShape={yupShape}
      commonWebContent={commonWebContent}
      userProfileWebContent={userProfileWebContent}
    />
  );
};

export default CheckIfEmailIsOnFileContainer;
