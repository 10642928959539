import React from 'react';

import styles from './MonitoringAlerts.module.css';

type TabInfoProps = {
  onClose?: () => void;
  children?: React.ReactChild;
};

export default function TabInfo({ children, onClose }: TabInfoProps) {
  return (
    <div className={styles['tab-info']}>
      <button className={styles['tab-info-close']} onClick={onClose}>
        X
      </button>
      <div className={styles['tab-info-wrapper']}>{children}</div>
    </div>
  );
}
