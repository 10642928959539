import { ReadPayload, fetchMarketingPreference } from '@api/requests/marketingPreference';

export type OptInInfo = {
  isEmpty: boolean;
  currentOptIn: boolean;
};

export type ReadOptInOptions = Required<Pick<ReadPayload, 'smsNumber'>> & {
  accessToken: string;
  userId: string;
  locale?: string;
};

export const readOptIn = (options: ReadOptInOptions): Promise<OptInInfo> =>
  fetchMarketingPreference(options, 'smsNumber')
    .then(({ data }) => ({ isEmpty: false, currentOptIn: !!data?.preferences?.some(({ optIn }) => !!optIn) }))
    .catch(() => ({ isEmpty: true, currentOptIn: false }));
