import { useCallback } from 'react';
import { useApi } from '@api';
import config from '@config/config';

const useGetLink = () => {
  const api = useApi();

  return useCallback(
    (linkConfig: string | undefined) =>
      api
        .getLink(linkConfig)
        .then(({ data: { redirectURL } }) => redirectURL)
        .catch(() => config.getOemValue(`${linkConfig}_FAILURE_URL`)),
    [api],
  );
};

export default useGetLink;
