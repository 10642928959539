import React from 'react';
import { CombinedPaymentMethods, PaymentMethodDetails } from '@lib-payment/Types';
import { FormControl, FormControlLabel } from '@mui/material';

import SavedPaymentMethodItem, { SavedPaymentMethodItemProps } from './SavedPaymentMethodItem';

import { Radio, RadioGroup } from '@lib-components/Radio';

export interface SavedPaymentMethodsListProps {
  defaultPaymentId: string;
  setDefault: (paymentMethodId: string) => void;
  paymentMethods: CombinedPaymentMethods;
}

interface SavedPaymentMethodsListLocalProps extends SavedPaymentMethodsListProps {
  getLabel: (paymentDetails: PaymentMethodDetails) => string;
}

const SavedPaymentMethods: React.FC<SavedPaymentMethodItemProps & SavedPaymentMethodsListLocalProps> = ({
  deleteLabel,
  editLinkLabel,
  setDefault,
  paymentMethods,
  defaultPaymentId,
  onEdit,
  getLabel,
  onDelete,
}) => (
  <FormControl sx={{ width: '100%' }}>
    <RadioGroup value={defaultPaymentId} onChange={(_, value) => setDefault(value)}>
      {paymentMethods?.map((paymentDetails) => (
        <FormControlLabel
          key={paymentDetails.paymentMethodId}
          value={paymentDetails.paymentMethodId}
          control={<Radio />}
          disableTypography
          label={
            <SavedPaymentMethodItem
              deleteLabel={deleteLabel}
              editLinkLabel={editLinkLabel}
              label={getLabel(paymentDetails)}
              paymentMethodDetails={paymentDetails}
              onEdit={onEdit}
              onDelete={paymentDetails.defaultPaymentMethod ? undefined : onDelete}
            />
          }
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default SavedPaymentMethods;
