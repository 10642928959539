import { useApi } from '@api';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { login, loginWithCallback } from './login';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@app/components-lib/components/Analytics';

export type AuthApi = {
  login: () => void;
  loginWithCallback: () => Promise<void>;
};

export type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthContext = React.createContext<AuthApi | null>(null);

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const history = useHistory();
  const api = useApi();

  const wrapper = React.useCallback(
    (func: () => Promise<void>) => async (): Promise<void> => {
      try {
        await func();
      } catch (e) {
        sendAnalyticsEvent(new EventDataBuilder(EventType.UserSignInErrored).withArgs());
      } finally {
      }
    },
    [],
  );

  const initializedStrategy = React.useMemo(() => {
    return {
      login,
      loginWithCallback: wrapper(loginWithCallback(history, api)),
    };
  }, [history, api, wrapper]);

  return <AuthContext.Provider value={initializedStrategy}>{children}</AuthContext.Provider>;
};

const useAuth = (): AuthApi => {
  const api = React.useContext(AuthContext);

  if (api === null) {
    throw new Error('useAuth hook must be used within AuthProvider');
  }

  return api;
};

export { AuthProvider, useAuth };
