import React from 'react';
import { FaCheck } from 'react-icons/fa';
import RoundToggleButton from '@components/RoundToggleButton';
import ControlLabel from '@components/ControlLabel';
import styles from './DaysSelect.module.css';
import { WeekDay } from '@components/Map/MonitoringAlerts/ManageAlertsContent/TabCurfew';

export type DaysSelectProps = {
  onChange: (day: string[], isSelected: boolean) => void;
  disabledDays?: string[];
  helpers?: any;
  checkboxLabel?: string;
  value: WeekDay[];
};

const DaysSelect = ({ onChange, disabledDays, checkboxLabel, value }: DaysSelectProps) => {
  const areAllDaysSelected = value.every((opt) => opt.selected);

  const handleToggle = (day: string, index: number) => (isSelected: boolean) => {
    if (disabledDays?.includes(day)) {
      return;
    }
    value[index].selected = isSelected;
    onChange(value, isSelected);
  };

  const handleToggleAll = () => {
    const toggledDays = value.map((opt) => {
      if (!disabledDays?.includes(value)) {
        opt.selected = !areAllDaysSelected;
      }
      return opt;
    });

    onChange(toggledDays, !areAllDaysSelected);
  };

  return (
    <div className={styles['content-container']}>
      <div className={styles['days-container']}>
        {value.map(({ value, label, selected }, index) => (
          <RoundToggleButton
            key={value}
            name={value}
            checked={selected}
            onToggle={handleToggle(value, index)}
            disabled={disabledDays?.includes(value)}
            aria-label={label}
          >
            {label.slice(0, 1)}
          </RoundToggleButton>
        ))}
      </div>

      <ControlLabel
        label={checkboxLabel}
        control={
          <RoundToggleButton
            checked={areAllDaysSelected}
            onToggle={handleToggleAll}
            classes={{
              input: styles['toggle-all-button'],
            }}
          >
            <FaCheck />
          </RoundToggleButton>
        }
      />
    </div>
  );
};

export default DaysSelect;
