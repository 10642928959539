import React from 'react';
import { omit } from 'lodash';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import CardView from '@components/Card/CardView';
import Accordion from '@components/Accordion';
import Markdown from '@components/Markdown';
import { OnlyLabels } from '@utils/labels';
import useDynamicQuestions from './useDynamicQuestions';

import styles from './Faq.module.css';

const Faq = ({ labels: labelsQuestions }: OnlyLabels): JSX.Element => {
  const questions = useDynamicQuestions(labelsQuestions);

  return (
    <CardView>
      <div className={styles.root}>
        <h2>{questions?.title?.primary}</h2>
        {Object.values(omit(questions, 'title')).map(
          ({ primary: title, secondary: text = '', analyticsEventName = '' }) => {
            const formatEventName = formatAnalyticsEventName(analyticsEventName);

            return (
              <Accordion
                key={title}
                title={title}
                text={<Markdown>{text}</Markdown>}
                className={styles.accordion}
                classes={{
                  title: styles['accordion-title'],
                }}
                analyticsEventName={formatEventName}
              />
            );
          },
        )}
      </div>
    </CardView>
  );
};

export default Faq;
