import React, { useCallback, useEffect, useRef, useState, MouseEvent } from 'react';
import { Placement } from './types';
import { calculatePosition } from './utils';
import TooltipContainer from './TooltipContainer';
import { addPointerEventListener, removePointerEventListener, POINTER_EVENTS } from '@utils/pointer-events';

import styles from './Tooltip.module.css';

type TooltipProps = {
  /* Should me react element with ref */
  children: React.ReactElement;
  /* Tooltip placement */
  placement?: Placement;
  /* Content to show in tooltip */
  title?: React.ReactNode;
  /* CSS class of tooltip container */
  className?: string;
  /* Page indents to correctly calculate tooltip placement.
    Can be defined as:
    1. number - will add it to all 4 sides
    2. number - will add it to all 4 sides in Pixels
    2. [yIndent, xIndent] - top/bottom, left/right indent
    3. [topIndent, xIndent, bottomIndent]
    4. [topIndent, rightIndent, bottomIndent, leftIndent]
  */
  indent?: number | [number, number, number?, number?];
  /* Offset of tooltip to have more flexible positioning
    Can be defined as:
    1. number - will add it to all 4 sides
    2. number - will add it to all 4 sides in Pixels
    2. [yOffset, xOffset] - top/bottom, left/right
    3. [topOffset, xOffset, bottomOffset]
    4. [topOffset, rightOffset, bottomOffset, leftOffset]
  */
  offset?: number | [number, number, number?, number?];
  /* Force position of tooltip to use only defined */
  forcePlacement?: boolean;
  /* Boolean to show whether arrow should be shown */
  arrow?: boolean;
};

const { body } = document;
const Tooltip = ({
  children,
  placement = 'top-center',
  title,
  className,
  forcePlacement,
  indent = 0,
  offset = 0,
  arrow = false,
}: TooltipProps) => {
  const triggerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [isShown, setShown] = useState<boolean>(false);
  const [[position, coordinates, arrowCoordinates], setPosition] = useState([
    placement,
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  const onDocumentClick = (ev: MouseEvent) => {
    const { target } = ev;

    if (target && target !== triggerRef.current && !target.closest(`.${styles['Tooltip-container']}`)) {
      setShown(false);
    }
  };

  const handleCalculatePosition = useCallback(() => {
    setPosition(
      (prev) =>
        calculatePosition({
          triggerElement: triggerRef.current,
          contentElement: contentRef.current,
          initialPlacement: placement,
          force: forcePlacement,
          indent,
          offset,
          arrow,
        }) || prev,
    );
  }, [calculatePosition, setPosition]);

  const showTooltip = () => {
    setShown(true);
    handleCalculatePosition();
  };

  const hideTooltip = () => {
    setShown(false);
  };

  const toggleTooltip = () => {
    (isShown ? hideTooltip : showTooltip)();
  };

  const handleRecalculate = useCallback(() => {
    if (isShown) {
      handleCalculatePosition();
    }
  }, [isShown, handleCalculatePosition]);

  useEffect(() => {
    triggerRef.current?.addEventListener('click', toggleTooltip);
    addPointerEventListener(body, POINTER_EVENTS.POINTER_DOWN, onDocumentClick);
    window.addEventListener('resize', handleRecalculate);
    window.document.addEventListener('scroll', handleRecalculate);

    return () => {
      triggerRef.current?.removeEventListener('click', toggleTooltip);
      removePointerEventListener(body, POINTER_EVENTS.POINTER_DOWN, onDocumentClick);
      window.removeEventListener('resize', handleRecalculate);
      window.document.removeEventListener('scroll', handleRecalculate);
    };
  }, [placement, handleRecalculate]);

  return (
    <>
      {React.cloneElement(children, { ref: triggerRef })}
      {isShown && (
        <TooltipContainer
          x={coordinates.x}
          y={coordinates.y}
          placement={position}
          ref={contentRef}
          className={className}
          arrow={arrow}
          arrowX={arrowCoordinates.x}
          arrowY={arrowCoordinates.y}
        >
          {title}
        </TooltipContainer>
      )}
    </>
  );
};

export default Tooltip;
