import React, { useMemo } from 'react';
import SavedPaymentMethodsList, { SavedPaymentMethodsListProps } from './SavedPaymentMethodsList';
import { usePaymentContext } from '@lib-payment/components/Payment/PaymentContext';
import SavedPaymentMethodItem from './SavedPaymentMethodItem';
import { CardMethodDetails, PaymentMethodDetails, PaymentOptions } from '@lib-payment/Types';

interface SavedPaymentMethodContainerProps extends SavedPaymentMethodsListProps {
  removePayment?: (arg: PaymentMethodDetails) => void;
  setPaymentToEdit?: (arg: CardMethodDetails) => void;
}

const SavedPaymentMethodContainer: React.FC<SavedPaymentMethodContainerProps> = (props) => {
  const { paymentMethods = [], defaultPaymentId, setPaymentToEdit, removePayment } = props;
  const { subscriptionProps, content } = usePaymentContext();
  const { cardNumberEndingLabel, commonWebContent } = content;
  const cardsCount = paymentMethods.length;

  const getLabel = (paymentDetails: PaymentMethodDetails) => {
    if (paymentDetails.cardType === PaymentOptions.PAYPAL) {
      return paymentDetails.email;
    }

    return `${cardNumberEndingLabel} ${paymentDetails.cardNumber?.slice(-4)}`;
  };

  const paymentDetails = useMemo(
    () =>
      paymentMethods.find((paymentDetails) => paymentDetails.paymentMethodId === defaultPaymentId) ??
      paymentMethods.find((paymentDetails) => paymentDetails.defaultPaymentMethod),
    [paymentMethods, defaultPaymentId],
  );

  if (!paymentDetails || cardsCount === 0) {
    return null;
  }

  if (subscriptionProps?.isSinglePaymentMethod || cardsCount === 1) {
    return (
      <SavedPaymentMethodItem
        label={getLabel(paymentDetails)}
        deleteLabel={commonWebContent.deleteLabel}
        editLinkLabel={commonWebContent.editLinkLabel}
        paymentMethodDetails={paymentDetails}
        onEdit={setPaymentToEdit}
      />
    );
  }

  return (
    <SavedPaymentMethodsList
      deleteLabel={commonWebContent.deleteLabel}
      editLinkLabel={commonWebContent.editLinkLabel}
      getLabel={getLabel}
      onDelete={removePayment}
      onEdit={setPaymentToEdit}
      {...props}
    />
  );
};

export default SavedPaymentMethodContainer;
