// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Eig6xuS1GeRGHN0zJItZ {
  margin: 2rem auto;
}

.zAErcNLDBWuNF1VyyFMg {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 550px;
}

@media (min-width: 768px) {
  .zAErcNLDBWuNF1VyyFMg {
    width: auto;
  }
}

.WFRBDRPvdH0Lo366Svu6 {
  color: var(--theme-color-error);
}

.aZpSjfRz68GvadXxcfRh {
  display: flex;
  flex-direction: row;
  justify-items: center;
}

.aZpSjfRz68GvadXxcfRh svg {
  color: var(--theme-color-error);
}

.aZpSjfRz68GvadXxcfRh span {
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorPage/ErrorPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  margin: 2rem auto;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-width: 550px;\n}\n\n@media (--viewport-s) {\n  .card {\n    width: auto;\n  }\n}\n\n.errorContainer {\n  color: var(--theme-color-error);\n}\n\n.title {\n  display: flex;\n  flex-direction: row;\n  justify-items: center;\n}\n\n.title svg {\n  color: var(--theme-color-error);\n}\n\n.title span {\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Eig6xuS1GeRGHN0zJItZ`,
	"card": `zAErcNLDBWuNF1VyyFMg`,
	"errorContainer": `WFRBDRPvdH0Lo366Svu6`,
	"title": `aZpSjfRz68GvadXxcfRh`
};
export default ___CSS_LOADER_EXPORT___;
