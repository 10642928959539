import React, { useEffect, useState } from 'react';
import { replaceTemplateString } from '@app/components-lib';
import { Modal } from '@components-lib';
import { Alert, Typography } from '@mui/material';
import usePreferencesSelector from '@redux/selectors/preferences';
import { useCancelLabels } from './CancelLabelsContext';
import { useCancelMachineContext } from './CancelSubscriptionStateMachineWrapper';

const ErrorModal = () => {
  const { error } = useCancelMachineContext();
  const { errorTitle, errorMessage } = useCancelLabels();
  const [isOpen, setIsOpen] = useState(false);
  const preferences = usePreferencesSelector();

  const errorMessageFilled = replaceTemplateString(errorMessage, {
    customerCareCancelPackageNumber: preferences.customerCareCancelPackageNumber,
  });

  useEffect(() => {
    if (error) {
      setIsOpen(true);
    }
  }, [error]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} title={errorTitle}>
      <Alert severity="error">
        <Typography variant="h6"> {errorMessageFilled}</Typography>
        <Typography>{error?.message}</Typography>
      </Alert>
    </Modal>
  );
};

export default ErrorModal;
