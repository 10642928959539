import set from 'lodash/set';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { SetupPinAndSecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions-pin/models';
import { setupPinAndSecurityQuestions } from '@cv/portal-idm-lib/security-questions-pin';

export default class SecurityPinService {
  private payload: Omit<SetupPinAndSecurityQuestionsRequest, 'data'>;

  constructor(environment = Environment.LOCAL) {
    this.payload = {
      environment,
      pathParams: {
        id: '',
      },
      headers: {
        Authorization: 'token',
      },
    };
  }

  setupSecurityPin({
    data,
    userId,
    accessToken,
  }: {
    data: SetupPinAndSecurityQuestionsRequest['data'];
    userId: string;
    accessToken: string;
  }) {
    const { payload } = this;
    set(payload, 'pathParams.id', userId);
    set(payload, 'data', data);
    set(payload, 'headers.Authorization', accessToken);
    return setupPinAndSecurityQuestions(payload as SetupPinAndSecurityQuestionsRequest);
  }
}
