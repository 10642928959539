import React from 'react';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { AssetObject } from '@utils/assets';
import { Box } from '@mui/material';

type Props = {
  product: Product;
  assets?: AssetObject;
  icon: React.ReactNode;
};

const ProductItem = (props: Props) => {
  const { product, icon } = props;
  return (
    <Box display="flex" flexDirection="column" gap={1} pt="1em" pb="1em">
      <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        {icon}
        <span>{product.marketingName}</span>
      </Box>
      <span>{product.longDescription}</span>
    </Box>
  );
};

export default ProductItem;
