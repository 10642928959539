import React from 'react';
import { H1, Container } from '@styled-components/globalStyles';
import ServiceList from '@lib-components/ServiceList/ServiceList';
import Button from '@lib-components/Button';
import {
  ContentfulFile,
  InformationPageContent,
  CommonWebContent,
  TrialPackagesWebContent,
} from '@manageSubscription/Subscription';
import { SubscribedPackageInfo } from '@manageSubscription/Types';
import { makeReadableTerm } from '@manageSubscription/utils';
import { ColumnContainer } from '../InformationPageStyles';
import {
  BackgroundImage,
  HeaderContainer,
  LogoContainer,
  OfferName,
  OfferDescription,
  Offer,
  FeatureNote,
  ServicesTitle,
} from './InformationPageStylesNNA';

export interface InformationPageSubscriptionProps {
  navigateForward: () => void;
  bgImageData?: ContentfulFile;
  headerLogoData?: ContentfulFile;
  subscribedPackages: SubscribedPackageInfo[];
  informationPageSubscriptionContent: InformationPageContent;
  commonWebContent: CommonWebContent;
  trialPackagesWebContent: TrialPackagesWebContent;
}

const InformationPageSubscription: React.FC<InformationPageSubscriptionProps> = ({
  navigateForward,
  bgImageData,
  headerLogoData,
  subscribedPackages,
  informationPageSubscriptionContent: { bodyHeader = '', servicesTitle = '', availabilityNote = '' },
  commonWebContent: { yearsLabel, monthsLabel, daysLabel, continueButtonLabel },
  trialPackagesWebContent: { trialLabel },
}) => {
  const url = bgImageData?.file.url;
  const { width, height } = bgImageData?.file.details?.image;
  return (
    <ColumnContainer>
      <BackgroundImage img={url} initialWidth={width} initialHeight={height}>
        <HeaderContainer>
          {headerLogoData && (
            <LogoContainer>
              <img src={headerLogoData.file.url} alt="Car brand logo" />
            </LogoContainer>
          )}
          <H1>{bodyHeader}</H1>
        </HeaderContainer>
      </BackgroundImage>
      <Container>
        {subscribedPackages.map(({ variant, products, subscriptionPackageId }) => {
          const offerTerm = makeReadableTerm(
            { term: variant.initialTerm, unit: variant.initialTermUnit },
            { years: yearsLabel, months: monthsLabel, days: daysLabel },
          );

          const packageName = variant.promotionalMessage.slice(0, variant.promotionalMessage.indexOf('_'));
          const offerName = `${offerTerm} ${packageName} ${trialLabel}`;

          const offerDescription = variant.longDescription || '';

          const keysToReplace = { packageName };
          const services = servicesTitle.replace(/{{([^{}]*)}}/g, (_, key: string) => keysToReplace[key] || '');

          return (
            <Offer key={subscriptionPackageId}>
              <OfferName>{offerName}</OfferName>
              <OfferDescription title={offerName}>{offerDescription}</OfferDescription>
              <ServiceList title={<ServicesTitle>{services}</ServicesTitle>} products={products} />
            </Offer>
          );
        })}
        <FeatureNote>{availabilityNote}</FeatureNote>
        <Button onClick={navigateForward}>{continueButtonLabel}</Button>
      </Container>
    </ColumnContainer>
  );
};

export default InformationPageSubscription;
