import axios from 'axios';
import { SubscriptionProps } from '@manageSubscription';
import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { encrypt } from '../cps';
import { CONFIG_KEYS } from '@manageSubscription/utils/constants';
import {
  CreatePinWithSqa,
  SearchUserShimNnaResponse,
  SearchUserResponse,
  UpdatePassword,
  UserInfo,
  EnrollUserInfo,
  EnrollUserResponse,
} from '@manageSubscription/Types';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';

export const searchUser = (props: SubscriptionProps): Promise<SearchUserResponse> => {
  const {
    config,
    userDetails: { email },
    vehicleDetails: { vin },
  } = props;
  const searchUserRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_SEARCH_USER_URL),
    method: HttpMethodEnum.POST,
    headers: { 'Content-Type': 'application/json' },
    data: { email, vin },
  };
  return axios(searchUserRequest).then((res) => res.data);
};

export const searchUserShimNna = (props: SubscriptionProps): Promise<SearchUserShimNnaResponse> => {
  const { config, accessToken, tenantId } = props;
  const searchUserRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_SEARCH_USER_URL),
    method: HttpMethodEnum.GET,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'CV-Tenant-Id': tenantId,
      'CV-APPID': config.getOemValue(CONFIG_KEYS.APP_ID),
    },
  };
  return axios(searchUserRequest).then((res) => {
    if (!res) {
      throw {
        response: { status: 404 },
      };
    }
    return res.data;
  });
};

export const updatePassword = async (
  props: SubscriptionProps,
  passwordData: UpdatePassword,
  encryptionEnabled: boolean,
) => {
  const {
    config,
    locale,
    publicKey,
    accessToken,
    userDetails: { externalTenantUserId },
    vehicleDetails: { make, registrationCountry },
  } = props;
  const { password } = passwordData;
  const updatePasswordRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_UPDATE_PASSWORD_URL),
    method: HttpMethodEnum.POST,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    data: {
      oemCustId: externalTenantUserId,
      password: encryptionEnabled
        ? await encrypt(password, publicKey).catch((error) => console.error(error))
        : password,
      language: locale?.split('-')[0] ?? 'en',
      country: registrationCountry,
      make,
    },
  };

  return axios(updatePasswordRequest).then((res) => res.data);
};

type GetTokenRequestBody = {
  email: string;
  sxmKey: string;
  surname: string;
  givenName: string;
  createUserFlag: boolean;
  sxmUserId: string;
  password?: string;
};

export const getToken = async (props: SubscriptionProps, userInfo: UserInfo, encryptionEnabled: boolean) => {
  const {
    config,
    sxmKey,
    publicKey,
    userDetails: { email, userId },
  } = props;
  const { firstName, lastName, password, isCreateProfile } = userInfo;

  const data: GetTokenRequestBody = {
    email,
    sxmKey,
    ...(lastName && { surname: lastName }),
    ...(firstName && { givenName: firstName }),
    createUserFlag: isCreateProfile || false,
    ...(userId && { sxmUserId: userId }),
  };

  if (!!password) {
    data.password = encryptionEnabled
      ? await encrypt(password, publicKey).catch((error) => console.error(error))
      : password;
  }

  const getTokenRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_GET_TOKEN_URL),
    method: HttpMethodEnum.POST,
    headers: { 'Content-Type': 'application/json' },
    data,
  };
  return axios(getTokenRequest).then((res) => res.data);
};

export const exchangeToken = async (props: SubscriptionProps) => {
  const { config, sxmKey } = props;
  const getTokenRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.NISSAN_TOKEN_LOGIN_URL),
    method: HttpMethodEnum.POST,
    headers: {
      'Content-Type': 'application/json',
      'cv-client-id': config.getOemValue(CONFIG_KEYS.MARKETING_CLIENT_ID),
    },
    data: { tokenId: sxmKey },
  };
  return axios(getTokenRequest).then((res) => res.data as { access_token: string; refresh_token: string });
};

export type ISxmKey = {
  vinEncrypted: string;
  vehicleId: string;
  userId: string;
  email: string;
  uri?: string;
  preferredLanguage?: string;
  lang?: string;
  source: string;
  tenantId: string;
  vin: string;
  ownershipType?: OwnershipType;
  make?: string;
};

export const getSxmKeyInfo = async (props: SubscriptionProps): Promise<{ data: { data: ISxmKey } }> => {
  const { config, sxmKey } = props;
  const getTokenRequest: APIRequest = {
    url: `${config.getOemValue(CONFIG_KEYS.SXM_KEY_URL)}/${sxmKey}`,
    method: HttpMethodEnum.GET,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'cv-client-id': config.getOemValue(CONFIG_KEYS.SXM_KEY_CLIENT_ID),
    },
  };
  return axios(getTokenRequest).then((res) => res.data.data);
};

export const createPinWithSQA = async (props: SubscriptionProps, pinData: CreatePinWithSqa) => {
  const { config, accessToken, userDetails } = props;
  const { pin, questions } = pinData;

  const createPinRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_CREATE_PIN_URL).replace('{{userId}}', userDetails?.externalTenantUserId),
    method: HttpMethodEnum.POST,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    data: { pin, questions },
  };
  return axios(createPinRequest).then((res) => res.data);
};

export const enrollUser = async (props: SubscriptionProps, data: EnrollUserInfo) => {
  const { config, tenantId, correlationId, locale, accessToken } = props;
  const language = locale ? new Intl.Locale(locale).language : 'en';

  const enrollUserRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_ENROLL_URL),
    method: HttpMethodEnum.POST,
    headers: {
      'Content-Type': 'application/json',
      'CV-Tenant-Id': tenantId,
      'CV-APPID': config.getOemValue(CONFIG_KEYS.APP_ID),
      'CV-Correlation-Id': correlationId,
      Language: language,
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  };
  return axios(enrollUserRequest).then((res) => res.data as EnrollUserResponse);
};

export const linkUser = async (props: SubscriptionProps) => {
  const { config, accessToken, tenantId, vehicleDetails } = props;

  const linkUserRequest: APIRequest = {
    url: config.get(CONFIG_KEYS.SHIM_LINK_USER),
    method: HttpMethodEnum.POST,
    headers: {
      'Content-Type': 'application/json',
      'CV-Tenant-Id': tenantId,
      Authorization: `Bearer ${accessToken}`,
    },
    data: { vehicleId: vehicleDetails?.vehicleId },
  };

  return axios(linkUserRequest).then((res) => res.data as EnrollUserResponse);
};
