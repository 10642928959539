const iso3166 = require('iso-3166-standard');
const countryToCurrency = require('country-to-currency');

export const PAYMENT_LABEL_IMAGE_SIZE = '36px';

export enum PaymentOptions_ {
  ADD_NEW_CARD = 'AddNewCard',
  USE_CARD_ON_FILE = 'UseCardOnFile',
  PAYPAL = 'PayPal',
  APPLE_PAY = 'ApplePay',
}

export enum PaymentOptions {
  CREDIT_CARD = 'Card',
  PAYPAL = 'PayPal',
  APPLE_PAY = 'ApplePay',
  USE_CARD_ON_FILE = 'UseCardOnFile', // TODO: remove this
  ADD_NEW_CARD = 'AddNewCard', // TODO: remove this
}

export enum PAYPAL_PLAN_TYPE {
  MERCHANT_INITIATED_BILLING = 'MERCHANT_INITIATED_BILLING',
}

export const PAYPAL_REDIRECT_URL = 'https://www.paypal.com/checkoutnow/error';

export const ZUORA_URL = 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';

export const APPLE_PAY_MERCHANT_CAPABILITIES = ['supports3DS'];

export const COUNTRY_DETAIL_LOOKUP = Object.fromEntries(
  iso3166.getAllCountries().map((country) => [
    country.alpha3Code,
    {
      countryCode: country.alpha2Code,
      currencyCode: countryToCurrency[country.alpha2Code],
    },
  ]),
);
