import { styled } from '@mui/material';
import { Title, Content } from '../../../styled-components/globalStyles';

export const PackageWrapper = styled('div')`
  display: flex;
  text-align: left;
  flex-direction: column;
  margin: 0.2rem 0 0.2rem 0;
`;

export const PackageHeader = styled('div')`
  margin-bottom: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

export const PackageDescription = styled(Content)`
  margin-bottom: 1rem;
`;

export const PackageTitle = styled(Title)`
  align-items: center;
  width: 70%;
`;

export const Pricing = styled(Title)`
  text-align: right;
`;

export const IconSection = styled('span')`
  margin-top: 0.5rem;
  align-items: center;
  width: 2.5rem;
`;
