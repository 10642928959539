import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import React from 'react';
import { Button } from '@components-lib';
import styles from './CancelSubscription.module.css';
import { ContentCenter, LineSeparator } from '@app/components-lib/styled-components/globalStyles';
import Message from '@app/components-lib/components/Message';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
type CancelSubscriptionOverviewProps = {
  handleContinue: () => void;
  labels: {
    sorryToSeeYouGo: { primary: string; analyticsEventName?: string };
    subscriptionIsNowCancelled: { primary: string; analyticsEventName?: string };
    goToButton: { primary: string; analyticsEventName?: string };
    designatedRefundDealerMessage: { primary: string; analyticsEventName?: string };
    designatedRefundDealerMessageHeader: { primary: string; analyticsEventName?: string };
  };
  showDesignatedDealerMessage: boolean;
};

export const CancelSubscriptionConfirmation = ({
  handleContinue,
  labels,
  showDesignatedDealerMessage = false,
}: CancelSubscriptionOverviewProps) => {
  const {
    sorryToSeeYouGo,
    subscriptionIsNowCancelled,
    goToButton,
    designatedRefundDealerMessage,
    designatedRefundDealerMessageHeader,
  } = labels;
  return (
    <div className={styles['container']}>
      {showDesignatedDealerMessage && (
        <Alert severity="warning">
          <AlertTitle>{designatedRefundDealerMessageHeader?.primary}</AlertTitle>
          {designatedRefundDealerMessage?.primary}
        </Alert>
      )}
      <h1 className="sorry">{sorryToSeeYouGo?.primary}</h1>

      <p className="subscription-cancelled">{subscriptionIsNowCancelled?.primary}</p>

      <Button
        className={styles['continue-button']}
        onClick={handleContinue}
        data-analyticseventname={formatAnalyticsEventName(goToButton?.analyticsEventName)}
      >
        {goToButton?.primary}
      </Button>
    </div>
  );
};
