import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentRenderer from '@components/ContentRenderer';
import { ContentfulComponent } from '@app/components-lib';

enum JustifyType {
  Start = 'start',
  End = 'end',
  Center = 'center',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
}

enum AlignType {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Stretch = 'stretch',
  Baseline = 'baseline',
}

type LayoutItemProps = {
  justify?: JustifyType;
  align?: AlignType;
  name: string;
  backgroundImage?: {
    file: { url: string };
    title: string;
  };
  backgroundSize?: 'cover' | 'contain';
  backgroundPositionX?: 'left' | 'right' | 'center';
  backgroundPositionY?: 'top' | 'bottom' | 'center';
  columnNumberDesktop?: number;
  columnNumberMobile?: number;
  gap?: number;
  minHeight?: string;
  maxWidth?: string;
  aspectRatio?: string;
  addBorder?: boolean;
  content?: ContentfulComponent[];
};

const LayoutItem = ({
  justify = JustifyType.Center,
  align = AlignType.Center,
  minHeight = '0',
  maxWidth = 'auto',
  aspectRatio = 'auto',
  addBorder,
  backgroundImage,
  backgroundSize = 'cover',
  backgroundPositionX = 'center',
  backgroundPositionY = 'center',
  columnNumberDesktop = 1,
  columnNumberMobile = 1,
  gap,
  name,
  content,
}: LayoutItemProps) => {
  const MIU_UNIT_PX = 4;
  const MIU_MAX_COL_NUMS = 12;
  const NUMS_OF_PADDINGS = 2;

  const colDesktopSize = MIU_MAX_COL_NUMS / columnNumberDesktop;
  const colMobileSize = MIU_MAX_COL_NUMS / columnNumberMobile;
  const padding = gap ? `${gap * MIU_UNIT_PX * NUMS_OF_PADDINGS}px` : '0';
  const isMobileView = useMediaQuery('(max-width: 991px)', { noSsr: true });
  const containerStyles = {
    backgroundImage: backgroundImage?.file?.url ? `url(${backgroundImage?.file?.url})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundClip: 'content-box',
    minHeight: Number(minHeight) ? `${minHeight}px` : minHeight,
    width: '100%',
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
    aspectRatio,
  };
  const childStyles = { maxWidth: isMobileView ? 'none' : maxWidth, padding };
  return (
    <Grid
      container
      justifyContent={justify}
      alignItems={align}
      style={containerStyles}
      data-testid="grid-container"
      sx={{
        border: addBorder ? '1px solid lightgray' : 'none',
        borderRadius: '6px',
      }}
    >
      {!content
        ? null
        : content.map((_content) => {
            const componentId = `${name}-${_content.componentType}-${_content.name}`;
            return (
              <Grid
                data-testid="grid-child"
                id={componentId}
                key={componentId}
                xs={colMobileSize}
                md={colDesktopSize}
                display="flex"
                justifyContent={justify || 'center'}
                alignItems={align || 'center'}
                style={childStyles}
              >
                <ContentRenderer name={`${name}-${_content.componentType}`} content={[_content]} />
              </Grid>
            );
          })}
    </Grid>
  );
};

export default LayoutItem;
