import { ZuoraUserInfo } from './ZuoraInfo';

export class ZuoraUserInfoBuilder {
  private readonly userInfo: ZuoraUserInfo;

  constructor() {
    this.userInfo = {
      creditCardHolderName: '',
      email: '',
      phone: '',
      creditCardAddress1: '',
      creditCardAddress2: '',
      creditCardCity: '',
      creditCardState: '',
      creditCardPostalCode: '',
      creditCardCountry: '',
    };
  }

  cardHolderName(cardHolderName: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardHolderName = cardHolderName;
    return this;
  }

  email(email: string): ZuoraUserInfoBuilder {
    this.userInfo.email = email;
    return this;
  }

  phone(phone: string): ZuoraUserInfoBuilder {
    this.userInfo.phone = phone;
    return this;
  }

  address1(address1: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardAddress1 = address1;
    return this;
  }

  address2(address2: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardAddress2 = address2;
    return this;
  }

  city(city: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardCity = city;
    return this;
  }

  state(state: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardState = state;
    return this;
  }

  postalCode(postalCode: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardPostalCode = postalCode;
    return this;
  }

  country(country: string): ZuoraUserInfoBuilder {
    this.userInfo.creditCardCountry = country;
    return this;
  }

  build(): ZuoraUserInfo {
    return this.userInfo;
  }
}
