// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CBPwIfUQCDM__m8VHwSD {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .CBPwIfUQCDM__m8VHwSD {
    align-items: center;
    flex-direction: row;
  }
}

.CBPwIfUQCDM__m8VHwSD .vS7dhFnGg4vYdOiCfd0A {
  text-align: left;
}

.vS7dhFnGg4vYdOiCfd0A {
  align-items: center;
  display: flex;
  margin-right: 1.25rem;
}

.vS7dhFnGg4vYdOiCfd0A > svg {
  color: var(--theme-color-success);
  font-size: 2.5rem;
  margin-right: 1.25rem;
}

.ZGPHZCXOqNI6R0_h__KI .vS7dhFnGg4vYdOiCfd0A > svg {
  color: var(--theme-color-error);
}

.UrMGwK_r36srTJkP6JiZ {
  color: var(--theme-color-header);
  margin-bottom: 1rem;
  white-space: nowrap;
}

.SidsgXTSh7YcRxPBpZhg,
.hphT0WVI8hMygmTz9Leq {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.hphT0WVI8hMygmTz9Leq h6 {
  font-size: 1em;
  font-weight: bold;
  margin: 0;
}

.hphT0WVI8hMygmTz9Leq p {
  margin: 0;
}

.GMELs90EVXZ2GvYMUdEZ {
  text-decoration: underline !important;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/EnrollStatus/EnrollStatus.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,mBAAmB;IACnB,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;AACnB","sourcesContent":[".header {\n  display: flex;\n  flex-direction: column;\n}\n\n@media (--viewport-s) {\n  .header {\n    align-items: center;\n    flex-direction: row;\n  }\n}\n\n.header .title {\n  text-align: left;\n}\n\n.title {\n  align-items: center;\n  display: flex;\n  margin-right: 1.25rem;\n}\n\n.title > svg {\n  color: var(--theme-color-success);\n  font-size: 2.5rem;\n  margin-right: 1.25rem;\n}\n\n.error .title > svg {\n  color: var(--theme-color-error);\n}\n\n.vin {\n  color: var(--theme-color-header);\n  margin-bottom: 1rem;\n  white-space: nowrap;\n}\n\n.body,\n.instructions {\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n}\n\n.instructions h6 {\n  font-size: 1em;\n  font-weight: bold;\n  margin: 0;\n}\n\n.instructions p {\n  margin: 0;\n}\n\n.link {\n  text-decoration: underline !important;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `CBPwIfUQCDM__m8VHwSD`,
	"title": `vS7dhFnGg4vYdOiCfd0A`,
	"error": `ZGPHZCXOqNI6R0_h__KI`,
	"vin": `UrMGwK_r36srTJkP6JiZ`,
	"body": `SidsgXTSh7YcRxPBpZhg`,
	"instructions": `hphT0WVI8hMygmTz9Leq`,
	"link": `GMELs90EVXZ2GvYMUdEZ`
};
export default ___CSS_LOADER_EXPORT___;
