import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo, VariantInfo } from '../Types';
import { SubscribedPackage, Variant } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { cloneDeep } from 'lodash';
import findIndex from 'lodash/findIndex';
import { MIN_PKG_TIER, PAID_PKG_OFFER_TYPES } from './constants';
import { DiscountCriteria } from '../Subscription';
import { isTrialPackage } from './trialPackageUtils';
import { getDiscounts, isPromoPackage } from './promoPackageUtils';
import { toDays } from './monthsToDays';
import { VariantsToShow } from '@manageSubscription/Packages/PaidPackage/utils';
import { isFuture, parse } from 'date-fns';

export const containsAddon = (packages: PackageSubscription[]) => {
  return packages.some((pkg) => pkg.packageType === PackageType.Add_on);
};

export const hasPackageAdded = (packages: PackageSubscription[], givenPackage: EligiblePackageInfo) => {
  if (!givenPackage) return false;

  const {
    id,
    variant: { id: variantId, discounts },
  } = givenPackage;
  return packages.some(
    (pkg) =>
      id === pkg.id &&
      variantId === pkg.variant.id &&
      (isPaidPackage(pkg.variant) ||
        getDiscounts(
          pkg.variant.discounts,
          discounts.map((d) => d.offerType),
        ).length > 0),
  );
};

export const hasSubscribedPackage = (ePackages: EligiblePackageInfo[], subscribedPackage: SubscribedPackageInfo) => {
  return ePackages.some((ePkg) => isPackageSubscribed(ePkg, subscribedPackage));
};

export const containsPackage = (eligiblePackages: EligiblePackageInfo[], ePackage: EligiblePackageInfo) => {
  return eligiblePackages?.some(({ id, variant }) => {
    const {
      id: packageId,
      variant: { id: variantId, initialTermUnit, initialTerm },
    } = ePackage;
    return (
      id === packageId &&
      variant.id === variantId &&
      variant.initialTermUnit === initialTermUnit &&
      variant.initialTerm === initialTerm
    );
  });
};

export const containsEligiblePackage = (sPackages: SubscribedPackageInfo[], ePkg: EligiblePackageInfo) =>
  sPackages?.some((sPkg) => isPackageSubscribed(ePkg, sPkg));

export const isPackageSubscribed = (eligiblePackage: EligiblePackageInfo, subscribedPackage: SubscribedPackageInfo) => {
  if (!subscribedPackage?.variant || !eligiblePackage?.variant) {
    return false;
  }
  const {
    id: ePkgId,
    variant: { renewalTerm },
  } = eligiblePackage;
  const {
    packageId: sPkgId,
    autoRenew: sPkgAutoRenew,
    variant: { renewalTerm: sPkgRenewalTerm },
  } = subscribedPackage;

  return ePkgId === sPkgId && sPkgAutoRenew && renewalTerm === sPkgRenewalTerm;
};

export const hasEligiblePackageVariants = (eligiblePackages: EligiblePackageInfo[]) => {
  return eligiblePackages?.some((pkg) => pkg.variants?.length);
};

export const getPackagesMatchingCriteria = (ePackages: EligiblePackageInfo[], discountCriteria: DiscountCriteria) => {
  const eligiblePackages: EligiblePackageInfo[] = [];

  if (!discountCriteria) {
    return eligiblePackages;
  }
  const { pkgTier, term, termUnit = TermUnit.Months, pkgCount } = discountCriteria;
  ePackages.forEach((pkg) => {
    if (!isPackageMatch(pkg, pkgTier)) return;

    pkg.variants.forEach((variant) => {
      if (isTermMatch(variant, termUnit, term) && (!pkgCount || eligiblePackages.length < pkgCount)) {
        eligiblePackages.push({ ...pkg, variant });
      }
    });
  });
  return eligiblePackages;
};

export const getEligiblePaidPackages = (ePackages: EligiblePackageInfo[]) => {
  return getEligiblePackagesAndVariants(ePackages, PAID_PKG_OFFER_TYPES);
};

export const getEligibleAddOns = (ePackages: EligiblePackageInfo[]) => {
  return ePackages.filter((pkg) => pkg.packageType === PackageType.Add_on);
};

export const sortByActualPriceInDesc = (packages: EligiblePackageInfo[]) => {
  if (!packages?.length) return [];
  return packages.sort((p1, p2) => p2.variant.actualPrice - p1.variant.actualPrice);
};

export const deDupePackages = (eligiblePackages: EligiblePackageInfo[], offerType: OfferType) => {
  if (!eligiblePackages?.length) return [];

  // De-duplicate packages to retain given Trial over Paid.
  return eligiblePackages.reduce((state, current) => {
    let prevIndex = findIndex(state, (x) => x.packageName.toLowerCase() === current.packageName.toLowerCase());
    if (prevIndex === -1) {
      state.push(current);
    } else if (
      (offerType === OfferType.Trial && isTrialPackage(current.variant)) ||
      (offerType === OfferType.Promotional && isPromoPackage(current.variant))
    ) {
      state[prevIndex] = current;
    }
    return state;
  }, []);
};

export const deDupeVariantsById = (variants: VariantsToShow): VariantsToShow => {
  if (!variants) return {};
  const pkgWithVariants = {} as VariantsToShow;
  Object.keys(variants).forEach((term) => {
    pkgWithVariants[term] = variants[term].reduce((state: EligiblePackageInfo[], current: EligiblePackageInfo) => {
      let prevIndex = findIndex(state, (x) => x.id === current.id);
      if (prevIndex === -1) {
        state.push(current);
      }
      return state;
    }, []);
  });
  return pkgWithVariants;
};

export const isPaidPackage = (variant: VariantInfo) => {
  if (!variant) return false;
  return !variant.discounts?.length || getDiscounts(variant.discounts, PAID_PKG_OFFER_TYPES).length > 0;
};

export const containsPaidPackage = (packages: PackageSubscription[]) =>
  !!packages.length && packages.some(({ variant }) => isPaidPackage(variant));
export const isDefaultPackage = ({ packageType }: SubscribedPackageInfo) => {
  return packageType === PackageType.Default;
};

export const isTiered = (tier: number) => tier !== null && tier > 0;

export const getEligiblePackagesAndVariants = (
  ePackages: EligiblePackageInfo[],
  offerTypes: OfferType[],
  termUnit: TermUnit | TermUnit[] = TermUnit.Months,
  tier: number = MIN_PKG_TIER,
  termInDays: number = 0,
  packageType: string = PackageType.Regular,
) => {
  if (!ePackages?.length) return [];

  const newPackages: EligiblePackageInfo[] = [];
  ePackages.forEach((pkg) => {
    if (pkg.packageType !== packageType) return;

    // Do not show any trials that are of lower term than the current active subscription
    if (tier < pkg.tier) {
      return;
    }

    const variants = getVariants(pkg.variants, offerTypes, termUnit, termInDays);
    if (!variants?.length) return;

    const newPackage = cloneDeep(pkg);
    newPackage.variants = variants;
    newPackages.push(newPackage);
  });
  return newPackages;
};

const getVariants = (
  variants: Variant[],
  offerTypes: OfferType[],
  termUnit: string | string[],
  initialTerm: number = 0,
): Variant[] => {
  const termUnits = Array.isArray(termUnit) ? termUnit : [termUnit];

  const newVariants: Variant[] = [];
  variants.forEach((v) => {
    const discounts = getDiscounts(v.discounts, offerTypes);
    // Paid package variants can have either base or no discounts
    if (
      termUnits.includes(v.initialTermUnit) &&
      ((offerTypes.includes(OfferType.Base_discount) && !v.discounts?.length) || discounts?.length > 0) &&
      toDays(v.initialTerm, v.initialTermUnit) > initialTerm
    ) {
      const variant = cloneDeep(v);
      variant.discounts = discounts;
      newVariants.push(variant);
    }
  });
  return newVariants;
};

/**
 * Better version than getEligiblePackagesAndVariants() which can be replaced phase wise.
 * It's sole responsibility is to return only packages and its variants with given discountTypes
 */
export const filterEligiblePackages = (
  ePackages: EligiblePackageInfo[],
  offerTypes: OfferType[],
): EligiblePackageInfo[] => {
  return ePackages.reduce((eligiblePackages, currentPackage) => {
    if (currentPackage.packageType !== PackageType.Regular) {
      return eligiblePackages;
    }
    const trialVariants = filterVariants(currentPackage.variants, offerTypes);

    if (trialVariants.length > 0) {
      const clonedPackage = cloneDeep(currentPackage);
      clonedPackage.variants = trialVariants;
      eligiblePackages.push(clonedPackage);
    }
    return eligiblePackages;
  }, [] as EligiblePackageInfo[]);
};

/**
 * It's sole responsibility is to return only variants with given discountTypes
 */
const filterVariants = (variants: Variant[], offerTypes: OfferType[]): Variant[] => {
  return (
    variants
      ?.filter((variant) => {
        const discounts = getDiscounts(variant.discounts, offerTypes);
        return discounts?.length > 0;
      })
      .map((variant) => {
        const clonedVariant = cloneDeep(variant);
        clonedVariant.discounts = getDiscounts(variant.discounts, offerTypes);
        return clonedVariant;
      }) || []
  );
};

/**
 * Returns tiered eligible packages that are higher than currently subscribed (tier = 1 being higher)
 */
export const getTierPackagesHigherThanSubscribed = (
  eligiblePackages: EligiblePackageInfo[],
  highestSubscription: SubscribedPackageInfo | null,
): EligiblePackageInfo[] => {
  if (!highestSubscription) {
    return eligiblePackages;
  }
  return eligiblePackages.filter((ePkg) => {
    const {
      tier,
      variant: { initialTerm, initialTermUnit },
    } = highestSubscription;

    if (ePkg.tier !== null && tier !== null && ePkg.tier < tier) {
      return true;
    }
    if (ePkg.tier === tier) {
      ePkg.variants = ePkg.variants.filter(
        (variant) => toDays(variant.initialTerm, variant.initialTermUnit) > toDays(initialTerm, initialTermUnit),
      );
      return ePkg.variants.length > 0;
    }
    return false;
  });
};

/**
 * Returns a-la-carte eligible packages and variants that higher than currently subscribed
 */
export const getAlaCartePackagesHigherThanSubscribed = (
  eligiblePackages: EligiblePackageInfo[],
  subscriptions: SubscribedPackageInfo[],
): EligiblePackageInfo[] => {
  if (!eligiblePackages?.length || !subscriptions?.length) {
    return eligiblePackages;
  }

  return eligiblePackages.filter((ePackage) => {
    const { id: ePackageId, tier, variants } = ePackage;
    if (tier !== null) {
      return;
    }
    const subscribedPackage = subscriptions.find((sPkg) => sPkg.packageId === ePackageId);
    if (!subscribedPackage) {
      return true;
    }
    const { initialTerm, initialTermUnit } = subscribedPackage;
    ePackage.variants = variants.filter(
      (v) => toDays(v.initialTerm, v.initialTermUnit) > toDays(initialTerm, initialTermUnit),
    );
    return ePackage.variants.length > 0;
  });
};

export const isPackageMatch = (ePkg: EligiblePackageInfo, pkgTier: number) =>
  ePkg.packageType === PackageType.Regular && (!pkgTier || ePkg.tier === pkgTier);

export const isTermMatch = (variant: VariantInfo, termUnit: string, term: number) => {
  return variant.initialTermUnit === termUnit && (!term || variant.initialTerm === term);
};

export const isPackageFutureDated = ({ termStartDate }: SubscribedPackageInfo | SubscribedPackage) => {
  return isFuture(parse(termStartDate || '', 'yyyy-MM-dd', new Date()));
};

/**
 * Returns subscribed package price
 */
export const getSubscribedPackagePrice = (subscribedPackage: SubscribedPackageInfo): number => {
  const {
    amountWithoutTax,
    variant: { discounts, listPrice },
  } = subscribedPackage;

  const isPromocode = discounts.some((discount) => discount.offerType === 'PROMO_CODE');
  const price = isPromocode ? listPrice : amountWithoutTax;

  return price;
};
