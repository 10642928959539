import React, { useMemo } from 'react';
import { ComponentMapping } from '@components/component-mappings';
import ContentfulComponent, { ContentfulFile } from '@customTypes/ContentfulComponent';
import useFilterByServices from '@hooks/useFilterByServices';
import InfoCardContent, { TContentColor } from '@components/InfoCard/InfoCardContent';
import ContentRenderer from '@components/ContentRenderer';

export type ActionCardProps = {
  name: string;
  component: ComponentMapping;
  title: string;
  icon: ContentfulFile;
  componentType: string;
  subtitle: string;
  description: string;
  services?: string[];
  content: Array<ContentfulComponent>;
};

const ActionCard = (props: ActionCardProps) => {
  const filterByServices = useFilterByServices();
  const hasServicesToSeeContent = useMemo(() => !!filterByServices([props]).length, [filterByServices, props]);

  return hasServicesToSeeContent ? (
    <InfoCardContent
      component={
        <ContentRenderer name="action-card-content" content={[{ ...props, componentType: props.component }]} />
      }
      description={''}
      contentColor={TContentColor.DARK}
    />
  ) : null;
};

export default ActionCard;
