import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { send, assign } from 'xstate';
import { buildSubscribedPackages } from '@manageSubscription/builders';
import { isLastInactivePackageLessThanXDays } from '@manageSubscription/utils';
import { getAgreementOnFile, getAgreementTemplate, getPackagesSubscribed } from '../../../../services/cps';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, TermsAndConditionsFlowContext } from '../Types';
import config from '@config/config';
import { Environment } from '@cv/portal-common-lib/ajax/constants';

export const termsAndConditionsFlow: ComponentFlowStateConfig<TermsAndConditionsFlowContext> = () => ({
  initial: 'idle',
  id: ComponentRoutes.termsAndConditions,
  context: {
    termsAndConditions: undefined,
  },
  states: {
    idle: {
      always: [{ target: 'navigate', cond: 'hasActiveSubscription' }],
      entry: 'setLoading',
      invoke: {
        id: 'getAgreementOnFile',
        src: ({ subscriptionProps, queryClient }) =>
          queryClient.fetchQuery(['agreementOnFile'], () => getAgreementOnFile(subscriptionProps)),
        onDone: [{ target: 'getAgreementTemplate', cond: 'isDataEmpty' }, { target: 'getPackagesSubscribed' }],
        onError: 'getAgreementTemplate',
      },
    },
    navigate: {
      entry: ['unsetLoading', 'navigateForward'],
    },
    getPackagesSubscribed: {
      invoke: {
        id: 'getPackagesSubscribed',
        src: (context) =>
          getPackagesSubscribed({
            ...context.subscriptionProps,
            includeInactivePackages: config.getEnvironmentEnum() === Environment.PROD,
          }),
        onDone: [
          {
            actions: ['unsetLoading', 'navigateForward'],
            cond: (_, event) => {
              return isLastInactivePackageLessThanXDays(buildSubscribedPackages(event.data));
            },
          },
          { target: 'getAgreementTemplate' },
        ],
        onError: {
          actions: ['navigateForward', 'unsetLoading'],
        },
      },
    },
    getAgreementTemplate: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.termsAndConditions },
        }),
      ],
      invoke: {
        id: 'getAgreementTemplate',
        src: (context) =>
          context.queryClient.fetchQuery(['termsAndConditions'], () =>
            getAgreementTemplate(context.subscriptionProps, AgreementType.Terms_of_service),
          ),
        onDone: {
          actions: [assign({ termsAndConditions: (_, event) => event.data }), 'unsetLoading'],
        },
        onError: {
          actions: ['navigateForward', 'unsetLoading'],
        },
      },
      on: {
        ACCEPT_TERMS: { actions: 'navigateForward' },
        DECLINE_TERMS: { actions: 'navigateToCancel' },
      },
    },
  },
});
