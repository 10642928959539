import React from 'react';
import { DIALOG_ACTIONS } from '@redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import useLogout from '@hooks/useLogout';
import { DialogState } from '@redux/reducers/dialog';
import Dialog from './Dialog';

const ModalReduxWrapper = () => {
  const { show, configs } = useSelector(({ dialogReducer }: { dialogReducer: DialogState }) => dialogReducer);
  const { title, message, reLogin } = configs;

  const dispatch = useDispatch();
  const logout = useLogout();

  const closeDialog = () => {
    dispatch({ type: DIALOG_ACTIONS.CLOSE });
    if (reLogin) {
      logout();
    }
  };

  return show ? (
    <Dialog title={title} onOk={closeDialog} labelOk="OK">
      {message}
    </Dialog>
  ) : null;
};

export default ModalReduxWrapper;
