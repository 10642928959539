import React from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';

var md = new Remarkable('full', {
  html: true,
  breaks: true,
}).use(linkify);

type RequiredChildrenProps = {
  children: string;
  text?: string;
  className?: string;
};

type RequiredTextProps = {
  children?: string;
  text: string;
  className?: string;
};

const Markdown = ({ children, text, className }: RequiredChildrenProps | RequiredTextProps) => {
  const html = md.render(children || text).trim();
  return <p className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Markdown;
