import { invert } from 'lodash';

/*
    Contentful doesn't support few locales we need (en-PR and en-HI) so
    as a temporary solution to handle unsupported contentful locales
    we need to map en-PR (english puerto-rico) to en-NA (english namibia)
    en-HI (english hawaii) to en-ZW (english zimbabwe)
    we choose en-ZW and en-NA as fake locales as those locales some which most likely won't
    be used in our system and also to reflect same configs as ENS has
  */
const localeSubstitutions = {
  'en-PR': 'en-NA',
  'en-HI': 'en-ZW',
};

type keys = keyof typeof localeSubstitutions;

const locales = {
  forward: localeSubstitutions,
  backward: invert(localeSubstitutions),
};

export function mapLocale(language: string): string {
  if (language in locales.forward) {
    return locales.forward[language as keys];
  }
  return language;
}

export function unMapLocale(language: string): string {
  if (language in locales.backward) {
    return locales.backward[language as keys];
  }
  return language;
}
