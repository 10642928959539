import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import spanish from 'i18n-iso-countries/langs/es.json';

const useIsoCountries = () => {
  countries.registerLocale(english);
  countries.registerLocale(spanish);

  return countries;
};

export default useIsoCountries;
