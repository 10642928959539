import { useApi } from '@api';
import React from 'react';
import { useMachine } from '@xstate/react';
import { OTP_STEP, securityAuthenticationMachine, STATUS } from './SecurityAuthentificationStateMachine';

import { LoaderBackdrop } from '@components/Loader';
import { OtpForm } from '@components-lib';
import SecurityAuthenticationForm from './SecurityAuthenticationForm';
import UserInfo from '@components/Login/UserInfo';
import styles from './SecurityAuthentication.module.css';
import AnalyticsTrackView from '@components/Analytics';
import { generateLabelsObjects, ContentfulContent } from '@utils/labels';
import { useHistory } from 'react-router-dom';
import Widget from '@components/Widget';
import urlSearchParams from '@utils/urlSearchParams';
import ErrorModal from '@components/Login/ErrorModal';
import usePreferencesSelector from '@redux/selectors/preferences';
import { CommonFormAnalyticsWrapper } from '@app/components-lib/components/Analytics';
import { useConfig } from '@components/ConfigProvider';

enum AnalyticsEventNameSuffix {
  FORM = 'Enter Code',
  ACCESS = 'SAC Choice',
}

type PageListData = {
  contentSections?: ContentfulContent[];
  analyticsEventName?: string;
};

export type BodyTextSource = 'email' | 'sms' | 'RRS-IVA';

type rrsParams = {
  sxmkey?: string;
  discountid?: string;
  u?: string;
  v?: string;
  ti?: string;
  exchangeToken?: string;
  lang?: string;
  flowName?: string;
  tk?: string;
};

const SecurityAuthentication = ({ contentSections, analyticsEventName }: PageListData) => {
  const preferences = usePreferencesSelector();
  const { otpTimeout } = preferences;
  const securityAuthenticationLabels = generateLabelsObjects(contentSections?.[0]?.content || []);
  const {
    title: securityAuthenticationTitle,
    otpErrorMessage,
    ...restSecurityAuthenticationLabels
  } = securityAuthenticationLabels;

  const api = useApi();
  const history = useHistory();

  const config = useConfig();

  const {
    sxmkey = '',
    discountid: discountId,
    u: userId,
    v: vehicleId,
    ti: externalTenantUserId,
    exchangeToken = '',
    flowName,
    lang,
    tk,
    otp,
    email,
  } = urlSearchParams.getAll<rrsParams>();

  const { nonSsoErrorRedirect = '/login' } = usePreferencesSelector();

  const [state, send] = useMachine(securityAuthenticationMachine, {
    context: {
      sxmkey,
      discountId,
      userId,
      vehicleId,
      externalTenantUserId,
      exchangeToken,
      flowName,
      lang,
      api,
      config,
      history,
      otpTimeout,
      nonSsoErrorRedirect,
      tk,
      otp,
      email,
    },
  });

  const {
    showNoContactsOnFileError,
    showPrimarySubscriberError,
    contacts,
    status,
    otpDigitsFormat,
    bodyTextSource,
    isLoggedIn,
    otpStep,
    showFormWithEmptyValues,
  } = state.context;

  const handleValidateOTPCode = async (otp: string) => {
    send({
      type: 'validateOTP',
      otp,
    });
  };

  const handleOTPCodeSend = async (channel: string) => {
    send({
      type: 'sendOTP',
      channel,
    });
  };

  const setIdleStatus = () =>
    send({
      type: 'setIdleStatus',
    });

  const tryAgain = () =>
    send({
      type: 'tryAgain',
    });

  const goBackToOTPFormStep = () => {
    send({ type: 'goBackToOTPFormStep' });
  };

  const Page = {
    [OTP_STEP.FORM]: (
      <SecurityAuthenticationForm
        onSubmit={handleOTPCodeSend}
        labels={securityAuthenticationLabels}
        contacts={contacts}
        showFormWithEmptyValues={showFormWithEmptyValues}
      />
    ),
    [OTP_STEP.ACCESS]: (
      <Widget title={securityAuthenticationTitle?.primary}>
        <CommonFormAnalyticsWrapper name="validate otp form">
          <OtpForm
            onSubmit={handleValidateOTPCode}
            labels={restSecurityAuthenticationLabels}
            apiError={status === STATUS.ERROR}
            onChange={setIdleStatus}
            inputDigitsFormat={otpDigitsFormat}
            bodyTextSource={bodyTextSource as BodyTextSource}
            tryAgain={!!(userId && vehicleId) ? tryAgain : undefined}
            errorMessage={otpErrorMessage}
          />
        </CommonFormAnalyticsWrapper>
      </Widget>
    ),
  };

  return (
    <div className={styles['SecurityAuthentication']}>
      {showPrimarySubscriberError && (
        // TODO: combine all PS role error dialogs in one place
        <ErrorModal
          errorMessage={preferences.noLinkedVehiclesErrorMessage ?? preferences.notLinkedVehicleErrorMessage}
          okLabel={preferences.ownerPortalButtonLabel}
          onOk={() => {
            window.location.replace(preferences.ownerPortalLink);
          }}
        />
      )}
      {showNoContactsOnFileError && (
        <ErrorModal
          errorMessage={preferences.noContactsOnFileErrorMessage || preferences.defaultErrorMsg}
          okLabel={preferences.ownerPortalButtonLabel}
          onOk={() => {
            window.location.replace(preferences.ownerPortalLink);
          }}
        />
      )}
      {status === STATUS.LOADING && <LoaderBackdrop />}
      {isLoggedIn && <UserInfo goBack={goBackToOTPFormStep} labels={securityAuthenticationLabels} />}
      <AnalyticsTrackView
        analyticsEventName={
          AnalyticsEventNameSuffix[otpStep] && `${analyticsEventName}${AnalyticsEventNameSuffix[otpStep]}`
        }
      >
        {Page[otpStep]}
      </AnalyticsTrackView>
    </div>
  );
};

export default SecurityAuthentication;
