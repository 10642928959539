import React from 'react';
import useInterceptor from '@api/interceptor';
import { Page } from '@customTypes/Navigation';
import { labelReducer } from '@utils/filter-label-dictionary';
import { ContentType } from 'contentful';
import { Modal } from '@components-lib';
import { Button } from '@components-lib';

type RegisterInterceptorProps = {
  apiErrors?: ContentType;
  pages?: Array<Page>;
};

const RegisterInterceptor = ({ apiErrors, pages }: RegisterInterceptorProps) => {
  const { error, handleClearError, handleOkModal } = useInterceptor(labelReducer(apiErrors), pages);

  return (
    <Modal
      open={!!error}
      title={error?.title}
      onClose={handleClearError}
      secondaryControl={
        <Button variant="outlined" onClick={handleClearError}>
          {error?.labelCancel || 'Cancel'}
        </Button>
      }
      primaryControl={
        <Button variant="contained" onClick={handleOkModal}>
          {error?.labelOk || 'Ok'}
        </Button>
      }
    >
      <div>{error?.message}</div>
    </Modal>
  );
};

export default React.memo(RegisterInterceptor);
