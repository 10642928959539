import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as H from 'history';
import { Path, Page } from '@customTypes/Navigation';
import useComputePaths from '@hooks/useComputePaths';

type NavigationContext = {
  initialPage?: string;
  currentPath: Path;
  paths: Path[];
  history: H.History;
  location: H.Location;
  navigateTo(location: H.Location): void;
  navigateTo(path: H.Path, state?: unknown): void;
};

const NavigationContext = React.createContext<NavigationContext | null>(null);

type NavigationProviderProps = {
  pagesList?: Page[];
  navigationJson?: {
    initial?: string;
    initialUnauth?: string;
  };
  children?: any;
};

export const NavigationProvider = ({ pagesList = [], navigationJson, children }: NavigationProviderProps) => {
  const history = useHistory();
  const location = useLocation();
  const computePaths = useComputePaths(pagesList);
  const { currentPath, paths } = computePaths();

  return (
    <NavigationContext.Provider
      value={{
        initialPage: navigationJson?.initial,
        initialPageUnauth: navigationJson?.initialUnauth,
        currentPath,
        paths,
        history,
        location,
        navigateTo: history.push,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export function useNavigation() {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationProvider');
  }
  return context;
}
