import { send } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const paymentFlow: ComponentFlowStateConfig<{}> = () => ({
  id: ComponentRoutes.payment,
  initial: 'pre',
  states: {
    pre: {
      always: [{ target: 'navigateForward', cond: 'skipPaymentScreen' }, 'idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.payment },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: 'navigateForward',
        [FlowEventName.NAVIGATE_BACK]: 'navigateBack',
      },
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateBack: {
      entry: 'navigateBack',
    },
  },
});
