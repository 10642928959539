import DeclineConfirmation, { DeclineConfirmationProps } from './TermsAndConditionsDeclineConfirmation';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@components-lib';
import { LoaderBackdrop } from '@components/Loader';
import styles from './TermsAndConditions.module.css';

type DeclineConfirmationLabels = Omit<DeclineConfirmationProps, 'onDecline' | 'onContinue'>;
type TermsAndConditionsProps = DeclineConfirmationLabels & {
  labelTitle: string;
  labelTermsConditionsInstructions: string;
  labelDecline: string;
  labelAgreeTerms: string;
  contentUrl: string;
  onDecline: () => void;
  onAccept: () => void;
};

const TermsAndConditions = ({
  labelTitle,
  labelTermsConditionsInstructions,
  labelDecline,
  labelAgreeTerms,
  labelDeclineConfirmationTitle,
  labelDeclineConfirmationNote,
  labelDeclineConfirmationDescription,
  labelDeclineConfirmation,
  labelDeclineYes,
  labelDeclineNo,
  contentUrl,
  onDecline,
  onAccept,
}: TermsAndConditionsProps) => {
  const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>(contentUrl ? 'loading' : 'error');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [acceptDisabled, setAcceptDisabled] = useState(Boolean(IntersectionObserver));
  const [declineConfirmation, setDeclineConfirmation] = useState(false);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadTermsAndConditions = async () => {
      try {
        const data = await fetch(contentUrl);
        if (!data.ok) {
          throw new Error(`HTTP error ${data.status}`);
        }
        setTermsAndConditions(await data.text());
        setStatus('loaded');
      } catch (e) {
        setStatus('error');
      }
    };

    if (contentUrl) {
      loadTermsAndConditions();
    }
  }, [contentUrl]);

  // Enable accept button when terms and conditions were scrolled to the end
  useEffect(() => {
    if (content.current && termsAndConditions) {
      const onTermsAndConditionsScroll = (evt: Event) => {
        const target = evt.target as HTMLDivElement;
        if (Math.abs(target.scrollTop + target.clientHeight - target.scrollHeight) < 20) {
          setAcceptDisabled(false);
        }
      };

      content.current.addEventListener('scroll', onTermsAndConditionsScroll, { passive: true });

      return () => {
        content.current?.removeEventListener('scroll', onTermsAndConditionsScroll);
      };
    }
  }, [content, termsAndConditions]);

  // Open all Terms And Conditions links in a new window
  useEffect(() => {
    if (content.current) {
      const links = content.current.querySelectorAll<HTMLAnchorElement>('a[href]');
      links.forEach((link) => {
        if (link.protocol.startsWith('http')) {
          const rel = link.getAttribute('rel') || '';
          link.setAttribute('rel', `${rel} noopener noreferrer`);
          link.setAttribute('target', '_blank');
        }
      });
    }
  }, [content]);

  const handleDeclineConfirm = () => {
    setDeclineConfirmation(true);
  };

  return (
    <div className={styles.container}>
      {status === 'loading' && <LoaderBackdrop />}
      {declineConfirmation && (
        <DeclineConfirmation
          labelDeclineConfirmationTitle={labelDeclineConfirmationTitle}
          labelDeclineConfirmationNote={labelDeclineConfirmationNote}
          labelDeclineConfirmationDescription={labelDeclineConfirmationDescription}
          labelDeclineConfirmation={labelDeclineConfirmation}
          labelDeclineYes={labelDeclineYes}
          labelDeclineNo={labelDeclineNo}
          onDecline={onDecline}
          onContinue={onAccept}
        />
      )}
      <h1 className={styles.title}>{labelTitle}</h1>
      <div className={styles.body}>
        <div className={styles.content} ref={content} dangerouslySetInnerHTML={{ __html: termsAndConditions || '' }} />
      </div>
      <div className={styles.instructions}>
        <p>{labelTermsConditionsInstructions}</p>
      </div>
      <div className={styles.buttons}>
        <Button variant="outlined" onClick={handleDeclineConfirm}>
          {labelDecline}
        </Button>
        <Button variant="contained" onClick={onAccept} disabled={acceptDisabled}>
          {labelAgreeTerms}
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
