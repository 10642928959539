import { TargetContext } from '@components/PortalTargetComponent/PortalTargetContext';

export type TargetContent = { targetTestName?: string };

const filterTargetContent = <T extends TargetContent>(content: T[], { tests }: TargetContext): T[] =>
  content.filter(
    ({ targetTestName }) =>
      !targetTestName || !tests.length || tests.some(({ contentful_name }) => contentful_name === targetTestName),
  );

export default filterTargetContent;
