// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cTXQyx7x_BLTOOYsJ1iZ {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 55px;
  width: 100%;
  background-color: #606060;
  line-height: 20px;
  font-weight: 400;
  color: var(--theme-color-white);
  font-family: var(--theme-font-primary);
  font-size: 15px;
  text-align: center;
  justify-content: center;
  padding: 16px;
  display: flex;
  z-index: 998;
}

@media screen and (max-width: 480px) {
  .cTXQyx7x_BLTOOYsJ1iZ {
    height: auto;
  }
}

.cTXQyx7x_BLTOOYsJ1iZ a {
  background-color: #606060;
  color: var(--theme-color-white);
  font-weight: 700;
  text-decoration: underline !important;
  margin: -1px;
}

.cTXQyx7x_BLTOOYsJ1iZ a:hover {
  color: var(--theme-color-white);
}

.zl5ulNyGs0pAK2UTRbQt {
  padding: 0 !important;
  margin-left: 15px;
  aspect-ratio: 1/1;
  min-width: 0;
}

.zl5ulNyGs0pAK2UTRbQt > img {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/CookieBar.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;EAC/B,sCAAsC;EACtC,eAAe;EACf,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,gBAAgB;EAChB,qCAAqC;EACrC,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".Cookie-div {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  height: 55px;\n  width: 100%;\n  background-color: #606060;\n  line-height: 20px;\n  font-weight: 400;\n  color: var(--theme-color-white);\n  font-family: var(--theme-font-primary);\n  font-size: 15px;\n  text-align: center;\n  justify-content: center;\n  padding: 16px;\n  display: flex;\n  z-index: 998;\n}\n\n@media screen and (max-width: 480px) {\n  .Cookie-div {\n    height: auto;\n  }\n}\n\n.Cookie-div a {\n  background-color: #606060;\n  color: var(--theme-color-white);\n  font-weight: 700;\n  text-decoration: underline !important;\n  margin: -1px;\n}\n\n.Cookie-div a:hover {\n  color: var(--theme-color-white);\n}\n\n.Cookie-button {\n  padding: 0 !important;\n  margin-left: 15px;\n  aspect-ratio: 1/1;\n  min-width: 0;\n}\n\n.Cookie-button > img {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cookie-div": `cTXQyx7x_BLTOOYsJ1iZ`,
	"Cookie-button": `zl5ulNyGs0pAK2UTRbQt`
};
export default ___CSS_LOADER_EXPORT___;
