import { infoPageEnrollmentFlow } from './InformationPageEnrollment';
import { infoPageSubscriptionFlow } from './InformationPageSubscription';
import { manageTrialPackagesFlow } from './ManageTrialPackages';
import { managePromoPackagesFlow } from './ManagePromoPackages';
import { managePromoCodePackagesFlow } from './ManagePromoCodePackages';
import { managePaidPackagesFlow } from './ManagePaidPackages';
import { manageAddonsFlow } from './ManageAddOns';
import { userProfileFlow } from './UserProfile';
import { termsAndConditionsFlow } from './TermsAndConditions';
import { paymentFlow } from './Payment';
import { reviewOrderFlow } from './ReviewOrder';
import { confirmOrderFlow } from './ConfirmOrder';
import { reviewDowngradeOrderFlow } from './ReviewDowngradeOrder';
import { ComponentRoutes } from '../../../types';
import {
  InformationPageEnrollmentContainer,
  InformationPageSubscriptionContainer,
} from '@manageSubscription/InformationPages';
import {
  ManageAddOnsContainer,
  ManagePaidPackagesContainer,
  ManagePromoCodePackagesContainer,
  ManagePromoPackagesContainer,
  ManageTrialPackagesContainer,
} from '@manageSubscription/ManagePackages';
import { TermsAndConditionsContainer } from '@manageSubscription/Legal';
import UserProfileContainer from '@manageSubscription/UserProfile/UserProfileContainer';
import { PaymentWrapperContainer } from '@manageSubscription/Payment';
import { ConfirmOrderContainer, ReviewOrderContainer, ReviewDowngradeOrderContainer } from '@manageSubscription/Order';
import ErrorPage from '@manageSubscription/Error/Error';
import CheckIfEmailIsOnFileContainer from '@manageSubscription/UserProfile/CheckIfEmailIsOnFileContainer';
import { checkIfEmailIsOnFileFlow } from './CheckIfEmailIsOnFile';

// ================================================================== //
// THIS IS A MAP OF COMMON COMPONENTS AND FLOWS for the state machine //
// ================================================================== //
export const CommonComponentFlowMap = {
  [ComponentRoutes.infoPageEnrollment]: {
    component: InformationPageEnrollmentContainer,
    flow: infoPageEnrollmentFlow,
  },
  [ComponentRoutes.infoPageSubscription]: {
    component: InformationPageSubscriptionContainer,
    flow: infoPageSubscriptionFlow,
  },
  [ComponentRoutes.manageTrialPackages]: { component: ManageTrialPackagesContainer, flow: manageTrialPackagesFlow },
  [ComponentRoutes.managePromoPackages]: { component: ManagePromoPackagesContainer, flow: managePromoPackagesFlow },
  [ComponentRoutes.managePromoCodePackages]: {
    component: ManagePromoCodePackagesContainer,
    flow: managePromoCodePackagesFlow,
  },
  [ComponentRoutes.managePaidPackages]: { component: ManagePaidPackagesContainer, flow: managePaidPackagesFlow },
  [ComponentRoutes.manageAddOns]: { component: ManageAddOnsContainer, flow: manageAddonsFlow },

  [ComponentRoutes.userProfile]: { component: UserProfileContainer, flow: userProfileFlow },
  [ComponentRoutes.termsAndConditions]: { component: TermsAndConditionsContainer, flow: termsAndConditionsFlow },
  [ComponentRoutes.payment]: { component: PaymentWrapperContainer, flow: paymentFlow },
  [ComponentRoutes.reviewOrder]: { component: ReviewOrderContainer, flow: reviewOrderFlow },
  [ComponentRoutes.reviewDowngradeOrder]: {
    component: ReviewDowngradeOrderContainer,
    flow: reviewDowngradeOrderFlow,
  },
  [ComponentRoutes.confirmOrder]: { component: ConfirmOrderContainer, flow: confirmOrderFlow },
  [ComponentRoutes.checkIfEmailIsOnFile]: {
    component: CheckIfEmailIsOnFileContainer,
    flow: checkIfEmailIsOnFileFlow,
  },
  [ComponentRoutes.error]: { component: ErrorPage },
};
