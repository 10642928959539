import { AgreementBuilderRequest } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { getByTag } from 'locale-codes';
import { priceTotal, UserDetails, VehicleDetails } from '@manageSubscription';
import { PaymentInfo } from '@lib-payment/Types';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { OrderSummaryInfo, SubscribedPackageInfo } from '@manageSubscription/Types';

export class AgreementRequestBuilder {
  private readonly request: AgreementBuilderRequest;

  constructor() {
    this.request = {
      agreementType: AgreementType.Terms_of_service,
      packagesInformation: null,
      paymentInformation: null,
      userInformation: null,
      vehicleInformation: null,
    };
  }

  withAgreementType(agreementType: AgreementType): AgreementRequestBuilder {
    this.request.agreementType = agreementType;
    return this;
  }

  withPackagesInformation(orderSummary: OrderSummaryInfo, shouldIncludeTax: boolean): AgreementRequestBuilder {
    this.request.packagesInformation = toPackagesInfoData(orderSummary, shouldIncludeTax);
    return this;
  }

  withPaymentInformation(paymentInfo: PaymentInfo): AgreementRequestBuilder {
    this.request.paymentInformation = paymentInfo ? toPaymentData(paymentInfo) : null;
    return this;
  }

  withUserInformation(userDetails: UserDetails, locale: string): AgreementRequestBuilder {
    this.request.userInformation = toUserInfoData(userDetails, locale);
    return this;
  }

  withVehicleInformation(vehicleDetails: VehicleDetails): AgreementRequestBuilder {
    const vehicleInfo: AgreementBuilderRequest['vehicleInformation'] = {
      make: vehicleDetails.make,
      model: vehicleDetails.model,
      modelYear: vehicleDetails.year,
      vehicleColor: vehicleDetails.color,
      trim: vehicleDetails.trimLevel,
      vin: vehicleDetails.vin,
      telematicsProgramId: vehicleDetails.telematicsProgramId,
    };
    this.request.vehicleInformation = vehicleInfo;
    return this;
  }

  build(): AgreementBuilderRequest {
    return this.request;
  }
}

const toPackagesInfoData = (
  orderSummary: OrderSummaryInfo,
  shouldIncludeTax: boolean,
): AgreementBuilderRequest['packagesInformation'] => {
  const { packages, amountTotal, taxTotal } = orderSummary;

  const packagesInfo = toPackages(packages, shouldIncludeTax);
  return { pkgs: packagesInfo, totalPrice: amountTotal || '0.00', taxes: taxTotal || '0.00' };
};

const toPackages = (
  subscribedPackages: SubscribedPackageInfo[],
  shouldIncludeTax: boolean,
): AgreementBuilderRequest['packagesInformation']['pkgs'] => {
  return subscribedPackages
    ?.filter((p) => p.packageType === PackageType.Regular && p.active)
    .sort((p1, p2) => p2.amountWithoutTax - p1.amountWithoutTax)
    .map((p) => {
      const { marketingName, termServiceEndDate, amountWithoutTax, variant } = p;

      return {
        serviceName: marketingName,
        startDate: variant.startDate,
        endDate: termServiceEndDate,
        expdate: termServiceEndDate,
        price: priceTotal(amountWithoutTax, shouldIncludeTax && variant.taxTotal),
      };
    });
};

const toPaymentData = (paymentInfo: PaymentInfo): AgreementBuilderRequest['paymentInformation'] => {
  return {
    cardNumber: paymentInfo.cardNumber,
    cardType: paymentInfo.cardType,
    expiration: paymentInfo.expiration,
    cardHolderName: paymentInfo.cardHolderName,
  };
};

const toUserInfoData = (
  {
    title,
    firstName,
    lastName,
    middleName,
    suffix,
    primaryPhone,
    secondaryPhone,
    email,
    userId,
    preferredLanguage,
    billingAddress: { country, address1, city, state, postalCode },
    mailingAddress: { address1: homeAddress },
  }: UserDetails,
  locale: string,
): AgreementBuilderRequest['userInformation'] => {
  return {
    title,
    suffix,
    firstName,
    businessName: firstName,
    lastName,
    middleName,
    primaryPhone,
    secondaryPhone,
    emailAddress: email,
    address: address1,
    homeAddress,
    city,
    state,
    zip: postalCode,
    country,
    locale,
    userId,
    sendNotification: false,
    ...(preferredLanguage && { preferredLanguage: getByTag(preferredLanguage)?.name }),
  };
};
