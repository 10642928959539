import { useQuery } from '@tanstack/react-query';
import { fetchEntitlements } from '@api/requests/userInfo';
import { TokenTypes } from '@api/services/StoreService';
import { getCookie } from '@utils/cookies';

const useCpsRtsEntitlements = () => {
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const { data = { cpsEntitlements: [], rtsEntitlements: [] } } = useQuery({
    queryKey: ['userInfo'],
    queryFn: () => fetchEntitlements(accessToken),
  });

  return data;
};

export default useCpsRtsEntitlements;
