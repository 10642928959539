import config from '@config/config';

export const gapiLoaded = () => gapi.load('client', initializeGapiClient);

const initializeGapiClient = async () => {
  const apiKey = config.getOemValue('GOOGLE_API_KEY') || '';
  const calendarApi = config.getOemValue('GOOGLE_CALENDAR_DISCOVERY_DOC') || '';

  await gapi.client.init({ apiKey, discoveryDocs: [calendarApi] });
};

let tokenClient: google.accounts.oauth2.TokenClient;

export const gisLoaded = (outerSetters) => {
  const clientId = config.getOemValue('GOOGLE_CLIENT_ID') || '';
  const scope = config.getOemValue('GOOGLE_API_SCOPES') || '';

  tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: clientId,
    scope,
    callback: () => callbackAfterSignIn(outerSetters),
  });
};

const callbackAfterSignIn = ({ setCalendarList }) => {
  fetchCalendarList().then(setCalendarList);
};

export const fetchCalendarList = async () => {
  let response;
  const fallbackValue = { result: { items: [] } };

  try {
    response = await gapi.client.calendar.calendarList.list();
  } catch (_) {
    response = fallbackValue;
  }

  return formatCalendarList(response?.result?.items);
};

export const formatCalendarList = (calendarList?: gapi.client.calendar.CalendarListEntry[]) =>
  (calendarList || []).map(({ id, summary }) => ({
    calendarId: id,
    calendarName: summary,
    userToken: gapi.client.getToken()?.token_type,
  }));

export const signInToGoogle = () => {
  if (gapi.client.getToken() === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    tokenClient.requestAccessToken({ prompt: 'consent' });
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    tokenClient.requestAccessToken({ prompt: '' });
  }
};
