import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { OfferType, SalesChannel, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { AnySubscribedPackage } from '../Types';
import { TenantIds } from '../utils/productFlowMap';
import { VehicleResponse } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { FlowLocation } from '@lib-appRouter/flowMiddleware';
import { CustomFields } from '@api';

export type AccountFormData = CustomFields & {
  name?: string;
  email: string;
  primaryPhone: string;
  primaryPhoneType: string;
  secondaryPhone: string;
  secondaryPhoneType: string;
  billingPostalAddress1: string;
  billingPostalAddress2: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  mailingPostalAddress1: string;
  mailingPostalAddress2: string;
  mailingCity: string;
  mailingStateProvince: string;
  mailingPostalCode: string;
  mailingAddressSameAsHome: boolean;
  preferredTimeZone?: string;
  billingCountry?: string;
  mailingCountry?: string;
  _id: string;
};

export type AddressData = {
  street: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
};

export type TypeShortcut = '( m )' | '( c )' | '( t )' | '( o )';

export type PhoneData = {
  number: string;
  type: string;
  typeShortcut: TypeShortcut;
};

export type NotificationServiceData = {
  email: boolean;
  serviceId: string;
  serviceName: string;
  text: boolean;
};

export type AccountData = {
  name: string;
  email: string;
  sxmKey: string;
  primaryPhone: PhoneData;
  secondaryPhone: PhoneData;
  homeAddress: AddressData;
  mailingAddress: AddressData;
  billingCountry: string;
  mailingCountry: string;
  billingId: string;
  externalTenantUserId: string;
  homeAddressSameAsMailingAddress: boolean;
  userName: {
    givenName: string;
    fathersName: string;
    mothersName: string;
  };
  notificationPreferences: {
    email: string;
    phone: string;
    services?: NotificationServiceData[];
  };
  vehicleIds?: string[];
  _id: string;
  tenantId: string;
  isPinConfigured: boolean;
  billingAddress: {
    street: string;
    unit: string;
    city: string;
    state: string;
    zip: string;
  };
  title?: string;
  vehicles: VehicleDetails[];
};

type ExpireRenewTextType = {
  autoRenew: boolean;
  termEndDate: string;
  termServiceEndDate: string;
  renewLabel: string;
  expiresLabel: string;
};

type ContentfulField = string | Array<string> | number | boolean | object;

export type ContentfulComponent = {
  componentType: string;
  [key: string]: ContentfulField;
};

export type DiscountCriteria = {
  promoCode: string;
  pkgTier: number;
  term: number;
  termUnit?: TermUnit;
  pkgCount: number;
};
export type AuthNotificationStatus = 'SHOW' | 'HIDE' | 'ERROR';

export const AUTH_NOTIFICATION_STATUSES: Record<AuthNotificationStatus, AuthNotificationStatus> = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  ERROR: 'ERROR',
};
export interface SubscriptionProps {
  agreementType: AgreementType;
  correlationId?: string;
  publicKey?: string;
  sxmKey?: string;
  location?: FlowLocation;
  salesChannel: SalesChannel;
  includeInactivePackages?: boolean;
  accessToken: string;
  refreshToken?: string;
  supportedCreditCards: Array<string>;
  applePaySupportedNetworks: Array<string>;
  tenantId: TenantIds;
  locale: string;
  vehicleDetails: VehicleDetails;
  discountCriteria?: DiscountCriteria;
  userDetails: UserDetails;
  privacyPolicyUrl: string;
  customerCareNumber: string;
  customerCareCancelPackageNumber: string;
  config: any;
  pkgIdsToRemove?: string[];
  isSinglePaymentMethod: boolean;
  showDatesOnPromoPage: boolean;
  shouldMatchTrialWithCCRequired: boolean;
  isAuthCodeFunctionality?: boolean;
  hideDownloadAppOnConfirmation?: boolean;
  setLanguage?: (lang: string) => void;
  onSubscriptionConfirm?: () => void;
  onDone?: () => void;
  goBackToPortal?: () => void;
  handleCriticalError?: () => void;
  getSubscriptionInfo?: (props: SubscriptionProps) => void;
  setVehicleData: (vehicleData: Partial<VehicleResponse>) => void;
  redirectLogin: () => void;
  getAccountDetails?: (locale: string, userId: string, accessToken: string) => Promise<{ data: AccountData }>;
  updateAccount: (data: Partial<AccountFormData>, accessToken: string) => Promise<{ data: AccountData }>;
  expireRenewText: (args: ExpireRenewTextType) => string;
  isPackageToShow: (anyPackage: AnySubscribedPackage) => boolean;
  getOfferLegalText: (type: OfferType, isYearly?: boolean) => string | false;
  renderContentfulComponent: ({
    name,
    content,
    props,
  }: {
    name: string;
    content: ContentfulComponent[];
    props: object;
  }) => JSX.Element;
  getPhoneTypeShortcut: (type?: string) => TypeShortcut;
}

export type VehicleDetails = Partial<VehicleResponse>;

export interface UserDetails {
  homeAddressSameAsMailingAddress: boolean;
  isPinConfigured: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  email?: string;
  phone: string;
  primaryPhone: PhoneData;
  secondaryPhone: PhoneData;
  billingId: string;
  billingAddress: Address;
  mailingAddress: Address;
  externalTenantUserId: string;
  title?: string;
  preferredLanguage?: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  region?: string;
}
