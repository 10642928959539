// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssxv1090pMIBzPD9GiNX {
  width: 100px;
}

.xF3ACWtwPeBO38ekbSgq {
  display: block;
  cursor: pointer;
  height: 100%;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
}

.xF3ACWtwPeBO38ekbSgq > img {
  width: 100%;
  height: 100%;
}

.azR1Q0QriQcsA5P63VvD {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppLink/AppLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wrapper {\n  width: 100px;\n}\n\n.url {\n  display: block;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  max-width: 200px;\n  min-width: 100px;\n}\n\n.url > img {\n  width: 100%;\n  height: 100%;\n}\n\n.theme-button-size {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ssxv1090pMIBzPD9GiNX`,
	"url": `xF3ACWtwPeBO38ekbSgq`,
	"theme-button-size": `azR1Q0QriQcsA5P63VvD`
};
export default ___CSS_LOADER_EXPORT___;
