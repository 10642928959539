import { Content } from '../../../../styled-components/globalStyles';
import { PackagePrice } from '../../Price/PackagePrice';
import {
  CurrentPriceLabel,
  DiscountHeader,
  DiscountHeaderPackage,
  DiscountInfo,
  DiscountSection,
  IconSection,
  PromoCodeLabel,
  PromoCodeRow,
  PromoCodeSection,
  PromoCodeValue,
  RegularPriceLabel,
  ToggleButton,
} from '../styles';
import React, { useEffect, useMemo, useState } from 'react';
import { hasPackageAdded, replaceTemplateString } from '../../../utils';
import { EligiblePackageInfo, PackageSubscription } from '../../../Types';
import { OfferDetails, PackageDetails } from '../../../Discount';
import { formatDateByLocale } from '@cv/portal-common-lib';
import RegularPrice from '../../Price/RegularPrice';
import { CommonWebContent, ContentfulAssets, PackagesWebContent, PromoPackagesWebContent } from '../../..';
import Image from '../../../../components/Image';
import { useFlowMachineContextSubscriptionProps } from '@lib-appRouter/RouterContext';

export interface DiscountProps {
  promoPackage: EligiblePackageInfo;
  landingPagePromo?: boolean;
  packageSubscriptions: PackageSubscription[];
  commonWebContent: CommonWebContent;
  promoPackagesWebContent: PromoPackagesWebContent;
  packagesWebContent: PackagesWebContent;
  customerCareCancelPackageNumber: string;
  location: string;
  make: string;
  locale: string;
  tenantId: string;
  assets: ContentfulAssets;
  send: (event: any) => void;
  onClick?: () => void;
  disabled?: boolean;
  onPromoSelection: (promoPackage: EligiblePackageInfo) => void;
}

export const PromoPackage: React.FC<DiscountProps> = ({
  promoPackage,
  packageSubscriptions,
  location,
  commonWebContent,
  promoPackagesWebContent,
  customerCareCancelPackageNumber,
  packagesWebContent,
  make,
  locale,
  tenantId,
  assets,
  landingPagePromo = false,
  send,
  onClick,
  disabled,
  onPromoSelection,
}) => {
  const [isPromoSelected, setPromoSelected] = useState<boolean>(false);
  const {
    packageName,
    marketingName: pkgMarketingName,
    variant,
    isAutoSelected,
    disabled: packageDisabled,
    taxTotal = 0,
  } = promoPackage;
  const { discounts, listPrice, actualPrice, endDate, startDate, termServiceEndDate, renewable } = variant;
  const [discount] = discounts;
  const { promoCode, marketingName = '', description } = discount;

  const { closeButtonLabel, okButtonLabel, currencyLabel, shouldIncludeTax, detailsLabel } = commonWebContent;
  const {
    packageDetailsLabel,
    discount: { header, regularlyLabel, nowLabel, paidInFullLabel, getThisDealLabel, promoCodeLabel },
  } = promoPackagesWebContent;
  const {
    subscribedPackagesWebContent: { expiresLabel, renewLabel, startsLabel },
    eligiblePackagesWebContent: { perTermsLabel },
  } = packagesWebContent;

  const { expireRenewText, showDatesOnPromoPage } = useFlowMachineContextSubscriptionProps();

  const timeFrame = useMemo(
    () =>
      `${startsLabel} ${formatDateByLocale(startDate)} | ${expireRenewText({
        autoRenew: renewable,
        termEndDate: endDate,
        termServiceEndDate,
        renewLabel,
        expiresLabel,
      })} `,
    [renewable, endDate, termServiceEndDate, renewLabel, expiresLabel, expireRenewText],
  );

  // this useEffect calls onClick if it was passed when the promo package is set and packageSubscriptions is updated
  useEffect(() => {
    isPromoSelected && onClick?.();
  }, [packageSubscriptions, isPromoSelected]);

  const discountDetails = () => replaceTemplateString(header, { packageName: pkgMarketingName });

  return (
    <DiscountSection>
      <DiscountHeader>
        {marketingName || description}
        <DiscountHeaderPackage>{discountDetails()}</DiscountHeaderPackage>
        <PackageDetails
          assets={assets}
          promoPackage={promoPackage}
          okButtonLabel={okButtonLabel}
          detailsLabel={detailsLabel}
          packageDetailsLabel={packageDetailsLabel}
        />
      </DiscountHeader>
      <DiscountInfo>
        {promoCode && (
          <PromoCodeSection>
            <PromoCodeLabel>{promoCodeLabel}</PromoCodeLabel>
            <PromoCodeRow>
              <IconSection>
                <Image assets={assets} name="CheckCircleGreen" size="25px" />
              </IconSection>
              <PromoCodeValue>{promoCode}</PromoCodeValue>
            </PromoCodeRow>
          </PromoCodeSection>
        )}
        <RegularPriceLabel>
          <RegularPrice label={regularlyLabel}>
            <PackagePrice
              amountWithoutTax={listPrice}
              perTermsLabel={perTermsLabel}
              currencyLabel={currencyLabel}
              shouldIncludeTax={shouldIncludeTax}
              taxTotal={taxTotal}
            />
          </RegularPrice>
        </RegularPriceLabel>
        <CurrentPriceLabel>
          {nowLabel}{' '}
          <PackagePrice
            amountWithoutTax={actualPrice}
            perTermsLabel={perTermsLabel}
            currencyLabel={currencyLabel}
            shouldIncludeTax={shouldIncludeTax}
            taxTotal={taxTotal}
          />
        </CurrentPriceLabel>
        <Content>{paidInFullLabel}</Content>
        {showDatesOnPromoPage && timeFrame}
        <OfferDetails
          assets={assets}
          packageName={packageName}
          variant={variant}
          discount={discount}
          discountWebContent={promoPackagesWebContent.discount}
          okButtonLabel={okButtonLabel}
          customerCareCancelPackageNumber={customerCareCancelPackageNumber}
        />
        <ToggleButton
          variant={hasPackageAdded(packageSubscriptions, promoPackage) ? 'contained' : 'outlined'}
          onClick={() => onPromoSelection(promoPackage)}
          disabled={
            (!hasPackageAdded(packageSubscriptions, promoPackage) && disabled) || isAutoSelected || packageDisabled
          }
        >
          {getThisDealLabel}
        </ToggleButton>
      </DiscountInfo>
    </DiscountSection>
  );
};
