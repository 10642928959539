import React from 'react';
import {
  useFlowMachine,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
} from '@lib-appRouter/RouterContext';
import InformationPageSubscription from './InformationPageSubscription';
import { FlowEventName } from '@lib-appRouter/flowTypes';

const InformationPageSubscriptionContainerNNA = () => {
  const flowMachine = useFlowMachine();
  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  const { informationPageSubscriptionContent, assets, commonWebContent, trialPackagesWebContent } =
    useFlowMachineContextContent();

  const navigateForward = () => {
    flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
  };

  const imageData = assets['InfoBackground'];
  const headerLogo = assets['headerLogoInfoPage'];

  return (
    <InformationPageSubscription
      informationPageSubscriptionContent={informationPageSubscriptionContent}
      commonWebContent={commonWebContent}
      trialPackagesWebContent={trialPackagesWebContent}
      headerLogoData={headerLogo}
      bgImageData={imageData}
      subscribedPackages={subscribedPackages}
      navigateForward={navigateForward}
    />
  );
};

export default InformationPageSubscriptionContainerNNA;
