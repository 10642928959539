import React from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import styles from './Calendar.module.css';

export type TopRightIconProps = {
  isReading: boolean;
  onEdit: () => void;
  onClose: () => void;
};

const TopRightIcon = ({ isReading, onEdit, onClose }: TopRightIconProps) => {
  const onClick = isReading ? onEdit : onClose;
  const Icon = isReading ? HiPencilAlt : IoMdClose;
  return (
    <button className={styles.calendarTopRightIcon} onClick={onClick} title={isReading ? 'Edit' : 'Close'}>
      <Icon size={18} color="white" />
    </button>
  );
};

export default TopRightIcon;
