// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oQBwVfgxJLkfsi9a9XEn {
  position: relative;
}

.p_j5NihZrs_Y6gl2mLP9 {
  cursor: pointer;
  padding: 0.625rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.p_j5NihZrs_Y6gl2mLP9 > .avSJ2sncUCt_VVAWx5UA {
  transition: transform 0.2s ease;
}

.avSJ2sncUCt_VVAWx5UA.erJDP1tkfYeoCrCWsCZP {
  transform: rotate(180deg);
}

.avSJ2sncUCt_VVAWx5UA.gJeRMuFdj3Ix1Le1YEbk {
  transform: rotate(0deg);
}

.RD3ArYJy261zVyDCyJaH {
  transition: height 0.15s ease-out;
  overflow: hidden;
  height: auto;
}

.RD3ArYJy261zVyDCyJaH > p {
  padding: 1rem;
  margin: 0;
}

.xpEOnDA8DDxvDUdEM8Hv {
  visibility: hidden;
  position: fixed;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf","sourcesContent":[".accordion {\n  position: relative;\n}\n\n.accordion-title {\n  cursor: pointer;\n  padding: 0.625rem 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.accordion-title > .toggle-icon {\n  transition: transform 0.2s ease;\n}\n\n.toggle-icon.open {\n  transform: rotate(180deg);\n}\n\n.toggle-icon.close {\n  transform: rotate(0deg);\n}\n\n.accordion-body {\n  transition: height 0.15s ease-out;\n  overflow: hidden;\n  height: auto;\n}\n\n.accordion-body > p {\n  padding: 1rem;\n  margin: 0;\n}\n\n.hidden {\n  visibility: hidden;\n  position: fixed;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `oQBwVfgxJLkfsi9a9XEn`,
	"accordion-title": `p_j5NihZrs_Y6gl2mLP9`,
	"toggle-icon": `avSJ2sncUCt_VVAWx5UA`,
	"open": `erJDP1tkfYeoCrCWsCZP`,
	"close": `gJeRMuFdj3Ix1Le1YEbk`,
	"accordion-body": `RD3ArYJy261zVyDCyJaH`,
	"hidden": `xpEOnDA8DDxvDUdEM8Hv`
};
export default ___CSS_LOADER_EXPORT___;
