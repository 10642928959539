import React from 'react';
import { Content, HSpace } from '../../styled-components/globalStyles';
import { HeaderTitle, Section } from './styles';
import { PaymentInfo } from '../../payment/Types';
import { useFlowMachineContextContent } from '../../Router/RouterContext';

interface PaymentDetailsProps {
  paymentInfo: PaymentInfo;
}

export const PaymentDetails = ({ paymentInfo }: PaymentDetailsProps) => {
  const content = useFlowMachineContextContent();
  const {
    cardType,
    cardNumber,
    cardHolderName,
    addressLine1,
    addressLine2 = '',
    city,
    state = '',
    zipCode,
  } = paymentInfo;

  const location = () => {
    const stateWithZip = state ? `${state} ${zipCode}` : zipCode;
    return `${city}, ${stateWithZip}`;
  };
  return (
    <div>
      <Section>
        <HeaderTitle>{content.paymentMethodLabel}</HeaderTitle>
        <Content>{cardHolderName}</Content>
        <Content>{cardType}</Content>
        <Content>
          {content.cardNumberEndingLabel}
          <HSpace />
          {cardNumber.substr(-4)}
        </Content>
      </Section>
      <Section>
        <HeaderTitle>{content.billingAddressLabel}</HeaderTitle>
        <Content>{cardHolderName}</Content>
        <Content>{addressLine1}</Content>
        {addressLine2 && <Content>{addressLine2}</Content>}
        <Content>{location()}</Content>
      </Section>
    </div>
  );
};
