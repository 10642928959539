import { styled } from '@mui/material';

export const InputGroup = styled('div')`
  position: relative;
`;

export const Label = styled('label')`
  position: absolute;
  background: #fff;
  margin: 0.8rem 0.8rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
`;

export const InputField = styled('input')`
  width: 100%;
  outline: none;
  padding: 16px;
  border-radius: 2px;
  margin: 1.5rem 0 0.2rem 0;
  font-size: 0.875rem;
  font-family: inherit;
  border: 1px solid #666666;
  background: #fff 0% 0% no-repeat padding-box;
`;

export const Message = styled('label')`
  display: block;
  text-align: left;
  font-size: 0.875rem;
  color: #c71444;
`;
