import React from 'react';
import { useModal } from '../../components/Modal';
import { YesNoModal } from '../../components/Modal/YesNoModal';
import { useFlowMachine, useFlowMachineContextContent } from '../../Router/RouterContext';
import { useSelector } from '@xstate/react';
import TermsAndConditions from './TermsAndConditions';

export const TermsAndConditionsContainer = () => {
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const flowMachine = useFlowMachine();
  const termsAndConditions = useSelector(flowMachine, (state) => state.context.termsAndConditions);
  const { isLoading } = useSelector(flowMachine, (state) => state.context.loading);
  const { termsAndConditionsWebContent, assets } = useFlowMachineContextContent();
  const {
    declineTerms: { declineTermsHeader, declineTermsDescription, message, declineTermsLabel, continueEnrollLabel },
  } = termsAndConditionsWebContent;

  const { isShown: isDeclineTermsModalShown, toggleModal: toggleDeclineTermsModal } = useModal();

  const acceptTerms = () => {
    flowMachine.send({ type: 'ACCEPT_TERMS' });
  };

  const declineTerms = () => {
    toggleDeclineTermsModal();
    flowMachine.send({ type: 'DECLINE_TERMS' });
  };

  const declineTermsConfirmation = (
    <YesNoModal
      assets={assets}
      imgName={'Warning'}
      header={declineTermsHeader}
      description={declineTermsDescription}
      message={message}
      yesText={declineTermsLabel}
      noText={continueEnrollLabel}
      onYes={declineTerms}
      onNo={toggleDeclineTermsModal}
    />
  );
  return (
    <TermsAndConditions
      assets={assets}
      declineTermsConfirmation={declineTermsConfirmation}
      acceptTerms={acceptTerms}
      isDeclineTermsModalShown={isDeclineTermsModalShown}
      toggleDeclineTermsModal={toggleDeclineTermsModal}
      isLoading={isLoading}
      termsAndConditions={termsAndConditions}
      termsAgreed={termsAgreed}
      setTermsAgreed={setTermsAgreed}
      termsAndConditionsWebContent={termsAndConditionsWebContent}
    />
  );
};
