// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HDGOYdeLyLu0Qm3J1xjG,
.dkqvq1ZgevCGJFi6NtNW {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dkqvq1ZgevCGJFi6NtNW {
  margin-right: 1rem;
}

.ZuUlAhhhf4B5U02YovX2 {
  color: var(--theme-color-white);
}
`, "",{"version":3,"sources":["webpack://./src/components/DaysSelect/DaysSelect.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".content-container,\n.days-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.days-container {\n  margin-right: 1rem;\n}\n\n.toggle-all-button {\n  color: var(--theme-color-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-container": `HDGOYdeLyLu0Qm3J1xjG`,
	"days-container": `dkqvq1ZgevCGJFi6NtNW`,
	"toggle-all-button": `ZuUlAhhhf4B5U02YovX2`
};
export default ___CSS_LOADER_EXPORT___;
