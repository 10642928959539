// Contentful
export const PREFERRED_LANGUAGES_DICT_KEY = 'preferred-languages-dict';
export const PACKAGE_TERMS_DICT_KEY = 'package-terms-dict';
export const PACKAGE_PER_TERM_DICT_KEY = 'package-per-term-dict';
export const OFFER_DETAILS_DICT_KEY = 'offer-details-dict';
export const PACKAGE_UPSELL_HEADERS_DICT_KEY = 'package-upsell-headers-dict';
export const INFORMATION_SUBSCRIPTION_PAGE = 'information-subscription-page';
export const INFORMATION_ENROLLMENT_PAGE = 'information-enrollment-page';
export const USER_PROFILE_PAGE = 'user-profile-page';
export const SECRET_QUESTIONS_LIST = 'secret-questions-list';
export const DISCOUNT_OFFER_ADDITIONAL_LEGAL_TEXT = 'discount-offer-additional-legal-text';
export const AUTH_CODE_FORM = 'auth-code-form';
export const CARD_TYPES = 'card-types';
export const MEXICO_STATES_DICT_KEY = 'mexico-states-dict';
export const RRS_SOURCES = ['RRS-Dealer', 'RRS-IVA'];
export const PACKAGE_ORDER = 'package-order';
export const PROMO_PACKAGE_ORDER = 'promo-package-order';
