import { AlertsData, Alerts } from '@api/types';
import { GetBreachResponse } from '@cv/portal-rts-lib';
import { SvcRequest } from '@cv/portal-rts-lib/models';

import { ALERTS_ACTIONS } from '../actions';
import { StateItem } from '../types';

type AlertsDataState = {
  drivingBehavior?: StateItem<AlertsData>;
  svcRequests?: SvcRequest[];
  breaches: GetBreachResponse;
};

type AlertsDataAction = {
  type: ALERTS_ACTIONS;
  data: AlertsData | Alerts;
};

export default (state: AlertsDataState = {}, action: AlertsDataAction) => {
  switch (action.type) {
    case ALERTS_ACTIONS.GET_ALERTS:
      return {
        ...state,
        drivingBehavior: action.data,
      };
    case ALERTS_ACTIONS.SET_BREACH:
      return {
        ...state,
        breaches: action.data,
      };
    case ALERTS_ACTIONS.CREATE_ALERT:
      return {
        ...state,
        drivingBehavior: {
          ...state.drivingBehavior,
          drivingBehavior: [...state?.drivingBehavior?.drivingBehavior, action.data.drivingBehavior[0]],
        },
      };
    case ALERTS_ACTIONS.UPDATE_ALERT:
      return {
        ...state,
        drivingBehavior: {
          ...state.drivingBehavior,
          drivingBehavior: state.drivingBehavior.drivingBehavior.map((alert) => {
            if (alert.lastSvcReqId === action.data.drivingBehavior[0].lastSvcReqId) {
              return action.data.drivingBehavior[0];
            }
            return alert;
          }),
        },
      };
    case ALERTS_ACTIONS.DELETE_ALERT:
      return {
        ...state,
        drivingBehavior: {
          ...state.drivingBehavior,
          drivingBehavior: action.data,
        },
      };
    default:
      return state;
  }
};
