import { APIRequest, APIResponse } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';
import config from '@config/config';

type Options = {
  accessToken: string;
  refreshToken: string;
  linkConfig: string | undefined;
};

type Link = {
  redirectURL: string;
};

export default class GetLinkService {
  async getLink({ accessToken, refreshToken, linkConfig }: Options): Promise<APIResponse<Link>> {
    const url = config.getOemValue(`${linkConfig}_GET_LINK_URL`);

    const request: APIRequest = {
      url,
      method: HttpMethodEnum.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        accessToken,
        refreshToken,
      },
    };

    return callHttpRequestMethod(request);
  }
}
