import React from 'react';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextFlowStorage,
} from '../../Router/RouterContext';
import ToyotaPayment from './ToyotaPayment';

const ToyotaPaymentContainer = () => {
  const { send } = useFlowMachine();
  const { subscriptionProps, content, operationType, totalTax, subTotal, orderInfo, taxItems } =
    useFlowMachineContext();

  const location = useFlowLocation();
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();

  const navigateCancel = () => {
    send('cancel');
  };

  const submitCallback = (data?: { paymentMethodId: string }) => {
    if (!data?.paymentMethodId) {
      send('error');
      return;
    }

    send({ type: 'submit', data });
  };

  return (
    <ToyotaPayment
      subTotal={subTotal}
      total={subTotal + totalTax}
      navigateCancel={navigateCancel}
      submitCallback={submitCallback}
      subscriptionProps={subscriptionProps}
      orderSummaryInfo={orderInfo}
      content={content}
      location={location}
      packageSubscriptions={packageSubscriptions}
      tax={totalTax}
      taxItems={taxItems}
      operationType={operationType}
    />
  );
};

export default ToyotaPaymentContainer;
