import { Box, styled } from '@mui/material';
import _Markdown from '@lib-components/Markdown/Markdown';

export const GrayText = styled('span')`
  color: ${({ theme }) => theme.palette.portalTheme.secondaryColor};
`;

export const CenterAlignedContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const Markdown = styled(_Markdown)`
  display: inline;

  * {
    display: inline;
  }
`;
