import { useCallback } from 'react';
import { ServicesContentItem } from '@customTypes/ContentfulComponent';
import { useVehicleSelector } from '@redux/selectors/vehicle';

export const checkRenderServices = (contentfulItem: ServicesContentItem, vehicleServices: string[]) =>
  !contentfulItem.services ||
  contentfulItem.services.every((supportedService) => vehicleServices.includes(supportedService));

const useFilterByServices = () => {
  const vehicle = useVehicleSelector();
  return useCallback(
    (contentItems: ServicesContentItem[]) => contentItems.filter((item) => checkRenderServices(item, vehicle.services)),
    [vehicle],
  );
};

export default useFilterByServices;
