import React, { useEffect } from 'react';
// @ts-ignore "is not a module"
import { MemoryRouter, Switch, Route, useHistory } from 'react-router-dom';
import { ComponentRoutes } from './types';
import { FlowMachineProvider, useFlowMachine, useFlowMachineContextSubscriptionProps } from './RouterContext';
import { useSelector } from '@xstate/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import { FlowEventName } from './flowTypes';
import { merge } from 'lodash';
import { ManageSubscriptionProps } from '@manageSubscription/Subscription/Subscription';
import { getClientIp } from '../services/cps';
import { ActionTypes } from './flowSessionStorage';
import { componentNames, getPageComponent } from './flowMiddleware';
import { getCookie } from '../utils';
import ScrollToTop from '@lib-components/ScrollToTop';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { useFeatureFlags } from '@components/FeatureFlags';

const FlowRoutes: React.FC<ManageSubscriptionProps> = ({ content, subscriptionProps }) => {
  const queryClient = useQueryClient();
  const flowMachine = useFlowMachine();
  const featureFlags = useFeatureFlags();
  const { send } = flowMachine;
  const history = useHistory();
  const { tenantId } = subscriptionProps;
  const { isLoading, spinnerType } = useSelector(flowMachine, (state) => state.context.loading);
  const stateMachineSubscriptionProps = useFlowMachineContextSubscriptionProps();

  const { data: clientIP } = useQuery(['clientIP'], getClientIp, {
    refetchOnWindowFocus: false,
    initialData: () => getCookie('ip'),
    staleTime: 10000,
  });

  useEffect(() => {
    send({
      type: FlowEventName.UPDATE_CONTEXT,
      data: { content, history, queryClient, featureFlags },
    });
  }, [content, history, featureFlags]);

  useEffect(() => {
    // storage data in priority for few reason:
    // 1. if this is IVA flow - we need data that we saved
    // 2. if this is common flow - the storage we get would be empty object, so we would just skip updating
    send({
      type: FlowEventName.SET_SUBSCRIPTION_PROPS,
      data: merge({}, stateMachineSubscriptionProps, subscriptionProps),
    });
  }, [subscriptionProps]);

  useEffect(() => {
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: ActionTypes.UpdateClientIP,
        payload: clientIP,
      },
    });
  }, [clientIP]);

  return (
    <>
      {isLoading && <Spinner type={spinnerType} />}
      <ScrollToTop />
      <Switch>
        {componentNames.map((componentName) => (
          <Route key={componentName} path={`/${ComponentRoutes[componentName]}`}>
            {RenderPageComponent(componentName, tenantId)}
          </Route>
        ))}
      </Switch>
    </>
  );
};

export const RenderPageComponent = (componentName: ComponentRoutes, tenantId: TenantIds) => {
  const PageComponent = getPageComponent(componentName, tenantId);
  if (!PageComponent) {
    console.error(`Please make sure ${componentName} is added to "pageToComponentMap" object.`);
    return null;
  }
  return <PageComponent />;
};

const RouterWrapper: React.FC<ManageSubscriptionProps> = (props) => {
  return (
    <FlowMachineProvider subscriptionProps={props.subscriptionProps}>
      <MemoryRouter>
        <FlowRoutes {...props} />
      </MemoryRouter>
    </FlowMachineProvider>
  );
};

export default RouterWrapper;
