import { SubscriptionProps } from '@manageSubscription';
import { getEffectiveAgreementTemplate } from '@cv/portal-cps-lib';
import { options } from '../commonService';
import {
  AgreementBuilderRequest,
  AgreementsRequest,
  CreateAgreementRequest,
  GetEffectiveAgreementRequest,
} from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { AgreementType, Country } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { DevicePrefLanguage } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import capitalize from 'lodash/capitalize';
import {
  createAgreement,
  createAgreementBuilder,
  getAgreements,
} from '@cv/portal-cps-lib/agreements/agreement-service';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';
import { PaymentInfo } from '@lib-payment/Types';
import { AgreementRequestBuilder } from '@manageSubscription/builders';
import { OrderSummaryInfo } from '@manageSubscription/Types';

export const getAgreementOnFile = async (props: SubscriptionProps) => {
  const {
    userDetails: { userId },
    vehicleDetails: { vin },
  } = props;

  const request: AgreementsRequest = {
    ...options(props),
    data: {
      userId,
      vin,
    },
  };
  return getAgreements(request)
    .then((res) => res.data?.payload)
    .then((agreements) => agreements?.[0])
    .catch((err) => {
      console.error(err);
      if (err.status === HttpStatus.NOT_FOUND) {
        return null;
      }
      throw err;
    });
};

export const getAgreementTemplate = (props: SubscriptionProps, agreementType?: AgreementType) => {
  const {
    vehicleDetails: { telematicsProgramId, make, registrationCountry },
    locale,
  } = props;

  const request: GetEffectiveAgreementRequest = {
    ...options(props),
    data: {
      telematicsProgramId,
      agreementType: agreementType || AgreementType.Subscriber_info,
      make,
      country: Country[capitalize(registrationCountry)],
      language: DevicePrefLanguage[locale?.replace('-', '')],
    },
  };
  return getEffectiveAgreementTemplate(request).then((res) => res.data?.payload);
};

export const createAgreementForUser = async (
  props: SubscriptionProps,
  orderSummary: OrderSummaryInfo,
  paymentInfo: PaymentInfo | undefined | null,
  shouldIncludeTax: boolean,
) => {
  const { agreementType, userDetails, vehicleDetails, locale } = props;

  const agreementBuilderRequest: AgreementBuilderRequest = new AgreementRequestBuilder()
    .withAgreementType(agreementType)
    .withPackagesInformation(orderSummary, shouldIncludeTax)
    .withPaymentInformation(paymentInfo)
    .withUserInformation(userDetails, locale)
    .withVehicleInformation(vehicleDetails)
    .build();

  const agreementRequestData = await createAgreementBuilder(agreementBuilderRequest);
  const request: CreateAgreementRequest = { ...options(props), data: agreementRequestData };

  return createAgreement(request)
    .then((res) => res.data?.payload)
    .catch((err) => `An error occurred while creating user agreement. ErrorMessage: ${err.data.message}`);
};
