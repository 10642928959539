import React, { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import UserCalendar from '@customTypes/UserCalendar';
import { setCalendarList } from '@redux/actions/user';
import useLoadGoogle from '@hooks/useLoadGoogle';

export type GoogleCalendarListProps = {
  children: ReactNode;
};

const GoogleCalendarList = ({ children }: GoogleCalendarListProps) => {
  const dispatch = useDispatch();
  const updateCalendarList = useCallback(
    (calendars: UserCalendar[]) => dispatch(setCalendarList(calendars)),
    [dispatch],
  );

  useLoadGoogle({ setCalendarList: updateCalendarList });

  return <>{children}</>;
};

export default GoogleCalendarList;
