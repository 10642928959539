import React from 'react';
import { LoaderBackdrop } from '@components/Loader';
import { useSelector } from 'react-redux';

function AppLoading(): JSX.Element | null {
  const isLoading = useSelector(({ loadingReducer }) => loadingReducer.isLoading);

  if (isLoading) {
    return <LoaderBackdrop testId="app_loader" />;
  }

  return null;
}
export default AppLoading;
