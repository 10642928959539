import { send } from 'xstate';
import { FlowEventName } from '../../../flowTypes';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig } from '../Types';

export const infoPageEnrollmentFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.infoPageEnrollment,
  states: {
    pre: {
      //[getSubscriptionTerms] action is inside InformationPageSubscription.tsx
      entry: 'getSubscriptionTerms',
      always: [
        {
          target: 'handleCpo',
          cond: 'isCpo',
        },
        'NAVIGATE',
      ],
    },
    handleCpo: {
      always: [{ target: 'showInfoPageEnrollment', cond: 'isUsVechicle' }, 'navigateToError'],
    },
    showInfoPageEnrollment: {
      entry: send({
        type: 'PUSH_HISTORY',
        data: { componentRoute: ComponentRoutes.infoPageEnrollment },
      }),
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'NAVIGATE' },
      },
    },
    NAVIGATE: {
      entry: 'navigateForward',
    },
    navigateToError: {
      entry: FlowEventName.NAVIGATE_TO_ERROR,
    },
    toReviewOrder: {
      entry: 'navigateToCancel',
    },
  },
});
