import React from 'react';
import nextId from '@utils/next-id';
import clsx from 'clsx';

import styles from './InputSwitch.module.css';
import useToggle from '@hooks/useToggle';

type InputSwitchProps = {
  label?: string | React.ReactChildren;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: null | ((event: React.ChangeEvent<HTMLInputElement>) => void);
  className?: string;
  type?: 'default' | 'wide';
  classes?: {
    label?: string;
    input?: string;
  };
};

export default function InputSwitch({
  label = '',
  checked,
  defaultChecked = false,
  disabled = false,
  onChange = null,
  className = '',
  type = 'default',
  classes = {},
  ...restProps
}: InputSwitchProps) {
  const inputId = nextId();
  const [isChecked, toggleChecked] = useToggle(defaultChecked);
  const inputChecked = checked ?? isChecked;

  if (typeof checked === 'boolean' && !onChange) {
    console.warn(
      'InputSwitch: invalid use of controlled component. onChange is required in order to control component state',
    );
  }

  const inputClassName = clsx(styles.InputSwitch, className, styles[`InputSwitch--${type}`], {
    [styles['InputSwitch--checked']]: inputChecked,
    [styles['InputSwitch--disabled']]: disabled,
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
    toggleChecked();
  };

  return (
    <div className={inputClassName}>
      {label && (
        <label className={clsx(styles['InputSwitch-label'], classes.label)} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={clsx(styles['InputSwitch-input'], 'visually-hidden', classes.input)}
        id={inputId}
        type="checkbox"
        checked={inputChecked}
        disabled={disabled}
        onChange={handleOnChange}
        {...restProps}
      />
      <label className={styles['InputSwitch-toggle']} htmlFor={inputId} />
    </div>
  );
}
