import { styled } from '@mui/material';
import { breakpoint, Title } from '../../../styled-components/globalStyles';
import Button from '../../../components/Button';

type WrapperProps = {
  showBottomBorder: boolean;
};

export const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  ${({ showBottomBorder }) => showBottomBorder && 'border-bottom: 2px solid #c1c1c1;'}
`;

export const PackageSection = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const PackageHeaderSection = styled('div')`
  display: flex;
`;

export const PackagePriceAndDetailsButtonsSection = styled('div')`
  display: flex;
  flex-grow: 1;
  align-self: center;
`;

export const IconSection = styled('span')`
  align-self: center;
  cursor: pointer;
  min-width: 30px;
`;

export const ExpandCollapseSection = styled('span')`
  align-self: baseline;
  cursor: pointer;
  min-width: 30px;
`;

export const PackageTitle = styled(Title)`
  padding: 1rem 0;
`;

type DropdownSectionProps = {
  showBottomBorder?: boolean;
  showTopBorder?: boolean;
};

export const DropdownSection = styled('div')<DropdownSectionProps>`
  ${({ showBottomBorder = true }) => showBottomBorder && 'border-bottom: 2px solid #c1c1c1;'}
  ${({ showTopBorder = false }) => showTopBorder && 'border-top: 2px solid #c1c1c1;'}
`;

export const PackageDescription = styled('div')`
  display: flex;
  padding: 1rem 0 0.5rem 1rem;
`;

export const OfferDetailSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SaveMoreBanner = styled(Title)`
  opacity: 0.6;
`;

export const StyledWrapper = styled('div')`
  margin: 0 0.5rem 0 auto;
`;

export const ToggleButton = styled(Button)`
  width: auto;
  padding: 0.4rem;
  margin: 0 0.5rem 0 auto;
  text-transform: none;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
`;

export const PackageSectionHeader = styled('h1')`
  font-weight: bold;
  font-size: 1rem;
  text-align: initial;

  @media only screen and ${breakpoint.device.md} {
    font-size: 1.2rem;
  }
`;

export const PackageSectionSubHeader = styled('div')`
  font-size: 0.9375rem;
  margin-bottom: 4rem;
  text-align: initial;

  @media only screen and ${breakpoint.device.md} {
    font-size: 1.1rem;
  }
`;
