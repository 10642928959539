import React from 'react';
import clsx from 'clsx';
import ContentRenderer from '@components/ContentRenderer';
import config from '@config/config';
import styles from './Footer.module.css';
import { useTheme } from '@mui/material';

export type FooterContent = {
  componentType: string;
  name: string;
  content: FooterContent[];
};

type Props = Pick<FooterContent, 'content'>;

function Footer({ content }: Props): JSX.Element {
  const oemClassName = config.getOemName().toLowerCase();
  const theme = useTheme();
  return (
    <footer
      className={clsx(styles['Footer-container'], styles[oemClassName])}
      style={{ color: theme.palette.text.footer, backgroundColor: theme.palette.background.footer }}
    >
      <div className={styles['Footer-wrapper']}>
        <ContentRenderer name="footer" content={content} />
      </div>
    </footer>
  );
}

export default Footer;
