import bluePin from '../assets/pin.png';
import { itemInFavorites } from './itemInFavorites';
import { v4 as uuidv4 } from 'uuid';
import { PointOfInterest } from '../components/Map/types';

type Favorites = Array<PointOfInterest> | null;

const scrollToElementById = (id: string) => {
  const target = document.getElementById(id)?.offsetTop;
  const containerEl = document.getElementById('poi_list');
  if (containerEl && target) {
    containerEl.scrollTop = target - 200;
  }
};

export const formatPins = (
  pins: Array<PointOfInterest>,
  dispatch: (arg: object) => void,
  favorites: Favorites = null,
) => {
  // THIS IS A TEMP FIX UNTIL THIS IS RESOLVED IN RTS
  const includedCountries = ['México', 'Mexico', 'MX', 'US', 'Estados Unidos', 'CA'];

  return pins
    .filter((loc: PointOfInterest) => includedCountries.includes(loc.geoAddress.address.country))
    .map((pin: PointOfInterest) => {
      const isInFavorites = favorites && itemInFavorites(pin, favorites);
      const pinId = isInFavorites ? isInFavorites.id : uuidv4();
      const center = pin.geoAddress.coordinate;
      const options = { icon: bluePin };
      const { street, city, state, country } = pin.geoAddress.address;
      const metadata = {
        title: pin.name,
        description: `${street}, ${city}, ${state}, ${country}`,
      };
      const hover = () => {
        const id = pinId;
        dispatch({
          type: 'SET_SELECTED_POINT_OF_INTEREST',
          selectedPointOfInterest: id,
        });
        scrollToElementById(id);
      };
      const hoverOut = () => {
        dispatch({
          type: 'SET_SELECTED_POINT_OF_INTEREST',
          selectedPointOfInterest: null,
        });
        const containerEl = document.getElementsByClassName('poi-item-list');
        containerEl.scrollTop = 0;
      };
      return {
        ...pin,
        metadata,
        center,
        pinId,
        options,
        hover: hover,
        hoverOut: hoverOut,
      };
    });
};
