import capitalize from 'lodash/capitalize';
import { TenantIds } from './productFlowMap';

/**
 * Temporary until the translations in common-lib is removed.
 * This component needs package/product names in english. Used to map assets.
 * TODO: This file needs to be removed once the translations in common-lib is removed that was added for NMEX
 */
const translations = {
  'es-MX': {
    // NIS or INF
    Convenience: 'Conveniencia',
    Plus: 'Plus',
    Premium: 'Premium',
    Security: 'Seguridad',
    Select: 'Select',
  },
};

export const translateToEN = (str: string, language: string, tenantId: string) => {
  if (language === 'en-US' || tenantId === TenantIds.Fca || tenantId === TenantIds.ABC_Car_Comp) return str;

  if (!translations.hasOwnProperty(language)) return '';

  const translated = Object.entries(translations[language])
    .filter(([key, value]) => value === capitalize(str))
    .map(([key, value]) => key);
  return translated?.length ? translated[0] : '';
};
