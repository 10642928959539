// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zkisV28wKaCMh1HdI0ET {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: var(--theme-header-color-background);
}

.zkisV28wKaCMh1HdI0ET .LsvgivCXoaBr24qpLMHI:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.LsvgivCXoaBr24qpLMHI {
  color: var(--theme-header-color-text);
  padding: 6px 0;
  margin: 0 0.75rem;
  cursor: pointer;
  white-space: nowrap;
  list-style: none;
  transition: color 0.5s ease;
}

.LsvgivCXoaBr24qpLMHI:hover {
  color: var(--accent-color);
}

.z8pGgufD2mk85ih0XHjw {
  text-decoration: line-through;
}
`, "",{"version":3,"sources":["webpack://./src/components/VehicleSwitcher/VehicleSwitcher.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,WAAW;EACX,sDAAsD;AACxD;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,qCAAqC;EACrC,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".VehicleItemsList {\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  background-color: var(--theme-header-color-background);\n}\n\n.VehicleItemsList .VehicleItem:not(:last-child) {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.VehicleItem {\n  color: var(--theme-header-color-text);\n  padding: 6px 0;\n  margin: 0 0.75rem;\n  cursor: pointer;\n  white-space: nowrap;\n  list-style: none;\n  transition: color 0.5s ease;\n}\n\n.VehicleItem:hover {\n  color: var(--accent-color);\n}\n\n.disabled {\n  text-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VehicleItemsList": `zkisV28wKaCMh1HdI0ET`,
	"VehicleItem": `LsvgivCXoaBr24qpLMHI`,
	"disabled": `z8pGgufD2mk85ih0XHjw`
};
export default ___CSS_LOADER_EXPORT___;
