import React, { useState } from 'react';
import { PaymentContextProvider, SubscriptionProps } from '@app/components-lib';
import Markdown from '@components/Markdown';
import { useGenerateSubscriptionContent, useGenerateSubscriptionProps } from '@components/SubscriptionWrapper/utils';
import NavigationProp from '@customTypes/NavigationProp';
import { Box, Stack, Typography } from '@mui/material';
import { useCancelLabels } from '../CancelLabelsContext';
import PreviewOrderList from '../PreviewOrderList';
import ScreenStepHeader from '../StepScreenHeader';
import Checkbox from '@lib-components/Checkbox';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import { cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import { useIsMutating } from '@tanstack/react-query';
import { CancelFlow } from '../CancelSubscriptionStateMachine';
import { useGetPaymentMethods } from '@app/components-lib/services/cps/queries';

type Props = { onContinue: () => void; step: CancelFlow } & NavigationProp;
const PaymentMethods = ({ Navigation, onContinue, step }: Props) => {
  const [agree, setAgree] = useState(false);
  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);

  const {
    assetsRaw,
    subHeaders,
    subscriptionLabels,
    paymentHeader,
    paymentSubHeader,
    reviewOrderTerms,
    orderSummary,
    saveAndContinue,
  } = useCancelLabels();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: paymentHeader,
    subsection: 'Cancelation > Payment',
  });

  const content = useGenerateSubscriptionContent({
    labels: [subscriptionLabels],
    assets: assetsRaw,
  });

  const paymentProps: SubscriptionProps = useGenerateSubscriptionProps();
  const { data } = useGetPaymentMethods(paymentProps);

  const isSomePaymentMethodExist = data?.paypal.length || data?.creditCard.length;

  const webContent = {
    ...content,
    commonWebContent: {
      ...content.commonWebContent,
      continueButtonLabel: content.commonWebContent.saveLabel,
      saveAndContinueButtonLabel: content.commonWebContent.saveLabel,
    },
    titles: subHeaders,
  };

  const previewOrderIsLoading = useIsMutating({ mutationKey: ['previewOrder'] });

  return (
    <Stack spacing={2}>
      <ScreenStepHeader>{paymentHeader}</ScreenStepHeader>
      <h3>{orderSummary}</h3>
      <PreviewOrderList />
      <Typography variant="body1">{paymentSubHeader}</Typography>
      <PaymentContextProvider
        content={webContent}
        subscriptionProps={paymentProps}
        managePage
        hidePaymentButton
        notifyOnFormOpen={setIsPaymentFormOpen}
      />
      {!isPaymentFormOpen && (
        <Stack direction={'row'} alignItems={'flex-start'} mb={3}>
          <Checkbox
            sx={{
              svg: {
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: 'unset',
                border: '1px solid',
                borderColor: (theme) => theme.palette.grey.A700,
              },
            }}
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
          />
          <Markdown>{reviewOrderTerms}</Markdown>
        </Stack>
      )}
      <Box>
        {!isPaymentFormOpen && (
          <AnalyticsButton
            sx={(theme) => ({
              width: '100%',
              mb: theme.spacing(1),
              [theme.breakpoints.up('sm')]: {
                mr: theme.spacing(1),
                mb: 0,
                width: 'auto',
              },
            })}
            disabled={!isSomePaymentMethodExist || !agree || !!previewOrderIsLoading}
            onClick={onContinue}
            analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
              text: saveAndContinue,
              pageName: step,
            })}
          >
            {saveAndContinue}
          </AnalyticsButton>
        )}
        {Navigation && <Navigation />}
      </Box>
    </Stack>
  );
};

export default PaymentMethods;
