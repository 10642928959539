import React from 'react';
import { ComponentMapping } from '@components/component-mappings';
import ContentRenderer from '@components/ContentRenderer';
import useLabels from '@hooks/useLabels';
import { ServerLabel, extractDictionaries, getDictionariesContent } from '@utils/labels';
import { BrandNamesFilterGroup } from '../../types/BrandNamesFilterGroup';
import useFilterDictionary from './useFilterDictionary';
import ContentfulComponent from '@customTypes/ContentfulComponent';

export type LabelDictionaryProps = {
  name: string;
  component?: ComponentMapping;
  content: ServerLabel[];
  dictionaryKey?: string;
  filterGroup?: BrandNamesFilterGroup;
  dictionaries: ContentfulComponent[];
};

function LabelDictionary({
  name,
  component,
  content,
  filterGroup,
  dictionaries,
}: LabelDictionaryProps): JSX.Element | null {
  const labels = useLabels(content);
  const dictionary = { labels: labels.getAll(), filterGroup };
  const filteredLabels = useFilterDictionary(dictionary);
  const dictionariesContent = extractDictionaries(dictionaries);

  if (!component) {
    return null;
  }

  return (
    <ContentRenderer
      name={name}
      content={[
        {
          componentType: component,
          labels: filteredLabels,
          ...dictionariesContent,
        },
      ]}
    />
  );
}

export default LabelDictionary;
