import { getContentTypes } from './contentful';
import ContentfulComponent from '@customTypes/ContentfulComponent';

export type FileDetails = {
  image: {
    height: number;
    width: number;
  };
  size: number;
};

export type File = {
  url: string;
  details: FileDetails;
  fileName: string;
  contentType: string;
};

export type Asset = {
  componentType?: string;
  file: File;
  title: string;
};

export type ServerAsset = {
  key?: string;
  asset: Asset;
};

export type AssetObject = {
  [key: string]: Asset;
};

export const getAssets = (content: ContentfulComponent[]) => {
  return getContentTypes(content, 'portalAsset');
};

export const generateAssetsObjects = (assets: ServerAsset[] = []) => {
  return assets.reduce((acc: AssetObject, assetObject: ServerAsset) => {
    const { key, asset } = assetObject;

    if (!key) {
      return acc;
    }

    return {
      ...acc,
      [key]: asset,
    };
  }, {} as AssetObject);
};
