import React from 'react';
import { isEmpty, set } from 'lodash';
import FormikField from '../FormikField';
import { WebContent } from '@manageSubscription';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { FormattedAddressResponse, ListAddressesResponse } from '@cv/portal-cps-lib/utility/utility-services/models';
import AddressAutocomplete from '../AddressAutocomplete';

export type AddressType = 'mailing' | 'billing';

interface FormikAddressFieldProps {
  name: string;
  addressType: AddressType;
  values: unknown;
  setValues: (values: React.SetStateAction<unknown>, shouldValidate?: boolean) => void;
  content: WebContent;
  registrationCountry: string;
  states: Array<{ label: string; value: string }>;
  countries: Array<{ label: string; value: string }>;
  handleChange: (name: string, value: unknown | string) => void;
  loadOptions: (inputText: string) => Promise<ListAddressesResponse[]>;
  formatOption: (address: ListAddressesResponse, isoCountryCode: string) => Promise<FormattedAddressResponse>;
}

const FormikAddressField = (props: FormikAddressFieldProps) => {
  const { values, setValues, content, addressType, registrationCountry, states, countries, loadOptions, formatOption } =
    props;
  const {
    addressFormWebContent: {
      streetPlaceholder,
      streetTwoPlaceholder,
      cityPlaceholder,
      countryPlaceholder,
      postalCodePlaceholder,
      statePlaceholder,
      streetLabel,
      streetTwoLabel,
      cityLabel,
      countryLabel,
      postalCodeLabel,
      stateLabel,
    },
  } = content;

  const createFieldName = (prefix, affix = '') => `${prefix}__${addressType}${affix}`;

  const fieldNames = {
    street: createFieldName('street'),
    street2: createFieldName('street', '2'),
    city: createFieldName('city'),
    state: createFieldName('state'),
    postalCode: createFieldName('postalCode'),
    country: createFieldName('country'),
  };

  const onSelectOption = (selectedAddress: ListAddressesResponse) => {
    formatOption(selectedAddress, registrationCountry).then((data) => {
      const { addressLine1, addressLine2, locality, postalCode, province } = data;
      const formattedAddress = {};
      set(formattedAddress, fieldNames.street, addressLine1);
      set(formattedAddress, fieldNames.street2, addressLine2);
      set(formattedAddress, fieldNames.city, locality);
      set(formattedAddress, fieldNames.state, province);
      set(formattedAddress, fieldNames.postalCode, postalCode);
      setValues({
        ...values,
        ...formattedAddress,
      });
    });
  };

  return (
    <>
      <FormikField
        name={fieldNames.street}
        label={streetLabel}
        id={fieldNames.street}
        placeholder={streetPlaceholder}
        loadOptions={loadOptions}
        onSelectOption={onSelectOption}
        Component={AddressAutocomplete}
        required
      ></FormikField>
      <FormikField
        name={fieldNames.street2}
        id={fieldNames.street2}
        label={streetTwoLabel}
        placeholder={streetTwoPlaceholder}
      />
      <FormikField
        name={fieldNames.city}
        id={fieldNames.city}
        required
        label={cityLabel}
        placeholder={cityPlaceholder}
      />
      <FormikField
        name={fieldNames.state}
        id={fieldNames.state}
        label={stateLabel}
        Component={Select}
        input={<OutlinedInput label={stateLabel} />}
        required
        aria-label={stateLabel}
        placeholder={statePlaceholder}
        fullWidth
        children={states.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      />
      <FormikField
        name={fieldNames.postalCode}
        id={fieldNames.postalCode}
        required
        label={postalCodeLabel}
        placeholder={postalCodePlaceholder}
      />
      {isEmpty(registrationCountry) && (
        <FormikField
          id={fieldNames.country}
          name={fieldNames.country}
          label={countryLabel}
          Component={Select}
          input={<OutlinedInput label={countryLabel} />}
          required
          aria-label={countryLabel}
          placeholder={countryPlaceholder}
          fullWidth
          children={countries.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        />
      )}
    </>
  );
};

export default FormikAddressField;
