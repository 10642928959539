import { send } from 'xstate/lib/actions';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const managePaidPackagesFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePaidPackages,
  states: {
    pre: {
      always: [{ target: 'navigate', cond: 'skipManagePaidPackages' }, 'idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePaidPackages },
        }),
      ],
      on: {
        HANDLE_ADD_SERVICES: 'handleAddServices',
        [FlowEventName.NAVIGATE_FORWARD]: 'navigate',
        [FlowEventName.NAVIGATE_BACK]: 'navigateBackward',
      },
    },
    handleAddServices: {
      always: [{ target: 'addTrialEligible', cond: 'shouldAddTrialEligiblePackage' }, { target: 'navigate' }],
    },
    addTrialEligible: {
      entry: 'addFirstTrialEligiblePackage',
      always: 'navigate',
    },
    navigateBackward: {
      always: [{ target: 'navigateBack' }],
    },
    navigate: {
      entry: 'navigateForward',
    },
    navigateBack: {
      entry: 'navigateBack',
    },
  },
});

export const actions: ComponentActions<{}> = {};
