import React from 'react';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { isTrialPackage } from '@utils/isTrialPackage';
import useFormatTermLabel from './useFormatTermLabel';
import { useCancelLabels } from '../../CancelLabelsContext';

import styles from '../../Cancellation.module.css';

type CustomerPackageProps = {
  customerPackage: SubscribedPackage;
  checkbox?: React.ReactNode;
};

const CustomerPackage = ({ customerPackage, checkbox }: CustomerPackageProps) => {
  const formatTermLabel = useFormatTermLabel();
  const { trial, paid, starts, renew, expires } = useCancelLabels();

  return (
    <div className={styles['customer-package-container']}>
      {checkbox}
      <div className={styles['customer-package']}>
        <h4 className={styles['customer-package-name']}>{customerPackage.marketingName}</h4>
        <span className={styles['package-subscription']}>{isTrialPackage(customerPackage) ? trial : paid}</span>
        <p className={styles['package-term']}>
          {formatTermLabel({
            pkg: customerPackage,
            startsLabel: starts,
            renewLabel: renew,
            expiresLabel: expires,
          })}
        </p>
      </div>
    </div>
  );
};

export default CustomerPackage;
