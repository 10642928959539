import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import hasFilter from '@utils/hasFilter';

type Content = {
  name: string;
  labelKey: string;
};

type Options<T extends Content> = {
  content: T[];
  makeName: string;
  filterGroup: BrandNamesFilterGroup;
  isYearly?: boolean;
};

const filterByMake = <T extends Content>({ content, makeName, filterGroup, isYearly }: Options<T>): T[] => {
  const filtered = content.filter(
    ({ name }) => name.split(' ').includes(makeName) || !hasFilter(name, filterGroup.group),
  );
  if (isYearly === undefined) {
    return filtered;
  }
  return filtered.filter((pkg) => isYearly === pkg.labelKey?.toLocaleLowerCase().includes('yearly'));
};

export default filterByMake;
