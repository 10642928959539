import { SESSION_STORAGE_KEY, SubscriptionProps } from '@manageSubscription';
import { merge } from 'lodash';
import { Action, assign, DoneInvokeEvent } from 'xstate';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { ApplicationFlowEvent } from './flowTypes';
import { DeepPartial } from '../typesUtils';
import { FlowLocation } from '@lib-appRouter/flowMiddleware';

export const getStorageData = (key: SESSION_STORAGE_KEY) => {
  let parsedData = {};
  const data = sessionStorage.getItem(key);
  if (data) {
    parsedData = JSON.parse(data);
  }
  return parsedData;
};

export const is2fFlow = (location: string) => {
  const completeProfileFlow: FlowLocation[] = ['completeprofile', 'learnmore'];
  return completeProfileFlow.includes(location as FlowLocation);
};

export const isMarketingFlow = (location?: FlowLocation) => {
  const marketingFlow: FlowLocation[] = ['trial', 'enroll', 'activate', 'subscribe', 'savemore', 'promocode'];
  return !!location && marketingFlow.includes(location as FlowLocation);
};

type Callback = (context: AggregatedFlowContext, event: ApplicationFlowEvent) => DeepPartial<SubscriptionProps>;
// TODO: User should not be able to update this data from library. This should be static data sent by consumer of this library.
export const assignSubscriptionProps = (callback: Callback): Action<AggregatedFlowContext, DoneInvokeEvent<any>> => {
  return assign((context: AggregatedFlowContext, event: ApplicationFlowEvent) => {
    const result = callback(context, event);

    const updatedSubscriptionProps = merge({}, context.subscriptionProps, result);

    sessionStorage.setItem(
      SESSION_STORAGE_KEY.SUBSCRIPTION,
      JSON.stringify(merge(getStorageData(SESSION_STORAGE_KEY.SUBSCRIPTION), updatedSubscriptionProps)),
    );

    return {
      subscriptionProps: updatedSubscriptionProps,
    };
  });
};
