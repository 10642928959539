import { getSubscribedPackages } from '@cv/portal-cps-lib/subscription';
import { SubscribedPackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { CapablePackagesResponse } from '@customTypes/subscription';
import { SubscribedServices } from '@api/types';
import { formatSubscription } from '@api/formatters';
import {
  fetchDefaultEligiblePackages,
  DefaultEligiblePackagesResponse,
} from '@api/requests/subscription/fetchDefaultEligiblePackages';
import { fetchVehicleDetails } from '@api/requests/vehicle';
import config from '@config/config';
import attachCapableServices from './attachCapableServices';

export type FetchSubscribedPackagesOptions = {
  accessToken: string;
  tenantId: string;
  vehicleId: string;
  userId: string;
  locale?: string;
  excludeDefaultPkgs?: boolean;
  subscribedServices?: SubscribedServices[];
};

export const fetchSubscribedPackages = async ({
  accessToken,
  tenantId,
  vehicleId,
  userId,
  locale = 'en-US',
  excludeDefaultPkgs = true,
  subscribedServices,
}: FetchSubscribedPackagesOptions): Promise<CapablePackagesResponse> => {
  const environment = config.getEnvironmentEnum();

  const request = {
    data: { userId, vehicleId },
    environment,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };

  let defaultPkgs: DefaultEligiblePackagesResponse | undefined;

  if (!excludeDefaultPkgs) {
    defaultPkgs = await fetchDefaultEligiblePackages({ accessToken, tenantId, vehicleId, userId, environment, locale });
  }

  const vehicleDetails = await fetchVehicleDetails({
    vehicleId,
    environment,
    accessToken,
    tenantId,
    locale,
    subscribedServices,
  });
  const response = await getSubscribedPackages(request).then((response) =>
    formatSubscription(response.data, defaultPkgs?.data),
  );

  return Promise.resolve(formatResponse({ response, capableServices: vehicleDetails?.data?.capableServices }));
};

export type FormatResponseOptions = {
  response?: Partial<SubscribedPackagesResponse>;
  capableServices?: VehicleServiceDetails[];
};

export type FormatResponse = (options?: FormatResponseOptions) => CapablePackagesResponse;

export const formatResponse: FormatResponse = (options = {}) => {
  const { response = {}, capableServices = [] } = options;
  const { subscribedPackages = [], ...restResponse } = response;

  const formattedResponse = {
    vin: restResponse.vin || '',
    vehicleId: restResponse.vehicleId || '',
    subscribedPackages: attachCapableServices({ packages: subscribedPackages, capableServices }),
  };

  return formattedResponse;
};
