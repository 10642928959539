import { send } from 'xstate';
import { exchangeToken } from '../../../../services/shim';
import { FlowEventName } from '../../../flowTypes';
import { ComponentRoutes } from '../../../types';
import { getVehicleDetails } from '../../../../services/cps/vehicleService';
import { ComponentFlowStateConfig, InfoPageSubscriptionFlowContext } from '../Types';
import { FetchMachineEvent } from '../../../fetchMachine';

export const infoPageSubscriptionFlow: ComponentFlowStateConfig<InfoPageSubscriptionFlowContext> = () => ({
  initial: 'initial',
  id: ComponentRoutes.infoPageSubscription,
  states: {
    initial: {
      entry: 'setLoading',
      invoke: {
        id: 'exchangeToken',
        src:
          ({ subscriptionProps }) =>
          async () => {
            const { access_token, refresh_token } = await exchangeToken(subscriptionProps);
            subscriptionProps.accessToken = access_token;
            const {
              data: {
                billingId,
                userName,
                email,
                externalTenantUserId,
                billingCountry,
                billingAddress,
                primaryPhone,
                secondaryPhone,
              },
            } = await subscriptionProps.getAccountDetails(
              subscriptionProps.locale,
              subscriptionProps.userDetails.userId,
              access_token,
            );
            const vehicleData = await getVehicleDetails({
              vehicleId: subscriptionProps.vehicleDetails.vehicleId,
              tenantId: subscriptionProps.tenantId,
              accessToken: access_token,
              config: subscriptionProps.config,
              locale: subscriptionProps.locale,
            });
            subscriptionProps.setVehicleData(vehicleData);
            return Promise.resolve({
              subscriptionData: {
                ...(access_token && { accessToken: access_token }),
                ...(refresh_token && { refreshToken: refresh_token }),
                userDetails: {
                  ...subscriptionProps.userDetails,
                  billingId,
                  firstName: userName.givenName,
                  lastName: userName.fathersName,
                  email,
                  externalTenantUserId: externalTenantUserId,
                  billingAddress: {
                    address1: billingAddress.street,
                    address2: billingAddress.unit,
                    city: billingAddress.city,
                    state: billingAddress.state,
                    country: billingCountry,
                    postalCode: billingAddress.zip,
                  },
                  primaryPhone,
                  secondaryPhone,
                },
                vehicleDetails: { ...subscriptionProps.vehicleDetails, ...vehicleData },
              },
            });
          },
        onDone: {
          actions: [
            send((_, event) => ({
              type: FlowEventName.SET_SUBSCRIPTION_PROPS,
              data: event.data.subscriptionData,
            })),
          ],
        },
        onError: { actions: [FlowEventName.NAVIGATE_TO_ERROR, 'unsetLoading'] },
      },
      on: {
        onFetchContextUpdated: 'fetchPackages',
      },
    },
    fetchPackages: {
      entry: FetchMachineEvent.fetchPackages(),
      on: {
        onFetchPackages: {
          target: 'idle',
        },
        onFetchFailed: {
          target: 'idle',
        },
      },
      exit: 'unsetLoading',
    },
    idle: {
      entry: [
        'getSubscriptionTerms',
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.infoPageSubscription },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { actions: 'navigateForward' },
      },
    },
  },
});
