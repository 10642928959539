import React from 'react';
import { Redirect } from 'react-router-dom';
import config from '@config/config';
import { useApi } from '@api';
import { isDealerPortal } from '@utils/index';
import { AppRoute, AppRouteProps } from './AppRoute';

export const SecureRoute = (props: AppRouteProps) => {
  const { pathname } = window.location;

  const api = useApi();
  const isAuthenticated = api.storeService.getRefreshToken();
  const authExcludePath = config.get<string[]>('authExcludePath') || [];

  const authenticationNeeded = !(isAuthenticated || isDealerPortal() || authExcludePath.includes(pathname));

  return authenticationNeeded ? <Redirect to={{ pathname: '/login' }} /> : <AppRoute {...props} />;
};
