import React from 'react';
import { H1, Content, VSpace } from '../../styled-components/globalStyles';
import Badge from '../../components/Badge';
import ServiceList from '@lib-components/ServiceList';
import Button from '../../components/Button';
import {
  BackgroundImage,
  InnerContainer,
  HeaderContainer,
  ColumnContainer,
  LogoContainer,
} from './InformationPageStyles';
import { ContentfulFile, InformationPageContent } from '../Subscription';
import { SubscribedPackageInfo } from '../Types';

interface InformationPageSubscriptionProps {
  navigateForward: () => void;
  bgImageData: ContentfulFile;
  headerLogoData: ContentfulFile;
  subscriptionTerms: string;
  subscribedPackages: SubscribedPackageInfo[];
  continueButtonLabel: string;
  informationPageSubscriptionContent: InformationPageContent;
}

const InformationPageSubscription: React.FC<InformationPageSubscriptionProps> = ({
  navigateForward,
  bgImageData,
  headerLogoData,
  subscriptionTerms,
  subscribedPackages,
  continueButtonLabel,
  informationPageSubscriptionContent: {
    header = '',
    subHeader = '',
    bodyHeader = '',
    bodyList = '',
    bodyFooter = '',
    servicesTitle = '',
  },
}) => {
  const url = bgImageData?.file.url;
  const { width, height } = bgImageData?.file.details?.image;
  return (
    <ColumnContainer>
      <BackgroundImage img={url} initialWidth={width} initialHeight={height}>
        <HeaderContainer>
          {headerLogoData && (
            <LogoContainer>
              <img src={headerLogoData.file.url} />
            </LogoContainer>
          )}
          <H1>{header.replace('{{trialPeriod}}', subscriptionTerms)}</H1>
          {subHeader}
        </HeaderContainer>
      </BackgroundImage>
      <InnerContainer>
        <H1>{bodyHeader}</H1>
        <VSpace>
          {bodyList.split('\n').map((listItem, index) => (
            <Content style={{ display: 'block' }} key={listItem}>
              <Badge>{index + 1}</Badge> {listItem}
            </Content>
          ))}
        </VSpace>
        <Content>{bodyFooter}</Content>
        {subscribedPackages.length > 0 && <ServiceList title={<H1>{servicesTitle}</H1>} />}
        <Button onClick={navigateForward}>{continueButtonLabel}</Button>
      </InnerContainer>
    </ColumnContainer>
  );
};

export default InformationPageSubscription;
