import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERTS_ACTIONS, DIALOG_ACTIONS } from '@redux/actions/';

import { Button } from '@components-lib';
import ActiveView from '../ActiveView';
import AlertsCount from '../AlertsCount';
import ManageCardItem from '../ManageCardItem';
import SpeedAlertForm from '../Forms/SpeedAlertForm';
import TabHeader from '../TabHeader';
import TabInfo from '../TabInfo';
import { useApi } from '@api';
import { saveSpeedAlert } from '../Forms/utils';
import { useAnalytics } from '@cv/webframework-react-components';

import stylesMonitoring from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { DrivingBehaviorResponse, DrivingBehaviorResult } from '@cv/portal-rts-lib/alerts/models';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { setLoadingStatus } from '@redux/actions/loading';
import { pollingTime } from '../constants';
import Loader from '@components/Loader';
import { useVehicleCountryFormat, useVehicleCountryUnit, convertToApiUnit } from '@utils/metrics';
import useToggle from '@hooks/useToggle';
import { Modal } from '@components-lib';

type AlertData = {
  speed: any;
  lastKnownState: any;
  inVehicleWarning: boolean;
  UoM?: string;
};

type ExpectedStore = {
  alertsReducer: {
    drivingBehavior: {
      drivingBehavior: Array<DrivingBehaviorResponse>; // NOT A TYPO
    };
  };
};

type TabSpeedProps = {
  alertSetLabel: string;
  alertSetPreposition: string;
  cancelButton: string;
  confirmButton: string;
  defaultViewContent: string;
  defaultViewButtonLabel: string;
  editViewButtonLabel: string;
  editViewTitle: string;
  fieldDescription?: string;
  fieldErrorMessage: string;
  fieldLabel: string;
  fieldUnit: string;
  formViewTitle: string;
  infoBoxContent: string;
  switchDescription: string;
  title: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateSuccessMsg: string;
  updateErrorMsg: string;
  deleteSuccessMsg: string;
  deleteErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  defaultSpeedValue: string;
  unitOfMeasurementValue: string;
  iButtonAnalyticsEventName: string;
  addNewAnalyticsEventName: string;
  speedLimitAnalyticsEventName: string;
  displayAlertInvehicleOnAnalyticsEventName: string;
  displayAlertInvehicleOffAnalyticsEventName: string;
  cancelAnalyticsEventName: string;
  saveAnalyticsEventName: string;
  editAnalyticsEventName: string;
  minSpeed: number;
  maxSpeed: number;
  attemptsExceededMessage: string;
};

export default function TabSpeed({
  alertSetLabel,
  alertSetPreposition,
  cancelButton,
  confirmButton,
  defaultViewContent,
  defaultViewButtonLabel,
  editViewButtonLabel,
  editViewTitle,
  fieldDescription,
  fieldErrorMessage,
  fieldLabel,
  fieldUnit,
  formViewTitle,
  infoBoxContent,
  switchDescription,
  title,
  createSuccessMsg,
  createErrorMsg,
  updateSuccessMsg,
  updateErrorMsg,
  deleteSuccessMsg,
  deleteErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  defaultSpeedValue,
  unitOfMeasurementValue,
  iButtonAnalyticsEventName,
  addNewAnalyticsEventName,
  speedLimitAnalyticsEventName,
  displayAlertInvehicleOnAnalyticsEventName,
  displayAlertInvehicleOffAnalyticsEventName,
  cancelAnalyticsEventName,
  saveAnalyticsEventName,
  editAnalyticsEventName,
  minSpeed,
  maxSpeed,
  attemptsExceededMessage,
}: TabSpeedProps) {
  const alertsLimit = 3;
  let speedAlertId = '';
  const { trackEvent } = useAnalytics();
  const api = useApi();
  const [view, setView] = useState('default');
  const [isLoading, setLoading] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<DrivingBehaviorResponse | null>(null);
  const speedAlert = useSelector(({ alertsReducer }: ExpectedStore) => alertsReducer.drivingBehavior);
  const dispatch = useDispatch();
  const getSpeedFormat = useVehicleCountryFormat();
  const countryUnit = useVehicleCountryUnit();
  const [attemptsExceededDialog, setAttemptsExceededDialog] = useToggle(false);

  const UoM = useMemo(() => convertToApiUnit(countryUnit), [countryUnit]);

  const initialValues = {
    speed: selectedAlert ? selectedAlert.config[0].value : defaultSpeedValue,
    inVehicleWarning: selectedAlert ? selectedAlert.inVehicleWarning : false,
  };

  const getAlerts = async () => {
    setLoading(true);
    try {
      const { data } = await api.getAlertService();
      dispatch({ type: ALERTS_ACTIONS.GET_ALERTS, data });
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      console.log('error api', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAlerts();
  }, []);

  const onItemEdit = (alert: DrivingBehaviorResponse) => {
    setSelectedAlert(alert);
    setView('editItem');
  };

  const updateAlert = async (formValues: AlertData, selectedAlert?: DrivingBehaviorResponse) => {
    const updatedAlert = saveSpeedAlert({ ...formValues, UoM }, { drivingBehavior: selectedAlert });
    if (updatedAlert) {
      try {
        dispatch(setLoadingStatus(true));
        await api.updateAlertService(updatedAlert);
        speedAlertId = selectedAlert?.lastSvcReqId || '';
        startPolling(updateSuccessMsg, updateErrorMsg);
      } catch (e) {
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const setSpeedAlerts = (response: APIResponse<DrivingBehaviorResult>) => {
    dispatch({ type: ALERTS_ACTIONS.GET_ALERTS, data: response.data });
  };

  const validationCallback = (response: APIResponse<DrivingBehaviorResult>) => {
    const speedAlert = response.data.svcRequests.find((item) => item.id === speedAlertId);
    const speedAlertStatus = speedAlert?.status;

    if (speedAlertStatus?.includes(Status.SUCCESS) || speedAlert === undefined) {
      setSpeedAlerts(response);
      return PollingStatus.SUCCESS;
    }

    if (speedAlertStatus?.includes(Status.FAILED)) {
      setSpeedAlerts(response);
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  };

  const successCallback = (message: string) => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
  };

  const errorCallback = (message: string) => {
    getAlerts();
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  };

  const warningCallback = () => {
    setAttemptsExceededDialog(true);
    onFormClose();
    getAlerts();
  };

  const closeModal = () => {
    setAttemptsExceededDialog(false);
  };

  const startPolling = (successMsg: string, errorMsg: string) => {
    initiatePolling({
      pollingFunc: api.getAlertService.bind(api),
      validationCallback,
      successCallback: successCallback.bind(this, successMsg),
      errorCallback: errorCallback.bind(this, errorMsg),
      warningCallback,
      timeout: pollingTime,
    });
  };

  const createAlert = async (formValues: AlertData) => {
    const createdAlert = saveSpeedAlert({ ...formValues, UoM }, null);
    if (createdAlert) {
      try {
        dispatch(setLoadingStatus(true));
        const { data: addAlertResponse } = await api.createAlertService(createdAlert);
        // TODO:
        speedAlertId = addAlertResponse.svcReqId;
        startPolling(createSuccessMsg, createErrorMsg);
      } catch (err) {
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const onItemDelete = async (selectedAlert: DrivingBehaviorResponse) => {
    try {
      dispatch(setLoadingStatus(true));
      await api.deleteAlertService(selectedAlert);
      speedAlertId = selectedAlert.lastSvcReqId;
      startPolling(deleteSuccessMsg, deleteErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: deleteErrorMsg, title: errorDialogHeader } });
    }
  };

  const onFormClose = () => {
    setSelectedAlert(null);
    setView('default');
    dispatch(setLoadingStatus(false));
  };

  const onFormConfirm = (formValues: AlertData) => {
    if (!!selectedAlert) {
      updateAlert(formValues, selectedAlert);
    } else {
      createAlert(formValues);
    }
  };

  const onChangeItemWarning = (selectedAlert: DrivingBehaviorResponse, lastKnownState: string) => {
    const updateInitialValues = { ...initialValues, speed: selectedAlert.config[0].value, lastKnownState };
    updateAlert(updateInitialValues, selectedAlert);
  };

  const renderCardItems = () => {
    const isEditView = view === 'editList';

    return (
      <div className={stylesMonitoring['card-list']}>
        {speedAlert?.drivingBehavior?.map((alert) => {
          const {
            config: [configItem],
            lastKnownState,
          } = alert;
          const { value } = configItem;
          const format = getSpeedFormat();
          const label = `${value} ${format}`;
          const isChecked = lastKnownState === 'ACTIVE';
          const oppositeState = isChecked ? 'INACTIVE' : 'ACTIVE';

          return (
            <ManageCardItem
              key={label}
              label={label}
              handlers={isEditView ? 'edit' : 'switch'}
              isChecked={isChecked}
              onSwitchToggle={!isEditView ? () => onChangeItemWarning(alert, oppositeState) : undefined}
              onItemEdit={isEditView ? () => onItemEdit(alert) : undefined}
              onItemRemove={isEditView ? () => onItemDelete(alert) : undefined}
            />
          );
        })}
      </div>
    );
  };

  const speedAlertCount = speedAlert?.drivingBehavior?.length || 0;
  const renderAlertCount = () =>
    !!speedAlert?.drivingBehavior && (
      <AlertsCount
        label={alertSetLabel}
        countCurrent={speedAlertCount}
        countMax={alertsLimit}
        preposition={alertSetPreposition}
      />
    );

  if (isLoading) return <Loader style={{ height: '100%' }} />;

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader
          label={title}
          content={!speedAlertCount ? defaultViewContent : ''}
          onClickInfo={() => {
            trackEvent(iButtonAnalyticsEventName);
            setView('tabInfo');
          }}
          onEdit={() => setView('editList')}
          displayEditButton={speedAlertCount > 0}
        />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container']}>
          <Button
            variant="contained"
            onClick={() => {
              trackEvent(addNewAnalyticsEventName);
              setView('editItem');
            }}
            disabled={speedAlert?.drivingBehavior?.length >= alertsLimit}
          >
            {defaultViewButtonLabel}
          </Button>
        </div>
        {renderAlertCount()}
      </ActiveView>
      <ActiveView currentView={view} activeView={'editList'}>
        <TabHeader label={editViewTitle} onEdit={() => setView('default')} />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container']}>
          <Button
            variant="outlined"
            onClick={() => {
              trackEvent(editAnalyticsEventName);
              setView('default');
            }}
          >
            {editViewButtonLabel}
          </Button>
        </div>
        {renderAlertCount()}
      </ActiveView>
      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={formViewTitle} />
        <SpeedAlertForm
          unit={countryUnit}
          minSpeed={minSpeed}
          maxSpeed={maxSpeed}
          label={fieldLabel}
          description={fieldDescription}
          switchLabel={switchDescription}
          errorMessage={fieldErrorMessage}
          initialValues={initialValues}
          onFormConfirm={onFormConfirm}
          onFormClose={onFormClose}
          cancelButtonLabel={cancelButton}
          confirmButtonLabel={confirmButton}
          analyticsEventNames={{
            speedLimitAnalyticsEventName,
            displayAlertInvehicleOnAnalyticsEventName,
            displayAlertInvehicleOffAnalyticsEventName,
            cancelAnalyticsEventName,
            saveAnalyticsEventName,
          }}
        />
      </ActiveView>
      <ActiveView currentView={view} activeView={'tabInfo'}>
        <TabInfo onClose={() => setView('default')}>
          <TabHeader label={title} content={infoBoxContent} showInfoIcon />
        </TabInfo>
      </ActiveView>
      <Modal
        open={attemptsExceededDialog}
        onClose={closeModal}
        primaryControl={
          <Button variant="contained" onClick={closeModal}>
            Ok
          </Button>
        }
      >
        <p>{attemptsExceededMessage}</p>
      </Modal>
    </>
  );
}
