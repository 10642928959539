import { ActionTypes } from '@lib-appRouter/flowSessionStorage';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import { OfferType, SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { assign, send } from 'xstate';
import { getPackagesMatchingOfferType } from '@manageSubscription';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, SubaruManagePromoPackages } from '../Types';

export const managePromoPackagesFlow: ComponentFlowStateConfig<SubaruManagePromoPackages> = () => ({
  initial: 'pre',
  id: ComponentRoutes.managePromoPackages,
  states: {
    pre: {
      entry: [
        assign({
          eligiblePromoPackages: (context) => {
            const {
              promoPackagesWebContent: { rrsPromoCriteria, subscriberPromoCriteria },
            } = context.content;
            const { salesChannel } = context.subscriptionProps;
            const { eligiblePackages } = context.flowSessionStorage;
            const offerTypesToMatch = [OfferType.Promotional, OfferType.Trial];
            const promoCriteria =
              salesChannel === SalesChannel.Rapid_registration ? rrsPromoCriteria : subscriberPromoCriteria;

            return getPackagesMatchingOfferType(eligiblePackages, promoCriteria, offerTypesToMatch).sort((x) =>
              x.variant.discounts[0].offerType === OfferType.Promotional ? 1 : -1,
            );
          },
        }),
        send(() => ({
          type: FlowEventName.SET_SESSION_STORAGE,
          action: { type: ActionTypes.RemovePackageSubscriptions },
        })),
        assign({
          maxSelectedPromoPackages: 1,
          disableWithoutPromoPackages: true,
          hideBackButtonOnPaid: true,
        }),
      ],

      always: [{ target: 'navigate', cond: 'skipPromoPackagesSubaru' }, 'idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.managePromoPackages },
        }),
      ],
      on: {
        NAVIGATE: 'navigate',
        NAVIGATE_OR_SKIP: 'navigateOrSkip',
      },
    },
    navigate: {
      always: [{ target: 'navigateForward' }, 'navigateToCancel'],
    },
    navigateOrSkip: {
      always: [{ target: 'navigateToSkip', cond: 'isTrialEligibleHasNoPaidPkg' }, 'navigateForward'],
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateToCancel: {
      entry: 'navigateToCancel',
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});
