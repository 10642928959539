import React, { ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './Card.module.css';
import { useTheme } from '@mui/material';

type CardProps = {
  children?: ReactNode;
  className?: string;
  type?: 'default' | 'main';
  classes?: {
    card?: ClassValue;
    inner?: ClassValue;
  };
};

function Card({ className, type = 'default', children, classes }: CardProps) {
  const theme = useTheme();
  return (
    <div
      className={clsx(styles.Card, styles[`Card--${type}`], className, classes?.card)}
      style={{
        borderTop: type === 'main' ? `8px solid ${theme.palette.primary.main}` : '',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <div className={clsx(styles['Card-inner'], classes?.inner)}>{children}</div>
    </div>
  );
}

export default Card;
