import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';
import { SubscriptionProps } from '@manageSubscription';
import { CONFIG_KEYS } from '@manageSubscription/utils/constants';

export const getWifiEligible = async (props: SubscriptionProps) => {
  const {
    accessToken,
    config,
    vehicleDetails: { vehicleId },
  } = props;
  const request: APIRequest = {
    url: config.get(CONFIG_KEYS.SCN_WIFI_ELIGIBLITY_URL),
    method: HttpMethodEnum.POST,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      vehicleId,
    },
  };
  return callHttpRequestMethod(request);
};

export const activateWifiSubscription = (props: SubscriptionProps) => {
  const {
    accessToken,
    config,
    vehicleDetails: { vehicleId, registrationCountry },
  } = props;
  const request: APIRequest = {
    url: config.get(CONFIG_KEYS.SCN_WIFI_SUBSCRIPTION_URL),
    method: HttpMethodEnum.POST,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      vehicleId,
      vehicleCountryCode: registrationCountry,
    },
  };
  return callHttpRequestMethod(request);
};
