import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ROOT_EVENTS } from '@app/reducers';
import useOpenPage from '@hooks/useOpenPage';

const useLogout = () => {
  const dispatch = useDispatch();
  const backToLogin = useOpenPage('/login');

  return useCallback(() => {
    dispatch({ type: ROOT_EVENTS.DESTROY_SESSION });
    backToLogin();
  }, [dispatch, backToLogin]);
};

export default useLogout;
