import { useApi } from '@api';
import { useQuery } from '@tanstack/react-query';

export function useGetInvoices(count: number) {
  const api = useApi();
  return useQuery({
    queryKey: ['getListOfRecentInvoices'],
    queryFn: async () => {
      const {
        data: { invoiceList },
      } = await api.getListOfRecentInvoices(count);
      return invoiceList;
    },
  });
}

export default useGetInvoices;
