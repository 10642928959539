import React, { useState } from 'react';
import Markdown from '@components/Markdown';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import { Button } from '@components-lib';
import { getCookie, setCookie } from '@utils/cookies';
import styles from './CookieBar.module.css';
import { ContentfulImage } from '@cv/webframework-react-components';
import { ContentfulFile } from '@app/types/ContentfulComponent';

export const cookieName = 'loadCookieBar';
type CookieBarProps = {
  cookiesCloseIcon: ContentfulFile;
};

enum BannerState {
  SHOW = 'show',
  HIDE = 'hide',
}
const bannerState = {
  cookieName: 'loadCookieBar',
  initialize() {
    if (getCookie(this.cookieName)) {
      return this;
    }
    return this.show();
  },
  show() {
    setCookie(this.cookieName, BannerState.SHOW);
    return this;
  },
  hide() {
    setCookie(this.cookieName, BannerState.HIDE);
    return this;
  },
  get shouldShow() {
    return getCookie(this.cookieName) === BannerState.SHOW;
  },
};

const CookieBar = ({ cookiesCloseIcon }: CookieBarProps) => {
  const content = get('cookiesMessage', '');

  if (!content || !cookiesCloseIcon) {
    return null;
  }

  const [showBanner, setShowBanner] = useState(bannerState.initialize().shouldShow);
  if (!showBanner) {
    return null;
  }
  const hideBanner = () => setShowBanner(bannerState.hide().shouldShow);

  return (
    <div className={styles['Cookie-div']}>
      <Markdown>{content}</Markdown>
      <Button variant="text" className={styles['Cookie-button']} onClick={hideBanner}>
        <ContentfulImage imageData={cookiesCloseIcon} />
      </Button>
    </div>
  );
};
export default CookieBar;
