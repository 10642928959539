import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { IUserState } from '@redux/reducers/user';
import { signInToGoogle } from '@api/services/google';
import UserCalendar from '@customTypes/UserCalendar';
import { LabelsPrimaries } from '@hooks/useLabels';
import { useDialog } from '@components/Dialog/useDialog';
import CalendarDialog from '@components/ActionCard/Calendar/CalendarDialog';

const useCalendarDialog = (labels: LabelsPrimaries, onSelect: (selectedCalendar: UserCalendar) => void) => {
  const { calendarList }: IUserState = useSelector(({ userReducer }: RootState) => userReducer);
  const dialog = useDialog();

  const signInToFetchCalendars = useCallback(() => {
    if (calendarList === null) {
      signInToGoogle();
    }
  }, [calendarList]);

  const showDialog = useCallback(
    () =>
      dialog({
        title: labels.dialogCalendarTitle,
        children: (
          <CalendarDialog
            dialogDescription={labels.dialogCalendarDescription}
            noCalendarsFound={labels.noCalendarsFound}
            cancelText={labels.cancel}
            selectText={labels.btnSelectText}
            onClose={() => dialog.close()}
            onSelect={onSelect}
          />
        ),
      }),
    [dialog, labels, onSelect],
  );

  const selectCalendar = useCallback(() => {
    signInToFetchCalendars();
    showDialog();
  }, [showDialog, signInToFetchCalendars]);

  return selectCalendar;
};

export default useCalendarDialog;
