import React, { useMemo } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import {
  useFlowMachineContext,
  useFlowMachineContextContent,
  useFlowMachineContextSubscriptionProps,
} from '../../Router/RouterContext';
import Button from '../../components/Button';
import { Card, CardInner, Container, Title } from './styles';

const ErrorPage = ({ error }) => {
  const { redirectLogin } = useFlowMachineContextSubscriptionProps();
  const { queryStatus, maritzError } = useFlowMachineContext();

  const {
    commonWebContent: {
      errorDefaultDescription = 'There seems to be a problem. Please call Customer Care at 844-711-8100.',
      errorDefaultTitle = 'preferences.customerService',
      errorMaritzDescription = 'Inform customer that additional proof of ownership is required, and to contact Nissan USA.',
      errorMaritzTitle = 'Maritz Failure',
      errorPooTitle = 'Proof of ownership is needed',
    },
  } = useFlowMachineContextContent();
  const [title, description] = useMemo(() => {
    if (queryStatus === 'POO') {
      return [errorPooTitle, errorDefaultDescription];
    }
    if (maritzError) {
      return [errorMaritzTitle, errorMaritzDescription];
    }
    return [errorDefaultTitle, errorDefaultDescription];
  }, [queryStatus, maritzError]);

  return (
    <Container>
      <Card main>
        <CardInner>
          <Title>
            <FaTimesCircle />
            <span>{title}</span>
          </Title>
          <p>{description}</p>
          <Button onClick={redirectLogin}>Continue</Button>
        </CardInner>
      </Card>
    </Container>
  );
};

export default ErrorPage;
