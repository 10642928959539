import React from 'react';

import { PaymentContextProvider, SubscriptionProps } from '@components-lib';
import { ServerAsset } from '@utils/assets';
import { useGenerateSubscriptionContent, useGenerateSubscriptionProps } from '@components/SubscriptionWrapper/utils';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import useLabels from '@hooks/useLabels';
import InvoicesList from '@components/InvoicesList/InvoicesList';

type CreditCardInfoConnectorProps = {
  creditCardImages: ServerAsset[];
  labelCollections: ContentfulLabelType[];
  showInvoices: boolean;
  invoicesAmount: number;
};

export function CreditCardInfo({
  creditCardImages,
  labelCollections,
  showInvoices,
  invoicesAmount,
}: CreditCardInfoConnectorProps) {
  const subHeadersDictionary = useLabels(findDictionary(labelCollections, 'subHeaders'));
  const invoicesDictionary = useLabels(findDictionary(labelCollections, 'invoices'));
  const content = useGenerateSubscriptionContent({
    labels: labelCollections,
    assets: creditCardImages,
  });

  const paymentProps: SubscriptionProps = useGenerateSubscriptionProps();

  const webContent = {
    ...content,
    commonWebContent: {
      ...content.commonWebContent,
      continueButtonLabel: content.commonWebContent.saveLabel,
      saveAndContinueButtonLabel: content.commonWebContent.saveLabel,
    },
    titles: subHeadersDictionary.getAllPrimaries(),
  };

  return (
    <PaymentContextProvider content={webContent} subscriptionProps={paymentProps}>
      {showInvoices && invoicesDictionary && (
        <InvoicesList
          count={invoicesAmount}
          labels={{
            ...invoicesDictionary.getAllPrimaries(),
            closeButtonLabel: content.commonWebContent.closeButtonLabel,
          }}
        />
      )}
    </PaymentContextProvider>
  );
}

export default CreditCardInfo;
