import React from 'react';
import { Product as SubscriptionProduct } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import Product from '@manageSubscription/Products/Product';
import {
  useFlowLocation,
  useFlowMachineContextSubscriptionProps,
  useFlowMachineContextContent,
} from '@lib-appRouter/RouterContext';

export type PackageProductProps = {
  product: SubscriptionProduct;
};

const PackageProduct = ({ product }: PackageProductProps) => {
  const location = useFlowLocation();
  const {
    locale,
    tenantId,
    vehicleDetails: { make },
  } = useFlowMachineContextSubscriptionProps();
  const { assets } = useFlowMachineContextContent();

  return (
    <Product
      productInfo={product}
      assets={assets}
      make={make}
      locale={locale}
      location={location}
      tenantId={tenantId}
    />
  );
};

export default PackageProduct;
