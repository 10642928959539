import { styled } from '@mui/material';
import { Content, Heading } from '../../styled-components/globalStyles';

export const Header = styled(Heading)`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;

export const Body = styled(Content)`
  margin-top: 1rem;
`;
