import { send } from 'xstate/lib/actions';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import { assignSubscriptionProps } from '@lib-appRouter/flowUtils';

export const checkIfEmailIsOnFileFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'initial',
  id: ComponentRoutes.checkIfEmailIsOnFile,
  states: {
    initial: {
      always: [{ target: 'NAVIGATE', cond: 'hasEmailOnFile' }, 'idle'],
    },
    idle: {
      entry: send({
        type: FlowEventName.PUSH_HISTORY,
        data: { componentRoute: ComponentRoutes.checkIfEmailIsOnFile },
      }),
      on: {
        SET_EMAIL_ON_FILE: {
          actions: [
            assignSubscriptionProps((_, event) => {
              const email = event.data.email;
              return {
                userDetails: {
                  email,
                },
              };
            }),
          ],
          target: 'NAVIGATE',
        },
      },
    },
    NAVIGATE: {
      entry: FlowEventName.NAVIGATE_FORWARD,
    },
  },
});
