import { send } from 'xstate';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { getEligibleTrialPackages } from '@manageSubscription/utils';
import { ActionTypes } from '../../../flowSessionStorage';
import { FlowEventName } from '../../../flowTypes';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig, ManageTrialPackagesFlowContext } from '../Types';

export const manageTrialPackagesFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.manageTrialPackages,
  states: {
    pre: {
      entry: 'getEligibleTrialPackages',
      always: [{ target: 'navigate', cond: 'hasNoEligibleTrialPackages' }, 'idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.manageTrialPackages },
        }),
      ],
      on: {
        ADD_ALL_PACKAGES: 'addAllPackages',
        HANDLE_NO_THANKS: 'handleNoThanks',
      },
    },
    addAllPackages: {
      entry: ['dispatchAddAllTrialPackages'],
      after: {
        50: [{ target: 'navigateToSkip', cond: 'hasSelectedTrialEligibleVariant' }, { target: 'navigate' }],
      },
    },
    handleNoThanks: {
      entry: ['dispatchAddAllTrialPackages'],
      always: 'navigate',
    },
    navigate: {
      entry: 'navigateForward',
    },
    navigateToSkip: {
      entry: FlowEventName.NAVIGATE_TO_SKIP,
    },
  },
});

export const actions: ComponentActions<ManageTrialPackagesFlowContext> = {
  dispatchAddAllTrialPackages: send((_, event) => {
    const packages = event.data.packages as EligiblePackageInfo[];
    return {
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddAllTrialPackages, payload: packages },
    };
  }),
  addFirstTrialEligiblePackage: send((context) => {
    const { eligiblePackages, subscribedPackages } = context.flowSessionStorage;
    const eligibleTrialPackages = getEligibleTrialPackages(eligiblePackages, subscribedPackages);
    return {
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddTrialPackage, payload: eligibleTrialPackages[0] },
    };
  }),
};
