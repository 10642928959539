import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { callHttpRequestMethod } from '@cv/portal-common-lib';
import { Entitlement } from '@api/types';
import config from '@config/config';

export type CpsRtsEntitlements = {
  cpsEntitlements: Entitlement[];
  rtsEntitlements: Entitlement[];
};

export const fetchEntitlements = (accessToken: string): Promise<CpsRtsEntitlements> => {
  const userInfoUrl: string = config.getOemValue('USER_INFO_URL') || '';

  const request = {
    url: userInfoUrl,
    method: HttpMethodEnum.POST,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  return callHttpRequestMethod(request)
    .then(({ data }) => ({
      cpsEntitlements: data?.sxmCPSEntitlement?.map((entitlement) => JSON.parse(entitlement)) || [],
      rtsEntitlements: data?.sxmRTSEntitlement?.map((entitlement) => JSON.parse(entitlement)) || [],
    }))
    .catch(() => ({
      cpsEntitlements: [],
      rtsEntitlements: [],
    }));
};

export const hasEntitlement = (entitlements: Entitlement[], entitlement: string) =>
  entitlements.some(({ resource }) => resource === entitlement);

export const isEntitledForMarketingPreferences = (accessToken: string) =>
  fetchEntitlements(accessToken)
    .then(({ cpsEntitlements }) => hasEntitlement(cpsEntitlements, 'marketing-preferences'))
    .catch(() => false);
