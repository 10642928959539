import React, { useCallback } from 'react';
import { camelCase } from 'lodash';

import COMPONENT_MAPPINGS, { ComponentMapping } from '@components/component-mappings';

type Page = { name: string; path: string; contentSections: mapContentSectionArgs[] };

type RenderPageProps = {
  name: ComponentMapping;
  [key: string]: unknown;
};

const normalizeName = (name: string) => camelCase(name.replace(/portal(.)/, '$1'));

type mapContentSectionArgs = {
  componentType: string;
  content: object[];
};

const mapContentSection = (contents: mapContentSectionArgs[]) => {
  return contents.reduce((acc, item) => {
    const normalizedName = normalizeName(item.componentType);

    return {
      ...acc,
      [normalizedName]: item.content,
    };
  }, {});
};

const useRenderPage = ({ pagesList }: { pagesList?: Page[] }) => {
  return useCallback(
    ({ name }: RenderPageProps) => {
      if (!pagesList || pagesList.length === 0) {
        return null;
      }

      const page = pagesList.find((page: Page) => page.path === name);

      if (!page) {
        return null;
      }

      return React.createElement(
        // TEMP to prevent typescript error
        COMPONENT_MAPPINGS[name] as any,
        {
          key: page.name,
          ...mapContentSection(page.contentSections),
        },
      );
    },
    [pagesList],
  );
};

export default useRenderPage;
