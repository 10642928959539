import React, { useState } from 'react';
import countries from '../utils/countries';
import { Container, Heading, Title } from '../../styled-components/globalStyles';
import { UserProfileWrapper } from '../UserProfile/userProfileStyles';
import { Form, Formik, FormikProps } from 'formik';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { formatPayload, FormValues, handleSubmit, stateSelector } from '../utils/addressFormUtils';
import { hasCorrectFullName, isNameValid } from '../utils';
import { WebContent, SubscriptionProps } from '../Subscription';
import Spinner from '../../components/Spinner';
import FormikAddressField, { AddressType } from '../../components/FormikAddressField';
import FormikField from '@lib-components/FormikField';
import { getListAddresses, formatAddress } from '../../services/cps/utility';
import { ListAddressesResponse } from '@cv/portal-cps-lib/utility/utility-services/models';
import { MenuItem, OutlinedInput, Select } from '@mui/material';

export type AddressFormProps = {
  allowEditName: boolean;
  content: WebContent;
  subscriptionProps: SubscriptionProps;
  setSubscriptionProps: (props: SubscriptionProps) => void;
  setDisplayPayment: (display: boolean) => void;
};

export const AddressForm = ({
  allowEditName,
  subscriptionProps,
  setSubscriptionProps,
  content,
  setDisplayPayment,
}: AddressFormProps) => {
  const [displayBillingForm, setDisplayBillingForm] = useState<boolean>(false);
  const [displayCFDIPostalCode, setDisplayCFDIPostalCode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    addressFormWebContent: {
      streetError,
      cityError,
      countryError,
      postalCodeError,
      stateError,
      submitButtonLabel,
      billingAndMailingSameLabel,
      mailingAddressLabel,
      billingAddressLabel,
      title,
    },
    userProfileWebContent: {
      firstNameInputLabel,
      firstNamePlaceholder,
      lastNameInputLabel,
      lastNamePlaceholder,
      firstNameValidationError,
      lastNameValidationError,
    },
    CFDILabels: {
      usoCFDI,
      usoCFDIError,
      usoCFDIPlaceholder,
      usoCFDIOptions,
      regimenFiscalReceptor,
      regimenFiscalReceptorError,
      regimenFiscalReceptorPlaceholder,
      regimenFiscalReceptorOptions,
      postalCodeCFDISameAsHomeZipCode,
      postalCodeCFDI,
      postalCodeCFDIError,
      postalCodeCFDIPlaceholder,
    },
    mexicoStates,
  } = content;

  const {
    userDetails: { userId, firstName, lastName },
    vehicleDetails: { registrationCountry },
  } = subscriptionProps;

  const needCorrectFullName = allowEditName && !hasCorrectFullName({ firstName, lastName });

  const handleFormSubmit = (values: FormValues) => {
    const payload = formatPayload({
      values,
      userId,
      billingSameAsMailing: !displayBillingForm,
      postalCodeCFDISameAsHomeZipCode: !displayCFDIPostalCode,
      shouldProvideCFDIPostalCode: !!postalCodeCFDISameAsHomeZipCode,
    });

    handleSubmit({
      payload,
      subscriptionProps,
      setSubscriptionProps,
      setDisplayPayment,
      setIsLoading,
      needCorrectFullName,
    });
  };

  const states = stateSelector(subscriptionProps.locale, mexicoStates);

  const initialValues = {
    ...(needCorrectFullName ? { firstName: firstName || '', lastName: lastName || '' } : {}),
    mailingSameAsBilling: false,
    street__mailing: '',
    street__mailing2: '',
    city__mailing: '',
    state__mailing: '',
    country__mailing: registrationCountry || '',
    postalCode__mailing: '',
    street__billing: '',
    street__billing2: '',
    city__billing: '',
    state__billing: '',
    country__billing: registrationCountry || '',
    postalCode__billing: '',
    customField02: '',
    customField03: '',
    customField04: '',
    postalCodeCFDISameAsHomeZipCode: false,
  };

  const handleDisplayBillingAddress = () => {
    setDisplayBillingForm((prev) => !prev);
  };

  const handleDisplayCFDIPostalCode = () => {
    setDisplayCFDIPostalCode((prev) => !prev);
  };

  const loadOptions = (inputText: string) =>
    getListAddresses(
      {
        isoCountryCode: registrationCountry,
        query: inputText,
      },
      subscriptionProps,
    )
      .then(({ data = [] }) => data)
      .catch(() => []);

  const formatOption = (address: ListAddressesResponse, isoCountryCode: string) =>
    formatAddress({ id: address.id, isoCountryCode }, subscriptionProps).then(({ data }) => data);

  const addressSchema = Yup.object().shape({
    ...(needCorrectFullName
      ? {
          firstName: Yup.string()
            .required(firstNameValidationError)
            .test("does not equal 'default'", firstNameValidationError, isNameValid),

          lastName: Yup.string()
            .required(lastNameValidationError)
            .test("does not equal 'default'", lastNameValidationError, isNameValid),
        }
      : {}),
    mailingSameAsBilling: Yup.boolean(),
    street__mailing: Yup.string().required(streetError),
    street__mailing2: Yup.string(),
    city__mailing: Yup.string().required(cityError),
    state__mailing: Yup.string().required(stateError),
    country__mailing: Yup.string().required(countryError),
    postalCode__mailing: Yup.string().required(postalCodeError),
    street__billing: Yup.string().when('mailingSameAsBilling', {
      is: true,
      then: (schema) => schema.required(streetError),
    }),
    street__biling2: Yup.string(),
    city__billing: Yup.string().when('mailingSameAsBilling', {
      is: true,
      then: (schema) => schema.required(cityError),
    }),
    state__billing: Yup.string().when('mailingSameAsBilling', {
      is: true,
      then: (schema) => schema.required(stateError),
    }),
    country__billing: Yup.string().when('mailingSameAsBilling', {
      is: true,
      then: (schema) => schema.required(countryError),
    }),
    postalCode__billing: Yup.string().when('mailingSameAsBilling', {
      is: true,
      then: (schema) => schema.required(postalCodeError),
    }),
    ...(!!usoCFDI && !!usoCFDIOptions
      ? {
          customField02: Yup.string().required(usoCFDIError),
        }
      : {}),
    ...(!!regimenFiscalReceptor && !!regimenFiscalReceptorOptions
      ? {
          customField03: Yup.string().required(regimenFiscalReceptorError),
        }
      : {}),
    ...(!!postalCodeCFDI && !!postalCodeCFDISameAsHomeZipCode
      ? {
          postalCodeCFDISameAsHomeZipCode: Yup.boolean(),
          customField04: Yup.string().when('postalCodeCFDISameAsHomeZipCode', {
            is: true,
            then: (schema) => schema.required(postalCodeCFDIError),
          }),
        }
      : {}),
  });

  type CPSAddressFieldProps = {
    addressType: AddressType;
    props: FormikProps<typeof initialValues>;
  };
  const CPSAddressField = ({ addressType, props }: CPSAddressFieldProps) => (
    <FormikAddressField
      addressType={addressType}
      name={`${addressType}AddressField`}
      content={content}
      registrationCountry={registrationCountry}
      states={states.map((state) => ({
        label: state.name,
        value: state.abbreviation,
      }))}
      countries={countries.map((country) => ({
        label: country.name,
        value: country.abbreviation,
      }))}
      loadOptions={loadOptions}
      formatOption={formatOption}
      {...props}
    />
  );
  if (isLoading) return <Spinner />;

  return (
    <Container>
      <Heading>{title}</Heading>
      <UserProfileWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={addressSchema}
          onSubmit={handleFormSubmit}
          initialTouched={needCorrectFullName ? { firstName: true, lastName: true } : undefined}
          validateOnMount={needCorrectFullName}
        >
          {(props: FormikProps<typeof initialValues>) => (
            <div>
              <Form>
                <Title>{mailingAddressLabel}</Title>

                {needCorrectFullName && (
                  <>
                    <FormikField
                      id="firstName"
                      name="firstName"
                      required
                      label={firstNameInputLabel}
                      placeholder={firstNamePlaceholder}
                    />
                    <FormikField
                      id="lastName"
                      name="lastName"
                      required
                      label={lastNameInputLabel}
                      placeholder={lastNamePlaceholder}
                    />
                  </>
                )}

                <CPSAddressField addressType="mailing" props={props} />

                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                  <input
                    type="checkbox"
                    name="mailingSameAsBilling"
                    id="mailingSameAsBilling"
                    checked={!props.values.mailingSameAsBilling}
                    onChange={() => {
                      props.setFieldValue('mailingSameAsBilling', !props.values.mailingSameAsBilling);
                      handleDisplayBillingAddress();
                    }}
                  />
                  <label htmlFor="mailingSameAsBilling">
                    <p style={{ paddingLeft: 10 }}>{billingAndMailingSameLabel}</p>
                  </label>
                </div>

                {props.values.mailingSameAsBilling && (
                  <div>
                    <Title>{billingAddressLabel}</Title>
                    <CPSAddressField addressType="billing" props={props} />
                  </div>
                )}

                {!!usoCFDI && !!usoCFDIOptions && (
                  <FormikField
                    name="customField02"
                    id="customField02"
                    label={usoCFDI}
                    Component={Select}
                    input={<OutlinedInput label={usoCFDI} />}
                    required
                    aria-label={usoCFDI}
                    placeholder={usoCFDIPlaceholder}
                    fullWidth
                    children={usoCFDIOptions.split('::').map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  />
                )}

                {!!regimenFiscalReceptor && !!regimenFiscalReceptorOptions && (
                  <FormikField
                    name="customField03"
                    id="customField03"
                    label={regimenFiscalReceptor}
                    Component={Select}
                    input={<OutlinedInput label={regimenFiscalReceptor} />}
                    required
                    aria-label={regimenFiscalReceptor}
                    placeholder={regimenFiscalReceptorPlaceholder}
                    fullWidth
                    children={regimenFiscalReceptorOptions.split('::').map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  />
                )}

                {!!postalCodeCFDI && !!postalCodeCFDISameAsHomeZipCode && (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                      <input
                        type="checkbox"
                        name="postalCodeCFDISameAsHomeZipCode"
                        id="postalCodeCFDISameAsHomeZipCode"
                        checked={!props.values.postalCodeCFDISameAsHomeZipCode}
                        onChange={() => {
                          props.setFieldValue(
                            'postalCodeCFDISameAsHomeZipCode',
                            !props.values.postalCodeCFDISameAsHomeZipCode,
                          );
                          handleDisplayCFDIPostalCode();
                        }}
                      />
                      <label htmlFor="postalCodeCFDISameAsHomeZipCode">
                        <p style={{ paddingLeft: 10 }}>{postalCodeCFDISameAsHomeZipCode}</p>
                      </label>
                    </div>

                    {props.values.postalCodeCFDISameAsHomeZipCode && (
                      <FormikField
                        name="customField04"
                        id="customField04"
                        label={postalCodeCFDI}
                        placeholder={postalCodeCFDIPlaceholder}
                      />
                    )}
                  </>
                )}

                <Button
                  fullWidth
                  disabled={!(props.dirty && props.isValid && !props.isSubmitting)}
                  onClick={props.submitForm}
                  type="submit"
                >
                  {submitButtonLabel}
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      </UserProfileWrapper>
    </Container>
  );
};
