// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vZ1TcPLNexnQnXMJJjuy ul {
  padding-left: 1rem;
}

.vZ1TcPLNexnQnXMJJjuy li > p {
  margin: 0;
}

.KH0NC3wgb6P_UP5kfdxl {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bnctJauXhIy678fvGwgM {
  min-width: 150px;
  margin: 1rem;
}

.nFXjOHpBjcLo0XumAnC1 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.CtadcdMyE1WsJwKMl0JO {
  max-width: 100%;
  color: var(--theme-color-text);
  margin: 0 auto;
  margin-top: 3rem;
}

.CtadcdMyE1WsJwKMl0JO .Rb6rCcF8agHRRBcj2qK0 {
  text-align: center;
}

@media (min-width: 1200px) {
  .CtadcdMyE1WsJwKMl0JO {
    max-width: 58.33%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ServiceOverview/ServicesOverview.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".container ul {\n  padding-left: 1rem;\n}\n\n.container li > p {\n  margin: 0;\n}\n\n.body {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.button {\n  min-width: 150px;\n  margin: 1rem;\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.card {\n  max-width: 100%;\n  color: var(--theme-color-text);\n  margin: 0 auto;\n  margin-top: 3rem;\n}\n\n.card .title {\n  text-align: center;\n}\n\n@media (--viewport-l) {\n  .card {\n    max-width: 58.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vZ1TcPLNexnQnXMJJjuy`,
	"body": `KH0NC3wgb6P_UP5kfdxl`,
	"button": `bnctJauXhIy678fvGwgM`,
	"buttonsContainer": `nFXjOHpBjcLo0XumAnC1`,
	"card": `CtadcdMyE1WsJwKMl0JO`,
	"title": `Rb6rCcF8agHRRBcj2qK0`
};
export default ___CSS_LOADER_EXPORT___;
