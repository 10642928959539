import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material';

import styles from './Tab.module.css';

export type TabItemProps = {
  activeTab: 'none' | string;
  label: string;
  onClick: () => void;
  type: string;
  showTab: boolean;
  children: React.ReactNode;
  ['data-label']: string;
  className: string;
  style?: unknown;
};

const TabItem = ({
  activeTab = 'none',
  label,
  onClick = () => {},
  type = 'primary',
  showTab,
  className,
  style = {},
}: Partial<TabItemProps>) => {
 
  if(showTab === false) return null;
  
  const itemClassName = clsx(styles.TabItem, styles[`TabItem--${type}`], className);

  const theme = useTheme();

  const tabItemStyle = {
    primary: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: '0.875rem',
      padding: '0.313rem 0.938rem',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      outline: 'none',
    },
    secondary: {
      fontSize: '0.75rem',
      padding: '0.188rem',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
      borderTop: `2px solid ${theme.palette.grey[300]}`,
      width: '100%',
      outline: 'none',
    },
    tertiary: {
      margin: '0.625rem 0',
      fontSize: '0.625rem',
      lineHeight: '0.688rem',
      color: theme.palette.grey[700],
      fontWeight: 'bold',
      borderBottom: '2px solid transparent',
      paddingBottom: '0.125rem',
      outline: 'none',
    },
  };

  const activeTabStyles = {
    primary: { backgroundColor: theme.palette.background.paper, color: theme.palette.primary.main },
    secondary: { borderBottom: `2px solid ${theme.palette.primary.main}` },
    tertiary: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  };

  const isActive = label === activeTab;

  return (
      <button
        className={itemClassName}
        onClick={onClick}
        style={{ ...(tabItemStyle[type] || {}), ...style, ...(isActive ? activeTabStyles[type] || {} : {}) }}
      >
        {label}
      </button>
  );
};

export default TabItem;
