import React, { ReactNode } from 'react';

import './Grid.css';

type GridProps = {
  className?: string;
  columns?: '1' | '2' | '3' | '4';
  style?: unknown;
  children?: ReactNode;
};

function Grid({ className, columns = '2', style = {}, children }: GridProps) {
  return (
    <div className={`${className} grid-column-${columns}`} style={style}>
      {children}
    </div>
  );
}

export default Grid;
