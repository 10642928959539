import React, { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import {
  ModalContainer as SModalContainer,
  Title as STitle,
  CloseButton as SCloseButton,
  Body as SBody,
  Icon as SIcon,
  BtnWrapper as SBtnWrapper,
} from './styles';
import { Breakpoint, Stack } from '@mui/material';
import { GrClose } from 'react-icons/gr';
import { ContentfulFile } from '@manageSubscription';
import clsx from 'clsx';

export type ModalProps = DialogProps & {
  title?: string;
  showXButton?: boolean;
  children?: React.ReactNode;
  icon?: ContentfulFile;
  classes?: {
    modal?: string;
    dialog?: string;
    title?: string;
    icon?: string;
    body?: string;
    controls?: string;
    pBtn?: string;
    sBtn?: string;
  };
  primaryControl?: React.ReactNode;
  secondaryControl?: React.ReactNode;
};
const Modal: FC<ModalProps> = ({
  open,
  maxWidth = 'sm' as Breakpoint,
  title,
  showXButton = true,
  children,
  icon,
  classes = {},
  onClose,
  primaryControl,
  secondaryControl,
}) => {
  const hasAnyBtn = primaryControl || secondaryControl;
  return (
    <Dialog
      classes={{ root: clsx(classes.modal) }}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && !showXButton) {
          return;
        }
        onClose(event, reason);
      }}
      aria-labelledby="modal-modal-title"
      maxWidth={maxWidth}
      disableEscapeKeyDown={!showXButton}
    >
      <SModalContainer classes={{ root: clsx(classes.dialog) }}>
        {!!showXButton && (
          <SCloseButton onClick={onClose as React.MouseEventHandler<HTMLButtonElement>} aria-label="Close">
            <GrClose aria-hidden="true" />
          </SCloseButton>
        )}
        {!!icon && <SIcon className={clsx(classes.icon)} src={icon?.file?.url} alt="Popup icon" />}
        {!!title && (
          <STitle id="modal-modal-title" classes={{ root: clsx(classes.title) }}>
            {title}
          </STitle>
        )}
        {!!children && (
          <SBody component="div" classes={{ root: clsx(classes.body) }}>
            {children}
          </SBody>
        )}
        {hasAnyBtn && (
          <SBtnWrapper disableSpacing classes={{ root: clsx(classes.controls) }} role="group">
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
              {primaryControl}
              {secondaryControl}
            </Stack>
          </SBtnWrapper>
        )}
      </SModalContainer>
    </Dialog>
  );
};
export default Modal;
