import { v4 as uuidv4 } from 'uuid';
import config from '@config/config';

export type ResetPasswordOptions = {
  clientId: string;
  tenantId: string;
  userIdentifier: string;
  resetType?: 'code' | 'link';
  resetChannel?: 'email' | 'sms';
};

const resetPassword = ({
  clientId,
  tenantId,
  userIdentifier,
  resetType = 'code',
  resetChannel = 'email',
}: ResetPasswordOptions) => {
  const resetPasswordUrl = config.getOemValue('PASSWORD_FLOW_RESET_PASSWORD');
  return fetch(resetPasswordUrl, {
    method: 'POST',
    headers: new Headers({
      'cv-client-id': clientId,
      'cv-tenant-id': tenantId,
      'cv-correlation-id': `CWP-${uuidv4()}`,
      'content-type': 'application/json',
    }),
    body: JSON.stringify({
      userIdentifier,
      resetType,
      resetChannel,
    }),
  });
};

export default resetPassword;
