import React, { useRef } from 'react';
import { FieldInputProps, FieldHelperProps } from 'formik';
import { TelephoneInput } from './phoneNumberInputStyles';

export type PhoneInputProps = {
  required?: boolean;
  placeholder?: boolean;
  label?: string;
  value?: string;
  helpers?: FieldHelperProps<string>;
  error?: boolean;
} & Partial<FieldInputProps<string>>;

export const PhoneInput = ({ required, placeholder, label, value, helpers, ...props }: PhoneInputProps) => {
  const ref = useRef({
    value: value || '',
  });

  const handleChange = () => {
    helpers?.setValue(ref.current.value.split(' ').join(''));
  };

  return (
    <TelephoneInput
      {...props}
      ref={ref}
      international
      defaultCountry="US"
      countries={['US', 'CA', 'MX']}
      countrySelectProps={{ disabled: true }}
      required={required}
      placeholder={placeholder}
      title={label || 'Phone Number'}
      value={value || ''}
      onChange={handleChange}
      limitMaxLength
    />
  );
};
