import React, { useMemo } from 'react';
import { OfferDetailSection, SaveMoreBanner } from './styles';
import { Content } from '../../../styled-components/globalStyles';
import { VariantInfo } from '../../Types';
import { OfferDetails } from '../../Discount';
import { PackagePrice } from '../Price/PackagePrice';
import RegularPrice from '../Price/RegularPrice';
import { CommonWebContent, ContentfulAssets, ContentMap, DiscountWebContent, getPromoDiscountInfo } from '../..';

export interface PaidPromoPackageInfoProps {
  packageName: string;
  variant: VariantInfo;
  location: string;
  perTermsLabel: ContentMap;
  discountWebContent: DiscountWebContent;
  commonWebContent: CommonWebContent;
  assets: ContentfulAssets;
  customerCareCancelPackageNumber: string;
}

const PaidPromoPackageInfo: React.FC<PaidPromoPackageInfoProps> = ({
  packageName,
  variant,
  location,
  perTermsLabel,
  discountWebContent,
  commonWebContent: { currencyLabel, shouldIncludeTax, okButtonLabel },
  assets,
  customerCareCancelPackageNumber,
}) => {
  const { regularlyLabel, saveMoreLabel } = discountWebContent;
  const { discounts, listPrice } = variant;
  const { promoDiscount, regularPrice } = getPromoDiscountInfo(discounts, listPrice);

  return (
    <OfferDetailSection>
      <SaveMoreBanner>{saveMoreLabel}</SaveMoreBanner>
      <Content>{promoDiscount?.marketingName || promoDiscount?.description}</Content>
      <RegularPrice label={regularlyLabel}>
        <PackagePrice
          amountWithoutTax={regularPrice}
          perTermsLabel={perTermsLabel}
          currencyLabel={currencyLabel}
          shouldIncludeTax={shouldIncludeTax}
        />
      </RegularPrice>
      <OfferDetails
        packageName={packageName}
        variant={variant}
        discount={promoDiscount}
        okButtonLabel={okButtonLabel}
        discountWebContent={discountWebContent}
        customerCareCancelPackageNumber={customerCareCancelPackageNumber}
        assets={assets}
      />
    </OfferDetailSection>
  );
};

export default PaidPromoPackageInfo;
