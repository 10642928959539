// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SF062wlpB_x8Lm0S5ERA {
  background: none;
  border-radius: unset;
  box-shadow: none;
  padding: 0;
}

.s988A7sTHGTNEX4Ao_xG {
  max-width: 300px;
  margin: 2rem auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ScheduleServiceButton/ScheduleServiceButton.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".ScheduleServiceButtonContainer--warning {\n  background: none;\n  border-radius: unset;\n  box-shadow: none;\n  padding: 0;\n}\n\n.ScheduleServiceButton {\n  max-width: 300px;\n  margin: 2rem auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScheduleServiceButtonContainer--warning": `SF062wlpB_x8Lm0S5ERA`,
	"ScheduleServiceButton": `s988A7sTHGTNEX4Ao_xG`
};
export default ___CSS_LOADER_EXPORT___;
