import React from 'react';

import styles from './infoCard.module.css';
import ContentfulComponent, { ContentfulFile } from '@app/types/ContentfulComponent';
import ContentRenderer from '@components/ContentRenderer';
import { ContentfulImage } from '@cv/webframework-react-components';
import { useTheme } from '@mui/material';

type InfoCardProps = {
  title: string;
  show: boolean;
  items: Array<ContentfulComponent>;
  description: string;
  icon: ContentfulFile;
};

const InfoCard = ({ title, show, items, description, icon }: InfoCardProps): JSX.Element => {
  const theme = useTheme();
  return (
    <div style={{ backgroundColor: 'white' }}>
      {show && (
        <div className={styles['InfoCard-title']}>
          {icon && (
            <div className={styles['img-div']}>
              <ContentfulImage imageData={icon} />
            </div>
          )}
          <h2 style={{ color: theme.palette.primary.main }}>{title}</h2>
          <p className={styles['desc-para']}>{description}</p>
          <div className={styles['InfoCard']}>
            <div className={styles['grid']}>
              {items?.map((infoBox, index) => {
                const content = <ContentRenderer name="infoBox" content={[infoBox]} key={`infoBox-${index}`} />;
                if (infoBox.componentType === 'portalInfoBoxPopUpContent') {
                  return content;
                }
                return (
                  <div className={styles['wrapper']} key={`infoBox-${index}`}>
                    {content}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
