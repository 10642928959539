import { CONFIG_KEYS, SubscriptionProps } from '@manageSubscription';
import { APPLE_PAY_MERCHANT_CAPABILITIES, COUNTRY_DETAIL_LOOKUP, SubscriptionInfo } from '../Types';

export const buildApplePayRequestInfo = (subscriptionProps: SubscriptionProps, subscriptionInfo: SubscriptionInfo) => {
  const {
    config,
    applePaySupportedNetworks,
    userDetails: {
      billingAddress: { country },
    },
  } = subscriptionProps;
  const { totalAmount } = subscriptionInfo;
  const sourceChannel = config.get(CONFIG_KEYS.SOURCE_CHANNEL);
  const { currencyCode, countryCode } = COUNTRY_DETAIL_LOOKUP[country];
  return {
    countryCode,
    currencyCode,
    merchantCapabilities: APPLE_PAY_MERCHANT_CAPABILITIES,
    supportedNetworks: applePaySupportedNetworks,
    total: { label: sourceChannel, amount: totalAmount },
  } as any;
};
