import attachCapableServices from '@api/requests/subscription/attachCapableServices';
import { TokenTypes } from '@api/services/StoreService';
import { RootState } from '@app/reducers';
import { useConfig } from '@components/ConfigProvider';
import { searchEligiblePromoCodePackages } from '@cv/portal-cps-lib';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import {
  EligiblePackagesResponse,
  SearchEligiblePromoCodePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useQuery } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import urlSearchParams, { parsePromoCodeCriteria } from '@utils/urlSearchParams';
import { useSelector } from 'react-redux';
import useVehicle from './useVehicle';
import { getPackagesMatchingCriteria } from '../../components-lib/manageSubscription/utils/packageUtils';

export type PromoCodePackagesQueryOptions = {
  onSuccess?: () => void;
};

const usePromoCodePackages = ({ onSuccess = () => undefined }: PromoCodePackagesQueryOptions = {}) => {
  const { data: vehicle } = useVehicle();

  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const vehicleId: string = vehicle?.vehicleId;
  const config = useConfig();
  const environment = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);

  const { p: promoCode = '', pc = '' } = urlSearchParams.getAll();
  const promoCodeCriteria = parsePromoCodeCriteria(pc);

  return useQuery<EligiblePackagesResponse[]>({
    enabled: Boolean(vehicleId && promoCode),
    queryKey: ['eligiblePackagesWithPromo', promoCode, promoCodeCriteria],
    queryFn: async () => {
      const request: SearchEligiblePromoCodePackagesRequest = {
        data: {
          userId,
          vehicleId,
          promoCode,
          salesChannel: SalesChannel.Subscriber,
        },
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
        },
        queryParams: {
          include: 'variantOptions',
        },
      };

      const response = await searchEligiblePromoCodePackages(request)
        .then((response) => getPackagesMatchingCriteria(response.data, promoCodeCriteria))
        .then((packages) =>
          attachCapableServices({
            packages: packages,
            capableServices: vehicle?.capableServices,
          }),
        )
        .catch((e) => {
          console.error(e);
          return [];
        });

      return response;
    },
    onSuccess,
    placeholderData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default usePromoCodePackages;
