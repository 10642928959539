import './VehicleImage.css';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';
import React from 'react';
import { STATUS } from '@components/VehicleImageContainer/constant';
import { useNavigation } from '@components/Navigation';

type VehicleImageProps = {
  image: ContentfulFile;
};

export default function VehicleImage({ image }: VehicleImageProps) {
  const { currentPath } = useNavigation();
  const location = currentPath.page;
  return (
    <span className={location === STATUS ? 'VehicleConditionItem-vehicle-logo' : 'RichTextContainer text-center'}>
      <ContentfulImage imageData={image} />
    </span>
  );
}
