import { InformationPageEnrollmentContainer } from '@manageSubscription/InformationPages';
import InformationPageSubscriptionContainerNNA from '@manageSubscription/InformationPages/NNA/InformationPageSubscriptionContainer';
import UserProfileContainerNNA from '@manageSubscription/UserProfile/NNA/UserProfileContainer';
import { ComponentRoutes } from '../../../types';
import { infoPageEnrollmentFlow } from './InformationPageEnrollment';
import { infoPageSubscriptionFlow } from './InformationPageSubscription';
import { userProfileFlowNNA } from './UserProfile';

export const NNAComponentFlowMap = {
  [ComponentRoutes.userProfile]: { component: UserProfileContainerNNA, flow: userProfileFlowNNA },
  [ComponentRoutes.infoPageEnrollment]: {
    component: InformationPageEnrollmentContainer,
    flow: infoPageEnrollmentFlow,
  },
  [ComponentRoutes.infoPageSubscription]: {
    component: InformationPageSubscriptionContainerNNA,
    flow: infoPageSubscriptionFlow,
  },
};
