import { styled } from '@mui/material';
import { Input } from '../../../components/Input';
import Button from '../../../components/Button';

export const PromoSection = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const PromoInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const WrapContent = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ApplyButton = styled(Button)`
  width: auto;
  padding: 0.5rem 1.5rem;
  text-transform: none;
`;

export const PromoCodeInput = styled(Input)`
  width: 100%;
  & .input-field {
    padding: 10px 0px 10px 10px;
    border-radius: 4px;
    margin: 1.3rem 0 0 0;
    border: 2px solid lightgray;
  }
`;
