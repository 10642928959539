import set from 'lodash/set';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  createDrivingBehavior,
  getDrivingBehavior,
  updateDrivingBehavior,
  deleteDrivingBehavior,
} from '@cv/portal-rts-lib/alerts/';
import {
  GetDrivingBehaviorRequest,
  DrivingBehaviorVehicleIdRequest,
  CreateDrivingBehaviorRequest,
  DeleteDrivingBehaviorRequest,
  CreateAlertsRequest,
  DrivingBehaviorByVehicleId,
} from '@cv/portal-rts-lib/alerts/models';
import { getBreaches, GetBreachesRequest } from '@cv/portal-rts-lib/history';
import { getPostalAddress } from '@cv/portal-rts-lib/v0/location/postal-address';
import { PostalAddressRequest } from '@cv/portal-rts-lib/v0/location/postal-address/models';

type CreateAlertsRequestProps = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
  data: CreateAlertsRequest;
};

type UpdateAlertsRequestProp = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
  data: DrivingBehaviorByVehicleId;
};

export type AlertsRequestProps = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
};

export type DeleteAlertsRequestProps = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
  data: {
    lastSvcReqId: string;
  };
};

type BreachRequestProps = {
  vehicleId: string;
  accessToken: string;
  type: string;
  startDateTime: string;
  endDateTime: string;
  alertType: string;
  idToken: string;
};

type PostalAddressProps = {
  vin: string;
  accessToken: string;
  idToken: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
};

export default class AlertsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getPostalAddress({ vin, accessToken, idToken, coordinates }: PostalAddressProps) {
    const request: PostalAddressRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
        vin,
      },
      data: coordinates,
    };

    return await getPostalAddress(request);
  }

  async getBreaches({
    vehicleId,
    accessToken,
    type,
    alertType,
    startDateTime,
    endDateTime,
    idToken,
  }: BreachRequestProps) {
    const request: GetBreachesRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      queryParams: {
        services: type,
        startDateTime,
        ...(alertType && { geofenceAlertType: alertType }),
        endDateTime,
      },
      data: {},
    };
    set(request, 'headers.Authorization', accessToken);
    return await getBreaches(request);
  }

  async getAlerts({ vehicleId, accessToken, idToken }: AlertsRequestProps) {
    const request: GetDrivingBehaviorRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await getDrivingBehavior(request);
    return { data: response.data };
  }

  async updateAlerts({ vehicleId, accessToken, idToken, data }: UpdateAlertsRequestProp) {
    const request: DrivingBehaviorVehicleIdRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await updateDrivingBehavior(request);
    return { data: response.data };
  }

  async createAlerts({ vehicleId, accessToken, idToken, data }: CreateAlertsRequestProps) {
    const request: CreateDrivingBehaviorRequest = {
      pathParams: {
        vehicleId: vehicleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await createDrivingBehavior(request);
    return { data: response.data };
  }

  async deleteAlerts({ vehicleId, accessToken, idToken, data }: DeleteAlertsRequestProps) {
    const request: DeleteDrivingBehaviorRequest = {
      pathParams: {
        vehicleId: vehicleId,
        id: data.lastSvcReqId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    set(request, 'headers.Authorization', accessToken);
    const response = await deleteDrivingBehavior(request);
    return { data: response.data };
  }
}
