import { Button } from '@components-lib';
import { useDialog } from '@components/Dialog/useDialog';
import clsx from 'clsx';
import React from 'react';
import Markdown from '@components/Markdown';

interface Props {
  minifiedText: string;
  expandedText: string;
  title: string;
  labelOk: string;
}

const ModalExpandingText = (props: Props) => {
  const { minifiedText, expandedText, title, labelOk } = props;
  const dialog = useDialog();

  return (
    <Button
      variant="text"
      onClick={() => {
        dialog({
          title,
          children: <Markdown>{expandedText}</Markdown>,
          labelOk,
        });
      }}
    >
      {minifiedText}
    </Button>
  );
};

export default ModalExpandingText;
