import { createContact, updateContactInfo, deleteContact } from '@cv/portal-idm-lib/contact';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  ManagedContactRequest,
  ManagedContact,
  UpdateContactRequest,
  DeleteContactRequest,
} from '@cv/portal-idm-lib/contact/models';
import { PatchPayload } from '@cv/portal-idm-lib/contact/models/patch';
import { ContactFilter, ContactFormData } from '../types/';
import { formatContactData, formatNewContactData, formatContactFormData, formatUpdateData } from '../formatters/';

export default class VehicleContactUpdateService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async postContact(
    data: ContactFormData,
    filter: ContactFilter,
    token: string,
    vehicleId: string,
    userId: string,
    tenantId: string,
  ) {
    const fullData: ManagedContact = formatNewContactData(data, filter);
    fullData.vehicleId = vehicleId;
    fullData.userId = userId;
    fullData.tenantId = tenantId;
    const payload: ManagedContactRequest = {
      environment: this.environment,
      data: fullData,
      headers: {
        Authorization: token,
      },
    };
    const response = await createContact(payload);
    return { data: formatContactData(response.data) };
  }

  async patchContact(data: ContactFormData, token: string) {
    const fullData: PatchPayload[] = formatUpdateData(formatContactFormData(data));
    const payload: UpdateContactRequest = {
      environment: this.environment,
      data: fullData,
      headers: {
        Authorization: token,
        'If-Match': '*',
      },
      pathParams: {
        _id: data._id,
      },
    };
    const response = await updateContactInfo(payload);
    return { data: formatContactData(response.data) };
  }

  async deleteContact(data: ContactFormData, token: string) {
    const payload: DeleteContactRequest = {
      environment: this.environment,
      headers: {
        Authorization: token,
        'If-Match': '*',
      },
      pathParams: {
        _id: data._id,
      },
    };
    const response = await deleteContact(payload);
    return { data: response.data };
  }
}
