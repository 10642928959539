import { styled } from '@mui/material';
import { Title } from '../../../styled-components/globalStyles';
import { ToggleButton } from '../PaidPackage/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  border: 2px solid ${(props) => props.theme.palette.primary.main};
  box-sizing: border-box;

  margin: 22px 0px;

  @media (min-width: 375px) {
    max-width: 335px;
  }
  @media (min-width: 1024px) {
    max-width: 600px;
    max-height: 216px;
  }
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2em;
  width: 100%;
  height: 39px;
  background: #888888;
  font-style: normal;
  font-weight: 300;
  font-size: 31px;
  line-height: 0.8;
  /* identical to box height, or 61% */

  text-align: center;

  background: ${({ theme }) => theme.palette.primary.main};

  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.primary.main)};

  @media (min-width: 1024px) {
    padding: 30px;
  }
`;

export const BundleContent = styled('div')`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PackageTitle = styled(Title)`
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.primary.main)};
  color: '#fff';
`;
export const StyledWrapper = styled('div')`
  margin: 0.5rem auto;
`;
export const StyledToggleButton = styled(ToggleButton)`
  margin: 0.5rem;
`;
