import React from 'react';

import './InfoBox.css';

type InfoItemProps = {
  label: string;
  content: string;
  columns?: '1' | '2' | '3';
};

function InfoItem({ label, content, columns = '2' }: InfoItemProps) {
  return (
    <div className="InfoItem-container" style={{ flex: `0 1 calc(100% / ${columns} - 1rem)` }}>
      <div>{label}</div>
      <div className="InfoItem-value" title={content}>
        {content}
      </div>
    </div>
  );
}

export default InfoItem;
