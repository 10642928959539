import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { es, enUS } from 'date-fns/locale';

import { formatDate } from '@utils/format-date';
import useFetchVehicleHealth from '@hooks/useFetchVehicleHealth';
import { useAnalytics } from '@cv/webframework-react-components';

import gasIcon from '@assets/gas_icon.svg';
import refreshIcon from '@assets/refresh.svg';

import styles from './Fuel.module.css';
import { RootState } from '../../reducers';

import { VehicleHealthResponse } from '@cv/portal-rts-lib/health/models';
import { UpperCaseUnits, useConvertDistance } from '@utils/metrics';

export type FuelProps = {
  data: {
    fuelLeft: number;
    lastUpdated: number;
  };
  FuelStatusPrefixLabel: string;
  FuelStatusPostfixLabel: string;
  FuelUpdatePrefixLabel: string;
  FuelUpdatePostfixRefresh: string;
  FuelStatusNotAvailableLabel: string;
  FuelRefreshAnalyticsEventName: string;
};

function Fuel(props: FuelProps) {
  const { trackEvent } = useAnalytics();
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer.locale);
  const { data: vehicleHealth } = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicleHealth);
  const { fetchVehicleHealth } = useFetchVehicleHealth();
  const convertToSpanish = locale === 'es-MX' ? { locale: es } : { locale: enUS };
  const convertDistance = useConvertDistance();

  useEffect(() => {
    fetchVehicleHealth();
  }, []);

  const renderFuelStatus = (vehicleHealth: VehicleHealthResponse) => {
    const { fuelAutonomy } = vehicleHealth;
    const { value, unit: UoM } = convertDistance(fuelAutonomy?.value, fuelAutonomy?.UoM as UpperCaseUnits);
    if (fuelAutonomy) {
      return (
        <div className={styles['FuelSection-wrapper']}>
          <span className={styles['Fuel-status-text-regular']}>{props.FuelStatusPrefixLabel}</span>
          <div className={styles['Fuel-label']}>
            {value} {UoM}
            <div className={styles['Fuel-postfix-label']}>{props.FuelStatusPostfixLabel}</div>
          </div>
        </div>
      );
    } else {
      return <div className={styles['FuelSection-wrapper']}>{props.FuelStatusNotAvailableLabel}</div>;
    }
  };

  const renderFuel = () => {
    if (vehicleHealth) {
      return (
        <div className={styles.FuelContainer}>
          <div className={styles['FuelSection']}>
            <img src={gasIcon} className={styles['GasIcon']} />
            {renderFuelStatus(vehicleHealth)}
          </div>

          <div className={styles['Separator']}></div>

          <div className={styles['FuelSection']}>
            <span className={styles['Fuel-status-text-bold']}>
              <span>{props.FuelUpdatePrefixLabel}</span>{' '}
              <span className={styles['Fuel-status-date-text']}>
                {formatDate(vehicleHealth?.lastUpdatedTime, convertToSpanish)}
              </span>
              {' | '}
              <span>{props.FuelUpdatePostfixRefresh}</span>
            </span>
            <img
              src={refreshIcon}
              alt="refresh fuel status"
              className={styles['RefreshIcon']}
              onClick={() => {
                trackEvent(props.FuelRefreshAnalyticsEventName);
                fetchVehicleHealth();
              }}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return renderFuel();
}

export default Fuel;
