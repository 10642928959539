import React, { useRef, useState } from 'react';

import { FaAngleDown } from 'react-icons/fa';
import clsx from 'clsx';
import styles from './Accordion.module.css';

type AccordionProps = {
  title: React.ReactNode;
  text?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
  onChange?: () => void;
  className?: string;
  classes?: {
    accordion?: string;
    title?: string;
    body?: string;
  };
  analyticsEventName?: string;
};

function collapseSection(element: HTMLElement) {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = '';

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
    });
  });
}

// remove "height" from the element's inline styles, so it can return to its initial value
function handleEndTransition({ target }: Event) {
  if (!target) {
    return;
  }

  (target as HTMLElement).style.height = '';
  target.removeEventListener('transitionend', handleEndTransition);
}

function expandSection(element: HTMLElement) {
  // have the element transition to the height of its inner content
  element.style.height = element.scrollHeight + 'px';

  // when the next css transition finishes (which should be the one we just triggered)
  element.addEventListener('transitionend', handleEndTransition);
}

const Accordion = ({
  title,
  text,
  onChange,
  className,
  classes,
  children,
  analyticsEventName = '',
}: AccordionProps) => {
  const fontSize = useRef<HTMLDivElement>(null);
  const [accordionState, setAccordionState] = useState<boolean>(false);

  const handleOnToggle = () => {
    setAccordionState(!accordionState);
    if (!fontSize.current) {
      return;
    }
    if (accordionState) {
      collapseSection(fontSize.current);
    } else {
      expandSection(fontSize.current);
    }
    onChange?.();
  };

  return (
    <div className={clsx(styles.accordion, className, classes?.accordion)}>
      <div
        className={clsx(styles['accordion-title'], classes?.title)}
        onClick={handleOnToggle}
        data-analyticseventname={`${analyticsEventName}${accordionState ? 'Expand' : 'Collapse'}`}
      >
        <span>{title}</span>
        <FaAngleDown
          className={clsx(styles['toggle-icon'], {
            [styles['open']]: accordionState,
            [styles['close']]: !accordionState,
          })}
        />
      </div>
      <div style={{ height: 0 }} ref={fontSize} className={clsx(styles['accordion-body'], classes?.body)}>
        <p>{text || children}</p>
      </div>
    </div>
  );
};

export default Accordion;
