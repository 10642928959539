import React from 'react';
import useToggle from '@hooks/useToggle';

import { useApi } from '@api';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import VehicleConditionContainer from './VehicleConditionContainer';
import { VehicleConditionProvider } from './VehicleConditionContext';

export type DisplayViewMode = 'default' | 'warning' | 'short';

type VehicleConditionConnectorProps = {
  name: any;
  content: ContentfulComponent;
  expandAllLabel: string;
  collapseAllLabel: any;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
  displayViewMode: DisplayViewMode;
};

function VehicleConditionConnector({
  name,
  expandAllLabel,
  collapseAllLabel,
  content,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  displayViewMode,
  ...restProps
}: VehicleConditionConnectorProps) {
  const api = useApi();
  const vehicleData = api.storeService.getVehicleData();
  const [isExpandAll, toggleExpandAll] = useToggle(false);

  return (
    <VehicleConditionProvider data={{ vehicleData, isExpandAll, toggleExpandAll, displayViewMode }}>
      <VehicleConditionContainer
        expandAllLabel={expandAllLabel}
        collapseAllLabel={collapseAllLabel}
        scheduleServiceButtonLabel={scheduleServiceButtonLabel}
        scheduleServiceButtonUrl={scheduleServiceButtonUrl}
        displayViewMode={displayViewMode}
        {...restProps}
      >
        <ContentRenderer name={name} content={content} />
      </VehicleConditionContainer>
    </VehicleConditionProvider>
  );
}

export default VehicleConditionConnector;
