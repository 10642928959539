import React from 'react';
import styles from '../services.module.css';
import { ModelConnector } from '../../ApiConnectorHOCs/';
import { labelReducer } from '@utils/filter-label-dictionary';
import useLocalDate from '@hooks/useLocalDate';
import { useApi } from '@api';

type SubscriptionPackageProps = {
  marketingName: string;
  autoRenewText: string;
  termEndDate: string;
};

type SubscriptionProps = {
  data: Array<any>;
  header: string;
  suscriptionItems: Array<DataType>;
};

type DataType = {
  componentType: string;
  description?: string;
  content: Array<any>;
  expirationDate?: string;
  name: string;
  title?: string;
};

const SubscriptionPackage = ({ marketingName, autoRenewText, termEndDate }: SubscriptionPackageProps) => {
  const formatDate = useLocalDate();
  return (
    <div key={marketingName} className={styles.SubscriptionPackage}>
      <div className={styles.SubscriptionPackageDescription}>{marketingName}</div>
      <div className={styles.SubscriptionPackageExpirationDate}>
        {autoRenewText} {formatDate(termEndDate)}
      </div>
    </div>
  );
};

const Subscription = ({ data, header, suscriptionItems }: SubscriptionProps) => {
  const subscriptionLabel = labelReducer(
    suscriptionItems?.filter((item) => item.componentType === 'portalLabelDictionary')[0],
  );
  const packages = data.services || [];

  const formatDate = useLocalDate();

  const api = useApi();
  const userVehicleInfo = api.storeService.getUserVehicleInfo();
  return (
    <div className={styles.Wrapper}>
      <div className={styles.ServiceContainer}>
        <h1 className={styles.ServiceHeader}>{header}</h1>
        <span className={styles.SubscriptionPackageTitle}>
          {userVehicleInfo}
          {packages?.length ? (
            packages.map(({ name, autoRenew, expiry }: any) => (
              <SubscriptionPackage
                key={name}
                marketingName={
                  subscriptionLabel?.packagePrefixLabel ? subscriptionLabel.packagePrefixLabel.concat(name) : name
                }
                autoRenewText={autoRenew ? subscriptionLabel?.renews : subscriptionLabel?.expires}
                termEndDate={expiry}
              />
            ))
          ) : (
            <div className={styles.SubscriptionPackageDescription}>{subscriptionLabel?.nopackagesfound}</div>
          )}
          <div className={styles.SubscriptionPackageExpirationDate}>
            {subscriptionLabel?.labelTrialExpiry}
            {formatDate(data?.defaultPkg?.expiry)}
          </div>
        </span>
      </div>
    </div>
  );
};

export default ModelConnector(Subscription);
