import { SubscribedPackageInfo } from '@manageSubscription/Types';
import { PackageToRemove } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { CancellationPolicy, CancelReason } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { containsDefaultsOnly, containsMonthlyOnly, formatToCpsTZ } from '@manageSubscription';
import { RemoveAllPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/createorder-previeworder';

export const buildPackageToRemove = (
  sPkg: SubscribedPackageInfo,
  isCancelEffectiveToday: boolean,
  reason?: CancelReason,
): PackageToRemove => {
  if (!reason) {
    reason = CancelReason.Cancelled_cancel;
  }
  return {
    subscriptionPackageId: sPkg.subscriptionPackageId,
    cancelReason: reason,
    cancellationPolicy: isCancelEffectiveToday ? CancellationPolicy.Effective_date : CancellationPolicy.End_of_term,
    cancelEffectiveDate: isCancelEffectiveToday ? formatToCpsTZ() : '',
  };
};

export const buildRemoveAllPackages = (packagesToRemove: SubscribedPackageInfo[]): RemoveAllPackage => {
  const cancelPolicy =
    containsDefaultsOnly(packagesToRemove) || !containsMonthlyOnly(packagesToRemove)
      ? CancellationPolicy.Effective_date
      : CancellationPolicy.End_of_term;
  return {
    cancelReason: CancelReason.Cancelled_default,
    cancellationPolicy: cancelPolicy,
  };
};
