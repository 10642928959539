import React from 'react';
import { Container, Content, Title } from '../../../../styled-components/globalStyles';
import { VariantInfo } from '../../../Types';
import { useFlowMachineContextContent, useFlowMachineContextSubscriptionProps } from '../../../../Router/RouterContext';
import { ContentSection } from '@manageSubscription/Packages/DiscountPackage/styles';
import TrialPackageTerm from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialPackageTerm';

interface PackageTrialProps {
  marketingName: string;
  shortDescription: string;
  variant: VariantInfo;
}

export const PackageTrial = ({ marketingName, shortDescription, variant }: PackageTrialProps) => {
  const { startDate, endDate, requiresAutoRenew, termServiceEndDate } = variant;

  const {
    packagesWebContent: {
      packagePrefixLabel,
      subscribedPackagesWebContent: { expiresLabel, renewLabel },
    },
  } = useFlowMachineContextContent();
  const { expireRenewText } = useFlowMachineContextSubscriptionProps();

  const showMarketingName = () => {
    return packagePrefixLabel ? `${packagePrefixLabel} ${marketingName}` : marketingName;
  };

  return (
    <Container>
      <Title>{showMarketingName()}</Title>
      <Content>
        <TrialPackageTerm startDate={startDate} endDate={endDate} isAddSubscriptionLabel={true} />
      </Content>
      <Content>
        {expireRenewText({
          autoRenew: requiresAutoRenew,
          termEndDate: endDate,
          termServiceEndDate,
          renewLabel,
          expiresLabel,
        })}
      </Content>
      <ContentSection>{shortDescription}</ContentSection>
    </Container>
  );
};
