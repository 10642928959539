import React from 'react';
import { Content } from '../../styled-components/globalStyles';
import Checkbox from '../../components/Checkbox';
import { LegalSection } from '../Order/styles';
import useSetLink from './useSetLink';
import { SubscriptionProps } from '../Subscription';

interface LegalSectionProps {
  legalText: string;
  termsAgreed?: boolean;
  setTermsAgreed?: any;
  showCheckbox?: boolean;
  analyticsEventName?: string;
  subscriptionProps: SubscriptionProps;
}

export const Legal = ({
  legalText,
  showCheckbox = true,
  termsAgreed,
  setTermsAgreed,
  subscriptionProps,
}: LegalSectionProps) => {
  const text = useSetLink(legalText, subscriptionProps);

  return (
    <LegalSection>
      {showCheckbox && (
        <Checkbox
          data-testid="agreement-checkbox"
          checked={termsAgreed}
          onChange={() => setTermsAgreed(!termsAgreed)}
        />
      )}
      <Content data-testid="agreement-content" dangerouslySetInnerHTML={{ __html: text }}></Content>
    </LegalSection>
  );
};
