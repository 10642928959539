import React from 'react';
import { ModelConnector } from '@components/ApiConnectorHOCs/';
import Carousel, { CarouselProps } from './Carousel';
import FlowCarousel from './FlowCarousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

type CarouselConnectorProps = CarouselProps & {
  apiConnector: {
    api: string;
    errorLabel?: string;
  };
};

function CarouselConnectorContent(props: CarouselConnectorProps) {
  if (props.apiConnector) {
    const CarouselWithData = ModelConnector(Carousel);
    return <CarouselWithData {...props} />;
  }
  return <Carousel {...props} />;
}

const CarouselConnector = ({ flows, ...otherProps }: CarouselConnectorProps) => (
  <FlowCarousel carouselFlows={flows}>
    <CarouselConnectorContent {...otherProps} />
  </FlowCarousel>
);

export default CarouselConnector;
