// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  font-family: var(--theme-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
}

a {
  color: var(--theme-color-link);
  transition: color 0.5s ease;
}

a:hover {
  color: var(--theme-color-link-hover);
}
button a:hover {
  color: var(--theme-color-white);
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,qCAAqC;EACrC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;IACE,kCAAkC;EACpC;AACF;;AAEA;;;;;;;EAOE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT","sourcesContent":["*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\nbody {\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  min-height: 100vh;\n  font-family: var(--theme-font-family);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@supports (-webkit-touch-callout: none) {\n  body {\n    min-height: -webkit-fill-available;\n  }\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np {\n  margin: 0;\n  font-weight: normal;\n}\n\na {\n  color: var(--theme-color-link);\n  transition: color 0.5s ease;\n}\n\na:hover {\n  color: var(--theme-color-link-hover);\n}\nbutton a:hover {\n  color: var(--theme-color-white);\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
