import ContentRenderer from '@components/ContentRenderer';
import React, { useEffect, useState } from 'react';
import { usePortalTargetContext } from './PortalTargetContext';
import ContentfulComponent from '@customTypes/ContentfulComponent';

export type PortalTargetComponentProps = {
  defaultView: ContentfulComponent;
  views: ContentfulComponent[];
  [key: string]: unknown;
};
interface AnalyticsApi {
  event: string;
  pageName: string;
  eVar1: string;
  t: () => void;
}

declare const sendToAnalytics: AnalyticsApi;

const PortalTargetComponent: React.FC<PortalTargetComponentProps> = ({ defaultView, views, ...rest }) => {
  const target = usePortalTargetContext();
  const [currentView, setView] = useState<ContentfulComponent>(defaultView);
  useEffect(() => {
    target.getOffer((offers) => {
      for (const offer of offers) {
        const content = offer.content.map(({ contentful_name }) => contentful_name);
        const view = views.find(({ name }) => content.includes(name));
        if (view) {
          setView(view);
          sendToAnalytics.pageName = String(view.name);
          sendToAnalytics.t();
          break;
        }
        sendToAnalytics.t();
      }
    });
  }, [views, target]);
  return (
    <ContentRenderer
      onClick={() => {
        target.trackClick({ contentful_name: currentView.name });
      }}
      content={[currentView]}
      {...defaultView}
      {...rest}
      name={currentView.name}
    />
  );
};

export default PortalTargetComponent;
