import React from 'react';
import { packagesToCancel } from '../utils';
import { useFlowMachineContextFlowStorage, useFlowMachineContextSubscriptionProps } from '../../Router/RouterContext';
import { ReviewOrderContainerCommon } from './ReviewOrderContainerCommon';
import { AnyPackage } from '../Packages/SubscribedPackage/SubscribedPackages';

export const ReviewDowngradeOrderContainer = () => {
  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  const { pkgIdsToRemove } = useFlowMachineContextSubscriptionProps();

  const getFilterPackageFunction = () => {
    if (pkgIdsToRemove?.length > 0) {
      return (pkg: AnyPackage) => pkg;
    }
    return (pkg: AnyPackage) => pkg.active || pkg.amountWithoutTax < 0;
  };

  return (
    <ReviewOrderContainerCommon
      packageToRemoveBuilder={packagesToCancel}
      packagesToSubscribe={subscribedPackages}
      filterPackages={getFilterPackageFunction}
    />
  );
};
