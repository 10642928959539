import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';

const Circle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: theme.palette.primary.main,
  backgroundColor: 'white',
  width: theme.spacing(6),
  height: theme.spacing(6),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: 9999,
}));

const ScrollToTopButton = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleScroll = () => {
      const showButton = window.scrollY > 400;
      setIsVisible(showButton);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return isVisible && isSmallScreen ? (
    <IconButton
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '56px',
        left: '4px',
        display: 'flex',
      }}
    >
      <Circle>
        <KeyboardArrowUpIcon fontSize="large" />
      </Circle>
    </IconButton>
  ) : null;
};

export default ScrollToTopButton;
