import { useMemo } from 'react';
import { mapValues, compact } from 'lodash';
import config from '@config/config';
import {
  PortalFilter,
  globalFilterRules,
  portalFilterRules,
  FrontendFilterProps,
  ContentfulObject,
  useFilterRulesProps,
  FilterableObject,
} from '@components/ContentRenderer/filterRules';
import usePromoCodePackages from '@api/queries/usePromoCodePackages';

type FilterObject = FilterableObject | string | undefined;

const enum ComponentType {
  PortalFilter = 'portalFilter',
}

const filterObject = (currentObject: FilterableObject, props: FrontendFilterProps): FilterableObject | null => {
  const shouldBeFilteredByTagsOrEnv = globalFilterRules.some((filter) => filter(currentObject, props));
  if (shouldBeFilteredByTagsOrEnv) return null;

  const filterObject = currentObject as PortalFilter;
  //checking if at least one filterRule among all filter rules returns true
  const shouldBeFiltered = (filterObject?.filterRules || []).some((filterRule) =>
    portalFilterRules.some((filter) => filter(filterRule, props)),
  );
  if (shouldBeFiltered) return null;

  return currentObject;
};

const processFilterContentAndFallback = (
  currentObject: FilterableObject,
): FilterableObject | FilterableObject[] | undefined => {
  if (currentObject?.componentType === ComponentType.PortalFilter) {
    const filterObject = currentObject as PortalFilter;
    const hasContent =
      filterObject.content.length && filterObject.content.some((c) => processFilterContentAndFallback(c));
    if (hasContent) {
      return filterObject.content;
    } else {
      return filterObject.fallbackValue;
    }
  }
  return currentObject;
};

const processContent = (
  obj: FilterObject,
  props: FrontendFilterProps,
  processor: (currentObject: FilterableObject, props: FrontendFilterProps) => FilterableObject | FilterableObject[],
): FilterObject | FilterObject[] => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return compact(obj.flatMap((o: FilterObject) => processContent(o, props, processor)));
  }

  const processedObject = processor(obj, props);

  if (!processedObject) {
    return null;
  }

  if (Array.isArray(processedObject)) {
    return compact(processedObject.flatMap((o: FilterObject) => processContent(o, props, processor)));
  }

  return mapValues(processedObject, (value: FilterObject) => processContent(value, props, processor)) as FilterObject;
};

const filterContent = (obj: FilterObject, props: FrontendFilterProps) => {
  const filteredContent = (processContent(obj, props, filterObject) || {}) as FilterObject;
  const processedContent = processContent(filteredContent, props, processFilterContentAndFallback);
  return processedContent || {};
};

// Useless hook to hide all needed information for filter content
// to keep all mess in one place

// WARNING!
// This hooks is used in ContentfulTagFilter which is the one of the TOP level
// components. Any change to state/redux here, trigger whole app to re-render.
// Please check that you are using data that is not changing link(in memory) all the time
// when you checking it (for example preferences from Redux they change based on this filter
// as preferences is filtered content).
export const useFilterContent = (pageContent: ContentfulObject) => {
  const filterRulesProps = useFilterRulesProps();
  const { data: promoCodePackages } = usePromoCodePackages();

  return useMemo(() => {
    const {
      telematicsProgramId,
      carMake,
      capableProducts,
      capableServices,
      vehicleType,
      eligiblePackages,
      campaignCode,
      eligibleSubscriberPromoPackages,
    } = filterRulesProps;
    const isContentLoaded = Boolean(Object.keys(pageContent).length);
    if (!isContentLoaded) {
      // TODO: Display loading indicator. We can't use LoaderBackdrop here because it relies on a theme
      return null;
    }

    return filterContent(pageContent, {
      eligiblePackages,
      promoCodePackages,
      vehicleType,
      telematicsProgramId,
      carMake,
      capableProducts,
      capableServices,
      campaignCode,
      oemName: config.getTenantId(),
      envName: config.getEnvironmentEnum(),
      eligibleSubscriberPromoPackages,
    });
  }, [pageContent, filterRulesProps]);
};
