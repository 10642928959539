import React, { useState } from 'react';
import { ApplyButton, PromoCodeInput, PromoInfo, PromoSection, WrapContent } from './styles';
import Image from '../../../components/Image';
import { DiscountInfo } from '../../Types';
import { HSpace, Link, Title } from '../../../styled-components/globalStyles';
import { ComponentRoutes } from '../../../Router/types';
import { useForm } from 'react-hook-form';
import { ContentfulAssets, WebContent } from '../..';

interface PromoCodeProps {
  discountInfo: DiscountInfo;
  assets: ContentfulAssets;
  applyPromoCode?: () => void;
  getPromoCodeError: () => string;
  location: string;
  content: WebContent;
  addDiscountInfo: (discountInfo: DiscountInfo) => void;
}

const PromoCode = ({
  discountInfo,
  applyPromoCode,
  getPromoCodeError,
  assets,
  addDiscountInfo,
  location,
  content,
}: PromoCodeProps) => {
  const {
    promoPackagesWebContent: {
      discount: { promoCodeLabel, promoCodeErrorLabel },
    },
    commonWebContent: { applyButtonLabel, editLinkLabel },
  } = content;
  const [isEditPromoCode, setEditPromoCode] = useState<boolean>(!discountInfo?.promoCode);
  const [givenPromoCode, setGivenPromoCode] = useState<string>(discountInfo?.promoCode);
  const isError = Boolean(getPromoCodeError());
  const errorMessage = promoCodeErrorLabel || getPromoCodeError();

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  });

  const addPromoCode = handleSubmit(({ promoCode }) => {
    addDiscountInfo({ promoCode } as DiscountInfo);
    applyPromoCode();
    if (!isError) {
      toggleEditPromoCode();
    }
  });

  const toggleEditPromoCode = () => {
    setEditPromoCode(!isEditPromoCode);
  };

  return (
    <PromoSection>
      {isEditPromoCode || isError ? (
        <form onSubmit={addPromoCode}>
          <PromoInfo>
            <PromoCodeInput
              id="promoCode"
              label={promoCodeLabel}
              defaultValue={givenPromoCode}
              register={register('promoCode', {
                onChange: (e) => {
                  setGivenPromoCode(e.target.value);
                },
              })}
              errorMsg={isError && errorMessage}
            />
            <ApplyButton disabled={!givenPromoCode} type="submit">
              {applyButtonLabel}
            </ApplyButton>
          </PromoInfo>
        </form>
      ) : (
        <PromoSection>
          <Title>{promoCodeLabel}</Title>
          <WrapContent>
            <PromoInfo>
              <Image assets={assets} name="CheckCircle" size="18px" />
              <HSpace />
              {givenPromoCode}
            </PromoInfo>
            {location === ComponentRoutes.payment && <Link onClick={toggleEditPromoCode}>{editLinkLabel}</Link>}
          </WrapContent>
        </PromoSection>
      )}
    </PromoSection>
  );
};

export default PromoCode;
