import React from 'react';
import { useAnalytics, useAnalyticsClickHandler } from '@cv/webframework-react-components';

type AnalyticsHandlerWrapperProps = {
  children: React.ReactNode;
};

export default function AnalyticsHandlerWrapper({ children }: AnalyticsHandlerWrapperProps): JSX.Element {
  const { trackEvent } = useAnalytics();

  useAnalyticsClickHandler(trackEvent);

  return <>{children}</>;
}
