import React from 'react';
import { WebContent } from '@manageSubscription';
import { CardMethodDetails, CombinedPaymentMethods, PaymentMethodDetails } from '@lib-payment/Types';
import Button from '@lib-components/Button';
import { UpdatePayment } from '../paymentMethods';

import SavedPaymentMethods from './components/SavedPaymentMethods';
import PaymentMethods from './components/PaymentMethods';
import { Grid } from '@lib-components/Grid';

export interface ManagePaymentProps {
  managePage?: boolean;
  children?: React.ReactNode;
  hidePaymentButton?: boolean;
}

interface ManagePaymentLocalPops extends ManagePaymentProps {
  content: WebContent;
  setDefault: (paymentMethodId: string) => void;
  paymentMethods: CombinedPaymentMethods;
  defaultPaymentId: string;
  paymentToEdit: CardMethodDetails;
  setPaymentToEdit: (arg: CardMethodDetails) => void;
  createNewPayment: boolean;
  setCreateNewPayment: (isOpen?: boolean) => void;
  cancelEditing: () => void;
  removePayment: (arg: PaymentMethodDetails) => void;
  isFirstPaymentMethod: boolean;
}

const ManagePayment: React.FC<ManagePaymentLocalPops> = ({
  content,
  setDefault,
  paymentMethods,
  defaultPaymentId,
  paymentToEdit,
  setPaymentToEdit,
  createNewPayment,
  setCreateNewPayment,
  cancelEditing,
  removePayment,
  children,
  isFirstPaymentMethod,
}) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <SavedPaymentMethods
          paymentMethods={paymentMethods}
          defaultPaymentId={defaultPaymentId}
          setDefault={setDefault}
          setPaymentToEdit={setPaymentToEdit}
          removePayment={removePayment}
        />
      </Grid>
      {!isFirstPaymentMethod && !createNewPayment && (
        <Grid item alignSelf="center">
          <Button variant="outlined" color="secondary" onClick={() => setCreateNewPayment(true)}>
            {content.addPaymentMethod}
          </Button>
        </Grid>
      )}
      {children && <Grid item>{children}</Grid>}
      {(paymentToEdit || createNewPayment) && (
        <Grid item>
          {paymentToEdit && (
            <UpdatePayment paymentInfo={paymentToEdit} cancelEdit={cancelEditing} onDelete={removePayment} />
          )}
          {createNewPayment && <PaymentMethods cancelEditing={cancelEditing} />}
        </Grid>
      )}
    </Grid>
  );
};

export default ManagePayment;
