import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { send, assign } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig, InfoPageSubscriptionFlowContext } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const infoPageSubscriptionFlow: ComponentFlowStateConfig<InfoPageSubscriptionFlowContext> = () => ({
  initial: 'idle',
  id: ComponentRoutes.infoPageSubscription,
  states: {
    idle: {
      entry: [
        'getSubscriptionTerms',
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.infoPageSubscription },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { actions: 'navigateForward' },
      },
    },
  },
});

export const actions: ComponentActions<InfoPageSubscriptionFlowContext> = {
  getSubscriptionTerms: assign({
    subscriptionTerms: (context) => {
      const {
        content: {
          commonWebContent: { monthsLabel, daysLabel },
          trialPackagesWebContent: { trialLabel },
        },
      } = context;
      const { subscribedPackages } = context.flowSessionStorage;
      if (!subscribedPackages?.[0]?.variant) {
        return trialLabel;
      }
      const { initialTerm, initialTermUnit } = subscribedPackages[0].variant;
      const termUnitLabel = initialTermUnit === TermUnit.Days ? daysLabel : monthsLabel;
      return `${initialTerm}-${termUnitLabel}`;
    },
  }),
};
