import config from '@config/config';
import { gapiLoaded, gisLoaded } from '@api/services/google';
import useScript from '@hooks/useScript';

const useLoadGoogle = (outerSetters) => {
  const gapiUrl = config.get('GOOGLE_API_URL') || '';
  const gisUrl = config.get('GOOGLE_INDENTITY_SERVICES_URL') || '';

  useScript(gapiUrl, gapiLoaded);
  useScript(gisUrl, () => gisLoaded(outerSetters));
};

export default useLoadGoogle;
