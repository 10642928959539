import { SubscriptionProps } from '@manageSubscription';
import { options } from '../commonService';
import { getTelematicsServicesByVin, getVehicleById, getVehicleServicesByVehicleId } from '@cv/portal-cps-lib';
import {
  SearchTelematicServiceRequest,
  SearchVehicleServiceRequest,
} from '@cv/portal-cps-lib/vehicle/service-management/models';
import { VehicleRequest } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { Environment } from '@cv/portal-common-lib/ajax/constants';

type VehicleDetailsArgs = {
  accessToken: string;
  locale: string;
};

type GetVehicleDetailsArgs = VehicleDetailsArgs & {
  vehicleId: string;
  include?: string;
  tenantId: string;
  config: {
    getEnvironmentEnum: () => Environment;
  };
};

export const getVehicleServices = (props: SubscriptionProps) => {
  const {
    vehicleDetails: { vehicleId },
  } = props;

  const request: SearchVehicleServiceRequest = {
    ...options(props),
    data: { vehicleId },
  };

  return getVehicleServicesByVehicleId(request).then((res) => res.data);
};

export const getVehicleDetails = ({ vehicleId, accessToken, locale, tenantId, config }: GetVehicleDetailsArgs) => {
  const payload: VehicleRequest = {
    data: {
      id: vehicleId,
    },
    environment: config.getEnvironmentEnum(),
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };

  return getVehicleById(payload).then((res) => res.data);
};

export const getVehicleTelematicsServicesByVin = (props: SubscriptionProps) => {
  const {
    vehicleDetails: { vin, vehicleId },
  } = props;

  if (!vehicleId && !vin) {
    console.error('Exactly one vin or vehicleId should be specified, fetching telematics services would be skipped');
    return Promise.reject('Exactly one vin or vehicleId should be specified');
  }

  const request: SearchTelematicServiceRequest = {
    ...options(props),
    data: vin ? { vin } : { vehicleId },
  };

  return getTelematicsServicesByVin(request).then((response) => response.data);
};
