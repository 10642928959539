import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '@redux/actions/loading';
import { useApi } from '@api';
import { setVehicleHealthReports } from '@redux/actions/vehicle';

const useFetchVehicleHealthReports = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const fetchVehicleHealthReports = async ({ allowBackgroundUpdate = false } = {}) => {
    // Update health data in background if we already have it fetched
    if (!allowBackgroundUpdate) {
      dispatch(setLoadingStatus(true));
    }

    try {
      const { data } = await api.getVehicleHealthReports();
      dispatch(setVehicleHealthReports(data.healthReports));
      return data;
    } catch (e) {
      const { code } = e.data;
      if (code !== 'TP4041') {
        throw e;
      }
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  return { fetchVehicleHealthReports };
};

export default useFetchVehicleHealthReports;
