import React from 'react';
import { Container, Heading, Link, Title } from '../../styled-components/globalStyles';
import { UserProfileWrapper } from './userProfileStyles';
import Button from '../../components/Button';
import FormikField from '../../components/FormikField';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { CommonWebContent, UserProfileWebContent } from '..';

export type CheckIfEmailIsOnFileSchema = {
  email: Yup.StringSchema;
};

export interface CheckEmailOnFileProps {
  handleSubmit: () => void;
  yupShape: CheckIfEmailIsOnFileSchema;
  userProfileWebContent: UserProfileWebContent;
  commonWebContent: CommonWebContent;
  navigateBack?: () => void;
}

const CheckIfEmailIsOnFile: React.FC<CheckEmailOnFileProps> = ({
  handleSubmit,
  yupShape,
  userProfileWebContent: { userProfileHeader, userProfileTitle, emailInputLabel },
  commonWebContent: { saveAndContinueButtonLabel, backButtonLabel },
  navigateBack,
}) => {
  return (
    <Container>
      <Heading>{userProfileHeader}</Heading>
      <Title>{userProfileTitle}</Title>
      <UserProfileWrapper>
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape(yupShape)}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(props: FormikProps<{}>) => (
            <Form>
              <FormikField name="email" data-testid="email" label={emailInputLabel} />
              <Button
                sx={{ mt: 2 }}
                fullWidth
                data-testid="submit-button"
                disabled={!(props.isValid && !props.isSubmitting)}
                onClick={props.submitForm}
                type="submit"
                data-analyticseventname="CompleteProfile::SaveAndContinue::Button-Clicked"
              >
                {saveAndContinueButtonLabel}
              </Button>
              {navigateBack && (
                <Link
                  data-testid="navigate-back-link"
                  onClick={navigateBack}
                  data-analyticseventname="CompleteProfile::Back::Link-Clicked"
                >
                  {backButtonLabel}
                </Link>
              )}
            </Form>
          )}
        </Formik>
      </UserProfileWrapper>
    </Container>
  );
};

export default CheckIfEmailIsOnFile;
