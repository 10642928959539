import React from 'react';

import Loader from './Loader';
import './LoaderBackdrop.css';

export function LoaderBackdrop({ testId }: { testId?: string }) {
  return (
    <div className="Loader-backdrop" id={testId}>
      <Loader />
    </div>
  );
}
