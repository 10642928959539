import { Calendar } from '@cv/portal-rts-lib/v0/information/calendar/models';
import UserCalendar from '@customTypes/UserCalendar';

export type UserCalendarToRtsCalendar = (calendar: UserCalendar) => Calendar;
export type RtsCalendarToUserCalendar = (calendar: Calendar) => UserCalendar;

export const convertToSend: UserCalendarToRtsCalendar = ({ userToken, calendarName, calendarId }) => ({
  'user-token': userToken,
  'calendar-name': calendarName,
  'calendar-id': calendarId,
});

export const convertToRead: RtsCalendarToUserCalendar = (calendar) => ({
  userToken: calendar['user-token'],
  calendarName: calendar['calendar-name'],
  calendarId: calendar['calendar-id'],
});
