import { InitialState, InitialStateType } from '@manageSubscription/Types';
import {
  discountReducer,
  eligiblePackageReducer,
  packageSubscriptionReducer,
  subscribedPackageReducer,
} from './packageReducer';
import { paymentReducer } from './paymentReducer';
import { clientIPReducer } from './commonReducers';
import { Actions, ActionTypes } from './actionTypes';
import { navigationReducer } from './navigationReducer';
import { SESSION_STORAGE_KEY } from '@manageSubscription';

const combineReducers = (
  {
    subscribedPackages,
    eligiblePackages,
    packageSubscriptions,
    discountInfo,
    paymentInfo,
    clientIP,
    lastVisitedPage,
  }: InitialStateType,
  action: Actions,
) => ({
  subscribedPackages: subscribedPackageReducer(subscribedPackages, action),
  eligiblePackages: eligiblePackageReducer(eligiblePackages, action),
  packageSubscriptions: packageSubscriptionReducer(packageSubscriptions, action),
  discountInfo: discountReducer(discountInfo, action),
  paymentInfo: paymentReducer(paymentInfo, action),
  clientIP: clientIPReducer(clientIP, action),
  lastVisitedPage: navigationReducer(lastVisitedPage, action),
});

const rootReducer = (state: InitialStateType, action: Actions) => {
  if (action.type === ActionTypes.ResetState) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW);
    return {
      ...InitialState,
      clientIP: state.clientIP,
    };
  }
  return combineReducers(state, action);
};

export default rootReducer;
