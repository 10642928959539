// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rRHzZC3Xlu85GoEDueQ5 {
  --color-expiry: var(--theme-color-grey-8a);

  border-bottom: 2px solid var(--theme-color-grey-c1);
  padding-bottom: 1em;
  text-align: center;
}

.R6pe_ayLr1QJsEqwOV4b {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.moaSygoumWC0DHoRiGTt {
  font-size: 1.1rem;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.SZgaRhuLE9HK2daWL9XY {
  margin-bottom: 0.5em;
}

.OH8v63pn0axIgRIfVuy4 {
  font-size: 0.75rem;
}

.Yop3gvu8aJxJR483PMPG {
  color: var(--color-expiry);
  font-weight: bold;
}

.qlrS4yiN27HMdIwk4vEd {
  color: var(--theme-color-grey-8a);
  font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubscriptionOverview/SubscriptionOverview.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;;EAE1C,mDAAmD;EACnD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;AACrB","sourcesContent":[".SubscriptionOverview {\n  --color-expiry: var(--theme-color-grey-8a);\n\n  border-bottom: 2px solid var(--theme-color-grey-c1);\n  padding-bottom: 1em;\n  text-align: center;\n}\n\n.SubscriptionOverview-vehicle-name {\n  font-size: 1.1rem;\n  font-weight: bold;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n.SubscriptionOverview-vin {\n  font-size: 1.1rem;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n.SubscriptionOverview-service {\n  margin-bottom: 0.5em;\n}\n\n.SubscriptionOverview-service-name {\n  font-size: 0.75rem;\n}\n\n.SubscriptionOverview-service-expiry {\n  color: var(--color-expiry);\n  font-weight: bold;\n}\n\n.grey-text {\n  color: var(--theme-color-grey-8a);\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubscriptionOverview": `rRHzZC3Xlu85GoEDueQ5`,
	"SubscriptionOverview-vehicle-name": `R6pe_ayLr1QJsEqwOV4b`,
	"SubscriptionOverview-vin": `moaSygoumWC0DHoRiGTt`,
	"SubscriptionOverview-service": `SZgaRhuLE9HK2daWL9XY`,
	"SubscriptionOverview-service-name": `OH8v63pn0axIgRIfVuy4`,
	"SubscriptionOverview-service-expiry": `Yop3gvu8aJxJR483PMPG`,
	"grey-text": `qlrS4yiN27HMdIwk4vEd`
};
export default ___CSS_LOADER_EXPORT___;
