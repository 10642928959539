import { ComponentRoutes, FlowActions } from '../../types';
import { CompoundState } from '../componentFlow/Types';
import { Middleware } from '../../flowMiddleware';

export default (middleware: Middleware) =>
  ({
    initial: ComponentRoutes.userProfile,
    states: {
      [ComponentRoutes.userProfile]: {
        ...middleware.getComponentFlow(ComponentRoutes.userProfile),
        on: {
          [FlowActions.proceed]: ComponentRoutes.infoPageEnrollment,
        },
      },
      [ComponentRoutes.infoPageEnrollment]: {
        ...middleware.getComponentFlow(ComponentRoutes.infoPageEnrollment),
        on: {
          [FlowActions.proceed]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.manageTrialPackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.manageTrialPackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.termsAndConditions,
          [FlowActions.skip]: ComponentRoutes.termsAndConditions,
        },
      },
      [ComponentRoutes.termsAndConditions]: {
        ...middleware.getComponentFlow(ComponentRoutes.termsAndConditions),
        on: {
          [FlowActions.proceed]: ComponentRoutes.managePromoPackages,
          [FlowActions.cancel]: ComponentRoutes.userProfile,
        },
      },
      [ComponentRoutes.managePromoPackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.managePromoPackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.payment,
          [FlowActions.skip]: ComponentRoutes.managePaidPackages,
          [FlowActions.cancel]: ComponentRoutes.managePaidPackages,
          [FlowActions.return]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.managePaidPackages]: {
        ...middleware.getComponentFlow(ComponentRoutes.managePaidPackages),
        on: {
          [FlowActions.proceed]: ComponentRoutes.payment,
          [FlowActions.return]: ComponentRoutes.manageTrialPackages,
        },
      },
      [ComponentRoutes.payment]: {
        ...middleware.getComponentFlow(ComponentRoutes.payment),
        on: {
          [FlowActions.proceed]: ComponentRoutes.reviewOrder,
          [FlowActions.return]: ComponentRoutes.managePromoPackages,
        },
      },
      [ComponentRoutes.reviewOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.reviewOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.confirmOrder,
          [FlowActions.return]: ComponentRoutes.payment,
        },
      },
      [ComponentRoutes.confirmOrder]: {
        ...middleware.getComponentFlow(ComponentRoutes.confirmOrder),
        on: {
          [FlowActions.proceed]: ComponentRoutes.userProfile,
        },
      },
    },
  } as CompoundState);
