import set from 'lodash/set';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { UserUpdateProfile } from '@cv/portal-idm-lib/user';
import { UserUpdateRequest, UserUpdatePayload } from '@cv/portal-idm-lib/user/user-update-profile/models';
import { AccountFormData } from '../types/';
import { formatUserData, formatUserFormData, formatUpdateData } from '../formatters/';

export default class AccountUpdateService {
  private payload: Omit<UserUpdateRequest, 'data'>;
  constructor(environment = Environment.LOCAL) {
    this.payload = {
      environment: environment,
      headers: {
        Authorization: 'token',
        'If-Match': '*',
      },
    };
  }

  async patchAccount(data: AccountFormData, token) {
    const cleanData = formatUserFormData(data);
    const fullData: UserUpdatePayload[] = formatUpdateData(cleanData);
    set(this.payload, 'headers.Authorization', token);
    set(this.payload, 'data', fullData);
    set(this.payload, 'pathParams._id', data._id);
    return UserUpdateProfile(this.payload as UserUpdateRequest).then((response) => ({
      data: formatUserData(response.data),
    }));
  }
}
