import { useCallback } from 'react';
import useLocalDate from './useLocalDate';

export type Options = {
  autoRenew: boolean;
  termEndDate: string;
  termServiceEndDate: string;
  renewLabel: string;
  expiresLabel: string;
};

const useExpireRenewText = () => {
  const formatDate = useLocalDate();
  return useCallback(
    ({ autoRenew, termEndDate, termServiceEndDate, renewLabel, expiresLabel }: Options): string => {
      const label = autoRenew ? renewLabel : expiresLabel;
      const termDate = autoRenew ? termEndDate : termServiceEndDate;

      const date = formatDate(termDate);

      return `${label} ${date}`;
    },
    [formatDate],
  );
};

export default useExpireRenewText;
