import React, { useContext, useState, useEffect } from 'react';
import loadTarget from './loadTarget';

export type TargetTestContent = {
  contentful_name: string;
};

type OffersParams = {
  action: string;
  content: TargetTestContent[];
}[];

type ExperienceCloudParams = {
  analytics: {
    logging: string;
  };
};
interface GetOfferProps {
  mbox: string;
  params?: unknown;
  success: (offer: OffersParams) => void;
  error: (status: string, error: string) => void;
  timeout?: number;
  experienceCloud?: ExperienceCloudParams;
}

interface ApplyOfferProps {
  mbox: string;
  offer: unknown;
}

interface TrackEventProps {
  mbox: string;
  event: string;
  params?: unknown;
  success?: (offer: OffersParams) => void;
  error: (status: string, error: string) => void;
}

interface AdobeApi {
  target: {
    getOffer: (props: GetOfferProps) => void;
    applyOffer: (props: ApplyOfferProps) => void;
    trackEvent: (props: TrackEventProps) => void;
  };
}

declare const adobe: AdobeApi;

type GetOfferSuccessCallback = (offer: OffersParams) => void;
type TrackClickParams = {
  [p: string]: string;
};

export interface TargetContext {
  tests: TargetTestContent[];
  getOffer: (successCallback: GetOfferSuccessCallback) => void;
  trackClick: (params: TrackClickParams) => void;
}

const PortalTargetContext = React.createContext<TargetContext>({
  tests: [],
  getOffer: () => undefined,
  trackClick: () => undefined,
});

const mbox = 'target-global-mbox';

const experienceCloud: ExperienceCloudParams = {
  analytics: {
    logging: 'client_side',
  },
};

export const PortalTargetProvider: React.FC = ({ children }) => {
  const [tests, setTests] = useState<TargetTestContent[] | null>(null);

  const trackClick = (params: TrackClickParams) => {
    adobe?.target?.trackEvent?.({
      event: 'click',
      mbox,
      error: function (status, error) {
        console.error('Error', status, error);
      },
      params,
    });
  };

  useEffect(() => {
    loadTarget();
  }, []);

  useEffect(() => {
    if (tests === null) {
      getOffer((offers = []) =>
        setTests(offers.reduce<TargetTestContent[]>((allContent, offer) => [...allContent, ...offer.content], [])),
      );
    }
  }, [tests, setTests]);

  return (
    <PortalTargetContext.Provider value={{ getOffer, trackClick, tests: tests || [] }}>
      {children}
    </PortalTargetContext.Provider>
  );
};

function getOffer(successCallback: GetOfferSuccessCallback) {
  adobe?.target?.getOffer?.({
    experienceCloud,
    mbox,
    success: function (offer) {
      successCallback(offer);
      adobe.target.applyOffer({
        mbox,
        offer: offer,
      });
    },
    error: function (status, error) {
      console.error('Error getOffer', status, error);
    },
  });
}

export const usePortalTargetContext: () => TargetContext = () => {
  const targetContext = useContext(PortalTargetContext);
  return targetContext;
};
