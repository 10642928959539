import { ManageSubscriptionProps } from '@manageSubscription/Subscription/Subscription';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { ValueOf } from '../typesUtils';
import * as oemConfigs from './oems';

/**
 * This is the place where we will save all configs related to portal-components-lib
 * These configs are the one that are global to all portal code and can contains
 * OEM specific code
 *
 * Most likely it should be overridden in future to have all this values from PORTAL mostly
 * to make this lib - OEM agnostic
 */

type OemConfigs = typeof oemConfigs;

type Configs = ValueOf<OemConfigs> & { tenant: TenantIds };

let configs = {} as Configs;

const initialize = function ({ subscriptionProps, content }: ManageSubscriptionProps) {
  const { tenantId } = subscriptionProps;

  configs = oemConfigs[tenantId] || oemConfigs.common;
  configs.tenant = tenantId;
};

export { configs, initialize };
