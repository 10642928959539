import React from 'react';
import { useFlowMachine, useFlowMachineContext } from '../../Router/RouterContext';
import isEmpty from 'lodash/isEmpty';
import { FcaUser, SxmUser } from '../Types';
import * as Yup from 'yup';
import { useSelector } from '@xstate/react';
import { FormFieldDropdown } from '@manageSubscription';
import { FormValues } from '../../Router/flows/componentFlow/Types';
import UserProfile, { UserProfileYupShapeType } from './UserProfile';
import { validatePhoneNumber } from '@manageSubscription/utils';

export type CommonUserProfileContainerProps = {
  requirePhone?: boolean;
};

const UserProfileContainer = ({ requirePhone }: CommonUserProfileContainerProps) => {
  const flowMachine = useFlowMachine();
  const searchUserData = useSelector(flowMachine, (state) => state.context.searchUserData);

  const {
    subscriptionProps,
    content: { commonWebContent, userProfileWebContent, formFields, addressFormWebContent },
  } = useFlowMachineContext();
  const {
    firstNameValidationError,
    lastNameValidationError,
    phoneNumberInputLabel,
    passwordMinValueError,
    passwordMaxValueError,
    passwordRequiredError,
    passwordUpperCaseError,
    passwordLowerCaseError,
    passwordOneDigitError,
    passwordSpecialCharError,
    passwordRepeatingCharError,
    passwordSequentialCharError,
    passwordNoSpacesError,
    confirmPasswordError,
    pinRequiredError,
    pinValidationError,
    sqRequiredError,
    sqaMinValueError,
    sqaRequiredError,
  } = userProfileWebContent;

  const { phoneRequiredError, phoneError } = addressFormWebContent;

  const { fcaUser, sxmUser } = searchUserData || { fcaUser: {} as FcaUser, sxmUser: {} as SxmUser };
  const { isPinSet, passwordExists, isSecurityQuestionsSet } = fcaUser || {
    isPinSet: false,
    passwordExists: false,
    isSecurityQuestionsSet: false,
  };

  const hasUpperCaseRegexp = /[A-Z]/;
  const hasLowerCaseRegexp = /[a-z]/;
  const hasDigitsRegex = /\d/;
  const hasSpecialCharRegexp = /[@$!%*?&_-]/;
  const allExceptAllowedCharsRegexp = /[^A-Za-z0-9@$!%*?&_-\s]/;
  const noSequentialCharRegexp =
    /^(?!.*(012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|PQR|QRS|RST|STU|TUV|UVW|WXY|XYZ)){3,}/;
  const noRepeatingCharRegexp = /^(?!.*(.)(?:\1\1)).*$/;
  const hasSpaceRegexp = /^(?!.*\s).*$/;

  const primaryPhoneType = formFields?.find(({ fieldName }) => fieldName === 'primaryPhoneType') as FormFieldDropdown;

  const phoneTypeOptions =
    primaryPhoneType?.fieldValues.map((val) => {
      const [value, label] = val.split('::');
      return { value, label };
    }) || [];

  const needPhone = !!(requirePhone && !!phoneTypeOptions.length);

  const yupShape: UserProfileYupShapeType = {
    firstName: Yup.string().trim().required(firstNameValidationError),
    lastName: Yup.string().trim().required(lastNameValidationError),
    ...(!passwordExists && {
      password: Yup.string()
        .required(passwordRequiredError)
        .min(8, passwordMinValueError)
        .max(16, passwordMaxValueError)
        .test('has upper case', passwordUpperCaseError, (value) => {
          return hasUpperCaseRegexp.test(value);
        })
        .test('has lower case', passwordLowerCaseError, (value) => {
          return hasLowerCaseRegexp.test(value);
        })
        .test('has digits', passwordOneDigitError, (value) => {
          return hasDigitsRegex.test(value);
        })
        .test('has special character', passwordSpecialCharError, (value) => {
          return hasSpecialCharRegexp.test(value);
        })
        .test('has unsupported character', passwordSpecialCharError, (value) => {
          return !allExceptAllowedCharsRegexp.test(value);
        })
        .test('has no repeating characters', passwordRepeatingCharError, (value) => {
          return noRepeatingCharRegexp.test(value);
        })
        .test('has no more than two sequential characters', passwordSequentialCharError, (value) => {
          return noSequentialCharRegexp.test(value);
        })
        .test('has no space', passwordNoSpacesError, (value) => {
          return hasSpaceRegexp.test(value);
        }),
      confirmPassword: Yup.string()
        .required(passwordRequiredError)
        .oneOf([Yup.ref('password'), null], confirmPasswordError),
    }),
    ...(!(isPinSet && isSecurityQuestionsSet) && {
      pin: Yup.string()
        .required(pinRequiredError)
        .matches(/^\d{4}$/, pinValidationError),
      question: Yup.string().required(sqRequiredError),
      answer: Yup.string().min(4, sqaMinValueError).required(sqaRequiredError),
    }),
    ...(needPhone && {
      primaryPhone: Yup.string()
        .required(phoneRequiredError)
        .test('validation', phoneError, (value = '') => validatePhoneNumber(value)),
      primaryPhoneType: Yup.string().required(primaryPhoneType?.fieldValidation?.fieldRequiredError),
      optIn: Yup.bool().optional(),
    }),
  };

  const handleSubmit = (values?: FormValues) => {
    const { optIn, ...restValues } = values || {};

    flowMachine.send({
      type: 'LOGIN_USER',
      data: { values: values ? restValues : values, needPhone, optIn },
    });
  };

  const initialValues = {
    firstName: sxmUser?.firstName || '',
    lastName: sxmUser?.lastName || '',
    email: subscriptionProps?.userDetails.email || sxmUser?.email || '',
    ...(needPhone && {
      primaryPhone: subscriptionProps?.userDetails?.primaryPhone?.number || '',
      primaryPhoneType: subscriptionProps?.userDetails?.primaryPhone?.type || 'Mobile',
      optIn: false,
    }),
    password: '',
    confirmPassword: '',
    pin: '',
    question: '',
    answer: '',
  };

  const firstNameExisted = !isEmpty(initialValues.firstName);
  const lastNameExisted = !isEmpty(initialValues.lastName);

  return (
    <UserProfile
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      firstNameExisted={firstNameExisted}
      lastNameExisted={lastNameExisted}
      isPinSet={isPinSet}
      isSecurityQuestionsSet={isSecurityQuestionsSet}
      passwordExists={passwordExists}
      needPhone={needPhone}
      phoneLabel={phoneNumberInputLabel}
      phoneTypeLabel={primaryPhoneType?.fieldLabel}
      phoneTypeOptions={phoneTypeOptions}
      yupShape={yupShape}
      commonWebContent={commonWebContent}
      userProfileWebContent={userProfileWebContent}
    />
  );
};

export default UserProfileContainer;
