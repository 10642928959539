import React from 'react';
import { Container, Content } from './BadgeStyles';

const Badge = ({ children }) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

export default Badge;
