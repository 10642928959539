import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { EligibleVehicleDetails, WebContentProps } from '@components/VinLookup/Types';

interface VehicleDetailsProps extends WebContentProps {
  vehicleDetails: EligibleVehicleDetails[];
}

const VehicleDetails = ({ labels, assets, vehicleDetails }: VehicleDetailsProps) => {
  const [vehicleDetail] = vehicleDetails;
  const { make: vehicleMake, year } = vehicleDetail;

  const getVehicleImage = () => {
    const make = vehicleMake.toLowerCase();
    const keys = [`${make}-${year}`, `${make}`];

    return assets?.find((asset) => asset.key && keys.includes(asset.key.replace('-image', '')));
  };
  const vehicleImage = getVehicleImage();

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid lightgray',
        borderRadius: '6px',
        borderColor: 'divider',
        height: '34em',
        overflowY: 'auto',
      }}
    >
      {vehicleImage && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={vehicleImage.asset.file.url}
            alt={vehicleImage.asset.title}
            style={{ width: '10rem', height: 'auto' }}
          />
        </Box>
      )}
      <Typography variant="h6" mt={3} mb={3}>
        {year} {vehicleMake.toUpperCase()}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Object.entries(vehicleDetail).map(([key, value]) =>
              labels[key] ? (
                <TableRow key={key} sx={{ '&:last-child td': { borderBottom: 0 } }}>
                  <TableCell padding="normal">{labels[key]}</TableCell>
                  <TableCell padding="normal">{value}</TableCell>
                </TableRow>
              ) : null,
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VehicleDetails;
