import { styled } from '@mui/material';

export const Container = styled('span')`
  font-size: 0.75rem;
  padding: 0.3125rem;
  text-align: center;
  display: inline-flex;
  position: relative;
  align-items: center;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
`;

export const Content = styled('span')`
  color: ${({ theme }) => theme.palette.background.default};
  background: ${({ theme }) => theme.palette.primary.main};
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  border-radius: 50%;
  padding: 0.125em;
  box-sizing: content-box;
  line-height: 1rem;
`;
