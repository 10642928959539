import React from 'react';
import { ContentfulAssets } from '@manageSubscription';
import { Link } from '@mui/material';
import Modal, { useModal } from '../Modal';
import { OkModal } from './OkModal';

interface LinkToOkModalProps {
  className?: string;
  message: React.ReactNode;
  buttonText: string;
  assets: ContentfulAssets;
  onConfirm?: () => void;
  sx?: any;
}

const LinkToOkModal = ({
  className,
  message,
  buttonText,
  assets,
  onConfirm,
  children,
  sx,
}: React.PropsWithChildren<LinkToOkModalProps>) => {
  const { isShown, toggleModal } = useModal();

  const showModal = () => {
    return (
      <OkModal
        onConfirm={() => {
          toggleModal();
          onConfirm?.();
        }}
        buttonText={buttonText}
        message={message}
      />
    );
  };

  return (
    <>
      <Link
        sx={{
          margin: '1rem 0',
          textDecoration: 'underline !important',
          ...sx,
        }}
        onClick={toggleModal}
        color="primary"
        title="Open"
      >
        {children}
      </Link>

      <Modal assets={assets} content={showModal()} isShown={isShown} hide={toggleModal} />
    </>
  );
};

export default LinkToOkModal;
