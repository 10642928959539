import CryptoJS from 'crypto-js';

function getTokenPayload(token: string): string {
  const payload = token.split('.')[1];
  return payload.replace(/-/g, '+').replace(/_/g, '/');
}

function decodeTokenPayload(base64: string): string {
  const words = CryptoJS.enc.Base64.parse(base64);
  return CryptoJS.enc.Utf8.stringify(words);
}

type DecodedToken = {
  sub: string;
  cts: string;
  auth_level?: number;
  auditTrackingId: string;
  subname: string;
  iss: string;
  tokenName: 'access_token' | string;
  token_type: 'Bearer' | string;
  authGrantId: string;
  aud: string;
  nbf: number;
  grant_type: 'password' | string;
  scope: string[];
  auth_time: number;
  realm: string;
  exp: number;
  iat: number;
  expires_in: number;
  jti: string;
};

const decodeToken = (token: string): DecodedToken => {
  const payload = getTokenPayload(token);
  const json = decodeTokenPayload(payload);
  return JSON.parse(json);
};

export function getTokenExpirationTime(token: string): number {
  const payload = decodeToken(token);
  return payload.exp * 1000;
}

export function getTokenScope(token: string): string[] {
  const payload = decodeToken(token);
  return payload.scope;
}

export function getTokenUserAndTenant(token: string): { userId: string; tenantId: string } {
  const payload = decodeToken(token);
  return {
    userId: payload.sub,
    tenantId: payload.realm.slice(1),
  };
}

export default decodeToken;
