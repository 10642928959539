export const getFormattedAddress = (address: any, locale: string) => {
  const { city = '', state = '', postalCode = '', street = '' } = address;
  let addressLine1 = '';
  let addressLine2 = '';

  switch (locale) {
    case 'es-MX':
      addressLine1 = street;
      addressLine2 = `${postalCode} ${city}, ${state}`;
      return { addressLine1, addressLine2 };
    case 'fr-CA':
    case 'en-CA':
    case 'en-US':
    default:
      addressLine1 = street;
      addressLine2 = `${city}, ${state} ${postalCode}`;
      return { addressLine1, addressLine2 };
  }
};
