import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import Spinner from '@lib-components/Spinner';
import { PayPalContainer } from '../styles';
import { SubscriptionProps } from '@manageSubscription';
import { OnApproveData } from '@paypal/paypal-js';
import { getPayPalAgreementToken, getPayPalPaymentMethod } from '@lib-services';
import { PaymentMethodsResponse } from '@cv/portal-cps-lib/payment/payment-methods/models';

interface PayPalProps {
  subscriptionProps: SubscriptionProps;
  setPaymentMethodId: (pmID: string) => void;
  onApprove?: () => void;
}

const PayPal = ({ subscriptionProps, onApprove, setPaymentMethodId }: PayPalProps) => {
  const [{ isPending }] = usePayPalScriptReducer();

  if (isPending) {
    return <Spinner />;
  }

  return (
    <PayPalContainer>
      <PayPalButtons
        data-testid="paypal"
        createBillingAgreement={async () => {
          return getPayPalAgreementToken(subscriptionProps).then(({ token_id }) => token_id);
        }}
        onApprove={async ({ billingToken }: OnApproveData) => {
          onApprove?.();
          getPayPalPaymentMethod(subscriptionProps, billingToken).then(
            ({ paymentMethodId }: PaymentMethodsResponse) => {
              setPaymentMethodId(paymentMethodId);
            },
          );
        }}
      />
    </PayPalContainer>
  );
};

export default PayPal;
