import React from 'react';
import {
  useFlowMachine,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
} from '../../Router/RouterContext';
import { useSelector } from '@xstate/react';
import InformationPageSubscription from './InformationPageSubscription';
import { FlowEventName } from '@lib-appRouter/flowTypes';

const InformationPageSubscriptionContainer = () => {
  const flowMachine = useFlowMachine();
  const subscriptionTerms = useSelector(flowMachine, (state) => state.context.subscriptionTerms);
  const { subscribedPackages } = useFlowMachineContextFlowStorage();
  const {
    informationPageSubscriptionContent,
    assets,
    commonWebContent: { continueButtonLabel },
  } = useFlowMachineContextContent();

  const navigateForward = () => {
    flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
  };

  const imageData = assets['InfoBackground'];
  const headerLogo = assets['headerLogoInfoPage'];

  return (
    <InformationPageSubscription
      informationPageSubscriptionContent={informationPageSubscriptionContent}
      subscriptionTerms={subscriptionTerms}
      headerLogoData={headerLogo}
      bgImageData={imageData}
      subscribedPackages={subscribedPackages}
      continueButtonLabel={continueButtonLabel}
      navigateForward={navigateForward}
    />
  );
};

export default InformationPageSubscriptionContainer;
