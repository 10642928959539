import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { RootState } from '@app/reducers';
import { IUserState } from '@redux/reducers/user';
import UserCalendar from '@customTypes/UserCalendar';
import { Button } from '@components-lib';

import styles from './Calendar.module.css';

export type CalendarDialogProps = {
  dialogDescription: string;
  noCalendarsFound: string;
  cancelText: string;
  selectText: string;
  onClose: () => void;
  onSelect: (selectedCalendar: UserCalendar) => void;
};

const CalendarDialog = ({
  dialogDescription,
  noCalendarsFound,
  cancelText,
  selectText,
  onClose,
  onSelect,
}: CalendarDialogProps) => {
  const { calendar: currentCalendar, calendarList }: IUserState = useSelector(
    ({ userReducer }: RootState) => userReducer,
  );

  const [selectedCalendar, setSelectedCalendar] = useState<UserCalendar | null>(null);

  const close = useCallback(() => {
    setSelectedCalendar(null);
    onClose();
  }, [setSelectedCalendar, onClose]);

  const select = useCallback(() => {
    !!selectedCalendar && onSelect(selectedCalendar);
    close();
  }, [onSelect, selectedCalendar, close]);

  const canSelect = !!selectedCalendar && selectedCalendar?.calendarName !== currentCalendar?.calendarName;

  return (
    <div>
      <p className={styles.calendarDialogDescription}>{dialogDescription}</p>
      {calendarList && calendarList.length ? (
        calendarList.map((calendar) => {
          const equalsToCurrent =
            !selectedCalendar && !!currentCalendar && currentCalendar?.calendarName === calendar.calendarName;

          const isSelected = !!selectedCalendar && selectedCalendar?.calendarName === calendar.calendarName;

          const selected = equalsToCurrent || isSelected;
          const secondClickOnSelected = !equalsToCurrent && isSelected;
          return (
            <Button
              className={styles.optionCalendarButton}
              variant={selected ? 'contained' : 'outlined'}
              onClick={() => setSelectedCalendar(secondClickOnSelected ? null : calendar)}
              key={calendar.calendarName}
              title={calendar.calendarName}
            >
              {calendar.calendarName}
            </Button>
          );
        })
      ) : (
        <p>{noCalendarsFound}</p>
      )}
      <div className={styles.calendarDialogButtons}>
        <Button onClick={close} className={styles.calendarDialogButton}>
          {cancelText}
        </Button>
        <Button
          onClick={select}
          className={clsx(styles.calendarDialogButton, styles.calendarDialogButtonSelect)}
          disabled={!canSelect}
        >
          {selectText}
        </Button>
      </div>
    </div>
  );
};

export default CalendarDialog;
