import { ZuoraPaymentRequest } from './ZuoraInfo';

export class ZuoraPaymentRequestBuilder {
  private readonly request: ZuoraPaymentRequest;

  constructor() {
    this.request = {
      id: '',
      key: '',
      url: '',
      style: 'inline',
      token: '',
      phone: '',
      email: '',
      locale: '',
      signature: '',
      tenantId: '',
      submitEnabled: 'true',
      field_accountId: '',
      field_deviceSessionId: '',
      param_supportedTypes: [],
      param_gwOptions_OEM: '',
      param_gwOptions_VIN: '',
      param_gwOptions_source: '',
      param_gwOptions_ipAddress: '',
      param_gwOptions_inAuthTId: '',
      param_gwOptions_browserCookie: window.document.cookie,
    };
  }

  id(id: string): ZuoraPaymentRequestBuilder {
    this.request.id = id;
    return this;
  }

  key(key: string): ZuoraPaymentRequestBuilder {
    this.request.key = key;
    return this;
  }

  url(url: string): ZuoraPaymentRequestBuilder {
    this.request.url = url;
    return this;
  }

  style(style: string): ZuoraPaymentRequestBuilder {
    this.request.style = style;
    return this;
  }

  token(token: string): ZuoraPaymentRequestBuilder {
    this.request.token = token;
    return this;
  }

  phone(phone: string): ZuoraPaymentRequestBuilder {
    this.request.phone = phone;
    return this;
  }

  email(email: string): ZuoraPaymentRequestBuilder {
    this.request.email = email;
    return this;
  }

  locale(locale: string): ZuoraPaymentRequestBuilder {
    this.request.locale = locale;
    return this;
  }

  signature(signature: string): ZuoraPaymentRequestBuilder {
    this.request.signature = signature;
    return this;
  }

  tenantId(tenantId: string): ZuoraPaymentRequestBuilder {
    this.request.tenantId = tenantId;
    return this;
  }

  submitEnabled(submitEnabled: string): ZuoraPaymentRequestBuilder {
    this.request.submitEnabled = submitEnabled;
    return this;
  }

  accountId(accountId: string): ZuoraPaymentRequestBuilder {
    this.request.field_accountId = accountId;
    return this;
  }

  deviceSessionId(deviceSessionId: string): ZuoraPaymentRequestBuilder {
    this.request.field_deviceSessionId = deviceSessionId;
    return this;
  }

  supportedTypes(supportedTypes: Array<string>): ZuoraPaymentRequestBuilder {
    this.request.param_supportedTypes = supportedTypes;
    return this;
  }

  oem(oem: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_OEM = oem;
    return this;
  }

  vin(vin: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_VIN = vin;
    return this;
  }

  source(source: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_source = source;
    return this;
  }

  ipAddress(ipAddress: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_ipAddress = ipAddress;
    return this;
  }

  authTId(authTId: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_inAuthTId = authTId;
    return this;
  }

  browserCookie(browserCookie: string): ZuoraPaymentRequestBuilder {
    this.request.param_gwOptions_browserCookie = browserCookie;
    return this;
  }

  build(): ZuoraPaymentRequest {
    return this.request;
  }
}
