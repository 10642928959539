import React from 'react';
import ManageTrialPackages from './ManageTrialPackages';
import { useSelector } from '@xstate/react';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
  useFlowMachineContextSubscriptionProps,
} from '@lib-appRouter/RouterContext';
import { getTrialSubscriptions, hasMultipleTrialPackages, TrialPackagesWebContent } from '@manageSubscription';

const ManageTrialPackagesContainer = () => {
  const flowMachine = useFlowMachine();

  const {
    trialPackagesWebContent,
    commonWebContent: { productsToHide },
    assets,
  } = useFlowMachineContextContent();

  const {
    locale,
    tenantId,
    vehicleDetails: { make },
    shouldMatchTrialWithCCRequired,
  } = useFlowMachineContextSubscriptionProps();

  const { packageSubscriptions } = useFlowMachineContextFlowStorage();

  const location = useFlowLocation();
  const eligibleTrials = useSelector(flowMachine, (state) => state.context.eligibleTrialPackages);
  const { header, subHeader, trialEligibleDescription, legalText } = trialPackagesWebContent as TrialPackagesWebContent;

  const hasMultipleTrials = hasMultipleTrialPackages(eligibleTrials);
  const selectedTrialSubscriptions = getTrialSubscriptions(packageSubscriptions);

  const addAllPackages = (eligiblePackages: EligiblePackageInfo[]) => {
    flowMachine.send({ type: 'ADD_ALL_PACKAGES', data: { packages: eligiblePackages } });
  };

  const handleNoThanks = () => {
    flowMachine.send({ type: 'HANDLE_NO_THANKS', data: { packages: [] } });
  };

  return (
    <ManageTrialPackages
      header={header}
      subHeader={subHeader}
      trialEligibleDescription={trialEligibleDescription}
      legalText={legalText}
      trialPackages={eligibleTrials}
      selectedTrialSubscriptions={selectedTrialSubscriptions}
      make={make}
      locale={locale}
      tenantId={tenantId}
      location={location}
      shouldMatchTrialWithCCRequired={shouldMatchTrialWithCCRequired}
      assets={assets}
      productsToHide={productsToHide}
      hasMultipleTrials={hasMultipleTrials}
      addAllPackages={addAllPackages}
      handleNoThanks={handleNoThanks}
    />
  );
};

export default ManageTrialPackagesContainer;
