import React from 'react';
import Button, { ButtonProps } from '@app/components-lib/components/Button';
import { sendAnalyticsEvent, EventDataType } from '@app/components-lib/components/Analytics';

export type AnalyticsButtonProps = ButtonProps & { analyticsEvent?: EventDataType };

const AnalyticsButton = ({ analyticsEvent, ...props }: AnalyticsButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (analyticsEvent && Object.keys(analyticsEvent).length > 0) {
      sendAnalyticsEvent(analyticsEvent);
    }
    props.onClick?.(event);
  };

  return <Button {...props} onClick={handleClick} />;
};

export default AnalyticsButton;
