import React from 'react';
import { Container, Heading, LineSeparator, Title } from '../../styled-components/globalStyles';
import { PrivacyPolicyLink } from './styles';
import { OrderSummary } from '../Order/OrderSummary';
import { Pricing } from '../Order/styles';
import { PromoCode } from '../Discount';
import { PreviewOrderSummaryFetcher } from '../Order/PreviewOrderSummaryFetcher';
import { DiscountInfo, OrderSummaryInfo, PreviewOrderRequestInfo } from '../Types';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SubscriptionProps, WebContent } from '../Subscription';
import StateMachineWrapper from '../../payment/components/Payment/StateMachineWrapper';
import { SubmitCallback } from '../../payment/Types';

interface PaymentWrapperProps {
  invokePreviewOrder: () => void;
  getPreviewOrderError: () => string;
  getSubtotal: () => string;
  setPreviewOrderResponse: (data: PreviewOrderResponse) => void;
  setPreviewOrderError: (data: string) => void;
  navigateCancel: () => void;
  submitCallback: SubmitCallback;
  addDiscountInfo: (discountInfo: DiscountInfo) => void;
  subscriptionProps: SubscriptionProps;
  displayPayment: boolean;
  discountInfo: DiscountInfo;
  orderSummaryInfo: OrderSummaryInfo;
  content: WebContent;
  location: string;
  canEnterPromoCode: boolean;
  previewOrderRequestInfo: PreviewOrderRequestInfo;
}

const PaymentWrapper: React.FC<PaymentWrapperProps> = ({
  invokePreviewOrder,
  getPreviewOrderError,
  getSubtotal,
  setPreviewOrderResponse,
  setPreviewOrderError,
  navigateCancel,
  submitCallback,
  addDiscountInfo,
  subscriptionProps,
  displayPayment,
  discountInfo,
  orderSummaryInfo,
  content,
  location,
  canEnterPromoCode,
  previewOrderRequestInfo,
  children,
}) => {
  const { subTotalLabel, commonWebContent, paymentInfoLabel, privacyPolicyLinkLabel, assets } = content;
  return (
    <div>
      <Container>
        {displayPayment ? (
          <div>
            <Heading>{paymentInfoLabel}</Heading>
            <LineSeparator />
            <PreviewOrderSummaryFetcher
              setPreviewOrderResponse={setPreviewOrderResponse}
              setPreviewOrderError={setPreviewOrderError}
              isFetchPreviewOrder
              subscriptionProps={subscriptionProps}
              previewOrderRequestInfo={previewOrderRequestInfo}
            />
            <OrderSummary
              orderSummaryInfo={orderSummaryInfo}
              content={content}
              subscriptionProps={subscriptionProps}
              discountInfo={discountInfo}
              location={location}
            >
              <Pricing>
                <Title>{subTotalLabel}</Title>
                <Title>
                  {commonWebContent.currencyLabel}
                  {getSubtotal()}
                </Title>
              </Pricing>

              {canEnterPromoCode && (
                <PromoCode
                  discountInfo={discountInfo}
                  applyPromoCode={invokePreviewOrder}
                  getPromoCodeError={getPreviewOrderError}
                  assets={assets}
                  location={location}
                  content={content}
                  addDiscountInfo={addDiscountInfo}
                />
              )}
            </OrderSummary>
            <PrivacyPolicyLink href={subscriptionProps.privacyPolicyUrl} target={'_blank'}>
              {privacyPolicyLinkLabel}
            </PrivacyPolicyLink>
            <StateMachineWrapper
              navigateCancel={navigateCancel}
              submitCallback={submitCallback}
              subscriptionInfo={{ totalAmount: getSubtotal() }}
            />
          </div>
        ) : (
          children
        )}
      </Container>
    </div>
  );
};

export default PaymentWrapper;
