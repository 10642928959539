interface Option<V extends string> {
  label: string;
  value: V;
}

function transformToOptions<K extends string, T extends Option<K>>(options: Array<string> = []): Array<T> {
  return options.reduce<T[]>((acc, str) => {
    const [value, label] = str.split('::');
    if (!value || !label) {
      console.error(`transformToOptions: unprocessable option ${str}`);
      return acc;
    }
    return [...acc, { value, label } as T];
  }, []);
}

export default transformToOptions;
export { Option };
