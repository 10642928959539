import React from 'react';
import { EligiblePackageDetail, EligibleVehicleDetails, WebContentProps } from '@components/VinLookup/Types';
import { Box, Container, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import MobileIcon from '@mui/icons-material/MobileFriendly';
import { Button } from '@app/components-lib';
import VinEligibleServices from '@components/VinLookup/VinEligibleServices';
import VehicleDetails from '@components/VinLookup/VehicleDetails';
import NonVinEligibilityInfo from '@components/VinLookup/NonVinEligibilityInfo';
import { useFeatureFlags } from '@components/FeatureFlags';

type QueryStatus = 'idle' | 'loading' | 'error' | 'success';

interface EligibleVehicleOverviewProps extends WebContentProps {
  queryStatus: QueryStatus;
  vehicleDetails: EligibleVehicleDetails[];
  vinEligiblePackages: EligiblePackageDetail[];
}

const EligibleVehicleOverview = ({
  labels,
  assets,
  queryStatus,
  vehicleDetails,
  vinEligiblePackages,
}: EligibleVehicleOverviewProps) => {
  const {
    vinSearchProgressLabel,
    vinEligibleServiceHeader,
    vinEligibleServiceSubHeader,
    callToClaimOwnershipLabel,
    customerCareNumber,
    goToEproofLabel,
    eproofURL,
  } = labels;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { vinLookupShowGoToEproof } = useFeatureFlags();

  const getSkeleton = () => {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        role="skeleton"
        sx={{
          height: {
            xs: '10em',
            md: '24em',
          },
        }}
      />
    );
  };

  if (queryStatus === 'error') {
    return <NonVinEligibilityInfo labels={labels} assets={assets} />;
  }

  return (
    <Container
      sx={(theme) => ({
        width: { xs: '100%', md: '70%' },
        mt: 4,
        mb: 4,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
      })}
    >
      <Box>
        <Typography
          variant="h5"
          mb={1}
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
        >
          {queryStatus === 'loading' ? vinSearchProgressLabel : vinEligibleServiceHeader}
        </Typography>
        <Typography variant="h6" style={{ color: 'grey' }}>
          {vinEligibleServiceSubHeader}
        </Typography>

        {queryStatus === 'success' &&
          (vinLookupShowGoToEproof ? (
            <Button sx={{ '&:hover': { color: 'white' } }} href={`${eproofURL}?vin=${vehicleDetails[0].vin}`}>
              {goToEproofLabel}
            </Button>
          ) : (
            <Button
              sx={{
                mt: 2,
                '&:hover': {
                  color: 'white',
                },
              }}
              variant="contained"
              href={`tel:${customerCareNumber}`}
              startIcon={<MobileIcon />}
            >
              {callToClaimOwnershipLabel + (isSmallScreen ? '' : ` ${customerCareNumber}`)}
            </Button>
          ))}
        <Grid container spacing={2} mt={3} mb={4}>
          <Grid xs={12} md={6}>
            {queryStatus === 'loading' ? (
              getSkeleton()
            ) : (
              <VehicleDetails labels={labels} assets={assets} vehicleDetails={vehicleDetails} />
            )}
          </Grid>
          <Grid xs={12} md={6}>
            {queryStatus === 'loading' ? (
              getSkeleton()
            ) : (
              <VinEligibleServices labels={labels} eligiblePackages={vinEligiblePackages} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EligibleVehicleOverview;
