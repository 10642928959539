import React, { useMemo, useState } from 'react';
import { Button } from '@components-lib';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import { isTrialPackage } from '@utils/isTrialPackage';
import styles from './CancelSubscription.module.css';
import { intersectionBy, isNull } from 'lodash';

type CancelSubscriptionOverviewProps = {
  handleContinue: () => void;
  handleCancel: () => void;
  handleMoreDetails: () => void;
  handleSelect: (checkedPackage: SubscribedPackage[]) => void;
  selectedPackages: Array<SubscribedPackage>;
  labels: {
    [index: string]: { primary: string; analyticsEventName?: string };
  };
  packages: Array<SubscribedPackage>;
};

const findFuturePackages = (
  allPackages: SubscribedPackage[],
  selectedPackage?: SubscribedPackage[],
  lastSelectedPackage?: SubscribedPackage,
) => {
  if (!selectedPackage) {
    return [];
  }

  const isBundle = lastSelectedPackage?.bundle || false;

  const filterStartDate = (pkg: SubscribedPackage) => {
    return selectedPackage.some(
      ({ endDate, subscriptionPackageId }) =>
        endDate <= pkg.startDate || subscriptionPackageId === pkg.parentSubscriptionId,
    );
  };

  const filterBundlePackages = (pkg: SubscribedPackage, comparePkg = lastSelectedPackage) => {
    if (!comparePkg || comparePkg?.subscriptionPackageId === pkg.subscriptionPackageId) {
      return false;
    }

    const haveInCommon = intersectionBy(comparePkg.products, pkg.products, 'id');
    return !!haveInCommon.length;
  };

  const isSame = (pkg: SubscribedPackage, comparePkg = lastSelectedPackage) => {
    return comparePkg?.subscriptionPackageId === pkg.subscriptionPackageId;
  };

  const isRelated = (pkg: SubscribedPackage, comparePkg = lastSelectedPackage) => {
    return comparePkg?.packageId === pkg.packageId && comparePkg?.subscriptionPackageId !== pkg.subscriptionPackageId;
  };

  const isTiered = (pkg?: SubscribedPackage) => {
    return !isNull(pkg?.tier);
  };

  return allPackages.filter((pkg) => {
    if (isTiered(lastSelectedPackage)) {
      return filterStartDate(pkg);
    }

    const same = isSame(pkg);
    const related = isRelated(pkg);
    const someRelated = selectedPackage.some(
      (sPkg) => !isSame(pkg, sPkg) && (isRelated(pkg, sPkg) || filterBundlePackages(pkg, sPkg)),
    );

    if (isBundle) {
      if (someRelated || related) {
        return filterStartDate(pkg);
      }
      return filterBundlePackages(pkg) && filterStartDate(pkg);
    }

    return someRelated && !same && (related || someRelated) && filterStartDate(pkg);
  });
};

export const CancelSubscriptionOverview = ({
  handleContinue,
  handleCancel,
  handleMoreDetails,
  handleSelect,
  selectedPackages = [],
  labels,
  packages,
}: CancelSubscriptionOverviewProps) => {
  const initialData = useMemo(
    () => ({
      selectedPackages,
      futurePackages: findFuturePackages(packages, selectedPackages),
    }),
    [],
  );

  const [futurePackages, setFuturePackages] = useState<{
    selectedPackages: SubscribedPackage[];
    futurePackages: SubscribedPackage[];
  }>(initialData);

  const { title, cancelDescription, keepSubscriptionButton, continueButton, moreDetailsLabel } = labels;

  const handleSelectPackage = (selectedPackage: SubscribedPackage) => {
    const updatedPackages = futurePackages.selectedPackages.includes(selectedPackage)
      ? futurePackages.selectedPackages.filter((sPackage) => sPackage !== selectedPackage)
      : [selectedPackage, ...futurePackages.selectedPackages];

    const fPackages = findFuturePackages(packages, updatedPackages, selectedPackage);

    setFuturePackages({
      selectedPackages: updatedPackages,
      futurePackages: fPackages,
    });

    const allSelectedPackages = Array.from(new Set([...updatedPackages, ...fPackages]));
    handleSelect(allSelectedPackages);
  };

  const renderedPackages = useMemo(() => {
    return packages.map((packageToDisplay: SubscribedPackage) => {
      const isSelectedPackage = futurePackages.selectedPackages.includes(packageToDisplay);
      const isDisabledPackages = futurePackages.futurePackages.includes(packageToDisplay);

      const isTrial = isTrialPackage(packageToDisplay);
      const onSelect = () => handleSelectPackage(packageToDisplay);

      return (
        <li
          data-testid={'product-details'}
          key={packageToDisplay.subscriptionPackageId}
          className={styles['package-list-item']}
        >
          <label className={styles['package-description']}>
            <input
              type="checkbox"
              onChange={onSelect}
              checked={isSelectedPackage || isDisabledPackages}
              disabled={isDisabledPackages}
            />
            {packageToDisplay.marketingName}
            {isTrial && ' (TRIAL)'}
          </label>
          <ul className={styles['package-long-description']}>
            <li>{packageToDisplay.longDescription}</li>
          </ul>
        </li>
      );
    });
  }, [packages, selectedPackages, handleSelect]);

  return (
    <div className={styles['container']}>
      <h1 className="title">{title?.primary}</h1>

      <p className="cancel-description">{cancelDescription?.primary}</p>

      {packages.length && (
        <ul id="package-list" key="package-list" className={styles['package-container']}>
          {renderedPackages}
        </ul>
      )}
      <div className={styles['more-details-button-wrapper']}>
        <Button variant="text" onClick={handleMoreDetails}>
          {moreDetailsLabel?.primary}
        </Button>
      </div>
      <div className={styles['action-button-wrapper']}>
        <Button
          variant="contained"
          onClick={handleCancel}
          data-analyticseventname={formatAnalyticsEventName(keepSubscriptionButton?.analyticsEventName)}
        >
          {keepSubscriptionButton?.primary}
        </Button>
        <Button
          variant="outlined"
          onClick={handleContinue}
          disabled={futurePackages.selectedPackages.length === 0}
          data-analyticseventname={formatAnalyticsEventName(continueButton?.analyticsEventName)}
        >
          {continueButton?.primary}
        </Button>
      </div>
    </div>
  );
};
