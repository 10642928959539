import { EligiblePackageInfo, SubscribedPackageInfo } from '../../Types';
import { containsEligiblePackage, deDupeVariantsById, getHighestPaidSubscription } from '../../utils';
import { ALLOWED_PAID_PKG_VARIANTS } from '../../utils/constants';
import { TenantsSupportOnlyUpgrade } from '../../utils/productFlowMap';

/**
 * Extract packages by segregating them based on variants (Monthly, Annual etc.) in the below format:
 * {1: [Monthly packages] 12: [Annual packages] }
 *
 * @param ePackages - Already filtered to contain only packages with PackageType = Regular and TermUnit = Months
 */
export type VariantsToShow = Record<number, EligiblePackageInfo[]>;
export const extractVariants = (
  sPackages: SubscribedPackageInfo[],
  ePackages: EligiblePackageInfo[],
  tenantId: string,
): VariantsToShow => {
  const pkgWithVariants = {} as VariantsToShow;
  const sPackage = getHighestPaidSubscription(sPackages);

  // Extract each variant along with its package details
  ePackages.forEach((pkg) => {
    const subscribedVariant = sPackages.find(({ packageId }) => packageId === pkg.id);

    if (subscribedVariant && ALLOWED_PAID_PKG_VARIANTS.includes(subscribedVariant.renewalTerm)) {
      if (subscribedVariant.autoRenew) {
        (pkgWithVariants[subscribedVariant.renewalTerm] ??= []).push({
          ...subscribedVariant,
          products: subscribedVariant.products,
          id: subscribedVariant.packageId,
          parentId: subscribedVariant.parentSubscriptionId,
          basePriceMonthly: null,
          isPkgSubscribed: true,
          variants: null,
        });
      }
    }
    pkg.variants.forEach((variant) => {
      if (!ALLOWED_PAID_PKG_VARIANTS.includes(variant.initialTerm)) return;

      const vPackage = { ...pkg, variant } as EligiblePackageInfo;
      const isPkgSubscribed = containsEligiblePackage(sPackages, vPackage);

      if (isPkgSubscribed || canShowPackage(sPackage, vPackage, tenantId)) {
        (pkgWithVariants[variant.initialTerm] ??= []).push({ ...vPackage, isPkgSubscribed });
      }
    });
  });
  return deDupeVariantsById(pkgWithVariants);
};

/**
 * A check to return a boolean flag whether to show only upgraded or all packages
 * based on the tenant
 */
export const canShowPackage = (sPackage: SubscribedPackageInfo, ePackage: EligiblePackageInfo, tenantId: string) => {
  if (!TenantsSupportOnlyUpgrade[tenantId]) return true;

  const {
    tier,
    variant: { initialTerm },
  } = sPackage;
  return (ePackage.tier === tier && ePackage.variant.initialTerm > initialTerm) || ePackage.tier < tier;
};
