import { useState, useEffect } from 'react';
import useGetLink from '@hooks/useGetLink';

const useLinkConfig = (staticUrl: string, linkConfig?: string) => {
  const [url, setUrl] = useState<string>();
  const getLink = useGetLink();

  useEffect(() => {
    if (linkConfig) {
      const setDynamicUrl = async () => {
        const dynamicUrl = await getLink(linkConfig);
        setUrl(dynamicUrl);
      };

      setDynamicUrl();
    }
  }, [linkConfig, getLink]);

  return url || staticUrl;
};

export default useLinkConfig;
