import React from 'react';
import { ContentfulInit } from '@cv/webframework-react-components';
import config from '@config/config';

export const ContentfulInitWrapper = (props: { children: React.ReactNode }) => {
  // Contentful settings
  const contentfulConfig = {
    accessToken: config.get<string>('CONTENTFUL_ACCESS_TOKEN'),
    spaceId: config.get<string>('CONTENTFUL_SPACE_ID'),
    environment: config.get<string>('CONTENTFUL_ENV'),
  };
  return <ContentfulInit {...contentfulConfig}>{props.children}</ContentfulInit>;
};
