import * as yup from 'yup';
import _ from 'lodash';
import { ServerLabel } from '@utils/labels';
import RuleType from '@customTypes/password/RuleType';

type GetPasswordValidation = (labels: ServerLabel[], email?: Email) => PasswordValidation;

export const getPasswordValidation: GetPasswordValidation = _.flow([convertLabelsToRules, convertRulesToValidation]);

export function convertLabelsToRules(labels: ServerLabel[], email: Email = {}): [Rule[], Email] {
  const rules = labels.map<Rule>((label) => ({
    errorMessage: label.labelValue || '',
    value: label.additionalLabelValue || '',
    range: label.labelKey || '',
    ruleType: label.ruleType,
  }));
  return [rules, email];
}

export function convertRulesToValidation([rules, outerEmail]: [Rule[], Email]): PasswordValidation {
  let password = yup.string().trim();
  let confirmPassword = yup.string().trim();

  const onlyRequireHandler: RuleApplier = ({ errorMessage }) => {
    password = password.required(errorMessage);
    confirmPassword = confirmPassword.required(errorMessage);
  };

  const lengthHandler: RuleApplier = ({ value, errorMessage }) => {
    const minLength = Number(value);
    password = password.min(minLength, errorMessage);
  };

  const itemsHandler: RuleApplier = ({ range, errorMessage, value }) => {
    const requiredCount = Number(value);
    const allRange = range.split('');
    password = password.test(
      errorMessage,
      errorMessage,
      (value = '') => value.split('').filter((item) => allRange.includes(item)).length >= requiredCount,
    );
  };

  const preventEmailHandler: RuleApplier = ({ errorMessage }) => {
    password = password.test('not match', errorMessage, (value = '', { parent = {} }) => {
      const email = parent.email || outerEmail.email || '';
      return (!value && !email) || value !== email;
    });
  };

  const confirmationHandler: RuleApplier = ({ errorMessage }) => {
    confirmPassword = confirmPassword.test('match', errorMessage, function () {
      return this.parent.password === this.parent.confirmPassword;
    });
  };

  const realRules = rules.filter(({ ruleType }) => ruleType !== RuleType.Info);

  const requireRules = getSpecificRules(RuleType.Require, onlyRequireHandler, realRules);
  const lengthRules = getSpecificRules(RuleType.Length, lengthHandler, realRules);
  const itemsRules = getSpecificRules(RuleType.Items, itemsHandler, realRules);
  const preventEmailRules = getSpecificRules(RuleType.PreventSameAsEmail, preventEmailHandler, realRules);
  const confirmationRules = getSpecificRules(RuleType.Confirmation, confirmationHandler, realRules);

  const allRules: SpecificRules[] = [requireRules, lengthRules, itemsRules, preventEmailRules, confirmationRules];

  allRules.forEach(([specificRules, apply]) => specificRules.forEach((rule) => apply(rule)));

  return { password, confirmPassword };
}

function getSpecificRules(specificType: RuleType, handler: RuleApplier, rules: Rule[]): SpecificRules {
  const specificRules = rules.filter(({ ruleType }) => ruleType === specificType);
  return [specificRules, handler];
}

export type PasswordValue = string | undefined;

export type Validation = yup.StringSchema<PasswordValue, Record<string, unknown>, PasswordValue>;

export type PasswordValidation = {
  password: Validation;
  confirmPassword: Validation;
};

export type Rule = {
  errorMessage: string;
  value: string;
  range: string;
  ruleType?: string;
};

export type RuleApplier = (rule: Rule) => void;
export type SpecificRules = [Rule[], RuleApplier];

export type Email = Record<string, string | undefined>;
