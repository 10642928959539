import { linkVehicle, unlinkVehicle } from '@cv/portal-idm-lib/vehicle';
import { LinkVehicleRequest, UnlinkVehicleRequest } from '@cv/portal-idm-lib/vehicle/models';
import { Environment } from '@cv/portal-common-lib/ajax/constants';

type LinkVehicle = {
  vehicleId: string;
  userId: string;
  roleId: string;
  accessToken: string;
  locale: string;
};

export default class VehicleLinkService {
  private environment: Environment;

  constructor(environment = Environment.SIT) {
    this.environment = environment;
  }

  linkVehicle({ vehicleId, userId, roleId, accessToken, locale }: LinkVehicle) {
    const request: LinkVehicleRequest = {
      pathParams: {
        vehicleId,
        userId,
      },
      data: {
        roleId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    return linkVehicle(request);
  }

  unlinkVehicle({ vehicleId, userId, roleId, accessToken, locale }: LinkVehicle) {
    const request: UnlinkVehicleRequest = {
      pathParams: {
        vehicleId,
        userId,
        roleId,
      },
      data: {},
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    return unlinkVehicle(request);
  }
}
