import { searchPoi } from '@cv/portal-rts-lib/v0/location/poi-search';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { PoiSearchRequest, PoiSearchPayload } from '@cv/portal-rts-lib/v0/location/poi-search/models';

type PoiServiceRequestProps = {
  accessToken: string;
  data: PoiSearchPayload;
  vin: string;
  id_token: string;
};

export default class PoiService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async poiSearch({ accessToken, data, vin, id_token }: PoiServiceRequestProps) {
    const request: PoiSearchRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
        id_token,
      },
      data,
    };

    const response = await searchPoi(request);
    return { data: response.data };
  }
}
