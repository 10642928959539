import { styled } from '@mui/material';
import { Title } from '../../styled-components/globalStyles';

export const Background = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
`;

export const Wrapper = styled('div')`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const StyledModal = styled('div')`
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  width: 300px;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;
export const HeaderText = styled('div')`
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

export const ModalContent = styled('div')`
  padding: 1rem;
`;

export const CloseButton = styled('button')`
  border: none;
  background: none;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  align-items: center;
  cursor: pointer;
`;

export const ButtonPanel = styled('div')`
  display: flex;
  justify-content: center;
`;

export const Message = styled('div')`
  margin-bottom: 1rem;
  text-align: left;
`;

export const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
`;

export const ModalTitle = styled(Title)`
  margin: 0.6rem 0;
`;
