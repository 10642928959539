const countries = [
  {
    name: 'United States',
    abbreviation: 'USA',
  },
  {
    name: 'Canada',
    abbreviation: 'CAN',
  },
];

export default countries;

export const countriesOptions = countries.map((country) => ({
  label: country.name,
  value: country.abbreviation,
}));
