import React, { useState } from 'react';
import { WarningShortView, WarningShortViewProps } from '@components/VehicleCondition/VehicleConditionItem';
import { ContentfulFile } from '@customTypes/ContentfulComponent';
import vcStyles from '../../VehicleCondition/VehicleCondition.module.css';

const LikeWarningItem = ({
  warningDescription,
  commonWarningIcon,
  label,
  moreLabel,
  modal,
  isAuthStatusError,
  toggleConfirmationModal,
  isConfirmationModalVisible,
}: WarningShortViewProps) => (
  <div className={vcStyles['VehicleCondition--short']}>
    <div className={vcStyles['VehicleCondition-wrapper--short']}>
      <WarningShortView
        commonWarningIcon={commonWarningIcon}
        modal={() => modal?.(isConfirmationModalVisible, toggleConfirmationModal, isAuthStatusError)}
        {...{
          warningDescription,
          label,
          moreLabel,
          isConfirmationModalVisible,
          toggleConfirmationModal,
        }}
      />
    </div>
  </div>
);

export const AuthForm = ({
  commonWarningIcon,
  label,
  moreLabel,
  warningDescription,
  isAuthStatusError,
  modal,
}: {
  commonWarningIcon?: ContentfulFile;
  label: string;
  moreLabel: string;
  warningDescription: string | undefined;
  isAuthStatusError?: boolean;
  modal: (
    isConfirmationModalVisible: boolean,
    toggle: React.Dispatch<React.SetStateAction<boolean>>,
  ) => React.ReactNode;
}) => {
  const [isConfirmationModalVisible, toggleConfirmationModal] = useState<boolean>(false);

  return (
    <LikeWarningItem
      {...{
        commonWarningIcon,
        modal,
        warningDescription,
        label,
        moreLabel,
        isAuthStatusError,
        isConfirmationModalVisible,
        toggleConfirmationModal,
      }}
    />
  );
};
