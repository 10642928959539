export enum FlowActions {
  proceed = 'PROCEED',
  return = 'RETURN',
  cancel = 'CANCEL',
  details = 'DETAILS',
  skip = 'SKIP',
}

export enum ComponentRoutes {
  infoPageEnrollment = 'infoPageEnrollment',
  infoPageSubscription = 'infoPageSubscription',
  manageTrialPackages = 'manageTrialPackages',
  managePromoPackages = 'managePromoPackages',
  managePromoCodePackages = 'managePromoCodePackages',
  managePaidPackages = 'managePaidPackages',
  manageAddOns = 'manageAddOns',
  userProfile = 'userProfile',
  termsAndConditions = 'termsAndConditions',
  payment = 'payment',
  reviewOrder = 'reviewOrder',
  reviewDowngradeOrder = 'reviewDowngradeOrder',
  confirmOrder = 'confirmOrder',
  checkIfProfileIsComplete = 'checkIfProfileIsComplete',
  checkIfEmailIsOnFile = 'checkIfEmailIsOnFile',
  error = 'error',
}

export type FlowMachineActions =
  | { type: FlowActions.proceed }
  | { type: FlowActions.return }
  | { type: FlowActions.cancel }
  | { type: FlowActions.details }
  | { type: FlowActions.skip };

export type MachineContext = {
  eligibilityCount: {
    promo: number;
    trial: number;
    addons: number;
  };
};
