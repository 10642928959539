import { usePaymentContext } from '@lib-payment/components/Payment/PaymentContext';
import { PaymentOptions } from '@lib-payment/Types';
import React, { useState } from 'react';
import PaymentMethods, { PaymentMethodsPops } from './PaymentMethods';

interface PaymentMethodsContainerProps extends PaymentMethodsPops {}

const PaymentMethodsContainer: React.FC<PaymentMethodsContainerProps> = ({ cancelEditing }) => {
  const { content, payPalClientId } = usePaymentContext();

  const [wayToPay, setWayToPay] = useState<PaymentOptions>(PaymentOptions.CREDIT_CARD);
  return (
    <PaymentMethods
      content={content}
      cancelEditing={cancelEditing}
      wayToPay={wayToPay}
      setWayToPay={setWayToPay}
      payPalClientId={payPalClientId}
    />
  );
};

export default PaymentMethodsContainer;
