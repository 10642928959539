import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Header.module.css';
import { FaExclamationTriangle, FaAngleDown, FaAngleUp } from 'react-icons/fa';

type StolenVehicleLocatorProps = {
  stolenVehicleLocator: {
    header: string;
    firstText: string;
    secondText: string;
    thirdText: string;
    url: string;
  };
};

export default function StolenVehicleLocator({ stolenVehicleLocator }: StolenVehicleLocatorProps) {
  const [isOpen, toggle] = useState<boolean>(false);
  const history = useHistory();
  const handleAnchorTag = () => {
    toggle((isOpen) => !isOpen);
    history.push(`${stolenVehicleLocator.url}`);
  };
  const handleOnToggle = () => {
    toggle((isOpen) => !isOpen);
  };
  return (
    <>
      <header className={clsx(styles['Header-main-header'])}>
        <div className={clsx(styles['Header-main-header-div'])}>
          <FaExclamationTriangle />
          <span className={clsx(styles['Header-main-header-span'])}>{stolenVehicleLocator?.header}</span>
          {!isOpen && <FaAngleDown onClick={handleOnToggle} className={clsx(styles['Header-main-header-arrow'])} />}
          {isOpen && <FaAngleUp onClick={handleOnToggle} className={clsx(styles['Header-main-header-arrow'])} />}
        </div>
      </header>
      {isOpen && (
        <div className={clsx(styles['Header-main-svl'])}>
          <div className={clsx(styles['Header-main-svl-open-div'])}>
            <p className={clsx(styles['Header-main-svl-text-one'])}>{stolenVehicleLocator?.header}</p>
            <span className={clsx(styles['Header-main-svl-text-two'])}>{stolenVehicleLocator?.firstText}</span>
            <div className={clsx(styles['Header-main-svl-text-two'])}>
              <span>{stolenVehicleLocator?.secondText}</span>
              <a className={clsx(styles['Header-main-open-anchor'])} onClick={handleAnchorTag}>
                {stolenVehicleLocator?.thirdText}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
