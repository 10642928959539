import { useInterpret, useSelector } from '@xstate/react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AnyEventObject,
  BaseActionObject,
  InterpreterFrom,
  ResolveTypegenMeta,
  ServiceMap,
  StateMachine,
  TypegenDisabled,
} from 'xstate';
import { SESSION_STORAGE_KEY, SubscriptionProps } from '@manageSubscription';
import { applicationFlowMachineInitialContext, createApplicationFlowMachine } from './flowMachine';
import { Middleware } from './flowMiddleware';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { ApplicationFlowEvent, FlowEventName } from './flowTypes';
import {
  EventDataBuilder,
  EventType,
  PageLoadStartedData,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';
import { ComponentRoutes } from './types';

export const FlowMachineContext = React.createContext<{ flowMachine: FlowMachineType }>(null);

export const ComponentRouteToAnalyticsCheckoutStepNameMap: { [key in ComponentRoutes]?: string } = {
  [ComponentRoutes.userProfile]: 'Account Register',
  [ComponentRoutes.manageTrialPackages]: 'Eligible Trial',
  [ComponentRoutes.termsAndConditions]: 'Term Acceptance',
  [ComponentRoutes.managePaidPackages]: 'Follow-On Package',
  [ComponentRoutes.payment]: 'Payment',
  [ComponentRoutes.reviewOrder]: 'Review',
  [ComponentRoutes.reviewDowngradeOrder]: 'Review Downgrade',
};

const componentRouteCustomPageStartedProps: { [key in ComponentRoutes]?: Partial<PageLoadStartedData['page']> } = {
  [ComponentRoutes.confirmOrder]: { pageName: 'manageAppDownload' },
};

const SkipClosingBraketsComponentRoutes = [ComponentRoutes.managePaidPackages];

class FlowMachineHistoryEvent {
  callbacks: ((context: AggregatedFlowContext, event: AnyEventObject) => void)[];
  lastRoute: string;
  constructor() {
    this.callbacks = [];
    this.lastRoute = '';
    this.subscribe((context, event) => {
      const route = event.data.componentRoute as ComponentRoutes;
      closeAnalyticsBracket();
      openAnalyticsBracket(context, componentRouteCustomPageStartedProps[route]);
    });
    this.subscribe((context, event) => {
      const route = event.data.componentRoute;
      if (!route) return;

      const checkoutStepName =
        ComponentRouteToAnalyticsCheckoutStepNameMap[route as ComponentRoutes] || route.substring(1);
      sendAnalyticsEvent(
        new EventDataBuilder(EventType.CheckoutStepEvent).withArgs({
          checkoutStep: checkoutStepName,
          products: context.flowSessionStorage.packageSubscriptions,
        }),
      );
      if (!SkipClosingBraketsComponentRoutes.includes(route)) closeAnalyticsBracket();
    });
    this.subscribe((_, event: AnyEventObject) => {
      sessionStorage.setItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE, event.data.componentRoute);
    });
  }
  subscribe(callback: (context: AggregatedFlowContext, event: AnyEventObject) => void) {
    this.callbacks.push(callback);
  }
  dispatch(context: AggregatedFlowContext, event: AnyEventObject) {
    const route = event.data.componentRoute;
    //prevent dispatching events for the same route multiple times
    if (this.lastRoute === route) return;
    this.lastRoute = route;
    this.callbacks.forEach((cb) => cb(context, event));
  }
}

export type FlowMachineType = InterpreterFrom<StateMachineType>;

type StateMachineType = StateMachine<
  AggregatedFlowContext,
  any,
  ApplicationFlowEvent,
  {
    value: any;
    context: AggregatedFlowContext;
  },
  BaseActionObject,
  ServiceMap,
  ResolveTypegenMeta<TypegenDisabled, AnyEventObject, BaseActionObject, ServiceMap>
>;

export const FlowMachineProvider: React.FC<{
  children: React.ReactNode;
  subscriptionProps: SubscriptionProps;
  context?: Partial<AggregatedFlowContext>;
}> = ({ children, context, subscriptionProps: { tenantId, location } }) => {
  const middleware = useMemo(() => new Middleware(tenantId, location), [tenantId, location]);
  const historyEvent = new FlowMachineHistoryEvent();
  const flowMachine = useInterpret(
    createApplicationFlowMachine(middleware).withContext({
      ...applicationFlowMachineInitialContext,
      ...context,
      flow: middleware.flow,
      skipToRoute: sessionStorage.getItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE),
    } as AggregatedFlowContext) as any,
  ) as FlowMachineType;
  flowMachine.onEvent((event) => {
    if (event.type === FlowEventName.UNSET_LOADING) {
      sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadEvent).withArgs());
    }
    if (event.type === FlowEventName.PUSH_HISTORY) {
      historyEvent.dispatch(flowMachine.getSnapshot().context, event);
    }
  });

  return <FlowMachineContext.Provider value={{ flowMachine }}>{children}</FlowMachineContext.Provider>;
};

export const useFlowMachine = () => {
  const { flowMachine } = React.useContext(FlowMachineContext);
  return flowMachine;
};

export const useFlowLocation = () => {
  const location = useLocation().pathname;
  return useMemo(() => location.substring(1), [location]);
};

export const useFlowMachineContextContent = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.content);
};

export const useFlowMachineContextSubscriptionProps = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.subscriptionProps);
};

export const useFlowMachineContext = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context);
};

export const useFlowMachineContextFlowStorage = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.flowSessionStorage);
};
