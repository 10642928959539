import React, { useEffect, useState } from 'react';
import { Content, Card, CardAction, IconSection } from '../../styled-components/globalStyles';
import { activateWifiSubscription, getWifiEligible } from '../../services/scn/wirelessService';
import { Header } from './styles';
import Image from '../../components/Image';
import Button from '../../components/Button';
import { APPROVED_TENANTIDS } from '../utils/constants';
import Spinner from '../../components/Spinner';
import { ContentfulAssets, SubscriptionProps, WifiWebContent } from '..';

interface AttWifiProps {
  wifiWebContent: WifiWebContent;
  subscriptionProps: SubscriptionProps;
  assets: ContentfulAssets;
}

const AttWifi: React.FC<AttWifiProps> = ({ wifiWebContent, subscriptionProps, assets }) => {
  const {
    headerLabel,
    descriptionLabel,
    activateLabel,
    headerSuccessLabel,
    descriptionSuccessLabel,
    activateSuccessLabel,
  } = wifiWebContent;

  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [isEligible, setIsEligible] = useState<boolean>(false);

  useEffect(() => {
    if (APPROVED_TENANTIDS.includes(subscriptionProps.tenantId)) {
      isWifiEligible();
    }
  }, [subscriptionProps]);

  const isWifiEligible = async () => {
    try {
      const { data } = await getWifiEligible(subscriptionProps);
      setIsEligible(data?.isVehicleWifiCapable && data.isWifiTrialEligible);
    } catch (error) {
      console.error('sxm wifi eligible error', error);
    }
  };

  const handleActivate = async () => {
    try {
      setApiStatus('loading');
      const { data } = await activateWifiSubscription(subscriptionProps);
      setApiStatus('success');
    } catch (error) {
      console.error('sxm wifi activate error', error);
      setApiStatus('error');
    }
  };

  return (
    <div>
      {isEligible && (
        <Card>
          <Header>{apiStatus !== 'success' ? headerLabel : headerSuccessLabel}</Header>
          <IconSection>
            <Image assets={assets} name="Wifi" size="45px" />
          </IconSection>
          <Content>{apiStatus !== 'success' ? descriptionLabel : descriptionSuccessLabel}</Content>
          <CardAction>
            {apiStatus === 'loading' ? (
              <Spinner />
            ) : (
              <Button
                disabled={['success'].includes(apiStatus)}
                data-testid="att-wifi-activate"
                onClick={handleActivate}
              >
                {apiStatus !== 'success' ? activateLabel : activateSuccessLabel}
              </Button>
            )}
          </CardAction>
        </Card>
      )}
    </div>
  );
};

export default AttWifi;
