import React from 'react';
import { Typography } from '@mui/material';
import { H1 } from '../../styled-components/globalStyles';
import Button from '../../components/Button';
import {
  BackgroundImage,
  FooterContainer,
  HeaderContainer,
  ColumnContainer,
  ServiceContainer,
  LogoContainer,
} from './InformationPageStyles';
import { Carousel, ContentfulFile, InformationPageContent, SubscriptionProps } from '../Subscription';

interface InformationPageEnrollmentProps {
  navigateForward: () => void;
  bgImageData: ContentfulFile;
  headerLogoData: ContentfulFile;
  carouselList: Carousel[];
  subscriptionTerms: string;
  informationPageEnrollmentContent: InformationPageContent;
  subscriptionProps?: SubscriptionProps;
}

const InformationPageEnrollment: React.FC<InformationPageEnrollmentProps> = ({
  navigateForward,
  bgImageData,
  headerLogoData,
  carouselList,
  subscriptionTerms,
  informationPageEnrollmentContent: {
    header = '',
    subHeader = '',
    headerFooter = '',
    bodyFooterTitle = '',
    bodyFooterSubtitle = '',
    continueButtonLabel = '',
  },
  subscriptionProps,
}) => {
  const url = bgImageData?.file.url;
  const { width, height } = bgImageData?.file.details?.image;
  return (
    <ColumnContainer>
      <BackgroundImage img={url} initialWidth={width} initialHeight={height}>
        <HeaderContainer>
          {headerLogoData && (
            <LogoContainer>
              <img src={headerLogoData.file.url} />
            </LogoContainer>
          )}
          <H1>{header}</H1>
          <p>{subHeader.replace('{{trialPeriod}}', subscriptionTerms)}</p>
          <Button fullWidth onClick={navigateForward} data-testid="continue">
            {continueButtonLabel}
          </Button>
          <Typography component="span" fontSize="0.875rem" lineHeight="1.5" color="white">
            {headerFooter}
          </Typography>
        </HeaderContainer>
      </BackgroundImage>

      {carouselList.map((item) => {
        const { name, title, subtitle, component } = item;

        return (
          <ServiceContainer key={name as string}>
            {title && (
              <Typography component="h2" fontSize="1.375rem" lineHeight="1.38" mb="1.5rem" sx={{ opacity: 0.89 }}>
                {title}
              </Typography>
            )}
            {subtitle && <p>{subtitle}</p>}

            {subscriptionProps.renderContentfulComponent({
              name: 'portalCarousel',
              content: [component],
              props: { showArrows: true },
            })}
          </ServiceContainer>
        );
      })}
      <FooterContainer>
        {bodyFooterTitle && <h2>{bodyFooterTitle}</h2>}
        {bodyFooterSubtitle && <p>{bodyFooterSubtitle.replace('{{trialPeriod}}', subscriptionTerms)}</p>}
        <Button
          fullWidth
          onClick={navigateForward}
          data-testid="continue"
          sx={(theme) => ({
            borderColor: theme.palette.portalTheme.navigationTextActive,
            color: theme.palette.common.white,
            '&:hover': {
              borderColor: theme.palette.portalTheme.navigationTextActive,
            },
          })}
        >
          {continueButtonLabel}
        </Button>
        <Typography
          component="span"
          fontSize="0.875rem"
          color="white"
          sx={(theme) => ({ color: theme.palette.portalTheme.navigationTextColor })}
        >
          {headerFooter}
        </Typography>
      </FooterContainer>
    </ColumnContainer>
  );
};

export default InformationPageEnrollment;
