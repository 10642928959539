import { isEntitledForMarketingPreferences } from '@api/requests/userInfo';
import {
  ReadOptInOptions,
  readOptIn,
  createNewMarketingPreference,
  updateCurrentMarketingPreference,
} from '@api/requests/marketingPreference';

export type HandleOptInOptions = ReadOptInOptions & {
  email: string;
  newOptIn: boolean;
};

export const handleOptIn = async ({ newOptIn, ...options }: HandleOptInOptions) => {
  const isEntitled = await isEntitledForMarketingPreferences(options.accessToken);

  if (isEntitled) {
    try {
      const { isEmpty, currentOptIn } = await readOptIn(options);
      const payload = { ...options, phone: options.smsNumber, optIn: newOptIn };

      if (isEmpty) {
        await createNewMarketingPreference(payload);
      } else if (currentOptIn !== newOptIn) {
        await updateCurrentMarketingPreference(payload);
      }
    } catch (_) {
      console.error('There is an error during creation/update of opt-in');
    }
  }
};
