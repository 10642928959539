import { send } from 'xstate';
import { ActionTypes } from '../../../flowSessionStorage';
import { FlowEventName } from '../../../flowTypes';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig, ReviewDowngradeOrderFlowFlowContext } from '../Types';

export const reviewDowngradeOrderFlow: ComponentFlowStateConfig<{}> = () => ({
  initial: 'pre',
  id: ComponentRoutes.reviewDowngradeOrder,
  states: {
    pre: {
      entry: 'dispatchDowngradeOnCancellation',
      always: ['idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.reviewDowngradeOrder },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: 'navigateForward',
        [FlowEventName.NAVIGATE_BACK]: { actions: 'navigateBack' },
      },
    },
    navigateForward: {
      entry: ['navigateForward'],
    },
  },
});

export const actions: ComponentActions<ReviewDowngradeOrderFlowFlowContext> = {
  dispatchDowngradeOnCancellation: send((context) => {
    const { subscribedPackages } = context.flowSessionStorage;
    const { pkgIdsToRemove } = context.subscriptionProps;
    return {
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: ActionTypes.AddSubscribedPackages,
        payload: subscribedPackages.filter((pkg) => pkgIdsToRemove?.includes(pkg.packageId)),
      },
    };
  }),
};
