// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OlxlmToysPEAKvgpHQaV > *:not(:first-child) {
  margin-top: 25px;
}

.adpCOvlo8avPc77Wbpmt {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.modules.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".root > *:not(:first-child) {\n  margin-top: 25px;\n}\n\n.title {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OlxlmToysPEAKvgpHQaV`,
	"title": `adpCOvlo8avPc77Wbpmt`
};
export default ___CSS_LOADER_EXPORT___;
