// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RichTextContainer img {
  max-width: 50%;
}

.RichTextContainer p {
  font-size: var(--theme-paragraph-font-size);
}

.RichTextContainer h2 {
  font-size: var(--theme-header-font-size);
}

.Card-container .RichTextContainer.text-center img {
  max-width: 50%;
}

.one-level .contentful-rich-text {
  display: flex;
  align-items: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/RichTextContainer/RichTextContainer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".RichTextContainer img {\n  max-width: 50%;\n}\n\n.RichTextContainer p {\n  font-size: var(--theme-paragraph-font-size);\n}\n\n.RichTextContainer h2 {\n  font-size: var(--theme-header-font-size);\n}\n\n.Card-container .RichTextContainer.text-center img {\n  max-width: 50%;\n}\n\n.one-level .contentful-rich-text {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
