import { styled } from '@mui/material';

type InputProps = {
  error: boolean;
};

export const InputLabelContainer = styled('label')`
  display: inline-flex;
  z-index: 2;
  line-height: 1rem;
  padding: 0 0.5rem;
  position: relative;
  left: 10px;
  top: 0.5rem;
  color: black;
  background-color: #fff;
  font-size: 1rem;
`;

export const InputLabelStar = styled('p')`
  &:before {
    content: '*';
    color: red;
  }
`;

export const Input = styled('input')<InputProps>`
  border: 1px solid transparent;
  display: block;
  margin-bottom: 2px;
  border-radius: 3px;
  text-indent: 5px;
  width: 100%;
  height: 35px;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  &:focus {
    border-color: black;
    outline: 0;
  }

  ${(props) => (props.error ? `border-color: red` : `border-color: rgb(190, 190, 190);`)}
`;

export const ErrorText = styled('p')`
  color: red;
  margin: 0;
`;
