import React, { useState, useMemo } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import clsx from 'clsx';
import * as yup from 'yup';

import { useApi } from '@api';
import { APIErrorResponse } from '@cv/portal-common-lib/ajax/models';
import { ContentfulImage } from '@cv/webframework-react-components';
import { AccountType, Currency } from '@cv/portal-idm-lib/user/user-create/enums';
import { UserCreatePayload } from '@cv/portal-idm-lib/user/user-create/models';
import { AccountStatus } from '@cv/portal-idm-lib/enums';
import { FormikInputField } from '@components/FormikFields';
import { getPasswordValidation } from '@utils/passwordRules';
import { LoaderBackdrop } from '@components/Loader';
import { formValidation } from './constants';

import { Button } from '@components-lib';
import ModalContainer from '@components/ModalContainer';
import PasswordTooltipContent from '@components/PasswordTooltipContent';
import ContentContainer from './ContentContainer';
import RichTextContainer from '../RichTextContainer';
import usePreferencesSelector from '@redux/selectors/preferences';

import useLabels, { UseLabelsReturn } from '@hooks/useLabels';
import { ServerLabel } from '@utils/labels';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import failure from '@assets/failure_icon.svg';

import styles from './RegistrationForm.module.css';
import config from '@config/config';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

enum Status {
  Idle = 'idle',
  Loading = 'loading',
}

enum Step {
  Registration = 'registration',
  Success = 'success',
}

type Values = {
  email: string;
  password: string;
  name: string;
  surname: string;
  secondSurname: string;
};

const initialValues = {
  email: '',
  password: '',
  name: '',
  surname: '',
  secondSurname: '',
};

type RegistrationFormLabels = ReturnType<UseLabelsReturn['getAllPrimaries']> & {
  currency?: Currency;
};

export type RegistrationFormProps = {
  titleImage?: object;
  disclaimer: object;
  title: string;
  successTitle: string;
  successDescription?: string;
  successButtonLabel: string;
  labels: ServerLabel[];
  passwordLabels: ContentfulComponent;
};

const RegistrationForm = ({
  titleImage,
  disclaimer,
  title,
  successTitle,
  successDescription = '',
  successButtonLabel,
  labels = [],
  passwordLabels,
}: RegistrationFormProps): JSX.Element => {
  const api = useApi();
  const history = useHistory();
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [step, setStep] = useState<Step>(Step.Registration);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const preferences = usePreferencesSelector();

  const {
    currency,
    billingPostalAddress1,
    billingPostalAddress2,
    billingCity,
    billingStateProvince,
    billingPostalCode,
    billingCountry,
  }: RegistrationFormLabels = useLabels(labels).getAllPrimaries();

  const onRedirectToLogin = () => {
    history.push('/login');
  };

  const isUserExistsError = (error: Error & { response?: { data?: APIErrorResponse } }) => {
    if (!error.response || !error.response.data) {
      return false;
    }
    const { code, detail = {} } = error.response.data;
    const failedPolicyRequirements = detail?.failedPolicyRequirements?.[0];
    const policyRequirement = failedPolicyRequirements?.policyRequirements?.[0]?.policyRequirement;

    return code === 422 && policyRequirement === 'UNIQUE' && failedPolicyRequirements?.property?.includes('username');
  };

  const onSubmit = async (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      setStatus(Status.Loading);

      const payload: UserCreatePayload = {
        tenantId: config.getTenantId(),
        accountStatus: AccountStatus.active,
        accountType: AccountType.Person,
        email: values.email,
        givenName: values.name,
        surname: values.surname,
        surname2: values.secondSurname,
        userName: values.email,
        password: values.password,
        currency,
        billingPostalAddress1,
        billingPostalAddress2,
        billingCity,
        billingStateProvince,
        billingPostalCode,
        billingCountry,
      };

      const configLanguage = config.getOemValue('DEFAULT_LANGUAGE');
      if (configLanguage) {
        payload.preferredLanguage = configLanguage;
      }

      // Create user account
      const { data } = await api.createAccount(payload);

      // Link newly created user account with default role
      await api.userRoleLink({ userId: data._id, tenantId: data.tenantId });

      setStatus(Status.Idle);
      setStep(Step.Success);
      sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: 'registration form' }));
    } catch (e) {
      if (isUserExistsError(e)) {
        // TODO: set specific error message
      }

      sendAnalyticsEvent(
        new EventDataBuilder(EventType.FormFailedEvent).withArgs({
          name: 'registration form',
          error: (e as Error).message,
        }),
      );
      setIsModalVisible(true);
      setStatus(Status.Idle);
    }
    setSubmitting(false);
  };

  const isRegistrationStep = step === Step.Registration;

  const updateServiceNameByTenant = (description: string) => {
    return description && description.replace(/{{(.+?)}}/g, preferences?.serviceName || '');
  };

  const validationSchema = useMemo(() => {
    const { password } = getPasswordValidation(passwordLabels.content as ServerLabel[]);
    return yup.object().shape({ ...formValidation, password });
  }, [passwordLabels]);

  return (
    <>
      {status === Status.Loading && <LoaderBackdrop />}

      <ContentContainer>
        <div className={clsx(styles.Card, { [styles['Card-success']]: !isRegistrationStep })}>
          <div className={styles['Title-container']}>
            {titleImage && (
              <div className={styles['Card-header']}>
                <ContentfulImage imageData={titleImage} />

                {!isRegistrationStep && (
                  <span className={styles['Avatar-check']}>
                    <FaCheckCircle />
                  </span>
                )}
              </div>
            )}

            <h1 className={styles['Title']}>{isRegistrationStep ? title : successTitle}</h1>
          </div>

          <div className={styles['Disclaimer']}>
            {isRegistrationStep ? (
              <RichTextContainer content={disclaimer} />
            ) : (
              updateServiceNameByTenant(successDescription)
            )}
          </div>

          {isRegistrationStep && (
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting);
              }}
              validationSchema={validationSchema}
            >
              {(props: FormikProps<Values>) => (
                <>
                  <Form onSubmit={props.handleSubmit} className={styles['Form']}>
                    <FormikInputField
                      label="Correo Electrónico"
                      helpCallout="Solo letras, apóstrofe (') y guión (-), números, guión bajo (_) y letras minúsculas"
                      name="email"
                    />
                    <FormikInputField
                      label="Contraseña"
                      helpCallout={<PasswordTooltipContent tooltipLabels={passwordLabels} />}
                      name="password"
                      type="password"
                    />
                    <FormikInputField label="Primer Nombre" name="name" />
                    <FormikInputField label="Apellido Paterno" name="surname" />
                    <FormikInputField label="Apellido Materno" name="secondSurname" />
                  </Form>
                  <div className={styles['Actions']}>
                    <Button variant="outlined" className={styles['Button']} onClick={onRedirectToLogin}>
                      CANCELAR
                    </Button>
                    <Button
                      variant="contained"
                      className={styles['Button']}
                      disabled={!props.dirty || !props.isValid || props.isSubmitting}
                      onClick={props.submitForm}
                    >
                      ENVIAR
                    </Button>
                  </div>
                </>
              )}
            </Formik>
          )}

          {!isRegistrationStep && (
            <Button variant="contained" className={styles['Button']} onClick={onRedirectToLogin}>
              {successButtonLabel}
            </Button>
          )}
        </div>
      </ContentContainer>

      {isModalVisible && (
        <ModalContainer
          show={isModalVisible}
          header={{ text: '', position: 'center', showXButton: false }}
          onCloseHandler={() => setIsModalVisible(false)}
          position="center"
          height="auto"
        >
          <>
            <div className={styles['ModalHeader']}>
              <img src={failure} /> <span>Error</span>
            </div>
            <div className={styles['ModalContent']}>
              <p>Parece que hay un problema. Llame a atención al cliente al {preferences.customerCareNumber}.</p>
            </div>
            <div className={styles['Actions']}>
              <Button variant="contained" className={styles['Button']} onClick={() => setIsModalVisible(false)}>
                OK
              </Button>
            </div>
          </>
        </ModalContainer>
      )}
    </>
  );
};

export default RegistrationForm;
