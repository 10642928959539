import UserProfileContainer from '@manageSubscription/UserProfile/MultiBrand/UserProfileContainer';
import { ManagePaidPackagesContainer, ManagePromoPackagesContainer } from '@manageSubscription/ManagePackages';
import { userProfileFlowSubaru } from './UserProfile';
import { ComponentRoutes } from '../../../types';
import { managePromoPackagesFlow } from './promoPackages';
import { checkIfProfileIsComplete } from '../honda/CheckIfProfileIsComplete';
import { managePaidPackagesFlow } from './ManagePaidPackages';
import PaymentWrapperContainer from '@manageSubscription/Payment/PaymentContainer';
import { paymentFlow } from './Payment';

export const SubaruComponentFlowMap = {
  [ComponentRoutes.userProfile]: { component: UserProfileContainer, flow: userProfileFlowSubaru },
  [ComponentRoutes.managePromoPackages]: {
    component: ManagePromoPackagesContainer,
    flow: managePromoPackagesFlow,
  },
  [ComponentRoutes.managePaidPackages]: {
    component: ManagePaidPackagesContainer,
    flow: managePaidPackagesFlow,
  },
  [ComponentRoutes.payment]: { component: PaymentWrapperContainer, flow: paymentFlow },
  [ComponentRoutes.checkIfProfileIsComplete]: { flow: checkIfProfileIsComplete },
};
