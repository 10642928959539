import React, { useState } from 'react';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { FaCheck } from 'react-icons/fa';

import { timeOptions } from '../constants';
import { FormikInputField } from '@components/FormikFields';
import { Button } from '@components-lib';
import ControlLabel from '@components/ControlLabel';
import InputSwitch from '@components/InputSwitch';
import RoundToggleButton from '@components/RoundToggleButton';
import Select from '@components/InputSelect';
import { useAnalytics } from '@cv/webframework-react-components';

import styles from './Forms.module.css';
import stylesInput from '@components/Input/Input.module.css';
import DaysSelectFormikField from '@components/DaysSelect/DaysSelectFormikField';

type Values = {
  name: string;
  dayOfTheWeek: string[];
  inVehicleWarning: boolean;
  allDay: boolean;
  [key: number]: string;
};

type CurfewFormProps = {
  label?: string;
  description?: string;
  switchLabel?: string;
  onFormClose?: () => void;
  onFormConfirm?: (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setStatus: (status?: object | string) => void,
  ) => void;
  initialValues: Values;
  placeholder?: string;
  tabDescription: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  dayLabel: string;
  daysSelectDayEndLabel: string;
  daysSelectDayStartLabel: string;
  weekLabel: string;
  formNameError: string;
  analyticsEventName: {
    curfewLimitAnalyticsEventName: string;
    saveAnalyticsEventName: string;
    cancelAnalyticsEventName: string;
  };
};

export default function CurfewForm({
  label = '',
  switchLabel,
  onFormClose,
  onFormConfirm,
  initialValues,
  placeholder,
  tabDescription,
  cancelButtonLabel,
  confirmButtonLabel,
  dayLabel,
  daysSelectDayEndLabel,
  daysSelectDayStartLabel,
  weekLabel,
  formNameError,
  analyticsEventName,
}: CurfewFormProps) {
  const { trackEvent } = useAnalytics();

  const [selectedHours, setSelectedHours] = useState(false);
  const [curfewNameError, setCurfewNameError] = useState(false);
  const [curfewName, setCurfewName] = useState(initialValues.name || '');

  const appendValues = (values: FormikValues) => ({
    ...values,
    allDay: selectedHours,
    name: curfewName,
  });

  return (
    <Formik
      key={label}
      initialValues={initialValues}
      validateOnMount
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        trackEvent(analyticsEventName.saveAnalyticsEventName);
        if (curfewName) {
          onFormConfirm && onFormConfirm(appendValues(values), setSubmitting, setStatus);
        } else {
          setCurfewNameError(true);
        }
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form onSubmit={props.handleSubmit} className={styles['form-curfew']}>
          <div className={styles['form-curfew-field']}>
            <FormikInputField
              name="name"
              label={label}
              placeholder={placeholder}
              className={`${stylesInput['input-dark']}`}
              onChange={(e) => {
                setCurfewName(e.target.value);
                setCurfewNameError(false);
              }}
              onFocus={() => trackEvent(analyticsEventName.curfewLimitAnalyticsEventName)}
              value={curfewName}
            />
            {curfewNameError && <p className={styles['name-error']}>{formNameError}</p>}
          </div>
          <div className={styles['form-curfew-title']}>{tabDescription}</div>
          <FormikInputField name="dayOfTheWeek" InputComponent={DaysSelectFormikField} checkboxLabel={weekLabel} />
          <div className={styles['form-curfew-row']}>
            <div className={styles['form-curfew-row-item']}>
              <FormikInputField
                name="startTime"
                label={daysSelectDayStartLabel}
                InputComponent={Select}
                nativeDropdown={true}
                onChange={() => {}}
                options={timeOptions}
                disabled={selectedHours}
              />
              <FormikInputField
                name="endTime"
                label={daysSelectDayEndLabel}
                InputComponent={Select}
                nativeDropdown={true}
                onChange={() => {}}
                options={timeOptions}
                disabled={selectedHours}
              />
            </div>
            <ControlLabel
              label={dayLabel}
              control={
                <RoundToggleButton
                  checked={selectedHours}
                  onToggle={setSelectedHours}
                  classes={{ input: styles['toggle-all-button'] }}
                >
                  <FaCheck />
                </RoundToggleButton>
              }
            />
          </div>

          <InputSwitch
            label={switchLabel}
            defaultChecked={initialValues.inVehicleWarning}
            className={styles['input-switch--bottom']}
            type="wide"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setFieldValue('inVehicleWarning', event.target.checked)
            }
          />

          <div className={styles['button-container']}>
            <Button
              variant="outlined"
              onClick={() => {
                trackEvent(analyticsEventName.cancelAnalyticsEventName);
                if (onFormClose) onFormClose();
              }}
              type="button"
            >
              {cancelButtonLabel}
            </Button>
            <Button variant="contained" type="submit">
              {confirmButtonLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
