import { useSelector } from 'react-redux';

type SettingsReducer = { settingsReducer: { locale: string } };

const getLocale = (state: SettingsReducer) => {
  return state.settingsReducer.locale;
};

const useLocaleSelector = (): string => {
  return useSelector(getLocale);
};

export default useLocaleSelector;
