import { styled } from '@mui/material';
import { breakpoint } from '@styled-components/globalStyles';
import BaseButton from '@lib-components/Button';
import TextField from '@lib-components/TextField';

export const SecurityWrapper = styled('form')`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;
export const InputWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  width: 80%;
  @media only screen and ${breakpoint.device.sm} {
    width: 50%;
    min-width: 250px;
  }
`;
export const InputItem = styled(TextField)`
  width: 55px;
  height: 50px;
  font-size: 17px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;
export const ErrorMessageContainer = styled('div')`
  display: flex;
  justify-content: inherit;
  @media only screen and ${breakpoint.device.sm} {
    justify-content: flex-start;
  }
`;
export const ErrorMessage = styled('div')`
  margin-top: 20px;
  color: red;
  width: 80%;
  text-align: center;
  @media only screen and ${breakpoint.device.sm} {
    width: 100%;
  }
`;
export const BodyText = styled('p')`
  width: 100%;
  @media only screen and ${breakpoint.device.sm} {
    width: 75%;
  }
`;
export const TitleWrapper = styled('div')`
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 2rem 0 0.5rem;
`;
export const Button = styled(BaseButton)`
  width: 100%;
  @media only screen and ${breakpoint.device.sm} {
    width: ${(props) => (props.variant === 'contained' ? '20%' : 'auto')};
  }
`;
