export const GET_POINTS_OF_INTEREST = 'GET_POINTS_OF_INTEREST';
export const SET_POINTS_OF_INTEREST = 'SET_POINTS_OF_INTEREST';
export const SET_VEHICLE_LOCATION = 'SET_VEHICLE_LOCATION';
export const SET_SELECTED_POINT_OF_INTEREST = 'SET_SELECTED_POINT_OF_INTEREST';
export const GET_FAVORITES = 'GET_FAVORITES';
export const SET_FAVORITES = 'SET_FAVORITES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';
export const GET_SENT_TO_CAR = 'GET_SENT_TO_CAR';
export const SET_SENT_TO_CAR = 'SET_SENT_TO_CAR';
export const REMOVE_FROM_SENT_TO_CAR = 'REMOVE_FROM_SENT_TO_CAR';
export const SET_FOLDERS = 'SET_FOLDERS';
export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const SET_ROUTE_SUMMARY = 'SET_ROUTE_SUMMARY';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_MAP = 'SET_MAP';
export const SET_TIMER_WINDOW = 'SET_TIMER_WINDOW';
export const SET_MAP_TIMER_VALUE = 'SET_MAP_TIMER_VALUE';
export const SET_VEHICLE_LOCATION_CLICKED = 'SET_VEHICLE_LOCATION_CLICKED';

export const DOOR_ID = '1';
export const DOOR_ID_ALL = 'ALL';
export const FAIL = 'FAIL';
export const SET_JOURNEYS = 'SET_JOURNEYS';
export const REMOVE_JOURNEY = 'REMOVE_JOURNEY';
export const RECENTS_FOLDER = 'RECENTS';
export const MY_DESTINATIONS_FOLDER = 'MY DESTINATIONS';

export const GEOFENCE_RADIUS_MIN = { value: 0.25, unit: 'MI' as const };
export const GEOFENCE_RADIUS_MAX = { value: 93, unit: 'MI' as const };

export const myCarFinderLabels = ['My Car Finder', 'Parked Car Finder', 'Localizar mi vehículo'];

export const Label_Message = {
  ContactingVehicle: 'contactingVehicle',
  InitiatingService: 'initiatingService',
  ServiceCompleted: 'serviceCompleted',
  UnabletoContactVehicle: 'unabletoContactVehicle',
  Unabletoconnecttonetwork: 'unabletoconnecttonetwork',
};

export enum RemoteCommand {
  DOOR_LOCK = 'doorLock',
  DOOR_UNLOCK = 'doorUnlock',
  HORN_LIGHT = 'hornLight',
  LIGHT = 'light',
  START_STOP_ENGINE = 'startStopEngine',
  STOP_ENGINE = 'stopEngine',
}

export const lockStatusValues = ['unlocked', 'Desbloqueado', 'locked', 'Bloqueado'];
