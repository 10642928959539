import { isEqual } from 'lodash';
import React from 'react';
import { initialize } from '../../configs';
import { invalidateCache } from '../utils';
import Subscription, { ManageSubscriptionProps } from './Subscription';

export const ManageSubscription = React.memo(
  (props: ManageSubscriptionProps) => {
    initialize(props);

    return <Subscription {...props} />;
  },
  (prevProps, nextProps) => {
    const isSameVehicle =
      prevProps.subscriptionProps.vehicleDetails.vehicleId === nextProps.subscriptionProps.vehicleDetails.vehicleId;

    if (!isSameVehicle) {
      invalidateCache();
    }

    const isSameLocale = prevProps.subscriptionProps.locale === nextProps.subscriptionProps.locale;

    const isSameContent = isEqual(prevProps.content, nextProps.content);

    const isSameShouldMatchTrialWithCCRequired =
      prevProps.subscriptionProps.shouldMatchTrialWithCCRequired ===
      nextProps.subscriptionProps.shouldMatchTrialWithCCRequired;

    return isSameVehicle && isSameLocale && isSameContent && isSameShouldMatchTrialWithCCRequired;
  },
);
