import React, { useEffect } from 'react';
import { EligiblePackageInfo } from '../../Types';
import { styled } from '@mui/material';
import Button from '../../../components/Button';
import { IconSection, PackageHeader, PackageDescription, PackageTitle, PackageWrapper, Pricing } from './styles';
import { hasPackageAdded, isTrialPackage } from '../../utils';
import Image from '../../../components/Image';
import { translateToEN } from '../../utils/translations';
import { PackagePrice } from '../Price/PackagePrice';
import { useFlowMachine, useFlowMachineContext, useFlowMachineContextFlowStorage } from '../../../Router/RouterContext';
import { FlowEventName } from '../../../Router/flowTypes';
import { ActionTypes } from '../../../Router/flowSessionStorage';

const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 6rem;
  height: 1.6rem;
  padding: 0.3rem;
  margin-left: auto;
  margin-top: 0.7rem;
  text-transform: none;
`;

export interface AddOnPackageProps {
  pkg: EligiblePackageInfo;
}

export const AddOnPackage = ({ pkg }: AddOnPackageProps) => {
  const { packageName, marketingName, longDescription, variant } = pkg;
  const { send } = useFlowMachine();
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();
  const {
    content: {
      commonWebContent: { addButtonLabel, addedButtonLabel, currencyLabel, shouldIncludeTax },
      packagesWebContent: {
        eligiblePackagesWebContent: { perTermsLabel },
      },
      assets,
    },
    subscriptionProps: {
      vehicleDetails: { make },
      locale,
      tenantId,
    },
  } = useFlowMachineContext();

  useEffect(() => {
    // TrialPackage AddOn should be added by default
    if (isTrialPackage(pkg.variant)) addAnAddOn(pkg);
  }, []);

  const addAnAddOn = (addOnPackage) => {
    if (hasPackageAdded(packageSubscriptions, addOnPackage)) return;
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddPackage, payload: addOnPackage },
    });
  };

  const onAddOnSelect = (selectedAddOn) => {
    // TODO: Fix useEffect() to call after the state is update to date onClick of the button
    const isPackageAdded = hasPackageAdded(packageSubscriptions, selectedAddOn);
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: isPackageAdded ? ActionTypes.RemovePackage : ActionTypes.AddPackage,
        payload: selectedAddOn,
      },
    });
  };

  const buttonLabel = () => {
    const isPackageAdded = hasPackageAdded(packageSubscriptions, pkg);
    return isPackageAdded ? (
      <>
        <Image assets={assets} name="CheckCircle" size="18px" />
        {addedButtonLabel}
      </>
    ) : (
      addButtonLabel
    );
  };

  const getProductName = () => {
    return translateToEN(packageName, locale, tenantId);
  };

  const term = variant.initialTerm >= 12 ? 12 : variant.initialTerm;

  return (
    <PackageWrapper>
      <PackageHeader>
        <IconSection>
          <Image assets={assets} name={getProductName()} prefix={make} />
        </IconSection>
        <PackageTitle>{marketingName}</PackageTitle>
        <ToggleButton data-testid="select-addon-button" variant="outlined" onClick={() => onAddOnSelect(pkg)}>
          {buttonLabel()}
        </ToggleButton>
      </PackageHeader>
      <PackageDescription>{longDescription}</PackageDescription>
      <Pricing>
        <PackagePrice
          amountWithoutTax={variant.actualPrice}
          taxTotal={variant.taxTotal}
          term={term}
          perTermsLabel={perTermsLabel}
          currencyLabel={currencyLabel}
          shouldIncludeTax={shouldIncludeTax}
        />
      </Pricing>
    </PackageWrapper>
  );
};
