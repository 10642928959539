import React, { ReactNode } from 'react';
import styles from './SubscriptionOverview.module.css';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import useExpireRenewText from '@hooks/useExpireRenewText';
import useLocalDate from '@hooks/useLocalDate';
import isPackageToShow from '@utils/isPackageToShow';
import PackageSubscriptionType, { PackageSubscriptionTypeProps } from './PackageSubscriptionType';

export type SubscriptionOverviewProps = PackageSubscriptionTypeProps & {
  data: {
    defaultPackages: any[];
    vehicle: {
      name: string;
      vin: string;
    };
    subscribedPackages: SubscriptionPackage[];
    defaultPkg: SubscriptionPackage;
  };
  vehicleInfo: ReactNode;
  labelExpiry: string;
  renewLabel: string;
  packagePrefixLabel: string;
  labelTrialExpiry: string;
  apiConnector: {
    api: string;
  };
  componentType: string;
  paidSubscriptionLabel: string;
  trialSubscriptionLabel: string;
  renewsAnnuallyLabel: string;
  renewsMonthlyLabel: string;
  endOfTermLabel: string;
  promoTermLabel: string;
  hideDefaultPackageExpiration?: boolean;
};

const DefaultPackage = ({
  defaultPackage,
  packagePrefixLabel,
  labelTrialExpiry,
  trialSubscriptionLabel,
}: {
  defaultPackage: SubscriptionPackage;
  packagePrefixLabel: string;
  labelTrialExpiry: string;
  trialSubscriptionLabel: string;
}) => {
  const formatDate = useLocalDate();
  const { id, marketingName, endDate } = defaultPackage;
  return (
    <div key={id} className={styles['SubscriptionOverview-service']}>
      <div className={styles['SubscriptionOverview-service-name']}>
        {packagePrefixLabel}
        {marketingName}
      </div>
      <PackageSubscriptionType subscribedPackage={defaultPackage} />
      <div className={styles['SubscriptionOverview-service-expiry']}>
        {`${labelTrialExpiry} ${formatDate(endDate)}`}
      </div>
    </div>
  );
};

function SubscriptionOverview({
  data,
  labelExpiry: expiresLabel,
  renewLabel,
  packagePrefixLabel,
  labelTrialExpiry,
  vehicleInfo,
  paidSubscriptionLabel,
  trialSubscriptionLabel,
  renewsAnnuallyLabel,
  renewsMonthlyLabel,
  endOfTermLabel,
  promoTermLabel,
  apiConnector,
  componentType,
  hideDefaultPackageExpiration,
  ...restProps
}: SubscriptionOverviewProps): JSX.Element {
  const expireRenewText = useExpireRenewText();
  const subscribedPackages = data?.subscribedPackages.filter((anyPackage: SubscriptionPackage) =>
    isPackageToShow(anyPackage),
  );

  const renderPackageInfo = (subscribedPackage: SubscriptionPackage) => {
    const { marketingName, subscriptionPackageId } = subscribedPackage;
    const text = expireRenewText({ ...subscribedPackage, renewLabel, expiresLabel });

    return (
      <div key={subscriptionPackageId} className={styles['SubscriptionOverview-service']}>
        <div className={styles['SubscriptionOverview-service-name']}>
          {packagePrefixLabel}
          {marketingName}
        </div>
        <PackageSubscriptionType subscribedPackage={subscribedPackage} />
        <div className={styles['SubscriptionOverview-service-expiry']}>{text}</div>
      </div>
    );
  };
  return (
    <div className={styles.SubscriptionOverview} {...restProps}>
      {vehicleInfo}
      {subscribedPackages.map(renderPackageInfo)}
      {!subscribedPackages.some((sPkg: SubscriptionPackage) => sPkg.packageType === 'DEFAULT') &&
        labelTrialExpiry &&
        data.defaultPackages
          ?.filter((pkg) => !pkg.productCode?.includes('DEFAULT_1') && !pkg.productCode?.includes('DEFAULT_3'))
          .map((pkg: SubscriptionPackage) => (
            <>
              <DefaultPackage
                trialSubscriptionLabel={trialSubscriptionLabel}
                defaultPackage={pkg}
                packagePrefixLabel={packagePrefixLabel}
                labelTrialExpiry={labelTrialExpiry}
              />
            </>
          ))}
    </div>
  );
}

export default SubscriptionOverview;
