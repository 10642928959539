import React, { ReactNode } from 'react';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { useFlowMachineContextContent } from '@lib-appRouter/RouterContext';
import PackageProduct from '@manageSubscription/Discount/PackageDetails/PackageProduct';
import ServiceList from '@lib-components/ServiceList';

export type PackageProductListProps = {
  products?: Product[];
  servicesTitle: ReactNode;
};

const PackageProductList = ({ products, servicesTitle }: PackageProductListProps) => {
  const {
    informationPageSubscriptionContent: { servicesFetchError },
    commonWebContent: { productsToHide = [] },
  } = useFlowMachineContextContent();

  return (
    <>
      {products
        ?.filter(({ productName }) => !productsToHide.includes(productName))
        .map((product) => (
          <div key={product.id}>
            <PackageProduct product={product} />
            <ServiceList expanded title={servicesTitle} products={[product]} />
          </div>
        )) || servicesFetchError}
    </>
  );
};

export default PackageProductList;
