import React from 'react';
import { PackageSectionHeader, PackageSectionSubHeader } from './styles';

interface PackageSectionProps {
  header: string;
  subHeader: string;
  children?: React.ReactNode;
}

const PackageSection = ({ header, subHeader, children }: PackageSectionProps) => {
  return (
    <React.Fragment>
      <PackageSectionHeader>{header}</PackageSectionHeader>
      <PackageSectionSubHeader>{subHeader}</PackageSectionSubHeader>
      {children}
    </React.Fragment>
  );
};

export default PackageSection;
