export enum RRS_ACTIONS {
  SET_RRS_DATA = 'SET_RRS_DATA',
}

export type RRSData = {
  channel: string;
  email: string;
  lang: string;
  make: string;
  model: string;
  phoneNumber: string;
  source: string;
  templateId: string;
  tenantId: string;
  vehicleId: string;
  vin: string;
};
