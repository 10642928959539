import React from 'react';
import { ContentMap } from '../..';
import { HSpace } from '../../../styled-components/globalStyles';
import { priceTotal } from '../../utils';

interface PackagePriceProps {
  amountWithoutTax: number;
  taxTotal?: number;
  term?: number;
  perTermsLabel: ContentMap;
  currencyLabel: string;
  shouldIncludeTax: boolean;
}

export const PackagePrice = ({
  amountWithoutTax,
  taxTotal = 0.0,
  term,
  perTermsLabel,
  currencyLabel,
  shouldIncludeTax,
}: PackagePriceProps) => {
  const getAmount = () => {
    return priceTotal(amountWithoutTax, shouldIncludeTax && taxTotal);
  };

  return (
    <>
      {currencyLabel}
      {getAmount()}
      {term && (
        <>
          <HSpace />/{perTermsLabel[String(term)]}
        </>
      )}
    </>
  );
};
