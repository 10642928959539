import { Modify } from '@cv/portal-common-lib';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { createGeofence, deleteGeofence, getGeofence, updateGeofence } from '@cv/portal-rts-lib/geofences';
import {
  CreateGeofenceRequest,
  DeleteGeofenceRequest,
  GetGeofenceRequest,
  UpdateGeofenceRequest,
  CreateGeofencePayload,
  UpdateGeofencePayload,
} from '@cv/portal-rts-lib/geofences/models';

type GeofenceRequestProps = {
  vehicleId: string;
  accessToken: string;
  idToken: string;
};

type CreateGeofenceRequestProps = Modify<
  GeofenceRequestProps,
  {
    data: CreateGeofencePayload;
  }
>;

type UpdateGeofenceRequestProps = Modify<
  GeofenceRequestProps,
  {
    data: UpdateGeofencePayload;
  }
>;

type GetValetRequestProps = {
  accessToken: string;
  vehicleId: string;
  idToken: string;
};

type DeleteGeofenceRequestProps = {
  accessToken: string;
  vehicleId: string;
  geofenceId: string;
  idToken: string;
};

export default class GeofenceService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getGeofence({ accessToken, vehicleId, idToken }: GetValetRequestProps) {
    const request: GetGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId,
      },
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    return await getGeofence(request);
  }

  async createGeofence({ accessToken, vehicleId, data, idToken }: CreateGeofenceRequestProps) {
    const request: CreateGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId,
      },
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    return await createGeofence(request);
  }

  async updateGeofence({ accessToken, vehicleId, data, idToken }: UpdateGeofenceRequestProps) {
    const request: UpdateGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId,
      },
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
      data,
    };
    return await updateGeofence(request);
  }

  async deleteGeofence({ accessToken, vehicleId, geofenceId, idToken }: DeleteGeofenceRequestProps) {
    const request: DeleteGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId,
        geofenceId,
      },
      headers: {
        Authorization: accessToken,
        id_token: idToken,
      },
    };
    return await deleteGeofence(request);
  }
}
