import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { BrandNamesFilterGroup as OfferTypeGroup } from '@customTypes/BrandNamesFilterGroup';
import filterText from '@utils/filterByMake';
import { ServerLabel } from '@utils/labels';

type GetOfferLegalText = (offer: OfferType, isYearly?: boolean) => string | false;

type UtilityCreator = (allTexts: ServerLabel[], offerTypes: OfferTypeGroup | undefined) => GetOfferLegalText;

const textForOffer: UtilityCreator = (content, filterGroup) => (makeName, isYearly) => {
  if (filterGroup) {
    const [text] = filterText({ content, makeName, filterGroup, isYearly });
    return !!text && (text.labelValue as string);
  }

  return false;
};

export default textForOffer;
