import React, { useMemo } from 'react';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import {
  GridContainer,
  GridItem,
  GridItemHeader,
  TrialOption,
  TrialOptionContent,
  TrialPackagesContainer,
} from '../styles';
import { Heading, Title } from '@styled-components/globalStyles';
import { extractTrialVariantsToTerm, findMatchingTerm, TrialPackagesWebContent } from '@manageSubscription';
import { useFlowMachineContextContent } from '@lib-appRouter/RouterContext';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import { Radio, RadioGroup } from '@lib-components/Radio';

interface TrialOptionsDisplayProps {
  trialPackages: EligiblePackageInfo[];
  selectedTrialPackages: EligiblePackageInfo[];
  onSelect: (packages: EligiblePackageInfo[]) => void;
}

const TrialOptionsDisplay = ({ trialPackages, selectedTrialPackages, onSelect }: TrialOptionsDisplayProps) => {
  const {
    trialPackagesWebContent,
    packagesWebContent: { packageLabel, expirationLabel },
  } = useFlowMachineContextContent();

  const { trialOptionHeader, ccRequiredCopyBlock, ccNotRequiredCopyBlock, baseTrialLabel, extendedTrialLabel } =
    trialPackagesWebContent as TrialPackagesWebContent;

  const variantsToTerm = extractTrialVariantsToTerm(trialPackages);

  const selectedTerm = useMemo(() => {
    if (!selectedTrialPackages) {
      return '';
    }
    const matchingTerm = findMatchingTerm(variantsToTerm, selectedTrialPackages);
    return matchingTerm || '';
  }, [selectedTrialPackages, variantsToTerm]);

  const handleSelect = (term: string) => {
    const selectedPackages = variantsToTerm[term];
    onSelect(selectedPackages);
  };

  return (
    <TrialPackagesContainer>
      <Heading>{trialOptionHeader}</Heading>
      <FormControl>
        <RadioGroup value={selectedTerm} onChange={(_, value) => handleSelect(value)}>
          {Object.entries(variantsToTerm).map(([initialTermKey, packages]) => {
            const [multiVariantPackage] = packages;
            const {
              variant: { id: variantId, discounts },
            } = multiVariantPackage;
            const [discount] = discounts;

            return (
              <TrialOption key={variantId}>
                <FormControlLabel
                  key={initialTermKey}
                  value={initialTermKey}
                  control={<Radio size={'small'} />}
                  label={<Title>{discount.ccRequired ? extendedTrialLabel : baseTrialLabel}</Title>}
                />
                <TrialOptionContent>
                  {discount.ccRequired ? ccRequiredCopyBlock : ccNotRequiredCopyBlock}
                </TrialOptionContent>
                <GridContainer container>
                  <GridItemHeader item xs={6}>
                    {packageLabel}
                  </GridItemHeader>
                  <GridItemHeader item xs={6}>
                    {expirationLabel}
                  </GridItemHeader>
                  {packages.map((p) => {
                    const {
                      marketingName,
                      variant: { termServiceEndDate: expirationDate },
                    } = p;
                    return (
                      <Grid container key={marketingName}>
                        <GridItem item xs={6}>
                          {marketingName}
                        </GridItem>
                        <GridItem item xs={6}>
                          {expirationDate}
                        </GridItem>
                      </Grid>
                    );
                  })}
                </GridContainer>
              </TrialOption>
            );
          })}
        </RadioGroup>
      </FormControl>
    </TrialPackagesContainer>
  );
};

export default TrialOptionsDisplay;
