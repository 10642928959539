import React, { useMemo } from 'react';
import clsx from 'clsx';
import { lowerCase } from 'lodash';
import { Asset as ContentfulAsset } from 'contentful';
import { TyrePressure } from '@cv/portal-rts-lib/health/models';

import lock from '@assets/lock.svg';
import unlock from '@assets/unlock-door-status.svg';
import lockUnavailable from '@assets/lock-unavailable-status.svg';
import tirePressure from '@assets/tire_pressure.svg';
import tirePressureUnavailable from '@assets/tire_pressure_unavailable.svg';

import styles from './DoorsAndTires.module.css';
import { useSelector } from 'react-redux';
import { Position, MilType, LockType } from '@cv/portal-rts-lib/health/enums';
import { RootState } from '@app/reducers';

export type Asset = ContentfulAsset['fields'];

export type WireframeAssets = {
  base: Asset;
  hood: Asset;
  tailgate: Asset;
  frontDoorOpen: Asset;
  frontDoorClosed: Asset;
  rearDoorOpen: Asset;
  rearDoorClosed: Asset;
};

export type Wireframe = {
  name: string;
  models: string[];
} & WireframeAssets;

export type DoorsAndTiresProps = {
  DoorsLabel: string;
  TiresLabel: string;
  PressureUnavailableLabel: string;
  CheckPressureLabel: string;
  PressureOkLabel: string;
  DoorsUnavailableLabel: string;
  wireframes: Wireframe[];
};

const vehicleParts = [
  { openFileName: 'hood', lockType: LockType.Engine_Hood },
  { openFileName: 'tailgate', lockType: LockType.Hatch },
  { openFileName: 'frontDoorOpen', closedFileName: 'frontDoorClosed', lockType: LockType.Door_Front_Right },
  { openFileName: 'frontDoorOpen', closedFileName: 'frontDoorClosed', lockType: LockType.Door_Front_Left },
  { openFileName: 'rearDoorOpen', closedFileName: 'rearDoorClosed', lockType: LockType.Door_Rear_Right },
  { openFileName: 'rearDoorOpen', closedFileName: 'rearDoorClosed', lockType: LockType.Door_Rear_Left },
];

const TirePressureStatus = ({ pressure, className }: { pressure?: TyrePressure; className: string }) => (
  <span
    data-testid={`tire-${pressure?.position.toLowerCase().replace('_', '-')}`}
    className={clsx(className, { [styles['TiresSection-pressure--high']]: pressure?.status !== '0' })}
  >
    {pressure?.value ? <span>{pressure?.value}</span> : '0'}
  </span>
);

function DoorsAndTires(props: DoorsAndTiresProps) {
  const {
    vehicle,
    vehicleHealth: { data: vehicleStatus },
  } = useSelector(({ vehicleReducer }: RootState) => vehicleReducer);

  const getTireVar = (type: Position) => vehicleStatus?.tyres?.find((item) => item.position === type);
  const getLockVar = (type: LockType) => vehicleStatus?.locks?.find((item) => item.type === type);
  const getMilVar = (type: MilType) => vehicleStatus?.mil?.find((item) => item.type === type);

  const frontLeftTire = getTireVar(Position.Front_Left);
  const frontRightTire = getTireVar(Position.Front_Right);
  const rearLeftTire = getTireVar(Position.Rear_Left);
  const rearRightTire = getTireVar(Position.Rear_Right);

  const renderTirePressureElement = (status: string, type: 'icon' | 'label') => {
    switch (type) {
      case 'icon':
        switch (status) {
          case '0':
          case '1':
            return tirePressure;
          default:
            return tirePressureUnavailable;
        }
      case 'label':
        switch (status) {
          case '0':
            return props.PressureOkLabel;
          case '1':
            return props.CheckPressureLabel;
          default:
            return props.PressureUnavailableLabel;
        }
    }
  };

  const wireframes = useMemo(
    () =>
      props.wireframes.find((wireframe) => {
        const models = wireframe.models.map((model) => model.toLowerCase());
        // take default wireframe if vehicle doesn't have model
        const vehicleModel = vehicle?.model?.toLowerCase() || 'default';
        const isModelExist = models.includes(vehicleModel);

        // use default wireframe if provided model is not added to contentful
        return isModelExist || models.includes('default');
      }),
    [props.wireframes, vehicle?.model],
  );

  const renderWireframe = (wireframe: { open: Asset; closed?: Asset }, lockType: LockType) => {
    const { open, closed } = wireframe;
    const lock = getLockVar(lockType);
    const isInverted = [LockType.Door_Front_Right, LockType.Door_Rear_Right].includes(lockType);
    const asset = lock?.status === 'open' ? open : closed;
    const direction = isInverted ? ' right' : ' left';
    const altDirection = ![LockType.Engine_Hood, LockType.Hatch].includes(lockType) ? direction : '';

    if (!asset) {
      return null;
    }

    const url = asset?.file.url;
    const title = asset?.title;

    return (
      <img
        key={title + lockType}
        src={url}
        alt={`${lowerCase(title)} ${altDirection}`}
        className={clsx(styles['CarImage'], {
          [styles['ImageInverted']]: isInverted,
        })}
      />
    );
  };

  const tiresPressureStatus = getMilVar(MilType.Tyre_Pressure);
  const overallLockStatus = getLockVar(LockType.Overrall_Lock);

  const renderDoorLockIcon = () => {
    switch (overallLockStatus?.status) {
      case 'unlocked':
      case 'Desbloqueado':
        return <img src={unlock} className={styles['DoorsAndTiresIcon']} />;
      case 'locked':
      case 'Bloqueado':
        return <img src={lock} className={styles['DoorsAndTiresIcon']} />;
      default:
        return <img src={lockUnavailable} className={styles['DoorsAndTiresIcon']} />;
    }
  };

  return (
    <div className={styles['DoorsAndTiresContainer']}>
      <div className={styles['DoorsAndTiresSection']}>
        <div className={styles['DoorsAndTiresSubSection']}>
          {renderDoorLockIcon()}
          <span>{props.DoorsLabel}</span>
          <span>{overallLockStatus?.status || props.DoorsUnavailableLabel}</span>
        </div>

        <div className={styles['Horizontal-separator']}></div>

        <div className={styles['DoorsAndTiresSubSection']}>
          <img
            src={renderTirePressureElement(tiresPressureStatus ? tiresPressureStatus.status : '', 'icon')}
            className={styles['DoorsAndTiresIcon']}
          />
          <div>{props.TiresLabel}</div>
          <div className={styles['Pressure']}>
            {renderTirePressureElement(tiresPressureStatus ? tiresPressureStatus.status : '', 'label')}
          </div>
        </div>
      </div>

      <div className={styles['Separator']}></div>

      <div className={styles['CarSection']}>
        {wireframes?.base && <img src={wireframes?.base?.file.url} alt="base" className={styles['CarImage']} />}
        {vehicleParts.reduce<(JSX.Element | null)[]>((acc, data) => {
          const { openFileName, closedFileName, lockType } = data;
          const open = wireframes?.[openFileName as keyof WireframeAssets];
          const closed = wireframes?.[closedFileName as keyof WireframeAssets];
          if (open !== undefined) {
            const element = renderWireframe({ open, closed }, lockType);
            element && acc.push(element);
          }

          return acc;
        }, [])}
        <TirePressureStatus
          pressure={frontLeftTire}
          className={clsx(styles['Pressure'], styles['Front'], styles['Left'])}
        />
        <TirePressureStatus
          pressure={frontRightTire}
          className={clsx(styles['Pressure'], styles['Front'], styles['Right'])}
        />
        <TirePressureStatus
          pressure={rearLeftTire}
          className={clsx(styles['Pressure'], styles['Back'], styles['Left'])}
        />
        <TirePressureStatus
          pressure={rearRightTire}
          className={clsx(styles['Pressure'], styles['Back'], styles['Right'])}
        />
      </div>
    </div>
  );
}

export default DoorsAndTires;
