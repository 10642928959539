import React, { useMemo } from 'react';
import { camelCase } from 'lodash';
import { getVehicleServices } from '../../services/cps/vehicleService';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../../components/Spinner';
import { Title, Content } from '../../styled-components/globalStyles';
import { ServicesList, Service, ServiceContent } from './VehicleServicesStyles';
import Image from '../../components/Image';
import { ContentfulAssets, SubscriptionProps } from '..';
import { getCustomerFacingServices, getUniqueVehicleServices } from '@utils/subscriptionUtils';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

type VehicleServicesProps = {
  fetchError?: string;
  assets: ContentfulAssets;
  servicesToShow?: string[];
  subscriptionProps: SubscriptionProps;
};

const VehicleServices = ({
  fetchError = 'Data loading failed. Please contact support.',
  assets,
  servicesToShow,
  subscriptionProps,
}: VehicleServicesProps) => {
  const { status, isInitialLoading, data } = useQuery(['vehicleServices'], () => getVehicleServices(subscriptionProps));

  const services = useMemo(() => {
    if (!data) {
      return null;
    }
    const { vehicleServiceDetails } = data;
    let displayServices = getCustomerFacingServices(vehicleServiceDetails as VehicleServiceDetails[]);

    if (servicesToShow?.length) {
      const showServices = displayServices.filter(({ vehicleServiceId }) => servicesToShow.includes(vehicleServiceId));

      displayServices = getUniqueVehicleServices(showServices);

      if (!displayServices.length) {
        return null;
      }
    }

    return displayServices.map((item) => (
      <Service key={item.vehicleServiceId}>
        <Image assets={assets} name={camelCase(item.marketingName)} size="30px" />
        <ServiceContent>
          <Title title={item.vehicleServiceName}>{item.marketingName}</Title>
          <Content>{item.description}</Content>
        </ServiceContent>
      </Service>
    ));
  }, [data]);

  if (isInitialLoading) return <Spinner />;

  if (status !== 'success') {
    return <>{fetchError}</>;
  }

  return <ServicesList>{services}</ServicesList>;
};

export default VehicleServices;
