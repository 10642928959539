import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { DIALOG_ACTIONS } from '@redux/actions';
import { ROOT_EVENTS } from '@app/reducers';
import { Api } from '../../api';
import { getUserData } from '@utils/getUserData';
import { Dispatch } from 'redux';
import { PAGE_LOADING_STATUS } from '@utils/constants';
import { cancelSubscriptions, SubscriptionProps } from '@components-lib';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';

type CancelSubscriptionRequestProps = {
  api: Api;
  dispatch: Dispatch;
  subscriptionProps: SubscriptionProps;
  setStatus: (status: PAGE_LOADING_STATUS) => void;
  setCurrentView: (currentView: string) => void;
  errorMessage: { primary: string; analyticsEventName?: string };
  errorTitle: { primary: string; analyticsEventName?: string };
  isMonthlySubscription: boolean;
  packagesToRemove: Array<SubscribedPackage>;
  isAllPackages: boolean;
};

export const cancelSubscriptionRequest = async ({
  api,
  dispatch,
  subscriptionProps,
  setStatus,
  setCurrentView,
  errorMessage,
  errorTitle,
  isMonthlySubscription,
  packagesToRemove,
  isAllPackages,
}: CancelSubscriptionRequestProps) => {
  const selectedVehicleId = api.storeService.getVehicleData().vehicleId;
  const accessToken = api.storeService.getAccessToken();
  const refreshToken = api.storeService.getRefreshToken();
  const nextView = isMonthlySubscription ? 'confirmation-monthly' : 'confirmation-annual';

  try {
    setStatus(PAGE_LOADING_STATUS.LOADING);
    const res = await cancelSubscriptions(subscriptionProps, packagesToRemove, isAllPackages, false, true);
    if (res.status === HttpStatus.ACCEPTED) {
      dispatch({ type: ROOT_EVENTS.CLEAR_SUBSCRIPTIONS });
      await getUserData({ accessToken, refreshToken, dispatch, api, selectedVehicleId });
      setCurrentView(nextView);
    }
  } catch (err) {
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: errorMessage?.primary, title: errorTitle?.primary } });
  } finally {
    setStatus(PAGE_LOADING_STATUS.IDLE);
  }
};
