import React from 'react';

import { LoaderBackdrop } from '@components/Loader';
import { useAuth } from '../AuthProvider/AuthProvider';

const Login = (): JSX.Element => {
  const { login } = useAuth();

  React.useEffect(() => {
    login();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <LoaderBackdrop />;
};

export default Login;
