import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useHeadlineStyles, useTextStyles, useActionButtonStyles } from './styles';
import Button from '@components-lib/components/Button';
import { extractPrimaries } from '@utils/labels';
import urlSearchParams from '@utils/urlSearchParams';

const CodeResentMessage = ({ labels }) => {
  const { title, message, buttonText } = extractPrimaries(labels);
  const { email } = urlSearchParams.getAll();

  const history = useHistory();
  const headline = useHeadlineStyles();
  const textStyles = useTextStyles();
  const button = useActionButtonStyles();

  const keysToReplace = { email };
  const formattedMessage = message.replaceAll(
    /{{([^{}]*)}}/g,
    (_: string, foundKey: string) => keysToReplace[foundKey],
  );

  return (
    <Stack p="1rem" sx={{ height: '100vh' }}>
      <Typography variant="h1" component="h1" className={headline.headline}>
        {title}
      </Typography>
      <Stack width={1} height={1} justifyContent="space-between">
        <Typography component="p" mb="1.5rem" className={textStyles.text}>
          {formattedMessage}
        </Typography>
        <Button className={button.actionButton} sx={{ marginBottom: '1rem' }} onClick={() => history.goBack()}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CodeResentMessage;
