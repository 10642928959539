import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormHelperText, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { VIN_LOOKUP_REGEX, VIN_SIZE } from '@components/AccountEnrollment/constants';
import { WebContentProps } from '@components/VinLookup/Types';
import SearchIcon from '@mui/icons-material/Search';
import { EventDataBuilder, EventType } from '@lib-components/Analytics';
import { maskVin } from '@app/components-lib/manageSubscription';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';

interface VinLookupFormProps extends WebContentProps {
  onVinSubmit: (vin: string) => void;
}

const VinLookupForm = ({ labels, onVinSubmit }: VinLookupFormProps) => {
  const { vinInputPlaceholderLabel, invalidVinErrorMsg, vinRegexErrorMsg, checkVinLabel } = labels;

  const vinLookupSchema = z.object({
    vin: z
      .string()
      .min(VIN_SIZE, invalidVinErrorMsg)
      .regex(VIN_LOOKUP_REGEX, vinRegexErrorMsg)
      .transform((val) => val.toUpperCase()),
  });

  type VinLookupData = z.infer<typeof vinLookupSchema>;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<VinLookupData>({
    mode: 'onChange',
    resolver: zodResolver(vinLookupSchema),
  });

  const handleFormSubmit = (data: VinLookupData) => {
    onVinSubmit(data.vin);
  };

  const handleVinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const upperCaseValue = event.target.value.toUpperCase();
    setValue('vin', upperCaseValue, { shouldValidate: true });
  };

  return (
    <Box mt={4} mb={5}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
          <Grid xs={12} md={8}>
            <TextField
              {...register('vin')}
              id={'vin'}
              name={'vin'}
              placeholder={vinInputPlaceholderLabel}
              error={!!errors.vin}
              sx={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '4px',
              }}
              inputProps={{
                maxLength: VIN_SIZE,
              }}
              onChange={handleVinChange}
            />
            {errors.vin?.message && <FormHelperText error>{errors.vin?.message}</FormHelperText>}
          </Grid>
          <Grid xs={12} md={4} alignItems="flex-start" justifyContent="center">
            <AnalyticsButton
              sx={{ width: { xs: '100%', md: 'auto' }, minHeight: { xs: 'auto', md: '3.4em' } }}
              disabled={!isValid}
              startIcon={<SearchIcon />}
              type="submit"
              analyticsEvent={new EventDataBuilder(EventType.VinLookupClick).withArgs({
                text: checkVinLabel,
                pageName: 'vinLookup',
                vinMask: maskVin(getValues().vin),
              })}
            >
              {checkVinLabel}
            </AnalyticsButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default VinLookupForm;
