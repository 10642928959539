import { styled } from '@mui/material';
import { Title } from '../../styled-components/globalStyles';

export const ProductWrapper = styled('div')`
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 1rem 2rem 1rem 1rem;
`;

export const ProductHeader = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ProductTitle = styled(Title)`
  margin-top: 0.1rem;
`;

export const ProductDescription = styled('div')`
  display: flex;
  padding: 0.5rem 0 0.3rem 0.3rem;
`;

export const IconSection = styled('span')`
  margin-right: 1rem;
`;
