import { PackageFlag } from '@customTypes/package-flags';
import { useSelector } from 'react-redux';

export type Preferences = {
  isSinglePaymentMethod: boolean;
  goToManageToSubscribe: boolean;
  shouldMatchTrialWithCCRequired: boolean;
  isAuthCodeFunctionality: boolean;
  hideDownloadAppOnConfirmation: boolean;
  otpTimeout: number;
  phoneTypeShortcutsLabels?: Record<string, string>;
  packageFlags: PackageFlag[];
  showDatesOnPromoPage: boolean;
} & { [key: string]: string };

export type PreferencesReducer = {
  preferencesReducer: {
    preferences: Preferences;
  };
};

const getPreferences = (state: PreferencesReducer) => {
  return state.preferencesReducer.preferences;
};

const usePreferencesSelector = () => {
  const preferences = useSelector(getPreferences);
  return preferences;
};

export default usePreferencesSelector;
