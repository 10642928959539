import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { FormikInputField } from '@components/FormikFields';
import { Button } from '@components-lib';

import styles from './VinSearch.module.css';

export type VinSearchFormData = {
  vin: string;
};

type VinSearchProps = {
  labelTitle: string;
  labelInput: string;
  labelInputPlaceholder: string;
  labelSubmit: string;
  labelErrorVinRequired: string;
  labelErrorVinInvalid: string;
  onSubmit: (data: VinSearchFormData) => void;
};

const VinSearch = ({
  labelTitle,
  labelInput,
  labelInputPlaceholder,
  labelSubmit,
  labelErrorVinRequired,
  labelErrorVinInvalid,
  onSubmit,
}: VinSearchProps) => {
  const validationSchema = yup.object().shape({
    vin: yup
      .string()
      .trim()
      .required(labelErrorVinRequired)
      .length(17, labelErrorVinInvalid)
      .matches(/^[0-9A-HJ-NPR-Z]{17}$/i, labelErrorVinInvalid),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={{ vin: '' }}
    >
      <Form className={styles.form}>
        <h1>{labelTitle}</h1>
        <FormikInputField
          name="vin"
          label={labelInput}
          maxLength={17}
          placeholder={labelInputPlaceholder}
          className={styles['input-field']}
          classes={{
            wrapper: styles['input-wrapper'],
            label: styles['input-label'],
            error: styles['input-error'],
          }}
        />
        <Button variant="contained" className={styles.submit} type="submit">
          {labelSubmit}
        </Button>
      </Form>
    </Formik>
  );
};

export default VinSearch;
