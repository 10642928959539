// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QENYbxjWmCS0UdkPUiwz {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 -1rem;
  overflow: hidden;
}

.QENYbxjWmCS0UdkPUiwz > * {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1.625rem;
}

@media (min-width: 768px) {
  .QENYbxjWmCS0UdkPUiwz > * {
    width: 100%;
  }
}

.bXORnNj1s_F0pZ7RKFKy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.fkDOCZipscA6zTFH2Tf7 {
  width: 150px;
}

.fkDOCZipscA6zTFH2Tf7 + .fkDOCZipscA6zTFH2Tf7 {
  margin-left: 1rem;
}

.cOW4Lx92MVwWmxwVR4ss {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-family: inherit;
}

.r3tLGrKP_q_u_Xh4FJSF {
  font-size: 20px;
  color: #4a4a4a;
}

.cOW4Lx92MVwWmxwVR4ss img {
  padding: 0 1rem;
}

.a9pijTkSjJGezVdrIbwA {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/VinSearch/VinSearch.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".Form {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  margin: 0 -1rem;\n  overflow: hidden;\n}\n\n.Form > * {\n  width: 100%;\n  padding: 0 1rem;\n  margin-bottom: 1.625rem;\n}\n\n@media (--viewport-s) {\n  .Form > * {\n    width: 100%;\n  }\n}\n\n.Actions {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 2rem;\n}\n\n.Button {\n  width: 150px;\n}\n\n.Button + .Button {\n  margin-left: 1rem;\n}\n\n.ModalHeader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0.5rem 1rem;\n  font-family: inherit;\n}\n\n.ModalHead {\n  font-size: 20px;\n  color: #4a4a4a;\n}\n\n.ModalHeader img {\n  padding: 0 1rem;\n}\n\n.ModalContent {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": `QENYbxjWmCS0UdkPUiwz`,
	"Actions": `bXORnNj1s_F0pZ7RKFKy`,
	"Button": `fkDOCZipscA6zTFH2Tf7`,
	"ModalHeader": `cOW4Lx92MVwWmxwVR4ss`,
	"ModalHead": `r3tLGrKP_q_u_Xh4FJSF`,
	"ModalContent": `a9pijTkSjJGezVdrIbwA`
};
export default ___CSS_LOADER_EXPORT___;
