import { PaymentInfo } from '../../payment/Types';
import {
  DiscountInfo,
  EligiblePackageInfo,
  PackageSubscription,
  SubscribedPackageInfo,
} from '@manageSubscription/Types';

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : {
        allowMultiplePromoPackagesSelection?: boolean;
        type: Key;
        payload: M[Key];
        promoPackages?: EligiblePackageInfo[];
      };
};

export enum ActionTypes {
  // Root
  ResetState = 'RESET_STATE',

  // Packages
  AddTrialPackage = 'ADD_TRIAL_PACKAGE',
  AddPromoPackage = 'ADD_PROMO_PACKAGE',
  AddPackage = 'ADD_PACKAGE',
  AddAllTrialPackages = 'ADD_ALL_TRIAL_PACKAGES',
  RemovePackage = 'REMOVE_PACKAGE',
  RemovePaidPackages = 'REMOVE_PAID_PACKAGES',
  AddSubscribedPackages = 'ADD_SUBSCRIBED_PACKAGES',
  AddEligiblePackages = 'ADD_ELIGIBLE_PACKAGES',
  RemovePackageSubscriptions = 'REMOVE_PACKAGE_SUBSCRIPTIONS',

  // Discount
  AddDiscountInfo = 'ADD_DISCOUNT_INFO',
  RemoveDiscountInfo = 'REMOVE_DISCOUNT_INFO',

  // Payment
  AddPaymentMethodId = 'ADD_PAYMENT_METHOD_ID',
  AddPaymentOption = 'ADD_PAYMENT_OPTION',

  // Common
  UpdateClientIP = 'UPDATE_CLIENT_IP',

  // Routing
  SaveLastVisitedPage = 'LAST_VISITED_PAGE',
}

export type Actions =
  | RootActions
  | ClientIPActions
  | DiscountActions
  | SubscribedPackageActions
  | EligiblePackageActions
  | PackageSubscriptionActions
  | PaymentActions
  | LastVisitedPageActions;

// Root
type RootPayload = {
  [ActionTypes.ResetState]: undefined;
};
type RootActions = ActionMap<RootPayload>[keyof ActionMap<RootPayload>];

// ClientIP
type ClientIPPayload = {
  [ActionTypes.UpdateClientIP]: string;
};
type ClientIPActions = ActionMap<ClientIPPayload>[keyof ActionMap<ClientIPPayload>];

// PackageSubscription
type PackageSubscriptionPayload = {
  [ActionTypes.AddTrialPackage]: PackageSubscription;
  [ActionTypes.AddPromoPackage]: PackageSubscription;
  [ActionTypes.AddPackage]: PackageSubscription;
  [ActionTypes.RemovePackage]: PackageSubscription;
  [ActionTypes.RemovePaidPackages]: undefined;
  [ActionTypes.AddAllTrialPackages]: EligiblePackageInfo[];
  [ActionTypes.RemovePackageSubscriptions]: [];
};
type PackageSubscriptionActions = ActionMap<PackageSubscriptionPayload>[keyof ActionMap<PackageSubscriptionPayload>];

// Discount
type DiscountPayload = {
  [ActionTypes.AddDiscountInfo]: DiscountInfo;
  [ActionTypes.RemoveDiscountInfo]: DiscountInfo;
};
type DiscountActions = ActionMap<DiscountPayload>[keyof ActionMap<DiscountPayload>];

// SubscribedPackage
type SubscribedPackagePayload = {
  [ActionTypes.AddSubscribedPackages]: SubscribedPackageInfo[];
};
type SubscribedPackageActions = ActionMap<SubscribedPackagePayload>[keyof ActionMap<SubscribedPackagePayload>];

// PaidPackage
type EligiblePackagePayload = {
  [ActionTypes.AddEligiblePackages]: EligiblePackageInfo[];
};
type EligiblePackageActions = ActionMap<EligiblePackagePayload>[keyof ActionMap<EligiblePackagePayload>];

// Payment
type PaymentPayload = {
  [ActionTypes.AddPaymentMethodId]: PaymentInfo;
  [ActionTypes.AddPaymentOption]: PaymentInfo;
};
type PaymentActions = ActionMap<PaymentPayload>[keyof ActionMap<PaymentPayload>];

// Routing
type LastVisitedPagePayload = {
  [ActionTypes.SaveLastVisitedPage]: string;
};
type LastVisitedPageActions = ActionMap<LastVisitedPagePayload>[keyof ActionMap<LastVisitedPagePayload>];
