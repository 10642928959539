import { useState, useEffect } from 'react';

export enum Status {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Ready = 'READY',
  Error = 'ERROR',
}

const useScript = (src?: string, onload?: (event: Event) => void): Status => {
  const [status, setStatus] = useState<Status>(src ? Status.Loading : Status.Idle);

  useEffect(() => {
    if (!src) {
      setStatus(Status.Idle);
      return;
    }

    let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      script.setAttribute('data-status', 'loading');

      document.body.appendChild(script);

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        script?.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };

      script.addEventListener('load', setAttributeFromEvent, { once: true });
      script.addEventListener('error', setAttributeFromEvent, { once: true });
    } else {
      // Grab existing script status from attribute and set to state.
      const scriptStatusMap = { loading: Status.Loading, ready: Status.Ready, error: Status.Error } as const;
      const scriptStatus = script.getAttribute('data-status') as keyof typeof scriptStatusMap;
      setStatus(scriptStatusMap[scriptStatus] || Status.Error);
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? Status.Ready : Status.Error);
    };

    const onLoad = (event: Event) => {
      setStateFromEvent(event);
      onload && onload(event);
    };

    // Add event listeners
    script.addEventListener('load', onLoad);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      script?.removeEventListener('load', onLoad);
      script?.removeEventListener('error', setStateFromEvent);
    };
  }, [src]);

  return status;
};

export default useScript;
