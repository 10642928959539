import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import { Button } from '@components-lib';
import Widget from '@components/Widget/Widget';

import styles from '@components/ResetPassword/ResetPassword.module.css';

type UpdatePasswordSuccessProps = {
  onClick: () => void;
  labels: Record<string, string>;
};

const UpdatePasswordSuccess = ({ onClick, labels }: UpdatePasswordSuccessProps) => {
  return (
    <Widget
      avatar={
        <span className={styles['reset-check-big']}>
          <FaCheckCircle />
        </span>
      }
      title={labels.successTitle}
      titleAlignment="center"
    >
      <p className={styles['forget-password-description']}>{labels.succesDescription}</p>

      <div className={styles['actions']}>
        <Button variant="contained" className={styles['button']} onClick={onClick}>
          {labels.login}
        </Button>
      </div>
    </Widget>
  );
};

export default UpdatePasswordSuccess;
