import React, { useLayoutEffect, useRef, useState, useMemo } from 'react';
import BackgroundImage from './BackgroundImageStyles';

type BackgroundImageComponentProps = {
  img: string;
  initialWidth?: number;
  initialHeight?: number;
  fitSize?: boolean;
  children?: React.ReactChild | React.ReactChild[];
};

const BackgroundImageComponent = ({
  img,
  initialHeight,
  initialWidth,
  children,
  fitSize = false,
  ...rest
}: BackgroundImageComponentProps) => {
  const imgWrapper = useRef(null);
  const [wrapperSize, setWrapperSize] = useState({ width: 0 });

  useLayoutEffect(() => {
    if (!imgWrapper.current || !fitSize) {
      return;
    }

    const calculateWrapperSize = () => {
      setWrapperSize(imgWrapper.current.getBoundingClientRect());
    };

    calculateWrapperSize();
    window.addEventListener('resize', calculateWrapperSize);
    return () => {
      window.removeEventListener('resize', calculateWrapperSize);
    };
  }, []);

  const getImageHeigh = (imgWidth, imgHeight) => {
    const containerWidth = wrapperSize?.width;
    return (imgHeight * containerWidth) / imgWidth;
  };

  const height = useMemo(
    () => (getImageHeigh(initialWidth, initialHeight) || fitSize ? 250 : 0),
    [wrapperSize, initialWidth, initialHeight],
  );

  return (
    <BackgroundImage ref={imgWrapper} img={img} height={height} {...rest}>
      {children}
    </BackgroundImage>
  );
};

export default BackgroundImageComponent;
