import { HealthReport } from '@cv/portal-rts-lib/health-reports/models';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';

export enum VEHICLE_ACTIONS {
  SET_VEHICLE_DATA = 'SET_VEHICLE_DATA',
  SET_VEHICLE_HEALTH = 'SET_VEHICLE_HEALTH',
  SET_VEHICLE_HEALTH_REPORTS = 'SET_VEHICLE_HEALTH_REPORTS',
}

export interface IVehicle {
  vin: string;
  active: boolean;
  make: string;
  model: string;
  nickname: string;
  year: string;
  vehicleId: string;
  telematicsProgramId: string;
  activeServices: string[];
  services: string[];
  marketingServiceNames: string[];
  registrationCountry: string;
  ownershipType: OwnershipType;
  additionalFeatures?: {
    ccsGen?: string;
  };
}

export const setVehicleData = (data: IVehicle) => {
  return {
    type: VEHICLE_ACTIONS.SET_VEHICLE_DATA,
    data,
  };
};

export const setVehicleHealthReports = (data: HealthReport[]) => {
  return {
    type: VEHICLE_ACTIONS.SET_VEHICLE_HEALTH_REPORTS,
    data,
  };
};
