import { add } from 'date-fns';

type Duration = {
  hours?: number;
};

const getScheduleTime = (duration: Duration = { hours: 3 }) => {
  const startTime = new Date();
  const endTime = add(startTime, duration);

  return [getISOTime(startTime), getISOTime(endTime)];
};

export function getISOTime(date: Date) {
  const [, time] = date.toISOString().split('T');
  return time;
}

export default getScheduleTime;
