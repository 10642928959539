import React from 'react';
import PaymentLabel from '../PaymentLabel';
import Link from '@lib-components/Link';
import { CardMethodDetails, PaymentMethodDetails, PaymentOptions } from '@lib-payment/Types';
import { Grid } from '@lib-components/Grid';

export interface SavedPaymentMethodItemProps {
  deleteLabel?: string;
  editLinkLabel?: string;
  onEdit?: (arg: CardMethodDetails) => void;
  onDelete?: (arg: PaymentMethodDetails) => void;
}
interface SavedPaymentMethodItemLocalProps extends SavedPaymentMethodItemProps {
  label?: string;
  paymentMethodDetails?: PaymentMethodDetails;
}

const SavedPaymentMethodItem: React.FC<SavedPaymentMethodItemLocalProps> = ({
  deleteLabel,
  editLinkLabel,
  label,
  paymentMethodDetails,
  onEdit,
  onDelete,
}) => {
  if (!paymentMethodDetails) {
    return null;
  }

  const { cardType } = paymentMethodDetails;

  const [actionLabel, action] = [PaymentOptions.PAYPAL, PaymentOptions.APPLE_PAY].includes(cardType)
    ? [deleteLabel, onDelete?.bind(onDelete, paymentMethodDetails)]
    : [editLinkLabel, onEdit?.bind(onEdit, paymentMethodDetails as CardMethodDetails)];

  return (
    <Grid container justifyContent="space-between">
      <PaymentLabel type={cardType}>{label}</PaymentLabel>
      {actionLabel && action && (
        <Link
          data-testid={paymentMethodDetails.paymentMethodId}
          component="button"
          onClick={(event) => {
            event.preventDefault();
            action();
          }}
        >
          {actionLabel}
        </Link>
      )}
    </Grid>
  );
};

export default SavedPaymentMethodItem;
