import React, { useState } from 'react';
import ContentRenderer from '@components/ContentRenderer';
import { Button } from '@components-lib';
import ModalContainer from '@components/ModalContainer';
import Loader from '@components/Loader';

import { useApi } from '@api';
import useToggle from '@hooks/useToggle';

import styles from './ResetDataInfoBox.module.css';

type ResetDataInfoBoxProps = {
  expandLabel: string;
  visibleText: { componentType: string };
  hiddenText: { componentType: string };
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  modalText: string;
  modalConfirmationLabel: string;
  modalCancelLabel: string;
  modalOKLabel: string;
  modalSuccessMessage: string;
  modalErrorMessage: string;
  modalSuccessHeader: string;
  modalErrorHeader: string;
};

const STATE = {
  DEFAULT: 'DEFAULT',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export default function ResetDataInfoBox({
  expandLabel,
  visibleText,
  hiddenText,
  cancelButtonLabel,
  confirmButtonLabel,
  modalText,
  modalConfirmationLabel,
  modalCancelLabel,
  modalOKLabel,
  modalSuccessMessage,
  modalErrorMessage,
  modalSuccessHeader,
  modalErrorHeader,
}: ResetDataInfoBoxProps) {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [operationState, setOperationState] = useState(STATE.DEFAULT);
  const [headerLabel, setHeaderLabel] = useState('');
  const api = useApi();

  const onFinalConfirm = async () => {
    try {
      setOperationState(STATE.LOADING);
      const response = await api.resetVehicleData();
      setOperationState(STATE.SUCCESS);
      setHeaderLabel(modalSuccessHeader);
    } catch (error) {
      setHeaderLabel(modalErrorHeader);
      setOperationState(STATE.ERROR);
    }
  };

  const renderQuestionModalContent = () => (
    <>
      <div className={styles['modal-text']}>{modalText}</div>
      <div className={styles['modal-buttons']}>
        <Button variant="outlined" onClick={toggleModal}>
          {modalCancelLabel}
        </Button>
        <Button variant="contained" onClick={onFinalConfirm}>
          {modalConfirmationLabel}
        </Button>
      </div>
    </>
  );

  const renderLoadingModalContent = () => <Loader />;

  const renderResultModalContent = () => (
    <>
      <div className={styles['modal-text']}>
        {operationState === STATE.SUCCESS ? modalSuccessMessage : modalErrorMessage}
      </div>
      <div className={styles['modal-buttons']}>
        <Button
          variant="contained"
          onClick={() => {
            toggleModal();
            setHeaderLabel('');
            setOperationState(STATE.DEFAULT);
          }}
        >
          {modalOKLabel}
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles['reset-data-container']}>
      <Button variant="text" onClick={toggleExpanded}>
        {expandLabel}
      </Button>
      <div className={styles['reset-data-text-visible']}>
        <ContentRenderer name="visibleText" content={[visibleText]} />
      </div>
      <ModalContainer
        header={{ text: headerLabel, showXButton: false, position: 'center' }}
        show={isModalOpen}
        size="md"
        height="auto"
      >
        {operationState === STATE.DEFAULT && renderQuestionModalContent()}
        {operationState === STATE.LOADING && renderLoadingModalContent()}
        {[STATE.SUCCESS, STATE.ERROR].includes(operationState) && renderResultModalContent()}
      </ModalContainer>
      {isExpanded && (
        <div className={styles['reset-data-text-hidden']}>
          <ContentRenderer name="hiddenText" content={[hiddenText]} />

          <div className={styles['button-container']}>
            <Button variant="outlined" onClick={toggleExpanded}>
              {cancelButtonLabel}
            </Button>
            <Button variant="contained" onClick={toggleModal}>
              {confirmButtonLabel}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
