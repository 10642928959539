import { createMarketingPreference } from '@cv/portal-cps-lib/preferences/marketing-preference';
import {
  CreatePreferenceRequest,
  PftPreferencePayload,
} from '@cv/portal-cps-lib/preferences/marketing-preference/models';
import config from '@config/config';
import { get } from '@components/GlobalPreferences/GlobalPreferences';

export type CreateNewMarketingPreferenceOptions = CreateEditPayloadOptions & {
  accessToken: string;
  userId: string;
  locale?: string;
};

export const createNewMarketingPreference = ({
  accessToken,
  userId,
  locale = 'en-US',
  optIn,
  phone,
  email,
}: CreateNewMarketingPreferenceOptions) => {
  const environmentEnum = config.getEnvironmentEnum();
  const payload = createEditPayload({ optIn, phone, email });

  const request: CreatePreferenceRequest = {
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
    },
    environment: environmentEnum,
    data: {
      userId,
      ...payload,
    },
  };
  return createMarketingPreference(request);
};

export type CreateEditPayloadOptions = { optIn: boolean; phone: string; email: string };

export type EditPayload = Omit<PftPreferencePayload, 'userId'>;

export const createEditPayload = ({ optIn, phone, email }: CreateEditPayloadOptions): EditPayload => {
  const filterID = get('filterID') || '';
  const phoneWithoutCountry = Number(phone.slice(1));

  return {
    marketingPreferenceList: [
      {
        filterID,
        contactElement: {
          pft: phoneWithoutCountry,
        },
        optIn,
        preferenceAttributes: [
          {
            key: 'AgentID',
            value: email,
          },
        ],
      },
    ],
  };
};
