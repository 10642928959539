import { styled } from '@mui/material';
import { SpinnerProps, SpinnerTypes } from './Spinner';

const spinnerOpacity: Record<SpinnerTypes, number> = {
  opaque: 1,
  translucent: 0.6,
};

export const SpinnerBackDrop = styled('div')`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: ${({ type }: SpinnerProps) => spinnerOpacity[type] || spinnerOpacity.translucent};
  background-color: #fff;
  z-index: 999;
`;

export const SpinnerBox = styled('div')`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  position: static;
`;

export const SpinnerWheel = styled('div')`
  --width: calc(6 * 1em);
  --border-width: calc(var(--width) / 6);
  animation: animate 1.25s infinite linear;
  border: var(--border-width) solid lightgray;
  font-size: 10px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  border-left: var(--border-width) solid ${(props) => props.theme.palette.primary.main};

  border-radius: 50%;
  width: var(--width);
  height: var(--width);

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
