import React, { useEffect, useRef, FC } from 'react';

import ContentRenderer from '@components/ContentRenderer';

import styles from './HeroBanner.module.css';

type HeroImage = {
  image: {
    file: { url: string };
    title: string;
  };
  target: 'desktop' | 'tablet' | 'mobile';
};

type HeroBannerProps = {
  title: string;
  description: string;
  images: HeroImage[];
  portalHeroLink?: Array<{ componentType: string }>;
};

const HeroBanner: FC<HeroBannerProps> = ({ title, description, images, portalHeroLink }) => {
  const rootEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    images.forEach((image) => {
      const propName = `--hero-image-${image.target}`;
      const propValue = `url(${image.image.file.url})`;
      rootEl.current?.style.setProperty(propName, propValue);
    });
  }, [rootEl]);

  return (
    <div className={styles.HeroBanner} ref={rootEl}>
      <div className={styles['HeroBanner-text']}>
        <h2>{title}</h2>
        <p>{description}</p>
        {portalHeroLink && (
          <div className={styles['Hero-link']}>
            <ContentRenderer name="hero-link" content={[portalHeroLink]} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroBanner;
